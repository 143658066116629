/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */


import httpApi from "./httpApi";


/**
 * This function is used to get all brands
 */
const getAllBrands = async (searchTerm = "") => {
  const response = await httpApi.get("/v1/brands/admin", { params: { searchTerm } });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This function is used to get brands
 * @param {String} searchTerm
 */
const getBrands = getAllBrands;

/**
 * This function is used to get all products by brand
 *
 * @param {string} id
 * @param {string} [searchTerm=""]
 * @param {number} [page=0]
 * @param {number} [size=10]
 * @returns
 */
const getProductsByBrand = async (id, searchTerm = "", page = 0, size = 10) => {
  const response = await httpApi.get(`/v1/brands/id/${id}/products`, { params: { searchTerm, page, size } });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete a brand
 * @param {String} id
 */
const deleteBrand = async (id) => {
  const response = await httpApi.delete(`v1/brands/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/** This method is used to change status in brand
  * @param { String } id
  */
const setBrandStatus = async (id, status) => {
  const requestBody = { id, status };
  const response = await httpApi.patch("v1/brands/status", requestBody);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is add a new brand
 * @param {Object} data
 */
const addBrand = async (data) => {
  const response = await httpApi.post("v1/brands", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
* This method is use to update category
 * @param {Object} data
*/
const updateBrand = async (data) => {
  const response = await httpApi.patch("v1/brands", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get brand details for edit and view
 * @param {String} brandId
 * @returns {Object}
 */
const getBrandDetails = async (brandId) => {
  const response = await httpApi.get(`v1/brands/admin/id/${brandId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getAllBrands,
  getProductsByBrand,
  getBrands,
  setBrandStatus,
  deleteBrand,
  addBrand,
  getBrandDetails,
  updateBrand,
};
