// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n * \n */\n .BrandCreatorView_block1__24l5O{\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.BrandCreatorView_block1__24l5O .BrandCreatorView_pageSelectionText__10juN{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n\n.BrandCreatorView_block1__24l5O .BrandCreatorView_buttonAddMenu__30yOW{\n  color: #ffffff;\n  width: 113px;\n  height: 35px;\n  border-radius: 2px;\n  background-color: #448aff;\n  border: 0px;\n  float: right;\n}\n\n.BrandCreatorView_blockWrapper__1JCG8{\n  min-height: calc(100vh - 385px);\n} \n \n.BrandCreatorView_borderRadius2__1L35C {\n  border-radius: 0.125rem !important;\n}\n\n.BrandCreatorView_secondaryColor__11Cmj {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.BrandCreatorView_primaryColor__19ZEj {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.BrandCreatorView_customColorForDelete__36QlL {\n  background: #FF2C16 !important;\n  border-color: #FF2C16 !important;\n}\n\n.BrandCreatorView_customColorForDelete__36QlL:hover {\n  background: #F20000 !important;\n  border-color: #FF2C16 !important;\n}\n\n.BrandCreatorView_btn__1OzfB {\n  min-width: 7rem;\n}\n\n.BrandCreatorView_icon__3khBO {\n  width: 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"block1": "BrandCreatorView_block1__24l5O",
	"pageSelectionText": "BrandCreatorView_pageSelectionText__10juN",
	"buttonAddMenu": "BrandCreatorView_buttonAddMenu__30yOW",
	"blockWrapper": "BrandCreatorView_blockWrapper__1JCG8",
	"borderRadius2": "BrandCreatorView_borderRadius2__1L35C",
	"secondaryColor": "BrandCreatorView_secondaryColor__11Cmj",
	"primaryColor": "BrandCreatorView_primaryColor__19ZEj",
	"customColorForDelete": "BrandCreatorView_customColorForDelete__36QlL",
	"btn": "BrandCreatorView_btn__1OzfB",
	"icon": "BrandCreatorView_icon__3khBO"
};
module.exports = exports;
