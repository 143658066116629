// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n * \n */\n\n .MenuList_liveBtnWrapper__3Rg59 .MenuList_liveBtn__2mNYa{\n  border: none;\n  border-radius: 2px;\n  text-transform: uppercase;\n}\n .MenuList_block1__1K9kT{\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.MenuList_block1__1K9kT .MenuList_navigationManagementnText__sMW17{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n\n.MenuList_block1__1K9kT .MenuList_buttonAddMenu__3gcXV{\n  color: #ffffff;\n  width: 113px;\n  height: 35px;\n  border-radius: 2px;\n  background-color: #448aff;\n  border: 0px;\n  float: right;\n}\n.MenuList_tableWrapper__3B_El{\n  min-height: calc(100vh - 385px);\n}\n\n/* ============= table actions ===================== */\n.MenuList_actions__2Oh9g > span[disabled]  { \n  /* cursor: not-allowed; */\n  pointer-events: none !important;\n}\n\n.MenuList_actions__2Oh9g > span[disabled] > img {\n  opacity: 0.4; \n}\n", ""]);
// Exports
exports.locals = {
	"liveBtnWrapper": "MenuList_liveBtnWrapper__3Rg59",
	"liveBtn": "MenuList_liveBtn__2mNYa",
	"block1": "MenuList_block1__1K9kT",
	"navigationManagementnText": "MenuList_navigationManagementnText__sMW17",
	"buttonAddMenu": "MenuList_buttonAddMenu__3gcXV",
	"tableWrapper": "MenuList_tableWrapper__3B_El",
	"actions": "MenuList_actions__2Oh9g"
};
module.exports = exports;
