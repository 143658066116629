/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import CampaignStepTwoView from "../components/CampaignStepTwoView";


/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { updateQualifiers } from "../../../api/campaignServices";
/** ============ SVG IMAGE ICON ===================== */
/** ========= MODULE STYLES ========= */

const CampaignStepTwo = (props) => {
  const {
    nextStep, customerGroups, coupons, basicInfo,
    handleChange: parentHandleChange,
  } = props;
  const history = useHistory();
  const campaignId = (basicInfo && basicInfo.id) || null;

  /** local states */
  const [form, setForm] = useState({
    applicableUserGroups: [],
    applicableCoupons: [],
  });
  const [onSubmitting, setOnSubmitting] = useState(false);

  useEffect(() => {
    if (!(basicInfo && basicInfo.id)) {
      nextStep(1);
    }
  }, [basicInfo]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const updateOnServer = (applicableUserGroups, applicableCoupons, callback) => {
    if (basicInfo) {
      const requestBody = {
        id: basicInfo.id,
        applicableUserGroups,
        applicableCoupons,

      };
      updateQualifiers(requestBody).then((response) => {
        if (response && response.success === true) {
          callback(response.data);
        }
      });
    }
  };

  /**
   * This function is used to submit the form
   */
  const formSubmitHandler = async (event) => {
    if (Array.isArray(customerGroups)
    && Array.isArray(coupons)) {
      const customerGroupIds = customerGroups.filter((each) => each.id).map(({ id }) => id);
      const couponIds = coupons.filter((each) => each.id).map(({ id }) => id);
      updateOnServer(customerGroupIds, couponIds, () => {
        nextStep(3);
      });
    }
  };

  const formBackHandler = () => {
    nextStep(1);
  };

  const formSaveDraftHandler = () => {
    const customerGroupIds = customerGroups.filter((each) => each.id).map(({ id }) => id);
    const couponIds = coupons.filter((each) => each.id).map(({ id }) => id);
    updateOnServer(customerGroupIds, couponIds, () => {
      history.push("/marketing/campaign");
    });
  };

  const handleCustomerGroups = (customerGroupsIn) => {
    parentHandleChange("customerGroups", customerGroupsIn);
  };

  const handleCoupons = (couponsIn) => {
    parentHandleChange("coupons", couponsIn);
  };

  return (
    <CampaignStepTwoView
      onSubmitting={onSubmitting}
      campaignId={campaignId}
      customerGroups={customerGroups}
      coupons={coupons}
      // functions
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      formSubmitHandler={formSubmitHandler}
      formBackHandler={formBackHandler}
      formSaveDraftHandler={formSaveDraftHandler}
      handleCustomerGroups={handleCustomerGroups}
      handleCoupons={handleCoupons}
    />
  );
};

CampaignStepTwo.defaultExport = {
  basicInfo: null,
};

CampaignStepTwo.propTypes = {
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  coupons: PropTypes.arrayOf(PropTypes.any).isRequired,
  basicInfo: PropTypes.objectOf(PropTypes.any),
  // functions
  nextStep: PropTypes.func.isRequired,
};

export default CampaignStepTwo;
