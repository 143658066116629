/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** ===== COMPONENTS =========== */
import SiteMapManagement from "../components/SiteMapManagement";
import { updateUrlConfiguration, getUrlConfiguration } from "../../../api/SeoSettingsService";
import { getSitePreviewConfigurations } from "../../../api/sitePreviewConfigurationsServices";

const SiteMapContainer = () => {
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [form, setForm] = useState({
    productPriority: "",
    productSchedule: "",
    categoryPriority: "",
    categorySchedule: "",
  });
  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
     * This method is used to change form filed input
     * @param {Event} event
     */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
       * This method is used to cancel the edit operation and return to the listing page
       */
  const onHandleCancel = () => {
    history.push("/marketing/site-map");
  };

  /**
       * This method is used to update the form
       */
  const onHandleSave = async () => {
    await updateUrlConfiguration(form);
    history.push("/marketing/site-map");
  };

  const onSubmit = async (event) => {
    const requestBody = {
      productPriority: form.productPriority,
      productSchedule: form.productSchedule,
      categoryPriority: form.categoryPriority,
      categorySchedule: form.categorySchedule,
    };

    /** clear the messages */
    setMessage({ type: null, message: "" });
    /** setting on submit true */
    setOnSubmitting(true);
    const response = await updateUrlConfiguration(requestBody);
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages[0] });
      setTimeout(() => {
        setOnSubmitting(false);
        history.push(`/merchandising/product/edit/${response.data.id}`);
        setMessage({ type: null, message: "" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      setMessage({ type: "warning", message: t("common:message_error_default") });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  useEffect(() => {
    let localForm = form;
    getUrlConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        localForm = {
          ...form,
          productPriority: (data.product && data.product.priority) || "",
          productSchedule: (data.product && data.product.changeFrequency) || "",
          categoryPriority: (data.category && data.category.priority) || "",
          categorySchedule: (data.category && data.category.changeFrequency) || "",
        };
        setForm(localForm);
      }
    });
  }, []);

  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [indexingMode, setIndexingMode] = useState("");

  const IndexingModeList = [
    { label: "Staging", value: "STAGING" },
    { label: "Live", value: "LIVE" },

  ]


  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setIndexingMode("STAGING");
        }
      }
    });
  }, []);

  const onIndexingModeChange = (e) => {
    const { target: { value } } = e;
    setIndexingMode(value);

  }

  return (
    <SiteMapManagement
      form={form}
      handleFormChange={handleFormChange}
      onHandleSave={onHandleSave}
      onHandleCancel={onHandleCancel}
      setForm={setForm}
      onSubmit={onSubmit}
      productPriority={form.productPriority}
      productSchedule={form.productSchedule}
      categoryPriority={form.categoryPriority}
      categorySchedule={form.categorySchedule}
      handleChange={handleChange}
      previewEnabled={previewEnabled}
      indexingMode={indexingMode}
      IndexingModeList={IndexingModeList}
      onIndexingModeChange={onIndexingModeChange}
    />
  );
};
export default SiteMapContainer;
