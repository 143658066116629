/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "../css/JobConfiguration.module.css";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Badge from "../../../common/core/Badge";
import Nav from "../../../common/core/Nav";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";

const JobNavigationHeader = ({ selectedTab, changeTabView, onJobListing }) => {
  const { t } = useTranslation(["common", "job-configuration"]);

  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">
            {t("job-configuration:span_job_configuration_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Badge
            color={selectedTab === "JOB_HISTORY" ? "dark" : "info"}
            className="p-2 m-2"
            style={{ cursor: "pointer" }}
            onClick={() => changeTabView("JOB_HISTORY")}
          >
            {t("job-configuration:span_tab_job_history")}
          </Badge>
          <Badge
            color={selectedTab === "JOB_DETAILS" ? "dark" : "info"}
            className="p-2 m-2"
            style={{ cursor: "pointer" }}
            onClick={() => changeTabView("JOB_DETAILS")}
          >
            {t("job-configuration:span_tab_job_details")}
          </Badge>
          <Badge
            color={selectedTab === "JOB_STEPS" ? "dark" : "info"}
            className="p-2 m-2"
            style={{ cursor: "pointer" }}
            onClick={() => changeTabView("JOB_STEPS")}
          >
            {t("job-configuration:span_tab_job_task")}
          </Badge>
        </Col>
        <Col xs="12" md="4">
          <Nav>
            <NavItem>
              <NavLink onClick={() => { onJobListing(); }} href="#">
                {"< "}
                {t("job-configuration:sub_title_job_listing")}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </>
  );
};

JobNavigationHeader.propTypes = {
  onJobListing: PropTypes.func.isRequired,
  changeTabView: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

export default JobNavigationHeader;
