/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import CustomInput from "../../../common/core/CustomInput";
import Alert from "../../../common/core/Alert";

/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/Select";
import CategorySelector from "../containers/CategorySelectorContainer";
import ProductSelector from "../../productManagement/containers/SimpleProductSelectorContainer";
import BrandSelector from "../../categoryManagement/containers/SimpleBrandSelectorContainer";
import CombineWithSelector from "../containers/CombineWithSelector";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */

const DiscountedProductsForm = (props) => {
  const {
    categories, products, validator, categoryDetails, productDetails, onCampaign, brands, brandDetails, excludedProducts, excludedProductDetails, excludeProductsOnOffer, excludeSourceDiscountedProducts,
    handleChange, handleFormChange,
    refDiscountedElements,
  } = props;

  console.warn("DiscountedProductsForm", props);

  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);

  /** validation methods */
  const { register, errors } = validator;

  return (
    <div className="common-page-card">
      <Row className={clsx("px-3", "pb-1")}>
        <Col>
          <Row>
            <Col xs="12" className="text-truncate pageHeader pb-0">
              <span className="pageText">{t("promotion:title_discounted_products_form")}</span>
              <hr />
              <div ref={refDiscountedElements}>
                {errors.discountedElements
                  && (
                    <Alert color="danger">
                      {errors.discountedElements.message}
                    </Alert>
                  )}
              </div>
            </Col>

            <Col xs="12">
              <div className="p-3 my-1 border rounded">
                <FormGroup>
                  <Label className="formTextLabel">
                    Category
                  </Label>
                  <CategorySelector
                    name="discountStrategyCategories"
                    value={Array.isArray(categories) ? categories : []}
                    categoryDetails={Array.isArray(categoryDetails) ? categoryDetails : []}
                    onChange={(o) => handleChange("discountStrategyCategories", o)}
                    id="dpfcs"
                  />
                </FormGroup>
              </div>
            </Col>

            <Col xs="12">
              <div className="p-3 my-1 border rounded">
                <FormGroup>
                  <Label className="formTextLabel">
                    Brand
                  </Label>
                  <BrandSelector
                    name="discountStrategyBrands"
                    value={Array.isArray(brands) ? brands : []}
                    options={Array.isArray(brandDetails) ? brandDetails : []}
                    onChange={(o) => handleChange("discountStrategyBrands", o)}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs="12">
              <div className="p-3 my-1 border rounded">
                <Row>
                  <Col xs="12" sm={onCampaign ? "12" : "6"}>
                    <FormGroup>
                      <Label className="formTextLabel">
                        Included products
                      </Label>
                      <ProductSelector
                        name="discountStrategyProducts"
                        value={Array.isArray(products) ? products : []}
                        options={Array.isArray(productDetails) ? productDetails : []}
                        onChange={(o) => handleChange("discountStrategyProducts", o)}
                        excluded={Array.isArray(excludedProducts) ? excludedProducts : []}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm={onCampaign ? "12" : "6"}>
                    <FormGroup>
                      <Label className="formTextLabel">
                        Excluded products
                      </Label>
                      <ProductSelector
                        name="discountStrategyExcludedProducts"
                        value={Array.isArray(excludedProducts) ? excludedProducts : []}
                        options={Array.isArray(excludedProductDetails) ? excludedProductDetails : []}
                        onChange={(o) => handleChange("discountStrategyExcludedProducts", o)}
                        excluded={Array.isArray(products) ? products : []}
                      />
                    </FormGroup>
                    <CustomInput
                      type="checkbox"
                      checked={excludeProductsOnOffer}
                      name="discountedExcludeProductsOnOffer"
                      onChange={() => { }}
                      onClick={() => handleChange("discountedExcludeProductsOnOffer", !excludeProductsOnOffer)}
                      id="exclude-products-label-996"
                      className="font-weight-light small d-flex"
                      label={t("promotion:message_exclude_products_on_discount")}
                    />
                    <CustomInput
                      type="checkbox"
                      checked={excludeSourceDiscountedProducts}
                      name="excludeSourceDiscountedProducts"
                      onChange={() => { }}
                      onClick={() => handleChange(
                        "excludeSourceDiscountedProducts",
                        !excludeSourceDiscountedProducts,
                      )}
                      id="exclude-products-source-label-994"
                      className="font-weight-light small d-flex"
                      label={t("promotion:message_exclude_products")}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

DiscountedProductsForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  categoryDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  productDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  onCampaign: PropTypes.bool.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludeProductsOnOffer: PropTypes.bool.isRequired,
  excludeSourceDiscountedProducts: PropTypes.bool.isRequired,
  // functions
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  // scrol ref
  refDiscountedElements: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default DiscountedProductsForm;
