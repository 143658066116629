// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Aardra S\n */\n\n.StoreManagement_block3__3ywZc .StoreManagement_store__XUXUT .StoreManagement_storeBody__crJez .StoreManagement_storeTable__34SA2 thead {\n  background-color: #edf0f5;\n}\n\n.StoreManagement_block1__2g_R4 .StoreManagement_buttonNewStore__d9I7i{\n    color: #ffffff;\n    width: 160px;\n    border-radius: 2px;\n    border: 0px;\n    float: right;\n  }\n  \n  .StoreManagement_liveBtnWrapper__YG8Qr .StoreManagement_liveBtn__37cyC{\n    border: none;\n    border-radius: 2px;\n    text-transform: uppercase;\n  }", ""]);
// Exports
exports.locals = {
	"block3": "StoreManagement_block3__3ywZc",
	"store": "StoreManagement_store__XUXUT",
	"storeBody": "StoreManagement_storeBody__crJez",
	"storeTable": "StoreManagement_storeTable__34SA2",
	"block1": "StoreManagement_block1__2g_R4",
	"buttonNewStore": "StoreManagement_buttonNewStore__d9I7i",
	"liveBtnWrapper": "StoreManagement_liveBtnWrapper__YG8Qr",
	"liveBtn": "StoreManagement_liveBtn__37cyC"
};
module.exports = exports;
