// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta\n * (\"Confidential Information\"). You shall not disclose such Confidential\n * Information and shall use it only in accordance with the terms of the\n * contract agreement you entered into with Mozanta.\n *\n * PermissionManagement.module.css\n *\n * @author Naseef O\n *\n */\n\n\n\n/* block 2 */\n\n.PermissionManagement_block2__LgXbT{\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n\n.PermissionManagement_block2__LgXbT .PermissionManagement_buttonAdd__2rJYc{\n  color: #ffffff;\n  width: 113px;\n  height: 35px;\n  border-radius: 2px;\n  background-color: #e91e63;\n  border: 0px;\n  float: right;\n}\n\n.PermissionManagement_tableWrapper__1rWYe{\n  min-height: calc(100vh - 373px);\n}", ""]);
// Exports
exports.locals = {
	"block2": "PermissionManagement_block2__LgXbT",
	"buttonAdd": "PermissionManagement_buttonAdd__2rJYc",
	"tableWrapper": "PermissionManagement_tableWrapper__1rWYe"
};
module.exports = exports;
