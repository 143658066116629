/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalFooter from "../../../common/core/ModalFooter";

const ConfirmationModal = (props) => {
  const {
    isOpen,
    togglClose,
    content,
    header,
  } = props;

  return (
    <>
      <Modal modalClassName="modalWrapper" isOpen={isOpen}>
        <ModalHeader>
          {header}
        </ModalHeader>
        <ModalBody>
          {content}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="secondaryButton" onClick={togglClose}>Cancel</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  togglClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default ConfirmationModal;
