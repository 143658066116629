// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n .BrandForm_block2__1i0TZ {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.BrandForm_borderRadius2__F_ZPd {\n  border-radius: 0.125rem !important;\n}\n\n.BrandForm_secondaryColor__o2vRI {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.BrandForm_primaryColor__NVH2T {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.BrandForm_btn__39Y3C {\n  min-width: 7rem;\n}\n\n.BrandForm_icon__2ZN8p {\n  width: 1.25rem;\n}\n\n/* ========== FORM AREA ============ */\n.BrandForm_block3__25Ttm {\n  padding: 1.25rem;\n}\n\n/* ============= PRODUCT TITLE ========= */\n.BrandForm_titleTextContainer__21p3R {\n  padding: 0 2.1rem 0.125rem 2.1rem;\n  border-bottom: 1px solid #edf0f5;\n  margin: 0 -0.938rem;\n}\n\n.BrandForm_titleTextContainer__21p3R .BrandForm_pageTitleText__Uj4f-{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}", ""]);
// Exports
exports.locals = {
	"block2": "BrandForm_block2__1i0TZ",
	"borderRadius2": "BrandForm_borderRadius2__F_ZPd",
	"secondaryColor": "BrandForm_secondaryColor__o2vRI",
	"primaryColor": "BrandForm_primaryColor__NVH2T",
	"btn": "BrandForm_btn__39Y3C",
	"icon": "BrandForm_icon__2ZN8p",
	"block3": "BrandForm_block3__25Ttm",
	"titleTextContainer": "BrandForm_titleTextContainer__21p3R",
	"pageTitleText": "BrandForm_pageTitleText__Uj4f-"
};
module.exports = exports;
