/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */


import React, { useState } from "react";
import PropTypes from "prop-types";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";

const MultiSelectCheckBox = ({
  inputData, stateData, handleChange, className,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    label, inputName, attributes,
  } = inputData;
  const selectedCheckBoxArray = stateData[inputName];
  const {
    options,
  } = attributes || {};
  return (
    <>
      <div className={className || ""}>
        <Label>
          {label}
        </Label>
        <Dropdown
          id={inputName}
          name={inputName}
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
        >
          <DropdownToggle caret>
            {label}
          </DropdownToggle>
          <DropdownMenu>
            {Array.isArray(options) && options.length > 0 && options.map((option) => (
              <div>
                <FormGroup check>
                  <Label
                    check
                    className="text-truncate pr-1"
                  >
                    <Input
                      type="checkbox"
                      id={option.label}
                      checked={selectedCheckBoxArray
                        && selectedCheckBoxArray.includes(option.value)}
                      onChange={() => handleChange(inputName, option.value, "MULTI_SELECT_CHECKBOX")}
                      value={option.value}
                      disabled={option.value !== "All" && selectedCheckBoxArray && selectedCheckBoxArray.includes("All")}
                    />
                    {option.label}
                  </Label>
                </FormGroup>
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

MultiSelectCheckBox.propTypes = {
  inputData: PropTypes.objectOf(PropTypes.any).isRequired,
  stateData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};


export default MultiSelectCheckBox;
