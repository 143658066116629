/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useState, useEffect } from "react";
import OrderNoteForm from "../components/OrderNoteForm";
import { updateOrderNote } from "../../../api/orderServices";

const OrderNoteFormContainer = ({ orderNotes, orderId }) => {
  useEffect(() => {
    setOrderNote(orderNotes);
    setId(orderId);
  }, [orderNotes, orderId]);

  const [orderNote, setOrderNote] = useState("");
  const [id, setId] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onHandleNoteChange = (e) => {
    const { value } = e.target;
    setOrderNote(value);
  };

  const onHandleNoteSubmit = async () => {
    setSubmitting(true);
    await updateOrderNote(id, orderNote);
    setSubmitting(false);
  };
  return (
    <OrderNoteForm
      orderNotes={orderNote}
      onHandleNoteChange={onHandleNoteChange}
      onHandleNoteSubmit={onHandleNoteSubmit}
      submitting={submitting}
    />
  );
};

export default OrderNoteFormContainer;
