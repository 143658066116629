/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "../css/ImageSelection.module.css";
import Add from "../../../common/assets/images/svg/add-24px.svg";
import ImageCropper from "../../../common/components/imageCropper/Cropper";
import { uploadProductImage } from "../../../api/productManagementServices";

//* as of now, sample component only */
const ImageSelection = ({
  images,
  handleChangeFile,
  modalOpen,
  imageUrl,
  setModalOpen,
  handleUpload,
  isUploading,
  setIsUploading,
  handleCloseModal,
  handleRemoveImage,
}) => {
  /**
   * This method is used to return image url form array
   * @param {Array} medias
   */
  const getPreviewImage = (medias) => {
    let imgUrl = null;
    if (medias.mediaThumbnailUrl) {
      imgUrl = medias.mediaThumbnailUrl;
    }
    return imgUrl;
  };

  /**
   * This method is used to generate key
   *
   * @param {*} index
   */
  const generateKey = (index) => `id_${index}`;

  return (
    <div className="d-flex">
      {
        Array.isArray(images) && images.map((image, index) => (
          <div key={generateKey(index)} className={styles.uploadImagePreview}>
            <span
              className={styles.uploadImagePreviewClose}
              role="presentation"
              onClick={() => handleRemoveImage(index)}
            >
              x

            </span>
            <img className="img-fluid img-thumbnail" src={getPreviewImage(image)} alt="" />
          </div>
        ))
      }
      <div className={clsx(styles.uploadImagePreviewIcon, "")}>
        <label className="btn btn-default w-100 h-100 p-0 m-0">
          <img
            className="img-fluid img-thumbnail h-100"
            role="presentation"
            width="130px"
            src={Add}
            alt=""

          />
          <input
            type="file"
            hidden
            onChange={(e) => handleChangeFile(e)}
            accept="image/jpg,image/jpg"
          />
        </label>
        <ImageCropper
          imageUrl={imageUrl}
          modalOpen={modalOpen}
          aspect={0.75}
          setModalOpen={setModalOpen}
          handleUpload={handleUpload}
          imageUploadApi={uploadProductImage}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </div>
  );
};

ImageSelection.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleChangeFile: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  setIsUploading: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
};

export default ImageSelection;
