/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SEOInfo Editor Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import commonUtils from "../../../common/utils/commonUtils";
import { getSeoInformation, updateSeoInformation } from "../../../api/categoryManagementServices";
import SEOInfoEditor from "../components/SEOInfoEditor";

const SEOInfoEditorContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    widgetType, categoryId, pageType, channel, locale,
  } = commonUtils.getQueryParams(location);


  const [seoInfoForm, setSeoInfoForm] = useState({
    metaDescription: "",
    pageTitle: "",
    metaKeywords: "",
    description: "",
    longDescription: "",
    customSeoEnabled: false,
  });

  useEffect(() => {
    getSeoInformation(categoryId, locale).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setSeoInfoForm({
          ...data,
          description: data.description || data.pageTitle,
          longDescription: data.longDescription || data.metaDescription,
        });
      }
    });
  }, [categoryId]);


  /**
* This method is used to handle change text
*
* @param {String} key
* @param {Object} e
*/
  const handleChange = (key, e) => {
    setSeoInfoForm({ ...seoInfoForm, [key]: e.target.value });
  };

  const handleCustomChange = (name, value) => {
    setSeoInfoForm({ ...seoInfoForm, [name]: value })
  }

  /**
 * This method is used to redirect to page-customization
 *
 */
  const handleRedirectToPageCustomization = () => {
    const path = `/merchandising/${channel === "mobile" ? "mobile-app-customization" : "web-app-customization"}?pageType=${pageType}&&categoryId=${categoryId}`;
    history.push(path);
  };

  /**
 * This method is used to handle sumbit
 *
 */
  const handleSubmitForm = () => {
    updateSeoInformation(categoryId, seoInfoForm, locale).then((response) => {
      if (response && response.success) {
        handleRedirectToPageCustomization();
      }
    });
  };

  return (
    <SEOInfoEditor
      widgetType={widgetType}
      location={location}
      seoInfoForm={seoInfoForm}
      handleChange={handleChange}
      handleSubmitForm={handleSubmitForm}
      handleRedirectToPageCustomization={handleRedirectToPageCustomization}
      handleCustomChange={handleCustomChange}
    />
  );
};
export default SEOInfoEditorContainer;
