/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */
import httpApi from "./httpApi";

/**
 *This method is used to get layouts
 *
 * @param {*} pageType
 * @param {*} organizationId
 */
const getPageLayouts = async (pageType) => {
  const response = await httpApi.get(`/v1/layouts/pageType/${pageType}`);
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
*This method is used to update page layouts
*
* @param {*} pageType
* @param {*} layoutId
* @param {*} configId
* @param {*} categoryId
*/
const updatePageLayouts = async (pageType, layoutId, configId, categoryId) => {
  const response = await httpApi.patch("/v1/layouts", {
    pageType, layoutId, configId, categoryId,
  });
  if (response && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to get Domain Name
 *
 */
const getDomainName = async () => {
  const response = await httpApi.get("/v1/organization/domain-name");
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};


export {
  getPageLayouts,
  updatePageLayouts,
  getDomainName,
};
