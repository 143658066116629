/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */


import React, { useState } from "react";
import clsx from "clsx";

/** ===== TAG COMPONENTS =========== */
import Button from "../../../common/core/Button";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";

/** ======== MODULE STYLES ========== */
import styles from "../css/AddUrl.module.css";


const AddUrl = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <div>
        <Button onClick={toggle} className={clsx(styles.borderRadius2, "text-truncate", "btn-tertiary", "button-font-size", "flex-shrink-1", "text-truncate")}>Add Url to Sitemap</Button>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>
            <ModalHeader toggle={toggle}>Add New Url</ModalHeader>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  https://mozcomstore.com/
                </span>
              </div>
              <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>Submit</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
AddUrl.propTypes = {


};
export default AddUrl;
