// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta\n * (\"Confidential Information\"). You shall not disclose such Confidential\n * Information and shall use it only in accordance with the terms of the\n * contract agreement you entered into with Mozanta.\n *\n * Table.module.css\n *\n * @author Naseef O\n *\n */\n\n\n.UrlTable_spinnerWrapper__6wt7a {\n    width: 1.5rem !important;\n    height: 1.5rem !important;\n    color: #17a2b8;\n}", ""]);
// Exports
exports.locals = {
	"spinnerWrapper": "UrlTable_spinnerWrapper__6wt7a"
};
module.exports = exports;
