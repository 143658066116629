/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * Validation constants
 *
 * @author Naseef O
 *
 */

const constants = {
  DATE_FORMAT: "DD/MM/YYYY",
  IS_NUMBER: /^[1-9][0-9]*([.][0-9]{2}|)$/,
  IS_EMAIL: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
  IS_INTEGER: /^[1-9][0-9]*$/,
};

export default constants;
