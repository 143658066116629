/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Inventory Management Container
 *
 * @author Amjad Rehman A
 *
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import InventoryManagement from "../components/InventoryManagement";
import {
  getStoreList,
  getInventoryData,
  getInventoryDataBySearchCriteria,
  setPerpetualInInventory,
  transformInventoryData,
} from "../../../api/inventoryManagementService";

const InventoryManagementContainer = () => {
  const [storeList, setStoreList] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [page, setPage] = useState(0);
  const [storeId, setStoreId] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isProccessing, setIsProccessing] = useState(false);

  const addZero = (val) => (val < 10 ? `0${val}` : val);

  useEffect(() => {
    getStoreList().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && Array.isArray(data) && data.length > 0) {
          const storeLists = [{ value: "MASTER", label: "Fulfillment Stores" }];
          setStoreId("MASTER");
          data.map((store) => {
            const storeListItem = {};
            storeListItem.value = store.storeId;
            storeListItem.label = store.storeId;
            storeLists.push(storeListItem);
            return null;
          });
          getInventoryListBySearchTerm();
          setStoreList(storeLists);
        }
      }
    });
  }, []);

  const getInventoryList = (storeId, pageNumber, limit) => {
    /** getting list of Inventory when page load */
    getInventoryData(storeId, pageNumber, limit).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setInventoryData(data);
      }
    });
  };

  const updatePerpetualFlag = (perpetual, productId, skuId) => {
    setPerpetualInInventory(productId, skuId, perpetual).then(
      async (response) => {
        if (response && response.success) {
          const newInventoryData = inventoryData.map((inventory) =>
            inventory.productId === productId
              ? { ...inventory, perpetual }
              : inventory
          );
          setInventoryData(newInventoryData);
        }
      }
    );
  };

  const handlePerpetualStatus = (perpetual, productId, skuId, e) => {
    updatePerpetualFlag(perpetual, productId, skuId);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getInventoryListBySearchTerm = (
    storeId,
    searchTerm,
    pageNumber,
    limit
  ) => {
    let searchValue;
    if (searchTerm) {
      searchValue = searchTerm.trim();
    }
    /** getting list of Inventory when page load */
    getInventoryDataBySearchCriteria(
      storeId,
      searchValue,
      pageNumber,
      limit
    ).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setInventoryData(data);
      }
    });
  };

  const getInventoryListbyPage = (storeId, pageNumber, limit) => {
    /** getting list of Inventory when page load */
    getInventoryData(storeId, pageNumber, limit).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (Array.isArray(data)) {
          setInventoryData(data);
          const moreData = data.length > 0;
          setHasMoreData(moreData);
        } else {
          setHasMoreData(false);
        }
        setPage(pageNumber);
      }
    });
  };

  /**
   * This function used on handle store change
   */
  const onHandleStoreChange = (e) => {
    const { value } = e.target;
    setStoreId(value);
    getInventoryList(value, 0, 5);
  };

  const getPageData = (direction) => {
    getInventoryListbyPage(storeId, direction);
  };

  const handleSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  const handleSearch = () => {
    if (searchTerm && `${searchTerm}`.trim().length > 1) {
      getInventoryListBySearchTerm(storeId, searchTerm, 0, 5);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      getInventoryListBySearchTerm(storeId, searchTerm, 0, 5);
    } else if (storeId) {
      getInventoryList(storeId, 0, 5);
    }
  }, [searchTerm, storeId]);

  const initializeData = async () => {
    setSearchTerm("");
    setStoreId("");
    setPage(0);
    setHasMoreData(true);
    setInventoryData("");
  };

  const onHandleClear = () => {
    initializeData();
  };

  const onHandleExport = async (type) => {
    setIsProccessing(true);
    const page = 0;
    const size = 0;
    const currentDateTime = new Date();
    let searchValue;
    if (searchTerm) {
      searchValue = searchTerm.trim();
    }
    getInventoryDataBySearchCriteria(storeId, searchValue, page, size)
      .then(async (response) => {
        if (response && response.success === true) {
          const { data } = response;
          if (Array.isArray(data)) {
            const inventories = await transformInventoryData(data);
            const year = currentDateTime.getFullYear();
            const month = addZero(currentDateTime.getMonth() + 1);
            const date = currentDateTime.getDate();
            const hours = currentDateTime.getHours();
            const mins = addZero(currentDateTime.getMinutes());
            const secs = currentDateTime.getSeconds();
            let fileName = `Inventory_${year}${month}${date}${hours}${mins}${secs}.xlsx`;
            if (type === "CSV") {
              fileName = `Inventory_${year}${month}${date}${hours}${mins}${secs}.csv`;
            }
            const ws = XLSX.utils.json_to_sheet(inventories);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Inventories");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 15 },
              { wch: 15 },
              { wch: 10 },
              { wch: 10 },
              { wch: 15 },
              { wch: 30 },
              { wch: 30 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch((err) => {
        setIsProccessing(false);
      });
    setIsProccessing(false);
  };

  return (
    <InventoryManagement
      storeList={storeList}
      onHandleStoreChange={onHandleStoreChange}
      inventoryData={inventoryData}
      getPageData={getPageData}
      page={page}
      hasMoreData={hasMoreData}
      searchTerm={searchTerm}
      handleSearchTerm={handleSearchTerm}
      search={handleSearch}
      handleKeyPress={handleKeyPress}
      onHandleClear={onHandleClear}
      storeId={storeId}
      onPerpetualStatusChange={handlePerpetualStatus}
      onHandleExport={onHandleExport}
      isProccessing={isProccessing}
    />
  );
};

export default InventoryManagementContainer;
