/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

/** ========= SUB COMPONENT ========= */
import AddBadge from "../components/AddBadge";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

/** ========= API SERVICE FUNCTIONS ========= */
import { addBadge } from "../../../api/badgeService";

const AddBadgeContainer = () => {
    const history = useHistory();
    const positions = constants.NAVIGATION_MENU_POSITIONS;
    const productDetails = [];
    const excludedProducts = [];
    const products = [];
    /** local states */
    const [form, setForm] = useState({
        badge_id: "",
        badge_type: "",
        primary_text: "",
        secondary_text: "",
        font_color: "#24292E",
        background_color: "#fffb00",
        badgeCode: "CustomBadge",
        badgeGroup: "CustomBadge",
    });
    const [onSubmitting, setOnSubmitting] = useState(false);
    const [message, setMessage] = useState({
        type: null,
        message: "",
    });
    /**
     * This method is used to change fom state
     * @param {String} name
     * @param {String} value
     */
    const handleChange = (name, value) => {
        let valueClone = value;
        if (name === "code") {
            valueClone = value.toUpperCase();
        }
        setForm({
            ...form,
            [name]: valueClone,
        });
    };

    /**
     * This method is used to change form filed input
     * @param {Event} event
     */
    const handleFormChange = (event) => {
        handleChange(event.target.name, event.target.value);
    };

    /**
     * This method is used to go back to listing page
     */
    const formCancel = () => {
        history.push("/marketing/badges");
    };

    /**
     * This method is used to submit the form for add new coupon
     * @param {Event} event
     */
    const formSubmit = async (event) => {
        const requestBody = {
            primaryText: form.primary_text,
            secondaryText: form.secondary_text,
            fontColor: form.font_color,
            backgroundColor: form.background_color,
            applicableProducts: form.products,
            badgeCode: form.badgeCode,
            badgeGroup: form.badgeGroup,
        };
        setMessage({ type: null, message: "" });
        setOnSubmitting(true);
        const response = await addBadge(requestBody);
        if (response && response.success) {
            setTimeout(() => {
                setOnSubmitting(false);
                history.push("/marketing/badges");
            }, 3000);
        } else {
            setOnSubmitting(false);
            if (response && response.messages) {
                setMessage({ type: "warning", message: response.messages[0] });
            }
            setTimeout(() => {
                setMessage({ type: null, message: "" });
            }, 3000);
        }
    };

    return (
        <AddBadge
            badge_id={form.badge_id}
            badge_type={form.badge_type}
            primary_text={form.primary_text}
            secondary_text={form.secondary_text}
            font_color={form.font_color}
            background_color={form.background_color}
            /** form data */
            positions={positions}
            onSubmitting={onSubmitting}
            /** form methods */
            handleFormChange={handleFormChange}
            handleChange={handleChange}
            formSubmit={formSubmit}
            formCancel={formCancel}
            message={message}
            productDetails={productDetails}
            excludedProducts={excludedProducts}
            products={products}
        />
    );
};

export default AddBadgeContainer;