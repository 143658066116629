// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n * \n */ \n.AddBadge_borderRadius2__2DbcW {\n  border-radius: 0.125rem !important;\n}\n\n.AddBadge_secondaryColor__1h61y {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.AddBadge_primaryColor__2hKFT {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.AddBadge_btn__1dvva {\n  min-width: 7rem;\n}\n\n .AddBadge_block1__H8TEj{\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.AddBadge_block1__H8TEj .AddBadge_navigationManagementnText__34vhR{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n\n.AddBadge_block1__H8TEj .AddBadge_buttonAddCoupon__2mubz{\n  color: #ffffff;\n  width: 113px;\n  height: 35px;\n  border-radius: 2px;\n  background-color: #448aff;\n  border: 0px;\n  float: right;\n}\n.AddBadge_blockWrapper__3lzW_{\n  min-height: calc(100vh - 385px);\n}", ""]);
// Exports
exports.locals = {
	"borderRadius2": "AddBadge_borderRadius2__2DbcW",
	"secondaryColor": "AddBadge_secondaryColor__1h61y",
	"primaryColor": "AddBadge_primaryColor__2hKFT",
	"btn": "AddBadge_btn__1dvva",
	"block1": "AddBadge_block1__H8TEj",
	"navigationManagementnText": "AddBadge_navigationManagementnText__34vhR",
	"buttonAddCoupon": "AddBadge_buttonAddCoupon__2mubz",
	"blockWrapper": "AddBadge_blockWrapper__3lzW_"
};
module.exports = exports;
