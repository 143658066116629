/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * WidgetContent
 *
 * @author Naseef O
 *
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/PageCustomization.module.css";
import DragAndDropTableContainer from "../containers/DragAndDropTableContainer";
import CustomizationTableContainer from "../containers/CustomizationTableContainer";
import Constants from "../../../common/utils/constants";

const WidgetContent = (props) => {
  const history = useHistory();
  const {
    widgetContent, imgEnabled, getPageContents, categoryId, selectedPage, channel, locale,
  } = props;

  /**
 * This method is used to handle Redirect To WidgetContentEditor
 *
 * @param {boolean} isEdit
 */
  const handleRedirectToWidgetContentEditor = (isEdit, index, widgetType, configId) => {
    history.push(`/merchandising/${channel === "mobile" ? "mobile-app-customization" : "web-app-customization"}/content-editor?isEdit=${isEdit}&&index=${index}&&widgetType=${widgetType}&&configId=${configId}&&categoryId=${categoryId}&&pageType=${selectedPage}&&channel=${channel}&&locale=${locale}`);
  };

  return (
    <>
      <Row className={clsx(styles.widgetsTable, "m-0 ")}>
        <Col xs="12">
          {widgetContent && (
            <>
              {Constants.CAROUSAL_LIST.includes(widgetContent.widgetType)
                ? (
                  <DragAndDropTableContainer
                    widgetType={widgetContent.widgetType}
                    configId={widgetContent.configId}
                    categoryId={categoryId}
                    handleRedirectToWidgetContentEditor={handleRedirectToWidgetContentEditor}
                    widget={Constants.CAROUSAL_LIST.includes(widgetContent.widgetType) && widgetContent}
                    getPageContents={getPageContents}
                    deleteEnabled={widgetContent
                      && widgetContent.contents
                      && widgetContent.contents.length > 1}
                    channel={channel}
                    locale={locale}

                  />
                )
                : (
                  <CustomizationTableContainer
                    widgetType={widgetContent.widgetType}
                    configId={widgetContent.configId}
                    handleRedirectToWidgetContentEditor={handleRedirectToWidgetContentEditor}
                    widget={widgetContent}
                    imgEnabled={imgEnabled}
                    categoryId={categoryId}
                    deleteEnabled={widgetContent&& widgetContent.contents && Constants.NO_DEL_RESTRICT_WIDGETS.includes(widgetContent.widgetType)? true :
                      (widgetContent && widgetContent.contents && widgetContent.contents.length > 1)}
                    getPageContents={getPageContents}
                    channel={channel}
                    locale={locale}

                  />
                )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

WidgetContent.propTypes = {
  widgetContent: PropTypes.objectOf(PropTypes.any).isRequired,
  getPageContents: PropTypes.func.isRequired,
  imgEnabled: PropTypes.bool.isRequired,
  categoryId: PropTypes.string.isRequired,
  selectedPage: PropTypes.string.isRequired,
};

export default WidgetContent;
