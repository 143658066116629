/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */
import MainMenuItem from "../components/MainMenuItem";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */

const MainMenuItemContainer = (props) => {
  const {
    level, index, id, name, menus, allowDropdown, htmlId, active, menuCount, position, image,
    editMenu, deleteMenu, addMenu, updateColumns, handleMenuItemStatus,
    getLocalKey,
  } = props;
  const COL_SPLIT = 6;

  /** local states */
  const resizeAnimDiv = useRef(null);
  const resizeAnimContainer = useRef(null);
  const [resizeColumn, setResizeColumn] = useState(null);
  const [localContents, setLocalContent] = useState([]);
  const [onFullscreen, setOnFullscreen] = useState(null);

  useEffect(() => {
    setLocalContent(menus);
  }, [menus]);

  useEffect(() => {
    const bodyTag = document.body;
    if (bodyTag && bodyTag.classList) {
      if (onFullscreen) {
        bodyTag.classList.add("modal-open");
      } else {
        bodyTag.classList.remove("modal-open");
      }
    }
  }, [onFullscreen]);

  /**
   * This method is sued to clear the movement
   */
  const clearResizing = () => {
    if (resizeAnimDiv && resizeAnimDiv.current) {
      const { current } = resizeAnimDiv;
      current.style.display = "none";
      setResizeColumn(null);
    }
  };

  /**
   * This method is sued to update columns in parent state
   * @param {Array} columns
   */
  const columnsChanged = (columns) => {
    updateColumns(columns, id, index);
  };

  /**
   * This method is used to monitor on mouse down to set selected col and index
   * @param {Integer} itemIndex
   * @param {Event} event
   */
  const onMouseDown = (itemIndex, event) => {
    if (event && event.target && !resizeColumn) {
      const { target } = event;
      if (target && target.parentNode) {
        const { parentNode } = target;
        if (resizeAnimDiv && resizeAnimDiv.current) {
          const { current } = resizeAnimDiv;
          const left = parentNode.offsetLeft + parentNode.offsetWidth;

          current.style.left = `${left - 4}px`;
          current.style.display = "block";
          setResizeColumn({ parentNode, itemIndex });
        }
      }
    }
  };

  /**
   * This method is used to catch on mouse up event
   */
  const onMouseUp = () => {
    clearResizing();
  };

  /**
   * This method is sued to resize two columns
   * @param {Number} indexIn
   * @param {Number} indexTo
   * @param {Number} increment
   */
  const handleSelfResizeColumn = (indexIn, indexTo, increment) => {
    if (Array.isArray(localContents) && localContents.length > 0) {
      let restTotalColWidth = 0;
      const tempLocalContests = localContents.map((each, colIndex) => {
        if ((colIndex !== indexIn && colIndex !== indexTo) && each && each.columnConfig && each.columnConfig.columnWidth) {
          restTotalColWidth += each.columnConfig.columnWidth;
        }
        return each;
      });
      const sourceCol = tempLocalContests[indexIn];
      const targetCol = tempLocalContests[indexTo];
      if (sourceCol && targetCol) {
        const sourceColWidth = Math.max(1, (sourceCol.columnConfig.columnWidth + increment));
        const destinationColWidth = Math.max(1, (targetCol.columnConfig.columnWidth - increment));
        if (sourceCol.columnConfig.columnWidth !== sourceColWidth && targetCol.columnConfig.columnWidth !== destinationColWidth) {
          if (restTotalColWidth + sourceColWidth + destinationColWidth === 6) {
            tempLocalContests[indexIn] = { ...sourceCol, columnConfig: { ...sourceCol.columnConfig, columnWidth: sourceColWidth } };
            tempLocalContests[indexTo] = { ...targetCol, columnConfig: { ...targetCol.columnConfig, columnWidth: destinationColWidth } };
            setLocalContent(tempLocalContests);
            columnsChanged(tempLocalContests);
          }
        }
      }
    }
  };

  /**
   * This method us sued to get resize component position in 6 col grid
   * @param {Array} menusIn
   */
  const getCurrentColIndex = (menusIn) => (menusIn.reduce((total, each) => total + each.columnConfig.columnWidth, 0) - 1);

  /**
   * This method is sued to preprocess the resizing column data
   * @param {Number} indexIn
   * @param {Number} increment
   */
  const reSizeColumn = (indexIn, increment) => {
    if (Array.isArray(localContents) && localContents.length > 0 && typeof increment === "number") {
      const menu = localContents[indexIn];
      const currentIndex = getCurrentColIndex(localContents.slice(0, indexIn + 1));
      if (menu && menu.columnConfig && menu.columnConfig.columnWidth) {
        const { columnConfig } = menu;
        const forWord = increment - currentIndex;
        if (forWord > 0) {
          if (!(indexIn >= localContents.length - 2)) {
            handleSelfResizeColumn(indexIn, indexIn + 1, forWord);
          } else {
            handleSelfResizeColumn(indexIn + 1, indexIn, -1 * forWord);
          }
        } else if (!(indexIn === 0 && columnConfig.columnWidth <= 1)) {
          if (columnConfig.columnWidth > 1) {
            handleSelfResizeColumn(indexIn, indexIn + 1, forWord);
          }
        }
      }
    }
  };

  /**
   * This method is used to monitor mouse move on root component
   * @param {Integer} itemIndex
   * @param {Event} event
   */
  const onMouseMove = (itemIndex, event) => {
    if (resizeAnimDiv && resizeAnimDiv.current && resizeAnimContainer && resizeAnimContainer.current && resizeColumn) {
      const container = resizeAnimContainer.current;
      if (event) {
        const {
          left, top, width, height,
        } = container.getBoundingClientRect();
        let positionLeft = event.clientX - left;
        const positionTop = event.clientY - top;
        const { current } = resizeAnimDiv;
        const eachWidth = Math.round(width / COL_SPLIT);
        const lastPerfect = Math.max(eachWidth, (positionLeft - (positionLeft % eachWidth)));
        const nextPerfect = Math.min((width - eachWidth), ((positionLeft + eachWidth) - (positionLeft % eachWidth)));
        const rangeValue = 30;

        if ((lastPerfect < positionLeft && positionLeft < (lastPerfect + rangeValue)) || positionLeft < (eachWidth)) {
          positionLeft = lastPerfect - 4;
          reSizeColumn(resizeColumn.itemIndex, Math.floor(positionLeft / eachWidth), 1);
        }
        if ((nextPerfect > positionLeft && positionLeft > (nextPerfect - rangeValue)) || positionLeft > (width - eachWidth)) {
          positionLeft = nextPerfect - 4;
          reSizeColumn(resizeColumn.itemIndex, Math.floor(positionLeft / eachWidth), -1);
        }

        current.style.left = `${positionLeft}px`;
        if (positionLeft <= 0 || positionTop <= 0 || positionLeft > left + width || positionTop > top + height) {
          clearResizing();
        }
      }
    }
  };

  /**
   * This method is sued to add new column
   * @param {Number} indexIn
   * @param {Boolean} toRight
   */
  const addNewColumn = (indexIn, toRight) => {
    if (localContents.length < 6) {
      const newColIndex = toRight ? (indexIn + 1) : indexIn;
      let prevContents = (newColIndex) > 0 ? localContents.slice(0, newColIndex) : [];
      let nextContents = localContents.slice(newColIndex, localContents.length);
      let widthChanged = false;
      nextContents = nextContents.map((each) => {
        if (!widthChanged && each.columnConfig.columnWidth > 1) {
          const columnWidth = each.columnConfig.columnWidth - 1;
          widthChanged = true;
          return { ...each, columnConfig: { ...each.columnConfig, columnWidth } };
        }
        return each;
      });
      if (!widthChanged) {
        prevContents = prevContents.map((each) => {
          if (!widthChanged && each.columnConfig.columnWidth > 1) {
            const columnWidth = each.columnConfig.columnWidth - 1;
            widthChanged = true;
            return { ...each, columnConfig: { ...each.columnConfig, columnWidth } };
          }
          return each;
        });
      }
      const nowContents = {
        id: `col-${getLocalKey()}`,
        columnConfig: {
          columnWidth: 1,
          displayType: "BasicItem",
        },
        menus: [],
      };
      if (widthChanged) {
        const newContentArray = [...prevContents, nowContents, ...nextContents];
        setLocalContent(newContentArray);
        columnsChanged(newContentArray);
      } else {
        let nextColWidth = 6;
        localContents.forEach((h) => {
          if (h && h.columnConfig && h.columnConfig.columnWidth) {
            nextColWidth -= h.columnConfig.columnWidth;
          }
        });
        if (nextColWidth && nextColWidth < 6) {
          const newContentArray = [...prevContents, { ...nowContents, columnConfig: { ...nowContents.columnConfig, columnWidth: nextColWidth } }, ...nextContents];
          setLocalContent(newContentArray);
          columnsChanged(newContentArray);
        }
      }
    }
  };

  /**
   * This method is used to remove a column and adjust the width of other cols
   * @param {Number} indexIn
   */
  const removeColumn = (indexIn) => {
    const removedContents = localContents[indexIn];
    if (removedContents) {
      const colWidthRemove = removedContents.columnConfig.columnWidth;
      const addContentIndex = indexIn >= (localContents.length - 1) ? indexIn - 1 : indexIn + 1;
      const inLocalContents = localContents.map((each, ind) => (ind === addContentIndex ? (
        { ...each, columnConfig: { ...each.columnConfig, columnWidth: each.columnConfig.columnWidth + colWidthRemove } }
      ) : each));
      const prevContents = (indexIn) > 0 ? inLocalContents.slice(0, indexIn) : [];
      const nextContents = inLocalContents.slice(indexIn + 1, inLocalContents.length);
      const newContentArray = [...prevContents, ...nextContents];
      /** * ================== section for issue fix ========================= ** */
      let indexExd = 0;
      const newFixArr = [];
      newContentArray.some((eachCol) => {
        let someStatus = false;
        let localIn = { ...eachCol };
        if (eachCol && eachCol.columnConfig && eachCol.columnConfig.columnWidth) {
          const currentWidth = Number(eachCol.columnConfig.columnWidth);
          const lastIn = indexExd;
          indexExd += currentWidth;
          if (indexExd === 6) {
            someStatus = true;
          } else if (indexExd > 6) {
            if (lastIn < 6) {
              const newColumnWidth = 6 - lastIn;
              localIn = { ...eachCol, columnConfig: { ...eachCol.columnConfig, columnWidth: newColumnWidth } };
            }
            someStatus = true;
          }
        }
        newFixArr.push(localIn);
        return someStatus;
      });
      /** * ================== section for issue fix end ========================= ** */
      setLocalContent(newFixArr);
      columnsChanged(newFixArr);
    }
  };

  const openFullscreen = () => {
    setOnFullscreen((oldState) => !oldState);
  };

  return (
    <>
      <MainMenuItem
        htmlId={htmlId}
        fullscreen={onFullscreen}
        minimal={!onFullscreen}
        hiddenHeader={onFullscreen}
        collapseOpen
        level={level}
        index={index}
        id={id}
        name={name}
        active={active}
        image={image}
        allowDropdown={allowDropdown}
        resizeAnimDiv={resizeAnimDiv}
        resizeAnimContainer={resizeAnimContainer}
        menus={localContents}
        onResizing={Boolean(resizeColumn)}
        editMenu={editMenu}
        deleteMenu={deleteMenu}
        addMenu={addMenu}
        getLocalKey={getLocalKey}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        openFullscreen={openFullscreen}
        addNewColumn={addNewColumn}
        removeColumn={removeColumn}
        handleMenuItemStatus={handleMenuItemStatus}
        menuCount={menuCount}
        position={position}
      />
    </>
  );
};

MainMenuItemContainer.defaultProps = {
  menus: null,
  allowDropdown: false,
  image: null,
};

MainMenuItemContainer.propTypes = {
  htmlId: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  position: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  allowDropdown: PropTypes.bool,
  menus: PropTypes.arrayOf(PropTypes.any),
  menuCount: PropTypes.number.isRequired,
  editMenu: PropTypes.func.isRequired,
  deleteMenu: PropTypes.func.isRequired,
  addMenu: PropTypes.func.isRequired,
  getLocalKey: PropTypes.func.isRequired,
  updateColumns: PropTypes.func.isRequired,
  handleMenuItemStatus: PropTypes.func.isRequired,
};

export default MainMenuItemContainer;
