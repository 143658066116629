/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";

/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= *//** ======== MODULE STYLES ========== */
import styles from "../css/CategorySelection.module.css";

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/MultiSelect";

/** ========= API SERVICE FUNCTIONS ========= */

const CategorySelection = ({
  name, onChange, categories, selectedCategories, handleTypeaheadChange,
}) => {
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["category"]);

  return (
    <Row className={clsx(styles.block4, "pt-2")}>
      <Col xs="12" sm="7" md="6">
        <div className="pt-2">
          <FormGroup className="m-0">
            <Label className="formTextLabel">Sub categories</Label>
            <Select
              className="formText"
              placeholder={t("category:placeholder_select_categories")}
              name={name}
              id="category"
              value={selectedCategories}
              onChange={onChange}
              typeahead={handleTypeaheadChange}
              typeaheadText="Category name"
              typeaheadPlaceholder="Search ID, name"
              options={
              Array.isArray(categories)
              && categories.map(({ id: value, name: label }) => ({ value, label }))
             }
            />
          </FormGroup>
        </div>
      </Col>
    </Row>
  );
};

CategorySelection.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleTypeaheadChange: PropTypes.func.isRequired,

};

export default CategorySelection;
