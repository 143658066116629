/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Inventory Management
 *
 * @author Amjad Rehman A
 *
 */
import React, { useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import styles from "../css/InventoryManagement.module.css";
import CustomizationTable from "../../../common/components/Table";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Select from "../../../common/components/Select";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import RefreshIcon from "../../../common/assets/images/svg/refresh.svg";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";

const customRows = [
  {
    label: "External SkuId",
    value: "",
    path: "externalSkuId",
    transform: "",
  },
  {
    label: "SkuId",
    value: "",
    path: "skuId",
    transform: "",
  },
  {
    label: "Product Id",
    value: "",
    path: "productId",
    transform: "",
  },
  {
    label: "Available Stock",
    value: "",
    path: "quantity",
    transform: "",
    default: 0,
  },
  {
    label: "Reservation",
    value: "",
    path: "allocatedStock",
    transform: "",
    default: 0,
  },
  {
    label: "ATP",
    value: "",
    path: "availableToPromise",
    transform: "",
    default: 0,
  },
  {
    label: "Status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Created Date",
    value: "",
    path: "createdDate",
    transform: "",
  },
  {
    label: "Updated Date",
    value: "",
    path: "updatedDate",
    transform: "",
  },
];

const InventoryManagement = ({
  getLocalKey,
  storeList,
  onHandleStoreChange,
  inventoryData,
  getPageData,
  page,
  hasMoreData,
  searchTerm,
  handleSearchTerm,
  search,
  handleKeyPress,
  onHandleClear,
  storeId,
  onPerpetualStatusChange,
  onHandleExport,
  isProccessing,
  allocatedStock,
}) => {
  const { t } = useTranslation(["common", "inventory-management"]);

  const [dropdownRoleOpen, setDropdownRoleOpen] = useState(false);
  const hidePagination = page === 0 && !(inventoryData && Array.isArray(inventoryData) && inventoryData.length > 0);
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.productId}`}
          checked={row.perpetual}
          disabled={(row.storeId === "MASTER") ? !row.isVendorFulfillment : true}
          onChange={(e) => onPerpetualStatusChange(!row.perpetual, row.productId, row.skuId, e)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.productId}`} />
      </div>
    );

    return newStatus;
  };
  const customRows = [
    {
      label: "External SkuId",
      value: "",
      path: "externalSkuId",
      transform: "",
    },
    {
      label: "SkuId",
      value: "",
      path: "skuId",
      transform: "",
    },
    {
      label: "Product Id",
      value: "",
      path: "productId",
      transform: "",
    },
    {
      label: "Available stock",
      value: "",
      path: "render",
      render: (row) => {
        if (row) {
          if (row.perpetual) {
            return (
              <p>NA</p>
            );
          }

          return (
            <p>
              {row.quantity}
            </p>
          );
        }
      },
      transform: "",
      default: 0,
    },
    {
      label: "Reservation",
      value: "",
      path: "render",
      render: (row) => {
        if (row) {
          if (row.perpetual) {
            return (
              <p>NA</p>
            );
          }
          return (
            <p>
              {row.allocatedStock}
            </p>
          );
        }
      },
      default: 0,
    },
    {
      label: "ATP",
      value: "",
      path: "render",
      render: (row) => {
        if (row) {
          if (row.perpetual) {
            return (
              <p>NA</p>
            );
          }
          return (
            <p>
              {row.availableToPromise}
            </p>
          );
        }
      },
      default: 0,
    },
    {
      label: "Status",
      value: "",
      path: "render",
      render: (row) => {
        if (row) {
          if (row.perpetual) {
            return (
              <p>InStock</p>
            );
          }

          return (
            <p>
              {row.status}
            </p>
          );
        }
      },
      transform: "",
    },
    {
      label: "Perpetual",
      path: "render",
      render: getStatus,
    },
    {
      label: "Created Date",
      value: "",
      path: "createdDate",
      transform: "",
      type: "date",
    },
    {
      label: "Updated Date",
      value: "",
      path: "updatedDate",
      transform: "",
      type: "date",
    },
  ];

  /**
   *This method is used to toogle dropdown
   *
   */
  const toggleDropdownRole = () => {
    setDropdownRoleOpen(!dropdownRoleOpen);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
   *This method is used to toogle dropdown
   *
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      <Row className="pageHeader common-page-card ">
        <Col xs="12">
          <span className="pageText">Inventory Management</span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, " common-page-card ")}>
        <Col xs="12">
          <Row className="m-0">
            <Col xs="8" className="p-0">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                Manage Inventory
              </span>
            </Col>
          </Row>
          <Row className={clsx(styles.areaActionRight, "m-0 pt-3")}>
            <Col className="mb-2" xs="12" sm="12" md="6" />
            <div className={clsx(styles.searchContainer)}>
              <span
                onClick={() => onHandleClear()}
                className="commonPointer"
                title="Refresh"
              >
                <img
                  width="40"
                  src={RefreshIcon}
                  alt="search category"
                  className={styles.icon}
                />
              </span>
            </div>
            <Col className="mb-2" xs="12" sm="12" md="3">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("inventory-management:label_store_id")}
                </Label>
                <Select
                  className="formTextLabel"
                  placeholder={t(
                    "inventory-management:placeholder_select_store_id",
                  )}
                  name="status"
                  value={storeId}
                  onChange={(e) => onHandleStoreChange(e)}
                  options={[
                    ...[
                      {
                        value: "",
                        label: "Select Store Id",
                      },
                    ],
                    ...(Array.isArray(storeList) ? storeList : []),
                  ]}
                />
              </FormGroup>
            </Col>
            <Col className="mb-2" xs="12" sm md>
              <div className={styles.areaActionRight}>
                <div>
                  <Label className="formTextLabel" for="exampleEmail">
                    {t("inventory-management:label_search")}
                  </Label>
                  <InputGroup
                    className={clsx(styles.search, "color-tertiary-main")}
                  >
                    <Input
                      placeholder={t("inventory-management:placeholder_search")}
                      value={searchTerm}
                      onChange={handleSearchTerm}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                    <InputGroupAddon
                      addonType="append"
                      className="commonPointer bg-color-tertiary-main"
                    >
                      <img
                        src={SearchIcon}
                        alt={t("inventory-management:placeholder_search")}
                        className={styles.icon}
                        onClick={search}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, " common-page-card ")}>
        <Col xs="12">
          <Row className={clsx(styles.block2, "pb-2")}>
            <Col xs="12">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className={clsx(styles.dropdownBtn, "commonDropDownWrapper")}
              >
                {isProccessing ? <span>{t("common:span_loading")}</span> : null}
                <DropdownToggle caret>
                  <span>{t("common:span_export_as")}</span>
                </DropdownToggle>
                <DropdownMenu right className="dropdownMenuWrapper">
                  <div
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className="dropdownMenuItem"
                    onClick={() => onHandleExport("XLSX")}
                  >
                    {t("common:div_export_format_XLSX")}
                  </div>
                  <div
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className="dropdownMenuItem"
                    onClick={() => onHandleExport("CSV")}
                  >
                    {t("common:div_item_export_format_CSV")}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="custom-table">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={inventoryData}
                  isStatusEnable={false}
                  isDeleteEnable={false}
                  isEditEnable={false}
                />
              </div>
              {!hidePagination ? (
                <div
                  className={clsx(
                    styles.pagination,
                    "p-3",
                    "d-flex",
                    "justify-content-end",
                  )}
                >
                  <div
                    className={clsx(
                      styles.pagination,
                      "p-3",
                      "d-flex",
                      "justify-content-end",
                    )}
                  >
                    <div className="d-flex">
                      <span
                        disabled={page <= 0}
                        className={clsx(styles.item, styles.arrowLeft)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={() => !page <= 0 && getPageData(page - 1)}
                      >
                        <span />
                      </span>
                      <span className={styles.item} />
                      <span
                        disabled={!hasMoreData}
                        className={clsx(styles.item, styles.arrowRight)}
                        tabIndex={0}
                        role="button"
                        onKeyPress={() => { }}
                        onClick={() => hasMoreData && getPageData(page + 1)}
                      >
                        <span />
                      </span>
                    </div>
                  </div>
                </div>
              )
                : ""}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

InventoryManagement.propTypes = {

  storeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  onHandleStoreChange: PropTypes.func.isRequired,
  inventoryData: PropTypes.objectOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  searchTerm: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  onPerpetualStatusChange: PropTypes.func.isRequired,
  onHandleExport: PropTypes.func.isRequired,
  isProccessing: PropTypes.bool.isRequired,
};

export default InventoryManagement;
