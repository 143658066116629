/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Anagha
 */
import httpApi from "./httpApi";

/**
 *This method is used to get all Fulfillment Method Configurations
 *
 * @param {*} retailer
 * @param {*} brand
 */
const getFulfillmentMethodConfigurations = async () => {
  const response = await httpApi.get("/v1/fulfillments/configurations");
  if (response && response.data && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};
/**
 *This method is used to get Fulfillment Method Configuration by id
 *
 * @param {*} id
 */
const getFulfillmentMethodConfigurationById = async (id) => {
  const response = await httpApi.get(`/v1/fulfillments/configurations/id/${id}`);
  if (response && response.data && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};
const updateFulfillment = async (
  Fulfillment,
) => {
  const response = await httpApi.put(
    "/v1/fulfillments/configurations",
    Fulfillment,
  );
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to set active flag 
 *
 * @returns
 */
const activateFulfillmentMethod = async (id) => {
  const response = await
    httpApi.patch(`v1/fulfillments/configurations/id/${id}/activate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

const deactivateFulfillmentMethod = async (id) => {
  const response = await
    httpApi.patch(`v1/fulfillments/configurations/id/${id}/deactivate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};


export {
  getFulfillmentMethodConfigurations,
  getFulfillmentMethodConfigurationById,
  updateFulfillment,
  activateFulfillmentMethod,
  deactivateFulfillmentMethod,
};
