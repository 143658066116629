/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima-Mohan
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaDescriptionSelector from "../components/MetaDescriptionSelector";

import { getMetaDesccription } from "../../../api/SeoSettingsService";

const MetaDescriptionSelectorContainer = ({ value, name, onChange }) => {
  const [MetaDescriptionTypes, setDescriptionTypes] = useState([]);
  const [selectedMetaDescriptionType, setSelectedMetaDescriptionType] = useState();

  useEffect(() => {
    /** use to get meta  description by default */
    getMetaDesccription().then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        setDescriptionTypes(response.data);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedMetaDescriptionType(value);
  }, [value]);

  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };
  return (
    <MetaDescriptionSelector
      types={MetaDescriptionTypes}
      selectedMetaDescriptionType={selectedMetaDescriptionType}
      onChange={localOnChange}
      name={name}
    />
  );
};

MetaDescriptionSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default MetaDescriptionSelectorContainer;
