// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n .EditCategory_block2__BJ49T {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.EditCategory_borderRadius2__25y7p {\n  border-radius: 0.125rem !important;\n}\n\n.EditCategory_secondaryColor__OHgVt {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.EditCategory_primaryColor__3ASQy {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.EditCategory_customColorForDelete__2ghYm {\n  background: #FF2C16 !important;\n  border-color: #FF2C16 !important;\n}\n\n.EditCategory_customColorForDelete__2ghYm:hover {\n  background: #F20000 !important;\n  border-color: #FF2C16 !important;\n}\n\n\n.EditCategory_btn__2r7tT {\n  min-width: 7rem;\n}\n\n\n/* ========== FORM AREA ============ */\n.EditCategory_block3__1MTRg {\n  padding: 1.25rem;\n}\n\n/* ============= PRODUCT TITLE ========= */\n.EditCategory_titleTextContainer__qL9mQ {\n  padding: 0 2.1rem 0.125rem 2.1rem;\n  border-bottom: 1px solid #edf0f5;\n  margin: 0 -0.938rem;\n}\n\n.EditCategory_titleTextContainer__qL9mQ .EditCategory_pageTitleText__3eq18{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n \n\n\n/** ========= PARENT CATEGORY  ================ **/\n.EditCategory_block5__2Q8kq {\npadding: 1.25rem;\n}\n\n.EditCategory_mw25__2oHj2 {\n  min-width: 25%;\n}", ""]);
// Exports
exports.locals = {
	"block2": "EditCategory_block2__BJ49T",
	"borderRadius2": "EditCategory_borderRadius2__25y7p",
	"secondaryColor": "EditCategory_secondaryColor__OHgVt",
	"primaryColor": "EditCategory_primaryColor__3ASQy",
	"customColorForDelete": "EditCategory_customColorForDelete__2ghYm",
	"btn": "EditCategory_btn__2r7tT",
	"block3": "EditCategory_block3__1MTRg",
	"titleTextContainer": "EditCategory_titleTextContainer__qL9mQ",
	"pageTitleText": "EditCategory_pageTitleText__3eq18",
	"block5": "EditCategory_block5__2Q8kq",
	"mw25": "EditCategory_mw25__2oHj2"
};
module.exports = exports;
