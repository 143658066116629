/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * AnimationAssetsEditor
 *
 * @author Naseef O
 *
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import styles from "../css/WidgetContentEditor.module.css";
import ImageCropper from "../../../common/components/imageCropper/Cropper";
import { uploadImage } from "../../../api/pageContentServices";
import ImageSelection from "./ImageSelection";
import { getAspectRatio } from "../../../common/utils/layoutUtils";

const AnimationAssetsEditor = (props) => {
  const {
    content,
    handleChangeFile,
    modalOpen,
    setModalOpen,
    handleUploadImage,
    isUploading,
    setIsUploading,
    handleCloseModal,
    media,
    selectedMediaType,
    handleRemoveImg,
    widgetConfig,
    selectedAnimationSet,
    handleChangeAnimationAsset,
    animationSets,
    selectedShape,
    handleChangeShape,
    mediaOption,
    handleMediaOption,
    getBreakPoints,
  } = props;

  const [animationAssetsOpen, setAnimationAssetsOpen] = useState(false);
  const [shapeOpen, setShapeOpen] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);


  /**
   *This method is used to toggle dropdown
   *
   */
  const toggleMediaType = () => {
    setMediaOpen(!mediaOpen);
  };


  /**
   *This method is used to toggle dropdown
   *
   */
  const toggleDropdownAnimationAssets = () => {
    setAnimationAssetsOpen(!animationAssetsOpen);
  };

  /**
   *This method is used to toggle dropdown
   *
   */
  const toggleDropdownShapes = () => {
    setShapeOpen(!shapeOpen);
  };

  const newAspectRatio = {};
  if (widgetConfig && widgetConfig.propertyConfig.imageUrl) {
    const { imageUrl } = widgetConfig.propertyConfig;
    if (mediaOption === "Animated banner") {
      newAspectRatio.defaultMediaUrl = imageUrl.aspectRatio.defaultMediaUrl;
    } else {
      newAspectRatio.largeMediaUrl = imageUrl.aspectRatio.largeMediaUrl;
      newAspectRatio.smallMediaUrl = imageUrl.aspectRatio.smallMediaUrl;
    }
  }
  return (
    <>
      <Row className={styles.block3}>
        <Col xs="4">
          <FormGroup className="w-100">
            <Dropdown
              isOpen={mediaOpen}
              toggle={toggleMediaType}
              className={clsx(styles.widgetContentEditorDropdownBtn, "commonDropDownWrapper")}
            >
              <DropdownToggle caret>
                <span>
                  {mediaOption}
                </span>
              </DropdownToggle>
              <DropdownMenu
                className={clsx(styles.widgetContentEditorDropdownMenu, "dropdownMenuWrapper")}
              >
                {["Banner", "Animated banner"].map((item) => (
                  <div
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className="dropdownMenuItem"
                    onClick={() => {
                      handleMediaOption(item); toggleMediaType();
                    }}
                  >
                    {item}
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
        </Col>
        {mediaOption === "Animated banner" && (
          <>
            <Col xs="4">
              <FormGroup className="w-100">
                <Dropdown
                  isOpen={animationAssetsOpen}
                  toggle={toggleDropdownAnimationAssets}
                  className={clsx(styles.widgetContentEditorDropdownBtn, "commonDropDownWrapper")}
                >
                  <DropdownToggle caret>
                    <span>
                      {(selectedAnimationSet && selectedAnimationSet.name) || "Assets"}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu
                    className={clsx(styles.widgetContentEditorDropdownMenu, "dropdownMenuWrapper")}
                  >
                    {animationSets
                      && Array.isArray(animationSets.content)
                      && animationSets.content.length > 0
                      && animationSets.content.map((item) => (
                        <div
                          onKeyPress={() => { }}
                          role="button"
                          tabIndex="0"
                          className="dropdownMenuItem"
                          onClick={() => {
                            handleChangeAnimationAsset(item); toggleDropdownAnimationAssets();
                          }}
                        >
                          {item.name}
                        </div>
                      ))}
                  </DropdownMenu>
                </Dropdown>
              </FormGroup>
            </Col>
            <Col xs="4">
              <FormGroup className="w-100">
                <Dropdown
                  isOpen={shapeOpen}
                  toggle={toggleDropdownShapes}
                  className={clsx(styles.widgetContentEditorDropdownBtn, "commonDropDownWrapper")}
                >
                  <DropdownToggle caret>
                    <span>
                      {(selectedShape && selectedShape) || "Shapes"}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu
                    className={clsx(styles.widgetContentEditorDropdownMenu, "dropdownMenuWrapper")}
                  >
                    {["blast", "splat", "spiral", "scribble", "pebble", "cloud"].map((item) => (
                      <div
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        className="dropdownMenuItem"
                        onClick={() => {
                          handleChangeShape(item); toggleDropdownShapes();
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </FormGroup>
            </Col>
          </>
        )}
      </Row>
      <Row className={styles.block3}>
        {widgetConfig && widgetConfig.propertyConfig.imageUrl
          && (
            <>
              {widgetConfig.propertyConfig.imageUrl && (
                <>
                  {Object.keys(
                    newAspectRatio,
                  ).map((type) => (
                    <Col className="d-flex col-auto justify-content-start align-items-center">
                      <FormGroup>
                        <Label className="formTextLabel pt-1">{getBreakPoints(type)}</Label>
                        <ImageSelection
                          handleChangeFile={handleChangeFile}
                          imageUrl={media[type]}
                          modalOpen={modalOpen}
                          type={type}
                          setModalOpen={setModalOpen}
                          handleUpload={handleUploadImage}
                          imageUploadApi={uploadImage}
                          isUploading={isUploading}
                          setIsUploading={setIsUploading}
                          handleCloseModal={handleCloseModal}
                          handleRemoveImage={handleRemoveImg}
                        />
                      </FormGroup>
                    </Col>
                  ))}
                  <ImageCropper
                    imageUrl={content.imageUrl}
                    modalOpen={modalOpen}
                    aspect={
                      getAspectRatio(
                        widgetConfig.propertyConfig.imageUrl.aspectRatio,
                        selectedMediaType,
                      )
                    }
                    setModalOpen={setModalOpen}
                    handleUpload={handleUploadImage}
                    imageUploadApi={uploadImage}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    handleCloseModal={handleCloseModal}
                  />
                </>
              )}
            </>
          )}
      </Row>
    </>
  );
};


AnimationAssetsEditor.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  setIsUploading: PropTypes.func.isRequired,
  handleChangeFile: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  media: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedMediaType: PropTypes.string.isRequired,
  handleRemoveImg: PropTypes.func.isRequired,
  selectedAnimationSet: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeAnimationAsset: PropTypes.func.isRequired,
  selectedShape: PropTypes.string.isRequired,
  handleChangeShape: PropTypes.func.isRequired,
  mediaOption: PropTypes.string.isRequired,
  handleMediaOption: PropTypes.func.isRequired,
  getBreakPoints: PropTypes.func.isRequired,
  widgetConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  animationSets: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AnimationAssetsEditor;
