/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima-Mohan
 */


import React from "react";
import PropTypes from "prop-types";

import Select from "../../../common/components/Select";


const MetaKeywordSelector = ({
  name, onChange, selectedMetaKeyWordType, types,
}) => (
    <Select
      className="formText"
      placeholder="Select Meta Keyword"
      name={name}
      id={name}
      value={selectedMetaKeyWordType}
      onChange={onChange}
      options={[
        {
          value: "", disabled: true, selected: true, label: "Select Meta Keyword",
        },
        ...(
          Array.isArray(types)
          && types.map((type) => ({ value: type, label: type }))
        ),
      ]}
    />
  );

MetaKeywordSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedMetaKeyWordType: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MetaKeywordSelector;
