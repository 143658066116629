/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React from "react";
import PropTypes from "prop-types";
import Select from "../../../common/components/MultiSelect/MultiSelect";


const ProductOptionSelector = (props) => {
  const {
    name,
    onChange,
    productOptionList,
    selectedProductOptions,
    invalid,
  } = props;

  return (
    <Select
      className="formText"
      name={name}
      id="productOptions"
      value={selectedProductOptions}
      onChange={onChange}
      invalid={invalid}
      options={
        Array.isArray(productOptionList)
        && productOptionList.map(({ id: value, name: label }) => ({ value, label }))
      }
    />
  );
};

ProductOptionSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  productOptionList: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedProductOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ProductOptionSelector;
