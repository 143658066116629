/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import CustomMenuItemCardForm from "../components/CustomMenuItemCardForm";
import CustomMenuItemBasicForm from "../components/CustomMenuItemBasicForm";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import ImageCropper from "../../../common/components/imageCropper/Cropper";

/** ========= API SERVICE FUNCTIONS ========= */
import { uploadImage } from "../../../api/pageContentServices";
import CustomMenuItemImageForm from "../components/CustomMenuItemImageForm";

const CustomMenuItemFormContainer = (props) => {
  const { onSave, value: promotion, onClear } = props;
  const aspectRatio = 375 / 500;
  /** local states */
  const [form, setForm] = useState({
    id: Math.random().toString(36),
    type: "promotion",
    name: "",
    description: "",
    button: "",
    url: "",
    image: "",
    file: null,
    displayType: null,
  });
  const [imageUrl, setImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedCustomType, setSelectedCustomType] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (promotion) {
      setForm(promotion);
    }
  }, [promotion]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const [error, setError] = useState({
    name: "",
    url: "",
    image: "",
  });
  /**
   * This method is used to check the promotion object is valid or not
   * @param {Object} promotion
   */
  const isValidPromotion = () => {
    let isValid = true;
    if (selectedCustomType) {
      if (selectedCustomType.type === "BasicItem") {
        if (form.name === "") {
          setError({ ...error, name: "Name is required" });
          isValid = false;
        } else {
          setError({ ...error, name: "" });
        }
      } else if (selectedCustomType.type === "CardItem") {
        if (!form.name) {
          setError({ ...error, name: "Name is required" });
          isValid = false;
        } else if (!form.image) {
          setError({ ...error, image: "image is required", name: "" });
          isValid = false;
        } else {
          setError({ ...error, image: "", name: "" });
        }
      } else if (selectedCustomType.type === "ImageItem") {
        if (form.image === "") {
          setError({ ...error, image: "Image is required" });
          isValid = false;
        } else {
          setError({ ...error, image: "" });
        }
      }
    }
    return isValid;
  };

  /**
 * This method is used to change form filed input
 * @param {Event} event
 */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
 * This function is used to select image
 * @param {Event} event
 */
  const handleImageSelection = (event) => {
    if (event.target) {
      const { target } = event;
      const { files } = target;
      if (files[0]) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          setForm({
            ...form, file, image: e.target.result,
          });
          setImageUrl(e.target.result);
          setModalOpen(true);
          if (target) {
            target.files = null;
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  /**
 * This method is used to set form to default values
 */
  const setDefaultForm = () => {
    setForm({
      name: "",
      description: "",
      button: "",
      url: "",
      image: "",
      file: null,
      displayType: null,
    });
  };
  /**
 * This method is used to trigger form submit
 */
  const onLocalSave = async () => {
    if (isValidPromotion(form)) {
      onSave(form);
      setDefaultForm();
    } else {
      console.warn("Promotion is invalid.");
    }
  };

  /**
 * This method is used to clear form
 */
  const onLocalClear = () => {
    setDefaultForm();
    if (onClear) onClear();
  };


  const handleUploadImage = (newMedia) => {
    handleChange("image", (newMedia && newMedia.largeMediaUrl) || null);
  };

  const handleCloseModal = () => {
    setImageUrl("");
    setModalOpen(false);
    setForm({
      ...form, image: null,
    });
  };


  /**
   *this method is used to handle toggle dropdown
   *
   */
  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
   * This method is used to handle select custom type
   *
   * @param {*} type
   */
  const handleSelectCustomType = (data) => {
    setSelectedCustomType(data);
    setDropdownOpen(!dropdownOpen);
    setForm({
      ...form,
      displayType: data.type,
    });
    setError({
      name: "",
      url: "",
      image: "",
    });
  };

  // Custom sub menu item's  display type
  const displayTypes = [
    { name: "Basic", type: "BasicItem" },
    { name: "Card", type: "CardItem" },
    { name: "Image", type: "ImageItem" },
  ];
  return (
    <>

      <Dropdown
        isOpen={dropdownOpen}
        toggle={handleDropdown}
        className="commonDropDownWrapper pb-3"
      >
        <DropdownToggle caret>
          <span>
            {selectedCustomType ? selectedCustomType.name : "Select display type"}
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdownMenuWrapper">
          {displayTypes.map((item) => (
            <div
              role="presentation"
              className="dropdownMenuItem"
              onClick={() => handleSelectCustomType(item)}
            >
              {item.name}
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
      {selectedCustomType && selectedCustomType.type === "CardItem"
        && (
          <CustomMenuItemCardForm
            name={form.name}
            description={form.description}
            button={form.button}
            url={form.url}
            image={form.image}
            handleChange={handleChange}
            handleFormChange={handleFormChange}
            handleImageSelection={handleImageSelection}
            onSave={onLocalSave}
            onClear={onLocalClear}
            error={error}
          />
        )}
      {selectedCustomType && selectedCustomType.type === "BasicItem"
        && (
          <CustomMenuItemBasicForm
            name={form.name}
            description={form.description}
            button={form.button}
            url={form.url}
            image={form.image}
            handleChange={handleChange}
            handleFormChange={handleFormChange}
            handleImageSelection={handleImageSelection}
            onSave={onLocalSave}
            onClear={onLocalClear}
            error={error}
          />
        )}
      {selectedCustomType && selectedCustomType.type === "ImageItem"
        && (
          <CustomMenuItemImageForm
            name={form.name}
            description={form.description}
            button={form.button}
            url={form.url}
            image={form.image}
            handleChange={handleChange}
            handleFormChange={handleFormChange}
            handleImageSelection={handleImageSelection}
            onSave={onLocalSave}
            onClear={onLocalClear}
            error={error}
          />
        )}
      <ImageCropper
        imageUrl={imageUrl}
        modalOpen={modalOpen}
        aspect={aspectRatio}
        setModalOpen={setModalOpen}
        handleUpload={handleUploadImage}
        imageUploadApi={uploadImage}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

CustomMenuItemFormContainer.defaultProps = {
  value: null,
  onClear: null,
};

CustomMenuItemFormContainer.propTypes = {
  value: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func.isRequired,
  onClear: PropTypes.func,
};

export default CustomMenuItemFormContainer;
