/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Customization table
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import deleteIcon from "../../../common/assets/images/svg/delete.svg";
import Spinner from "../../../common/core/Spinner";
import Table from "../../../common/core/Table";
import styles from "../css/Table.module.css";
import transformers from "../../../common/utils/transformers";
import Row from "../../../common/core/Row";

const ItemTable = (props) => {
  const {
    handleDelete,
    isLoading,
    selectedIndex,
    customizationData,
  } = props;

  /**
   *This method is used to get a value from an item
   *
   * @param {object} row
   * @param {object} rowItemData
   * @returns
   */
  const getItem = (row, rowItemData) => {
    let data = get(row, rowItemData.path);
    if (rowItemData.transform && rowItemData.transform !== "") {
      data = transformers[rowItemData.transform](row, rowItemData.path);
    }
    return data;
  };

  const pageCustomizationRows = [
    {
      label: "Id",
      value: "",
      path: "id",
    },
    {
      label: "name",
      value: "",
      path: "name",
    },
  ];

  return (
    <Table borderless>
      <thead>
        <tr>
          <th>Order</th>
          <th>Preview</th>
          {pageCustomizationRows.map((row) => <th>{row.label}</th>)}
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {customizationData
          && customizationData.length > 0 ? customizationData.map((rowItem, index) => (
            <tr>
              <th scope="row" width="10%">
                <span className="p-2">{index + 1}</span>
              </th>
              <td width="10%">
                {rowItem.imageUrl ? <img src={getItem(rowItem, { path: "imageUrl" })} alt="preview" width="60px" /> : "No image"}
              </td>
              {pageCustomizationRows.map((row) => <td width="10%">{getItem(rowItem, row)}</td>)}
              <td width="10%">
                {isLoading && (selectedIndex === index) ? (
                  <Spinner className={styles.spinnerWrapper} />
                )
                  : (
                    <img
                      src={deleteIcon}
                      className="commonPointer"
                      alt="drag indicator icon"
                      width="20px"
                      role="presentation"
                      onClick={() => handleDelete(rowItem.id, index)}
                    />
                  )}
              </td>
            </tr>
          ))
          : <Row className="justify-content-center">No data</Row>}
      </tbody>
    </Table>
  );
};

ItemTable.propTypes = {
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default ItemTable;
