/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../core/Input";

const InputWrapper = ({
  className,
  placeholder,
  name,
  defaultValue,
  onChange,
  innerRef,
  invalid,
  setValue,
}) => {
  useEffect(() => {
    setValue(`${name}`, defaultValue);
  }, [defaultValue]);

  return (
    <Input
      className={className}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      innerRef={innerRef}
      invalid={invalid}
    />
  );
};


InputWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  innerRef: PropTypes.objectOf(PropTypes.any).isRequired,
  invalid: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};
export default InputWrapper;
