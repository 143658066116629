/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import EditPaymentMethod from "../components/EditPaymentMethod";
import { getPaymentMethodConfigurationById, getPaymentTypes, getPaymentPriceStrategies,
   updatePaymentMethodConfiguration } from "../../../api/paymentManagementServices";


const EditPaymentMethodContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [id, setId] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState();
  const [paymentPriceStrategies, setPaymentPriceStrategies] = useState();
  const [form, setForm] = useState({
    name: "",
    description: "",
    paymentType: "",
    creditLimit: "",
    priceStrategy: "",
    paymentFee: "",
  });

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      getPaymentMethodConfigurationById(params.id).then((response) => {
        if (response && response.data) {
          const { data } = response;
          setForm(data);
        }
      });
    }
    setId(params.id);

    getPaymentTypes().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setPaymentTypes(data);
      }
    });

    getPaymentPriceStrategies().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setPaymentPriceStrategies(data);
      }
    });
  }, []);


  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const onHandleCancel = () => {
    history.push("/operations/payment-method");
  };

  const onHandleSave = async () => {
    updatePaymentMethodConfiguration(form).then((response) => {
      if (response && response.data) {
        history.push("/operations/payment-method");
      }
    });
  };
  
  return (
    <EditPaymentMethod
      handleFormChange={handleFormChange}
      form={form}
      onHandleSave={onHandleSave}
      onHandleCancel={onHandleCancel}
      handleChange={handleChange}
    />
  );
};

export default EditPaymentMethodContainer;
