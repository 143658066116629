import React from "react";
import PropTypes from "prop-types";
import Modal from "../core/Modal";
import ModalBody from "../core/ModalBody";
import ModalFooter from "../core/ModalFooter";

const ConfirmationModal = (props) => {
  const {
    isOpen,
    toggleOpen,
    togglClose,
    handleConfirm,
    content,
  } = props;

  return (
    <>
      <Modal modalClassName="modalWrapper" isOpen={isOpen} toggle={toggleOpen}>
        <ModalBody>
          {content}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="secondaryButton" onClick={togglClose}>Cancel</button>
          <button type="button" className="primaryButton" onClick={handleConfirm}>Confirm</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  togglClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default ConfirmationModal;
