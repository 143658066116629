// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n .ParentCategory_block2__upwTC {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.ParentCategory_borderRadius2__25Muq {\n  border-radius: 0.125rem !important;\n}\n\n.ParentCategory_secondaryColor__3ohsK {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.ParentCategory_primaryColor__canVc {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.ParentCategory_btn__JP1f1 {\n  min-width: 7rem;\n} \n\n/* ============= PRODUCT TITLE ========= */\n.ParentCategory_titleTextContainer__tOMwm {\n  padding: 0 2.1rem 0.125rem 2.1rem;\n  border-bottom: 1px solid #edf0f5;\n  margin: 0 -0.938rem;\n}\n\n/** ========= PARENT CATEGORY  ================ **/\n\n.ParentCategory_dropdownMenu__12qGN {\n  max-height: 60vh;\n  overflow-y: auto;\n  width: 100%;\n  background-color: #fff;\n}\n\n.ParentCategory_dropdownMenu__12qGN::-webkit-scrollbar-track {\n  background: #fff !important;\n}\n\n.ParentCategory_dropdown__3i34g > button {\n  background: transparent !important;\n  text-align: left !important;\n  color: black !important;\n  display: flex !important;\n  align-items: center !important;\n}\n\n.ParentCategory_dropdown__3i34g > button .ParentCategory_clear__2FwOP {\n  margin-left: auto;\n  display: flex;\n  padding: 0.125rem;\n  border: 1px solid;\n  border-radius: 2px;\n  border-color: #b7b2b2;\n}\n\n.ParentCategory_dropdown__3i34g > button .ParentCategory_clear__2FwOP> img {\n  width: 20px;\n  height: 20px;\n}", ""]);
// Exports
exports.locals = {
	"block2": "ParentCategory_block2__upwTC",
	"borderRadius2": "ParentCategory_borderRadius2__25Muq",
	"secondaryColor": "ParentCategory_secondaryColor__3ohsK",
	"primaryColor": "ParentCategory_primaryColor__canVc",
	"btn": "ParentCategory_btn__JP1f1",
	"titleTextContainer": "ParentCategory_titleTextContainer__tOMwm",
	"dropdownMenu": "ParentCategory_dropdownMenu__12qGN",
	"dropdown": "ParentCategory_dropdown__3i34g",
	"clear": "ParentCategory_clear__2FwOP"
};
module.exports = exports;
