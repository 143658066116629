// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Anokh J Ajai\n */\n\n .ProductBundle_productBundleButtons__2P6Uv {\n   display: flex;\n   align-items: center;\n }\n\n .ProductBundle_productBundleButtons__2P6Uv .ProductBundle_btn__25pFS{\n  border : 1px solid #17a2b8;\n  border-radius: 22px;\n  width :32px;\n  margin-right: 20px;\n  cursor: pointer;\n  margin-top: 10px;\n }  ", ""]);
// Exports
exports.locals = {
	"productBundleButtons": "ProductBundle_productBundleButtons__2P6Uv",
	"btn": "ProductBundle_btn__25pFS"
};
module.exports = exports;
