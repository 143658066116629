// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/calander.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n \n .styles_datePickerComponent__3DsAM .styles_datePicker__19V3I { \n   z-index: 99;\n   background: #fff;\n   padding: 10px;\n   border: 1px solid #ced4da;\n   border-radius: 2px;\n   min-width: 280px;\n   margin-top: 0.125rem;\n }\n\n .styles_datePickerComponent__3DsAM input {\n   box-sizing: border-box;\n   font-size: 16px;\n   background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n   background-position: 4px 0px;\n   background-repeat: no-repeat;\n   padding: 12px 10px 12px 45px;\n   transition: width 0.4s ease-in-out;\n   background-size: 1.6rem 100%;\n   cursor: pointer !important;\n   border-radius: 2px;\n   overflow: hidden;\n }", ""]);
// Exports
exports.locals = {
	"datePickerComponent": "styles_datePickerComponent__3DsAM",
	"datePicker": "styles_datePicker__19V3I"
};
module.exports = exports;
