/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * StaticPages
 *
 * @author Naseef O
 *
 */

import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Alert from "../../../common/core/Alert";
import Button from "../../../common/core/Button";
import styles from "../css/StaticPages.module.css";
import PagesTableContainer from "../containers/PagesTableContainer";

const StaticPages = (props) => {
  const { t } = useTranslation(["common"]);
  const {
    customPages,
    handleChangeStatus,
    page,
    onHandleSearch,
    hasMoreData,
    message,
    handlePushToLive,
    pushToLiveEnabled,
    pushToLiveButtonEnabled,
    getReplicationStatus,
    getCustomPages,
    handleNewPage,
  } = props;
  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className={clsx(styles.block1)}>
        <Col xs="12">
          <Row className="pageHeader">
            <Col xs="12" md="6" lg="3">
              <span className="pageText">Static Pages</span>
            </Col>
            {pushToLiveEnabled && (
              <Col xs="12" md="3">
                <Button
                  className={clsx(styles.liveBtn, " bg-color-tertiary-main ")}
                  onClick={() => handlePushToLive()}
                  disabled={!pushToLiveButtonEnabled}
                >
                  {t("common:button_push_to_live")}
                </Button>
              </Col>
            )}
            {pushToLiveEnabled && (
              <Col xs="12" md="3" lg="3" className={styles.liveBtnWrapper}>
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "mx-0 mb-3")}>
        <Col xs="12">
          <Row>
            <Col xs="12" className="d-flex align-items-center justify-content-end p-3">
              <Button
                onClick={() => handleNewPage()}
                className="bg-color-tertiary-main  primaryButton"
              >
                Add
              </Button>
            </Col>
          </Row>
          <Row className="custom-table m-0 ">
            <PagesTableContainer
              customPages={customPages}
              handleChangeStatus={handleChangeStatus}
              handlePushToLive={handlePushToLive}
              pushToLiveButtonEnabled={pushToLiveButtonEnabled}
              getReplicationStatus={getReplicationStatus}
              getCustomPages={getCustomPages}
            />
          </Row>
          <div
            className={clsx(
              styles.pagination,
              "p-3",
              "d-flex",
              "justify-content-end",
            )}
          >
            <div className="d-flex">
              <span
                disabled={page <= 0}
                className={clsx(styles.item, styles.arrowLeft)}
                tabIndex={0}
                role="button"
                onKeyPress={() => { }}
                onClick={() => page > 0 && onHandleSearch(-1)}
              >
                <span />
              </span>
              <span className={styles.item} />
              <span
                disabled={!hasMoreData}
                className={clsx(styles.item, styles.arrowRight)}
                tabIndex={0}
                role="button"
                onKeyPress={() => { }}
                onClick={() => hasMoreData && onHandleSearch(1)}
              >
                <span />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

StaticPages.propTypes = {
  customPages: PropTypes.arrayOf(PropTypes.any).isRequired,
  page: PropTypes.number.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  getCustomPages: PropTypes.func.isRequired,
  handleNewPage: PropTypes.func.isRequired,
};

export default StaticPages;
