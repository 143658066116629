/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/MultiSelect";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= MODULE STYLES ========= */
import styles from "../css/SimpleBrandSelector.module.css";


const SimpleBrandSelector = ({
  name, onChange, brands, selectedBrands, handleTypeaheadChange, disabled,
}) => {
  /** internationalization using i18n from common and brand  */
  const { t } = useTranslation(["brand"]);

  return (
    <Select
      className="formText"
      typeaheadPlaceholder={t("category:placeholder_select_Brands")}
      name={name}
      id="brand"
      value={selectedBrands}
      onChange={onChange}
      typeahead={handleTypeaheadChange}
      typeaheadText="Brand name"
      chipClass={styles.chipWidth}
      options={
        Array.isArray(brands)
        && brands.map(({ id: value, name: label }) => ({ value, label }))
      }
      disabled={disabled}
    />
  );
};

SimpleBrandSelector.defaultProps = {
  brands: null,
};

SimpleBrandSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any),
  selectedBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleTypeaheadChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SimpleBrandSelector;
