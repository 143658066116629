/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima-Mohan
 */


import React from "react";
import PropTypes from "prop-types";

import Select from "../../../common/components/Select";


const MetaTitleSelector = ({
  name, onChange, types, selectedMetaTitleType,
}) =>  (
    <Select
      className="formText"
      placeholder="Select Meta Title"
      name={name}
      id={name}
      value={selectedMetaTitleType}
      onChange={onChange}
      options={[
        {
          value: "", disabled: true, selected: true, label: "Select Meta Title",
        },
        ...(
          Array.isArray(types)
          && types.map((type) => ({ value: type, label: type }))
        ),
      ]}
    />
  );

MetaTitleSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedMetaTitleType: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MetaTitleSelector;
