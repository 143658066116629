/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomizationTable from "../../../common/components/Table";

import Row from "../../../common/core/Row";
import leftArrowLong from "../../../common/assets/images/svg/left-arrow-long.svg";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";

import styles from "../css/JobConfiguration.module.css";

const customRows = [
  {
    label: "Id",
    value: "",
    path: "id",
    transform: "",
  },
  {
    label: "JobId",
    value: "",
    path: "jobId",
    transform: "",
    //   type: "date"
  },
  {
    label: "Name",
    value: "",
    path: "name",
    transform: "",
  },
  {
    label: "Status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Start Date",
    value: "",
    path: "startDate",
    transform: "",
    type: "date",
  },
  {
    label: "End Date",
    value: "",
    path: "endDate",
    transform: "",
    type: "date",
  },
  {
    label: "Read Count",
    value: "",
    path: "readCount",
    transform: "",
    default: 0,
  },
  {
    label: "Write Count",
    value: "",
    path: "writeCount",
    transform: "",
    default: 0,
  },
  {
    label: "Read Skip Count",
    value: "",
    path: "readSkipCount",
    transform: "",
    default: 0,
  },
  {
    label: "Write Skip Count",
    value: "",
    path: "writeSkipCount",
    transform: "",
    default: 0,
  },
];

const JobAuditDetails = ({
  jobAuditDetails,
  jobHistoryData,
  canModify,
  onHandleBack,
  onClickRowStep,
  formatDate,
}) => {
  const { t } = useTranslation(["common", "job-configuration"]);

  return (
    <Row className={clsx(styles.block1, "common-page-card ")}>
      <Col xs="12">
        {canModify ? (
          <Row className="pb-4">
            <Col xs="12">
              <span className={clsx(styles.leftArrow, "mr-4")}>
                <img
                  src={leftArrowLong}
                  alt="click left"
                  width="30"
                  height="auto"
                  onClick={() => onHandleBack(null)}
                />
              </span>
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("job-configuration:span_job_step_details")}
              </span>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_name")}</b>
              </Label>
              <p>{jobHistoryData.name}</p>
            </FormGroup>
          </Col>

          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_id")}</b>
              </Label>
              <p>{jobHistoryData.id}</p>
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_status")}</b>
              </Label>
              <p>{jobHistoryData.status}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_start_date")}</b>
              </Label>
              <p>{formatDate(jobHistoryData.startDate)}</p>
            </FormGroup>
          </Col>

          <Col xs="6">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_end_date")}</b>
              </Label>
              <p>{formatDate(jobHistoryData.endDate)}</p>
            </FormGroup>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col xs="12">
            <Label className={styles.sectionHead}>Step Audit List</Label>
            <div className="custom-table">
              <CustomizationTable
                customRows={customRows}
                customizationData={jobAuditDetails}
                isStatusEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
                onClickRow={onClickRowStep}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

JobAuditDetails.propTypes = {
  onHandleBack: PropTypes.func.isRequired,
  jobAuditDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  canModify: PropTypes.bool.isRequired,
  jobHistoryData: PropTypes.arrayOf.isRequired,
};

export default JobAuditDetails;
