// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Amjad Rehman A\n */\n\n.TaxLocation_borderRadius2__cTvC3 {\n  border-radius: 0.125rem !important;\n}\n\n/** =========== ACTION AREA STYLES ============== **/\n.TaxLocation_areaActionRight__180Mz {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.TaxLocation_secondaryColor__3SjRJ,\n.TaxLocation_secondaryColor__3SjRJ:focus,\n.TaxLocation_secondaryColor__3SjRJ:active {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.TaxLocation_secondaryColor__3SjRJ:hover {\n  background-color: #919191 !important;\n  border-color: #919191 !important;\n}\n\n.TaxLocation_border__bky5B {\n  border: 1px solid #c4c4c4;\n}\n\n.TaxLocation_tableWrapper__1Faa0{\n  min-height: calc(100vh - 365px);\n}\n\n.TaxLocation_pointer__Erdiz{\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"borderRadius2": "TaxLocation_borderRadius2__cTvC3",
	"areaActionRight": "TaxLocation_areaActionRight__180Mz",
	"secondaryColor": "TaxLocation_secondaryColor__3SjRJ",
	"border": "TaxLocation_border__bky5B",
	"tableWrapper": "TaxLocation_tableWrapper__1Faa0",
	"pointer": "TaxLocation_pointer__Erdiz"
};
module.exports = exports;
