/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima-Mohan
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaKeywordSelector from "../components/MetaKeywordSelector";

import { getMetaKeyWord } from "../../../api/SeoSettingsService";

const MetaKeywordSelectorContainer = ({ value, name, onChange }) => {
  const [MetaKeyWordTypes, setMetaKeyWordTypes] = useState([]);
  const [selectedMetaKeyWordType, setSelectedMetaKeyWordTypes] = useState();

  useEffect(() => {
    /** use to get meta  keyword by default */
    getMetaKeyWord().then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        setMetaKeyWordTypes(response.data);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedMetaKeyWordTypes(value);
  }, [value]);

  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };
  return (
    <MetaKeywordSelector
      types={MetaKeyWordTypes}
      selectedMetaKeyWordType={selectedMetaKeyWordType}
      onChange={localOnChange}
      name={name}
    />
  );
};

MetaKeywordSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default MetaKeywordSelectorContainer;
