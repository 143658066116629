/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "../css/JobConfiguration.module.css";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Badge from "../../../common/core/Badge";

const NewJobConfiguration = ({ setSelectedTab, getColor, tabView }) => {
  const { t } = useTranslation(["common", "job-configuration"]);
  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">
            {t("job-configuration:span_job_configuration_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <span
            className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
          >
            {t("job-configuration:span_new_job")}
          </span>
        </Col>
        <Col xs="12">
          <Badge
            color={getColor("JOB_BASIC")}
            className="p-2 m-2"
            onClick={() => setSelectedTab("JOB_BASIC")}
          >
            {t("job-configuration:span_basic_job_configuration")}
          </Badge>
          <Badge
            color={getColor("JOB_TASK")}
            className="p-2 m-2"
            onClick={() => setSelectedTab("JOB_TASK")}
          >
            {t("job-configuration:span_job_task_configuration")}
          </Badge>
          <Badge
            color={getColor("JOB_REVIEW")}
            className="p-2 m-2"
            onClick={() => setSelectedTab("JOB_REVIEW")}
          >
            {t("job-configuration:span_job_review")}
          </Badge>
        </Col>
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">{tabView}</Col>
      </Row>
    </>
  );
};

NewJobConfiguration.propTypes = {
  getColor: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  tabView: PropTypes.string.isRequired,
};
export default NewJobConfiguration;
