/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import CampaignStepOneView from "../components/CampaignStepOneView";

/** ========= CUSTOM COMPONENTS ========= */

/** ========= API SERVICE FUNCTIONS ========= */
import { createCampaign, updateCampaign } from "../../../api/campaignServices";

const CampaignStepOne = (props) => {
  const {
    nextStep, handleChange: parentHandleChange, basicInfo, translatableFileds, selectedLanguage,
  } = props;
  const history = useHistory();

  /** local states */
  const [form, setForm] = useState({
    id: null,
    name: "",
    description: "",
    active: 1,
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    indefinite: false,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);

  useEffect(() => {
    if (basicInfo) {
      if (basicInfo.indefinite) {
        setForm((oldState) => ({
          ...oldState,
          ...basicInfo,
          startDate: moment(basicInfo.startDate).startOf("day"),
          endDate: null,
        }));
      } else {
        setForm((oldState) => ({
          ...oldState,
          ...basicInfo,
          startDate: moment(basicInfo.startDate).startOf("day"),
          endDate: basicInfo.endDate
            ? moment(basicInfo.endDate).endOf("day")
            : null,
        }));
      }
    }
  }, [basicInfo]);
  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This function is sued to clear form value
   */
  const formCancelHandler = () => {
    setForm({
      id: null,
      name: "",
      description: "",
      active: 1,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      indefinite: false,
      enabled: true,
    });
    history.push("/marketing/campaign");
  };

  /**
   * This function is used to get submit method create or update
   */
  const getApiFunction = () => (form.id ? updateCampaign : createCampaign);

  /**
   * This function is used to submit the form
   */
  const formSubmitHandler = async () => {
    setOnSubmitting(true);
    const formData = {
      id: form.id || undefined,
      name: form.name,
      description: form.description,
      startDate:
        (form.startDate &&
          moment.isMoment(form.startDate) &&
          moment(form.startDate)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss")) ||
        null,
      endDate:
        (form.endDate &&
          moment.isMoment(form.endDate) &&
          moment(form.endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")) ||
        null,
      indefinite: !form.endDate,
      enabled: true,
      active: Boolean(form.active),
    };
    const response = await getApiFunction()(formData);
    if (response && response.success) {
      if (parentHandleChange && response.data && response.data.id) {
        parentHandleChange("basicInfo", response.data);
      }
      if (nextStep) {
        nextStep();
      }
    }
    setOnSubmitting(false);
  };

  return (
    <CampaignStepOneView
      name={form.name}
      description={form.description}
      active={form.active}
      startDate={form.startDate}
      endDate={form.endDate}
      onSubmitting={onSubmitting}
      indefinite={form.indefinite}
      update={Boolean(form.id)}
      // functions
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      formSubmitHandler={formSubmitHandler}
      formCancelHandler={formCancelHandler}
      translatableFileds={translatableFileds}
      selectedLanguage={selectedLanguage}
    />
  );
};

CampaignStepOne.defaultProps = {
  basicInfo: null,
};

CampaignStepOne.propTypes = {
  basicInfo: PropTypes.objectOf(PropTypes.any),
  // functions
  nextStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default CampaignStepOne;
