/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Navigation Management Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import CampaignListView from "../components/CampaignListView";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import { getCampaigns, deleteCampaign, setStatus } from "../../../api/campaignServices";
import { getSitePreviewConfigurations, replicatePromotion, getPromotionReplicationStatus } from "../../../api/sitePreviewConfigurationsServices";

const CampaignList = () => {
  const history = useHistory();
  const searchTermMinLength = 3;
  const sortOptions = [
    {
      by: "campaign:label_campaign_id", direction: "common:label_sort_asc", sortBy: "id", sortDirection: "asc",
    },
    {
      by: "campaign:label_campaign_id", direction: "common:label_sort_desc", sortBy: "id", sortDirection: "desc",
    },
    {
      by: "common:label_name", direction: "common:label_sort_asc", sortBy: "name", sortDirection: "asc",
    },
    {
      by: "common:label_name", direction: "common:label_sort_desc", sortBy: "name", sortDirection: "desc",
    },
    {
      by: "common:label_startDate", direction: "common:label_sort_asc", sortBy: "startDate", sortDirection: "asc",
    },
    {
      by: "common:label_startDate", direction: "common:label_sort_desc", sortBy: "startDate", sortDirection: "desc",
    },
    {
      by: "common:label_endDate", direction: "common:label_sort_asc", sortBy: "startDate", sortDirection: "asc",
    },
    {
      by: "common:label_endDate", direction: "common:label_sort_desc", sortBy: "startDate", sortDirection: "desc",
    },
  ];

  /** local states */
  const [campaigns, setCampaigns] = useState([]);
  const [searchQuery, setSearchQuery] = useState({
    selectedCampaign: "",
    searchTerm: "",
    page: 0,
    limit: 5,
    sort: -1,
    sortBy: "updatedDate",
    sortDirection: "desc",
    active: "",
    hasMoreData: true,
  });
  const [onSubmitting, setOnSubmitting] = useState("");
  const [selectedCampId, setSelectedCampId] = useState(null);
  const [deleteSelectedCampaign, setDeleteSelectedCampaign] = useState(null);


  /**
       * This function is sued to change search query
       * @param {String} name
       * @param {String} value
       */
  const handleChange = (name, value, changeFunction) => {
    changeFunction({
      ...searchQuery,
      [name]: value,
    });
  };

  /**
       * This function is used to get data from the server
       * @param {String} searchTerm
       * @param {Number} page
       * @param {Number} limit
       * @param {String} sort
       * @param {String} active
       */
  const getCampaignList = async (searchTerm = null, page = 0, limit = 10, sort = -1, active = null) => {
    const sortBy = sortOptions[sort] ? sortOptions[sort].sortBy : "updatedDate";
    const sortDirection = sortOptions[sort] ? sortOptions[sort].sortDirection : "desc";
    let status = null;
    if (active === "y") {
      status = true;
    } else if (active === "n") {
      status = false;
    }
    const response = await getCampaigns(searchTerm, page, limit, sortBy, sortDirection, status);
    if (response && response.success) {
      const { data } = response;
      let newSearchQuery = {
        ...searchQuery, searchTerm, page, limit, sort, sortBy, sortDirection, active,
      };
      if (Array.isArray(data)) {
        if (data.length > 0) {
          newSearchQuery = { ...newSearchQuery, hasMoreData: true };
        } else {
          newSearchQuery = { ...newSearchQuery, hasMoreData: false };
        }
        setCampaigns(data);
      }
      setSearchQuery({ ...newSearchQuery, page });
    }
  };

  useEffect(() => {
    getCampaignList();
  }, []);

  /**
       * This function is used to change sort order
       * @param {Number} sort
       */
  const handleSortChange = (sort) => {
    handleChange("sort", sort, setSearchQuery);
    getCampaignList(searchQuery.searchTerm, searchQuery.page, searchQuery.limit, sort, searchQuery.active);
  };


  /**
       * This method is used to change search text input
       * @param {Event} event
       */
  const handleSearchFormChange = (event) => {
    if (event) {
      const { name, value } = event.target;
      handleChange(name, value, setSearchQuery);
      if (name === "searchTerm") {
        if (`${value}`.length >= searchTermMinLength) {
          getCampaignList(value, 0, searchQuery.limit, searchQuery.sort, searchQuery.active);
        } else {
          getCampaignList(null, 0, searchQuery.limit, searchQuery.sort, searchQuery.active);
        }
      }
      if (name === "active") {
        getCampaignList(searchQuery.searchTerm, 0, searchQuery.limit, searchQuery.sort, value);
      }
      if (name === "sort") {
        handleSortChange(value);
      }
    }
  };

  /**
       * This metsortOptions[ sortOptionIndex] ? sortOptions[ sortOptionIndex].by hod is used to redirect add campaign page
       */
  const newCampaign = () => {
    history.push("/marketing/campaign/new");
  };

  /**
       * This method is used to edit campaign
       * @param {String} campaignId
       */
  const editCampaign = (campaignId) => {
    history.push(`/marketing/campaign/edit/${campaignId}`);
  };

  /**
       * This method is used to open new product
       * This method will trigger when pagination button click
       * @param {Number} page
       */
  const getPageData = (page) => {
    getCampaignList(searchQuery.searchTerm, page, searchQuery.limit, searchQuery.sort, searchQuery.active);
    handleChange("page", page, setSearchQuery);
  };

  /**
   * This method is used to remove a Campaign
   * @param {String} id
   */

  const removeCampaign = (id) => {
    setDeleteSelectedCampaign({ id });
  };

  /**
   * This method is used to cancel delete campaign
   */

  const cancelConfirm = () => {
    setDeleteSelectedCampaign(null);
  };


  /**
   * method for confirming delete campaign
   */
  const confirmDelete = async () => {
    if (Boolean(deleteSelectedCampaign) && deleteSelectedCampaign.id) {
      const { id } = deleteSelectedCampaign;
      const response = await deleteCampaign(id);
      if (response && response.success === true) {
        const newCampaigns = campaigns.filter((campaign) => (campaign.id !== id));
        setCampaigns(newCampaigns);
      }
      setDeleteSelectedCampaign(null);
    }
  };


  /**
     * This method used to change campaign status
     * @param {String} id
     * @param {Boolean} status
     */
  const changeCampaignStatus = async (status, id) => {
    if (onSubmitting) return;
    setOnSubmitting(true);
    setSelectedCampId(id);
    const response = await setStatus(id, status);
    if (response && response.success) {
      if (!searchQuery.active) {
        const newCampaigns = campaigns.map((campaign) => (campaign.id === id
          ? ({ ...campaign, active: status }) : campaign));
        setCampaigns(newCampaigns);
      } else {
        getCampaignList(
          searchQuery.searchTerm,
          searchQuery.page,
          searchQuery.limit,
          searchQuery.sort,
          searchQuery.active,
        );
      }
    }
    setSelectedCampId(null);
    setOnSubmitting(false);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    replicatePromotion().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Campaign pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };

  const getReplicationStatus = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    getPromotionReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last promotion push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch(error => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  return (
    <>
      <CampaignListView
        searchTerm={searchQuery.searchTerm}
        active={searchQuery.active}
        sort={searchQuery.sort}
        sortOptions={sortOptions}
        campaigns={campaigns}
        page={searchQuery.page}
        hasMoreData={searchQuery.hasMoreData}
        previewSiteUrl={previewSiteUrl}
        previewEnabled={previewEnabled}
        pushToLiveEnabled={pushToLiveEnabled}
        message={message}
        // functions
        newCampaign={newCampaign}
        handleSearchFormChange={handleSearchFormChange}
        editCampaign={editCampaign}
        removeCampaign={removeCampaign}
        getPageData={getPageData}
        selectedCampId={selectedCampId}
        handleCampaignStatus={changeCampaignStatus}
        handlePushToLive={handlePushToLive}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
      />
      <ConfirmationModal
        isOpen={Boolean(deleteSelectedCampaign)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this Campaign?"
      />
    </>
  );
};

export default CampaignList;
