/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * page customization services
 *
 * @author Naseef O
 *
 */

const navigation = [
  {
    label: "Merchandising",
    id: "merchandising",
    icon: "merchandisingIcon",
    description: "Ensure your products and your brand are seen as you intend",
    color: "#1a9fe5",
    subMenus: [{
      displayText: "Catalog Management",
    },
    {
      displayText: "Category",
      path: "/merchandising/category",
      default: true,
    },
    {
      displayText: "Brand",
      path: "/merchandising/brand",
      default: true,
    },
    {
      displayText: "Products",
      path: "/merchandising/product",
    },
    
    // uncomment when product option is applicable
    
    // {
    //   displayText: "Product Options",
    //   path: "/merchandising/product-option",
    // },
    {
      displayText: "Pricebooks",
      path: "/merchandising/pricebooks",
    },
    {
      displayText: "Inventory",
      path: "/merchandising/inventory",
    },
    // {
    //   displayText: "Search Facets",
    //   path: "/merchandising/category/search-facets"
    // },
    {
      displayText: "Sorting Rules",
      path: "/merchandising/category/sorting-rules",
    },
    {
      displayText: "Content Management",
    },
    // {
    //   displayText: "Themes",
    //   path: "/merchandising/theme-settings",

    // },
    {
      displayText: "Layout Settings",
      path: "/merchandising/layout-settings",
    },
    // {
    //   displayText: "Media Management",
    //   path: "/merchandising/media",
    // },
    // {
    //   displayText: "Static Pages",
    //   path: "/merchandising/static-pages",
    // },
    {
      displayText: "Static Pages",
      path: "/merchandising/static-pages",
    },
    {
      displayText: "Web App Customizations",
      path: "/merchandising/web-app-customization",
    },
    {
      displayText: "Mobile App Customizations",
      path: "/merchandising/mobile-app-customization",
    },
    {
      displayText: "Site Navigations",
    },
    {
      displayText: "Navigation Management",
      path: "/merchandising/navigation",
    },
    ],
  },
  {
    label: "Operations",
    id: "operations",
    icon: "operationsIcon",
    description: "Creating a flawless shopping experience",
    color: "#33942f",
    subMenus: [{
      displayText: "Order Management",
    },
    {
      displayText: "Orders",
      path: "/operations/order",
      default: true,
    },
    {
      displayText: "Fulfillment Method",
      path: "/operations/fulfillment-method",
      default: true,
    },
    // {
    //   displayText: "Order Settings",
    //   path: "/operations/order-settings",
    // },
    // {
    //   displayText: "Inventory",
    // },
    // {
    //   displayText: "Sources",
    //   path: "/operations/source",
    // },
    // {
    //   displayText: "Stock",
    //   path: "/operations/stock",
    // },
    {
      displayText: "Shipping Methods",
      path: "/operations/shipping-method",
    },
    {
      displayText: "Payment Method",
      path: "/operations/payment-method",
    },
      // {
      //   displayText: "Order Settings",
      //   path: "/operations/order-settings",
      // },
      // {
      //   displayText: "Inventory",
      // },
      // {
      //   displayText: "Sources",
      //   path: "/operations/source",
      // },
      // {
      //   displayText: "Stock",
      //   path: "/operations/stock",
      // },
    ],
  },
  {
    label: "Marketing",
    id: "marketing",
    icon: "marketingIcon",
    description: "Marketing tactics both on and off your website",
    color: "#f55d94",
    subMenus: [{
      displayText: "Marketing",
    },
    {
      displayText: "Campaign",
      path: "/marketing/campaign",
      default: true,
    },
    {
      displayText: "Promotions",
      path: "/marketing/promotion",
    },
    {
      displayText: "Coupons",
      path: "/marketing/coupons",
    },
    {
      displayText: "Badges",
      path: "/marketing/badges",
    },
    // {
    //   displayText: "Content Slots",
    //   path: "/marketing/content-slots",
    // },
    {
      displayText: "SEO Settings",
    },
    {
      displayText: "Site Map",
      path: "/marketing/site-map",
    },
    {
      displayText: "Robots Txt",
      path: "/marketing/robots-txt",
    },
    {
      displayText: "Custom URL",
      path: "/marketing/custom-url",
    },
    {
      displayText: "Vanity URLs",
      path: "/marketing/vanity-urls",
    },
    {
      displayText: "URL Redirects",
      path: "/marketing/url-redirects",
    },
    {
      displayText: "Page Metatags",
      path: "/marketing/page-metatags",
    },
    {
      displayText: "Customer Management",
    },
    {
      displayText: "Customer Data",
      path: "/marketing/customerdata",
    },
    {
      displayText: "Customer Groups",
      path: "/marketing/customergroups",
    },
    ],
  },
  {
    label: "Reports",
    id: "reports",
    icon: "reportsIcon",
    description: "Holistic view of your products and conversion funnel",
    color: "#f9a826",
    subMenus: [{
      displayText: "Reports",
    },
    {
      displayText: "Order Reports",
      path: "/reports/order-report",
      default: true,
    },
    {
      displayText: "Inventory Reports",
      path: "/reports/inventory-report",
      default: true,
    },
    // {
    //   displayText: "Customer report",
    //   path: "/reports/customer-report",
    // },
    {
      displayText: "Site Traffic Report",
      path: "/reports/sitetraffic-report",
    },
      // {
      //   displayText: "Audit Log",
      // },
    ],
  },
  {
    label: "Administration",
    id: "administration",
    icon: "administrationIcon",
    description: "Manage common e-commerce administrative tasks",
    color: "#ce6ed8",
    subMenus: [{
      displayText: "User Management",
    },
    {
      displayText: "Manage Users",
      path: "/administration/user",
      default: true,
    },
    // {
    //   displayText: "Search",
    //   path: "/administration/search",
    // },
    // {
    //   displayText: "Roles & Permissions",
    // },
    // {
    //   displayText: "Manage Roles",
    //   path: "/administration/manage-roles",
    // },
    // {
    //   displayText: "Manage Permissions",
    //   path: "/administration/permission",
    // },
    // {
    //   displayText: "Integrations",
    // },
    // {
    //   displayText: "API Settings",
    //   path: "/administration/api-setings",
    // },
    // {
    //   displayText: "Site Management",
    // },
    // {
    //   displayText: "Site Preference",
    //   path: "/administration/site-preference",
    // },
    // {
    //   displayText: "Site Protection",
    //   path: "/administration/site-protection",
    // },
    // {
    //   displayText: "Brand, Region Locale",
    //   path: "/administration/brand-region",
    // },
    // {
    //   displayText: "Currency",
    //   path: "/administration/currency",
    // },
    // {
    //   displayText: "URL Management",
    //   path: "/administration/url",
    // },
    // {
    //   displayText: "Currency Support",
    //   path: "/administration/currency-support",
    // },
    // {
    //   displayText: "Replication Support",
    //   path: "/administration/replication-support",
    // },
    {
      displayText: "Tax configuration",
    },
    {
      displayText: "Tax Class",
      path: "/administration/tax-class",
    },
    {
      displayText: "Tax Location",
      path: "/administration/tax-location",
    },
    // {
    //   displayText: "Tax Rule",
    //   path: "/administration/tax-rule",
    // },
    // {
    //   displayText: "Payment Configuration",
    // },
    // {
    //   displayText: "Payment Method",
    //   path: "/administration/payment-method",
    // },
    {
      displayText: "Store Settings",
    },
    {
      displayText: "Store Locations",
      path: "/administration/store-locations",
    },
    {
      displayText: "Job Scheduling",
    },
    {
      displayText: "New Job",
      path: "/administration/new-job",
    },
    {
      displayText: "Jobs",
      path: "/administration/job-list",
    },
    {
      displayText: "Job History",
      path: "/administration/job-details",
    },
    {
      displayText: "Global settings",
    },
    {
      displayText: "Cart Settings",
      path: "/administration/cart-settings",
    },
    {
      displayText: "Search Configurations",
    },
    {
      displayText: "Search Indexes/ Attributes",
      path: "/administration/search-index",
    },
    {
      displayText: "Search Settings",
      path: "/administration/search-settings",
    },
    {
      displayText: "Search Synonyms/ Hyponyms",
      path: "/administration/search-synonym",
    },
      // {
      //   displayText: "Search Suggestions",
      //   path: "/administration/search-suggestions",
      // },
      // {
      //   displayText: "Search Preferences",
      //   path: "/administration/search-preferences",
      // },
      // {
      //   displayText: "Search Relevancy Ranking",
      //   path: "/administration/search-ranking",
      // },
      // {
      //   displayText: "Sorting Options/ Rules",
      //   path: "/administration/sorting",
      // },
      // {
      //   displayText: "Shipping Configuration",
      // },
    ],
  },
  {
    label: "Documentation Guide",
    id: "Documentation Guide",
    description: "Complete documentation for your e-commerce needs",
    icon: "documentationIcon",
    color: "#ce6ed8",
    subMenus: [{
      displayText: "Documentation Guide",
    },
    {
      displayText: "Welcome",
      path: "/documentation-guide/welcome",
      default: true,
    },
    {
      displayText: "Merchandising Your Site",
      path: "/documentation-guide/merchandising",
    },
    {
      displayText: "Marketing",
      path: "/documentation-guide/marketing",
    },
    {
      displayText: "Operations",
      path: "/documentation-guide/operations",
    },
    {
      displayText: "Administering your organisation",
      path: "/documentation-guide/administering",
    },
    ],
  },
];

const getMainMenus = async () => {
  const success = true;
  const data = navigation.map(({
    label, id, icon, description, color,
  }) => ({
    label, id, icon, description, color,
  }));
  return ({
    success,
    data,
  });
};

/**
 *This method is used to get page customization rows
 * @returns
 */
const getSideNavItems = async (navigationKey) => {
  const navg = navigation.filter((y) => navigationKey === y.id)[0];
  return {
    success: Boolean(navg),
    data: (navg && navg.subMenus) || [],
  };
};


export {
  getMainMenus,
  getSideNavItems,
};
