/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import MenuEditor from "../components/MenuEditor";

/** ========= UTILS ========= */


/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { getMenu, updateMenu, getNavigationTypes } from "../../../api/siteNavigationServices";

const MenuEditorContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  /** redirecting to listing page if there is not any menu id in url */
  if (!params.menuId) {
    history.push("/merchandising/navigation");
  }

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    position: "",
    locale: "en_AE",
    active: false,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [navigationType, setNavigationType] = useState([]);

  useEffect(() => {
    getNavigationTypes().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) setNavigationType(data);
      }
    });
  }, []);

  useEffect(() => {
    getMenu(params.menuId).then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setForm({
          ...data,
          id: data.id,
          name: data.name || "",
          position: data.type || "",
          locale: data.locale || "en_AE",
          active: Boolean(data.active),
        });
      }
    });
  }, [params.menuId]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used to go back to listing page
   */
  const formCancel = () => {
    history.push("/merchandising/navigation");
  };

  /**
   * This method is used to submit the form for add new menu
   * @param {Event} event
   */
  const formSubmit = async (event) => {
    setOnSubmitting(true);
    const { localPosition, ...localForm } = form;
    const formData = { ...localForm, type: localPosition };
    const response = await updateMenu(formData);
    if (response && response.success) {
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/merchandising/navigation");
      }, 3000);
    }
    setOnSubmitting(false);
  };
  return (
    <MenuEditor
      name={form.name}
      position={form.position}
      locale={form.locale}
      active={form.active}
      /** form data */
      positions={navigationType}
      onSubmitting={onSubmitting}
      /** form methods */
      handleFormChange={handleFormChange}
      handleChange={handleChange}
      formSubmit={formSubmit}
      formCancel={formCancel}
    />
  );
};

export default MenuEditorContainer;
