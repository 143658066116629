/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * UrlRedirect urls container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import UrlRedirects from "../components/URLRedirectsManagement";
import {
  getPageRedirects, createPageRedirect, updatePageRedirect, deletePageRedirectById,
} from "../../../api/SeoSettingsService";
import validateForm from "../../../common/utils/validateForm";

const UrlRedirectsContainer = () => {
  const [urlRedirects, setUrlRedirects] = useState(null);
  const [modal, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [form, setForm] = useState({ url: "", internalUrl: "", redirectStatusCode: null });
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);
  const methods = validateForm();
  const {
    handleSubmit, register, errors, setError, clearError,
  } = methods;

  useEffect(() => {
    getPageRedirects(0, 10, searchTerm.trim()).then((response) => {
      if (response && response.success && response.data) {
        setUrlRedirects(response.data);
        setPage(0);
        if (Array.isArray(response.data) && response.data.length > 0) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      }
    });
  }, [searchTerm]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
  };

  const getAllUrlRedirects = (pageNumber) => {
    const limit = 10;
    getPageRedirects(pageNumber, limit, searchTerm).then((response) => {
      if (response && response.success && response.data) {
        setUrlRedirects(response.data);
        setPage(pageNumber);
        if (Array.isArray(response.data) && response.data.length > 0) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      }
    });
  };

  /**
 * This method is used to get handle search custom pages
 *
 * @param {*} direction
 */
  const onHandleSearch = (direction) => {
    const newPage = page + direction;
    getAllUrlRedirects(newPage);
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };


  const toggleModal = (data) => {
    clearError(["url"]);
    if (data) {
      setIsEdit(true);
      setForm({ ...form, ...data });
    } else {
      setIsEdit(false);
      setForm({ url: "", internalUrl: "" });
    }
    setModalOpen(!modal);
  };

  /**
 * This method is used to handle update and save custom page
 *
 * @param {*} value
 */
  const handleSave = (value) => {
    const requestData = {
      ...form,
    };
    if (value) {
      if (isEdit) {
        updatePageRedirect(requestData).then((response) => {
          if (response && response.success && response.data) {
            setModalOpen(false);
            getAllUrlRedirects();
          } else {
            setError("url", "noMatch", "URL already exists");
          }
        });
      } else {
        createPageRedirect(requestData).then((response) => {
          if (response && response.success && response.data) {
            setModalOpen(false);
            getAllUrlRedirects();
          } else {
            setError("url", "noMatch", "URL already exists");
          }
        });
      }
    }
  };

  const [isLoading, setLoading] = useState(false);
  const [selectedIndex, setIndex] = useState(null);
  const handleDelete = (id, index) => {
    setLoading(true);
    setIndex(index);
    deletePageRedirectById(id).then((response) => {
      if (response && response.success) {
        getAllUrlRedirects(0);
      }
      setLoading(false);
    });
  };

  return (
    <UrlRedirects
      urlRedirects={urlRedirects}
      modal={modal}
      form={form}
      handleChange={handleChange}
      toggleModal={toggleModal}
      isEdit={isEdit}
      register={register}
      errors={errors}
      handleSave={handleSave}
      handleSubmit={handleSubmit}
      onHandleSearch={onHandleSearch}
      page={page}
      hasMoreData={hasMoreData}
      handleChangeSearch={handleChangeSearch}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      handleDelete={handleDelete}
    />
  );
};

export default UrlRedirectsContainer;
