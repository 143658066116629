/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ======== CUSTOM COMPONENTS ========= */
import ParentCategory from "../components/ParentCategory";

/** ===== API SERVICE FUNCTIONS =========== */
import { listAllCategories } from "../../../api/categoryManagementServices";

/** Base container for add new category  */
const ParentCategoryContainer = (props) => {
  const {
    parentCategory, handleParentCategory, exclude, categoryName: parentCategoryName, disbaledivClass,
  } = props;
  /** local states */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (parentCategoryName) {
      setCategoryName(parentCategoryName);
    }
  }, [parentCategoryName]);

  /**
   * This method is used return id only
   * but to display we need category name
   * @param {Object} category
   */
  const localHandleParentCategory = (category) => {
    if (category) {
      setCategoryName(category.name ? category.name : null);
      handleParentCategory(category.id ? category.id : "");
      setDropdownOpen(false);
    }
  };

  /**
   * This method is used for set parent category as root
   */
  const clearCategories = () => {
    setCategoryName(null);
    handleParentCategory("");
  };

  return (
    <ParentCategory
      // component
      categoryName={categoryName}
      toggle={toggle}
      dropdownOpen={dropdownOpen}
      clearCategories={clearCategories}
      // parent category
      parentCategory={parentCategory}
      handleParentCategory={localHandleParentCategory}
      exclude={exclude}
      disbaledivClass={disbaledivClass}
    />
  );
};

ParentCategoryContainer.defaultProps = {
  exclude: [],
  categoryName: null,
};

ParentCategoryContainer.propTypes = {
  parentCategory: PropTypes.string.isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  exclude: PropTypes.arrayOf(PropTypes.any),
  categoryName: PropTypes.string,
  disbaledivClass: PropTypes.string.isRequired,
};

export default ParentCategoryContainer;
