/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Authentication and authorization utilities
 * @module
 */
import user from "./UserData";

export const isAuthenticated = () => user.getAuthenticated() === "true";

/**
 * Checking logged in user has any one of accessrights
 * @param {Array} rights
 */
export const hasAccessRights = (rights) => (
  Array.isArray(user.rights) ? rights.some((right) => user.rights.includes(right)) : false);

/**
 * Checking logged in user has any one of role
 * @param {Array} roles
 */
export const hasRole = (roles) => roles.some((role) => user.getType() === role);
