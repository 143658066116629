// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Sreelekshmi Prasannan\n */\n\n .CustomerGroupCreatorView_block2__Yl-Ef {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.CustomerGroupCreatorView_borderRadius2__3shyg {\n  border-radius: 0.125rem !important;\n}\n\n.CustomerGroupCreatorView_secondaryColor__bPul2 {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.CustomerGroupCreatorView_primaryColor__3W-i3 {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.CustomerGroupCreatorView_btn__1Mf8e {\n  min-width: 7rem;\n}\n\n\n\n/* ========== FORM AREA ============ */\n.CustomerGroupCreatorView_block3__2qC35 {\n  padding: 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"block2": "CustomerGroupCreatorView_block2__Yl-Ef",
	"borderRadius2": "CustomerGroupCreatorView_borderRadius2__3shyg",
	"secondaryColor": "CustomerGroupCreatorView_secondaryColor__bPul2",
	"primaryColor": "CustomerGroupCreatorView_primaryColor__3W-i3",
	"btn": "CustomerGroupCreatorView_btn__1Mf8e",
	"block3": "CustomerGroupCreatorView_block3__2qC35"
};
module.exports = exports;
