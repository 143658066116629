/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */


let globalHistory = null;

const GlobalHistory = (props) => {
  const { history } = props;

  useEffect(() => {
    /** This function for reload the webpage on 401 response */
    function reload() {
      history.push((window && window.location && window.location.pathname) || "/");
    }
    const localHistory = { ...history, reload };
    globalHistory = (localHistory);
    return () => { globalHistory = localHistory; };
  }, [history]);

  return null;
};

GlobalHistory.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(GlobalHistory);

export const getHistory = () => globalHistory;
