/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import PropTypes from "prop-types";
import React, { useState } from "react";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import CustomMenuItem from "../components/CustomMenuItemCreator";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */

const CustomMenuItemContainer = (props) => {
  const { value, name, onChange } = props;

  /** local states */
  const [promotions, setPromotions] = useState(Array.isArray(value) ? value : []);
  const [newPromotion, setNewPromotion] = useState(false);

  /**
   * This method is used to trigger parent event onChange
   */
  const triggerParentChange = (newPromotions) => {
    onChange(newPromotions);
  };

  /**
   * This method is used to add new promotions
   * @param {Object} data
   */
  const handleChange = (data) => {
    const newPromo = [...promotions, data];
    setPromotions(newPromo);
    triggerParentChange(newPromo);
    setNewPromotion(false);
  };

  /**
   * This method is used to open add promotion form
   */
  const addNewPromotion = () => {
    setNewPromotion((oldState) => !oldState);
  };

  /**
   * This method is used to remove new add promotion form
   */
  const clearNewForm = () => {
    setNewPromotion(false);
  };

  /**
   * This method is used to remove a promotion
   * @param {String} promotionsId
   */
  const deletePromotion = (promotionsId) => {
    const localPromotions = promotions.filter((promotion) => promotion.id !== promotionsId);
    setPromotions(localPromotions);
    triggerParentChange(localPromotions);
  };

  return (
    <CustomMenuItem
      name={name}
      newPromotion={newPromotion}
      promotions={promotions}
      handleChange={handleChange}
      addNewPromotion={addNewPromotion}
      clearNewForm={clearNewForm}
      deletePromotion={deletePromotion}
    />
  );
};

CustomMenuItemContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
};

CustomMenuItemContainer.defaultProps = {
  name: "promotion",
};

export default CustomMenuItemContainer;
