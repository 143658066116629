/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */

import React, { useState, useEffect } from "react";
import SiteSettingsManagement from "../component/SiteSettingsManagement";
import { getSiteSettings, updateSiteSettings } from "../../../api/SiteSettingsService";
import { useHistory } from "react-router-dom";

const SiteSettingsManagementContainer = () => {
  const [siteSettings, setSiteSettings] = useState({
    showZeroPriceProduct: false,
    showOutOfStockProducts: false
  });

  const getSiteDetails = () => {
    getSiteSettings().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        const { showZeroPriceProduct, showOutOfStockProducts } = data;
        setSiteSettings({ showZeroPriceProduct: showZeroPriceProduct, showOutOfStockProducts: showOutOfStockProducts });
      }
    });
  }

  useEffect(() => {
    getSiteDetails();
  }, []);

  /**
   * This method is used to change form state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setSiteSettings({
      ...siteSettings,
      [name]: value,
    });
  };

  const handleCancel = () => {
    getSiteDetails();
  }
  const saveSiteSettings = async (event) => {   
    const response = await updateSiteSettings(siteSettings);
    if (response && response.data && response.success) {
        console.log(response.data);
    }
  };

  return (
    <SiteSettingsManagement
      siteSettings={siteSettings}
      handleChange={handleChange}
      saveSiteSettings={saveSiteSettings}
      handleCancel={handleCancel}
    />
  );
};

export default SiteSettingsManagementContainer;
