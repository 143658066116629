import React, { PureComponent } from "react";
import { UncontrolledPopover as RUncontrolledPopover } from "reactstrap";

/** @class
 * This class is used as react-strap's UncontrolledPopover Compontent
 */

class UncontrolledPopover extends PureComponent {
  render() {
    return <RUncontrolledPopover {...this.props} />;
  }
}

export default UncontrolledPopover;
