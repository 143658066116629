/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Popover from "../../../common/core/Popover";
import InputGroup from "../../../common/core/InputGroup";
import Input from "../../../common/core/Input";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Button from "../../../common/core/Button";
import ButtonGroup from "../../../common/core/ButtonGroup";
import Form from "../../../common/core/Form";

/** ========= CUSTOM COMPONENTS ========= */
import CustomizationTable from "../../../common/components/Table";

/** ========= UTILS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
import styles from "../css/MegaMenuItemCreator.module.css";

/** ============ SVG IMAGE ICON ===================== */
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import ClearIcon from "../../../common/assets/images/svg/clear.svg";
import DoneIcon from "../../../common/assets/images/svg/done.svg";

const SelectedItemListView = (props) => {
  const {
    data, label, editEnabled,
    actionHandler, onChange,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  const [editPopover, setEditPopover] = useState(false);
  const [newUrl, setNewUrl] = useState("");

  /**
   * This function is used to update status
   * @param {Object} content
   */
  const toggle = (content) => setEditPopover(content);

  useEffect(() => {
    if (editPopover && editPopover.url) {
      setNewUrl(editPopover.url);
    }
  }, [editPopover]);

  /**
     * This method is used to change form filed input
     * @param {Event} event
     */
  const handleUrlChange = (event) => {
    setNewUrl(event.target.value);
  };

  const updateData = (rowData, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onChange === "function") {
      onChange(data.map((g) => (g.id === rowData.id ? ({ ...g, ...rowData }) : g)));
      setEditPopover(null);
      setNewUrl("");
    }
  };

  const editAction = (row) => (
    <>
      <span
        className="commonPointer"
        onClick={() => actionHandler(row.id)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={DeleteIcon}
          alt="Delete"
        />
      </span>
      <span
        className="commonPointer ml-1"
        onClick={() => toggle(row)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
        id={`edit-item-url-${row.id}`}
      >
        <img
          className={styles.icon}
          src={EditIcon}
          alt="Edit"
        />
      </span>
      <Popover placement="left" isOpen={editPopover && editPopover.id === row.id} target={`edit-item-url-${row.id}`} toggle={() => toggle(false)}>
        <Form onSubmit={(j) => updateData(({ ...row, url: newUrl }), j)}>
          <InputGroup style={{ width: 300 }}>
            <Input value={newUrl} type="textarea" onChange={handleUrlChange} required />
            <InputGroupAddon addonType="append">
              <ButtonGroup>
                <Button onClick={() => toggle(false)}>
                  <img
                    className={styles.icon}
                    src={ClearIcon}
                    alt="Clear"
                  />
                </Button>
                <Button type="submit">
                  <img
                    className={styles.icon}
                    src={DoneIcon}
                    alt="Update"
                  />
                </Button>
              </ButtonGroup>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </Popover>
    </>
  );

  return Array.isArray(data) && data.length > 0
    ? (
      <div className="custom-table">
        <CustomizationTable
          customRows={[
            {
              label: t("site-navigation:label_id"),
              path: "render",
              render: (row) => row.dataId || row.id,
            },
            {
              label: label || t("site-navigation:label_data"),
              path: "name",
            },
            {
              label: label || t("site-navigation:label_url"),
              path: "render",
              render: (row) => (row.url || (row.link && row.link.url)) || "-",
            },
            ...(editEnabled ? ([{
              label: t("common:label_actions"),
              path: "render",
              render: editAction,
            }]) : []),
          ]}
          customizationData={data}
        />
      </div>
    ) : null;
};

SelectedItemListView.defaultProps = {
  label: null,
  onChange: undefined,
  editEnabled: false,
};

SelectedItemListView.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  editEnabled: PropTypes.bool,
  actionHandler: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default SelectedItemListView;
