/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import React from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
import Input from "../core/Input";

const Select = ({
  size, bsSize, valid, invalid, innerRef, plaintext,
  className, cssModule, placeholder, name, id, value,
  options, onChange, render, disabled,
}) => (
    <Input
      type="select"
      className={className}
      placeholder={placeholder}
      size={size}
      bsSize={bsSize}
      invalid={invalid}
      valid={valid}
      innerRef={innerRef}
      plaintext={plaintext}
      cssModule={cssModule}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {
        Array.isArray(options) && options.map((option) => (
          render ? render(option) : (
            <option key={option.value} value={option.value} defaultValue={option.value} disabled={option.disabled} selected={option.selected} hidden={option.hidden}>
              {option.label}
            </option>
          )))
      }
    </Input>
  );

Select.propTypes = {
  size: PropTypes.string,
  bsSize: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
  })).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  plaintext: PropTypes.bool,
  className: PropTypes.string,
  cssModule: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  render: PropTypes.func,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  size: null,
  bsSize: null,
  valid: null,
  invalid: null,
  plaintext: null,
  className: "",
  placeholder: "",
  cssModule: null,
  innerRef: null,
  render: null,
  value: undefined,
  disabled: false,
};

export default Select;

/**
 * Example usage
 *
 * <Select
 *   className="classNme"
 *   placeholder="Select form options"
 *   name="name"
 *   id="id"
 *   onChange={  onChange }
 *   options={[
 *       {
 *       selected: true, disabled: false, value: "", label: `Select Element`,
 *   },
 *   ...(
 *       Array.isArray( options ) &&
 *       options.map((option) => ({
 *           value: option.value,
 *           label: option.label
 *       }))
 *       )
 *   ]}
 *   value={ value }
 * />
 *
 *
 *
 *  options: PropTypes.arrayOf(PropTypes.shape({
 *   value: PropTypes.string,
 *   label: PropTypes.string,
 *   selected: PropTypes.bool,
 *   disabled: PropTypes.bool,
 * })).isRequired,
 *
 */
