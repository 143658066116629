/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/TaxLocation.module.css";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";

const TaxLocationForm = ({ onHandleCancel, handleFormChange, form, onHandleSave }) => {
  const { t } = useTranslation(["common", "tax-configuration"]);
  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">{t("tax-configuration:span_tax_configuration_title")}</span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="6" className="p-0">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("tax-configuration:span_tax_location")}
              </span>
            </Col>
            <Col className="mb-2" xs="6" sm>
              <div className={styles.areaActionRight}>
                <div>
                  <Button
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.secondaryColor,
                      "text-uppercase",
                      "text-truncate",
                      "btn-tertiary",
                      "ml-1",
                    )}
                    onClick={onHandleCancel}
                  >
                    {t("common:button_cancel")}
                  </Button>
                  <Button
                    color="primary"
                    className={clsx(
                      styles.borderRadius2,
                      "text-uppercase",
                      "text-truncate",
                      "btn-tertiary",
                      "ml-1",
                    )}
                    onClick={onHandleSave}
                  >
                    {t("common:button_save")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <Row>
            <Col xs="12">
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("common:label_name")}</Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="name"
                      value={form.name}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("tax-configuration:label_country")}</Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="country"
                      value={form.country}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("tax-configuration:label_location")}</Label>
                    <Input
                      className="formText"
                      placeholder=""
                      name="location"
                      value={form.location}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">{t("common:label_description")}</Label>
                    <Input
                      type="textarea"
                      className="formText"
                      placeholder=""
                      name="description"
                      value={form.description}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

TaxLocationForm.propTypes = {
  onHandleCancel: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TaxLocationForm;
