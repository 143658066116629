/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Custom table
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import {Table} from "reactstrap";
import {get} from "lodash";
import {useTranslation} from "react-i18next";
import editIcon from "../assets/images/svg/edit.svg";
import deleteIcon from "../assets/images/svg/delete.svg";
import toggleOnIcon from "../assets/images/svg/toggle-on.svg";
import dropdownIcon from "../assets/images/svg/dropdown.png";

const moment = require("moment");

const TABLE_ATTRIBUTE_RENDER = "render";
const TABLE_ATTRIBUTE_TYPE_DATE = "date";

const CustomTable = (props) => {
  const {
    customRows,
    customizationData,
    isStatusEnable,
    isDeleteEnable,
    isEditEnable,
    onClickRow,
    onEditData,
    onDeleteData,
    isDropdownEnabled,
    isSerialNoEnabled,
  } = props;
  const {t} = useTranslation(["common"]);
  /**
   *This method is used to get a value from an item
   *
   * @param {object} row
   * @param {object} rowItemData
   * @returns
   */
  const getItem = (row, rowItemData) => {
    let formattedData = null;
    const data =
      rowItemData.path === TABLE_ATTRIBUTE_RENDER
        ? rowItemData.render(row)
        : get(row, rowItemData.path);
    if (rowItemData.type === TABLE_ATTRIBUTE_TYPE_DATE) {
      formattedData = moment(data, moment.ISO_8601, true).isValid()
        ? moment(data).format("YYYY-MM-DD HH:mm:ss")
        : null;
    } else {
      formattedData = data;
    }
    return formattedData || rowItemData.default;
  };

  const onHandleEditData = (item, e) => {
    e.stopPropagation();
    onEditData(item);
  };

  const onHandleDeleteData = (item, e) => {
    e.stopPropagation();
    onDeleteData(item);
  };

  return (
    <Table borderless>
      <thead>
        <tr>
          {isDropdownEnabled && <th />}
          {isSerialNoEnabled && <th>{t("common:table_title_sl_no")}</th>}
          {customRows.map((row) => (
            <th>{row.label}</th>
          ))}
          {isStatusEnable && <th>{t("common:table_title_status")}</th>}
          {isEditEnable && <th>{t("common:table_title_edit")}</th>}
          {isDeleteEnable && <th>{t("common:table_title_delete")}</th>}
        </tr>
      </thead>
      <tbody>
        {customizationData.length > 0 ? (
          customizationData.map((rowItem, index) => (
            <tr
              onClick={onClickRow ? () => onClickRow(rowItem) : null}
              className={onClickRow ? "focusedRow" : ""}
            >
              {isDropdownEnabled && (
                <td width="10%">
                  <img
                    src={dropdownIcon}
                    width="20px"
                    onClick={onClickRow ? () => onClickRow(rowItem) : null}
                    className={onClickRow ? "pointer" : ""}
                  />
                </td>
              )}
              {isSerialNoEnabled && <td>{index + 1}</td>}
              {customRows.map((row) => (
                <td width="auto">{getItem(rowItem, row)}</td>
              ))}
              {isStatusEnable && (
                <td width="10%">
                  <img
                    src={toggleOnIcon}
                    className="commonPointer"
                    width="23px"
                    alt="drag indiactor icon"
                  />
                </td>
              )}
              {isEditEnable && (
                <td width="10%">
                  <img
                    src={editIcon}
                    className="commonPointer"
                    role="presentation"
                    alt="drag indiactor icon"
                    width="20px"
                    onClick={
                      rowItem.id
                        ? (e) => onEditData && onHandleEditData(rowItem.id, e)
                        : (e) => onEditData && onHandleEditData(rowItem.name, e)
                    }
                  />
                </td>
              )}
              {isDeleteEnable && (
                <td width="10%">
                  <img
                    src={deleteIcon}
                    className="commonPointer"
                    alt="drag indiactor icon"
                    width="20px"
                    onClick={
                      rowItem.id
                        ? (e) =>
                            onDeleteData && onHandleDeleteData(rowItem.id, e)
                        : (e) =>
                            onDeleteData && onHandleDeleteData(rowItem.name, e)
                    }
                  />
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={8} align="center" valign="center">
              {t("common:td_nothing_to_show")}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

CustomTable.propTypes = {
  customRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  isStatusEnable: PropTypes.bool.isRequired,
  isDeleteEnable: PropTypes.bool.isRequired,
  isEditEnable: PropTypes.bool.isRequired,
  isDropdownEnabled: PropTypes.bool.isRequired,
  isSerialNoEnabled: PropTypes.bool.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onEditData: PropTypes.func.isRequired,
  onDeleteData: PropTypes.func.isRequired,
};

export default CustomTable;
