/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Select from "../../../common/components/MultiSelect/MultiSelect";


const FulfilmentTypeSelector = (props) => {
  const {
    name,
    onChange,
    fulfilmentTypeList,
    selectedFulfilmentTypes,
  } = props;

  const { t } = useTranslation(["fulfillment-method"]);
  return (
    <Select
      className="formText"
      typeaheadPlaceholder={t("fulfillment-method:label_select_fulfilment_method")}
      name={name}
      id="fulfilmentTypes"
      value={selectedFulfilmentTypes}
      onChange={onChange}
      options={
        Array.isArray(fulfilmentTypeList)
        && fulfilmentTypeList.map(({ id: value, name: label }) => ({ value, label }))
      }
    />
  );
};

FulfilmentTypeSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fulfilmentTypeList: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedFulfilmentTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default FulfilmentTypeSelector;
