
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";
import styles from "../css/AddCustomerGroup.module.css";
import EditCustomerGroupForm from "./CustomerGroupForm";
import validateForm from "../../../common/utils/validateForm";

const EditCustomerGroup = (props) => {
  const {
    name, description, type, userIds,
    handleFormChange, handleChange, formSubmit, formCancel,
    onSubmitting, setUserIds,
    message,
    criteria, matchType, pattern, noOfDays, orderAmount,
    uploadCustomers, downloadCustomers,
  } = props;
  const { t } = useTranslation(["customerGroup"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  return (
    <Row className="">
      <Col xs="12">
        <Form onSubmit={handleSubmit(formSubmit)}>
          {
            message && message.type && (
              <Alert color={message.type}>
                {message.message}
              </Alert>
            )
          }
          <fieldset disabled={onSubmitting}>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">{t("customerGroup:title_edit_customerGroup")}</span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={formCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                  {t("customerGroup:label_button_cancel")}
                </Button>
                <Button type="submit" color="tertiary" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size,btn-tertiary ")}>
                  {t("customerGroup:label_button_update")}
                </Button>
              </Col>
            </Row>
            <Row className="pageHeader common-page-card">
              <Col xs="12">
                <EditCustomerGroupForm
                  name={name}
                  description={description}
                  type={type}
                  validator={validator}
                  userIds={userIds}
                  handleFormChange={handleFormChange}
                  handleChange={handleChange}
                  setUserIds={setUserIds}
                  criteria={criteria}
                  matchType={matchType}
                  pattern={pattern}
                  noOfDays={noOfDays}
                  orderAmount={orderAmount}
                  uploadCustomers={uploadCustomers}
                  downloadCustomers={downloadCustomers}
                  isEdit
                />
              </Col>
            </Row>
          </fieldset>
        </Form>
      </Col>
    </Row>
  );
};

EditCustomerGroup.propTypes = {
  // form data
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  criteria: PropTypes.string.isRequired,
  matchType: PropTypes.string.isRequired,
  pattern: PropTypes.string.isRequired,
  noOfDays: PropTypes.string.isRequired,
  orderAmount: PropTypes.string.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setUserIds: PropTypes.func.isRequired,
  downloadCustomers: PropTypes.func.isRequired,
  uploadCustomers: PropTypes.func.isRequired,
  // form actions
  onSubmitting: PropTypes.bool.isRequired,
  formSubmit: PropTypes.func.isRequired,
  formCancel: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default EditCustomerGroup;
