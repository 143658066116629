/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";


/** ======== COMPONENTS */
import Category from "../containers/CategoryContainer";

/** ===== MODULE STYLES =========== */
import styles from "../css/CategoryManagement.module.css";

const CategoryManagement = ({
  categories,
  addNewCategory,
  handlePushToLive,
  pushToLiveEnabled,
  message,
  previewSiteUrl,
  previewEnabled,
  getReplicationStatus,
  pushToLiveButtonEnabled,
}) => {
  /** internationalization using i18n from common and category  */
  const { t } = useTranslation(["common", "category"]);

  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">{t("category:title_category_manage")}</span>
        </Col>
        <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
          <Row>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                  onClick={() => redirectToPage()}
                >
                  {t("common:button_preview")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col className="mb-2" xs="12" sm="12" md="4">
          <span
            className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
          >
            {t("category:title_manage_categories")}
          </span>
        </Col>
        <Col className="mb-2" xs="12" sm>
          <div className={styles.areaActionRight}>
            {/* <div>
              <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                <Input placeholder="Search category" />
                <InputGroupAddon
                  addonType="append"
                  className="commonPointer bg-color-tertiary-main"
                >
                  <img
                    src={SearchIcon}
                    alt="search category"
                    className={styles.icon}
                  />
                </InputGroupAddon>
              </InputGroup>
            </div> */}
            <div>
              <Button
                onClick={addNewCategory}
                className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
              >
                {t("category:button_new")}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, styles.tableWrapper, "common-page-card")}>
        <Col>
          <div className={styles.categoryTree}>
            {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
            <div
              className={clsx(styles.categoryTreeBody, "table-responsive")}
            >
              <Table borderless className={styles.categoryTreeTable}>
                {/* ========= COMPONENT HEAD S[0003] ======================= */}
                <thead>
                  <tr>
                    <th>{t("category:label_category_id")}</th>
                    <th>{t("category:label_category_name")}</th>
                    <th>{t("common:label_type")}</th>
                    <th>{t("common:label_startDate")}</th>
                    <th>{t("common:label_endDate")}</th>
                    <th className="text-center">{t("common:label_status")}</th>
                    <th className="text-center">{t("common:label_actions")}</th>
                  </tr>
                </thead>
                {/* ========= COMPONENT HEAD S[0003] ======================= */}
                <tbody>
                  {/* ========= COMPONENT BODY S[0002] ======================= */}
                  {
                    categories.length > 0
                      ? categories.map((each) => (
                        <Category
                          key={each.id}
                          id={each.id}
                          name={each.name}
                          type={each.type}
                          active={each.active}
                          startDate={each.startDate}
                          endDate={each.endDate}
                          childCategories={each.childCategories}
                          childProducts={each.childProducts}
                        />
                      )) : (
                        <tr>
                          <td colSpan={6} align="center" valign="center"> Nothing to show.</td>
                        </tr>
                      )
                  }
                  {/* ========= COMPONENT BODY E[0002] ======================= */}
                </tbody>
              </Table>
            </div>
            {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
          </div>
        </Col>
      </Row>
    </>
  );
};

CategoryManagement.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  addNewCategory: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
};

export default CategoryManagement;
