/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";

/** ===== IMAGE ICONS =========== */
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

/** ========= CUSTOM COMPONENTS ========= */
import TreeCategorySelector from "../../categoryManagement/containers/TreeCategorySelectorContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/CategorySelector.module.css";

const CategorySelector = (props) => {
  const {
    selectedCategories, handleCategorySelector, dropdownOpen, toggle, categoryName, clearCategories, options,
  } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["category"]);

  return (
    <div className={clsx(styles.rightDivider, "position-relative", "w-100")}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className={styles.dropdown}>
        <DropdownToggle
          className="formText form-control"
          caret
          title={categoryName}
        >
          <span className="text-truncate">
            { categoryName || t("category:placeholder_select_categories")}
          </span>
          <span className="ml-auto">
            <span />
            {
            categoryName && (
            <span role="button" onKeyDown={() => {}} tabIndex={0} className={styles.clear} onClick={clearCategories}>
              <img src={ClearIcon} alt="" />
            </span>
            )
          }
          </span>
        </DropdownToggle>
        <DropdownMenu direction="down" className={clsx(styles.dropdownMenu, "custom-scrollbar")}>
          <TreeCategorySelector
            categories={selectedCategories}
            onChange={handleCategorySelector}
            fullObject
            multiple
            options={options}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

CategorySelector.defaultProps = {
  categoryName: null,
  selectedCategories: [],
  options: [],
};

CategorySelector.propTypes = {
  // tag
  categoryName: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  clearCategories: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  // parent category
  selectedCategories: PropTypes.arrayOf(PropTypes.any),
  handleCategorySelector: PropTypes.func.isRequired,
};

export default CategorySelector;
