/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import styles from "../css/DocumentationGuide.module.css";

const Operations = () => (
  <>
    <Row>
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Row className="pageHeader  common-page-card">
          <Col xs="12" md="6" className="text-truncate">
            <span className={styles.pageHeader}>Operations</span>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Managing Orders
              <br />
            </span>
            <div className={styles.pageText}>
              <b>Login > Dashboard > Operations > Order Management > Order</b>
              <br />
              <br />
              For order reports or to search a particular order, please go to
              Order Management.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image13.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              View order by date by entering the FROM & To date or search for an
              order by entering the Order no: or Customer Name.
              <br />
              <br />
              The attributes in the report are
              <br />
              <ul>
                <li>Order number</li>
                <li>Order Date</li>
                <li>Customer name</li>
                <li>Customer Email ID</li>
                <li>Order status</li>
                <li>Order Amount</li>
              </ul>
              You have the option to export the report as .xlsx / .csv.
              <br />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default Operations;
