/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Site Trafic Report
 *
 * @author Naseef O
 *
 */
import React from "react";
import clsx from "clsx";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/SiteTraficReport.module.css";
import CustomizationTable from "../../../common/components/Table";

const customRows = [
  {
    label: "Date",
    value: "",
    path: "date",
    transform: "",
    type: "date"
  },
  {
    label: "Visitors",
    value: "",
    path: "visitors",
    transform: "",
  },
  {
    label: "Page Views",
    value: "",
    path: "pageViews",
    transform: "",
  },
  {
    label: "Orders",
    value: "",
    path: "orders",
    transform: "",
  },
  {
    label: "Total Sales",
    value: "",
    path: "totalSales",
    transform: "",
  },
  {
    label: "Average Order",
    value: "",
    path: "averageOrderValue",
    transform: "",
  },
  {
    label: "Conversions",
    value: "",
    path: "conversions",
    transform: "",
  },
];

const customizationData = [
  {
    date: "1",
    visitors: "Mar 20, 2020",
    pageViews: "1",
    orders: "$130",
    totalSales: "$0.00",
    averageOrderValue: "$50.00",
    conversions: "$0.00",
  },
  {
    date: "1",
    visitors: "Mar 20, 2020",
    pageViews: "1",
    orders: "$130",
    totalSales: "$0.00",
    averageOrderValue: "$50.00",
    conversions: "$0.00",
  },
  {
    date: "1",
    visitors: "Mar 20, 2020",
    pageViews: "1",
    orders: "$130",
    totalSales: "$0.00",
    averageOrderValue: "$50.00",
    conversions: "$0.00",
  },
  {
    date: "1",
    visitors: "Mar 20, 2020",
    pageViews: "1",
    orders: "$130",
    totalSales: "$0.00",
    averageOrderValue: "$50.00",
    conversions: "$0.00",
  },
  {
    date: "1",
    visitors: "Mar 20, 2020",
    pageViews: "1",
    orders: "$130",
    totalSales: "$0.00",
    averageOrderValue: "$50.00",
    conversions: "$0.00",
  },

];

const SiteTraficReport = () => (
  <>
    <Row className={clsx(styles.block1)}>
      <Col xs="12">
        <Row className="pageHeader">
          <Col xs="8">
            <span className="pageText">Site Traffic Report</span>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className={clsx(styles.block2, "m-0")}>
      <Col xs="4" className={styles.reportCard}>
        <Row className={styles.headding}>
          <Col xs="12">
            Visits
          </Col>
        </Row>
        <hr className="m-0" />
        <Row className={styles.value}>
          <Col xs="12">
            50
          </Col>
        </Row>
      </Col>
      <Col xs="4" className={styles.reportCard}>
        <Row className={styles.headding}>
          <Col xs="12">
            Orders
          </Col>
        </Row>
        <hr className="m-0" />
        <Row className={styles.value}>
          <Col xs="12">
            150
          </Col>
        </Row>
      </Col>
      <Col xs="4" className={styles.reportCard}>
        <Row className={styles.headding}>
          <Col xs="12">
            Revenue
          </Col>
        </Row>
        <hr className="m-0" />
        <Row className={styles.value}>
          <Col xs="12">
            $1,344.00
          </Col>
        </Row>
      </Col>
      <Col xs="4" className={styles.reportCard}>
        <Row className={styles.headding}>
          <Col xs="12">
            Transactions
          </Col>
        </Row>
        <hr className="m-0" />
        <Row className={styles.value}>
          <Col xs="12">
            25
          </Col>
        </Row>
      </Col>
      <Col xs="4" className={styles.reportCard}>
        <Row className={styles.headding}>
          <Col xs="12">
            Average Value
          </Col>
        </Row>
        <hr className="m-0" />
        <Row className={styles.value}>
          <Col xs="12">
            $305
          </Col>
        </Row>
      </Col>
      <Col xs="4" className={styles.reportCard}>
        <Row className={styles.headding}>
          <Col xs="12">
            Conversions
          </Col>
        </Row>
        <hr className="m-0" />
        <Row className={styles.value}>
          <Col xs="12">
            $200
          </Col>
        </Row>
      </Col>
    </Row>
    <Row className={styles.block3}>
      <Col xs="12">
        <Row className="m-0 pt-4 pb-2">
          <Col xs="12" className="p-0">
            <div className={styles.dateFilterRow}>
              <span className={styles.verticalLineNone}>
                Day
              </span>
              <span className={styles.verticalLine}>
                Month
              </span>
              <span className={styles.verticalLine}>
                Year
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="custom-table">
              <CustomizationTable
                customRows={customRows}
                customizationData={customizationData}
                isStatusEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default SiteTraficReport;
