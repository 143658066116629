/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */

import React from "react";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import FormGroup from "../../../common/core/FormGroup";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";

const RadioButtonGroup = ({
  inputData, handleChange, stateData,
}) => {
  const {
    label, inputName, attributes,
  } = inputData;
  const { options } = attributes || [];
  const selectedOption = stateData && stateData[inputName] ? stateData[inputName] : "";
  return (
    <div className="col-sm-6 mt-2">
      <FormGroup>
        <Label>{label}</Label>
        <Row>
          <Col className="ml-4">
            {options && options.length > 0 && options.map((option) => (
              <Label>
                <input
                  id={inputName}
                  name={inputName}
                  value={option.value}
                  type="radio"
                  checked={selectedOption === option.value}
                  onChange={(e) => handleChange(
                    e.target.name,
                    e.target.value,
                  )}
                  disabled={false}
                />
                {option.label}
              </Label>
            ))}
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  inputData: PropTypes.objectOf(PropTypes.any).isRequired,
  stateData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RadioButtonGroup;
