/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * User Management Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import UserManagement from "../components/UserManagement";
import { getUserList } from "../../../api/UserServices";

const UserManagementContainer = () => {
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [roles, setRoles] = useState("RETAIL_ADMIN");
  const [active, setactive] = useState(true);
  const [roleList, setRoleList] = useState([
    {
      label: "Retail Admin",
      code: "RETAIL_ADMIN",
    },
    {
      label: "Content Producer",
      code: "CONTENT_PRODUCER",
    },
    {
      label: "Customer Exective",
      code: "CUSTOMER_EXECUTIVE",
    },
    {
      label: "Merchandizer",
      code: "MERCHANDIZER",
    },
    {
      label: "Marketing",
      code: "MARKETING",
    },
  ]);
  const limit = 10;
  const getUserData = (localRole, localActive, localPage) => {
    getUserList(localRole, localActive, localPage, limit).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (Array.isArray(data)) {
          setUserList(data);
          const moreData = data.length > 0;
          setHasMoreData(moreData);
        } else {
          setHasMoreData(false);
        }
        setPage(localPage);
      }
    });
  };
  const onHandleRolesChange = (e) => {
    const { value } = e.target;
    setRoles(value);
    getUserData(value, active, 0);
  };
  const onHandleActiveChange = (e) => {
    const { value } = e.target;
    setactive(value);
    getUserData(roles, value, 0);
  };
  const getPageData = (direction) => {
    getUserData(roles, active, direction);
  };

  useEffect(() => {
    getUserData(roles, active, page);
  }, []);
  return (
    <UserManagement
      userList={userList}
      page={page}
      hasMoreData={hasMoreData}
      getPageData={getPageData}
      onHandleRolesChange={onHandleRolesChange}
      onHandleActiveChange={onHandleActiveChange}
      roleList={roleList}
    />
  );
};

export default UserManagementContainer;
