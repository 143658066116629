/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import DashboardView from "../components/DashboardView";
import { getSideNavItems } from "../../../api/navigationServices";


const Dashboard = () => {
  const history = useHistory();
  const params = useParams();

  const navigation = (params && params.baseRoute) || null;

  /** local states */
  const [menuItems, setMenuItems] = useState(null);
  const [notFound, setNotFound] = useState(false);

  // loading dynamic menu list by navigation change
  useEffect(() => {
    if (navigation) {
      getSideNavItems(navigation).then((response) => {
        if (response && response.success) {
          setMenuItems(Array.isArray(response.data) ? response.data : []);
        } else {
          setNotFound(true);
        }
        // const defaultActive = response.filter((item) => item.default)[0];
        // if (defaultActive && defaultActive.path) { history.push(defaultActive.path); }
      });
    }
  }, [navigation]);

  /**
   * This method is used to change url
   * @param {String} menuId
   */
  const openMenu = (menuId) => {
    if (menuId) { history.push(`${menuId}`); }
  };
  return notFound ? (
    (<Redirect to="/not-found" />)
  ) : (
    <DashboardView menuItems={menuItems} openMenu={openMenu} />
  );
};

export default Dashboard;
