/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import VariantSelector from "../components/VariantSelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { getVariantAttributes } from "../../../api/productManagementServices";


const VariantSelectorContainer = ({
  productType, onChange, name, value, register, errors,
}) => {
  /** local states */
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttribute] = useState({});


  useEffect(() => {
    getVariantAttributes(productType).then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        setAttributes(response.data);
      }
    });
  }, [productType]);

  useEffect(() => {
    setSelectedAttribute(value);
  }, [value]);

  /**
   * This method is used to trigger component wise on change
   * @param {Array} localAttributes
   */
  const triggerChange = (localAttributes) => {
    const localSelectedAttributes = {};
    Object.keys(localAttributes).forEach((eachAttrib) => {
      if (localAttributes[eachAttrib] && localAttributes[eachAttrib] !== "") {
        localSelectedAttributes[eachAttrib] = localAttributes[eachAttrib];
      }
    });
    onChange(localSelectedAttributes);
  };

  /**
   * This method is used to trigger on change
   * @param {Event} event
   */
  const localOnChange = (event, key) => {
    const { value: localValue } = event.target;
    const localChangedData = { ...selectedAttributes, [key]: localValue };
    setSelectedAttribute(localChangedData);
    triggerChange(localChangedData);
  };

  return (
    <VariantSelector
      register={register}
      errors={errors}
      attributes={attributes}
      selectedAttributes={selectedAttributes}
      onChange={localOnChange}
      name={name}
    />
  );
};

VariantSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  productType: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

VariantSelectorContainer.defaultProps = {
  name: "attributes",
};

export default VariantSelectorContainer;
