// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta\n * (\"Confidential Information\"). You shall not disclose such Confidential\n * Information and shall use it only in accordance with the terms of the\n * contract agreement you entered into with Mozanta.\n *\n * EditPage.module.css\n *\n * @author Naseef O\n *\n */\n\n\n/* block 1 */\n .EditPage_block1__1g7H_ {\n   background: #ffffff;\n   margin: 0px !important;\n   padding-top: 10px !important;\n   margin-bottom: 10px !important;\n}\n\n/* block 2 */\n.EditPage_block2__Idrws{\n  padding-top: 16px !important;\n  background: #ffffff;\n  display: flex;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"block1": "EditPage_block1__1g7H_",
	"block2": "EditPage_block2__Idrws"
};
module.exports = exports;
