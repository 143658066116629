/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/
import React from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import MenuItemCreatorModal from "./MenuItemCreatorContainer";
import MegaMenuItemCreatorContainer from "./MegaMenuItemCreatorContainer";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */

const getAddMainMenuModal = (inProps) => {
  const {
    level, showModal, rootMenus, menuTypes, edit, isWebResponsiveHeader, isWebFooter,
    onSave, onCancel,
    selectedMenuItem, handleRootMenuChange, getLocalKey,
  } = inProps;

  return (
    <MenuItemCreatorModal
      level={level}
      showModal={showModal}
      rootMenus={rootMenus}
      menuTypes={menuTypes}
      edit={edit}
      onSave={onSave}
      onCancel={onCancel}
      selectedMenuItem={selectedMenuItem}
      handleRootMenuChange={handleRootMenuChange}
      getLocalKey={getLocalKey}
      isWebResponsiveHeader={isWebResponsiveHeader}
      isWebFooter={isWebFooter}
    />
  );
};

const getMegaMenuItemCreatorModal = (inProps) => {
  const {
    level, showModal, rootMenus, menuItems, parentMenuId, column, parentId, menuTypes, edit, isWebResponsiveHeader, isWebFooter,
    onSave, onCancel,
    selectedMenuItem, getLocalKey,
  } = inProps;
  return (
    <MegaMenuItemCreatorContainer
      level={level}
      column={column}
      parentId={parentId}
      menuTypes={menuTypes}
      showModal={showModal}
      rootMenus={rootMenus}
      menuItems={menuItems}
      edit={edit}
      onSave={onSave}
      onCancel={onCancel}
      selectedMenu={selectedMenuItem}
      parentMenuId={parentMenuId}
      getLocalKey={getLocalKey}
      isWebResponsiveHeader={isWebResponsiveHeader}
      isWebFooter={isWebFooter}
    />
  );
};

const OpenMenuItemModalContainer = (props) => {
  const {
    level, showModal, rootMenus, parentMenuId, column, parentId, menuTypes, edit, isWebResponsiveHeader, isWebFooter,
    onSave, onCancel, selectedMenuItem, handleRootMenuChange,
    menuItems, getLocalKey,
  } = props;
  return (
    <>
      {
      getAddMainMenuModal({
        level,
        rootMenus,
        parentMenuId,
        column,
        parentId,
        edit,
        menuTypes,
        onSave,
        onCancel,
        selectedMenuItem,
        handleRootMenuChange,
        menuItems,
        getLocalKey,
        showModal: showModal && level === 0,
        isWebResponsiveHeader,
        isWebFooter,
      })
    }
      {
      getMegaMenuItemCreatorModal({
        level,
        rootMenus,
        parentMenuId,
        column,
        edit,
        parentId,
        menuTypes,
        onSave,
        onCancel,
        selectedMenuItem,
        handleRootMenuChange,
        menuItems,
        getLocalKey,
        showModal: showModal && level > 0,
        isWebResponsiveHeader,
        isWebFooter,
      })
    }
    </>
  );
};

OpenMenuItemModalContainer.defaultProps = {
  showModal: false,
  level: 0,
  column: -1,
  onCancel: null,
  selectedMenuItem: "",
  handleRootMenuChange: null,
  parentMenuId: null,
  menuItems: null,
  parentId: null,
  edit: null,
};

OpenMenuItemModalContainer.propTypes = {
  showModal: PropTypes.bool,
  rootMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  parentMenuId: PropTypes.string,
  level: PropTypes.number,
  column: PropTypes.number,
  parentId: PropTypes.string,
  menuTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  edit: PropTypes.objectOf(PropTypes.any),
  isWebResponsiveHeader: PropTypes.bool.isRequired,
  isWebFooter: PropTypes.bool.isRequired,
  /** methods */
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  /** component specific */
  /** for add menu item level 0 */
  selectedMenuItem: PropTypes.string,
  handleRootMenuChange: PropTypes.func,
  /** for add menu item level 1 */
  menuItems: PropTypes.arrayOf(PropTypes.any),
  getLocalKey: PropTypes.func.isRequired,
};


export default OpenMenuItemModalContainer;
