/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Harikrishnan
 *
 */

import httpApi from "./httpApi";

/**
 * This method is used to get  tax class
 *
 * @param {*} retailer
 * @param {*} brand
 * @returns
 */
const getTaxClassByRetaileranBrand = async (retailer, brand) => {
  const response = await httpApi.get(`/v1/tax-classes/retailer/${retailer}/brand/${brand}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to save  tax class
 *
 * @param {*} data
 * @returns
 */
const saveTaxClass = async (data) => {
  const response = await httpApi.post("/v1/tax-classes", data);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get  tax class codes
 *
 * @param {*} retailer
 * @param {*} brand
 * @returns
 */
const getTaxClassCodesByRetailerandBrand = async () => {
  const response = await httpApi.get("/v1/tax-classes/codes");
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};


/** This method is used to change status in tax class
 * @param { String } id
 */
const setTaxClassStatus = async (id) => {
  const response = await httpApi.patch(`v1/tax-classes/${id}/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getTaxClassByRetaileranBrand,
  saveTaxClass,
  getTaxClassCodesByRetailerandBrand,
  setTaxClassStatus,
};
