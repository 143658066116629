/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import clsx from "clsx";
import DatePicker from "react-datetime";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";

/** ========= UTIlS ========= */
import validationConstants from "../../../common/utils/validationConstants";

/** ========= CUSTOM COMPONENTS ========= */
import DateRangePicker from "../../../common/components/DateRangePicker";

/** ========= MODULE STYLES ========= */
import styles from "../css/PromotionForm.module.css";
import InputWrapper from "../../../common/components/InputWrapper";

const PromotionForm = (props) => {
  const {
    id, name, startDate, endDate, handleChange, handleFormChange, indefinite, onCampaign,
    status, validator, priority, translatableFileds, localeHandler, isDefault,
  } = props;

  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);

  /** validation methods */
  const { register, errors, setValue } = validator;
  const { IS_NUMBER } = validationConstants;
  return (
    <>
      <Row className={clsx("common-page-card", "pb-1")}>
        <Col xs="12">
          <Row>
            {
              id && (
                <Col xs="12" sm={onCampaign ? "12" : "6"}>
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("promotion:label_promotion_id")}
                    </Label>
                    <Input
                      className="formText"
                      value={id}
                      disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("id") : false}
                    />
                  </FormGroup>
                </Col>
              )
            }
            <Col xs="12" sm={onCampaign ? "12" : "6"}>
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_name")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <InputWrapper
                  className="formText"
                  placeholder={t("promotion:placeholder_name")}
                  name="name"
                  defaultValue={name}
                  setValue={setValue}
                  onChange={handleFormChange}
                  innerRef={
                    register({
                      required: t("common:validation_name"),
                      minLength: {
                        value: 3,
                        message: t("common:validation_min_three-letter"),
                      },
                      maxLength: {
                        value: 252,
                        message: t("common:validation_too_large"),
                      },
                    })
                  }
                  invalid={!!(errors.name && errors.name.message)}
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("title") : false}
                />
                <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" sm={onCampaign ? "12" : "6"}>
              <FormGroup>
                <Label className="formTextLabel">
                  {t("promotion:label_rank")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <Input
                  className="formText"
                  placeholder={t("promotion:placeholder_promotion_rank")}
                  name="priority"
                  innerRef={
                    register({
                      required: t("promotion:validation_rank_required"),
                      pattern: {
                        value: IS_NUMBER,
                        message: t("promotion:validation_rank_invalid"),
                      },
                      validate: (value) => {
                        const x = parseInt(value, 10);
                        return (isNaN(x) || x < 1) ? t("promotion:validation_rank_out_of_bound") : true;
                      },
                    })
                  }
                  defaultValue={priority}
                  onChange={handleFormChange}
                  invalid={!!(errors.priority && errors.priority.message)}
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("priority") : false}
                />
                <FormFeedback>{errors.priority && errors.priority.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12">
              <FormGroup className="mb-0">
                <DateRangePicker
                  labelClass="formTextLabel"
                  timeFormat="HH:mm:ss"
                  startDateProps={{
                    label: "Start date",
                    noStartDate: false,
                    value: startDate,
                    name: "startDate",
                    disabled: Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("startDate") : false,
                  }}
                  endDateProps={{
                    label: "End date",
                    noEndDate: true,
                    value: endDate,
                    name: "endDate",
                    disabled: Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("endDate") : false,
                  }}
                  indefinite={indefinite}
                  onChange={handleFormChange}
                  id="pcvv"
                />
              </FormGroup>
            </Col>
            <Col xs="12" sm={onCampaign ? "12" : "6"}>
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex", "mt-1")}>
                <Label className="formTextLabel">Status</Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-3",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="enable-promotion"
                    name="active"
                    checked={status}
                    onChange={() => handleChange("status", !status)}
                    disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("active") : false}
                  />
                  <Label className="custom-control-label" htmlFor="enable-promotion" />
                </div>
                <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

PromotionForm.defaultProps = {
  id: null,
};

PromotionForm.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.string.isRequired,
  indefinite: PropTypes.bool.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  priority: PropTypes.number.isRequired,
  // methods
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    setValue: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  translatableFileds: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PromotionForm;
