/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */

/** ========= MODULE STYLES ========= */
import styles from "./styles.module.css";

const Marker = (props) => {
  const {
    text, selected, draggable, onDragging,
  } = props;

  let cursorClass = draggable ? styles.draggable : "";
  cursorClass = onDragging ? styles.dragging : cursorClass;

  return (
    <div className={clsx(styles.marker, cursorClass)}>
      <div
        className={clsx(styles.pin, styles.bounce, selected ? styles.selected : "")}
        alt={text}
        role="presentation"
      />
      {
        selected && <div className={styles.pulse} />
      }
    </div>
  );
};

Marker.defaultProps = {
  selected: false,
  draggable: false,
  onDragging: false,
};

Marker.propTypes = {
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  draggable: PropTypes.bool,
  onDragging: PropTypes.bool,
  // function
};

export default Marker;
