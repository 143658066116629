/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Side Navigation Container
 *
 * @author Vikhil
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import SideNavigation from "../components/SideNavigation";
import { getSideNavItems } from "../../../api/navigationServices";

const SideNavigationContainer = (props) => {
  const {
    hideSideNav, sideNavOpen, isScrolled, navigation,
  } = props;
  const [navItems, setNavItems] = useState([]);
  const history = useHistory();
  const location = useLocation();

  // loading dynamic menu list by navigation change
  useEffect(() => {
    if (navigation) {
      getSideNavItems(navigation).then((response) => {
        if (response && response.success) {
          setNavItems(Array.isArray(response.data) ? response.data : []);
        }
        // const defaultActive = response.filter((item) => item.default)[0];
        // if (defaultActive && defaultActive.path) { history.push(defaultActive.path); }
      });
    }
  }, [navigation]);


  /**
 * This method is used to handle navigation
 * @param {String} path
 */
  const handleNavigation = (path) => {
    history.push(path);
  };

  return Array.isArray(navItems) && navItems.length > 0 ? (
    <SideNavigation
      isScrolled={isScrolled}
      hideSideNav={hideSideNav}
      sideNavOpen={sideNavOpen}
      navItems={navItems}
      navigation={navigation}
      handleNavigation={handleNavigation}
    />
  ) : null;
};

SideNavigationContainer.propTypes = {
  hideSideNav: PropTypes.func.isRequired,
  sideNavOpen: PropTypes.bool.isRequired,
  isScrolled: PropTypes.bool.isRequired,
  // navigation
  navigation: PropTypes.string.isRequired,
};
export default SideNavigationContainer;
