/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */


import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Select from "../../../common/components/MultiSelect/Select";

const DiscountTypeSelectorView = (props) => {
  const {
    name, selectedDiscountType, types, innerRef, invalid,
    onChange, disabled,
  } = props;

  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);

  return (
    <Select
      className="formText"
      placeholder={t("promotion:placeholder_select_discountType")}
      name={name}
      // id="category"
      value={selectedDiscountType}
      onChange={onChange}
      options={[
        {
          value: "", disabled: true, selected: true, label: t("promotion:placeholder_select_discountRule"),
        },
        ...(
          Array.isArray(types)
          && types.map((type) => ({ value: type, label: t(`promotion:label_${type}`) }))
        ),
      ]}
      innerRef={innerRef}
      invalid={invalid}
      disabled={disabled}
    />
  );
};

DiscountTypeSelectorView.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedDiscountType: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.any).isRequired,
  innerRef: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DiscountTypeSelectorView;
