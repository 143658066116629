/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Header
 *
 * @author Naseef O
 *
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import PropTypes from "prop-types";
import styles from "../css/Header.module.css";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownItem from "../../../common/core/DropdownItem";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import NavLink from "../../../common/core/NavLink";
import NavbarText from "../../../common/core/NavbarText";
import NavItem from "../../../common/core/NavItem";
import Nav from "../../../common/core/Nav";
import Navbar from "../../../common/core/Navbar";
import NavbarBrand from "../../../common/core/NavbarBrand";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import logo from "../../../common/assets/images/svg/blueCommerce.svg";
import logoutImage from "../../../common/assets/images/svg/logout.svg";
import language from "../../../common/assets/images/svg/language.svg";
import notification from "../../../common/assets/images/svg/notifications.svg";
import { isAuthenticated } from "../../../common/security/permissionUtils";

const Header = (props) => {
  const { navigation, menuHandler, mainMenus } = props;
  const { t, i18n } = useTranslation(["common", "header"]);

  const [dropdownOpen, setIsOpen] = useState(false);

  const history = useHistory();

  /**
 * This method is used to handle toggle dropdown
 *
 */
  const handleToggleDropdown = () => setIsOpen(!dropdownOpen);

  const [selectedLanguage, setLanguage] = useState();
  useEffect(() => {
    let ln = "English";
    if (i18n.language === "ar") {
      ln = "العربية";
    }
    setLanguage(ln);
  }, [i18n.language]);

  /**
   * This method is used to change language
   *
   * @param {String} lng
   */
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  /**
   * This method is used to change language
   *
   * @param {String} lng
   */
  const handlelogout = () => {
    props.handleLogout();
    history.push("/login");
  };

  /**
 *This method is used to handle scrol
 *
 */
  const handleScroll = () => {
    const element = document.getElementById("menuScrollWrap2");
    const element2 = document.getElementById("headerWrapper");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 46) {
      element.classList.add("headerNavScroll");
      element2.classList.add("headerWrapperScroll");
    } else {
      element.classList.remove("headerNavScroll");
      element2.classList.remove("headerWrapperScroll");
    }
  };

  // Check whether DOM manipulation is a best practice ?


  return (
    <>
      <div id="headerWrapper" className={styles.headerWrapper}>
        <Navbar>
          <Row className={clsx(styles.menuWrap, "m-0 ")}>
            <Col xs="12" className="p-0">
              <NavbarBrand href="/"><img src={logo} className={styles.logo} alt="logo of moz commerce" /></NavbarBrand>
              {isAuthenticated() ? (
                <NavbarText className={styles.logout} onClick={handlelogout}>
                  {t("header:logout")}
                  <img src={logoutImage} alt="logout icon" />
                </NavbarText>
              )
                : null}
              {/*  notification icon */}
              {isAuthenticated() ? (
                <NavbarText className={styles.notification}>
                  <img src={notification} alt="" />
                </NavbarText>
              ) : null}
              {/* <Row className="float-right">
                <span className={styles.selectedLanguage}>{selectedLanguage}</span>
                <Dropdown
                  isOpen={dropdownOpen}
                  className={styles.languageSwitcher}
                  toggle={handleToggleDropdown}
                >
                  <DropdownToggle caret className={styles.dropdownButton}>
                    <img src={language} alt="language switcher" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => changeLanguage("en")}>English</DropdownItem>
                    <DropdownItem onClick={() => changeLanguage("ar")}>العربية</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Row> */}
            </Col>
          </Row>
          <Row id="menuScrollWrap2" className={clsx(styles.menuWrap2)}>
            {isAuthenticated()
              ? (
                <Col>
                  <Nav className="" navbar>
                    {/* adding active class based on navigation value [dynamic select style - color change] */}
                    {
                      Array.isArray(mainMenus) && mainMenus.map((menuItem) => (
                        <NavItem>
                          <NavLink href="" className={menuItem.id === navigation ? "active" : ""} onClick={(o) => menuHandler(menuItem.id, o)}>{menuItem.label}</NavLink>
                        </NavItem>
                      ))
                    }
                  </Nav>
                </Col>
              )
              : null}
          </Row>
        </Navbar>
      </div>
    </>
  );
};

Header.propTypes = {
  mainMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  // navigation
  navigation: PropTypes.string.isRequired,
  menuHandler: PropTypes.func.isRequired,
};

export default Header;
