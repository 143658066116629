/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import PropTypes from "prop-types";
import NoImage from "../../../common/assets/images/no-image.png";

const Image = ({
  fallback, src, className, alt,
}) => (
  <img
    src={src}
    alt={alt}
    className={className}
    onError={(e) => {
      if (e.currentTarget.src !== fallback) { e.currentTarget.src = fallback; }
    }}
  />
);

Image.defaultProps = {
  fallback: NoImage,
  className: null,
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

export default Image;
