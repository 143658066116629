/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */


import React from "react";
import underConstruction from "../assets/images/svg/under-construction.svg";
import Row from "../core/Row";
import Col from "../core/Col";

const UnderConstruction = () => (
  <Row className="under-construction-row">
    <Col xs="12" className="text-center">
      <Row>
        <Col xs="12" className="text-center under-construction-img">
          <img src={underConstruction} width="499px" alt="under construction" />
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-center under-construction ">
          Under construction
        </Col>
      </Row>
    </Col>
  </Row>
);


export default UnderConstruction;
