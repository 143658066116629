/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ======== CUSTOM COMPONENTS ========= */
import ProductSelection from "../components/ProductSelection";
import { uploadChildProductsCSV } from "../../../api/categoryManagementServices";
import AlertModal from "../components/AlertModal";

/** ===== API SERVICE FUNCTIONS =========== */
import { getProducts } from "../../../api/productManagementServices";

/** Base container for add new product  */
const ProductSelectionContainer = ({
  onChange, selectedProductDetails, exclude, categoryId, showRanking, locale, onProductUpload
}) => {
  /** local states */
  /** used to for showing and sorting products */
  const [products, setProducts] = useState([]);
  /** used for store products for rollback sort/filter to default */
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isUploading, setUploading] = useState(false);

  const [modalMattibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });
  /**
   * This method is sued to get product list from server
   * @param {String} searchTerm
   */
  const getProductListFromSever = (searchTerm = "") => {
    getProducts(searchTerm).then((response) => {
      if (response && response.success === true) {
        const { content } = response.data;
        const excludeIn = Array.isArray(exclude) ? exclude : [];
        const filteredProductsList = content.filter((u) => u && !excludeIn.includes(u.id));
        if (Array.isArray(filteredProductsList)) {
          const listedProductId = productList.map(({ id }) => id);
          setProducts(filteredProductsList);
          setProductList([
            ...(filteredProductsList.filter((each) => each && !listedProductId.includes(each.id))),
            ...productList,
          ]);
        }
      }
    });
  };

  useEffect(() => {
    if (Array.isArray(selectedProductDetails) && selectedProductDetails.length > 0) {
      const listedProductId = productList.map(({ id }) => id);
      const filteredList = selectedProductDetails.filter((each) => each
        && !listedProductId.includes(each.id));
      setProductList([
        ...productList,
        ...filteredList,
      ]);
      setSelectedProducts(selectedProductDetails.map(({ id }) => id));
    }
  }, [selectedProductDetails]);

  /**
   * This method is used to change search filed and trigger search functionality
   * @param {Event} event
   */
  const handleSearchFiled = (event) => {
    const { value } = event.target;
    if (value && `${value}`.trim().length > 1) { getProductListFromSever(value); } else { getProductListFromSever(); }
    setSearchKeyword(value);
  };

  /**
   * This method is used to set select / deselect product
   * @param {String} productId
   */
  const setProductSelected = (productId) => {
    const index = selectedProducts.indexOf(productId);
    let newSelectedList = [];
    if (index < 0) {
      /** if item not exist adding in the list */
      newSelectedList = ([...selectedProducts, productId]);
    } else {
      /** if item exits remove form the list */
      newSelectedList = (selectedProducts.filter((o) => o !== productId));
    }
    setSelectedProducts(newSelectedList);
    onChange(newSelectedList || []);
  };

  useEffect(() => {
    getProductListFromSever();
  }, []);

  const uploadCSV = (event) => {
    if (event && event.target && event.target.files) {
      const [file] = event.target.files;
      const fileName = file.name;
      if (!fileName.endsWith(".csv")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a CSV file",
          header: "Error",
        });
      }
      else {
        let formData = new FormData();
        formData.append("file", file);
        setUploading(true);
        uploadChildProductsCSV(categoryId, formData).then((response) => {
          setUploading(false);
          if (response && response.success) {
            onProductUpload();
            if (response.messages) {
              setModalAttributes({
                isOpen: true,
                content: `${response.messages[0]},ActiveProducts in RA(${response.data.activeChildProducts && response.data.activeChildProducts.length > 0 ?  response.data.activeChildProducts.length:"0"}): ${response.data.activeChildProducts.length > 0 ? response.data.activeChildProducts:"no products"}, InActiveProducts in RA(${response.data.inActiveChildProducts && response.data.inActiveChildProducts.length > 0 ?  response.data.inActiveChildProducts.length:"0"}): ${response.data.inActiveChildProducts.length > 0 ? response.data.inActiveChildProducts:"no products"},Not Found such Products in RA(${response.data.childProductsNotfound && response.data.childProductsNotfound.length > 0?  response.data.childProductsNotfound.length:"0"}): ${response.data.childProductsNotfound !=null ? response.data.childProductsNotfound:"no products"},`,
                header: "Success",
              });
            }
          } else if (response && response.messages && Array.isArray(response.messages)) {
            setModalAttributes({
              isOpen: true,
              content: `${response.messages[0]}`,
              header: "Error",
            });
          } else {
            setModalAttributes({
              isOpen: true,
              content: "Something went wrong",
              header: "Error",
            });
          }
        });
      }
    }
    event.target.value = null;
  };
  return (
    <>
      <ProductSelection
        // products
        productList={productList}
        products={products}
        selectedProducts={selectedProducts}
        setProductSelected={setProductSelected}
        // product search
        searchKeyword={searchKeyword}
        handleSearchFiled={handleSearchFiled}
        categoryId={categoryId}
        showRanking={showRanking}
        locale={locale}
        uploadCSV={uploadCSV}
        isUploading={isUploading}
      />
      <AlertModal
        isOpen={modalMattibutes.isOpen}
        content={modalMattibutes.content}
        header={modalMattibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};

ProductSelectionContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedProductDetails: PropTypes.arrayOf(PropTypes.object),
  categoryId: PropTypes.string.isRequired,
  getCategoryDetailsFromServer: PropTypes.func.isRequired,
};

ProductSelectionContainer.defaultProps = {
  selectedProductDetails: [],
};

export default ProductSelectionContainer;
