// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\r\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\r\n *\r\n * All rights reserved.\r\n *\r\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\r\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\r\n * accordance with the terms of the contract agreement you entered into with Mozanta.\r\n *\r\n * @author Amjad Rehman A\r\n */\r\n\r\n.OrderDetails_borderRadius2__36VXh {\r\n  border-radius: 0.125rem !important;\r\n}\r\n\r\n.OrderDetails_btnColor__1v3-v{\r\n  background: #3a9fe3 !important;\r\n  border-color: #3a9fe3 !important;\r\n  min-width: 50%;\r\n}\r\n\r\n.OrderDetails_block1__2Zs3M{\r\n  min-height: -webkit-fill-available;\r\n}\r\n\r\n.OrderDetails_block2__36Agn{\r\n  display: flex;\r\n  justify-content: center;\r\n}", ""]);
// Exports
exports.locals = {
	"borderRadius2": "OrderDetails_borderRadius2__36VXh",
	"btnColor": "OrderDetails_btnColor__1v3-v",
	"block1": "OrderDetails_block1__2Zs3M",
	"block2": "OrderDetails_block2__36Agn"
};
module.exports = exports;
