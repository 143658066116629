/* eslint-disable react/jsx-props-no-spreading */
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Public Router
 *
 */
import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

const PublicRouter = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => <Component {...props} {...rest} />
    }
  />
);

PublicRouter.defaultProps = {
  location: null,
};

PublicRouter.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.func,
};

export default PublicRouter;
