/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */
import AddCategory from "../components/AddCategory";

/** ===== API SERVICE FUNCTIONS =========== */
import { addCategory } from "../../../api/categoryManagementServices";

/** Base container for add new category  */
const AddCategoryContainer = () => {
  const history = useHistory();

  /** local states */
  const [form, setForm] = useState({
    name: "",
    description: "",
    longDescription: "",
    startDate: new Date(),
    endDate: new Date(),
    status: false,
    childProducts: [],
    type: "Root",
    source: "RetailAdmin",
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState("");
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method triggers when a subcategory selected or deselected
   * @param {Array} categories
   */
  const selectedCategoryChange = (categories) => {
    setSelectedCategories(categories);
  };


  /**
   * This method is used to change parent category
   * @param {Array} categories
   */
  const handleParentCategory = (categories) => {
    setParentCategory(categories);
  };

  /**
   * Clear button trigger
   */
  const cancelForm = () => {
    history.push("/merchandising/category");
  };

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const submitForm = async (event) => {
    const dataToServer = {
      ...form,
      startDate: moment(form.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(form.endDate).format("YYYY-MM-DD HH:mm:ss"),
      parentCategory,
      childCategories: selectedCategories,
    };
    setMessage({ type: null, message: "" });
    setOnSubmitting(true);
    /** now passing object itself into service */
    const response = await addCategory(dataToServer);
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages[0] });
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/merchandising/category");
        setMessage({ type: null, message: "" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      console.error(response.error);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  return (
    <AddCategory
      // form field
      name={form.name}
      description={form.description}
      longDescription={form.longDescription}
      startDate={form.startDate}
      endDate={form.endDate}
      status={form.status}
      type={form.type}
      source={form.source}
      // for showing success message
      message={message}
      // disable text filed while form submitting
      onSubmitting={onSubmitting}
      // input filed handle change
      handleFormChange={handleFormChange}
      handleChange={handleChange}
      // form actions
      submitForm={submitForm}
      cancelForm={cancelForm}
      // categories
      selectedCategories={selectedCategories}
      // parent category
      parentCategory={parentCategory}
      handleParentCategory={handleParentCategory}
      // sub category
      selectedCategoryChange={selectedCategoryChange}
    />
  );
};

export default AddCategoryContainer;
