/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 * */

import httpApi from "./httpApi";

/**
 * This method is used  to get list of  customers
 * */

const getCustomers = async (searchTerm, page, size) => {
  const response = await httpApi.get("v1/users/customers", {
    params: {
      searchTerm, page, size,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to transform Customers data
 * @param Array
 * @returns {Array}
 */
const transformCustomerData = async (customers) => {
  const filtered = customers.map(({
    firstName,
    lastName,
    email,
    dialCode,
    mobile,
  }) => ({
    "First Name": firstName,
    "Last Name": lastName,
    Email: email,
    "Phone No": `${dialCode || ""}${mobile || ""}`,
  }));
  return filtered;
};
const initiateResetPassword = async (resetPasswordRequest) => {
  const response = await httpApi.put("/v1/users/password-reset", resetPasswordRequest);
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  getCustomers,
  transformCustomerData,
  initiateResetPassword,
};
