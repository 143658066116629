/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";

import styles from "../css/JobConfiguration.module.css";
import JobTask from "./JobTask";
import JobDetails from "./JobDetails";

const JobReview = ({ jobBasicConfigData, jobTaskData, cancelJobCreation, saveJobCreation }) => {
  const { t } = useTranslation(["common", "job-configuration"]);
  return (
    <Col xs="12">
      <Row>
        <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>
          {t("job-configuration:span_job_review")}
        </span>
      </Row>
      <Row>
        <JobDetails
          jobBasicConfigData={jobBasicConfigData}
        />
      </Row>
      <JobTask jobTaskData={jobTaskData} />
      <Row className={styles.areaActionRight}>
        <Button
          color="secondary"
          className={clsx(
            styles.borderRadius2,
            styles.btn,
            styles.secondaryColor,
            "text-uppercase",
            "text-truncate",
            "btn-tertiary",
            "ml-1",
          )}
          onClick={cancelJobCreation}
        >
          {t("common:button_cancel")}
        </Button>
        <Button
          color="primary"
          className={clsx(
            styles.borderRadius2,
            "text-uppercase",
            "text-truncate",
            "btn-tertiary",
            "ml-1",
          )}
          onClick={saveJobCreation}
        >
          {t("job-configuration:button_confirm_job")}
        </Button>
      </Row>
    </Col>
  );
};

JobReview.propTypes = {
  jobBasicConfigData: PropTypes.objectOf(PropTypes.any).isRequired,
  jobTaskData: PropTypes.objectOf(PropTypes.any).isRequired,
  cancelJobCreation: PropTypes.func.isRequired,
  saveJobCreation: PropTypes.func.isRequired,
};
export default JobReview;
