/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * DragAndDrop Table Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DragAndDropTable from "../components/DragAndDropTable";
import { getPageCustomizationRows, updatePageContent } from "../../../api/pageContentServices";

const DragAndDropTableContainer = (props) => {
  const {
    widget,
    widgetType,
    configId,
    handleRedirectToWidgetContentEditor,
    getPageContents,
    deleteEnabled,
    categoryId,
    channel,
    locale,
  } = props;
  const [pageCustomizationRows, setPageCustomizationRows] = useState([]);
  useEffect(() => {
    getPageCustomizationRows().then((response) => {
      setPageCustomizationRows(response);
    });
  }, []);


  const [customizationData, setCustomizationData] = useState(widget.contents);

  useEffect(() => {
    setCustomizationData(widget.contents);
  }, [widget.contents]);
  /**
   *This method is used to modify the list after drag
   *
   * @param {Array} list
   * @param {Number} startIndex
 * @param {Number} endIndex
 * @returns
 */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   *This method is used ito on drag end
 *
 * @param {object} result
 * @returns
 */
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rowData = reorder(
      customizationData,
      result.source.index,
      result.destination.index,
    );
    setCustomizationData(rowData);
    await updatePageContent({
      ...widget,
      widgetType,
      configId,
      contents: rowData,
      categoryId,
    }, channel, locale).then((response) => {
      if (response && response.success) {
        console.log("success");
      }
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  /**
   * This method is used to delete a content
   *
   * @param {*} indexNumber
   */
  const handleDelete = async (indexNumber) => {
    setIsLoading(true);
    setSelectedIndex(indexNumber);
    const contents = customizationData.filter((item, index) => index !== indexNumber);
    await updatePageContent({
      ...widget,
      widgetType,
      configId,
      contents,
      categoryId,
    }, channel, locale).then((response) => {
      if (response && response.success) {
        getPageContents();
        setIsLoading(false);
      }
    });
  };

  /**
   * This method is used to get  handle change status
   * @param {*} value
   */
  const handleChangeStatus = async (status, rowIndex) => {
    const requestData = {
      ...widget,
      id: widget.id,
      widgetType,
      configId,
      contents: customizationData.map((h, l) => (l === rowIndex ? ({ ...h, active: status }) : h)),
      categoryId,
    };
    const response = await updatePageContent(requestData, channel, locale);
    if (response) {
      const { success } = response;
      if (success) {
        getPageContents();
      }
    }
  };

  return (
    <DragAndDropTable
      widgetType={widgetType}
      configId={configId}
      handleRedirectToWidgetContentEditor={handleRedirectToWidgetContentEditor}
      pageCustomizationRows={pageCustomizationRows}
      customizationData={customizationData}
      onDragEnd={onDragEnd}
      handleDelete={handleDelete}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      deleteEnabled={deleteEnabled}
      handleChangeStatus={handleChangeStatus}
      widget={widget}
    />
  );
};
DragAndDropTableContainer.propTypes = {
  widget: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  getPageContents: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
  channel: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};
export default DragAndDropTableContainer;
