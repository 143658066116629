// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer_footerWrapper__sTjj0{\n    background: #ffffff;\n    padding: 20px 10px 20px 10px;\n    color: #5d5d5d;\n}\n.Footer_textUnderline__1CoH-{\n    text-decoration: underline;\n}", ""]);
// Exports
exports.locals = {
	"footerWrapper": "Footer_footerWrapper__sTjj0",
	"textUnderline": "Footer_textUnderline__1CoH-"
};
module.exports = exports;
