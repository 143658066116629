/* eslint-disable react/jsx-no-duplicate-props */
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";

const NewTaxClassForm = ({ setName, setCode, setDescription, validator }) => {
  const { t } = useTranslation(["tax-configuration"]);
  const { register, errors } = validator;

  return (
    <>
      <Row>
        <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("tax-configuration:label_name")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <Input
              className="formText"
              placeholder=""
              name="name"
              onChange={(e) => setName(e.target.value)}
              innerRef={
                register({
                  required: t("common:validation_name"),
                  minLength: {
                    value: 3,
                    message: t("common:validation_min_three-letter"),
                  },
                  maxLength: {
                    value: 252,
                    message: t("common:validation_too_large"),
                  },
                })
              }
              invalid={!!(errors.name && errors.name.message)}
            />
            <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("tax-configuration:label_code")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <Input
              className="formText"
              placeholder=""
              name="code"
              onChange={(e) => setCode(e.target.value)}
              innerRef={
                register({
                  required: t("common:validation_code"),
                  minLength: {
                    value: 3,
                    message: t("common:validation_min_three-letter"),
                  },
                  maxLength: {
                    value: 252,
                    message: t("common:validation_too_large"),
                  },
                })
              }
              invalid={!!(errors.code && errors.code.message)}
            />
            <FormFeedback>{errors.code && errors.code.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("tax-configuration:label_description")}
            </Label>
            <Input
              type="textarea"
              className="formText"
              placeholder=""
              name="name"
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

NewTaxClassForm.propTypes = {
  setName: PropTypes.func.isRequired,
  setCode: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default NewTaxClassForm;
