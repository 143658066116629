/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */


import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Alert from "../../../common/core/Alert";

/** ========= CUSTOM COMPONENTS ========= */
import CustomizationTable from "../../../common/components/Table";

/** ============ SVG IMAGE ICON ===================== */
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

/** ===== MODULE STYLES =========== */
import styles from "../css/BrandManagement.module.css";

const BrandManagement = (props) => {
  const {
    brands,
    submitting, selectedBrandId, searchTerm, loading,
    keyPress, clearSearch, searchTermHandler,
    addBrand, handleBrandStatus, editBrand, removeBrand,
    handlePushToLive,
    pushToLiveEnabled,
    message,
    previewSiteUrl,
    previewEnabled,
    getReplicationStatus,
    pushToLiveButtonEnabled,
  } = props;
  /** internationalization using i18n from common and brand  */
  const { t } = useTranslation(["common", "brand"]);

  const redirectToPage = () => {
    const url = `${previewSiteUrl}/brands`;
    window.open(url, "_blank");
  };

  /**
   * This method use use to crate status change toggle for table
   * @param {Object} row
   * @returns {Element}
   */
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
          submitting && selectedBrandId === row.id ? "loading" : "",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) => handleBrandStatus(!row.active, row.id, o)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };

  /**
   * This method is used to create action component for table
   * @param {Object} row
   * @returns {Element}
   */
  const getActions = (row) => (
    <div className={clsx("d-flex", "justify-content-start", styles.actions)}>
      <span
        // disabled={!row.active}
        className="commonPointer mr-2"
        onClick={(o) => editBrand(row.id, o)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={EditIcon}
          alt="Edit"
        />
      </span>
      {
        false && (
          <span
            className="commonPointer"
            onClick={(o) => removeBrand(row.id, o)}
            role="button"
            tabIndex={0}
            onKeyPress={() => { }}
          >
            <img
              className={styles.icon}
              src={DeleteIcon}
              alt="Delete"
            />
          </span>
        )
      }
    </div>
  );

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">{t("brand:title_brand_manage")}</span>
        </Col>
        <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
          <Row>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                  onClick={() => redirectToPage()}
                >
                  {t("common:button_preview")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col className="mb-2" xs="12" sm="12" md="4">
          <span
            className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
          >
            {t("brand:title_manage_brands")}
          </span>
        </Col>
        <Col className="mb-2" xs="12" sm>
          <div className={styles.areaActionRight}>
            <div>
              <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                <Input placeholder="Search brand" value={searchTerm} onChange={searchTermHandler} onKeyDown={keyPress} />
                <InputGroupAddon
                  addonType="append"
                  className="commonPointer bg-color-tertiary-main"
                  onClick={searchTerm && clearSearch}
                >
                  <img
                    src={searchTerm ? ClearIcon : SearchIcon}
                    alt={searchTerm ? "clear search" : "search brand"}
                    className={styles.icon}
                  />
                </InputGroupAddon>
              </InputGroup>
            </div>
            {
              false && (
                <div>
                  <Button
                    onClick={addBrand}
                    className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
                  >
                    {t("brand:button_new")}
                  </Button>
                </div>
              )
            }
          </div>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <div className="custom-table" style={{ opacity: loading ? "0.5" : "1" }}>
            <CustomizationTable
              customRows={[
                {
                  label: t("brand:label_brand_id"),
                  path: "id",
                },
                {
                  label: t("common:label_name"),
                  path: "name",
                },
                {
                  label: t("common:label_createdDate"),
                  path: "createdDate",
                },
                {
                  label: t("common:label_status"),
                  path: "render",
                  render: getStatus,
                },
                {
                  label: t("common:label_actions"),
                  path: "render",
                  render: getActions,
                },
              ]}
              customizationData={brands}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

BrandManagement.defaultProps = {
  selectedBrandId: null,
};

BrandManagement.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedBrandId: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  addBrand: PropTypes.func.isRequired,
  editBrand: PropTypes.func.isRequired,
  removeBrand: PropTypes.func.isRequired,
  handleBrandStatus: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  keyPress: PropTypes.func.isRequired,
  searchTermHandler: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
};

export default BrandManagement;
