/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";

/** ========== SUB COMPONENT ================ */

/** ======== MODULE STYLES ========== */
import styles from "../css/BrandForm.module.css";

const BrandForm = (props) => {
  const {
    id, name, longDescription, description, startDate, endDate, status, exclusiveBrand,
    handleFormChange, handleChange, validator,
  } = props;

  /** validation methods */
  const { register, errors } = validator;

  /** * internationalization using i18n from common and brand ** */
  const { t } = useTranslation(["common", "brand"]);

  return (
    <Row className={clsx(styles.block3, "pt-3", "pb-1")}>
      <Col xs="10">
        <Row>
          {
            id
            && (
              <Col xs="12" sm="5">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("brand:label_brand_id")}
                  </Label>
                  <Input className="formText" disabled placeholder="Brand ID" name="id" value={id} required />
                </FormGroup>
              </Col>
            )
          }
          <Col xs="12" sm={id ? "7" : "5"}>
            <FormGroup>
              <Label className="formTextLabel">
                {t("brand:label_name")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <Input
                className="formText"
                placeholder={t("brand:placeholder_brand_name")}
                name="name"
                defaultValue={name}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: t("brand:error_required_brand_name"),
                    minLength: {
                      value: 3,
                      message: t("brand:error_valid_brand_name"),
                    },
                  })
                }
                invalid={!!(errors.name && errors.name.message)}
              />
              <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm={id ? "12" : "7"}>
            <FormGroup>
              <Label className="formTextLabel">
                {t("brand:label_short_description")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <Input
                className="formText"
                placeholder={t("brand:placeholder_short_description")}
                name="description"
                defaultValue={description}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: t("brand:error_length_short_description"),
                    minLength: {
                      value: 12,
                      message: t("brand:error_length_short_description"),
                    },
                    maxLength: {
                      value: 50,
                      message: t("brand:error_max_length_short_description"),
                    },
                  })
                }
                invalid={!!(errors.description && errors.description.message)}
              />
              <FormFeedback>{errors.description && errors.description.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label className="formTextLabel">
                {t("brand:label_long_description")}
              </Label>
              <Input className="formText" placeholder={t("brand:placeholder_long_description")} name="longDescription" value={longDescription} onChange={handleFormChange} />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label className="formTextLabel">
                {t("brand:label_startDate")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <DatePicker
                id="endate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
                input
                inputProps={{
                  placeholder: `Ex: ${moment().format("DD/MM/YYYY")}`,
                  name: "startDate",
                }}
                value={moment(startDate).format("DD/MM/YYYY")}
                onChange={(o) => (moment.isMoment(o) && handleChange("startDate", o.toDate()))}
                required
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label className="formTextLabel">
                {t("brand:label_endDate")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <DatePicker
                id="endate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
                input
                inputProps={{
                  placeholder: `Ex: ${moment().format("DD/MM/YYYY")}`,
                }}
                name="endDate"
                value={moment(endDate).format("DD/MM/YYYY")}
                onChange={(o) => (moment.isMoment(o) && handleChange("endDate", o.toDate()))}
                required
                isValidDate={(current) => current.isAfter(startDate)}
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="6" sm="5">
            <FormGroup>
              <Label className="formTextLabel">{t("common:label_status")}</Label>
              <div
                className={clsx(
                  "custom-control",
                  "custom-switch",
                  "ml-1 pt-2",
                )}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="status-new-brand"
                  name="status"
                  checked={status}
                  onChange={() => handleChange("status", !status)}
                />
                <Label className="custom-control-label" htmlFor="status-new-brand" />
              </div>
            </FormGroup>
          </Col>
          <Col xs="12" md="6" sm="5">
            <FormGroup>
              <Label className="formTextLabel">{t("brand:label_exclusive_brand")}</Label>
              <div
                className={clsx(
                  "custom-control",
                  "custom-switch",
                  "ml-1 pt-2",
                )}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="status-new-exclusive-brand"
                  name="status"
                  checked={exclusiveBrand}
                  onChange={() => handleChange("exclusiveBrand", !exclusiveBrand)}
                />
                <Label className="custom-control-label" htmlFor="status-new-exclusive-brand" />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

BrandForm.defaultProps = {
  id: null,
};

BrandForm.propTypes = {
  // form field
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  validator: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  exclusiveBrand: PropTypes.bool.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default BrandForm;
