/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import MenuItemCreator from "../components/MenuItemCreator";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import ImageCropper from "../../../common/components/imageCropper/Cropper";

/** ========= API SERVICE FUNCTIONS ========= */
import { uploadImage } from "../../../api/pageContentServices";

const MenuItemCreatorContainer = (props) => {
  const {
    level, showModal, rootMenus, selectedMenuItem, menuTypes, edit, isWebResponsiveHeader, isWebFooter,
    onSave, onCancel, handleRootMenuChange,
    getLocalKey,
  } = props;

  const aspectRatio = 16 / 16;

  /** local states */
  const [form, setForm] = useState({
    menu: selectedMenuItem,
    active: true,
    parentMenu: "",
    name: "",
    url: "",
    type: "",
    navigable: false,
    allowDropdown: isWebFooter || isWebResponsiveHeader || false,
    image: null,
    menus: [
      {
        id: `col-p-${getLocalKey()}-06`,
        columnConfig: {
          columnWidth: 6,
          displayType: "BasicItem",
        },
        menus: [],
      },
    ],
  });
  const [open, setOpen] = useState(showModal);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [menus, setMenus] = useState(Array.isArray(rootMenus) ? rootMenus : []);

  const [imageUrl, setImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (edit) {
      setForm((oldForm) => ({
        ...oldForm,
        ...edit,
      }));
    }
  }, [edit]);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    setMenus(Array.isArray(rootMenus) ? rootMenus : []);
  }, [rootMenus]);

  useEffect(() => {
    setMenus(Array.isArray(rootMenus) ? rootMenus : []);
  }, [rootMenus]);

  useEffect(() => {
    setForm((oldState) => ({
      ...oldState,
      allowDropdown: isWebFooter || isWebResponsiveHeader || false,
    }));
  }, [isWebFooter, isWebResponsiveHeader]);

  /**
   * This method is used to set sate to default
   */
  const setToDefault = () => {
    setForm({
      menu: selectedMenuItem,
      active: true,
      parentMenu: "",
      name: "",
      url: "",
      type: "",
      navigable: false,
      allowDropdown: isWebFooter || isWebResponsiveHeader || false,
      image: null,
      menus: [
        {
          id: `col-p-${getLocalKey()}-06`,
          columnConfig: {
            columnWidth: 6,
            displayType: "BasicItem",
          },
          menus: [],
        },
      ],
    });
    setOnSubmitting(false);
  };

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm((oldState) => ({
      ...oldState,
      ...form,
      [name]: value,
    }));
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used to send data to server
   */
  const addNewMenuItemServerCall = () => {
    setOnSubmitting(true);
    const dataToServer = {
      ...form,
      id: form.id || `menu_pid_${getLocalKey()}`,
      menu: selectedMenuItem,
    };
    onSave(dataToServer, level);
    setOpen(false);
    setToDefault();
    setOnSubmitting(false);
  };

  /**
   * This method is used to submit the form
   */
  const formSubmit = () => {
    addNewMenuItemServerCall();
  };

  /**
   * This method is used to hide/ cancel the modal / form
   */
  const formCancel = (clear = false) => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
    if (clear) setToDefault();
  };

  /**
   * This function is used to select image
   * @param {Event} event
   */
  const handleImageSelection = (event) => {
    if (event.target) {
      const { target } = event;
      const { files } = target;
      if (files[0]) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          setForm({
            ...form, file, image: e.target.result,
          });
          setImageUrl(e.target.result);
          setModalOpen(true);
          if (target) {
            target.files = null;
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  /**
   * This function is ue to get uploaded image url
   * @param {Object} newMedia
   */
  const handleUploadImage = (newMedia) => {
    handleChange("image", (newMedia && newMedia.largeMediaUrl) || null);
  };

  /** This function is used to close the image upload modal */
  const handleCloseModal = () => {
    setImageUrl("");
    setModalOpen(false);
    setForm({
      ...form, image: null,
    });
  };

  return (
    <>
      <MenuItemCreator
        level={level}
        open={open}
        menu={selectedMenuItem}
        name={form.name}
        navigable={form.navigable}
        url={form.url}
        image={form.image}
        allowDropdown={form.allowDropdown}
        type={form.type}
        menus={menus}
        onSubmitting={onSubmitting}
        menuTypes={menuTypes}
        onEdit={Boolean(edit)}
        isWebResponsiveHeader={isWebResponsiveHeader}
        isWebFooter={isWebFooter}
      /** form methods */
        handleChange={handleChange}
        handleFormChange={handleFormChange}
        formCancel={formCancel}
        formSubmit={formSubmit}
        handleRootMenuChange={handleRootMenuChange}
        handleImageSelection={handleImageSelection}
      />
      <ImageCropper
        imageUrl={imageUrl}
        modalOpen={modalOpen}
        aspect={aspectRatio}
        setModalOpen={setModalOpen}
        handleUpload={handleUploadImage}
        imageUploadApi={uploadImage}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

MenuItemCreatorContainer.defaultProps = {
  showModal: false,
  level: 0,
  onCancel: null,
  selectedMenuItem: null,
  edit: null,
};

MenuItemCreatorContainer.propTypes = {
  showModal: PropTypes.bool,
  rootMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  level: PropTypes.number,
  selectedMenuItem: PropTypes.string,
  menuTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  edit: PropTypes.objectOf(PropTypes.any),
  isWebResponsiveHeader: PropTypes.bool.isRequired,
  isWebFooter: PropTypes.bool.isRequired,
  /** methods */
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  handleRootMenuChange: PropTypes.func.isRequired,
  getLocalKey: PropTypes.func.isRequired,
};

export default MenuItemCreatorContainer;
