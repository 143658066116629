/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import moment, { months } from "moment";
import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
/** ========= TAG COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */
import CampaignEditorView from "../components/CampaignEditorView";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { getCampaign } from "../../../api/campaignServices";
import { getTranslatedInputsByPage } from "../../../api/translationServices";

const CampaignEditor = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  /** redirecting to listing page if there is not any menu id in url */
  if (!params.campaignId) {
    history.push("/marketing/campaign");
  }

  /** local states */
  const [form, setForm] = useState({
    basicInfo: null,
    customerGroups: [],
    coupons: [],
    promotions: [],
  });
  const [activeStep, setActiveStep] = useState(1);
  const [selectedLanguage, setLanguage] = useState("en");
  const [translatableFileds, setSelectedtranslatableFiledsect] = useState([]);

  useEffect(() => {
    getCampaign(params.campaignId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        const basicInfo = {
          id: data.id,
          name: data.name,
          description: data.description,
          active: Number(data.active),
          startDate: (data.startDate && moment(data.startDate)) || null,
          endDate: (data.endDate && moment(data.endDate)) || null,
          indefinite: data.indefinite,
        };

        const customerGroups = Array.isArray(data.applicableUserGroups) ? data.applicableUserGroups.map((applicableUserGroup) => ({
          id: applicableUserGroup.id,
          name: applicableUserGroup.name,
          description: applicableUserGroup.description || "",
        })) : [];
        const coupons = Array.isArray(data.applicableCoupons) ? data.applicableCoupons.map((id) => ({ id, couponCode: id, active: true })) : [];
        const promotions = Array.isArray(data.promotions) ? data.promotions.map((promotion) => ({
          id: promotion.promotionId,
          title: promotion.promotionId,
          description: promotion.description || "",
          coupons: Array.isArray(promotion.associatedCoupons) ? promotion.associatedCoupons : [],
          customerGroups: Array.isArray(promotion.applicableTo) ? promotion.applicableTo : [],
          applyToEveryone: promotion.applyToEveryone,
        })) : [];

        setForm({
          basicInfo,
          customerGroups,
          coupons,
          promotions,
        });
      }
    });
  }, [params.campaignId]);

  useEffect(() => {
    getTranslatedInputsByPage("campaign").then((response) => {
      if (response && response.data) {
        const { data } = response;
        if (data) {
          setSelectedtranslatableFiledsect(data);
        }
      }
    });
  }, []);

  /**
     * This method is used to change fom state
     * @param {String} name
     * @param {String} value
     */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
     * This function is used to change step view
     * @param {Number} stepIndex
     */
  const switchStep = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  /**
     * This method is used to redirect to campaign listing page
     */
  const backButtonHandler = () => {
    history.push("/marketing/campaign");
  };

  /**
     * This function is used to change steps
     * @param {Number} stepIndex
     */
  const nextStepHandler = (stepIndex) => {
    switchStep(stepIndex);
  };

  /**
   * Method to select Language
   */
  const selectedLanguageChange = (value) => {
    setLanguage(value);
  };

  return (
    <CampaignEditorView
      activeStep={activeStep}
      customerGroups={form.customerGroups}
      coupons={form.coupons}
      basicInfo={form.basicInfo}
      promotions={form.promotions}
      // functions
      backButtonHandler={backButtonHandler}
      switchStep={switchStep}
      nextStepHandler={nextStepHandler}
      handleChange={handleChange}
      selectedLanguageChange={selectedLanguageChange}
      translatableFileds={translatableFileds}
      selectedLanguage={selectedLanguage}
    />
  );
};

export default CampaignEditor;
