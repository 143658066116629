/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima-Mohan
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTitleSelector from "../components/MetaTitleSelector";

import { getMetaTitle } from "../../../api/SeoSettingsService";

const MetaTitleSelectorContainer = ({ value, name, onChange }) => {
  const [MetaTitleTypes, setMetaTitleTypes] = useState([]);
  const [selectedMetaTitleType, setSelectedMetaTitleType] = useState();
  
  useEffect(() => {
    /** use to get meta  title by default */
    getMetaTitle().then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        setMetaTitleTypes(response.data);
      }
    });
  }, []);
  
  useEffect(() => {
    setSelectedMetaTitleType(value);
  }, [value]);

  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };
  
  return (
    <MetaTitleSelector
      types={MetaTitleTypes}
      selectedMetaTitleType={selectedMetaTitleType}
      onChange={localOnChange}
      name={name}
    />
  );
};

MetaTitleSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default MetaTitleSelectorContainer;
