/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import CustomizationTable from "../../../common/components/Table";
import styles from "../css/OrderDetails.module.css";
import leftArrowLong from "../../../common/assets/images/svg/left-arrow-long.svg";
import OrderNoteFormContainer from "../containers/OrderNoteFormContainer";

const moment = require("moment");

const customRows = [
  {
    label: "SKU",
    value: "",
    path: "skuId",
    transform: "",
  },
  {
    label: "External Id",
    value: "",
    path: "uniqueId",
    transform: "",
  },
  {
    label: "Quantity",
    value: "",
    path: "quantity",
    transform: "",
  },
  {
    label: "List Price",
    value: "",
    path: "priceInfo.listPrice",
    transform: "",
  },
  {
    label: "Sale Price",
    value: "",
    path: "priceInfo.salePrice",
    transform: "",
  },
  {
    label: "Discount",
    value: "",
    path: "priceInfo.discount.grossPrice",
    transform: "",
  },
  {
    label: "Taxable amount",
    value: "",
    path: "priceInfo.grossPrice",
    transform: "",
  },
  {
    label: "Tax percent",
    value: "",
    path: "priceInfo.tax.taxPercentage",
    transform: "",
  },
  {
    label: "Tax (amount)",
    value: "",
    path: "priceInfo.tax.amount",
    transform: "",
  },
  {
    label: "Row Total",
    value: "",
    path: "priceInfo.discountedSubTotal",
    transform: "",
  },
];

const promotionRows = [
  {
    label: "Promotion Id",
    value: "",
    path: "adjustmentId",
    transform: "",
  },
  {
    label: "Promotion Name",
    value: "",
    path: "adjustmentName",
    transform: "",
  },
  {
    label: "Coupon Applied",
    value: "",
    path: "coupon",
    transform: "",
  },
  {
    label: "Applied To",
    value: "",
    path: "appliedTo",
    transform: "",
  },
  {
    label: "Amount",
    value: "",
    path: "render",
    render: (row) => row && row.grossPrice ? Math.abs(row.grossPrice).toFixed(2) : 0,
    transform: "",
  },
];

const OrderDetails = ({ order, onHandleBack }) => {
  const [promotionList, setPromotionList] = useState([]);
  const { t } = useTranslation(["common", "order-details"]);

  const getPromotions = () => {
    let promotionList = [];
    if (order) {
      const { priceInfo, lineItems, shipments } = order;
      const { adjustments } = priceInfo || [];
      if (adjustments && adjustments.length) {
        const updatedAdjustments = [];
        adjustments.map((adjustment) => {
          const updatedAdjustment = adjustment;
          updatedAdjustment.appliedTo = "Order";
          updatedAdjustments.push(updatedAdjustment);
        });
        promotionList = promotionList.concat(updatedAdjustments);
      }
      lineItems && lineItems.map((lineItem) => {
        const { priceInfo: { adjustments: lineItemAdjustments } } = lineItem;
        if (lineItemAdjustments && lineItemAdjustments.length) {
          const updatedLineItemAdjustments = [];
          lineItemAdjustments.map((lineItemAdjustment) => {
            const updatedLineItemAdjustment = lineItemAdjustment;
            updatedLineItemAdjustment.appliedTo = `Product: ${lineItem.productId}`;
            updatedLineItemAdjustments.push(updatedLineItemAdjustment)
          });
          promotionList = promotionList.concat(updatedLineItemAdjustments);
        }
      });
      shipments && shipments.map((shipment) => {
        const { priceInfo: { adjustments: shipmentAdjustments } } = shipment;
        if (shipmentAdjustments && shipmentAdjustments.length) {
          const updatedShipmentAdjustments = [];
          shipmentAdjustments.map((shipmentAdjustment) => {
            const updatedShipmentAdjustment = shipmentAdjustment;
            updatedShipmentAdjustment.appliedTo = `Shipment: ${shipment.shipmentId}`;
            updatedShipmentAdjustments.push(updatedShipmentAdjustment)
          });
          promotionList = promotionList.concat(updatedShipmentAdjustments);
        }
      })
    }
    return promotionList;
  }

  useEffect(() => {
    const promotionList = getPromotions(order);
    setPromotionList(promotionList);
  }, [order]);

  if (order) {
    const {
      id,
      orderDate,
      status,
      priceInfo,
      lineItems,
      billingAddress,
      orderNotes,
      shipments,
      currency,
      email,
      orderNo,
      paymentType,
      guestOrder,
    } = order;
    const {
      grossPrice, netPrice, tax, discountedSubTotal, shippingCharge, discounts,
    } = priceInfo || "";
    const { taxPercentage, amount } = tax || "";
    const { shippingAddress } = shipments ? shipments[0] : "";
    const {
      firstName,
      lastName,
      contactPerson,
      addressLine1,
      addressLine2,
      city,
      district,
      state,
      telephone1,
      country,
      postCode,
      dialCode,
    } = billingAddress || "";
    const {
      contactPerson: shippingAddressContactPerson,
      addressLine1: shippingAddressAddressLine1,
      addressLine2: shippingAddressAddressLine2,
      city: shippingAddressCity,
      district: shippingAddressDistrict,
      state: shippingAddressState,
      email: shippingAddressEmail,
      telephone1: shippingAddressTelephone1,
      country: shippingAddressCountry,
      postCode: shippingAddressPostCode,
      dialCode: shippingAddressDialCode,
    } = shippingAddress || "";
    const customerName = contactPerson ? contactPerson : `${firstName} ${lastName}`;
    return (
      <>
        <Row className="common-page-card pageHeader">
          <Col xs="12">
            <span className={clsx(styles.leftArrow, "mr-2")}>
              <img
                src={leftArrowLong}
                alt="click left"
                width="30"
                height="auto"
                onClick={() => onHandleBack(null)}
              />
            </span>
            <span className="pageText">
              {t("order-details:order_details_title")}
            </span>
          </Col>
          <Col xs="12" md="4" lg="5" />
        </Row>
        <Row className={clsx(styles.block1, "common-page-card ")}>
          <Col xs="12">
            <Row className="m-0">
              <Col xs="6" className="p-2">
                <Row>
                  <Col md="12" className="p-2">
                    <span
                      className={clsx(
                        styles.pageSelectionText,
                        "fontsizeMediumBold",
                      )}
                    >
                      {t("order-details:span_order_summary")}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_order_number")}
                      </p>
                      <p>{orderNo || "---"}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_order_date")}
                      </p>
                      <p>{orderDate ? moment(orderDate).format("YYYY-MM-DD HH:mm:ss") : "---"}</p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_order_status")}
                      </p>
                      <p>{status || "---"}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_payment_method")}
                      </p>
                      <p>
                        {paymentType || "---"}
                      </p>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_order_amount")}
                      </p>
                      <p>
                        {currency}
                        {" "}
                        {grossPrice || "---"}
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col xs="6" className="p-2">
                <Row>
                  <Col md="12" className="p-2">
                    <span
                      className={clsx(
                        styles.pageSelectionText,
                        "fontsizeMediumBold",
                      )}
                    >
                      {t("order-details:span_account_information")}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_customer_name")}
                      </p>
                      <p>{customerName || "--"}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_user_type")}
                      </p>
                      <p>{guestOrder !== undefined && (guestOrder ? t("order-details:p_guest") : t("order-details:p_registered"))}</p>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_email")}
                      </p>
                      <p>{email}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="p-2">
                    <FormGroup>
                      <p className="font-weight-bold">
                        {t("order-details:p_phone")}
                      </p>
                      <p>{`${dialCode} ${telephone1}` || "---"}</p>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="m-0">
              <Col xs="12" className="p-2">
                <Row>
                  <Col md="12" className="p-2">
                    <span
                      className={clsx(
                        styles.pageSelectionText,
                        "fontsizeMediumBold",
                      )}
                    >
                      {t("order-details:span_address_information")}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="p-2">
                    <p className="font-weight-bold">
                      {t("order-details:p_billing_address")}
                    </p>
                    <p>{contactPerson}</p>
                    <p>{addressLine1}</p>
                    <p>{addressLine2}</p>
                    <p>
                      {city}
                      ,
                      {district}
                      ,
                      {state}
                    </p>
                    <p>
                      {country}
                      ,
                      {postCode}
                    </p>
                    <p>{email}</p>
                    <p>{`${dialCode} ${telephone1}`}</p>
                  </Col>
                  <Col md="6" className="p-2">
                    <p className="font-weight-bold">
                      {t("order-details:p_shipping_address")}
                    </p>
                    <p>{shippingAddressContactPerson}</p>
                    <p>{shippingAddressAddressLine1}</p>
                    <p>{shippingAddressAddressLine2}</p>
                    <p>
                      {shippingAddressCity}
                      ,
                      {shippingAddressDistrict}
                      ,
                      {" "}
                      {shippingAddressState}
                    </p>
                    <p>
                      {shippingAddressCountry}
                      ,
                      {shippingAddressPostCode}
                    </p>
                    <p>{shippingAddressEmail}</p>
                    <p>{`${shippingAddressDialCode} ${shippingAddressTelephone1}`}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="m-0">
              <Col xs="12" className="p-2">
                <Row>
                  <Col md="12" className="p-2">
                    <span
                      className={clsx(
                        styles.pageSelectionText,
                        "fontsizeMediumBold",
                      )}
                    >
                      {t("order-details:span_items_ordered")}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="custom-table">
                      <CustomizationTable
                        customRows={customRows}
                        customizationData={lineItems || []}
                        isStatusEnable={false}
                        isDeleteEnable={false}
                        isEditEnable={false}
                        isSerialNoEnabled
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {!!(promotionList && promotionList.length) && (
              <Row className="m-0">
                <Col xs="12" className="p-2">
                  <Row>
                    <Col md="12" className="p-2">
                      <span
                        className={clsx(
                          styles.pageSelectionText,
                          "fontsizeMediumBold",
                        )}
                      >
                        {t("order-details:span_promotions")}
                      </span>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div className="custom-table">
                        <CustomizationTable
                          customRows={promotionRows}
                          customizationData={promotionList || []}
                          isStatusEnable={false}
                          isDeleteEnable={false}
                          isEditEnable={false}
                          isSerialNoEnabled
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>)}
            <Row className="m-0">
              <Col xs="12" className="p-2">
                <Row>
                  <Col md="12" className="p-2">
                    <span
                      className={clsx(
                        styles.pageSelectionText,
                        "fontsizeMediumBold",
                      )}
                    >
                      {t("order-details:span_order_total")}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <OrderNoteFormContainer
                    orderNotes={orderNotes}
                    orderId={id}
                  />
                  <Col md="6" className="p-2">
                    <p className="font-weight-bold">
                      {t("order-details:p_order_total")}
                    </p>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col xs="6">
                            <p>{t("order-details:p_sub_total")}</p>
                          </Col>
                          <Col xs="6" className="text-right">
                            <p>
                              {currency}
                              {" "}
                              {priceInfo?.subTotal?.grossPrice - Math.abs(priceInfo?.itemDiscounts?.grossPrice) || 0}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <p>{t("order-details:p_discounts")}</p>
                          </Col>
                          <Col xs="6" className="text-right">
                            <p>
                              {currency}
                              {" "}
                              {priceInfo?.discount?.grossPrice || 0}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <p>{t("order-details:p_shipping")}</p>
                          </Col>
                          <Col xs="6" className="text-right">
                            <p>
                              {currency}
                              {" "}
                              {shippingCharge || 0}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <p>
                              {t("order-details:p_tax")}
                              {" "}
                              (
                              {taxPercentage}
                              %)
                            </p>
                          </Col>
                          <Col xs="6" className="text-right">
                            <p>
                              {currency}
                              {" "}
                              {amount || 0}
                            </p>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col xs="6" className="font-weight-bold">
                            <p>{t("order-details:p_grand_total")}</p>
                          </Col>
                          <Col xs="6" className="text-right">
                            <p>
                              {currency}
                              {" "}
                              {grossPrice || 0}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className={clsx(styles.leftArrow, "mr-2")}>
            <img
              src={leftArrowLong}
              alt="click left"
              width="30"
              height="auto"
              onClick={() => onHandleBack(null)}
            />
          </span>
          <span className="pageText">
            {t("order-details:order_details_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Row className="m-0">
            <Col xs="6" className="p-2">
              <Row>
                <Col md="12" className="p-2">
                  <span
                    className={clsx(
                      styles.pageSelectionText,
                      "fontsizeMediumBold",
                    )}
                  >
                    {t("order-details:span_order_summary")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs="12">
          <Row
            className="m-0"
            className={clsx(styles.block2, "common-page-card ")}
          >
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {t("common:span_error_incomplete_data")}
            </span>
          </Row>
        </Col>
      </Row>
    </>
  );
};
OrderDetails.propTypes = {
  order: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleBack: PropTypes.func.isRequired,
};

export default OrderDetails;
