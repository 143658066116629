/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React from "react";
import PropTypes from "prop-types";
import Select from "../../../common/components/MultiSelect/MultiSelect";


const OrganizationSelector = (props) => {
  const {
    name,
    onChange,
    organizationList,
    selectedOrganizations,
    disabled,
  } = props;

  return (
    <Select
      className="formText"
      name={name}
      id="productOptions"
      value={selectedOrganizations}
      onChange={onChange}
      options={
        Array.isArray(organizationList)
        && organizationList.map(({ id: value, name: label }) => ({ value, label }))
      }
      disabled={disabled}
    />
  );
};

OrganizationSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  organizationList: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedOrganizations: PropTypes.arrayOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default OrganizationSelector;
