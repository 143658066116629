/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ======== CUSTOM COMPONENTS ========= */
import CategorySelector from "../components/CategorySelector";
/** ===== API SERVICE FUNCTIONS =========== */

/** Base container for add new category  */
const CategorySelectorContainer = (props) => {
  const { value, onChange } = props;
  /** local states */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [categoryName, setCategoryName] = useState(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (Array.isArray(value)) {
      setCategoryName((oldNames) => {
        if (!oldNames) {
          return value.map((category) => category.name).join(", ");
        }
        return oldNames;
      });
    }
  }, [value]);

  /**
   * This function is used to manipulate object
   * @param {Object} category
   */
  const updateUrl = (category) => {
    const catalogItemId = category.dataId || category.id;
    const url = category.landingPageBaseUrl || `/product-list?cat=${catalogItemId}`;
    return ({ ...category, url });
  };

  /**
   * This method is used return id only
   * but to display we need category name
   * @param {Object} categories
   */
  const localHandleCategorySelector = (categories) => {
    if (Array.isArray(categories) && categories.length > 0) {
      setCategoryName(categories.map((category) => category.name).join(", "));
      onChange(categories.map(updateUrl));
    }
  };

  /**
   * This method is used for set selected category as root
   */
  const clearCategories = () => {
    setCategoryName(null);
    onChange([]);
  };

  const valueIds = value.map(({ id }) => id);

  return (
    <CategorySelector
      // component
      categoryName={categoryName}
      toggle={toggle}
      dropdownOpen={dropdownOpen}
      clearCategories={clearCategories}
      // selected category
      selectedCategories={valueIds}
      handleCategorySelector={localHandleCategorySelector}
      options={value}
    />
  );
};

CategorySelectorContainer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CategorySelectorContainer;
