// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Roshna Accu\n */\n\n .CartManagement_liveBtnWrapper__1oaGX .CartManagement_liveBtn__2FZpb{\n  border: none;\n  border-radius: 2px;\n  text-transform: uppercase;\n}\n\n.CartManagement_collapseIcon__2cI2r>img {\n  width: 15px;\n  height: 15px;\n  transition: 0.6s ease-out;\n  float: right;\n}\n\n.CartManagement_openCollapse__29oI6 {\n  transform: rotateZ(-90deg);\n}\n\n.CartManagement_closeCollapse__7Tc7r {\n  transform: rotateZ(90deg);\n}\n\n.CartManagement_block2__5O5hq {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.CartManagement_borderRadius2__2P2ST {\n  border-radius: 0.125rem !important;\n}\n\n.CartManagement_secondaryColor__1LulQ {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.CartManagement_primaryColor__2SYeb {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n\n.CartManagement_btn__pkDZv {\n  min-width: 7rem;\n}", ""]);
// Exports
exports.locals = {
	"liveBtnWrapper": "CartManagement_liveBtnWrapper__1oaGX",
	"liveBtn": "CartManagement_liveBtn__2FZpb",
	"collapseIcon": "CartManagement_collapseIcon__2cI2r",
	"openCollapse": "CartManagement_openCollapse__29oI6",
	"closeCollapse": "CartManagement_closeCollapse__7Tc7r",
	"block2": "CartManagement_block2__5O5hq",
	"borderRadius2": "CartManagement_borderRadius2__2P2ST",
	"secondaryColor": "CartManagement_secondaryColor__1LulQ",
	"primaryColor": "CartManagement_primaryColor__2SYeb",
	"btn": "CartManagement_btn__pkDZv"
};
module.exports = exports;
