/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Page Content Container
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import WidgetContent from "../components/WidgetContent";

const PageContentContainer = (props) => {
  const {
    widgetConfig,
    categoryId,
    selectedPage,
    widgetContent,
    getPageContents,
    channel,
    locale
  } = props;
  return (
    <WidgetContent
      widgetContent={widgetContent}
      imgEnabled={!!(widgetConfig && widgetConfig.propertyConfig
        && widgetConfig.propertyConfig.imageUrl)}
      getPageContents={getPageContents}
      categoryId={categoryId}
      selectedPage={selectedPage}
      channel={channel}
      locale={locale}
    />
  );
};

PageContentContainer.propTypes = {
  widgetConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetContent: PropTypes.objectOf(PropTypes.any).isRequired,
  categoryId: PropTypes.string.isRequired,
  selectedPage: PropTypes.string.isRequired,
  getPageContents: PropTypes.func.isRequired,
  channel: PropTypes.string.isRequired,
};
export default PageContentContainer;
