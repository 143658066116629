/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/MultiSelect";

/** ========= API SERVICE FUNCTIONS ========= */

const SimpleCategorySelector = ({
  name, onChange, categories, selectedCategories, handleTypeaheadChange,
}) => {
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["category"]);

  return (
    <Select
      className="formText"
      typeaheadPlaceholder={t("category:placeholder_select_categories")}
      name={name}
      id="category"
      value={selectedCategories}
      onChange={onChange}
      typeahead={handleTypeaheadChange}
      typeaheadText="Category name"
      options={
        Array.isArray(categories)
        && categories.map(({ id: value, name: label }) => ({ value, label }))
      }
    />
  );
};

SimpleCategorySelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleTypeaheadChange: PropTypes.func.isRequired,

};

export default SimpleCategorySelector;
