/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PagesTable Container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import PagesTable from "../components/PagesTable";
import { getSitePreviewConfigurations } from "../../../api/sitePreviewConfigurationsServices";
import { deleteCustomPageById } from "../../../api/pageConfigurationsServices";

const PagesTableContainer = (props) => {
  const {
    widgetType,
    configId,
    customPages,
    handleChangeStatus,
    handlePushToLive,
    getCustomPages,
  } = props;

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [previewEnabled, setPreviewEnabled] = useState(false);

  const history = useHistory();
  const handleRedirectEditPage = (id) => {
    history.push(`/merchandising/static-pages/edit-page?pageId=${id}`);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
        }
      }
    });
  }, []);


  const handleRedirectPreviewSite = (previewPath) => {
    const completeUrl = `${previewSiteUrl}/custom/${previewPath}`;
    window.open(completeUrl, "_blank");
  };

  /**
   * This method is used to delete a page
   * @param {String} id
   */
  const handleDelete = (id) => {
    deleteCustomPageById(id).then((response) => {
      if (response && response.success) {
        getCustomPages(0);
      }
    });
  };

  return (
    <PagesTable
      widgetType={widgetType}
      configId={configId}
      handleRedirectEditPage={handleRedirectEditPage}
      customizationData={customPages}
      handleChangeStatus={handleChangeStatus}
      handlePushToLive={handlePushToLive}
      pushToLiveEnabled={pushToLiveEnabled}
      previewEnabled={previewEnabled}
      handleRedirectPreviewSite={handleRedirectPreviewSite}
      handleDelete={handleDelete}
    />
  );
};

PagesTableContainer.propTypes = {
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  customPages: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  getCustomPages: PropTypes.func.isRequired,
};

export default PagesTableContainer;
