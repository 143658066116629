/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */


import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRouter from "../common/routes/PrivateRouter";
import HeaderContainer from "../features/header/containers/HeaderContainer";
import SideNavigationContainer from "../features/sideNavigation/containers/SideNavigationContainer";
import FooterContainer from "../features/footer/containers/FooterContainer";
import "../common/css/styles.css";
import LayoutSettings from "../features/layoutSettings/containers/LayoutSettingsContainer";
import { isAuthenticated } from "../common/security/permissionUtils";
import UserData from "../common/security/UserData";

/** Page customization */

import PageCustomization from "../features/pageCustomization";

import CustomerDataManagement from "../features/customerDataManagement";

/** coupon management */
import CouponsManagement from "../features/couponsManagement";

/** Badge management */
import BadgesManagement from "../features/badgesManagement";

/** Static pages */
import StaticPages from "../features/staticPages";

/** category management */
import CategoryManagement from "../features/categoryManagement";

/** category management */
import BrandManagement from "../features/brandManagement";

/** product management */
import ProductManagement from "../features/productManagement";

/** product option management */
// import ProductOptionManagement from "../features/productOptionManagement";

/** site navigation  */
import SiteNavigation from "../features/siteNavigation";

/** campaign */
import CampaignManagement from "../features/campaignManagement";

/** promotion */
import PromotionManagement from "../features/promotionsManagement/index.jsx";

import PermissionManagement from "../features/permissionManagement";
import RoleManagement from "../features/roleManagement";
import OrderManagement from "../features/orderManagement";
import FulfillmentMethod from "../features/fulfillmentMethod";
import UserManagement from "../features/userManagement";
import OrderReport from "../features/orderReport";
import SiteTraficReport from "../features/siteTraficReport";
import SearchIndexManagement from "../features/searchIndexManagement";
import SearchSettingsManagement from "../features/searchSettingsManagement";
import SearchSynonymManagement from "../features/searchsynonymManagement";
import UnderConstruction from "../common/components/UnderConstruction";
import CustomURLManagement from "../features/customURLManagement";
import URLRedirectsManagement from "../features/urlRedirectsManagement";
import TaxLocation from "../features/taxLocation";
import TaxClasses from "../features/taxClasses";

/* CustomerGroup Management */
import CustomerGroupManagement from "../features/customerGroupManagement";

// import PrivateRouter from "../common/routes/PrivateRouter";

/** Shipment * */
import ShippingMethod from "../features/shipmentManagement";

/** Job Configuration * */
import NewJobConfiguration from "../features/jobConfiguration";
import NewJobConfigurationContainer from "../features/jobConfiguration/containers/NewJobConfigurationContainer";
import JobHistoryConfigurationContainer from "../features/jobConfiguration/containers/JobHistoryConfigurationContainer";
import DocumentationGuide from "../features/documentationGuide";


/** Store Locator * */
import StoreManagement from "../features/storeManagement";

import InventoryReport from "../features/inventoryReport";

/** SEO Settings */
import SiteMapContainer from "../features/siteMapManagement";
import RobotsTxtManagement from "../features/robotsTxtManagement";
import PageMetaTags from "../features/pageMetaTags";
import InventoryManagement from "../features/inventoryManagement";
import PriceBookManagement from "../features/priceBookManagement";

/** Cart Settings */
import CartManagementContainer from "../features/cartManagement/container/CartManagementContainer";
import SiteSettingsManagementContainer from "../features/siteSettingsManagement/container/SiteSettingsManagementContainer";

import SubMenuDashboard from "../features/subMenuDashboard/containers/Dashboard";
import Dashboard from "../features/dashboard/containers/Dashboard";
import PageNotFound from "../common/components/PageNotFound";

import { logout } from "../api/UserServices";
import MobileAppCustomizationContainer from "../features/mobileAppCustomization";
import PaymentMethod from "../features/paymentMethodManagement";
import EditPaymentMethodContainer from "../features/paymentMethodManagement/containers/EditPaymentMethodContainer";
import VanityUrls from "../features/vanityUrls";

const MainRoutes = (props) => {
  const { match: { path, params } } = props;

  const baseRoute = (params && params.baseRoute) || null;

  const [sideNavOpen, setSideNavOpen] = useState(isAuthenticated());
  const [isScrolled, setIsScrolled] = useState(false);

  // used for dynamic menu rendering
  const [navigation, setNavigation] = useState(null);

  useEffect(() => {
    setNavigation(baseRoute);
  }, [baseRoute]);

  /**
  *This method is used to  hide and show side nav
  *
  */
  const hideSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  /**
  *This method is used to call after login success
  *
  */
  const handleLogout = async () => {
    setSideNavOpen(false);
    UserData.clearAll();
  };

  return (
    <>
      <HeaderContainer setIsScrolled={setIsScrolled} navigation={navigation} setNavigation={setNavigation} handleLogout={handleLogout} />
      <SideNavigationContainer
        hideSideNav={hideSideNav}
        sideNavOpen={sideNavOpen}
        isScrolled={isScrolled}
        navigation={navigation}
      />
      <div id="mainWrapper" className={sideNavOpen ? "mainWrapper " : "mainWrapperWidthfix"}>
        <Switch>
          <PrivateRouter exact path="/dashboard" component={Dashboard} />
          <PrivateRouter path={`${path}/layout-settings`} component={LayoutSettings} />
          <PrivateRouter path={`${path}/permission`} component={PermissionManagement} />
          <PrivateRouter path={`${path}/manage-roles`} component={RoleManagement} />
          <PrivateRouter path={`${path}/order`} component={OrderManagement} />
          <PrivateRouter path={`${path}/fulfillment-method`} component={FulfillmentMethod} />
          <PrivateRouter path={`${path}/user`} component={UserManagement} />
          <PrivateRouter path={`${path}/order-report`} component={OrderReport} />
          <PrivateRouter path={`${path}/inventory-report`} component={InventoryReport} />
          <PrivateRouter path={`${path}/sitetraffic-report`} component={SiteTraficReport} />

          <PrivateRouter path={`${path}/customerdata`} component={CustomerDataManagement} />
          <PrivateRouter path={`${path}/custom-url`} component={CustomURLManagement} />
          <PrivateRouter path={`${path}/url-redirects`} component={URLRedirectsManagement} />
          <PrivateRouter path={`${path}/page-metatags`} component={PageMetaTags} />

          {/* =========== COUPON =========== */}
          <PrivateRouter path={`${path}/coupons`} component={CouponsManagement} />

          {/* =========== BADGE =========== */}
          <PrivateRouter path={`${path}/badges`} component={BadgesManagement} />

          {/* =========== CATEGORY =========== */}
          <PrivateRouter path={`${path}/category`} component={CategoryManagement} />

          {/* =========== BRAND =========== */}
          <PrivateRouter path={`${path}/brand`} component={BrandManagement} />

          {/* =========== CUSTOMERGROUP =========== */}
          <PrivateRouter path={`${path}/customerGroups`} component={CustomerGroupManagement} />

          {/* =========== PRODUCT =========== */}
          <PrivateRouter path={`${path}/product`} component={ProductManagement} />
          {/* <PrivateRouter path={`${path}/product-option`} component={ProductOptionManagement} /> */}

          {/* =========== SITE NAVIGATION =========== */}
          <PrivateRouter path={`${path}/navigation`} component={SiteNavigation} />

          {/* =========== CAMPAIGN =========== */}
          <PrivateRouter path={`${path}/campaign`} component={CampaignManagement} />

          {/* =========== Job Configuration =========== */}
          <PrivateRouter exact path={`${path}/new-job`} component={NewJobConfigurationContainer} />
          <PrivateRouter path={`${path}/job-list`} component={NewJobConfiguration} />
          <PrivateRouter exact path={`${path}/job-details`} component={JobHistoryConfigurationContainer} />

          {/* =========== PROMOTION =========== */}
          <PrivateRouter path={`${path}/promotion`} component={PromotionManagement} />

          {/* =========== Store Locator =========== */}
          <PrivateRouter path={`${path}/store-locations`} component={StoreManagement} />

          <PrivateRouter path={`${path}/pricebooks`} component={PriceBookManagement} />
          <PrivateRouter path={`${path}/inventory`} component={InventoryManagement} />
          <PrivateRouter exact path={`${path}/cart-settings`} component={CartManagementContainer} />

          {/* =========== Page customization =========== */}
          <PrivateRouter path={`${path}/web-app-customization`} component={PageCustomization} />
          <PrivateRouter path={`${path}/mobile-app-customization`} component={MobileAppCustomizationContainer} />
          <PrivateRouter path={`${path}/media`} component={UnderConstruction} />
          ``
          {/* =========== Static pages =========== */}
          <PrivateRouter path={`${path}/static-pages`} component={StaticPages} />

          <PrivateRouter path={`${path}/search-index`} component={SearchIndexManagement} />
          <PrivateRouter path={`${path}/search-settings`} component={SearchSettingsManagement} />
          <PrivateRouter path={`${path}/search-synonym`} component={SearchSynonymManagement} />
          <PrivateRouter path={`${path}/tax-location`} component={TaxLocation} />
          <PrivateRouter path={`${path}/site-map`} component={SiteMapContainer} />
          <PrivateRouter path={`${path}/robots-txt`} component={RobotsTxtManagement} />
          <PrivateRouter path={`${path}/vanity-urls`} component={VanityUrls} />
          <PrivateRouter path={`${path}/tax-class`} component={TaxClasses} />
          <PrivateRouter path="/documentation-guide" component={DocumentationGuide} />
          <PrivateRouter path={`${path}/shipping-method`} component={ShippingMethod} />
          <PrivateRouter path={`${path}/payment-method`} component={PaymentMethod} />
          {/* <PrivateRouter exact path={`${path}/payment-method/edit/:id`} component={EditPaymentMethodContainer} /> */}
          {/* <PrivateRouter exact path="/tax-location/tax-configuration/:id" component={TaxConfigurationContainer} />
          <PrivateRouter exact path="/tax-location/tax-configuration/:id/new" component={TaxConfigurationFormContainer} />
          <PrivateRouter exact path="/tax-location/tax-configuration/:id/edit/:taxConfigId`}  component={TaxConfigurationFormContainer} /> */}
          <PrivateRouter path={`${path}/theme-settings`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/order-history`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/order-settings`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/source`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/stock`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/content-slots`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/vanity`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/customer-report`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/search`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/api-setings`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/site-preference`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/site-protection`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/brand-region`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/currency`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/url`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/currency-support`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/replication-support`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/tax-rate`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/tax-rule`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/payment-method`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/search-suggestions`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/search-preferences`} component={UnderConstruction} />
          <PrivateRouter path={`${path}/url-redirects`} component={UnderConstruction} />
          <PrivateRouter exact path="/not-found" component={PageNotFound} />
          <PrivateRouter exact path={`${path}`} component={SubMenuDashboard} />
          <PrivateRouter exact path="" component={PageNotFound} />
        </Switch>
        <FooterContainer />
      </div>
    </>
  );
};

MainRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default MainRoutes;
