/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import BrandList from "./containers/BrandList";
import BrandCreator from "./containers/BrandCreator";
import BrandEditor from "./containers/BrandEditor";
import PageNotFound from "../../common/components/PageNotFound";


/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */


/** components */


const BrandManagement = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={BrandList} />
    <PrivateRouter exact path={`${path}/new`} component={BrandCreator} />
    <PrivateRouter exact path={`${path}/edit/:brandId`} component={BrandEditor} />
    <PrivateRouter exact path="" component={PageNotFound} />
  </Switch>
);
BrandManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default BrandManagement;
