import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import Input from "../../../common/core/Input";
import { ModalHeader } from "reactstrap";

const EditSynonymModal = (props) => {
  const {
    isOpen,
    toggleOpen,
    togglClose,
    handleConfirm,
    elements,
    field,
    handleFormChange
  } = props;

  return (
    <>
      <Modal modalClassName="modalWrapper" isOpen={isOpen} toggle={toggleOpen}>
          <ModalHeader>Edit/Add Synonyms</ModalHeader>
        <ModalBody>
        <Input
              value={elements}
              type="textarea"
              placeholder="Enter Comma seperated values for Synonyms"
              name="elements"
              onChange={handleFormChange}
            //   invalid={!!(errors.description && errors.description.message)}
            />
        </ModalBody>
        <ModalFooter>
          <button type="button" className="secondaryButton" onClick={togglClose}>Cancel</button>
          <button type="button" className="primaryButton" onClick={handleConfirm}>Save</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

EditSynonymModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  togglClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};

export default EditSynonymModal;