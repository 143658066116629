/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import JobListConfiguration from "../components/JobListConfiguration";
import { getJobList, getJobListStatus, getJobListScope } from "../../../api/jobConfigurationService";

const JobListConfigurationContainer = () => {
  const history = useHistory();
  const [jobListStatusForm, setJobListStatusForm] = useState([]);
  const [jobListScopeForm, setJobListScopeForm] = useState([]);
  const [jobListingForm, setJobListingForm] = useState({
    id: "",
    name: "",
    status: "",
    lastRun: "",
    scope: "",
    duration: "",
    jobTask: [],
  });

  const newJob = () => {
    history.push("/administration/new-job");
  };

  useEffect(() => {
    getJobList().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setJobListingForm(data);
      }
    });
    getJobListStatus().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setJobListStatusForm(data);
      }
    });
    getJobListScope().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setJobListScopeForm(data);
      }
    });
  });
  /**
     *This method is used to handle status routing to job History details page
     *
     */
  const handleJobHistoryPage = (jobId) => {
    history.push(`/administration/job-list/history/${jobId}`);
  };
  return (
    <>
      <JobListConfiguration
        jobList={jobListingForm}
        jobListStatuses={jobListStatusForm}
        jobListScopes={jobListScopeForm}
        onJobHistoryPage={handleJobHistoryPage}
        newJob={newJob}
      />
    </>
  );
};

export default JobListConfigurationContainer;
