/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Alert from "../../../common/core/Alert";

/** ========= CUSTOM COMPONENTS =============== */
import Image from "./Image";

/** ========= SVG IMAGE ICON ============= */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import RefreshIcon from "../../../common/assets/images/svg/refresh.svg";

/** ========= UTILS/CONFIG =============== */

/** ========= MODULE STYLES =============== */
import styles from "../css/ProductManagement.module.css";

const ProductManagement = (props) => {
  const {
    productData,
    changeProductStatus,
    submitting,
    getPageData,
    page,
    hasMoreData,
    addNewProduct,
    editProduct,
    searchTerm,
    handleSearchTerm,
    onHandleClear,
    handlePushToLive,
    pushToLiveEnabled,
    message,
    previewSiteUrl,
    previewEnabled,
    pushToLiveButtonEnabled,
    getReplicationStatus,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader  common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">{t("product:title_manage")}</span>
        </Col>
        <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
          <Row>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                  onClick={() => redirectToPage()}
                >
                  {t("common:button_preview")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, " common-page-card")}>
        <Col xs="12">
          <Row className="m-0">
            <Col xs="8" className="p-0">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("product:sub_title_title_manage")}
              </span>
            </Col>
          </Row>
        </Col>
        <Col className="mb-2" xs="12" sm>
          <div className={styles.areaActionRight}>
            <div className={clsx(styles.searchContainer)}>
              <span
                onClick={() => onHandleClear()}
                className="commonPointer"
                title="Refresh"
              >
                <img
                  width="40"
                  src={RefreshIcon}
                  alt="search category"
                  className={styles.icon}
                />
              </span>
            </div>
            <div className="px-2">
              <InputGroup
                className={clsx(styles.search, "color-tertiary-main")}
              >
                <Input
                  placeholder={t("product:placeholder_search")}
                  value={searchTerm}
                  onChange={handleSearchTerm}
                />
                <InputGroupAddon
                  addonType="append"
                  className="commonPointer bg-color-tertiary-main"
                >
                  <img
                    src={SearchIcon}
                    alt={t("product:placeholder_search")}
                    className={styles.icon}
                  />
                </InputGroupAddon>
              </InputGroup>
            </div>
            <div>
              <Button
                color="primary"
                onClick={addNewProduct}
                className={clsx(
                  styles.borderRadius2,
                  "text-uppercase",
                  "text-truncate",
                  "btn-tertiary",
                )}
              >
                New Product
              </Button>
            </div>
          </div>
          <Col xs="12">
            {
              productData.totalElements ? (
                <Row className="pt-3" align="center" valign="center">
                  <Col xs="8" className="p-0">
                    <span
                      className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                    >
                      {t("product:sub_title_product_count")}
                      {productData.totalElements}
                    </span>
                  </Col>
                </Row>
              ) : null
            }
          </Col>
        </Col>
      </Row>
      <Row
        className={clsx(
          styles.block3,
          styles.tableWrapper,
          " common-page-card",
        )}
      >
        <Col>
          <div className={styles.product}>
            {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
            <div className={clsx(styles.productBody, "table-responsive")}>
              <Table borderless className={styles.productTable}>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <thead>
                  <tr>
                    <th>{t("product:label_id")}</th>
                    <th>{t("product:label_external_id")}</th>
                    <th>{t("common:label_image")}</th>
                    <th>{t("common:label_name")}</th>
                    <th>{t("common:label_status")}</th>
                    <th>{t("common:label_edit")}</th>
                    <th>{t("common:label_delete")}</th>
                  </tr>
                </thead>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <tbody>
                  {/* ========= TABLE BODY S[0002] ======================= */}
                  {Array.isArray(productData.content) && productData.content.length > 0 ? (
                    productData.content.map(({
                      uniqueId, id, name, active, medias,
                    }) => (
                        <tr key={id} className={styles.productTreeItem}>
                          {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
                          <td>{id}</td>
                          <td>{uniqueId}</td>
                          <td className="p-1 align-middle text-center">
                            <Image
                              src={getPreviewImage(medias)}
                              alt={name}
                              className={clsx(styles.productImage, "img-fluid")}
                            />
                          </td>
                          <td>
                            <span
                              title={name}
                              className={clsx(
                                styles.productName,
                                "text-truncate",
                                "d-block",
                              )}
                            >
                              {name}
                            </span>
                          </td>
                          <td>
                            <div
                              className={clsx(
                                "custom-control",
                                "custom-switch",
                                submitting ? "loading" : "",
                              )}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`expand-multiple${id}`}
                                checked={active}
                                onChange={(o) => changeProductStatus(!active, id, o)}
                              />
                              {/* added input tag for removing ESLint error > if added above input tag inside label it wont work */}
                              <label
                                className="custom-control-label"
                                htmlFor={`expand-multiple${id}`}
                              >
                                <input type="hidden" />
                              </label>
                            </div>
                          </td>
                          <td>
                            <span
                              onClick={(o) => editProduct(id, o)}
                              onKeyDown={() => { }}
                              tabIndex={0}
                              role="button"
                              className="commonPointer"
                            >
                              <img
                                className={clsx("commonPointer", styles.icon)}
                                src={EditIcon}
                                alt="Edit"
                              />
                            </span>
                          </td>
                          <td>
                            <img
                              className={clsx("commonPointer", styles.icon)}
                              src={DeleteIcon}
                              alt="Delete"
                            />
                          </td>
                          {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                        </tr>
                      ))
                  ) : (
                      <tr>
                        <td colSpan={8} align="center" valign="center">
                          {" "}
                        Nothing to show.
                      </td>
                      </tr>
                    )}
                  {/* ========= TABLE BODY E[0002] ======================= */}
                </tbody>
              </Table>
            </div>
            <div
              className={clsx(
                styles.pagination,
                "p-3",
                "d-flex",
                "justify-content-end",
              )}
            >
              <div className="d-flex">
                <span
                  disabled={page < 1}
                  className={clsx(styles.item, styles.arrowLeft)}
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => { }}
                  onClick={(o) => getPageData(-1, o)}
                >
                  <span />
                </span>
                <span className={styles.item} />
                <span
                  disabled={!hasMoreData}
                  className={clsx(styles.item, styles.arrowRight)}
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => { }}
                  onClick={(o) => getPageData(1, o)}
                >
                  <span />
                </span>
              </div>
            </div>
            {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
          </div>
        </Col>
      </Row>
    </>
  );
};

ProductManagement.propTypes = {
  productData: PropTypes.objectOf(PropTypes.any).isRequired,
  changeProductStatus: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  getPageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  addNewProduct: PropTypes.func.isRequired,
  editProduct: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  onHandleClear: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductManagement;
