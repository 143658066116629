/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * UrlTable
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import editIcon from "../../../common/assets/images/svg/edit.svg";
import deleteIcon from "../../../common/assets/images/svg/delete.svg";
import Spinner from "../../../common/core/Spinner";
import Table from "../../../common/core/Table";
import styles from "../css/Table.module.css";
import transformers from "../../../common/utils/transformers";


const UrlTable = (props) => {
  const {
    pageCustomizationRows,
    customizationData,
    handleDelete,
    isLoading,
    selectedIndex,
    handleEdit,
  } = props;

  /**
 *This method is used to get a value from an item
 *
 * @param {object} row
 * @param {object} rowItemData
 * @returns
 */
  const getItem = (row, rowItemData) => {
    let data = get(row, rowItemData.path);
    if (rowItemData.transform && rowItemData.transform !== "") {
      data = transformers[rowItemData.transform](row, rowItemData.path);
    }
    return data;
  };

  return (
    <Table borderless>
      <thead>
        <tr>
          {pageCustomizationRows.map((row) => <th>{row.label}</th>)}
          <th>Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(customizationData)
          && customizationData.length > 0
          && customizationData.map((rowItem, index) => (
            <tr>
              {pageCustomizationRows.map((row) => <td width="10%">{getItem(rowItem, row)}</td>)}
              <td width="5%">
                <img
                  src={editIcon}
                  className="commonPointer"
                  onClick={() => handleEdit(rowItem, index)}
                  role="presentation"
                  alt="edit"
                  width="20px"
                />
              </td>
              <td width="5%">

                {isLoading && (selectedIndex && selectedIndex === index) ? (
                  <Spinner className={styles.spinnerWrapper} />
                )
                  : (
                    <img
                      src={deleteIcon}
                      className="commonPointer"
                      alt="drag indiactor icon"
                      width="20px"
                      role="presentation"
                      onClick={() => handleDelete(rowItem.id, index)}
                    />
                  )}
              </td>
            </tr>
          ))}
      </tbody>

    </Table>
  );
};

UrlTable.propTypes = {
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default UrlTable;
