// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Sreelekshmi Prasannan\n */\n\n .CustomerGroupForm_block2__1pZ9u {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.CustomerGroupForm_borderRadius2__2pxdY {\n  border-radius: 0.125rem !important;\n}\n\n.CustomerGroupForm_secondaryColor__1OqLN {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.CustomerGroupForm_primaryColor__ju-iW {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.CustomerGroupForm_btn__2bf08 {\n  min-width: 7rem;\n}\n\n.CustomerGroupForm_icon__3QhAV {\n  width: 1.25rem;\n}\n\n/* ========== FORM AREA ============ */\n.CustomerGroupForm_block3__1mglo {\n  padding: 1.25rem;\n}\n\n.CustomerGroupForm_noLabelFormGroup__1aKzX {\n  margin-top: 29px;\n  height: calc(1.5em + 0.75rem + 2px);\n} \n.CustomerGroupForm_formTextLabelCustomer__3PHz_{\n  font-weight: 700;\n}", ""]);
// Exports
exports.locals = {
	"block2": "CustomerGroupForm_block2__1pZ9u",
	"borderRadius2": "CustomerGroupForm_borderRadius2__2pxdY",
	"secondaryColor": "CustomerGroupForm_secondaryColor__1OqLN",
	"primaryColor": "CustomerGroupForm_primaryColor__ju-iW",
	"btn": "CustomerGroupForm_btn__2bf08",
	"icon": "CustomerGroupForm_icon__3QhAV",
	"block3": "CustomerGroupForm_block3__1mglo",
	"noLabelFormGroup": "CustomerGroupForm_noLabelFormGroup__1aKzX",
	"formTextLabelCustomer": "CustomerGroupForm_formTextLabelCustomer__3PHz_"
};
module.exports = exports;
