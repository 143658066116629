/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Harikrishnan
 *
 */

import httpApi from "./httpApi";

/**
 * This method is used to create tax location
 *
 * @param {*} data
 * @returns
 */
const createTaxLocation = async (data) => {
  const response = await httpApi.post("/v1/tax-locations", data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to create tax class
 *
 * @param {*} data
 * @returns
 */
const createTaxClass = async (data) => {
  const response = await httpApi.post("/v1/tax-classes", data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update tax location
 *
 * @param {*} data
 * @returns
 */
const updateTaxLocation = async (data) => {
  const response = await httpApi.patch("/v1/tax-locations", data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update tax class
 *
 * @param {*} data
 * @returns
 */
const updateTaxClass = async (data) => {
  const response = await httpApi.patch("/v1/tax-classes", data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to create tax rate
 *
 * @param {*} data
 * @returns
 */
const createTaxRate = async (id, taxConfigName, data) => {
  const response = await httpApi.post(`/v1/tax-rates/tax-location-id/${id}/tax-config-id/${taxConfigName}`, data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is get to taxLocation by retailer and brand
 *
 * @param {*} retailer
 * @param {*} brand
 * @returns
 */
const getTaxLocationsByRetailerAndBrand = async (retailer, brand) => {
  const response = await httpApi.get(`/v1/tax-locations/retailer/${retailer}/brand/${brand}`);
  if (response && response.data
        && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get taxlocation by Id
 *
 * @param {*} id
 * @returns
 */
const getTaxLocationById = async (id) => {
  const response = await httpApi.get(`/v1/tax-locations/${id}`);
  if (response && response.data
        && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get taxclass by Id
 *
 * @param {*} id
 * @returns
 */
const getTaxClassById = async (id) => {
  const response = await httpApi.get(`/v1/tax-classes/${id}`);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get taxlocation by Id
 *
 * @param {*} id
 * @returns
 */
const getTaxClassByRetailerAndBrand = async (retailer, brand) => {
  const response = await httpApi.get(`/v1/tax-classes/retailer/${retailer}/brand/${brand}`);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to create tax configuration
 *
 * @param {*} id
 * @param {*} data
 * @returns
 */
const createTaxConfig = async (id, data) => {
  const response = await httpApi.post(`/v1/tax-configurations/${id}`, data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to update tax configuration
 * @param {*} id
 * @param {*} data
 * @returns
 */
const updateTaxConfig = async (id, data) => {
  const response = await httpApi.patch(`/v1/tax-configurations/${id}`, data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete tax configuration
 *
 * @param {*} data
 *  @param taxConfigId
 * @returns
 */
const deleteTaxConfig = async (id, taxConfigId) => {
  const response = await httpApi.delete(`/v1/tax-configurations/tax-location-id/${id}/tax-config-id/${taxConfigId}`);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete tax rate
 * @param {*} id
 * @param {*} taxConfigId
 * @param {*} taxRateName
 * @returns
 */
const deleteTaxRate = async (id, taxConfigId, taxRateName) => {
  const response = await httpApi.delete(`/v1/tax-rates/tax-location-id/${id}/tax-config-id/${taxConfigId}/tax-rate-name/${taxRateName}`);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete tax location
 *
 * @param {*} id
 * @returns
 */
const deleteTaxLocation = async (id) => {
  const response = await httpApi.delete(`/v1/tax-locations/${id}`);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete tax class
 *
 * @param {*} id
 * @returns
 */
const deleteTaxClass = async (id) => {
  const response = await httpApi.delete(`/v1/tax-classes/${id}`);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update tax rate
 *
 * @param {*} data
 * @returns
 */
const updateTaxRate = async (taxLocationId, taxConfigId, data) => {
  const response = await httpApi.patch(`/v1/tax-rates/tax-location-id/${taxLocationId}/tax-config-id/${taxConfigId}`, data);
  if (response && response.data
      && response.data.success) {
    return response.data;
  }
  return null;
};


export {
  createTaxLocation,
  getTaxLocationsByRetailerAndBrand,
  getTaxLocationById,
  createTaxConfig,
  updateTaxConfig,
  deleteTaxConfig,
  createTaxRate,
  updateTaxLocation,
  deleteTaxLocation,
  deleteTaxRate,
  updateTaxRate,
  getTaxClassByRetailerAndBrand,
  createTaxClass,
  getTaxClassById,
  updateTaxClass,
  deleteTaxClass,
};
