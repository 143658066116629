/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React, { useState } from "react";
import SearchFacet from "../components/SearchFacet";
import { bulkIndexProducts } from "../../../api/productManagementServices";


const SearchFacetContainer = () => {
  const [rowsLoading, setLoading] = useState([]);


  const handleIndexing = (row) => {
    setLoading([row.name, ...rowsLoading]);
    bulkIndexProducts().then((response) => {
      setLoading(rowsLoading.filter((item) => item.name !== row.name));
      if (response && response.data) {
        console.log(response.data);
      }
    });
  };

  return (<SearchFacet handleIndexing={handleIndexing} rowsLoading={rowsLoading} />);
};

export default SearchFacetContainer;
