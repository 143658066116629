/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styles from "../css/ImageSelection.module.css";
import Add from "../../../common/assets/images/svg/add-24px.svg";
import close from "../../../common/assets/images/svg/close-alt.svg";

const ImageSelection = ({
  handleChangeFile,
  imageUrl,
  type,
  handleRemoveImage,
}) => (
    <div className="d-flex">
      {imageUrl ? (
        <div className={styles.uploadImagePreview}>
          <span
            className={styles.uploadImagePreviewClose}
            role="presentation"
            onClick={() => handleRemoveImage(type)}
          >
            <img src={close} alt="close" width="20px" />

          </span>
          <div>
            <img className=" img-thumbnail" src={imageUrl} alt="" />
          </div>
        </div>
      )
        : (
          <div className={styles.uploadImagePreviewIcon}>
            <label className="btn btn-default w-100 h-100 p-0 m-0">
              <img
                className="img-fluid img-thumbnail h-100"
                role="presentation"
                width="150px"
                src={Add}
                alt=""
              />
              <input
                type="file"
                hidden
                onChange={(e) => handleChangeFile(e, type)}
                accept="image/jpg, image/jpeg, image/svg+xml, image/png"
              />
            </label>
          </div>
        )}
    </div>
  );

ImageSelection.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleChangeFile: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
};

export default ImageSelection;
