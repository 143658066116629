/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Nav from "../../../common/core/Nav";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Label from "../../../common/core/Label";
import Alert from "../../../common/core/Alert";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import NavLink from "../../../common/core/NavLink";
import NavItem from "../../../common/core/NavItem";
import TabPane from "../../../common/core/TabPane";
import FormGroup from "../../../common/core/FormGroup";
import TabContent from "../../../common/core/TabContent";
import validateForm from "../../../common/utils/validateForm";
import FormFeedback from "../../../common/core/FormFeedback";
import Input from "../../../common/core/Input";
import validationConstants from "../../../common/utils/validationConstants";
import constants from "../../../common/utils/constants";
/** ========== SUB COMPONENT ================ */
import CategoryForm from "./CategoryForm";
import CategorySelectionContainer from "../containers/CategorySelectionContainer";
import ImageSelection from "../containers/ImageSelectionContainer";
import ProductSelectionContainer from "../containers/ProductSelectionContainer";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

/** ===== CUSTOM COMPONENTS =========== */
import Select from "../../../common/components/Select";

/** ======== MODULE STYLES ========== */
import styles from "../css/EditCategory.module.css";

const EditCategory = (props) => {
  const {
    id,
    name,
    longDescription,
    description,
    startDate,
    endDate,
    status,
    bannerImage,
    image,
    disableDelete,
    childProductDetails,
    handleFormChange,
    handleChange,
    submitForm,
    cancelForm,
    iconUrl,
    parentCategory,
    handleParentCategory,
    navPosition,
    type,
    source,
    selectedCategories,
    parentCategoryName,
    selectedCategoryChange,
    selectedProductChange,
    childProducts,
    message,
    onSubmitting,
    submitting,
    changeProductStatus,
    dropdownOpen,
    handleDeleteCategory,
    toggleSortRuleDropdown,
    facet,
    sortOptions,
    sortRuleOnchange,
    selectedSort,
    translatableFileds,
    selectedLanguage,
    navigable,
    locale,
    localeHandler, isDefault,
    categoryId, getCategoryDetailsFromServer
  } = props;
  const validator = validateForm();
  const {
    handleSubmit, errors, register, clearError,
  } = validator;

  useEffect(() => {
    if (!navigable) {
      clearError(["navPosition"]);
    }
  }, [navigable]);

  const [activeTab, setActiveTab] = useState("1");
  const { t } = useTranslation(["common", "product-option"]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const errorArray = errors ? Object.entries(errors) : [];

  const { IS_NUMBER } = validationConstants;
  const { imageAspectRatios: { CATEGORY_IMAGE } } = constants;
  return (
    <>
      <Row>
        <Col xs="12">
          {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
          {message && message.type && (
            <Alert color={message.type}>{message.message}</Alert>
          )}

          <Form onSubmit={handleSubmit(submitForm)}>
            <fieldset disabled={onSubmitting}>
              {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
              <Row className="pageHeader  common-page-card">
                <Col xs="12" md="6" className="text-truncate">
                  <span className="pageText">Edit Category</span>
                </Col>
                <Col xs="12" md="6" className="d-flex justify-content-end">
                  <Button
                    disabled={disableDelete}
                    type="button"
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.customColorForDelete,
                      "text-truncate",
                      "mr-1",
                      "button-font-size",
                    )}
                    onClick={handleDeleteCategory}
                  >
                    DELETE
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.secondaryColor,
                      "text-truncate",
                      "mr-1",
                      "button-font-size",
                    )}
                    onClick={cancelForm}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.primaryColor,
                      "text-truncate",
                      "mr-1",
                      "button-font-size",
                    )}
                  >
                    UPDATE CHANGES
                  </Button>
                </Col>
              </Row>

              <Row className="pageHeader common-page-card">
                <Col xs="12">
                  <LocaleDropdownContainer
                    entityName="category"
                    isIndocument
                    localeHandler={localeHandler}
                  />
                </Col>
              </Row>

              {errorArray
                && activeTab !== "1"
                && errorArray.map((error) => (
                  <Alert color="danger" className="mb-2 p-2">
                    <h5 className="m-0 alert-heading text-capitalize">
                      {error && error[0]}
                    </h5>
                    <p className="m-0">
                      {(error && error[1] && error[1].message)
                        || "Invalid input"}
                      <span className="pl-1">in details page</span>
                      {" "}
                    </p>
                  </Alert>
                ))}

              <Nav tabs>
                <NavItem
                  active={activeTab === "1"}
                  className={clsx(
                    styles.mw25,
                    "cursor-pointer",
                    "text-center",
                    activeTab === "1" ? "bg-white" : " bg-light",
                  )}
                >
                  <NavLink
                    className="border-0"
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Details
                  </NavLink>
                </NavItem>
                <NavItem
                  active={activeTab === "2"}
                  className={clsx(
                    styles.mw25,
                    "cursor-pointer",
                    "text-center",
                    activeTab === "2" ? "bg-white" : " bg-light",
                  )}
                >
                  <NavLink
                    className="border-0"
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Products
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="p-0">
                  {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
                  <div className="common-page-card">
                    {/*  ====== CATEGORY FORM S[0007]============ */}
                    <CategoryForm
                      id={id}
                      name={name}
                      longDescription={longDescription}
                      description={description}
                      startDate={startDate}
                      endDate={endDate}
                      status={status}
                      bannerImage={bannerImage}
                      image={image}
                      iconUrl={iconUrl}
                      handleFormChange={handleFormChange}
                      handleChange={handleChange}
                      parentCategory={parentCategory}
                      handleParentCategory={handleParentCategory}
                      validator={validator}
                      parentCategoryName={parentCategoryName}
                      translatableFileds={translatableFileds}
                      type={type}
                      source={source}
                      isDefault={isDefault}
                    />
                    <Row className={styles.block3}>

                      <Col xs="12" md="5" sm="5">
                        <FormGroup>
                          <Label className="formTextLabel">
                            Position
                          </Label>
                          <Input
                            className="formText"
                            placeholder="Position"
                            name="navPosition"
                            disabled={!navigable}
                            innerRef={
                              navigable ? (
                                register({
                                  pattern: {
                                    value: IS_NUMBER,
                                    message: "Please Enter a Valid Number",
                                  },
                                })
                              )
                                : null
                            }
                            defaultValue={navPosition}
                            onChange={handleFormChange}
                            invalid={!!(errors.navPosition && errors.navPosition.message)}
                          // disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("navPosition") : false}
                          />
                          <FormFeedback>{errors.navPosition && errors.navPosition.message}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="5" sm="5">
                        <FormGroup>
                          <Label className="formTextLabel">
                            Show in Site Navigation
                          </Label>
                          <div
                            className={clsx(
                              "custom-control",
                              "custom-switch",
                              "ml-1 pt-2",
                            )}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="navigable-new-category"
                              name="navigable"
                              checked={navigable}
                              onChange={() => handleChange("navigable", !navigable)}
                            />
                            <Label className="custom-control-label" htmlFor="navigable-new-category" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/*  ====== CATEGORY FORM E[0007]============ */}
                    <Row className={styles.block3}>
                      <Col>
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label className="formTextLabel">
                                Banner Image
                              </Label>
                              <ImageSelection
                                images={bannerImage}
                                single={false}
                                aspectRatio={235 / 88}
                                handleChange={(o) => {
                                  handleChange("bannerImage", o);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12">
                            <FormGroup>
                              <Label className="formTextLabel">Image</Label>
                              <ImageSelection
                                images={image}
                                single={false}
                                aspectRatio={CATEGORY_IMAGE}
                                handleChange={(o) => {
                                  handleChange("image", o);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12">
                            <FormGroup>
                              <Label className="formTextLabel">
                                Icon Image
                              </Label>
                              <ImageSelection
                                images={iconUrl}
                                single
                                aspectRatio={1}
                                handleChange={(o) => {
                                  handleChange("iconUrl", o);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/*  ====== ADD SUB CATEGORIES S[0003]============ */}
                    {Array.isArray(childProducts)
                      && childProducts.length < 1
                      && (
                        <CategorySelectionContainer
                          onChange={selectedCategoryChange}
                          exclude={[id]}
                        />
                      )}
                    {/*  ====== ADD SUB CATEGORIES E[0003]============ */}
                    <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">Sorting Rules</Label>
                        <Select
                          className="formText"
                          name="sortRules"
                          id="sort-rules"
                          onChange={(e) => {
                            sortRuleOnchange(e);
                          }}
                          options={sortOptions}
                          value={selectedSort}
                        // disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("sortRules") : false}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className={(styles.product, "mt-3")}>
                        {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
                        <Label className="formTextLabel">Search facet</Label>
                        <div
                          className={clsx(
                            styles.productBody,
                            "table-responsive",
                            "custom-table",
                          )}
                        >
                          <Table borderless className={styles.productTable}>
                            {/* ========= TABLE HEAD S[0003] ======================= */}
                            <thead>
                              <tr>
                                <th>{t("common:label_name")}</th>
                                <th>{t("common:label_enabled")}</th>
                              </tr>
                            </thead>
                            {/* ========= TABLE HEAD S[0003] ======================= */}
                            <tbody>
                              {/* ========= TABLE BODY S[0002] ======================= */}
                              {facet && facet.length > 0 ? (
                                facet.map((item, index) => {
                                  const { field, active, level } = item;
                                  return (
                                    <tr
                                      key={field}
                                      className={styles.productTreeItem}
                                    >
                                      <td>
                                        <span
                                          title={field}
                                          className={clsx(
                                            styles.productName,
                                            "text-truncate",
                                            "d-block",
                                          )}
                                        >
                                          {field}
                                        </span>
                                      </td>
                                      <td>
                                        <div
                                          className={clsx(
                                            "custom-control",
                                            "custom-switch",
                                            submitting ? "loading" : "",
                                          )}
                                        >
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`expand-multiple${index + 1}`}
                                            checked={active}
                                            // disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("facet") : false}
                                            onChange={(o) => changeProductStatus(
                                              !active,
                                              field,
                                              level,
                                              o,
                                            )}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`expand-multiple${index + 1}`}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              )
                                : (
                                  <tr>
                                    <td
                                      colSpan={8}
                                      align="center"
                                      valign="center"
                                    >
                                      {" "}
                                      Nothing to show.
                                    </td>
                                  </tr>
                                )}
                              {/* ========= TABLE BODY E[0002] ======================= */}
                            </tbody>
                          </Table>
                        </div>
                        {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
                      </div>
                    </Col>
                  </div>
                </TabPane>
                <TabPane tabId="2" className="p-0">
                  {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
                  <div className="common-page-card">
                    <div className="py-2">
                      {/*  ====== ADD PRODUCTS S[0003]============ */}
                      <ProductSelectionContainer
                        selectedProductDetails={childProductDetails}
                        onChange={selectedProductChange}
                        categoryId={id}
                        showRanking
                        locale={locale}
                        categoryId={categoryId}
                        onProductUpload={getCategoryDetailsFromServer}
                      />
                      {/*  ====== ADD PRODUCTS E[0003]============ */}
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </fieldset>
          </Form>
          {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
        </Col>
      </Row>
    </>
  );
};

EditCategory.propTypes = {
  // form field
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  childProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  childProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  image: PropTypes.objectOf(PropTypes.any),
  bannerImage: PropTypes.objectOf(PropTypes.any),
  disableDelete: PropTypes.bool.isRequired,
  categoryId: PropTypes.string.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // form actions
  submitForm: PropTypes.func.isRequired,
  cancelForm: PropTypes.func.isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
  // categories
  selectedCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  // parent category
  parentCategory: PropTypes.string.isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  parentCategoryName: PropTypes.string.isRequired,
  // sub category
  selectedCategoryChange: PropTypes.func.isRequired,
  // products of the category
  selectedProductChange: PropTypes.func.isRequired,
  // apply selected language
  selectedLanguageChange: PropTypes.func.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  navPosition: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  facet: PropTypes.arrayOf(PropTypes.any).isRequired,
  navigable: PropTypes.bool.isRequired,
  iconUrl: PropTypes.string.isRequired,
  getCategoryDetailsFromServer: PropTypes.func.isRequired,
};

EditCategory.defaultProps = {
  image: undefined,
  bannerImage: undefined,
};

export default EditCategory;
