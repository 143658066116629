/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * App
 *
 */

import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import ErrorBoundary from "../common/components/ErrorBoundary";
/** Login * */
import LoginContainer from "../features/login/containers/LoginContainer";

import PrivateRouter from "../common/routes/PrivateRouter";
import PublicRouter from "../common/routes/PublicRouter";
import MainRoutes from "./RouteViews";
import GlobalHistory from "../common/utils/globalHistory";
import "../common/css/styles.css";

const App = () => (
  <>
    <ErrorBoundary>
      <Router>
        <GlobalHistory />
        <Switch>
          <PublicRouter exact path="/login" component={LoginContainer} />
          <PrivateRouter path="/:baseRoute" component={MainRoutes} />
          <PrivateRouter exact path="/" component={() => <Redirect to="/dashboard" />} />
        </Switch>
      </Router>
    </ErrorBoundary>
  </>
);
export default App;
