/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Promotions Management Container
 *
 * @author Naseef O
 *
 */

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ========= SUB COMPONENT ========= */
import PromotionListView from "../components/PromotionListView";

/** ========= CUSTOM COMPONENTS ========= */
import ConfirmationModal from "../../../common/components/ConfirmationModal";

/** ========= API SERVICE FUNCTIONS ========= */
import { listPromotions, setStatus, deletePromotion } from "../../../api/promotionServices";
import { getSitePreviewConfigurations, replicatePromotion, getPromotionReplicationStatus } from "../../../api/sitePreviewConfigurationsServices";

/*  Main container of the PromotionList */
const PromotionList = () => {
  const paginationLimit = 5;

  /** state variables */
  const [promotions, setPromotions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPromotionId, setSelectedPromotionId] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);


  const history = useHistory();
  /**
 * This method is used to list promotions
 * @param {String} localSearchTerm
 * @param {Number} newPage
 * @param {Number} paginationLimit
 * @param {String} sort
 * @param {String} sortDirection

   * This method used to change the promotion status
   * @param {String} id
   * @param {Boolean} status
   */
  const changePromotionStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    setSelectedPromotionId(id);
    const response = await setStatus(id);
    if (response && response.success) {
      const newPromotions = promotions.map((promotion) => (promotion.id === id
        ? ({ ...promotion, active: status }) : promotion));
      setPromotions(newPromotions);
    }
    setSelectedPromotionId("");
    setSubmitting(false);
  };


  /**
   * This method is used to list promotions
   * @param {String} localSearchTerm
   * @param {Number} newPage
   * @param {Number} paginationLimit
   * @param {String} sort
   * @param {String} sortDirection
   */
  const getPromotionListFromServer = (localSearchTerm = "", newPage = 0, inPaginationLimit = 5, sort = "createdDate", sortDirection = "DESC") => {
    /** getting list of promotions when page load */
    listPromotions(localSearchTerm, newPage, inPaginationLimit, sort, sortDirection).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data) && data.length > 0) {
          setPromotions(data);
        } else {
          newPage = page;
        }
        setHasMoreData(Array.isArray(data) && data.length >= inPaginationLimit);
        setPage(Math.max(newPage, 0));
      }
    });
  };

  /**
 * This method is used to open new promotion
 * This method will trigger when pagination button click
 * @param {Number} page
 */
  const getPageData = (direction) => {
    const newPage = page + direction;
    getPromotionListFromServer(searchTerm, Math.max(newPage, 0), paginationLimit);
  };

  /**
 * This method is used to update text in search box
 * @param {Event} event
 */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getPromotionListFromServer(value); } else getPromotionListFromServer();
  };


  useEffect(() => {
    /** getting list of promotions when page load */
    getPromotionListFromServer();
  }, [paginationLimit]);

  const AddNewPromotion = () => {
    history.push("/marketing/promotion/new");
  };

  const editPromotion = (promotionId) => {
    history.push(`/marketing/promotion/edit/${promotionId}`);
  };

  const removePromotion = (promotionId) => {
    setSelectedPromotionId(promotionId);
    setOpenConfirmModal(true);
  };

  /**
   * This method is used to cancel confirm form
   */
  const cancelConfirm = () => {
    setSelectedPromotionId("");
    setOpenConfirmModal(false);
  };

  /**
   * This function form delete promotion
   */
  const confirmDelete = async () => {
    if (submitting) return;
    const response = await deletePromotion(selectedPromotionId);
    if (response && response.success) {
      const newPromotions = promotions.filter((promotion) => (promotion.id !== selectedPromotionId));
      setPromotions(newPromotions);
    }
    setSelectedPromotionId("");
    cancelConfirm();
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    replicatePromotion().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Promotion pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };

  const getReplicationStatus = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    getPromotionReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last promotion push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch(error => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  return (
    <>
      <PromotionListView
        promotions={promotions}
        submitting={submitting}
        page={page}
        hasMoreData={hasMoreData}
        searchTerm={searchTerm}
        selectedPromotionId={selectedPromotionId}
        pushToLiveEnabled={pushToLiveEnabled}
        previewSiteUrl={previewSiteUrl}
        previewEnabled={previewEnabled}
        message={message}
        // functions
        getPageData={getPageData}
        handleSearchTerm={handleSearchTerm}
        AddNewPromotion={AddNewPromotion}
        changePromotionStatus={changePromotionStatus}
        editPromotion={editPromotion}
        removePromotion={removePromotion}
        handlePushToLive={handlePushToLive}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
      />
      <ConfirmationModal
        isOpen={openConfirmModal}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this promotion ?"
      />
    </>
  );
};


export default PromotionList;
