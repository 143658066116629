// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n .CategoryForm_block2__17WPE {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.CategoryForm_borderRadius2__UevFH {\n  border-radius: 0.125rem !important;\n}\n\n.CategoryForm_secondaryColor__2g7mt {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.CategoryForm_primaryColor__nVikf {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.CategoryForm_btn__3Ol1x {\n  min-width: 7rem;\n}\n\n.CategoryForm_icon__2kVb- {\n  width: 1.25rem;\n}\n\n/* ========== FORM AREA ============ */\n.CategoryForm_block3__32Kfx {\n  padding: 1.25rem;\n}\n\n/* ============= PRODUCT TITLE ========= */\n.CategoryForm_titleTextContainer__TfYdX {\n  padding: 0 2.1rem 0.125rem 2.1rem;\n  border-bottom: 1px solid #edf0f5;\n  margin: 0 -0.938rem;\n}\n\n.CategoryForm_titleTextContainer__TfYdX .CategoryForm_pageTitleText__14R4z{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}", ""]);
// Exports
exports.locals = {
	"block2": "CategoryForm_block2__17WPE",
	"borderRadius2": "CategoryForm_borderRadius2__UevFH",
	"secondaryColor": "CategoryForm_secondaryColor__2g7mt",
	"primaryColor": "CategoryForm_primaryColor__nVikf",
	"btn": "CategoryForm_btn__3Ol1x",
	"icon": "CategoryForm_icon__2kVb-",
	"block3": "CategoryForm_block3__32Kfx",
	"titleTextContainer": "CategoryForm_titleTextContainer__TfYdX",
	"pageTitleText": "CategoryForm_pageTitleText__14R4z"
};
module.exports = exports;
