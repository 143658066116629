/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */


import httpApi from "./httpApi";

/**
 * This method is used to list campaigns
 * @returns
 */
const getCampaigns = async (searchTerm, page = 0, limit = 10, sortBy = null, sortDirection = null, active = null) => {
  const params = {
    page, limit, sortBy, sortDirection,
  };
  const requestBody = {
    searchTerm, active,
  };
  const response = await httpApi.post("v1/campaigns/criteria-search", requestBody, { params });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to create new campaign
 * @param {Object} campaignData
 * @returns {Object}
 */
const createCampaign = async (campaignData) => {
  const response = await httpApi.post("/v1/campaigns", campaignData);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This function is used to get campaign details by id
 * @param {String} campaignId
 */
const getCampaign = async (campaignId) => {
  const response = await httpApi.get(`/v1/campaigns/id/${campaignId}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This function is used  to get list of  customer groups
 * @param {String} searchTerm
 * @param {Number} page
 * @param {Number} size
 * @param {String} sortBy
 * @param {String} sortDirection
 */
const getCustomerGroups = async (searchTerm, page, size, sortBy, sortDirection) => {
  const response = await httpApi.get("v1/user-groups", {
    params: {
      searchTerm, page, size, sortBy, sortDirection,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This function is used  to get list of  customer groups
 * @param {String} searchTerm
 * @param {Number} page
 * @param {Number} size
 * @param {String} sortBy
 * @param {String} sortDirection
 */
const getCoupons = async (searchTerm, campaignId, page, size, sortBy, sortDirection) => {
  const response = await httpApi.get(`v1/coupons/campaigns/${campaignId}`, {
    params: {
      searchTerm, page, size, sortBy, sortDirection,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This function is used  to get list of  customer groups
 * @param {String} searchTerm
 * @param {Number} page
 * @param {Number} size
 * @param {String} sortBy
 * @param {String} sortDirection
 */
const getPromotions = async (searchTerm, campaignId, page, size, sortBy, sortDirection) => {
  const response = await httpApi.get(`v1/promotions/campaigns/${campaignId}`, {
    params: {
      searchTerm, page, size, sortBy, sortDirection,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This function is used to update user group and coupons
 * @param {Object} campaignData
 */
const updateQualifiers = async (campaignData) => {
  const response = await httpApi.patch("v1/campaigns/qualifiers", campaignData);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This function is used to update experiences
 * @param {Object} campaignData
 */
const updateExperience = async (campaignData) => {
  const response = await httpApi.patch("v1/campaigns/experiences", campaignData);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete a campaign
 * @param {String} id
 */
const deleteCampaign = async (id) => {
  const response = await httpApi.delete(`v1/campaigns/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This method is used to change status a campaign
 * @param {String} id
 */
const setStatus = async (id) => {
  const response = await httpApi.patch(`v1/campaigns/${id}/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This function is used to update campaign data
 * @param {Object} campaignData
 * @returns {Object}
 */
const updateCampaign = async (campaignData) => {
  const response = await httpApi.patch("/v1/campaigns", campaignData);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  getCampaigns, createCampaign, getCustomerGroups, getCoupons, getCampaign,
  getPromotions, updateQualifiers, updateExperience, setStatus, deleteCampaign, updateCampaign,
};
