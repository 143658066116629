/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PaymentMethod from "../components/PaymentMethod";
import { getPaymentMethodConfigurations, activatePaymentMethod, deactivatePaymentMethod } from "../../../api/paymentManagementServices";
import { getFulfillmentMethodConfigurations } from "../../../api/fulfillmentMethodService";

const PaymentMethodContainer = () => {
  const history = useHistory();

  const [selectedDataId, setSelectedDataId] = useState(null);
  const [paymentMethodData, setPaymentMethodConfigurations] = useState([]);
  const [fulfillmentMethodConfigurations, setFulfillmentMethodConfigurations] = useState([]);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    getPaymentMethods();
    getFulfillmentMethods();
  }, []);


  const getPaymentMethods = () => {
    getPaymentMethodConfigurations().then((response) => {
      if (response && response.success && response.data) {
        setPaymentMethodConfigurations(response.data);
      }
    });
  };

  const getFulfillmentMethods = () => {
    getFulfillmentMethodConfigurations().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setFulfillmentMethodConfigurations(data);
      }
    });
  };

  const onEditData = (id) => {
    history.push(`/operations/payment-method/edit/${id}`);
  }

  const updateStatusFlag = (active, id) => {
    if (active) {
      activatePaymentMethod(id).then(async (response) => {
        if (response && response.success) {
          const newPaymentData = paymentMethodData.map((payment) => (payment.id === id
            ? ({ ...payment, active }) : payment));
          setPaymentMethodConfigurations(newPaymentData);
        }
      });
    }
    else {
      deactivatePaymentMethod(id).then(async (response) => {
        if (response && response.success) {
          const newPaymentData = paymentMethodData.map((payment) => (payment.id === id
            ? ({ ...payment, active }) : payment));
          setPaymentMethodConfigurations(newPaymentData);
        }
      });
    }
  };
  const checkAvailabilityToUpdate = (active, id, paymentMethod) => {
    let ableToUpdateStatus = true;
    fulfillmentMethodConfigurations.map((fulfillmentMethod) => {
      const { applicablePaymentMethods } = fulfillmentMethod;
      if (applicablePaymentMethods.includes(paymentMethod)) {
        ableToUpdateStatus = false;
        setWarning(true);
      }
    });
    if (ableToUpdateStatus) {
      setWarning(false);
      updateStatusFlag(active, id);
    }
  }

  return (
    <>
      <PaymentMethod
        data={paymentMethodData}
        selectedDataId={selectedDataId}
        onEditData={onEditData}
        onStatusChange={checkAvailabilityToUpdate}
        warning={warning}
      />
    </>
  );
};

export default PaymentMethodContainer;
