/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Page Customization Container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import PageCustomization from "../components/PageCustomization";
import { PageTypes } from "../../../common/utils/layoutUtils";
import {
  getPageConfigurationsByPageType,
  updateSectionConfig,
  toggleCustomLandingPageFlag,
  getPageConfigurationsByPageTypeAndCategoryId,
} from "../../../api/pageConfigurationsServices";
import commonUtils from "../../../common/utils/commonUtils";
import { getSeoInformation, getCategoryDetails } from "../../../api/categoryManagementServices";
import { getAllBrands } from "../../../api/brandServices";
import { getSitePreviewConfigurations, replicateContent, getContentReplicationStatus } from "../../../api/sitePreviewConfigurationsServices";
import { getWidgetConfigurationsByPageType } from "../../../api/widgetConfigurationsServices";
import { getPageLayouts, updatePageLayouts } from "../../../api/LayoutServices";

const PageCustomizationContainer = () => {
  const pages = [
    { name: "Global", pageType: PageTypes.GLOBAL, previewPath: "/" },
    { name: "Home", pageType: PageTypes.HOME, previewPath: "/" },
    { name: "PLP", pageType: PageTypes.PLP, previewPath: "/product-list" },
    { name: "CLP", pageType: PageTypes.CLP, previewPath: "/category-details" },
    { name: "PDP", pageType: PageTypes.PDP, previewPath: "/product-details" },
    { name: "My account", pageType: PageTypes.MYACCOUNT, previewPath: "/myaccount" },
    { name: "Wishlist", pageType: PageTypes.WISHLIST, previewPath: "/wishlist" },
    { name: "Delivery details", pageType: PageTypes.DELIVERY_DETAILS, previewPath: "/delivery-details" },
    { name: "Cart", pageType: PageTypes.CART, previewPath: "/cart" },
    { name: "Payment", pageType: PageTypes.PAYMENT, previewPath: "/payment" },
    { name: "Order confirmation", pageType: PageTypes.ORDER_CONFIRMATION, previewPath: "/order-confirmation" },
    { name: "Login", pageType: PageTypes.LOGIN, previewPath: "/login" },
    { name: "Sign up", pageType: PageTypes.SIGNUP, previewPath: "/signup" },
    { name: "Password reset", pageType: PageTypes.PASSWORD_RESET, previewPath: "/password-reset" },
    { name: "BLP", pageType: PageTypes.BLP, previewPath: "/brand-details" },
    { name: "Track your order", pageType: PageTypes.TRACK_YOUR_ORDER, previewPath: "/track-your-order" },
    { name: "404", pageType: PageTypes.NOTFOUND, previewPath: "/404" },
  ];
  const location = useLocation();
  const { categoryId, pageType } = commonUtils.getQueryParams(location);
  let defaultPage = { name: "Global", pageType: PageTypes.GLOBAL, previewPath: "/global-layouts" };
  if (pageType) {
    defaultPage = pages.find((page) => page.pageType === pageType);
  }
  const history = useHistory();

  const [pageConfiguration, setPageConfiguration] = useState(null);
  const [seoInformation, setSeoInformation] = useState(null);
  const [selectedPage, setSelectedPage] = useState(defaultPage);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [parentCategory, setParentCategory] = useState(categoryId || "");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [dropdownBrand, toggleDropdownBrand] = useState(false);
  const [customPageEnabled, setCustomPageEnabled] = useState(false);
  const [globalLayout, setGlobalLayouts] = useState(null);
  const [categoryLabel, setCategoryLabel] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [dropdownLayout, setDropdownLayout] = useState(false);

  useEffect(() => {
    if (selectedPage && selectedPage.pageType) {
      setIsLoading(false);
      getPageLayouts(selectedPage.pageType).then((response) => {
        if (response && response.success) {
          setLayouts(response.data);
        }
        setIsLoading(true);
      });
    }
  }, [selectedPage]);

  const toggleDropdownLayout = () => {
    setDropdownLayout(!dropdownLayout);
  };

  useEffect(() => {
    if (Array.isArray(layouts) && layouts.length > 0) {
      if (pageConfiguration) {
        const { layoutId } = pageConfiguration;
        const layout = layouts.find((item) => item.layoutId === layoutId);
        if (layout) {
          setSelectedLayout(layout);
        } else {
          setSelectedLayout(layouts[0]);
        }
      } else {
        setSelectedLayout(layouts[0]);
      }
    }
  }, [layouts]);

  const [locale, setLocale] = useState(null);
  /**
  * This funciton is used query param
  * @param {Object} newPrams
  * @param {Boolean} update
  */
  const updateQueryPrams = (newPrams, update = true) => {
    const locationIn = history.location || location;
    if (locationIn) {
      const { search } = locationIn;
      let searchObject = qs.parse(search);
      searchObject = { ...searchObject, ...newPrams };
      history.push({
        pathname: location.pathname,
        search: `?${qs.stringify(searchObject)}`,
      });
    }
  };

  const localeHandler = (localeLang) => {
    setLocale(localeLang);
    updateQueryPrams({ locale: localeLang });
  };
  console.warn({ location, history });

  useEffect(() => {
    if (location && location.search) {
      const serachObject = qs.parse(location.search);
      if (serachObject && serachObject.locale) {
        setLocale(serachObject.locale);
      }
    }
  }, [location]);

  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  /**
   * This method is used to get page configurations
   *
   * @returns
   */
  const getPageConfigurations = async (configId) => {
    const response = await getPageConfigurationsByPageType(selectedPage.pageType, configId, locale);
    if (response && response.success && response.data) {
      setPageConfiguration(response.data);
      return true;
    }
    setPageConfiguration(null);

    return false;
  };

  const getPageConfigurationByCategoryId = (catId) => {
    getPageConfigurationsByPageTypeAndCategoryId(selectedPage.pageType, catId, locale).then((responseIn) => {
      if (responseIn && responseIn.success && responseIn.data) {
        setPageConfiguration(responseIn.data);
      }
      setIsLoading(true);
    });
  };

  const getPageConfigurationByConfigId = (configId) => {
    getPageConfigurationsByPageType(selectedPage.pageType, configId, locale).then((responseIn) => {
      if (responseIn && responseIn.success && responseIn.data) {
        setPageConfiguration(responseIn.data);
      }
      setIsLoading(true);
    });
  };

  const handleChangeCategoryLayout = (catId = "", configId = "") => {
    updatePageLayouts(selectedLayout.subType, selectedLayout.layoutId, configId, catId).then((response) => {
      if (response && response.success && response.data) {
        setPageConfiguration(response.data);
        setParentCategory(catId);
      }
      setIsLoading(true);
    });
  };

  const handleChangeLayout = (item) => {
    setIsLoading(false);
    if (selectedPage && !(["PLP", "CLP", "BLP"].includes(selectedPage.pageType))) {
      setSelectedLayout(item);
      if (item) {
        getPageConfigurations(item.configId).then(() => {
          setIsLoading(true);
        });
      }
    } else {
      setSelectedLayout(item);
      setParentCategory("");
      setCategoryLabel("");
      setIsLoading(true);
    }
    toggleDropdownLayout();
  };

  useEffect(() => {
    if (selectedPage && (["CLP", "PLP", "BLP"].includes(selectedPage.pageType))) {
      if (selectedLayout && parentCategory) {
        getCategoryDetails(parentCategory, locale).then((response) => {
          if (response && response.success && response.data) {
            if (selectedPage.pageType === "CLP") {
              const { customLandingPageConfigured, landingPageConfigId } = response.data;
              setCustomPageEnabled(customLandingPageConfigured);
              handleChangeCategoryLayout(parentCategory, landingPageConfigId);
            } else {
              handleChangeCategoryLayout(parentCategory);
            }
          }
        });
      } else {
        setPageConfiguration(null);
      }
    } else {
      getPageConfigurations().then(() => {
        setIsLoading(true);
      });
    }
  }, [selectedPage, parentCategory, locale]);

  useEffect(() => {
    if (selectedPage.pageType !== "GLOBAL") {
      getPageConfigurationsByPageType("GLOBAL", null, locale).then((response) => {
        if (response && response.success) {
          setGlobalLayouts(response.data);
        }
      });
    } else {
      setGlobalLayouts({ ...pageConfiguration });
    }
  }, [pageConfiguration]);

  const [widgetConfigurations, setWidgetConfigurations] = useState(null);

  useEffect(() => {
    getWidgetConfigurationsByPageType(selectedPage.pageType, "web").then((response) => {
      if (response && response.success && response.data) {
        setWidgetConfigurations(response.data);
        setIsLoading(true);
      }
    });
  }, [selectedPage]);

  useEffect(() => {
    if (selectedPage.pageType === PageTypes.BLP) {
      setIsLoading(false);
      getAllBrands().then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setBrands(data);
          if (parentCategory) {
            setIsLoading(true);
            const brand = data.find((item) => item.id === parentCategory);
            setSelectedBrand(brand);
            setParentCategory(parentCategory);
            setCustomPageEnabled(brand ? brand.customLandingPageConfigured : false);
          } else {
            setIsLoading(true);
            setSelectedBrand(data[0] ? data[0] : null);
            setParentCategory(data[0] ? data[0].id : null);
            setCustomPageEnabled(data[0] ? data[0].customLandingPageConfigured : false);
          }
        } else {
          setIsLoading(true);
        }
      });
    }
  }, [selectedPage]);

  useEffect(() => {
    if (categoryId) {
      setIsLoading(false);
      getCategoryDetails(categoryId, locale).then((response) => {
        if (response && response.success && response.data) {
          const { name, customLandingPageConfigured, landingPageConfigId } = response.data;
          setCategoryLabel(name);
          if (selectedPage && selectedPage.pageType === "CLP") {
            setCustomPageEnabled(customLandingPageConfigured);
            getPageConfigurationByConfigId(landingPageConfigId);
          } else {
            getPageConfigurationByCategoryId(categoryId);
          }
        } else {
          setIsLoading(true);
        }
      });
    }
  }, [categoryId]);

  useEffect(() => {
    if (parentCategory) {
      getSeoInformation(parentCategory, locale).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setSeoInformation(data);
        }
      });
    } else {
      setSeoInformation(null);
    }
  }, [selectedPage, parentCategory, locale]);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [widgetTitle, setWidgetTitle] = useState("");
  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);

  /**
* This method is used to handle edit widget title
*
* @param {*} widget
* @param {*} name
*/
  const handleEditWidget = (widget, name) => {
    setSelectedWidget(widget);
    setWidgetTitle(name);
    setIsEdit(true);
  };

  /**
* This method is used to handle save Title
*
* @param {*} widget
* @param {*} sectionId
* @param {*} configId
*/
  const handleSaveTitle = (widget, sectionId, configId) => {
    const requestData = {
      ...widget,
      sectionId,
      configId,
    };
    requestData.title = widgetTitle;
    updateSectionConfig(requestData, locale).then((response) => {
      if (response && response.success) {
        if (selectedPage && ["CLP", "PLP", "BLP"].includes(selectedPage.pageType)) {
          getPageConfigurationsByPageType(
            selectedPage.pageType, configId, locale,
          ).then((responseIn) => {
            if (responseIn && responseIn.success && responseIn.data) {
              setPageConfiguration(responseIn.data);
            }
          });
        } else {
          getPageConfigurations(configId);
        }
        setIsEdit(false);
      }
    });
  };

  const toggleLandingPageStatus = () => {
    const { name: selectedPageType } = selectedPage;
    let catalogItemId = null;
    if (selectedPageType === "CLP") {
      catalogItemId = parentCategory;
    } else if (selectedPageType === "BLP") {
      catalogItemId = selectedBrand.id;
    } else {
      return;
    }
    const requestData = {
      pageType: selectedPageType,
      catalogItemId,
    };
    toggleCustomLandingPageFlag(requestData).then((response) => {
      if (response && response.success) {
        setCustomPageEnabled(!customPageEnabled);
      }
    });
  };

  /**
* This method is used to toggle change status
*
* @param {*} widget
* @param {*} sectionId
* @param {*} configId
* @param {*} active
*/
  const toggleActiveWidget = (widget, sectionId, configId, active) => {
    const requestData = {
      ...widget,
      sectionId,
      configId,
    };
    requestData.active = !active;
    updateSectionConfig(requestData, locale).then((response) => {
      if (response && response.success) {
        if (selectedPage && ["CLP", "PLP", "BLP"].includes(selectedPage.pageType)) {
          getPageConfigurationsByPageType(
            selectedPage.pageType, configId, locale,
          ).then((responseIn) => {
            if (responseIn && responseIn.success && responseIn.data) {
              setPageConfiguration(responseIn.data);
            }
          });
        } else {
          getPageConfigurations();
        }
        setIsEdit(false);
      }
    });
  };

  /**
*This method is used to toogle dropdown
*
*/
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
*This method is used to handle select page
*
* @param {String} PageTypes
*/
  const handleSelectPage = (page) => {
    setSelectedPage(page);
    setIsLoading(false);
    setParentCategory("");
    setCategoryLabel("");
    toggleDropdown();
    toggleDropdownBrand(false);
    setCustomPageEnabled(false);
    if (page) {
      updateQueryPrams({ pageType: page.pageType }, false);
    }
  };

  /**
  * This method is used to handle cancel
  *
  */
  const handleCancelTitle = () => {
    setWidgetTitle("");
    setIsEdit(false);
  };

  /**
  * This method is used to change parent category
  * @param {Array} categories
  */
  const handleParentCategory = (category) => {
    if (category) {
      setIsLoading(false);
      setParentCategory(category.id);
      setPageConfiguration(null);
    } else {
      setCategoryLabel("");
      setParentCategory("");
    }
  };

  /**
  * This method is used to change parent category
  * @param {Array} categories
  */
  const handleSelectBrand = (brand) => {
    const { id, customLandingPageConfigured } = brand;
    setParentCategory(id);
    setCustomPageEnabled(customLandingPageConfigured);
    setSelectedBrand(brand);
    toggleDropdownBrand(!dropdownBrand);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    replicateContent().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Page contents pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    }).catch((error) => {
      const alertData = {
        type: "danger",
        message: "Something went wrong. Push to live failed",
      };
      setAlert(alertData);
    });
  };

  const getReplicationStatus = async () => {
    getContentReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last content push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  return (
    <PageCustomization
      getPageConfigurations={getPageConfigurations}
      pageConfiguration={pageConfiguration}
      isLoading={isLoading}
      isEdit={isEdit}
      selectedWidget={selectedWidget}
      setWidgetTitle={setWidgetTitle}
      widgetTitle={widgetTitle}
      handleEditWidget={handleEditWidget}
      handleSaveTitle={handleSaveTitle}
      handleCancelTitle={handleCancelTitle}
      toggleDropdown={toggleDropdown}
      dropdownOpen={dropdownOpen}
      handleSelectPage={handleSelectPage}
      selectedPageData={selectedPage}
      categoryId={parentCategory}
      handleParentCategory={handleParentCategory}
      seoInformation={seoInformation}
      categoryLabel={categoryLabel}
      brands={brands}
      selectedBrand={selectedBrand}
      handleSelectBrand={handleSelectBrand}
      dropdownBrand={dropdownBrand}
      toggleDropdownBrand={toggleDropdownBrand}
      previewSiteUrl={previewSiteUrl}
      handlePushToLive={handlePushToLive}
      previewEnabled={previewEnabled}
      pages={pages}
      customPageEnabled={customPageEnabled}
      toggleLandingPageStatus={toggleLandingPageStatus}
      toggleActiveWidget={toggleActiveWidget}
      widgetConfigurations={widgetConfigurations}
      globalLayout={globalLayout}
      setCategoryLabel={setCategoryLabel}
      layouts={layouts}
      selectedLayout={selectedLayout}
      handleChangeLayout={handleChangeLayout}
      dropdownLayout={dropdownLayout}
      toggleDropdownLayout={toggleDropdownLayout}
      locale={locale}
      localeHandler={localeHandler}
      pushToLiveEnabled={pushToLiveEnabled}
      message={message}
      pushToLiveButtonEnabled={pushToLiveButtonEnabled}
      getReplicationStatus={getReplicationStatus}
    />
  );
};
export default PageCustomizationContainer;
