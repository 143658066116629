/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 *
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Badge from "../../../common/core/Badge";
import constants from "../../../common/utils/constants";

const StatusBadge = ({ status }) => {
  const {
    STATUS_SUCCESS,
    STATUS_INPROGRESS,
    STATUS_WARNING,
    STATUS_FAILED,
  } = constants;

  const [color, setColor] = useState({ spanColor: "", badgeColor: "" });

  const getColor = () => {
    let spanColor;
    let badgeColor;
    if (status === STATUS_SUCCESS) {
      spanColor = "Green";
      badgeColor = "success";
    } else if (status === STATUS_INPROGRESS) {
      spanColor = "Grey";
      badgeColor = "secondary";
    } else if (status === STATUS_WARNING) {
      spanColor = "Orange";
      badgeColor = "warning";
    } else if (status === STATUS_FAILED) {
      spanColor = "Red";
      badgeColor = "danger";
    }
    setColor({ spanColor, badgeColor });
  };

  useEffect(() => {
    getColor();
  }, [status]);

  return (
    <span style={{ color: color.spanColor }}>
      <Badge className="mx-1 p-1" color={color.badgeColor} pill>
        {" "}
      </Badge>
      {status}
    </span>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StatusBadge;
