/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Table from "../../../common/core/Table";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Alert from "../../../common/core/Alert";

/** ========= SVG IMAGE ICON ============= */
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

/** ================== MODULE STYLES ================ */
import styles from "../css/CouponsManagement.module.css";

const CouponsManagement = (props) => {
  const {
    coupons, getPageData, page, hasMoreData,
    searchTerm, handleSearchTerm,
    newCoupon, editCoupon, removeCoupon, message, changeCouponStatus,
    handlePushToLive, pushToLiveEnabled,  previewSiteUrl, previewEnabled,
    getReplicationStatus,
    pushToLiveButtonEnabled,
  } = props;

  /** internationalization using i18n from common and coupon  */
  const { t } = useTranslation(["common", "coupon"]);

  const redirectToPage = () => {
    let url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  return (
    <Row>
      <Col xs="12">
        {
          message && message.type && (
            <Alert color={message.type}>
              {message.message}
            </Alert>
          )
        }
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="5" className="text-truncate">
            <span className="pageText">
              {t("coupon:title_coupon_code")}
            </span>
          </Col>
          <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
            <Row>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {previewEnabled && (
                  <Button
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                    onClick={() => redirectToPage()}
                  >
                      {t("common:button_preview")}
                  </Button>
                )}
              </Col>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {pushToLiveEnabled && (
                  <Button
                    onClick={handlePushToLive}
                    disabled={!pushToLiveButtonEnabled}
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                  >
                    {t("common:button_push_to_live")}
                  </Button>
                )}
              </Col>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                    {t("common:button_refresh_status")}
                </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs="12">
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col className="mb-2" xs="12" sm="12" md="4">
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {t("coupon:title_manage_coupon_code")}
            </span>
          </Col>
          <Col className="mb-2" xs="12" sm>
            <div className={styles.areaActionRight}>
              <div>
                <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                  <Input placeholder="Search coupon code" value={searchTerm} onChange={handleSearchTerm} />
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer bg-color-tertiary-main"
                  >
                    <img
                      src={SearchIcon}
                      alt="search coupon code"
                      className={styles.icon}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={newCoupon}
                  className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary")}
                >
                  {t("coupon:label_button_add_coupon")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={clsx(styles.block3, styles.tableWrapper, " common-page-card")}>
          <Col>
            <div className={styles.coupon}>
              {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
              <div
                className={clsx(styles.couponBody, "table-responsive")}
              >
                <Table borderless className={styles.couponTable}>
                  {/* ========= TABLE HEAD S[0003] ======================= */}
                  <thead>
                    <tr>
                      <th>{t("coupon:label_id")}</th>
                      <th>{t("coupon:label_code")}</th>
                      <th>{t("common:label_startDate")}</th>
                      <th>{t("common:label_endDate")}</th>
                      <th>{t("common:table_title_status")}</th>
                      <th>{t("common:table_title_edit")}</th>
                      <th>{t("common:table_title_delete")}</th>
                    </tr>
                  </thead>
                  {/* ========= TABLE HEAD S[0003] ======================= */}
                  <tbody>
                    {/* ========= TABLE BODY S[0002] ======================= */}
                    {
                      coupons.length > 0
                        ? coupons.map(({
                          id, couponCodes, startDate, endDate, active,indefinite
                        }) => (
                          <tr key={id}>
                            <td>
                              <span id={id} className={clsx(styles.couponID, "d-block")}>
                                {id}
                              </span>
                              </td>
                              <td>
                                 {couponCodes && couponCodes.length ? couponCodes.join() : ""}
                              </td>
                              <td>
                                {startDate}
                              </td>
                              <td>{ indefinite ? (<>  <span className="pl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
                                  <path d="M0 0h24v24H0V0z" fill="none" />
                                  <path d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62 2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12c1.02 1.01 2.37 1.57 3.82 1.57 2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37z" />
                                </svg>
                              </span></>) : endDate }</td>
                              <td>
                                <div
                                  className={clsx(
                                    "custom-control",
                                    "custom-switch",
                                  )}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`expand-multiple${id}`}
                                    checked={active}
                                    onChange={(o) => changeCouponStatus(!active, id, o)}
                                  />
                                  <label className="custom-control-label" htmlFor={`expand-multiple${id}`}>
                                    <input type="hidden" />
                                  </label>
                                </div>
                              </td>
                              <td>
                                <span
                                  onClick={(o) => editCoupon(id, o)}
                                  onKeyDown={() => { }}
                                  tabIndex={0}
                                  role="button"
                                  className="commonPointer"
                                >
                                  <img
                                    className={clsx("commonPointer", styles.icon)}
                                    src={EditIcon}
                                    alt="Edit"
                                  />
                                </span>
                              </td>
                              <td>
                              <span
                                onClick={(o) => removeCoupon(id, o)}
                                onKeyDown={() => { }}
                                tabIndex={0}
                                role="button"
                                className="commonPointer"
                              >
                                <img
                                  className={clsx("commonPointer", styles.icon)}
                                  src={DeleteIcon}
                                  alt="Delete"
                                />
                              </span>
                            </td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan={8} align="center" valign="center"> Nothing to show.</td>
                          </tr>
                        )
                    }
                    {/* ========= TABLE BODY E[0002] ======================= */}
                  </tbody>
                </Table>
              </div>
              <div className={clsx(styles.pagination, "p-3", "d-flex", "justify-content-end")}>
                <div className="d-flex">
                  <span disabled={page <= 1} className={clsx(styles.item, styles.arrowLeft)} tabIndex={0} role="button" onKeyPress={() => { }} onClick={(o) => getPageData(-1, o)}>
                    <span />
                  </span>
                  <span className={styles.item} />
                  <span disabled={!hasMoreData} className={clsx(styles.item, styles.arrowRight)} tabIndex={0} role="button" onKeyPress={() => { }} onClick={(o) => getPageData(1, o)}>
                    <span />
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
CouponsManagement.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  newCoupon: PropTypes.func.isRequired,
  editCoupon: PropTypes.func.isRequired,
  message: PropTypes.arrayOf(PropTypes.any).isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
};

export default CouponsManagement;
