/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */

import React from "react";
import clsx from "clsx";
import { Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import styles from "../css/PaymentClasses.module.css";
import CustomizationTable from "../../../common/components/Table";
import Alert from "../../../common/core/Alert";
/** ========= CUSTOM COMPONENTS ========= */
import validateForm from "../../../common/utils/validateForm";

const PaymentMethod = ({
  submitForm,
  submitting,
  data,
  onEditData,
  onStatusChange,
  warning,
}) => {
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(e) => onStatusChange(!row.active, row.id, row.paymentMethod, e)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };
  const customRows = [
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Description",
      value: "",
      path: "description",
      transform: "",
    },
    {
      label: "Credit Limit",
      value: "",
      path: "paymentLimit",
      default: 0,
      transform: "",
    },
    {
      label: "Status",
      path: "render",
      render: getStatus,
    },
  ];
  const { t } = useTranslation(["payment-method", "common"]);
  const validator = validateForm();
  const { handleSubmit } = validator;

  return (
    <>
      <Form onSubmit={handleSubmit(submitForm)}>
        {
          warning && (
            <Alert color="warning">
              <p>{t("payment-method:payment_method_warning_message")}</p>
            </Alert>
          )

        }
        <fieldset disabled={submitting}>
          <Row className="common-page-card pageHeader">
            <Col xs="12">
              <span className="pageText">
                {t("payment-method:payment_method_management_title")}
              </span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "common-page-card ")}>
            <Col xs="12">
              <Row className="m-0 ">
                <Col xs="6" className="p-0">
                  <span
                    className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                  >
                    {t("payment-method:payment_method_title")}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="common-page-card">
            <Col xs="12">
              <Row>
                <Col xs="12">
                  <div className={clsx(styles.tableWrapper, "custom-table")}>
                    {data ?
                      <CustomizationTable
                        customRows={customRows}
                        customizationData={data}
                        isEditEnable
                        onEditData={onEditData}
                      /> : ""}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  );
};

PaymentMethod.propTypes = {
  submitForm: PropTypes.func.isRequired,
  submit: PropTypes.bool.isRequired,
  name: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PaymentMethod;
