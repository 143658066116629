// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Amjad Rehman A\n */\n\n.TaxClasses_borderRadius2__3DsJA {\n  border-radius: 0.125rem !important;\n}\n\n/** =========== ACTION AREA STYLES ============== **/\n.TaxClasses_areaActionRight__GQzA6 {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.TaxClasses_secondaryColor__2RmO4,\n.TaxClasses_secondaryColor__2RmO4:focus,\n.TaxClasses_secondaryColor__2RmO4:active {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.TaxClasses_tableWrapper__1YcgY{\n  min-height: calc(100vh - 365px);\n}", ""]);
// Exports
exports.locals = {
	"borderRadius2": "TaxClasses_borderRadius2__3DsJA",
	"areaActionRight": "TaxClasses_areaActionRight__GQzA6",
	"secondaryColor": "TaxClasses_secondaryColor__2RmO4",
	"tableWrapper": "TaxClasses_tableWrapper__1YcgY"
};
module.exports = exports;
