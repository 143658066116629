/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Current user details
 * @module
 */

const UserDataProps = {
  TOKEN: "21F4BE40EDB211E9AAEF0800200C9A66",
  USER_ID: "F72D82A0EDB111E9AAEF0800200C9A66",
  CONVENTION_ID: "CCC1FB90EDB111E9AAEF0800200C9A66",
  NAME: "2F33B430EDB211E9AAEF0800200C9A66",
  USERNAME: "3A02E2A0EDB211E9AAEF0800200C9A66",
  TYPE: "442185C0EDB211E9AAEF0800200C9A66",
  IS_LOGGED_IN: "2DE28790EE6C11E9AAEF0800200C9A66",
  USER_EMAIL: "525FA240EF1D11E9AAEF0800200C9A66",
  AUTHENTICATED: "700633A1B0F65FA8456A18BD6053193C",
  AUTHORITIES: "C76CADAF632CAB016BC0D9E6129E0E9F",
};

/**
 * Sets a global property. Using html5 local storage
 * @param {String} propertyName
 * @param {Object} propertyValue
 */
const setProperty = (propertyName, propertyValue) => {
  localStorage.setItem(propertyName, propertyValue);
};

/**
 * Retrieve global property by name
 * @param {String} propertyName
 */
const getProperty = (propertyName) => localStorage.getItem(propertyName);

/**
 * Remove global property by name
 * @param {String} propertyName
 */
const clearProperty = (propertyName) => {
  localStorage.removeItem(propertyName);
};

/**
 * Holds logged in user details
 */
const UserData = {
  setToken: (token) => setProperty(UserDataProps.TOKEN, token),
  getToken: () => getProperty(UserDataProps.TOKEN),
  clearToken: () => clearProperty(UserDataProps.TOKEN),

  setUserEmail: (email) => setProperty(UserDataProps.USER_EMAIL, email),
  getUserEmail: () => getProperty(UserDataProps.USER_EMAIL),
  clearUserEmail: () => clearProperty(UserDataProps.USER_EMAIL),

  setIsLogged: (isLoggedIn) => setProperty(UserDataProps.IS_LOGGED_IN, isLoggedIn),
  getIsLogged: () => getProperty(UserDataProps.IS_LOGGED_IN),

  setUserId: (userId) => setProperty(UserDataProps.USER_ID, userId),
  getUserId: () => getProperty(UserDataProps.USER_ID),
  clearUserId: () => clearProperty(UserDataProps.USER_ID),

  setName: (name) => setProperty(UserDataProps.NAME, name),
  getName: () => getProperty(UserDataProps.NAME),
  clearName: () => clearProperty(UserDataProps.NAME),

  setUserName: (username) => setProperty(UserDataProps.USERNAME, username),
  getUserName: () => getProperty(UserDataProps.USERNAME),
  clearUserName: () => clearProperty(UserDataProps.USERNAME),

  setType: (type) => setProperty(UserDataProps.TYPE, type),
  getType: () => getProperty(UserDataProps.TYPE),
  clearType: () => clearProperty(UserDataProps.TYPE),


  setAuthenticated: (authenticated) => setProperty(UserDataProps.AUTHENTICATED, authenticated),
  getAuthenticated: () => getProperty(UserDataProps.AUTHENTICATED),
  clearAuthenticated: () => clearProperty(UserDataProps.AUTHENTICATED),


  setAuthorities: (authorities) => setProperty(UserDataProps.AUTHORITIES, authorities),
  getAuthorities: () => getProperty(UserDataProps.AUTHORITIES),
  clearAuthorities: () => clearProperty(UserDataProps.AUTHORITIES),

  getOrganizationId: () => "5e41373b4d50307e272eac3d",

  setAll: (userData) => {
    const {
      token, id, name, username, type, isLoggedIn, email, authenticated, authorities,
    } = userData;
    if (token) { setProperty(UserDataProps.TOKEN, token); }
    if (id) { setProperty(UserDataProps.USER_ID, id); }
    if (name) { setProperty(UserDataProps.NAME, name); }
    if (username) { setProperty(UserDataProps.USERNAME, username); }
    if (type) { setProperty(UserDataProps.TYPE, type); }
    if (isLoggedIn) { setProperty(UserDataProps.IS_LOGGED_IN, isLoggedIn); }
    if (email) { setProperty(UserDataProps.USER_EMAIL, email); }
    if (authenticated) { setProperty(UserDataProps.AUTHENTICATED, authenticated); }
    if (authorities) { setProperty(UserDataProps.AUTHORITIES, authorities); }
  },

  clearAll: () => {
    clearProperty(UserDataProps.TOKEN);
    clearProperty(UserDataProps.USER_EMAIL);
    getProperty(UserDataProps.IS_LOGGED_IN);
    clearProperty(UserDataProps.USER_ID);
    clearProperty(UserDataProps.NAME);
    clearProperty(UserDataProps.USERNAME);
    clearProperty(UserDataProps.TYPE);
    clearProperty(UserDataProps.AUTHENTICATED);
    clearProperty(UserDataProps.AUTHORITIES);
    clearProperty(UserDataProps.LOCALE);
  },
};

Object.freeze(UserData);
export default UserData;
