/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Droppable, Draggable } from "react-beautiful-dnd";

/** ========= TAG COMPONENTS ========= */
import Label from "../../../common/core/Label";
import CardBody from "../../../common/core/CardBody";
import Card from "../../../common/core/Card";
import CardHeader from "../../../common/core/CardHeader";

/** ========= SUB COMPONENT ========= */
import SubMenuItem from "./SubMenuItem";

/** ========= SVG IMAGE ICON ========= */
import PlusIcon from "../../../common/assets/images/svg/add-24px.svg";
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

/** ========= MODULE STYLES ========= */
import styles from "../css/MenuDetails.module.css";

const MenuColumn = (props) => {
  const {
    level, columnConfig, index, menus, id, parentId, rootMenuLength, minimal, collapseOpen, position,
    addNewColumn, removeColumn, onMouseDown, onMouseUp,
    addMenu, editMenu, deleteMenu, getLocalKey,
  } = props;
  const { NAVIGATION_MENU_POSITIONS } = constants;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  const isWebResponsiveHeader = NAVIGATION_MENU_POSITIONS.WEB_RESPONSIVE_HEADER === position;
  const isWebFooter = NAVIGATION_MENU_POSITIONS.WEB_FOOTER === position;

  const canRemoveColumn = !(isWebResponsiveHeader || isWebFooter);
  const hasHeader = !(isWebResponsiveHeader || isWebFooter);

  const name = `COL-${index + 1}`;

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          key={`menu_col_${index}`}
          className={clsx(styles.menuColumn,
            "pb-1", "d-flex", " flex-row",
            `col-${((columnConfig && columnConfig.columnWidth) || 1) * 2}`,
            snapshot.isDragging ? styles.onDragging : "")}
        >
          <>
            <Card className="w-100 d-flex overflow-hidden">
              {
                hasHeader ? (
                  <CardHeader
                    className={clsx(styles.cardHeader, "justify-content-center", minimal ? "p-2" : "p-3")}
                  >
                    <span
                      disabled={rootMenuLength > 5}
                      className={styles.icon}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      onClick={(o) => addNewColumn(index, false, o)}
                    >
                      <img src={PlusIcon} alt="" />
                    </span>
                    <span
                      {...provided.dragHandleProps}
                      className={clsx("mx-auto", styles.move)}
                    >
                      <div className={`text-center ${minimal ? "small" : ""} text-truncate w-100`}>
                        {name}
                      </div>
                    </span>
                    <span
                      disabled={rootMenuLength > 5}
                      className={styles.icon}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      onClick={(o) => addNewColumn(index, true, o)}
                    >
                      <img src={PlusIcon} alt="" />
                    </span>
                  </CardHeader>
                ) : (
                  <span className="d-none">
                    <span {...provided.dragHandleProps} />
                  </span>
                )
              }
              <CardBody className="px-1 py-2">
                <div className="w-100 ">

                  <Droppable key={id} droppableId={`sub_menu_${id}_${level}_${index}`} type={`droppableSubMenu_${getLocalKey()}`}>
                    {(providedIn) => (
                      <div
                        ref={providedIn.innerRef}
                        {...providedIn.droppableProps}
                      >
                        {/* ============== EACH SUB MAIN MENU ITEM S=================== */}
                        {
                          Array.isArray(menus)
                          && menus.map((menuIn, indexIn) => (
                            <SubMenuItem
                              parentId={parentId}
                              collapseOpen={collapseOpen}
                              minimal={minimal}
                              level={level + 1}
                              index={indexIn}
                              id={menuIn.id}
                              key={menuIn.id}
                              name={menuIn.name}
                              type={menuIn.type}
                              column={index}
                              menus={menuIn.menus}
                              editMenu={editMenu}
                              addMenu={addMenu}
                              deleteMenu={deleteMenu}
                              getLocalKey={getLocalKey}
                              showEdit={isWebResponsiveHeader || isWebFooter}
                              // extra
                              image={menuIn.image}
                              button={menuIn.button}
                              description={menuIn.description}
                            />
                          ))
                        }
                        {/* ============== EACH MAIN MENU ITEM E=================== */}
                        {providedIn.placeholder}
                      </div>
                    )}
                  </Droppable>

                  {/* ============== ADD NEW SUB MAIN MENU  S=================== */}
                  <div
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={(o) => addMenu({
                      columnId: id, id: parentId, level: (level + 1), column: index,
                    }, o)}
                    className={clsx(minimal ? "p-1" : "p-2", minimal ? styles.minimal : "", "mb-1 ", "border", "commonPointer")}
                  >
                    <span className="d-flex align-items-center commonPointer">
                      <span className="px-1">
                        <img className={styles.icon} src={PlusIcon} alt="" />
                      </span>
                      <span className="pl-1 text-truncate font-weight-bold text-primary text">
                        {t("site-navigation:label_add_item")}
                      </span>
                    </span>
                  </div>
                  {/* ============== ADD NEW SUB MAIN MENU  E=================== */}
                </div>
              </CardBody>
              {
                canRemoveColumn && (
                <div className={styles.cardHeader}>
                  <span
                    disabled={rootMenuLength < 2}
                    className={clsx("ml-auto", styles.icon)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={(o) => removeColumn(index, o)}
                  >
                    <img src={ClearIcon} alt="" />
                  </span>
                </div>
                )
              }
            </Card>
            <Label
              onMouseDown={(o) => onMouseDown(index, o)}
              onMouseUp={(o) => onMouseUp(index, o)}
              className={styles.dargHand}
            />
          </>
        </div>
      )}
    </Draggable>
  );
};

MenuColumn.defaultProps = {
  rootMenuLength: 0,
  menus: null,
  minimal: false,
  collapseOpen: false,
};

MenuColumn.propTypes = {
  collapseOpen: PropTypes.bool,
  minimal: PropTypes.bool,
  level: PropTypes.number.isRequired,
  rootMenuLength: PropTypes.number,
  menus: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  columnConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  position: PropTypes.string.isRequired,
  addNewColumn: PropTypes.func.isRequired,
  removeColumn: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,

  addMenu: PropTypes.func.isRequired,
  editMenu: PropTypes.func.isRequired,
  deleteMenu: PropTypes.func.isRequired,
  getLocalKey: PropTypes.func.isRequired,
};

export default MenuColumn;
