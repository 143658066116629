// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n .AddCategory_block2__3OgOK {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.AddCategory_borderRadius2__1hjgU {\n  border-radius: 0.125rem !important;\n}\n\n.AddCategory_secondaryColor__vwUNI {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.AddCategory_primaryColor__1swhk {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n\n.AddCategory_btn__2MgEZ {\n  min-width: 7rem;\n}\n\n\n/* ========== FORM AREA ============ */\n.AddCategory_block3__xBCH1 {\n  padding: 1.25rem;\n}\n\n/* ============= PRODUCT TITLE ========= */\n.AddCategory_titleTextContainer__1Z0Kf {\n  padding: 0 2.1rem 0.125rem 2.1rem;\n  border-bottom: 1px solid #edf0f5;\n  margin: 0 -0.938rem;\n}\n\n.AddCategory_titleTextContainer__1Z0Kf .AddCategory_pageTitleText__LHlDZ{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n \n\n\n/** ========= PARENT CATEGORY  ================ **/\n.AddCategory_block5__2sSXd {\npadding: 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"block2": "AddCategory_block2__3OgOK",
	"borderRadius2": "AddCategory_borderRadius2__1hjgU",
	"secondaryColor": "AddCategory_secondaryColor__vwUNI",
	"primaryColor": "AddCategory_primaryColor__1swhk",
	"btn": "AddCategory_btn__2MgEZ",
	"block3": "AddCategory_block3__xBCH1",
	"titleTextContainer": "AddCategory_titleTextContainer__1Z0Kf",
	"pageTitleText": "AddCategory_pageTitleText__LHlDZ",
	"block5": "AddCategory_block5__2sSXd"
};
module.exports = exports;
