/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import PageCustomizationContainer from "./containers/PageCustomizationContainer";
import SEOInfoEditorContainer from "./containers/SEOInfoEditorContainer";
import WidgetContentEditorContainer from "./containers/WidgetContentEditorContainer";
import FeaturedProductsEditorContainer from "./containers/FeaturedProductsEditorContainer";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";
import CombinedWidgetEditorContainer from "./containers/CombinedWidgetEditorContainer";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */


/** components */


const ProductManagement = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={PageCustomizationContainer} />
    <PrivateRouter exact path={`${path}/content-seo-editor`} component={SEOInfoEditorContainer} />
    <PrivateRouter path={`${path}/content-editor`} component={WidgetContentEditorContainer} />
    <PrivateRouter path={`${path}/content-product-editor`} component={FeaturedProductsEditorContainer} />
    <PrivateRouter path={`${path}/combined-widget-editor`} component={CombinedWidgetEditorContainer} />
  </Switch>
);
ProductManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default ProductManagement;
