/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import styles from "../css/JobConfiguration.module.css";
import AddJobTaskModal from "./AddJobTaskModal";
import JobTask from "./JobTask";

const JobTaskConfiguration = ({
  selectedJobTaskData,
  setSelectedJobTaskData,
  handleChange,
  renderFilter,
  isAddJobTaskModalOpen,
  setAddJobTaskModalOpen,
  jobTaskData,
  handleJobTaskData,
  setJobTaskData,
  handleNext,
  jobTaskTypes,
  jobTaskTemplateData,
  setjobTaskTemplateData,
}) => {
  const { t } = useTranslation(["common", "job-configuration"]);
  return (
    <Col xs="12">
      <Row>
        <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>
          {t("job-configuration:span_job_task_configuration")}
        </span>
      </Row>
      <Row className={styles.areaActionRight}>
        <Button
          onClick={() => setAddJobTaskModalOpen(!isAddJobTaskModalOpen)}
          className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
        >
          {t("job-configuration:button_add_job_task")}
        </Button>
      </Row>
      <JobTask
        jobTaskData={jobTaskData}
        setJobTaskData={setJobTaskData}
        handleJobTaskData={handleJobTaskData}
        canModify
      />
      {
        isAddJobTaskModalOpen
          ? (
            <AddJobTaskModal
              selectedJobTaskData={selectedJobTaskData}
              setSelectedJobTaskData={setSelectedJobTaskData}
              handleChange={handleChange}
              renderFilter={renderFilter}
              isAddJobTaskModalOpen={isAddJobTaskModalOpen}
              setAddJobTaskModalOpen={setAddJobTaskModalOpen}
              handleJobTaskData={handleJobTaskData}
              jobTaskTypes={jobTaskTypes}
              jobTaskTemplateData={jobTaskTemplateData}
              setjobTaskTemplateData={setjobTaskTemplateData}
            />
          ) : ""
      }
      <Row className={styles.areaActionRight}>
        <Button onClick={() => handleNext("/administration/new-job/job-review")}>
          {t("common:button_next")}
        </Button>
      </Row>
    </Col>
  );
};

JobTaskConfiguration.propTypes = {
  handleNext: PropTypes.func.isRequired,
  selectedJobTaskData: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedJobTaskData: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  renderFilter: PropTypes.func.isRequired,
  isAddJobTaskModalOpen: PropTypes.bool.isRequired,
  setAddJobTaskModalOpen: PropTypes.func.isRequired,
  handleJobTaskData: PropTypes.func.isRequired,
  jobTaskData: PropTypes.objectOf(PropTypes.any).isRequired,
  setJobTaskData: PropTypes.func.isRequired,
  jobTaskTypes: PropTypes.objectOf(PropTypes.any).isRequired,
  jobTaskTemplateData: PropTypes.objectOf(PropTypes.any).isRequired,
  setjobTaskTemplateData: PropTypes.func.isRequired,
};
export default JobTaskConfiguration;
