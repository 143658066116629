/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import React from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
import Table from "../../../common/core/Table";

/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
import styles from "../css/EditProduct.module.css";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */

const StaticDetailsView = (props) => {
  const { properties, type } = props;

  const fields = [
    "name",
    "description",
    "longDescription",
    "productBrand",
    "_id",
    "unitOfQuantity",
    "metaKeywords",
    "metaDescription",
    "pageTitle",
    "brandName",
  ];
  const splitCamelCase = (text) => {
    const x = text.match(/^[A-Z]?[^A-Z]*|[A-Z][^A-Z]*/g).join(" ");
    return x.charAt(0).toUpperCase() + x.slice(1);
  };

  return (
    <div className="table-responsive">
      <Table className={styles.tableDetails}>
        <tbody>
          <>
            {properties
              ? Object.entries(properties).map(([key, value]) => (
                <>
                  {(typeof value === "string"
                    && fields.some((each) => each === key)
                    && type === "productDetails")
                    && (
                      <tr>
                        <td>{splitCamelCase(key)}</td>
                        <td>{value}</td>
                      </tr>
                    )}
                  {(typeof value === "string"
                    && type === "retailerExtensions")
                    && (
                      <tr>
                        <td>{key}</td>
                        <td>{value}</td>
                      </tr>
                    )}
                  {(typeof value === "boolean"
                    && type === "retailerExtensions")
                    && (
                      <tr>
                        <td>{key}</td>
                        <td>{value.toString()}</td>
                      </tr>
                    )}
                </>
              ))
              : ""}
          </>
        </tbody>
      </Table>
    </div>
  );
};

StaticDetailsView.propTypes = {
  type: PropTypes.string.isRequired,
  properties: PropTypes.objectOf(PropTypes.any),
};

StaticDetailsView.defaultProps = {
  properties: null,
};

export default StaticDetailsView;
