/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";

/** ======== CUSTOM COMPONENTS ========= */
import EditProduct from "../components/EditProduct";

/** ===== API SERVICE FUNCTIONS =========== */
import { updateProduct, getProduct, getRelatedProducts } from "../../../api/productManagementServices";

/** Base container for edit product details */
const EditProductContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  if (!params.productId) {
    history.push("/merchandising/product");
  }

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    productType: "",
    enableProduct: false, /** product buy default disabled */
    attributes: {}, /** multiple attribute key value pairs */
    skuName: "",
    listPrice: "",
    quantity: "",
    onSale: false, /** by default on sale is false */
    images: [], /** selected images list */
    description: "",
    longDescription: "",
    startDate: new Date(),
    endDate: new Date(),
    landingPageUrl: "",
    skus: [],
    relatedProducts: [],
    primaryParentCategory: "",
    productOptions: [],
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [retailerExtensions, setRetailerExtensions] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [categoryData, setcategoryData] = useState();
  const [selectedLanguage, setLanguage] = useState();
  const [translatableFileds, setSelectedtranslatableFiledsect] = useState([]);
  const [isDefault, setIsDefault] = useState(true);

  /**
       * This method is used to handle locale selection
       */
  const localeHandler = (localeLang, defaultIn, translatableProperties) => {
    setLanguage(localeLang);
    setSelectedtranslatableFiledsect(translatableProperties);
    setIsDefault(defaultIn);
  };

  useEffect(() => {
    let localForm = form;
    const loadRelatedProducts = (newFormData) => {
      getRelatedProducts(params.productId).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          if (Array.isArray(data)) {
            /** null rows for server */
            const filteredData = data.filter((each) => each);
            setRelatedProducts(filteredData);
            const relatedProductIds = filteredData.filter((each) => each).map(({ id }) => id);
            setForm({ ...newFormData, relatedProducts: relatedProductIds });
          }
        }
      });
    };
    if (selectedLanguage) {
      getProduct(params.productId, selectedLanguage).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          const { parentCategoryDetails } = response.data;
          if (parentCategoryDetails != null) {
            parentCategoryDetails.map((parentCategory) => {
              const primaryParentCategory = parentCategory.id;
            });
          }
          if (data) {
            localForm = {
              ...form,
              id: data.id,
              name: data.name || "",
              productOptions: data.productOptions || "",
              productType: data.type || "",
              enableProduct: data.active,
              attributes: data.productSelector || {},
              skuName: data.name || "",
              description: data.description || "",
              longDescription: data.longDescription || "",
              startDate: new Date(data.startDate),
              endDate: new Date(data.endDate),
              landingPageUrl: data.landingPageBaseUrl || "",
              listPrice: 25,
              quantity: 405,
              onSale: false,
              images: data.medias || [],
              skus: data.skus,
              primaryParentCategory: data.primaryParentCategory,
            };
            setForm(localForm);
            setSelectedCategories(data.parentCategories);
            setCategories(data.parentCategoryDetails);
            const { retailerExtensions: re, ...rest } = data;
            setRetailerExtensions({ ...re } || null);
            setProductDetails({ ...rest } || null);
          }
        }
        loadRelatedProducts(localForm);
      });
    }
  }, [params.productId, selectedLanguage]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
 * This method is used to select categories
 *
 * @param {*} value
 * @param {*} data
 */
  const handleChangeCategory = (value, data) => {
    let newList = [];
    if (Array.isArray(value) && value.length > 0) {
      newList = value.map((item) => {
        let category = Array.isArray(categories) && categories.length > 0 && categories.find((cat) => (cat.id === item));
        if (!category) {
          category = Array.isArray(data) && data.length > 0 && data.find((cat) => (cat.id === item));
        }
        return category;
      });
    }
    setCategories(newList);
    setSelectedCategories(value);
  };

  /**
   * This method is used to update sku name by product name and attribute changes
   * @param {String} productName
   * @param {Object} productAttributes
   */
  const skuNameUpdate = (productName, productAttributes) => {
    if (productAttributes) {
      const hasNullAttributes = Object.entries(
        productAttributes,
      ).some((attribute) => !attribute[1]);
      /** fix for null value from server */
      if (hasNullAttributes || productName === "") { return; }
      let newSKUName = `${productName}${Object.keys(productAttributes).length > 0
        ? "-" : ""}${Object.entries(productAttributes).map((each) => `${each[1]}`).join("-")}`;
      newSKUName = newSKUName.replace(/ /g, "_");
      setForm({
        ...form,
        skuName: newSKUName,
      });
    }
  };

  useEffect(() => {
    skuNameUpdate(form.name, form.attributes);
  }, [form.name, form.attributes]);

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used for  cancel and redirect to product listing page
   */
  const onCancel = () => {
    history.push("/merchandising/product");
  };

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const onSubmit = async (value) => {
    const requestBody = {
      id: form.id,
      name: form.name,
      type: form.productType,
      active: form.enableProduct,
      parentCategories: selectedCategories,
      skuName: form.skuName,
      salePrice: form.salePrice,
      listPrice: form.listPrice,
      quantity: form.quantity,
      onSale: form.onSale,
      description: form.description,
      longDescription: form.longDescription,
      startDate: moment(form.startDate).format("YYYY-MM-DD hh:mm:ss"),
      endDate: moment(form.endDate).format("YYYY-MM-DD hh:mm:ss"),
      landingPageBaseUrl: form.landingPageUrl,
      medias: form.images,
      relatedProducts: form.relatedProducts,
      primaryParentCategory: form.primaryParentCategory,
    };
    if (value) {
      /** setting on submit true */
      setOnSubmitting(true);
      const response = await updateProduct(requestBody, selectedLanguage);
      if (response && response.success) {
        setOnSubmitting(false);
        history.push("/merchandising/product");
      }
    }
  };

  /**
   * This method is used to redirect page to add sku page
   *
   */
  const handleRedirectToModifySku = () => {
    history.push(`/merchandising/product/modify-sku/${params.productId}`);
  };

  /**
   * This method is used to redirect page to edit sku page
   *
   */
  const handleEditSku = (skuId) => {
    history.push(`/merchandising/product/modify-sku/${params.productId}/?skuId=${skuId}`);
  };

  return (
    <EditProduct
      /** form data */
      name={form.name}
      id={form.id}
      enableProduct={form.enableProduct}
      skuName={form.skuName}
      listPrice={form.listPrice}
      quantity={form.quantity}
      onSale={form.onSale}
      images={form.images}
      skus={form.skus}
      selectedCategories={selectedCategories}
      categories={categories}
      productType={form.productType}
      attributes={form.attributes}
      description={form.description}
      longDescription={form.longDescription}
      startDate={form.startDate}
      endDate={form.endDate}
      landingPageUrl={form.landingPageUrl}
      relatedProducts={form.relatedProducts}
      relatedProductDetails={relatedProducts}
      retailerExtensions={retailerExtensions}
      productDetails={productDetails}
      primaryParentCategory={form.primaryParentCategory}
      productOptions={form.productOptions}
      translatableFileds={translatableFileds}
      localeHandler={localeHandler}
      isDefault={isDefault}
      /** sate change events */
      handleChange={handleChange}
      handleChangeCategory={handleChangeCategory}
      handleFormChange={handleFormChange}
      /** form action */
      onSubmitting={onSubmitting}
      onCancel={onCancel}
      onSubmit={onSubmit}
      handleEditSku={handleEditSku}
      handleRedirectToModifySku={handleRedirectToModifySku}
    />
  );
};

EditProductContainer.propTypes = {

};

export default EditProductContainer;
