/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PageCustomization
 *
 * @author Jithendra RK
 *
 */

import React, { useState } from "react";
import clsx from "clsx";
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalFooter from "../../../common/core/ModalFooter";
import Button from "../../../common/core/Button";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/WidgetContentEditor.module.css";
import ImageCropper from "../../../common/components/imageCropper/Cropper";
import ImageSelection from "./ImageSelection";
import errorIcon from "../../../common/assets/images/svg/error.svg";
import { getAspectRatio } from "../../../common/utils/layoutUtils";
import AnimationAssetsEditor from "./AnimationAssetsEditor";
import { uploadImage } from "../../../api/pageContentServices";
import Form from "../../../common/core/Form";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import { useTranslation } from "react-i18next";
import commonUtils from "../../../common/utils/commonUtils";

const BannerContainerEditor = (props) => {

  const {
    widgetType,
    configId,
    categoryId,
    selectedPage,
    channel,
    locale,
    errors,
    widgetConfig,
    content,
    register,
    media,
    mediaOption,
    selectedMediaType,
    modalOpen,
    setModalOpen,
    isUploading,
    setIsUploading,
    getBreakPoints,
    submitForm,
    handleChange,
    handleChangeFile,
    handleUploadImage,
    handleCloseModal,
    handleRemoveImg,
    handleChangeAnimationAsset,
    handleChangeShape,
    handleMediaOption,
    handleSubmit, 
    buttonLabel,
    linkType,
    setLinkType,
    className,
    modal,
    setModal,
    isEdit,
    toggle
  } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { t } = useTranslation(["common", "page-customization"]);

  /**
   *This method is used to toggle dropdown
   *
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  /**
   * This method is used to toggle select link
   *
   * @param {*} type
   */
  const toggleSelect = (type) => {
    setLinkType(type);
    setDropdownOpen(false);
  };


  return (
    <div>
      
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <Form onSubmit={handleSubmit(submitForm)}>
          <ModalHeader toggle={toggle}>{commonUtils.toSentenceCase(widgetConfig ? widgetConfig.name : "")}</ModalHeader>
          <ModalBody>
            <Row className={styles.block2}>
              {widgetConfig ? (
                <Col>
                  <FormGroup className="w-100">
                    <Label className="formTextLabel" for={'link'}>
                      Link
                                    </Label>
                    <Input
                      name={'link'}
                      defaultValue={content['link']}
                      className="formText"
                      placeholder="Link"
                      onChange={(e) => handleChange('link', e)}
                      innerRef={
                        register({
                          minLength: {
                            value: 3,
                            message: "Please enter at least 3 letters",
                          },
                          maxLength: {
                            value: widgetConfig.propertyConfig.link.maxSize,
                            message: `Should haven't more than ${widgetConfig.propertyConfig.link.maxSize} `,
                          },
                        })
                      }
                      invalid={!!(errors['link'] && errors['link'].message)}
                    />
                    <FormFeedback>
                      {
                        errors['link'] && errors['link'].message
                      }

                    </FormFeedback>
                  </FormGroup>
                </Col>
              ) : ""}

              {widgetConfig && widgetConfig.propertyConfig['linkType'].type === "DROPDOWN" && (
                <Col xs="12" md="6" className="d-flex align-items-center">
                  <FormGroup className="w-100">
                    <Label className="formTextLabel" for="exampleEmail">Call to action</Label>
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                      className={clsx(styles.widgetContentEditorDropdownBtn, "commonDropDownWrapper")}
                    >
                      <DropdownToggle caret>
                        <span>
                          {linkType || t("page-customization:internal")}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu
                        className={clsx(styles.widgetContentEditorDropdownMenu, "dropdownMenuWrapper")}
                      >
                        <div
                          onKeyPress={() => { }}
                          role="button"
                          tabIndex="0"
                          className="dropdownMenuItem"
                          onClick={() => toggleSelect("Internal")}
                        >
                          Internal
                                    </div>
                        <div
                          onKeyPress={() => { }}
                          role="button"
                          tabIndex="0"
                          className="dropdownMenuItem"
                          onClick={() => toggleSelect("External")}
                        >
                          External
                                    </div>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
              )}
            </Row>


            <Row className={clsx(styles.block4, "common-page-card")}>
              <Col xs="12">
                <Row className={styles.subTitle}>
                  <Col xs="6">
                    <span>Background Image</span>
                  </Col>
                  <Col xs="6" className={styles.mediaError}>
                    {errors && errors.media && errors.media.message
                      && (
                        <span className="d-flex align-items-center">
                          <img src={errorIcon} alt="error" />
                          <span>{errors.media.message}</span>
                        </span>
                      )}
                  </Col>
                </Row>
                <hr />
                {widgetConfig && widgetConfig.propertyConfig.bannerContent
                  && (
                    <Row className={styles.block3}>
                      {widgetConfig.propertyConfig.bannerContent && (
                        <>
                          {Object.keys(
                            widgetConfig.propertyConfig.bannerContent.aspectRatio,
                          ).map((type) => (
                            <Col className="d-flex col-auto justify-content-start align-items-center">
                              <FormGroup>
                                <Label className="formTextLabel pt-1">{getBreakPoints(type)}</Label>
                                <ImageSelection
                                  handleChangeFile={handleChangeFile}
                                  imageUrl={media[type]}
                                  modalOpen={modalOpen}
                                  type={type}
                                  setModalOpen={setModalOpen}
                                  handleUpload={handleUploadImage}
                                  imageUploadApi={uploadImage}
                                  isUploading={isUploading}
                                  setIsUploading={setIsUploading}
                                  handleCloseModal={handleCloseModal}
                                  handleRemoveImage={handleRemoveImg}
                                />
                              </FormGroup>
                            </Col>
                          ))}
                          <ImageCropper
                            imageUrl={content.imageUrl}
                            modalOpen={modalOpen}
                            aspect={
                              getAspectRatio(
                                widgetConfig.propertyConfig.bannerContent.aspectRatio,
                                selectedMediaType,
                              )
                            }
                            setModalOpen={setModalOpen}
                            handleUpload={handleUploadImage}
                            imageUploadApi={uploadImage}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            handleCloseModal={handleCloseModal}
                          />
                        </>
                      )}
                    </Row>
                  )}
              </Col>
            </Row>


          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Cancel</Button>
            <Button type="submit" className="buttonSave bg-color-tertiary-main">
              <span>{isEdit ? "Update" : "Save"}</span>
            </Button>{' '}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default BannerContainerEditor;