/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import ButtonGroup from "../../../common/core/ButtonGroup";

/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */

/** ========= MODULE STYLES ========= */
import styles from "../css/CustomMenuItemCreator.module.css";
import FormFeedback from "../../../common/core/FormFeedback";

const CustomMenuItemBasicForm = (props) => {
  const {
    name,
    url,
    handleFormChange,
    onSave,
    onClear,
    error,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  return (
    <Form>
      <fieldset disabled={false}>
        <FormGroup>
          <Label className="formTextLabel">
            {t("site-navigation:label_title")}
          </Label>
          <Input
            className="formText w-100"
            placeholder={t("site-navigation:placeholder_title")}
            invalid={error.name}
            name="name"
            value={name}
            onChange={handleFormChange}
          />
          <FormFeedback>
            {error.name}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="formTextLabel">
            {t("site-navigation:label_url")}
          </Label>
          <Input className="formText w-100" placeholder={t("site-navigation:placeholder_url")} name="url" value={url} onChange={handleFormChange} />
        </FormGroup>
        <FormGroup className="text-center">
          <ButtonGroup>
            <Button type="button" onClick={onClear} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1", "btn-sm")}>
              {t("common:button_cancel")}
            </Button>
            <Button type="button" onClick={onSave} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size", "btn-sm")}>
              {t("common:button_save")}
            </Button>
          </ButtonGroup>
        </FormGroup>
      </fieldset>
    </Form>
  );
};

CustomMenuItemBasicForm.defaultProps = {
  name: null,
  url: null,
};

CustomMenuItemBasicForm.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  /** form methods */
  onSave: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
};

export default CustomMenuItemBasicForm;
