/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";

import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";

/** ===== IMAGE ICONS =========== */
import SearchIcon from "../../../common/assets/images/svg/search.svg";

/** ======== COMPONENTS */

import CustomizationTable from "../../../common/components/Table";

import styles from "../css/SearchFacet.module.css";


const SearchFacetManagement = ({ handleIndexing, rowsLoading }) => (
  <>
    <Row>
      <Col xs="12">

        <Row className="pageHeader common-page-card">
          <Col xs="12">
            <span className="pageText">Search Facet Management</span>
          </Col>
        </Row>
        <Row className={clsx(styles.block2, "common-page-card")}>
          <Col className="mb-2" xs="12" sm="12" md="4">
            <span
              className="fontsizeMediumBold"
            >
              Manage Search Facet
            </span>
          </Col>
          <Col className="mb-2" xs="12" sm>
            <div className={styles.areaActionRight}>
              <div>
                <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                  <Input placeholder="Search Facet" />
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer bg-color-tertiary-main"
                  >
                    <img
                      src={SearchIcon}
                      alt="search synonym"
                      className={styles.icon}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </div>
              <div>
                <Button
                  // onClick={addNewCategory}
                  className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  Add New Facet
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={clsx(styles.tableWrapper, " common-page-card")}>
          <Col xs="12">
            <div className="custom-table ">
              <CustomizationTable
                customRows={[
                  {
                    label: "Value",
                    path: "name",
                  },
                  {
                    label: "Type",
                    path: "descirption",

                  },
                  {
                    label: "Locale",
                    path: "descirption",

                  },
                  {
                    label: "Last Updated",
                    path: "date",

                  },
                ]}
                customizationData={[
                  {
                    name: "Product Category",
                    descirption: "Index Category/Product Association",
                    date: "12/12/2019",
                    id: "1",
                    indexStaus: false,
                  },
                  {
                    name: "Product Skus",
                    descirption: "Index Category/Product Association",
                    date: "12/12/2019",
                    id: "095809",
                    indexStaus: true,

                  },
                  {
                    name: "Product Price",
                    descirption: "Index Category/Product Association",
                    date: "12/12/2019",
                    id: "095809",
                    indexStaus: false,

                  },
                ]}
                // isStatusEnable
                isDeleteEnable
              isEditEnable
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
SearchFacetManagement.propTypes = {
  rowsLoading: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleIndexing: PropTypes.func.isRequired,
};


export default SearchFacetManagement;
