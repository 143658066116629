/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import JobStepExAuditDetails from "../components/JobStepExAuditDetails";
import { getJobStepExAuditByJobId } from "../../../api/jobConfigurationService";

const JobStepExAuditDetailsContainer = ({
  onHandleBackStep,
  rowDataStep,
  formatDate,
}) => {
  const [jobStepExDetailsData, setJobStepExDetailsData] = useState([]);
  useEffect(() => {
    getJobStepExAuditByJobId(rowDataStep.jobId, rowDataStep.id, "10").then(
      async (response) => {
        if (response && response.data) {
          const { data } = response;
          setJobStepExDetailsData(data);
        }
      }
    );
  }, []);

  return (
    <JobStepExAuditDetails
      jobStepExDetailsData={jobStepExDetailsData}
      onHandleBackStepEx={onHandleBackStep}
      rowDataStep={rowDataStep}
      formatDate={formatDate}
      canModify
    />
  );
};

export default JobStepExAuditDetailsContainer;
