/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import CategorySelection from "../components/CategorySelection";

/** ===== API SERVICE FUNCTIONS =========== */
import { listOrphanCategories } from "../../../api/categoryManagementServices";


const CategorySelectionContainer = (props) => {
  const {
    onChange, name, value, exclude,
  } = props;
  /** local states */
  const [categories, setCategories] = useState([]);

  /**
   * This method is used to trigger onChange
   * @param {Event} event
   */
  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };

  /**
 * This method is used to handle typeahead change
 * @param {Event} event
 */
  const handleTypeaheadChange = (queryText) => {
    if (!queryText || (queryText && `${queryText}`.trim().length < 3)) {
      setCategories([]);
      return;
    }
    listOrphanCategories(queryText).then((response) => {
      const excludeIn = Array.isArray(exclude) ? exclude : [];
      const filteredCategoryList = response.data.filter((u) => u && !excludeIn.includes(u.id));
      if (response && response.success && Array.isArray(response.data)) { setCategories(filteredCategoryList); }
    });
  };

  return (
    <CategorySelection categories={categories} selectedCategories={value || []} onChange={localOnChange} name={name} handleTypeaheadChange={handleTypeaheadChange} />
  );
};


CategorySelectionContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  exclude: PropTypes.arrayOf(PropTypes.any),
};

CategorySelectionContainer.defaultProps = {
  name: "category",
  exclude: [],
};

export default CategorySelectionContainer;
