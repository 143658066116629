/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * page configuration services
 *
 * @author Dipin *
 */

import httpApi from "./httpApi";

/**
 * This method is used to get site preview configurations
 *
 * @returns
 */
const getSitePreviewConfigurations = async () => {
  const response = await httpApi.get("/v1/replication/settings/site-preview");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getRunningJobs = async () => {
  const response = await httpApi.get("/v1/batch-scheduler");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const replicatePrice = async () => {
  const response = await httpApi.get("/v1/replication/price");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const replicateCatalogue = async () => {
  const response = await httpApi.get("/v1/replication/catalogue");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const replicateContent = async () => {
  const response = await httpApi.get("/v1/replication/content");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const replicatePromotion = async () => {
  const response = await httpApi.get("/v1/replication/promotion");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const replicateSiteNavigation = async () => {
  const response = await httpApi.get("/v1/replication/site-navigation");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const bulkIndexingLive = async () => {
  const response = await httpApi.get("/v1/replication/search-index/all");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const generateSitemapLive = async () => {
  const response = await httpApi.post("/v1/replication/sitemap");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const replicateStoreLocations = async () => {
  const response = await httpApi.get("/v1/replication/store-locations");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const replicateGlobalSettings = async () => {
  const response = await httpApi.get("/v1/replication/global-settings");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getCatalogueReplicationStatus = async () => {
  const response = await httpApi.get("/v1/replication/status/catalogue");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getPriceReplicationStatus = async () => {
  const response = await httpApi.get("/v1/replication/status/price");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getContentReplicationStatus = async () => {
  const response = await httpApi.get("/v1/replication/status/content");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getPromotionReplicationStatus = async () => {
  const response = await httpApi.get("/v1/replication/status/promotion");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getSiteNavigationReplicationStatus = async () => {
  const response = await httpApi.get("/v1/replication/status/site-navigation");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getStoreLocationsReplicationStatus = async () => {
  const response = await httpApi.get("/v1/replication/status/store-locations");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

const getGlobalSettingsReplicationStatus = async () => {
  const response = await httpApi.get("/v1/replication/status/global-settings");
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  getSitePreviewConfigurations,
  getRunningJobs,
  replicatePrice,
  replicateCatalogue,
  replicateContent,
  replicatePromotion,
  replicateSiteNavigation,
  bulkIndexingLive,
  generateSitemapLive,
  replicateStoreLocations,
  replicateGlobalSettings,
  getCatalogueReplicationStatus,
  getPriceReplicationStatus,
  getContentReplicationStatus,
  getPromotionReplicationStatus,
  getSiteNavigationReplicationStatus,
  getStoreLocationsReplicationStatus,
  getGlobalSettingsReplicationStatus,
};
