/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi
 */

import React, { useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import ListGroup from "../../../common/core/ListGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";
import Table from "../../../common/core/Table";

/** ===== IMAGE ICONS =========== */
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

/** ======== MODULE STYLES ========== */
import styles from "../css/CustomerSelection.module.css";

const CustomerSelector = (props) => {
  const {
    userList, users, selectedUsers, setUserSelected,
    handleSearch, searchKeyword,
  } = props;
  const currentRow = useRef(null);
  const { t } = useTranslation(["customerGroup"]);
  return (
    <>
      <Row className={clsx(styles.block4, "pl-0")}>
        <Col xs="12" sm="6" lg="6" md="6" className={clsx(styles.rightDivider, "position-relative")}>
          {/*  ====== LIST AND SEARCH, AVAILABLE USERS S[0005]============ */}
          <div className="pt-2">
            <InputGroup className={styles.search}>
              <Input placeholder={t("customerGroup:placeHolder_search_customers")} value={searchKeyword} onChange={handleSearch} />
              <InputGroupAddon
                addonType="append"
                className="commonPointer"
              />
            </InputGroup>
            <ListGroup className={clsx("py-1","pl-1", styles.searchListContainer, "custom-scrollbar")} flush>
              {
                Array.isArray(users) && users.map((user) => (
                  <ListGroupItem key={user.id} className="px-2 py-1 border-0 d-flex ">
                    <div className="custom-control custom-checkbox text-truncate">
                      {/*
                    * maintaining separate state for selected users,
                    * */}
                      <input
                        type="checkbox"
                        id={`userRadio${user.id}`}
                        checked={selectedUsers.includes(user.id)}
                        name={user.id}
                        className="custom-control-input"
                        readOnly
                      />
                      <Label
                        onClick={(o) => setUserSelected(user.id, o)}
                        className="custom-control-label text-black commonPointer mw-100 "
                        htmlFor={`userRadio${user.id}`}
                        title={user.email}
                      >
                        <span className="text-truncate d-block mw-100">{user.email}</span>
                      </Label>
                    </div>
                  </ListGroupItem>
                ))
              }
            </ListGroup>
          </div>
          {/*  ====== LIST AND SEARCH, AVAILABLE USERS E[0005]============ */}
        </Col>
        <Col xs="12" sm="6" md="6" >
          {/*  ====== LISTING SELECTED USERS S[0006]============ */}
          <span className="pageTitleText, fontsizeMediumBold ">{t("customerGroup:title_customer_list")}</span>

          <div className="pt-2">
            <Row className={clsx("pb-0", styles.searchListContainer, styles.selectedListContainer)}>
              <div
                className={clsx(styles.customerBody, "table-responsive")}
              >
                <Table borderless>
                  <thead>
                    <tr>
                      <th>{t("customerGroup:table_placeHolder_name")}</th>
                      <th>{t("customerGroup:table_placeHolder_email")}</th>
                      <th>{t("customerGroup:table_placeHolder_delete")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Array.isArray(userList) && userList.filter((user) => selectedUsers.includes(user.id))
                        .map((user) => (
                          <tr key={user.id} ref={currentRow}>
                            <td>{user.firstName}</td>
                            <td>{user.email}</td>
                            <td>
                              <div className={styles.unselectUser}>
                                <span onClick={(o) => setUserSelected(user.id, o)} className="commonPointer" role="button" tabIndex={0} onKeyPress={() => { }} title={t("customerGroup:label_remove_customer")}>
                                  <img src={DeleteIcon} alt={t("customerGroup:label_remove_customer")} className={styles.icon} />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))
                    }
                  </tbody>
                </Table>
              </div>
            </Row>
          </div>
          {/*  ====== LISTING SELECTED USERS E[0006]============ */}
        </Col>
      </Row>
    </>
  );
};

CustomerSelector.propTypes = {
  // users
  userList: PropTypes.arrayOf(PropTypes.any).isRequired,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setUserSelected: PropTypes.func.isRequired,
  // search user
  searchKeyword: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
};


export default CustomerSelector;
