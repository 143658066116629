/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SiteTraficReport Container
 *
 * @author Naseef O
 *
 */

import React from "react";
import SiteTraficReport from "../components/SiteTraficReport";

const SiteTraficReportContainer = () => (<SiteTraficReport />);

export default SiteTraficReportContainer;
