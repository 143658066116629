/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getCustomers } from "../../../api/customerGroupServices";
import CustomerSelector from "../components/CustomerSelector";

const CustomerSelectionContainer = (props) => {

  /** local states */
  const { userIds, setUserIds } = props;
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [form, setForm] = useState({
    users: [],
  });

  /**
   * This method is used to get customer list from server
   * @param {String} searchTerm
   */
  const getCustomerListFromSever = (searchTerm = "") => {
    getCustomers(searchTerm).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          const listedUserId = userList.map(({ id }) => id);
          setUsers(data);
          setUserList([
            ...(data.filter((each) => each && !listedUserId.includes(each.id))),
            ...userList,
          ]);
        }
      }
    });
  };

  /**
    * this method display selected customers in edit page
    */
  useEffect(() => {
    if (Array.isArray(userIds) && userIds.length > 0) {
      const listedUserId = userIds;
      const filteredList = userIds.filter((each) => each && !listedUserId.includes(each.id));
      setUserList([
        ...userList,
        ...filteredList,
      ]);
      setSelectedUsers(userIds);
    }
  }, [userIds]);

  /**
   * This method is used to change search field and trigger search functionality
   * @param {Event} event
   */
  const handleSearch = (event) => {
    const { value } = event.target;
    if (value && `${value}`.trim().length > 1) { getCustomerListFromSever(value); } else { getCustomerListFromSever(); }
    setSearchKeyword(value);
  };

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to set select / deselect users
   * @param {String} userId
   */
  const setUserSelected = (userId) => {
    const index = selectedUsers.indexOf(userId);
    let newSelectedList = [];
    if (index < 0) {
      /** if item not exist adding in the list */
      newSelectedList = ([...selectedUsers, userId]);
    } else {
      /** if item exits remove form the list */
      newSelectedList = (selectedUsers.filter((o) => o !== userId));
    }
    setSelectedUsers(newSelectedList);
    handleChange(newSelectedList || []);
  };

  /** setting selected UserIds  */

  useEffect(() => {
    setUserIds(
      selectedUsers,
    );
  }, [selectedUsers]);

  useEffect(() => {
    getCustomerListFromSever();
  }, []);

  return (
    <CustomerSelector
      // users
      userList={userList}
      users={users}
      selectedUsers={selectedUsers}
      setUserSelected={setUserSelected}
      // user search
      searchKeyword={searchKeyword}
      handleSearch={handleSearch}
      handleChange={handleChange}
    />
  );
};
CustomerSelectionContainer.propTypes = {
  setUserIds: PropTypes.func.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default CustomerSelectionContainer;
