// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n .AddProduct_block2__2CjKz {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.AddProduct_borderRadius2__1Z33e {\n  border-radius: 0.125rem !important;\n}\n\n.AddProduct_secondaryColor__cHhez {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.AddProduct_primaryColor__BUVeP {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.AddProduct_btn__2uc9E {\n  min-width: 7rem;\n}\n\n\n/* ========== FORM AREA ============ */\n.AddProduct_block3__2rGGo {\n  padding: 1.25rem;\n}\n\n/* ============= PRODUCT TITLE ========= */\n.AddProduct_titleTextContainer__2YfJ8 {\n  padding: 0 2.1rem 0.125rem 2.1rem;\n  margin: 0 -0.938rem;\n}\n\n.AddProduct_titleTextContainer__2YfJ8 .AddProduct_pageTitleText__5Yqde{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n \n\n\n/** ========= PARENT CATEGORY  ================ **/\n.AddProduct_block5__1hLzu {\npadding: 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"block2": "AddProduct_block2__2CjKz",
	"borderRadius2": "AddProduct_borderRadius2__1Z33e",
	"secondaryColor": "AddProduct_secondaryColor__cHhez",
	"primaryColor": "AddProduct_primaryColor__BUVeP",
	"btn": "AddProduct_btn__2uc9E",
	"block3": "AddProduct_block3__2rGGo",
	"titleTextContainer": "AddProduct_titleTextContainer__2YfJ8",
	"pageTitleText": "AddProduct_pageTitleText__5Yqde",
	"block5": "AddProduct_block5__1hLzu"
};
module.exports = exports;
