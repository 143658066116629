/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * DashboardView Container
 *
 * @author Dipin
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DashboardView from "../components/DashboardView";
import { login } from "../../../api/UserServices";
import { getMainMenus } from "../../../api/navigationServices";


const Dashboard = () => {
  const history = useHistory();

  /** local states */
  const [mainMenus, setMainMenus] = useState([]);

  useEffect(() => {
    getMainMenus().then((response) => {
      if (response && response.success) {
        if (Array.isArray(response.data)) {
          setMainMenus(response.data);
        }
      }
    });
  }, []);

  /**
   * This method is used to change url
   * @param {String} menuId
   */
  const openMenu = (menuId) => {
    history.push(`/${menuId}`);
  };
  return (
    <>
      <DashboardView mainMenus={mainMenus} openMenu={openMenu} />
    </>
  );
};

export default Dashboard;
