// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n.ImageSelection_uploadImagePreview__3ig_e {\n    max-width: 150px;\n    margin-right: 1rem;\n    position: relative;\n  }\n.ImageSelection_uploadImagePreviewIcon__fFoPB {\n    max-width: 150px;\n}\n.ImageSelection_uploadImagePreviewClose__M1SCL{\n  display: none;\n}\n.ImageSelection_uploadImagePreview__3ig_e:hover .ImageSelection_uploadImagePreviewClose__M1SCL {\n  display: flex;\n  border-radius: 50%;\n  position: absolute;\n  top: -4px;\n  right: -4px;\n  background: #b3b3b3;\n  font-size: 11px;\n  padding: 0px 5px;\n  font-weight: 600;\n  cursor: pointer;\n}\n\n.ImageSelection_uploadImagePreview__3ig_e:last-child {\n      margin-right: 0;\n  }\n\n", ""]);
// Exports
exports.locals = {
	"uploadImagePreview": "ImageSelection_uploadImagePreview__3ig_e",
	"uploadImagePreviewIcon": "ImageSelection_uploadImagePreviewIcon__fFoPB",
	"uploadImagePreviewClose": "ImageSelection_uploadImagePreviewClose__M1SCL"
};
module.exports = exports;
