/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */


export const PageTypes = {
  GLOBAL: "GLOBAL",
  HOME: "HOME",
  PLP: "PLP",
  PDP: "PDP",
  CLP: "CLP",
  BLP: "BLP",
  MYACCOUNT: "MYACCOUNT",
  WISHLIST: "WISHLIST",
  DELIVERY_DETAILS: "DELIVERY_DETAILS",
  CART: "CART",
  PAYMENT: "PAYMENT",
  ORDER_CONFIRMATION: "ORDER_CONFIRMATION",
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  PASSWORD_RESET: "PASSWORD_RESET",
  TRACK_YOUR_ORDER: "TRACK_YOUR_ORDER",
  NOTFOUND: "NOTFOUND",
};

/**
 * This method is used to get aspect ratio
 *
 * @param {*} ratioObject
 * @param {*} widgetType
 * @returns
 */
export const getAspectRatio = (ratioObject, mediaType) => {
  const ratio = ratioObject[mediaType];
  return ratio;
};
