/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import Alert from "../../../common/core/Alert";

/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */
import BadgeForm from "./BadgeForm";

/** ========= MODULE STYLES ========= */
import styles from "../css/AddBadge.module.css";
import validateForm from "../../../common/utils/validateForm";

const AddBadge = (props) => {
  const {
    badge_id,
    badge_type,
    primary_text,
    secondary_text,
    fontColor,
    formSubmit,
    formCancel,
    onSubmitting,
    backgroundColor,
    handleFormChange,
    handleChange,
    message,
    productDetails,
    excludedProducts,
    products
  } = props;

  /** internationalization using i18n from common and coupon  */
  const { t } = useTranslation(["common", "badge"]);
  const validator = validateForm();
  const { handleSubmit } = validator;

  return (
    <>
      <Row>
        <Col xs="12">
          <Form onSubmit={handleSubmit(formSubmit)}>
            {message && message.type && (
              <Alert color={message.type}>{message.message}</Alert>
            )}
            <fieldset disabled={onSubmitting}>
              {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
              {/* <Form onSubmit={formSubmit}>
            <fieldset disabled={onSubmitting}> */}
              <Row className="pageHeader common-page-card">
                <Col xs="12" md="7" className="text-truncate">
                  <span className="pageText">{t("badge:title_add")}</span>
                </Col>
                <Col xs="12" md="5" className="d-flex justify-content-end">
                  <Button
                    onClick={formCancel}
                    type="button"
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.secondaryColor,
                      "text-truncate",
                      "mr-1"
                    )}
                  >
                    {t("common:button_cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.primaryColor,
                      "text-truncate",
                      "mr-1",
                      "button-font-size"
                    )}
                  >
                    {t("common:button_save")}
                  </Button>
                </Col>
              </Row>

              <Row
                className={clsx(
                  styles.blockWrapper,
                  " common-page-card",
                  "pb-0"
                )}
              >
                <Col xs="12" lg="10">
                  <div className="common-page-card">
                    {/*  ====== MENU FORM S[0005]============ */}
                    <BadgeForm
                      badge_id={badge_id}
                      badge_type={badge_type}
                      primary_text={primary_text}
                      secondary_text={secondary_text}
                      backgroundColor={backgroundColor}
                      fontColor={fontColor}
                      /** form methods */
                      handleFormChange={handleFormChange}
                      handleChange={handleChange}
                      validator={validator}
                      productDetails={productDetails}
                      excludedProducts={excludedProducts}
                      products={products}
                    />
                  </div>
                </Col>
              </Row>

              {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  );
};
AddBadge.propTypes = {
  badge_id: PropTypes.string.isRequired,
  badge_type: PropTypes.string.isRequired,
  primary_text: PropTypes.string.isRequired,
  secondary_text: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  // form actions
  formSubmit: PropTypes.func.isRequired,
  formCancel: PropTypes.func.isRequired,
  // form methods
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default AddBadge;