/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import FormGroup from "../../../common/core/FormGroup";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import DateRangePicker from "../../../common/components/DateRangePicker";
import CustomInput from "../../../common/core/CustomInput";

/** ========= SUB COMPONENT ========= */


/** ========= MODULE STYLES ========= */
import styles from "../css/CouponForm.module.css";

const CouponForm = (props) => {
  const {
    code, description, startDate, endDate, active,
    handleFormChange, handleChange, validator, indefinite, onCampaign, translatableFileds, selectedLanguage, caseSensitive,
  } = props;

  /** internationalization using i18n from common and coupon  */
  const { t } = useTranslation(["common", "coupon"]);
  const { register, errors } = validator;

  return (
    <>
      <Row>
        <Col xs="12">
          <FormGroup>
            <Label className="formTextLabel">{t("coupon:label_code")}</Label>
            <span className="pl-1 text-danger">*</span>
            <Input
              className="formText"
              placeholder={t("coupon:placeholder_code")}
              name="code"
              value={code}
              onChange={handleFormChange}
              innerRef={
                register({
                  required: t("coupon:validation_coupon_code"),
                  minLength: {
                    value: 3,
                    message: t("coupon:validation_coupon_code"),
                  },
                })
              }
              invalid={!!(errors.code && errors.code.message)}
              disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("code") : false}
            />
            <CustomInput
              type="checkbox"
              name="caseSensitive"
              checked={caseSensitive}
              onClick={() => handleChange("caseSensitive", !caseSensitive)}
              id="caseSensitive"
              className="font-weight-light small d-flex float-right"
              label="case sensitive"
            />
            <FormFeedback>{errors.code && errors.code.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <Label className="formTextLabel">{t("common:label_description")}</Label>
            <Input
              className="formText"
              placeholder={t("coupon:placeholder_description")}
              name="description"
              value={description}
              onChange={handleFormChange}
              disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("description") : false}
            />
          </FormGroup>
        </Col>
        <Col xs="12" sm={onCampaign ? "12" : "8"}>
          <FormGroup className="mb-0">
            <DateRangePicker
              id="couponDatePicker"
              labelClass="formTextLabel"
              startDateProps={{
                label: "Start date",
                noStartDate: false,
                value: startDate,
                name: "startDate",
                disabled: Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("startDate") : false,
              }}
              endDateProps={{
                label: "End date",
                noEndDate: true,
                value: endDate,
                name: "endDate",
                disabled: Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("endDate") : false,
              }}
              indefinite={indefinite}
              onChange={handleFormChange}
            />
          </FormGroup>
        </Col>
        <Col xs="6" sm="5" lg="3">
          <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
            <Label className="formTextLabel">{t("common:label_status")}</Label>
            <div
              className={clsx(
                "custom-control",
                "custom-switch",
                "ml-3",
              )}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="enable-coupon"
                name="active"
                checked={active}
                onChange={() => handleChange("active", !active)}
                disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("active") : false}
              />
              <Label className="custom-control-label" htmlFor="enable-coupon" />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
CouponForm.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.bool.isRequired,
  indefinite: PropTypes.bool.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  caseSensitive: PropTypes.bool.isRequired,
  /** form methods */
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default CouponForm;
