// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header_headerWrapper__3ty7P{\n    background: white;\n    position: fixed;\n    height: 75px;\n    display: inline-grid;\n    z-index: 100;\n    left: 32px;\n    right: 32px;\n    top: 0px;\n    padding: 10px 0px;\n    border-bottom: 1px solid #f2f2f2\n}\n.Header_menuWrap__2XouH{\n    display: block;\n    width: 100%;\n}\n.Header_menuWrap2__3-Mpd{\n    display: inline-block;\n    width: auto;\n    height: 32px;\n    padding-left: 125px;\n    position: absolute;\n    top: 2px;\n}\n\n.Header_logo__VQCZ1{\n    padding: 0px;\n    float: left;\n    width: 136px;\n    margin-top: -30px;\n}\n.Header_logout__wKRvF{\n    float: right;\n    display: inline-block;\n    font-weight: bold;\n    cursor: pointer;\n    padding-left: 20px;\n}\n.Header_avatar__1BmqD{\n    display: inline-block;\n    float: left;\n    width: 42px;\n    height: 42px;\n    border-radius: 100%;\n    background-color: grey;\n    padding: 1px;\n    margin-top: -4px;\n}\n.Header_avatar__1BmqD img{\n    max-width: 100%;\n    max-height: 100%;\n}\n.Header_logout__wKRvF img{\n    margin-left: 8px;\n    width: 14px;\n    height: 14px;\n}\n.Header_languageSwitcher__2cQEG  button{\n    background-color: #ffff !important;\n    color: black !important;\n    border: none;\n    box-shadow: none !important;\n    padding-left: 0.3125rem !important;\n\n}\n.Header_languageSwitcher__2cQEG  button:hover, .Header_languageSwitcher__2cQEG  button:active{\n    background-color: #ffff ;\n    color: black;\n    border: none\n}\n\n.Header_selectedLanguage__2KACH{\n    padding-left: .625rem;\n    padding-top: 0.5rem;\n}\n/*  notification icon style fix */\n.Header_notification__1Ajo4 {\n    float: right;\n    margin-left: 15px;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"headerWrapper": "Header_headerWrapper__3ty7P",
	"menuWrap": "Header_menuWrap__2XouH",
	"menuWrap2": "Header_menuWrap2__3-Mpd",
	"logo": "Header_logo__VQCZ1",
	"logout": "Header_logout__wKRvF",
	"avatar": "Header_avatar__1BmqD",
	"languageSwitcher": "Header_languageSwitcher__2cQEG",
	"selectedLanguage": "Header_selectedLanguage__2KACH",
	"notification": "Header_notification__1Ajo4"
};
module.exports = exports;
