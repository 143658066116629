/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 *
 */

import httpApi from "./httpApi";

/**
 * This method is used to get  translated inputs
 *
 * @param {*} page
 * @param {*} language
 * @returns
 */
const getTranslatedInputsByPage = async (entityName) => {
  const response = await httpApi.get(`/v1/translations/admin/entity/${entityName}/properties`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

const getTranslationConfig = async () => {
  const response = await httpApi.get("/v1/translations/admin/config");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getTranslatedInputsByPage,
  getTranslationConfig,
};
