// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Aardra S\n */\n\n.CustomURLManagement_borderRadius2__3oFA4 {\n  border-radius: 0.125rem;\n}\n\n.CustomURLManagement_secondaryColor__I_Wob {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.CustomURLManagement_primaryColor__1ey_h {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.CustomURLManagement_btn1__1lEBB {\n  min-width: 6rem;\n}\n\n.CustomURLManagement_btn2__1fYlt {\n  min-width: 9rem;\n}\n\n.CustomURLManagement_btnRule___I0by{\n   background-color: white !important;\n  color:black!important;\n  border-color: #b4bfca !important;\n  min-width: 6rem;\n}\n\n.CustomURLManagement_btnAdd__1hhCd{\n  background-color:#dadada !important; \n  color:black!important;\n  border-color: #808284!important;\n  min-width: 7rem;\n}\n\n.CustomURLManagement_badge__2k895{\n  background-color:#dadada !important;  \n  min-width: 7rem;\n  min-height: 2.25rem;\n  border-color: #808284!important;\n}\n\n.CustomURLManagement_badgeTag__6iFpD{\n  font-size: 14px;\n}\n\n.CustomURLManagement_quesIcon__2d6jR{\n  background-color:#ced4da !important;  \n  min-width: 2.25rem;\n  border-color: #808284!important;\n}\n\n.CustomURLManagement_tabLink__3SGY1{\n  cursor: pointer;\n}\n\n", ""]);
// Exports
exports.locals = {
	"borderRadius2": "CustomURLManagement_borderRadius2__3oFA4",
	"secondaryColor": "CustomURLManagement_secondaryColor__I_Wob",
	"primaryColor": "CustomURLManagement_primaryColor__1ey_h",
	"btn1": "CustomURLManagement_btn1__1lEBB",
	"btn2": "CustomURLManagement_btn2__1fYlt",
	"btnRule": "CustomURLManagement_btnRule___I0by",
	"btnAdd": "CustomURLManagement_btnAdd__1hhCd",
	"badge": "CustomURLManagement_badge__2k895",
	"badgeTag": "CustomURLManagement_badgeTag__6iFpD",
	"quesIcon": "CustomURLManagement_quesIcon__2d6jR",
	"tabLink": "CustomURLManagement_tabLink__3SGY1"
};
module.exports = exports;
