/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import MegaMenuItemCreator from "../components/MegaMenuItemCreator";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */

const MegaMenuItemCreatorContainer = (props) => {
  const {
    level, showModal, column, menuItems, selectedMenu, parentMenuId, parentId, menuTypes, edit, isWebResponsiveHeader, isWebFooter,
    onSave, onCancel, getLocalKey,
  } = props;
  const navigationItemTypes = constants.NAVIGATION_ITEM_TYPES;

  /** local states */
  const [form, setForm] = useState({
    id: `mega-menu-${getLocalKey()}`,
    menu: selectedMenu || [],
    parentMenu: parentMenuId,
    parentId,
    column,
    name: "",
    navigable: false,
    url: "",
    type: "",
    limit: "",
    menus: null,
  });
  const [open, setOpen] = useState(showModal);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [columns, setColumns] = useState([]);
  const [parentMenus, setParentMenus] = useState([]);

  useEffect(() => {
    if (edit) {
      setForm((oldForm) => ({
        ...oldForm,
        ...edit,
      }));
    }
  }, [edit]);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    if (Array.isArray(menuItems)) {
      setParentMenus(menuItems);
      if (parentMenuId) {
        const selectedParentMenu = menuItems.filter((each) => each.id === parentMenuId)[0];
        if (selectedParentMenu && Array.isArray(selectedParentMenu.menus)) {
          setColumns(selectedParentMenu.menus);
        }
      }
    } else {
      setParentMenus([]);
    }
  }, [menuItems, parentMenuId, column]);

  useEffect(() => {
    setForm((oldState) => ({
      ...oldState,
      menu: selectedMenu,
      parentMenu: parentMenuId,
      parentId,
    }));
  }, [selectedMenu, parentId, parentMenuId]);

  useEffect(() => {
    if (column > -1) {
      setForm((oldState) => ({
        ...oldState,
        column,
      }));
    }
  }, [column]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used to set sate to default
   */
  const setToDefault = () => {
    setForm({
      id: `mega-menu-${getLocalKey()}`,
      menu: selectedMenu,
      parentMenu: parentMenuId,
      parentId,
      column,
      name: "",
      url: "",
      type: "",
      limit: "",
      menus: null,
    });
  };

  /**
   * This method is used to send data to server
   */
  const addNewMegaMenuItemServerCall = async () => {
    const menus = Array.isArray(form.menus) ? form.menus.map((eachMenu) => {
      const catalogItemId = eachMenu.dataId || eachMenu.id;
      const url = eachMenu.url ? eachMenu.url : null;
      const link = url ? {
        type: eachMenu.type,
        name: eachMenu.name,
        url,
      } : null;
      return {
        ...eachMenu,
        dataId: catalogItemId,
        id: `menu_L9_id_${getLocalKey()}`,
        link,
        navigable: Boolean(link),
        type: form.type,
      };
    }) : null;
    const dataToServer = {
      ...form,
      menus,
      parentId: (Array.isArray(columns)
      && columns[form.column]
      && columns[form.column].id)
      || form.parentId,
    };
    setOnSubmitting(true);
    onSave(dataToServer, level);
    setOpen(false);
    setToDefault();
    setOnSubmitting(false);
  };

  /**
   * This method is used to submit the form
   */
  const formSubmit = () => {
    addNewMegaMenuItemServerCall();
  };

  /**
   * This method is used to hide/ cancel the modal / form
   */
  const formCancel = (clear = false) => {
    if (onCancel) {
      onCancel();
    }
    setOpen(false);
    if (clear) setToDefault();
  };

  const handleMenuChange = (event) => {
    if (event && event.target) {
      const { value } = event.target;
      if (value && Array.isArray(parentMenus)) {
        const selectedParentMenu = parentMenus.filter((each) => each.id === value)[0];
        handleChange(event.target.name, event.target.value);
        if (selectedParentMenu && Array.isArray(selectedParentMenu.menus)) {
          setColumns(selectedParentMenu.menus);
          handleChange("column", -1);
        }
      }
    }
  };

  /**
   * This method is used to remove selected Item
   * @param {String} dataId
   */
  const removeSelectedItem = (dataId) => {
    if (dataId) {
      const localMenus = form.menus.filter((each) => each.id !== dataId);
      setForm({
        ...form,
        menus: localMenus,
      });
    }
  };
  return (
    <MegaMenuItemCreator
      open={open}
      column={form.column}
      name={form.name}
      url={form.url}
      type={form.type}
      limit={form.limit}
      parentMenu={form.parentMenu}
      navigable={form.navigable}
      menus={form.menus}
      columns={columns}
      parentMenus={parentMenus}
      onSubmitting={onSubmitting}
      menuTypes={menuTypes}
      onEdit={Boolean(edit)}
      isWebFooter={isWebFooter}
      isWebResponsiveHeader={isWebResponsiveHeader}
      /** form methods */
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      formCancel={formCancel}
      formSubmit={formSubmit}
      removeItem={removeSelectedItem}
      handleMenuChange={handleMenuChange}
    />

  );
};

MegaMenuItemCreatorContainer.defaultProps = {
  showModal: false,
  level: 1,
  onCancel: null,
  column: null,
  menuItems: [],
  selectedMenu: null,
  parentMenuId: null,
  parentId: null,
  edit: null,
};

MegaMenuItemCreatorContainer.propTypes = {
  showModal: PropTypes.bool,
  level: PropTypes.number,
  column: PropTypes.number,
  menuItems: PropTypes.arrayOf(PropTypes.any),
  selectedMenu: PropTypes.string,
  parentMenuId: PropTypes.string,
  parentId: PropTypes.string,
  menuTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  edit: PropTypes.objectOf(PropTypes.any),
  isWebResponsiveHeader: PropTypes.bool.isRequired,
  isWebFooter: PropTypes.bool.isRequired,
  /** methods */
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  getLocalKey: PropTypes.func.isRequired,
};

export default MegaMenuItemCreatorContainer;
