/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *

 * @author Roshna Accu
 *
 */


import React from "react";
import PropTypes from "prop-types";
// import PromotionCreatorView from "../components/PromotionCreatorView";
import Promotion from "../../promotionsManagement/containers/PromotionCreator";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= UTILS ========= */

const PromotionCreator = (props) => {
  const { onSave, onCancel, setPromotionFlag, open, activeTab} = props;
  return (<Promotion onSave={onSave} onCancel={onCancel} setPromotionFlag={setPromotionFlag} open={open} activeTab={activeTab} />);
};
PromotionCreator.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setPromotionFlag: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};
export default PromotionCreator;
