/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import TaxLocationForm from "../components/TaxLocationForm";
import {createTaxLocation, getTaxLocationById, updateTaxLocation} from "../../../api/taxConfigurationService";

const TaxLocationFormContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [id, setId] = useState(null);
  const [form, setForm] = useState({
    name: "",
    country: "",
    location: "",
    description: "",
    retailer:"5e411b264d50307e272ea9ed",
    brand:"5e411c234d50307e272eaa05"
  });

  useEffect(() => {
    if(params.id) {
      getTaxLocationById(params.id).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
           setForm(data);
        }
      });
    }
    setId(params.id);
  }, [params.id]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const onHandleCancel = () => {
    history.push("/administration/tax-location");
  };

  const onHandleSave = async () => {
    if(id){
     await updateTaxLocation(form);
    } else {
     await createTaxLocation(form);
    }
    history.push("/administration/tax-location");
  };
  return (
    <TaxLocationForm
      onHandleCancel={onHandleCancel}
      handleFormChange={handleFormChange}
      form={form}
      onHandleSave={onHandleSave}
    />
  );
};

export default TaxLocationFormContainer;
