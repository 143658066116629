/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";

/** ========= SVG IMAGE ICON ========= */
import Administration from "../assets/images/dashboard/administration.svg";
import Marketing from "../assets/images/dashboard/marketing.svg";
import Merchandising from "../assets/images/dashboard/merchandising.svg";
import Operations from "../assets/images/dashboard/operations.svg";
import Reports from "../assets/images/dashboard/reports.svg";
import Documentation from "../assets/images/dashboard/documentation.svg";

export default {
  administrationIcon: Administration,
  merchandisingIcon: Merchandising,
  operationsIcon: Operations,
  reportsIcon: Reports,
  marketingIcon: Marketing,
  documentationIcon: Documentation,
};
