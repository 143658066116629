/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Akshay raj
 */

import React, { useState, useEffect } from "react";
import SearchSettingsManagement from "../components/SearchSettingsManagement";
import { getSearchSettings, updateSearchSettings } from "../../../api/searchSettingServices";
import LocaleDropDownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";
import { getTranslationConfig } from "../../../api/translationServices";


const SearchSettingsManagementContainer = () => {
  const [siteSettings, setSiteSettings] = useState([]);
  const [locale, setLocale] = useState([]);


  const handleIndexing = (locale) => {
    const searchConfigurationView = { code: "SiteSettings", locale };
    getSearchSettings(searchConfigurationView).then((response) => {
      const customization = [];
      if (response
        && response.data
        && response.data.defaultConfiguration
        && Array.isArray(response.data.defaultConfiguration)) {
        response.data.defaultConfiguration.forEach((siteSetting) => {
          const { fieldType } = siteSetting;
          const { field } = siteSetting;
          const { active } = siteSetting;
          customization.push({ fieldType, field, active });
        });
      }
      setSiteSettings(customization);
    });
  };

  const updateSettings = ((active, field) => {
    let fieldType = "";
    let indexFlag;
    const localSite = siteSettings;
    localSite.forEach((siteSetting, index) => {
      if (siteSetting.field === field) {
        fieldType = siteSetting.fieldType;
        indexFlag = index;
      }
    });
    localSite[indexFlag] = { field, fieldType, active };
    const updateData = { code: "SiteSettings", locale, defaultConfiguration: localSite };
    updateSearchSettings(updateData).then((response) => {
      const customization = [];
      if (response
        && response.data
        && response.data.defaultConfiguration
        && Array.isArray(response.data.defaultConfiguration)) {
        response.data.defaultConfiguration.forEach((siteSetting) => {
          customization.push({
            fieldType: siteSetting.fieldType,
            field: siteSetting.field,
            active: siteSetting.active,
          });
        });
      }
      setSiteSettings(customization);
    });
  });

  const localeOnChange = (localeLang, isDefault, translatableProperties) => {
    setLocale(localeLang);
    handleIndexing(localeLang);
  };

  useEffect(() => {
    getTranslationConfig().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        setLocale(data.defaultLocale);
        handleIndexing(data.defaultLocale);
      }
    });
  }, []);

  return (<SearchSettingsManagement updateSettings={updateSettings} siteSettings={siteSettings} LocaleDropDownContainer={LocaleDropDownContainer} localeOnChange={localeOnChange} />);
};

export default SearchSettingsManagementContainer;
