/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import XLSX from "xlsx";
import commonUtils from "./commonUtils";


const { isListNotEmpty } = commonUtils;

const parseDataFromSheet = (rawData) => {
  const headerKeys = ["email"];
  if (isListNotEmpty(rawData)) {
    const isValid = rawData.every(
      (data) => headerKeys.every((each) => Object.keys(data).includes(each)),
    );
    if (isValid) {
      const mappedValues = rawData.map((each) => each.email);
      return {
        isValid,
        options: rawData,
        value: mappedValues.filter((each, index) => mappedValues.indexOf(each) === index),
      };
    }
    return {
      isValid,
      message: "Invalid sheet format. Please refer the template for valid data format.",
    };
  }
  return {
    isValid: false,
    message: "No Data Found",

  };
};

const downloadXlsxDocument = (fileName, data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  ws["!cols"] = [
    { wch: 15 },
    { wch: 15 },
  ];
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  XLSX.writeFile(wb, fileName);
};

const downloadBulkImportTemplate = () => {
  const fileName = "customer_group_template.xlsx";
  const templateData = [
    { email: "<Email id 1>" },
    { email: "<Email id 2>" },
    { email: "<Email id 3>" },
    { email: "<Email id 4>" },
    { email: "<Email id 5>" },
  ];
  downloadXlsxDocument(fileName, templateData);
};

export default {
  parseDataFromSheet,
  downloadBulkImportTemplate,
  downloadXlsxDocument,
};
