/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author shafeeqe
 */
import axios from "axios";
import config from "../config";

const { authServerHost } = config;

const instance = axios.create({
  baseURL: authServerHost,
});

const login = async (loginData, apiUrl) => {
  const { hostname: serviceUrl } = new URL(apiUrl);
  const loginRequest = {
    ...loginData,
    serviceUrl,
    grantType: "Password",
    appId: "MozCom",
  };
  try{
    const response = await instance.post("/auth/v1/token", loginRequest);
    return response.data;
  }
  catch(error){
  }
};

export default { login };
