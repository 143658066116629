/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import SimpleProductSelector from "../components/SimpleProductSelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { getProducts } from "../../../api/productManagementServices";

const SimpleProductSelectorContainer = ({
  id,
  onChange,
  name,
  value,
  options,
  excluded,
  singleSelect,
  innerRef,
  invalid,
  validator,
  disabled,
}) => {
  /** local states */
  const [products, setProducts] = useState(options || []);
  const { clearError } = singleSelect && validator;
  useEffect(() => {
    if (!singleSelect) {
      const localOptions = (options && Array.isArray(options)) ? options : [];
      setProducts(localOptions);
    }
  }, [options]);
  /**
   * This method is used to trigger onChange
   * @param {Event} event
   */
  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
    if (singleSelect) {
      clearError([name]);
    }
  };

  /**
 * This method is used to handle typeahead change
 * @param {Event} event
 */
  const handleTypeaheadChange = (queryText) => {
    if (!queryText || (queryText && `${queryText}`.trim().length < 3)) {
      setProducts([]);
      return;
    }
    getProducts(queryText).then((response) => {
      if (response && response.success && response.data) {
        const { content } = response.data;
        if (Array.isArray(content)) {
          setProducts(content);
        }
      }
    });
  };
  return (
    <SimpleProductSelector
      id={id}
      products={products}
      selectedProducts={(!singleSelect && Array.isArray(value) ? value : [])}
      selectedProduct={singleSelect && typeof value === "string" ? value : ""} // for single select
      onChange={localOnChange}
      name={name}
      handleTypeaheadChange={handleTypeaheadChange}
      excluded={excluded}
      singleSelect={singleSelect}
      innerRef={innerRef}
      invalid={invalid}
      disabled={disabled}
    />
  );
};

SimpleProductSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]).isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  excluded: PropTypes.arrayOf(PropTypes.any),

  // sigle product select excclusive props
  singleSelect: PropTypes.bool,
  innerRef: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,

  // validation
  validator: PropTypes.shape({
    clearError: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

SimpleProductSelectorContainer.defaultProps = {
  name: "product",
  id: "product",
  options: undefined,
  excluded: [],
  singleSelect: false,
};

export default SimpleProductSelectorContainer;
