/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import XLSX from "xlsx";
import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import EditCustomerGroup from "../components/EditCustomerGroup";
/** ========= API SERVICE FUNCTIONS ========= */
import { getCustomerGroupById, updateCustomerGroup } from "../../../api/customerGroupServices";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";
import UserUtils from "../../../common/utils/UserUtils";


const EditCustomerGroupContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const positions = constants.NAVIGATION_MENU_POSITIONS;
  const { CUSTOMER_GROUP } = constants;

  if (!params.id) {
    history.push("/marketing/customergroups");
  }

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    description: "",
    type: "",
    criteria: "",
    matchType: "",
    pattern: "",
    orderAmount: "",
    noOfDays: "",
  });

  const [onSubmitting, setOnSubmitting] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  useEffect(() => {
    let localForm = form;

    getCustomerGroupById(params.id).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        localForm = {
          ...form,
          id: data.id,
          name: data.name || "",
          description: data.description || "",
          type: data.type || "",
          active: data.active,

        };
        if (data.criteria) {
          localForm.criteria = data.criteria.type;
          localForm.noOfDays = data.criteria.noOfDays || "";
          if (data.criteria.type === CUSTOMER_GROUP.BIG_SPENDER) {
            localForm.orderAmount = data.criteria.includeCriteria ? data.criteria.includeCriteria.orderAmount : "";
          }
          if (data.criteria.type === CUSTOMER_GROUP.EMPLOYEE) {
            if (data.criteria.includeCriteria) {
              localForm.pattern = data.criteria.includeCriteria.pattern || "";
              localForm.matchType = data.criteria.includeCriteria.exactMatch ? CUSTOMER_GROUP.EXACT_MATCH : CUSTOMER_GROUP.CONTAINS;
            }
            localForm.orderAmount = data.criteria.excludeCriteria ? data.criteria.excludeCriteria.orderAmount : "";
          }
        }
        setForm(localForm);
        setUserIds(data.users);
      }
    });
  }, [params.id]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    const localForm = { ...form };
    localForm[name] = value;
    if (name === "type") {
      localForm.criteria = "";
      localForm.matchType = "";
      localForm.pattern = "";
      localForm.orderAmount = "";
      localForm.noOfDays = "";
      setUserIds([]);
    }
    if (name === "criteria") {
      localForm.matchType = "";
      localForm.pattern = "";
      localForm.orderAmount = "";
      localForm.noOfDays = "";
      setUserIds([]);
    }
    if (name === "matchType") {
      localForm.pattern = "";
      setUserIds([]);
    }
    setForm(localForm);
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * Clear button trigger
   */
  const formCancel = () => {
    history.push("/marketing/customergroups");
  };

  /**
    * This method is used to submit the form for add new customer Group
    * @param {Event} event
    */
  const formSubmit = async (event) => {
    // event.preventDefault();
    const requestBody = {
      id: form.id,
      name: form.name,
      description: form.description,
      type: form.type,
      active: true,
      users: userIds,
    };

    if (form.type === "dynamic") {
      const criteria = {
        type: form.criteria,
        includeCriteria: {},
        excludeCriteria: {},
      };
      if (form.criteria === CUSTOMER_GROUP.BIG_SPENDER) {
        criteria.noOfDays = form.noOfDays;
        criteria.includeCriteria.orderAmount = form.orderAmount;
        criteria.includeCriteria.guestUser = false;
      }
      if (form.criteria === CUSTOMER_GROUP.EMPLOYEE) {
        criteria.noOfDays = form.noOfDays;
        criteria.includeCriteria.pattern = form.pattern;
        criteria.includeCriteria.guestUser = false;
        criteria.includeCriteria.exactMatch = form.matchType === CUSTOMER_GROUP.EXACT_MATCH;
        criteria.excludeCriteria.orderAmount = form.orderAmount;
      }
      requestBody.criteria = criteria;
    } else {
      requestBody.criteria = null;
    }
    /** clear the messages */
    setMessage({ type: null, message: "" });
    /** setting on submit true */
    setOnSubmitting(true);

    const response = await updateCustomerGroup(requestBody);
    if (response && response.success) {
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/marketing/customergroups");
        setMessage({ type: "Success", message: "Customer Group Details Updated Successfully" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };


  const uploadCustomers = (e) => {
    if (e && e.target && e.target.files) {
      const [file] = e.target.files;
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        window.scrollTo(0, 0);
        setMessage({ type: "warning", message: "Invalid file type. Please upload a XLSX/XLS file" });
      } else {
        const reader = new FileReader();
        reader.onload = (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNameList = workbook.SheetNames;
          const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
          const parsedData = UserUtils.parseDataFromSheet(rawData);
          if (parsedData.isValid) {
            const filterDuplicate = parsedData.value.filter((each) => !userIds.includes(each));
            const newUsers = [...userIds, ...filterDuplicate];
            setUserIds(newUsers);
          } else {
            window.scrollTo(0, 0);
            setMessage({ type: "warning", message: parsedData.message || "Something went wrong.Please try again" });
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };
  const downloadCustomers = (e) => {
    e.preventDefault();
    const data = userIds.map((each) => ({ email: each }));
    UserUtils.downloadXlsxDocument("customers.xlsx", data);
  };

  return (
    <EditCustomerGroup
      // form field
      name={form.name}
      description={form.description}
      type={form.type}
      userIds={userIds}
      criteria={form.criteria}
      matchType={form.matchType}
      pattern={form.pattern}
      noOfDays={form.noOfDays}
      orderAmount={form.orderAmount}
      /** form action */
      positions={positions}
      onSubmitting={onSubmitting}
      handleFormChange={handleFormChange}
      handleChange={handleChange}
      message={message}
      formSubmit={formSubmit}
      formCancel={formCancel}
      setUserIds={setUserIds}
      uploadCustomers={uploadCustomers}
      downloadCustomers={downloadCustomers}
    />
  );
};

export default EditCustomerGroupContainer;
