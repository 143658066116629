// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta\n * (\"Confidential Information\"). You shall not disclose such Confidential\n * Information and shall use it only in accordance with the terms of the\n * contract agreement you entered into with Mozanta.\n *\n * @author Hima-Mohan\n */\n\n.PageMetaTags_borderRadius2__2k-FE {\n  border-radius: 0.125rem;\n}\n\n.PageMetaTags_secondaryColor__frDhr {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.PageMetaTags_primaryColor__1lzI3 {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.PageMetaTags_btn1__1D8S8 {\n  min-width: 6rem;\n}\n\n.PageMetaTags_btn2__3h2DN {\n  min-width: 9rem;\n}\n\n.PageMetaTags_btnRule__2Ubty{\n   background-color: white !important;\n  color:black!important;\n  border-color: #b4bfca !important;\n  min-width: 6rem;\n}\n\n.PageMetaTags_btnAdd__1BpMM{\n  background-color:#dadada !important; \n  color:black!important;\n  border-color: #808284!important;\n  min-width: 7rem;\n}\n\n.PageMetaTags_badge__2dzhq{\n  background-color:#dadada !important;  \n  min-width: 7rem;\n  min-height: 2.25rem;\n  border-color: #808284!important;\n}\n\n.PageMetaTags_badgeTag__gys3c{\n  font-size: 14px;\n}\n\n.PageMetaTags_quesIcon__3lOhw{\n  background-color:#ced4da !important;  \n  min-width: 2.25rem;\n  border-color: #808284!important;\n}\n\n.PageMetaTags_tabLink__b19TJ{\n  cursor: pointer;\n}\n\n", ""]);
// Exports
exports.locals = {
	"borderRadius2": "PageMetaTags_borderRadius2__2k-FE",
	"secondaryColor": "PageMetaTags_secondaryColor__frDhr",
	"primaryColor": "PageMetaTags_primaryColor__1lzI3",
	"btn1": "PageMetaTags_btn1__1D8S8",
	"btn2": "PageMetaTags_btn2__3h2DN",
	"btnRule": "PageMetaTags_btnRule__2Ubty",
	"btnAdd": "PageMetaTags_btnAdd__1BpMM",
	"badge": "PageMetaTags_badge__2dzhq",
	"badgeTag": "PageMetaTags_badgeTag__gys3c",
	"quesIcon": "PageMetaTags_quesIcon__3lOhw",
	"tabLink": "PageMetaTags_tabLink__b19TJ"
};
module.exports = exports;
