/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getChannelGroupsByLocation } from "../../../api/promotionServices";
import OrganizationSelector from "../components/OrganizationSelector";
import constants from "../../../common/utils/constants";


const OrganizationSelectorContainer = (props) => {
  const {
    name, value, onChange, options, type, disabled,
  } = props;
  const { ORGANIZATIONS } = constants;
  const [organizationList, setOrganiztionList] = useState(options || []);

  const fetchOrganization = (fetchFunction) => {
    fetchFunction().then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        const data = response.data.map((each) => ({ id: each.id, name: each.name }));
        setOrganiztionList(data);
      } else {
        setOrganiztionList([]);
      }
    });
  };
  useEffect(() => {
    if (type === ORGANIZATIONS.CHANNELGROUP) {
      fetchOrganization(getChannelGroupsByLocation);
    }
  }, []);

  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };
  return (
    <OrganizationSelector
      name={name}
      organizationList={organizationList}
      selectedOrganizations={value}
      onChange={localOnChange}
      disabled={disabled}
    />
  );
};

OrganizationSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export default OrganizationSelectorContainer;
