// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddUrl_block2__1FP_j{\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.AddUrl_borderRadius2__lXrVT {\n  border-radius: 0.125rem !important;\n}", ""]);
// Exports
exports.locals = {
	"block2": "AddUrl_block2__1FP_j",
	"borderRadius2": "AddUrl_borderRadius2__lXrVT"
};
module.exports = exports;
