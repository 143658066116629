/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import CustomMenuItemForm from "../containers/CustomMenuItemFormContainer";

/** ========= CUSTOM COMPONENTS ========= */
import CustomizationTable from "../../../common/components/Table";

/** ========= SVG IMAGE ICON ========= */
import AddIcon from "../../../common/assets/images/svg/add-24px.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

/** ========= MODULE STYLES ========= */
import styles from "../css/CustomMenuItemCreator.module.css";


const CustomMenuItemCreator = (props) => {
  const {
    name, promotions, newPromotion,
    handleChange, addNewPromotion, clearNewForm, deletePromotion,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  return (
    <div className={clsx(styles.form, "px-3 pt-3")}>
      {
        newPromotion
          ? (
            <CustomMenuItemForm
              name={name}
              /** form methods */
              onSave={(o) => handleChange(o)}
              onClear={clearNewForm}
            />
          )
          : (
            <div className="mb-3 w-100 border">
              <span
                onClick={addNewPromotion}
                onKeyDown={() => { }}
                tabIndex={0}
                role="button"
                className="commonPointer text-center p-2 d-flex justify-content-center"
              >
                <img src={AddIcon} alt="" className={styles.icon} />
                <span className="pl-1">
                  {t("site-navigation:label_add_items")}
                </span>
              </span>
            </div>
          )
      }
      {
        Array.isArray(promotions) && promotions.length > 0 && (
          <div className="pt-3">
            <div className="custom-table">
              <CustomizationTable
                customRows={[
                  {
                    label: t("site-navigation:label_sub_items"),
                    path: "name",
                  },
                  {
                    label: t("common:label_actions"),
                    path: "render",
                    render: (row) => (
                      <span
                        className="commonPointer"
                        onClick={() => deletePromotion(row.id)}
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => { }}
                      >
                        <img
                          className={styles.icon}
                          src={DeleteIcon}
                          alt="Delete"
                        />
                      </span>
                    ),
                  },
                ]}
                customizationData={promotions}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

CustomMenuItemCreator.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.any).isRequired,
  newPromotion: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  /** form methods */
  handleChange: PropTypes.func.isRequired,
  addNewPromotion: PropTypes.func.isRequired,
  clearNewForm: PropTypes.func.isRequired,
  deletePromotion: PropTypes.func.isRequired,
};

export default CustomMenuItemCreator;
