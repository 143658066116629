/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import styles from "../css/CustomerGroupForm.module.css";
import Select from "../../../common/components/Select";
import CustomerSelectionContainer from "../containers/CustomerSelectionContainer";
import FormFeedback from "../../../common/core/FormFeedback";
import constants from "../../../common/utils/constants";
import validationConstants from "../../../common/utils/validationConstants";
import downloadIconfrom from "../../../common/assets/images/svg/file-upload.svg";
import publishIcon from "../../../common/assets/images/svg/publish.svg";
import userUtils from "../../../common/utils/UserUtils";

const BigSpenderForm = (props) => {
  const {
    orderAmount,
    noOfDays,
    validator,
    handleFormChange,
    t,
  } = props;

  const { IS_NUMBER } = validationConstants;

  const { register, errors } = validator;

  return (
    <div className="p-3 my-1 border rounded">
      <Row>
        <Col xs="12">
          <h5>
            {t("customerGroup:label_include_criteria")}
          </h5>
        </Col>
        <Col xs="12" sm="5" md="4" lg="4">
          <FormGroup>
            <Label className="formTextLabel">
              {t("customerGroup:label_order_amount")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <Input
              className="formText"
              placeholder={t("customerGroup:placeholder_order_amount")}
              name="orderAmount"
              value={orderAmount}
              onChange={handleFormChange}
              innerRef={
                register({
                  required: t("customerGroup:validation_order_amount"),
                  pattern: {
                    value: IS_NUMBER,
                    message: t("common:validation_is_number"),
                  },
                })
              }
              invalid={!!(errors.orderAmount && errors.orderAmount.message)}
            />
            <FormFeedback>{errors.orderAmount && errors.orderAmount.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs="12" sm="1" className="d-flex justify-content-center align-items-center">
          <span> in</span>
        </Col>
        <Col xs="12" sm="5" md="4">
          <FormGroup>
            <Label className="formTextLabel">
              {t("customerGroup:label_no_of_days")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <Input
              className="formText"
              placeholder={t("customerGroup:placeholder_no_of_days")}
              name="noOfDays"
              value={noOfDays}
              onChange={handleFormChange}
              innerRef={
                register({
                  required: t("customerGroup:validation_no_of_days"),
                  pattern: {
                    value: IS_NUMBER,
                    message: t("common:validation_is_number"),
                  },
                })
              }
              invalid={!!(errors.noOfDays && errors.noOfDays.message)}
            />
            <FormFeedback>{errors.noOfDays && errors.noOfDays.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

BigSpenderForm.propTypes = {
  // form field
  noOfDays: PropTypes.string.isRequired,
  orderAmount: PropTypes.string.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

const EmployeeForm = (props) => {
  const {
    orderAmount,
    noOfDays,
    handleFormChange,
    validator,
    matchType,
    pattern,
    t,
  } = props;
  const { CUSTOMER_GROUP } = constants;
  const { IS_NUMBER } = validationConstants;
  const { register, errors } = validator;

  return (
    <Row>
      <Col xs="12" sm="5" md="4">
        <FormGroup>
          <Label className="formTextLabel">
            {t("customerGroup:label_username_match")}
            <span className="pl-1 text-danger">*</span>
          </Label>
          <Select
            className="formText"
            placeholder={t("customerGroup:placeholder_username_match")}
            name="matchType"
            onChange={handleFormChange}
            value={matchType}
            options={[
              {
                value: "", selected: true, label: "Select username match ", hidden: true,
              },
              { value: CUSTOMER_GROUP.EXACT_MATCH, label: "Exact Match" },
              { value: CUSTOMER_GROUP.CONTAINS, label: "Contains" },
            ]}
            innerRef={
              register({
                required: t("customerGroup:validation_user_match"),
              })
            }
            invalid={!!(errors.matchType && errors.matchType.message)}
          />
          <FormFeedback>{errors.matchType && errors.matchType.message}</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs="12" sm="5" md="4" lg="4">
        {matchType === CUSTOMER_GROUP.CONTAINS
          ? (
            <FormGroup>
              <Label className="formTextLabel">
                {t("customerGroup:label_pattern")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <Input
                className="formText"
                placeholder={t("customerGroup:placeholder_pattern")}
                name="pattern"
                value={pattern}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: t("customerGroup:validation_pattern"),
                  })
                }
                invalid={!!(errors.pattern && errors.pattern.message)}
              />
              <FormFeedback>{errors.pattern && errors.pattern.message}</FormFeedback>
            </FormGroup>
          )
          : ""}
      </Col>
      <div className="p-3 my-1 border rounded">
        <Row>
          <Col xs="12">
            <h5>Exclude criteria</h5>
          </Col>
          <Col xs="12" sm="5" md="4" lg="4">
            <FormGroup>
              <Label className="formTextLabel">
                {t("customerGroup:label_order_amount")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <Input
                className="formText"
                placeholder={t("customerGroup:placeholder_order_amount")}
                name="orderAmount"
                value={orderAmount}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: t("customerGroup:validation_order_amount"),
                    pattern: {
                      value: IS_NUMBER,
                      message: t("common:validation_is_number"),
                    },
                  })
                }
                invalid={!!(errors.orderAmount && errors.orderAmount.message)}
              />
              <FormFeedback>{errors.orderAmount && errors.orderAmount.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm="1" className="d-flex justify-content-center align-items-center">
            <span> in</span>
          </Col>
          <Col xs="12" sm="5" md="4">
            <FormGroup>
              <Label className="formTextLabel">
                {t("customerGroup:label_no_of_days")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <Input
                className="formText"
                placeholder={t("customerGroup:placeholder_no_of_days")}
                name="noOfDays"
                value={noOfDays}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: t("customerGroup:validation_no_of_days"),
                    pattern: {
                      value: IS_NUMBER,
                      message: t("common:validation_is_number"),
                    },
                  })
                }
                invalid={!!(errors.noOfDays && errors.noOfDays.message)}
              />
              <FormFeedback>{errors.noOfDays && errors.noOfDays.message}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Row>
  );
};

EmployeeForm.propTypes = {
  // form field
  noOfDays: PropTypes.string.isRequired,
  orderAmount: PropTypes.string.isRequired,
  matchType: PropTypes.string.isRequired,
  pattern: PropTypes.string.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};


const CustomerGroupForm = (props) => {
  const {
    name, description, type, userIds,
    handleFormChange, setUserIds, validator,
    criteria, matchType, pattern, noOfDays, orderAmount, isEdit,
    uploadCustomers, downloadCustomers,
  } = props;

  const { t } = useTranslation(["customerGroup"]);

  const { CUSTOMER_GROUP } = constants;
  /** validation methods */
  const { register, errors } = validator;

  const diableFields = isEdit && criteria === "System";
  return (
    <>
      <Row className={styles.block3}>
        <Col xs="12">
          <Row>
            <Col xs="12" sm="5" md="4" lg="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("customerGroup:label_name")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <Input
                  className="formText"
                  placeholder={t("customerGroup:placeHolder_name")}
                  name="name"
                  disabled={diableFields}
                  value={name}
                  onChange={handleFormChange}
                  innerRef={
                    register({
                      required: "Please enter a name",
                      minLength: {
                        value: 3,
                        message: "Please enter a name",
                      },
                    })
                  }
                  invalid={!!(errors.name && errors.name.message)}
                />
                <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" sm="8" md="6" lg="6">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("customerGroup:label_description")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <Input
                  className="formText"
                  placeholder={t("customerGroup:placeHolder_description")}
                  name="description"
                  value={description}
                  disabled={diableFields}
                  onChange={handleFormChange}
                  innerRef={
                    register({
                      required: "Please enter a valid description between 12-50 characters",
                      minLength: {
                        value: 12,
                        message: "Please enter a valid description between 12-50 characters",
                      },
                      maxLength: {
                        value: 50,
                        message: "Please enter a description less than 50 characters",
                      },
                    })
                  }
                  invalid={!!(errors.description && errors.description.message)}
                />
                <FormFeedback>{errors.description && errors.description.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("customerGroup:label_type")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <Select
                  className="formText"
                  placeholder={t("customerGroup:placeHolder_type")}
                  name="type"
                  disabled={isEdit}
                  onChange={handleFormChange}
                  value={type}
                  options={[
                    {
                      value: "", selected: true, label: "Select Customer Type ",
                    },
                    { value: "static", label: "Static" },
                    { value: "dynamic", label: "Dynamic" },
                  ]}
                  innerRef={
                    register({
                      required: "Please select a customer type",
                    })
                  }
                  invalid={!!(errors.type && errors.type.message)}
                />
                <FormFeedback>{errors.type && errors.type.message}</FormFeedback>
              </FormGroup>
            </Col>
            {type === "dynamic" && (isEdit ? (criteria) : true)
              ? (
                <Col xs="12" sm="5" md="4">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("customerGroup:label_criteria")}
                      <span className="pl-1 text-danger">*</span>
                    </Label>
                    <Select
                      className="formText"
                      placeholder={t("customerGroup:placeholder_criteria")}
                      name="criteria"
                      onChange={handleFormChange}
                      value={criteria}
                      disabled={isEdit}
                      options={[
                        {
                          value: "", selected: true, label: "Select Criteria", hidden: true,
                        },
                        { value: "System", label: "System", hidden: true },
                        { value: CUSTOMER_GROUP.BIG_SPENDER, label: "Big Spender" },
                        { value: CUSTOMER_GROUP.EMPLOYEE, label: "Employee" },
                      ]}
                      innerRef={
                        register({
                          required: "Please select a criteria",
                        })
                      }
                      invalid={!!(errors.criteria && errors.criteria.message)}
                    />
                    <FormFeedback>{errors.criteria && errors.criteria.message}</FormFeedback>
                  </FormGroup>
                </Col>
              )
              : ""}
          </Row>


          {criteria === CUSTOMER_GROUP.BIG_SPENDER
            ? (
              <BigSpenderForm
                orderAmount={orderAmount}
                noOfDays={noOfDays}
                validator={validator}
                handleFormChange={handleFormChange}
                t={t}
              />
            )
            : ""}

          {criteria === CUSTOMER_GROUP.EMPLOYEE
            ? (
              <EmployeeForm
                orderAmount={orderAmount}
                noOfDays={noOfDays}
                validator={validator}
                matchType={matchType}
                pattern={pattern}
                handleFormChange={handleFormChange}
                t={t}
              />
            )
            : ""}
          {type === "dynamic" && matchType === CUSTOMER_GROUP.EXACT_MATCH
            ? (
              <Row>
                <Col xs="12" className={styles.block3}>
                  <div className={styles.titleTextContainer}>
                    <span className="pageTitleText, fontsizeMediumBold ">
                      {t("customerGroup:label_customer")}
                    </span>
                    <span className="float-right" title="Upload Brands">
                      <label htmlFor="customerFile" className="btn btn-primary ">
                        <img
                          src={publishIcon}
                          alt="upload"
                        />
                        Upload Customer List
                        <input
                          id="customerFile"
                          name="brands"
                          type="file"
                          className="d-none"
                          accept={CUSTOMER_GROUP.FILE_TYPES_CUSTOMER_GROUP}
                          onChange={(e) => uploadCustomers(e)}
                        />
                      </label>
                    </span>
                    <span
                      className="float-right mr-3 btn btn-light border text-primary"
                      role="presentation"
                      onClick={() => userUtils.downloadBulkImportTemplate()}
                      title="Download Template"
                    >
                      <img
                        src={downloadIconfrom}
                        alt="download"
                      />
                      Download template
                    </span>
                  </div>

                  <span className="mt-2">
                    {Array.isArray(userIds) && userIds.length > 0
                      ? (

                        <>
                          <span>
                            {userIds.length}
                            {" "}
                            customers exist in this group.
                            <Button
                              color="link"
                              className="px-1"
                              onClick={(e) => downloadCustomers(e)}
                            >
                              Click here
                            </Button>
                            to view the customers
                          </span>
                        </>
                      ) : (
                        "No customers exist in this group"
                      )}

                  </span>
                </Col>
              </Row>
            )
            : ""}
          {type === "static"
            ? (
              <Row>
                <Col xs="12" className={styles.block3}>
                  <div className={styles.titleTextContainer}>
                    <span className="pageTitleText, fontsizeMediumBold ">
                      {t("customerGroup:label_customer")}
                    </span>
                  </div>
                </Col>
                <Col xs="12" sm="10" md="10">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("customerGroup:label_Select customers")}
                    </Label>
                    <CustomerSelectionContainer
                      userIds={userIds}
                      setUserIds={setUserIds}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )
            : ""}
        </Col>
      </Row>
    </>
  );
};

CustomerGroupForm.defaultProps = {
  isEdit: false,
};
CustomerGroupForm.propTypes = {
  // form field
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  criteria: PropTypes.string.isRequired,
  matchType: PropTypes.string.isRequired,
  pattern: PropTypes.string.isRequired,
  noOfDays: PropTypes.string.isRequired,
  orderAmount: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,

  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  setUserIds: PropTypes.func.isRequired,
  downloadCustomers: PropTypes.func.isRequired,
  uploadCustomers: PropTypes.func.isRequired,

  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,


};

export default CustomerGroupForm;
