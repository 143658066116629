/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 */

import React, { useEffect, useState } from "react";
import { getJobTask, getJobTaskTypes, getJobTaskTemplateDataByType } from "../../../api/jobConfigurationService";
import JobTaskView from "../components/JobTaskView";
import TextBox from "../common/TextBox";
import DatePicker from "../common/DatePicker";
import SingleSelect from "../common/SingleSelect";
import RadioButtonGroup from "../common/RadioButtonGroup";
import MultiSelectCheckBox from "../common/MultiSelectCheckBox";

const JobTaskViewContainer = () => {

  const [jobTaskData, setJobTaskData] = useState([]);

  useEffect(() => {
    getJobTask().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setJobTaskData(data);
      }
    });
  });

  const [isAddJobTaskModalOpen, setAddJobTaskModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [jobTaskTypes, setJobTaskTypes] = useState([]);
  const [jobTaskTemplateData, setjobTaskTemplateData] = useState([]);

  useEffect(() => {
    getJobTaskTypes().then(async (response) => {
      if (response && response.success && response.data) {
        const respoJobTaskTypes = response.data;
        setJobTaskTypes(respoJobTaskTypes);
      }
    });
  }, []);

  useEffect(() => {
    if (data.jobTaskType) {
      getJobTaskTemplateDataByType(data.jobTaskType).then(async (response) => {
        if (response && response.success && response.data) {
          const respoJobTaskTemplateData = response.data;
          setjobTaskTemplateData(respoJobTaskTemplateData);
        }
      });
    }
  }, [data.jobTaskType]);

  const handleJobTaskData = () => {
    setAddJobTaskModalOpen(false);
    const updatedJobTaskData = jobTaskData;
    updatedJobTaskData.push(data);
    setJobTaskData(updatedJobTaskData);
    setjobTaskTemplateData([]);
    setData({});
  };

  const handleChange = (name, value, inputType) => {
    let selectedData = value;
    if (inputType === "MULTI_SELECT_CHECKBOX") {
      let filteredCheckBoxData = data[name] || [];
      if (filteredCheckBoxData && filteredCheckBoxData.includes(value)) {
        filteredCheckBoxData = filteredCheckBoxData.filter((
          checkBoxValue,
        ) => checkBoxValue !== value);
      } else {
        filteredCheckBoxData.push(value);
      }
      selectedData = filteredCheckBoxData;
    }
    setData({
      ...data,
      [name]: selectedData,
    });
  };

  const renderFilter = (dataField) => {
    switch (dataField.inputType) {
      case "INPUT_FIELD":
        return (
          <TextBox
            inputData={dataField}
            stateData={data}
            handleChange={handleChange}
          />
        );
      case "DATE_FIELD":
        return (
          <DatePicker
            inputData={dataField}
            stateData={data}
            handleChange={handleChange}
          />
        );
      case "RADIO_BUTTON_GROUP":
        return (
          <RadioButtonGroup
            inputData={dataField}
            stateData={data}
            handleChange={handleChange}
          />
        );
      case "MULTI_SELECT_CHECKBOX":
        return (
          <MultiSelectCheckBox
            inputData={dataField}
            stateData={data}
            handleChange={handleChange}
            className="col-sm-6 mt-2"
          />
        );
      case "SINGLE_SELECT":
        return (
          <SingleSelect
            inputData={dataField}
            stateData={data}
            handleChange={handleChange}
            renderFilter={renderFilter}
          />
        );
      default:
        break;
    }
    return true;
  };

  return (
    <JobTaskView
      jobTaskData={jobTaskData}
      selectedJobTaskData={data}
      setSelectedJobTaskData={setData}
      handleChange={handleChange}
      renderFilter={renderFilter}
      isAddJobTaskModalOpen={isAddJobTaskModalOpen}
      setAddJobTaskModalOpen={setAddJobTaskModalOpen}
      handleJobTaskData={handleJobTaskData}
      setJobTaskData={setJobTaskData}
      jobTaskTypes={jobTaskTypes}
      jobTaskTemplateData={jobTaskTemplateData}
      setjobTaskTemplateData={setjobTaskTemplateData}
      canModify
    />
  );
};

export default JobTaskViewContainer;
