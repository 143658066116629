/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Vanity urls container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import VanityUrls from "../components/VanityUrls";
import {
  getVanityUrls, createVanityUrl, updateVanityUrl, deleteVanityUrlById,
} from "../../../api/SeoSettingsService";
import validateForm from "../../../common/utils/validateForm";

const VanityUrlsContainer = () => {
  const [vanityUrls, setVanityUrls] = useState(null);
  const [modal, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [form, setForm] = useState({ url: "", internalUrl: "" });
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);
  const methods = validateForm();
  const {
    handleSubmit, register, errors, setError, clearError,
  } = methods;

  useEffect(() => {
    getVanityUrls(0, 10, searchTerm.trim()).then((response) => {
      if (response && response.success && response.data) {
        setVanityUrls(response.data);
        setPage(0);
        if (Array.isArray(response.data) && response.data.length > 0) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      }
    });
  }, [searchTerm]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
  };

  const getAllVanityUrls = (pageNumber) => {
    const limit = 10;
    getVanityUrls(pageNumber, limit, searchTerm).then((response) => {
      if (response && response.success && response.data) {
        setVanityUrls(response.data);
        setPage(pageNumber);
        if (Array.isArray(response.data) && response.data.length > 0) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      }
    });
  };

  /**
 * This method is used to get handdle search custom pages
 *
 * @param {*} direction
 */
  const onHandleSearch = (direction) => {
    const newPage = page + direction;
    getAllVanityUrls(newPage);
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };


  const toggleModal = (data) => {
    clearError(["url"]);
    if (data) {
      setIsEdit(true);
      setForm({ ...form, ...data });
    } else {
      setIsEdit(false);
      setForm({ url: "", internalUrl: "" });
    }
    setModalOpen(!modal);
  };

  /**
 * This method is used to handle update and save custom page
 *
 * @param {*} value
 */
  const handleSave = (value) => {
    const requestData = {
      ...form,
    };
    if (value) {
      if (isEdit) {
        updateVanityUrl(requestData).then((response) => {
          if (response && response.success && response.data) {
            setModalOpen(false);
            getAllVanityUrls();
          } else {
            setError("url", "noMatch", "URL already exists");
          }
        });
      } else {
        createVanityUrl(requestData).then((response) => {
          if (response && response.success && response.data) {
            setModalOpen(false);
            getAllVanityUrls();
          } else {
            setError("url", "noMatch", "URL already exists");
          }
        });
      }
    }
  };

  const [isLoading, setLoading] = useState(false);
  const [selectedIndex, setIndex] = useState(null);
  const handleDelete = (id, index) => {
    setLoading(true);
    setIndex(index);
    deleteVanityUrlById(id).then((response) => {
      if (response && response.success) {
        getAllVanityUrls(0);
      }
      setLoading(false);
    });
  };

  return (
    <VanityUrls
      vanityUrls={vanityUrls}
      modal={modal}
      form={form}
      handleChange={handleChange}
      toggleModal={toggleModal}
      isEdit={isEdit}
      register={register}
      errors={errors}
      handleSave={handleSave}
      handleSubmit={handleSubmit}
      onHandleSearch={onHandleSearch}
      page={page}
      hasMoreData={hasMoreData}
      handleChangeSearch={handleChangeSearch}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
      handleDelete={handleDelete}
    />
  );
};

export default VanityUrlsContainer;
