import React, { PureComponent } from 'react';
import { NavbarText as RNavbarText } from 'reactstrap';

/** @class
 * This class is used as react-strap's NavbarText Compontent
 */
class NavbarText extends PureComponent {
  render() {
    return <RNavbarText {...this.props} />;
  }
}

export default NavbarText;
