/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * CustomerData Management Container
 *
 * @author Indrajith C
 *
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import { getCustomers, transformCustomerData, initiateResetPassword } from "../../../api/customerDataService";
import CustomerDataManagement from "../components/CustomerDataManagement";

const CustomerDataManagementContainer = () => {
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isProccessing, setIsProccessing] = useState(false);
  const [loading, setLoading] = useState([]);
  const paginationLimit = 10;
  /**
   * This method is used to list customer Groups
   * @param {String} searchTerm
   * @param {Number} newPage
   * @param {Number} paginationLimit
   * @param {String} sortBy
   * @param {String} sortDirection
   */

  const getCustomerGroupList = (searchTerm = "", newPage = 1, paginationLimitIn = paginationLimit) => {
    getCustomers(searchTerm, newPage, paginationLimitIn).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setCustomers(data);
          if (data.length < paginationLimitIn) {
            setHasMoreData(false);
          } else {
            setHasMoreData(true);
          }
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 1));
      }
    });
  };

  /**
    * This method is used to trigger pagination
    * @param {Number} page
    */
  const getPageData = (direction) => {
    const newPage = page + direction;
    getCustomerGroupList(searchTerm, Math.max(newPage, 1), paginationLimit);
  };

  /** getting list of customers when page load */
  useEffect(() => {
    getCustomerGroupList();
  }, [paginationLimit]);

  /**
      * This method is used to update text in search box
      * @param {Event} event
      */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) { getCustomerGroupList(value); } else getCustomerGroupList();
  };


  const onHandleExport = async (type) => {
    setIsProccessing(true);
    const page = 0;
    const size = -1;
    getCustomers(searchTerm, page, size)
      .then(async (response) => {
        if (response && response.success === true) {
          const {
            data,
          } = response;
          if (Array.isArray(data)) {
            const customers = await transformCustomerData(data);
            let fileName = "Customers.xlsx";
            if (type === "CSV") {
              fileName = "Customers.csv";
            }
            const ws = XLSX.utils.json_to_sheet(customers);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Customers");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 15 },
              { wch: 30 },
              { wch: 15 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch((err) => {
        setIsProccessing(false);
      });
    setIsProccessing(false);
  };
  const handleResetPassword = (row) => {
    setLoading([...loading, row.id]);
    if (row && row.username) {
      const request = { username: row.username };
      initiateResetPassword(request).then((response) => {
      const filterLoading = loading.filter((each) => each.id !== row.id);
      setLoading(filterLoading);
   });
    }
  };

  return (
    <CustomerDataManagement
      customers={customers}
      page={page}
      hasMoreData={hasMoreData}
      searchTerm={searchTerm}
      getPageData={getPageData}
      handleSearchTerm={handleSearchTerm}
      onHandleExport={onHandleExport}
      isProccessing={isProccessing}
      handleResetPassword={handleResetPassword}
      loading={loading}
    />
  );
};

export default CustomerDataManagementContainer;
