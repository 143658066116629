/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * UrlEditor
 *
 * @author Naseef O
 *
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Form from "../../../common/core/Form";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Collapse from "../../../common/core/Collapse";
import infoIcon from "../../../common/assets/images/svg/info.svg";
import styles from "../css/UrlEditor.module.css";

const UrlEditor = (props) => {
  const {
    modal,
    toggle,
    form,
    handleChange,
    isEdit,
    register,
    errors,
    handleSave,
    handleSubmit,
  } = props;
  const { t } = useTranslation(["common"]);
  const [collapse, setCollapse] = useState(false);
  return (
    <Modal isOpen={modal} toggle={() => toggle()}>
      <Form onSubmit={handleSubmit(handleSave)}>
        <ModalHeader toggle={toggle}>{isEdit ? "Edit" : "Add"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Vanity URL</Label>
                <Input
                  className="formText"
                  placeholder="ex: /contact-us"
                  name="url"
                  defaultValue={form.url}
                  onChange={handleChange}
                  innerRef={
                    register({
                      required: t("common:validation_url"),
                    })
                  }
                  invalid={!!(errors.url && errors.url.message)}
                />
                <FormFeedback>{errors.url && errors.url.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Internal URL</Label>
                <Input
                  className="formText"
                  placeholder="ex: /custom/contact-us"
                  name="internalUrl"
                  defaultValue={form.internalUrl}
                  onChange={handleChange}
                  innerRef={
                    register({
                      required: t("common:validation_internal_url"),
                    })
                  }
                  invalid={!!(errors.internalUrl && errors.internalUrl.message)}
                />
                <FormFeedback>{errors.internalUrl && errors.internalUrl.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="m-0">
            <Col xs="12" className={styles.infoWrapper}>
              <Row>
                <Col xs="12" onClick={() => setCollapse(!collapse)} className=" d-flex justify-content-center ">
                  <span className="mr-2">More internal URLs </span>
                  <img src={infoIcon} alt="info" />
                </Col>
              </Row>
              <Collapse isOpen={collapse}>
                <Row>
                  <Col xs="12" className=" pt-2">
                    <span>
                      Static pages:  /custom/about-us
                      <br />
                      Search results :  /product-list?key=abc
                    </span>
                  </Col>
                </Row>
              </Collapse>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle()}>Cancel</Button>
          {" "}
          <Button color="secondary" type="submit">Save</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

UrlEditor.propTypes = {
  modal: PropTypes.func.isRequired,
  toggle: PropTypes.bool.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UrlEditor;
