/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import NewJobConfiguration from "../components/NewJobConfiguration";
import BasicJobConfigurationContainer from "./BasicJobConfigurationContainer";
import JobTaskConfigurationContainer from "./JobTaskConfigurationContainer";
import JobReview from "../components/JobReview";

const initialStates = {
  name: "",
  description: "",
  scope: [],
  locations: [],
  recurring: "ONLY_ONCE",
  date: "",
  frequencyFromDate: "",
  frequencyEndDate: "",
  noEndDate: true,
  selectedWeekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  startDateTime: "",
  startType: "PARTICULAR_TIME",
  startTime: "",
  intervalTime: "",
  intervalTimeUnit: "HR",
  errorHandlingType: "CONTINUE_SCHEDULE",
  retryCount: 0,
  notificationChannel: "EMAIL",
  notificationStatuses: [],
  notificationEmailTo: "",
  notificationEmailCC: "",
  notificationSMSTo: "",
};

function reducer(form, {
  name, value, type,
}) {
  let selectedData = value;
  if (type === "MULTI_SELECT_CHECKBOX") {
    let filteredCheckBoxData = form[name] || [];
    if (filteredCheckBoxData && filteredCheckBoxData.includes(value)) {
      filteredCheckBoxData = filteredCheckBoxData.filter((
        checkBoxValue,
      ) => checkBoxValue !== value);
    } else {
      filteredCheckBoxData.push(value);
    }
    selectedData = filteredCheckBoxData;
  }
  switch (type) {
    case "recurring":
      return {
        ...form,
        [name]: selectedData,
        date: "",
        frequencyFromDate: "",
        frequencyEndDate: "",
        noEndDate: true,
        selectedWeekDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        startDateTime: "",
        startType: "PARTICULAR_TIME",
        startTime: "",
        intervalTime: "",
        intervalTimeUnit: "HR",
      };
    case "noEndDate":
      return {
        ...form,
        [name]: selectedData,
        frequencyEndDate: "",
      };
    case "startType":
      return {
        ...form,
        [name]: selectedData,
        startTime: "",
        intervalTime: "",
        intervalTimeUnit: "HR",
      };
    case "errorHandlingType":
      return {
        ...form,
        [name]: selectedData,
        retryCount: 0,
      };
    case "notificationChannel":
      return {
        ...form,
        [name]: selectedData,
        notificationEmailTo: "",
        notificationEmailCC: "",
        notificationSMSTo: "",
      };
    default:
      return {
        ...form,
        [name]: selectedData,
      };
  }
}
const NewJobConfigurationContainer = () => {
  const history = useHistory();
  const [form, dispatch] = useReducer(reducer, initialStates);
  const [jobTaskData, setJobTaskData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("JOB_BASIC");
  const handleFormChange = (name, value, type) => {
    dispatch({ name, value, type });
  };

  const [tabView, setTabView] = useState(
    <BasicJobConfigurationContainer
      setSelectedTab={setSelectedTab}
      handleFormChange={handleFormChange}
      form={form}
    />,
  );

  const getColor = (tab) => {
    if (selectedTab === tab) {
      return "dark";
    }
    return "info";
  };

  const cancelJobCreation = () => {
    setJobTaskData([]);
    history.push("/administration/job-list");
  };

  const saveJobCreation = () => {
    setJobTaskData([]);
    history.push("/administration/job-list");
  };

  const getTabView = () => {
    if (selectedTab === "JOB_BASIC") {
      return (
        <BasicJobConfigurationContainer
          setSelectedTab={setSelectedTab}
          form={form}
          handleFormChange={handleFormChange}
          cancelJobCreation={cancelJobCreation}
        />
      );
    }
    if (selectedTab === "JOB_TASK") {
      return (
        <JobTaskConfigurationContainer
          setSelectedTab={setSelectedTab}
          jobTaskData={jobTaskData}
          setJobTaskData={setJobTaskData}
        />
      );
    }
    if (selectedTab === "JOB_REVIEW") {
      return (
        <JobReview
          jobBasicConfigData={form}
          jobTaskData={jobTaskData}
          cancelJobCreation={cancelJobCreation}
          saveJobCreation={saveJobCreation}
        />
      );
    }
    return (
      <BasicJobConfigurationContainer
        setSelectedTab={setSelectedTab}
        form={form}
        handleFormChange={handleFormChange}
      />
    );
  };

  useEffect(() => {
    setTabView(getTabView);
  }, [selectedTab, form]);

  return (
    <NewJobConfiguration
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      getColor={getColor}
      tabView={tabView}
      getTabView={getTabView}
    />
  );
};

export default NewJobConfigurationContainer;
