/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import AboutBlueCommerce from "./component/AboutBlueCommerce";
import Merchandising from "./component/Merchandising";
import Marketing from "./component/Marketing";
import Operations from "./component/Operations";
import Administering from "./component/Administering";
import PageNotFound from "../../common/components/PageNotFound";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */

/** components */

const DocumentationGuide = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter
      exact
      path={`${path}/welcome`}
      component={AboutBlueCommerce}
    />
    <PrivateRouter path={`${path}/merchandising`} component={Merchandising} />
    <PrivateRouter path={`${path}/marketing`} component={Marketing} />
    <PrivateRouter path={`${path}/operations`} component={Operations} />
    <PrivateRouter path={`${path}/administering`} component={Administering} />
    <PrivateRouter exact path="" component={PageNotFound} />
  </Switch>
);
DocumentationGuide.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default DocumentationGuide;
