/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */

import httpApi from "./httpApi";

/**
 * This method is used to create SKU
 *
 * @param {*} widgetType
 * @param {*} configId
 * @returns
 */
const createSKU = async (sku) => {
  const response = await httpApi.post("/v1/sku", sku);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update SKU
 *
 * @param {*} widgetType
 * @param {*} configId
 * @returns
 */
const updateSKU = async (sku) => {
  const response = await httpApi.patch("/v1/sku", sku);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get SKU by Id
 *
 * @param {*} widgetType
 * @param {*} configId
 * @returns
 */
const getSKUById = async (skuId) => {
  const response = await httpApi.get(`/v1/sku/id/${skuId}`);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return skus list
 * @returns {Array}
 */
const getSkus = async (searchTerm = "", page = 0, size = 10) => {
  const response = await httpApi.get("/v1/sku/search", { params: { searchTerm, page, size } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  createSKU,
  updateSKU,
  getSKUById,
  getSkus,
};
