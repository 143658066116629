// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Sreelekshmi Prasannan\n */\n\n .AddCustomerGroup_block2__3ZL0V {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.AddCustomerGroup_borderRadius2__3xFPR {\n  border-radius: 0.125rem !important;\n}\n\n.AddCustomerGroup_secondaryColor__1_TCI {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.AddCustomerGroup_primaryColor__97wos {\n  background: #e91e63 !important;\n  border-color: #e91e63 !important;\n}\n\n.AddCustomerGroup_btn__1Av9T {\n  min-width: 7rem;\n}\n\n\n\n/* ========== FORM AREA ============ */\n.AddCustomerGroup_block3__2plyj {\n  padding: 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"block2": "AddCustomerGroup_block2__3ZL0V",
	"borderRadius2": "AddCustomerGroup_borderRadius2__3xFPR",
	"secondaryColor": "AddCustomerGroup_secondaryColor__1_TCI",
	"primaryColor": "AddCustomerGroup_primaryColor__97wos",
	"btn": "AddCustomerGroup_btn__1Av9T",
	"block3": "AddCustomerGroup_block3__2plyj"
};
module.exports = exports;
