/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Dropdown from "../../../common/core/Dropdown";

const LocaleDropdown = ({
  locales, locale, dropdownLanguage, toggleDropdownLanguage, handleLanguageSelect,
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <Dropdown
      isOpen={dropdownLanguage}
      toggle={toggleDropdownLanguage}
    >
      <DropdownToggle caret>
        <span>
          {t(`common:label_${locale}`)}
        </span>
      </DropdownToggle>
      <DropdownMenu>
        {locales && locales.map((localeIn) => (
          <div
            role="presentation"
            onClick={() => handleLanguageSelect(localeIn)}
          >
            {t(`common:label_${localeIn}`)}
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
LocaleDropdown.propTypes = {
  locale: PropTypes.string.isRequired,
  locales: PropTypes.objectOf(PropTypes.any).isRequired,
  handleLanguageSelect: PropTypes.func.isRequired,
  dropdownLanguage: PropTypes.bool.isRequired,
};

export default LocaleDropdown;
