/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Anagha
 */

import { useHistory, useRouteMatch } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ===== COMPONENTS  =========== */
import EditFulfillmentMethod from "../components/EditfulfillmentMethod";

/** ===== API SERVICE FUNCTIONS =========== */
import { getFulfillmentMethodConfigurationById, updateFulfillment } from "../../../api/fulfillmentMethodService";
import { getShippingMethodConfigurationsByFulfillmentType } from "../../../api/shipmentManagementServices";
import { getPaymentMethodConfigurations } from "../../../api/paymentManagementServices";


const EditFulfillmentMethodContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [id, setId] = useState(null);
  const [form, setForm] = useState({
    id: "",
    name: "",
    description: "",
    displayMessage: "",
    fulfillmentMethod: "",
  });
  useEffect(() => {
    if (params.id) {
      getFulfillmentMethodConfigurationById(params.id).then((response) => {
        if (response && response.data) {
          const { data } = response;
          getShippingMethods(data.code);
          getPaymentMethods();
          setForm(data);
        }
      });
    } setId(params.id);
  }, [params.id]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };
  /**
   * This method is used to cancel the edit operation and return to the listing page
   */
  const onHandleCancel = () => {
    history.push("/operations/fulfillment-method");
  };

  /**
   * This method is used to update the form
   */
  const onHandleSave = async () => {
    await updateFulfillment(form);
    history.push("/operations/fulfillment-method");
  };

  const [
    shippingMethodConfigurations,
    setShippingMethodConfigurations,
  ] = useState([]);

  const getShippingMethods = async (code) => {
    getShippingMethodConfigurationsByFulfillmentType(code).then((response) => {
      if (response && response.data) {
        const { data } = response;
        let activeShippingMethods = [];
        data.map((selectedData) => {
          if (selectedData.active) {
            activeShippingMethods.push(selectedData);
          }
        })
        setShippingMethodConfigurations(activeShippingMethods);
      }
    });
  }

  const [
    paymentMethodConfigurations,
    setPaymentMethodConfigurations,
  ] = useState([]);

  const getPaymentMethods = async (code) => {
    getPaymentMethodConfigurations().then((response) => {
      if (response && response.data) {
        const { data } = response;
        let activePaymentmethods = [];
        data.map((selectedData) => {
          if (selectedData.active) {
            activePaymentmethods.push(selectedData);
          }
        })
        setPaymentMethodConfigurations(activePaymentmethods);
      }
    });
  }

  const handlePaymentStatusChange = (paymentMethod) => {
    let applicablePayments = form.applicablePaymentMethods;
    if (applicablePayments.includes(paymentMethod)) {
      applicablePayments = applicablePayments.filter(e => e !== paymentMethod);
    }
    else {
      applicablePayments.push(paymentMethod);
    }
    form.applicablePaymentMethods = applicablePayments;
    setForm(form);
    getPaymentMethods();
  };

  const handleShipmentStatusChange = (id, checked) => {
    shippingMethodConfigurations.map((shipment) => {
      if (shipment.id === id) {
        if (checked) {
          form.defaultShippingMethod = shipment.shippingMethod;
        } else {
          form.defaultShippingMethod = null;
        }
        setForm(form);
      }
    });
    getShippingMethods(form.code);
  };

  return (
    <EditFulfillmentMethod
      form={form}
      handleFormChange={handleFormChange}
      onHandleSave={onHandleSave}
      onHandleCancel={onHandleCancel}
      handleChange={handleChange}
      shippingMethodConfigurations={shippingMethodConfigurations}
      paymentMethodConfigurations={paymentMethodConfigurations}
      onPaymentStatusChange={handlePaymentStatusChange}
      onShipmentStatusChange={handleShipmentStatusChange}
    />
  );
};
export default EditFulfillmentMethodContainer;
