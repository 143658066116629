/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** ========== SUB COMPONENT ================ */
import StoreHours from "./StoreHours";
import ContactInfoForm from "./ContactInfoForm";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import FormFeedback from "../../../common/core/FormFeedback";


/** ======== MODULE STYLES ========== */
import styles from "../css/StoreForm.module.css";

const StoreForm = (props) => {
  const {
    name, events, services, showInStorefront, hdAvailable,
    cncAvailable, pageTitle, metaDescription, handleFormChange, handleChange,
    contactInfo, handleFormObjectChange, workingHours,
    onLocationChange, storeId, validateStoreId, error, cityList, dncAvailable
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "store"]);
  return (
    <>
      <Row className={styles.block3}>
        <Col xs="12">
          <Row>
            <Col xs="12" sm="11" md="6">
              <FormGroup>
                <Label className="formTextLabel font-weight-bold">
                  {t("store:label_name")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <Input className="formText" placeholder={t("store:placeholder_name")} name="name" value={name} onChange={handleFormChange} required />
              </FormGroup>

            </Col>
            <Col xs="12" sm="11" md="6">
              <FormGroup>
                <Label className="formTextLabel font-weight-bold">
                  {t("store:label_id")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <Input
                  className="formText"
                  placeholder={t("store:placeholder_id")}
                  name="storeId"
                  value={storeId}
                  onChange={handleFormChange}
                  onBlur={validateStoreId}
                  invalid={error.storeId}
                  required
                />
                <FormFeedback>{error.storeId}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="6">
              <FormGroup>
                <Label className="formTextLabel font-weight-bold">
                  {t("store:label_store_events")}
                </Label>
                <Input className="formText" placeholder={t("store:placeholder_store_events")} name="events" value={events} onChange={handleFormChange} />
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="6">
              <FormGroup>
                <Label className="formTextLabel font-weight-bold">{t("store:label_inStore_services")}</Label>
                <Input className="formText" placeholder={t("store:placeholder_inStore_services")} name="services" value={services} onChange={handleFormChange} />
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="3">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Label className="formTextLabel font-weight-bold">
                  <span className="pr-1">
                    {t("store:label_fulfillment_store")}
                  </span>
                </Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-3",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="enable-hdAvailable"
                    name="hdAvailable"
                    checked={hdAvailable}
                    onChange={() => handleChange("hdAvailable", !hdAvailable)}
                  />
                  <Label className="custom-control-label" htmlFor="enable-hdAvailable" />
                </div>
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="3">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Label className="formTextLabel font-weight-bold">
                  <span className="pr-1">
                    {t("store:label_dnc_Available")}
                  </span>
                </Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-3",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="enable-dncAvailable"
                    name="dncAvailable"
                    checked={dncAvailable}
                    onChange={() => handleChange("dncAvailable", !dncAvailable)}
                  />
                  <Label className="custom-control-label" htmlFor="enable-dncAvailable" />
                </div>
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="4">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Label className="formTextLabel font-weight-bold">
                  <span className="pr-1">
                    {t("store:label_allowClick_n_Collect")}
                  </span>
                </Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-3",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="enable-allowClick_n_Collect"

                    name="cncAvailable"
                    checked={cncAvailable}
                    onChange={() => handleChange("cncAvailable", !cncAvailable)}
                  />
                  <Label className="custom-control-label" htmlFor="enable-allowClick_n_Collect" />
                </div>
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="3">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Label className="formTextLabel font-weight-bold">
                  {t("store:label_store_in_storefront")}
                </Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-3",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="enable-inStorefront"
                    name="showInstorefront"
                    checked={showInStorefront}
                    onChange={() => handleChange("showInStorefront", !showInStorefront)}
                  />
                  <Label className="custom-control-label" htmlFor="enable-inStorefront" />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-truncate pageHeader">
              <span className="pageText">{t("store:title_contact_&_location")}</span>
              <hr />
              {/* Contact Info sub-component */}
              <ContactInfoForm
                handleFormObjectChange={handleFormObjectChange}
                contactInfo={contactInfo}
                onLocationChange={onLocationChange}
                cityList={cityList}
              />
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="text-truncate pageHeader">
              <span className="pageText">{t("store:title_SEO_info")}</span>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="11" md="6">
              <FormGroup>
                <Label className="formTextLabel font-weight-bold">{t("store:label_page_title")}</Label>
                <Input className="formText" placeholder={t("store:placeholder_page_title")} name="pageTitle" value={pageTitle} onChange={handleFormChange} />
              </FormGroup>
            </Col>
            <Col xs="12" sm="8">
              <FormGroup>
                <Label className="formTextLabel font-weight-bold">{t("store:label_page_description")}</Label>
                <Input className="formText" placeholder={t("store:placeholder_page_description")} name="metaDescription" value={metaDescription} onChange={handleFormChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-truncate pageHeader">
              <span className="pageText">{t("store:title_store_hours")}</span>
              <hr />
              {/* Store Hours sub-component */}
              <StoreHours
                workingHours={workingHours}
                handleFormObjectChange={handleFormObjectChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

StoreForm.propTypes = {
  name: PropTypes.string.isRequired,
  events: PropTypes.string.isRequired,
  services: PropTypes.string.isRequired,
  showInStorefront: PropTypes.bool.isRequired,
  hdAvailable: PropTypes.bool.isRequired,
  cncAvailable: PropTypes.bool.isRequired,
  contactInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  workingHours: PropTypes.objectOf(PropTypes.any).isRequired,
  pageTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFormObjectChange: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  validateStoreId: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  cityList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default StoreForm;
