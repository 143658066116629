/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Meshal Chalil
 */

import constants from "./constants";
import commonUtils from "./commonUtils";

const {
  isListNotEmpty,
} = commonUtils;
const {
  CATEGORY: {
    BULK_UPLOAD_LIMIT,
    SHEET_CONTEXT_SEQEUENCE,
  },
} = constants;


const parseDataFromSheet = (rawData) => {
  const headerKeys = ["Id", "CategoryName", "CategoryID", "ProductSKU", "SequenuceValue"];
  if (isListNotEmpty(rawData)) {
    const isValid = rawData.every((data) => headerKeys.every((each) => Object.keys(data).includes(each)));
    if (isValid) {
      const mappedValues = rawData.map((each) => each.id);
      const filteredValues = mappedValues.filter((each, index) => mappedValues.indexOf(each) === index);
      if (filteredValues.length < BULK_UPLOAD_LIMIT) {
        return {
          isValid,
          options: rawData,
          value: filteredValues,
        };
      }
      return {
        isValid: false,
        message: `The maximum number of item that can be uploaded has exceeded.Please upload the items in batches of ${BULK_UPLOAD_LIMIT}`,
      };
    }
    return {
      isValid,
      message: "Invalid sheet format. Please refer the template for valid data format.",
    };
  }
  return {
    isValid: false,
    message: "No Data Found",
  };
};

const sheetTemplateMapper = {
  [SHEET_CONTEXT_SEQEUENCE]: [{
      Id: "< ID 1>",
      CategoryName: "<Category NAME 1>",
      CategoryID: "<Category ID 1>",
      ProductSKU: "<ProductSKU 1>",
      SequenuceValue: "<SequenuceValue 1>",
    },
    {
      Id: "< ID 2>",
      CategoryName: "<Category NAME 2>",
      CategoryID: "<Category ID 2>",
      ProductSKU: "<ProductSKU 2>",
      SequenuceValue: "<SequenuceValue 2>",
    },
    {
      Id: "< ID 3>",
      CategoryName: "<Category NAME 3>",
      CategoryID: "<Category ID 3>",
      ProductSKU: "<ProductSKU 3>",
      SequenuceValue: "<SequenuceValue 3>",
    },
    {
      Id: "< ID 4>",
      CategoryName: "<Category NAME 4>",
      CategoryID: "<Category ID 4>",
      ProductSKU: "<ProductSKU 4>",
      SequenuceValue: "<SequenuceValue 4>",
    },
  ],
};

const getImportSheetTemplate = (context) => sheetTemplateMapper[context];

export default {
  parseDataFromSheet,
  getImportSheetTemplate,
};