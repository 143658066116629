/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Navigation Management Container
 *
 * @author Naseef O
 *
 */
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ========= SUB COMPONENT ========= */
import MenuList from "../components/MenuList";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

/** ========= API SERVICE FUNCTIONS ========= */
import {
  getWebHeaderNavigationMenus,
  setStatus,
  deleteSiteNavigation,
  syncSiteNavigation,
} from "../../../api/siteNavigationServices";

import {
  getSitePreviewConfigurations,
  replicateSiteNavigation,
  getSiteNavigationReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";

const MenuListContainer = () => {
  const history = useHistory();

  /** local states */
  const [menus, setMenus] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [deleteMenu, setDeleteMenu] = useState(null);

  const [locale, setLocale] = useState("en_AE");
  const localeHandler = (localeLang) => {
    setLocale(localeLang);
  };

  useEffect(() => {
    getWebHeaderNavigationMenus(locale).then((response) => {
      if (response) {
        const { success, data } = response;
        if (success && Array.isArray(data)) {
          setMenus(data);
        }
      }
    });
  }, [locale]);

  /**
   * to redirect to add menu page
   */
  const addMenu = () => {
    history.push("/merchandising/navigation/new");
  };

  /**
   * This method is sued to  open edit menu
   * @param {String} menuId
   */
  const editMenu = (menuId) => {
    history.push(`/merchandising/navigation/edit/${menuId}`);
  };

  /**
   * This method is sued to  open edit menu
   * @param {String} menuId
   */
  const viewDetails = (menuId) => {
    history.push(`/merchandising/navigation/details/${menuId}`);
  };

  /**
   * This method used to change product status
   * @param {String} id
   * @param {Boolean} status
   */
  const changeMenuStatus = async (status, id) => {
    if (submitting) return;
    setSubmitting(true);
    setSelectedMenuId(id);
    const response = await setStatus(id, status);
    if (response && response.success) {
      const newMenus = menus.map((menu) => (menu.id === id
        ? ({ ...menu, active: status }) : menu));
      setMenus(newMenus);
      /** should add a status change toast once the toast functionality implemented */
    }
    setSelectedMenuId(null);
    setSubmitting(false);
  };

  /**
   * This method is used to remove a menu
   * @param {String} menuId
   */
  const removeMenu = (menuId) => {
    setDeleteMenu({ menuId });
  };

  /**
 * This method is used to cancel confirm form
 */
  const cancelConfirm = () => {
    setDeleteMenu(null);
  };

  /**
   * method for confirming delete menu
   */
  const confirmDelete = async () => {
    if (Boolean(deleteMenu) && deleteMenu.menuId) {
      const { menuId } = deleteMenu;
      const response = await deleteSiteNavigation(menuId);
      if (response && response.success === true) {
        const newMenus = menus.filter((menu) => (menu.id !== menuId));
        setMenus(newMenus);
      }
      setDeleteMenu(null);
    }
  };

  /**
   * method for sync site Navigation
   */
  const [loading, setLoading] = useState(false);
  const syncSiteNavigations = async () => {
    setLoading(true);
    const response = await syncSiteNavigation();
    if (response && response.success) {
      setLoading(false);
    }
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    replicateSiteNavigation().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message:
            "Site Navigation pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };

  const getReplicationStatus = async () => {
    getSiteNavigationReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last site navigation push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };


  return (
    <>
      <MenuList
        menus={menus}
        submitting={submitting}
        selectedMenuId={selectedMenuId}
        pushToLiveEnabled={pushToLiveEnabled}
        previewSiteUrl={previewSiteUrl}
        previewEnabled={previewEnabled}
        message={message}
        // functional
        handleMenuStatus={changeMenuStatus}
        addMenu={addMenu}
        editMenu={editMenu}
        viewDetails={viewDetails}
        removeMenu={removeMenu}
        syncSiteNavigations={syncSiteNavigations}
        loading={loading}
        localeHandler={localeHandler}
        locale={locale}
        handlePushToLive={handlePushToLive}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
      />
      <ConfirmationModal
        isOpen={Boolean(deleteMenu)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this menu?"
      />
    </>
  );
};

export default MenuListContainer;
