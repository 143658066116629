/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Meshal Chalil
 */

import React from "react";
import PropTypes from "prop-types";
/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import constants from "../../../common/utils/constants";
import Spinner from "../../../common/core/Spinner";
/** ========= SVG IMAGE ICON ========= */
import downloadIconfrom from "../../../common/assets/images/svg/file-upload.svg";
import publishIcon from "../../../common/assets/images/svg/publish.svg";
import deleteIcon from "../../../common/assets/images/svg/delete.svg";

const Sequencing = (props) => {
  const {
    handleBulkImport,
    downloadBulkImportTemplate,
    setOpenConfirmModal,
    bulkUploadloadingFlags,
    bulkDeleteloadingFlags,
  } = props;
  const { CATEGORY } = constants;
  return (
    <>
      <Row>
        <Col xs="6" sm="6">
          <FormGroup>
            <span
              title="Upload Product Sequence"
              className="float-right"
            >
              <label htmlFor="productSequenceFile" className="btn btn-primary ">
                {
                  bulkUploadloadingFlags
                    ? <Spinner size="sm" />
                    : (
                      <img
                        src={publishIcon}
                        alt="upload"
                      />
                    )
                }
                <input
                  name="productSequence"
                  id="productSequenceFile"
                  accept={CATEGORY.FILE_TYPES_SEQUENCE}
                  type="file"
                  className="d-none"
                  onChange={
                    (e) => handleBulkImport(e)
                  }
                />
              </label>
            </span>
            <span
              className="float-right mr-3 btn btn-light border text-primary"
              role="presentation"
              title="Download Template"
              onClick={() => downloadBulkImportTemplate("Sequence", CATEGORY.SHEET_CONTEXT_SEQEUENCE)}
            >
              <img src={downloadIconfrom} alt="download" />
            </span>
            <span
              className="float-right mr-3 btn btn-light border text-primary"
              role="presentation"
              title="Products bulk delete"
              onClick={() => setOpenConfirmModal((prev) => !prev)}
            >
              {bulkDeleteloadingFlags ? (
                <Spinner size="sm" />
              ) : (
                <img src={deleteIcon} alt="download" />
              )}
            </span>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

Sequencing.propTypes = {
  handleBulkImport: PropTypes.func.isRequired,
  downloadBulkImportTemplate: PropTypes.func.isRequired,
  setOpenConfirmModal: PropTypes.func.isRequired,
  bulkUploadloadingFlags: PropTypes.bool.isRequired,
  bulkDeleteloadingFlags: PropTypes.bool.isRequired,
};

export default Sequencing;
