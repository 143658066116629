// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Roshna Accu\n */\n\n\n.PromotionCreatorView_block2__1e79V {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.PromotionCreatorView_borderRadius2__1gpjS {\n  border-radius: 0.125rem !important;\n}\n\n.PromotionCreatorView_secondaryColor__l4TMQ {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.PromotionCreatorView_primaryColor__D3JC3 {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n\n.PromotionCreatorView_btn__1SWu0 {\n  min-width: 7rem;\n}\n\n\n\n", ""]);
// Exports
exports.locals = {
	"block2": "PromotionCreatorView_block2__1e79V",
	"borderRadius2": "PromotionCreatorView_borderRadius2__1gpjS",
	"secondaryColor": "PromotionCreatorView_secondaryColor__l4TMQ",
	"primaryColor": "PromotionCreatorView_primaryColor__D3JC3",
	"btn": "PromotionCreatorView_btn__1SWu0"
};
module.exports = exports;
