/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi
 */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datetime";
import moment from "moment";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import ButtonGroup from "../../../common/core/ButtonGroup";
import styles from "../css/JobConfiguration.module.css";
import MultiSelectCheckBox from "../common/MultiSelectCheckBox";

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const startTypes = [
  { value: "PARTICULAR_TIME", label: "Particular Time" },
  { value: "REGULAR_INTERVAL", label: "Regular Interval" },
];

const EditJob = ({
  form,
  handleFormChange, scopeList, locationList, alertList, cancelJobEdit, saveJobDetails,
}) => {
  const [cSelected, setCSelected] = useState(form.selectedWeekDays);
  const onCheckboxBtnClick = (selected) => {
    const index = cSelected.indexOf(selected);
    if (index < 0) {
      cSelected.push(selected);
    } else {
      cSelected.splice(index, 1);
    }
    setCSelected([...cSelected]);
  };

  useEffect(() => {
    handleFormChange("selectedWeekDays", cSelected, "array");
  }, [cSelected]);

  const { t } = useTranslation(["common", "job-configuration"]);
  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">
            {t("job-configuration:span_edit_job_configuration")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_name")}
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <Input
                  className="formText"
                  type="text"
                  placeholder=""
                  name="name"
                  value={form.name}
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="3">
              <FormGroup>
                <MultiSelectCheckBox
                  inputData={{
                    label: t("job-configuration:label_job_scope"), inputName: "jobScope", attributes: { options: scopeList },
                  }}
                  handleChange={handleFormChange}
                  stateData={form}
                />
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <MultiSelectCheckBox
                  inputData={{
                    label: t("job-configuration:label_job_locations"), inputName: "jobLocation", attributes: { options: locationList },
                  }}
                  handleChange={handleFormChange}
                  stateData={form}
                />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_description")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  type="textarea"
                  name="description"
                  value={form.description}
                  onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs="5" sm="6" md="7">
              <Label className={styles.sectionHead}>
                {t("job-configuration:label_job_frequency")}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="4" sm="5" md="4">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Input
                  className="formText"
                  placeholder=""
                  type="select"
                  name="recurring"
                  value={form.recurring}
                  onChange={(e) => handleFormChange(
                    e.target.name,
                    e.target.value,
                    "recurring",
                  )}
                >
                  <option value="ONLY_ONCE">
                    {t("job-configuration:option_only_once")}
                  </option>
                  <option value="RECURRING">
                    {t("job-configuration:option_recurring")}
                  </option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          {form.recurring === "RECURRING" ? (
            <>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("job-configuration:label_from_date")}
                      <span className="pl-1 text-danger">*</span>
                    </Label>
                    <DatePicker
                      id="fromDate"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                      input
                      inputProps={{
                        placeholder: t("common:placeholder_date_example"),
                        name: "startDate",
                      }}
                      value={
                        form.frequencyFromDate
                          ? moment(form.frequencyFromDate).format("DD/MM/YYYY")
                          : null
                      }
                      onChange={(o) => moment.isMoment(o)
                        && handleFormChange("frequencyFromDate", o.toDate(), "date")}
                      required
                    />
                  </FormGroup>
                </Col>
                {!form.noEndDate ? (
                  <Col xs="6">
                    <FormGroup>
                      <Label className="formTextLabel">
                        {t("job-configuration:label_to_date")}
                        <span className="pl-1 text-danger">*</span>
                      </Label>
                      <DatePicker
                        id="toDate"
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false}
                        closeOnSelect
                        input
                        inputProps={{
                          placeholder: t("common:placeholder_date_example"),
                          name: "toDate",
                        }}
                        value={
                          form.frequencyEndDate
                            ? moment(form.frequencyEndDate).format("DD/MM/YYYY")
                            : null
                        }
                        onChange={(o) => moment.isMoment(o)
                          && handleFormChange(
                            "frequencyEndDate",
                            o.toDate(),
                            "date",
                            "noEndDate",
                          )}
                        required
                      />
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col xs="7">
                  <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                    <Label
                      title={t("job-configuration:label_recurring_job")}
                      className="formTextLabel text-truncate"
                    >
                      {t("job-configuration:label_no_end_date")}
                    </Label>
                    <div
                      className={clsx("custom-control", "custom-switch", "ml-1")}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="noEndDate"
                        name="noEndDate"
                        checked={form.noEndDate}
                        onChange={() => handleFormChange("noEndDate", !form.noEndDate, "toggle")}
                      />
                      <Label className="custom-control-label" htmlFor="noEndDate" />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="mb-4">
                  <ButtonGroup>
                    {weekdays.map((day) => (
                      <Button
                        color={
                          form.selectedWeekDays.includes(day)
                            ? "primary"
                            : "secondary"
                        }
                        onClick={() => onCheckboxBtnClick(day)}
                      >
                        {day}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("job-configuration:label_start_type")}
                    </Label>
                    <Input
                      className="formText"
                      placeholder=""
                      type="select"
                      name="startType"
                      value={form.startType}
                      onChange={(e) => handleFormChange(e.target.name, e.target.value, "startType")}
                    >
                      {startTypes.map((type) => (
                        <option value={type.value}>{type.label}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                {form.startType === "PARTICULAR_TIME" ? (
                  <Col xs="6">
                    <FormGroup>
                      <Label className="formTextLabel">
                        {t("job-configuration:label_start_time")}
                        <span className="pl-1 text-danger">*</span>
                      </Label>
                      <DatePicker
                        id="startTime"
                        dateFormat={false}
                        closeOnSelect
                        input
                        inputProps={{
                          placeholder: t("common:placeholder_date_example"),
                          name: "startTime",
                        }}
                        value={
                          form.startTime
                            ? moment(form.startTime).format("hh:mm:ss")
                            : null
                        }
                        onChange={(o) => moment.isMoment(o)
                          && handleFormChange("startTime", o.toDate(), "date")}
                        required
                      />
                    </FormGroup>
                  </Col>
                ) : (
                    <Col xs="6">
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">
                              {t("job-configuration:label_interval")}
                              <span className="pl-1 text-danger">*</span>
                            </Label>
                            <Input
                              className="formText"
                              type="text"
                              placeholder=""
                              name="intervalTime"
                              value={form.intervalTime}
                              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label className="formTextLabel">
                              {t("job-configuration:label_unit")}
                            </Label>
                            <Input
                              className="formText"
                              placeholder=""
                              type="select"
                              name="intervalTimeUnit"
                              value={form.intervalTimeUnit}
                              onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                            >
                              <option value="HR">hr</option>
                              <option value="MIN">min</option>
                              <option value="SEC">sec</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  )}
              </Row>
            </>
          ) : (
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className="formTextLabel">
                      {t("job-configuration:label_date")}
                      <span className="pl-1 text-danger">*</span>
                    </Label>
                    <DatePicker
                      id="date"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                      input
                      inputProps={{
                        placeholder: t("common:placeholder_date_example"),
                        name: "date",
                      }}
                      value={
                        form.date ? moment(form.date).format("DD/MM/YYYY") : null
                      }
                      onChange={(o) => moment.isMoment(o)
                        && handleFormChange("date", o.toDate(), "date")}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
          <hr />
          <Row>
            <Col xs="12">
              <Label className={styles.sectionHead}>
                {t("job-configuration:label_job_error_handling_title")}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_handling_type")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  type="select"
                  name="errorHandlingType"
                  value={form.errorHandlingType}
                  onChange={(e) => handleFormChange(
                    e.target.name,
                    e.target.value,
                    "errorHandlingType",
                  )}
                >
                  <option value="CONTINUE_SCHEDULE">
                    {t("job-configuration:option_continue_schedule")}
                  </option>
                  <option value="RETRY_JOB">
                    {t("job-configuration:option_retry_job")}
                  </option>
                  <option value="STOP_JOB">
                    {t("job-configuration:option_stop_job")}
                  </option>
                </Input>
              </FormGroup>
            </Col>
            {form.errorHandlingType === "RETRY_JOB" ? (
              <Col xs="2">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("job-configuration:label_retry_count")}
                  </Label>
                  <Input
                    className="formText"
                    placeholder=""
                    type="number"
                    name="retryCount"
                    value={form.retryCount}
                    min="0"
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                  />
                </FormGroup>
              </Col>
            ) : null}
          </Row>
          <hr />
          <Row>
            <Col xs="12">
              <Label className={styles.sectionHead}>
                {t("job-configuration:label_job_notification_title")}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_job_notification_type")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  type="select"
                  name="notificationChannel"
                  value={form.notificationChannel}
                  onChange={(e) => handleFormChange(
                    e.target.name,
                    e.target.value,
                    "notificationChannel",
                  )}
                >
                  <option value="EMAIL">
                    {t("job-configuration:option_EMAIL")}
                  </option>
                  <option value="SMS">{t("job-configuration:option_SMS")}</option>
                  <option value="PUSH_NOTIFICATION">
                    {t("job-configuration:option_PUSH_NOTIFICATION")}
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <MultiSelectCheckBox
                  inputData={{
                    label: t("job-configuration:label_alert_status"), inputName: "alertStatus", attributes: { options: alertList },
                  }}
                  handleChange={handleFormChange}
                  stateData={form}
                />
              </FormGroup>
            </Col>
          </Row>
          {form.notificationChannel === "EMAIL" ? (
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("job-configuration:label_email_to")}
                  </Label>
                  <Input
                    className="formText"
                    placeholder=""
                    type="text"
                    name="notificationEmailTo"
                    value={form.notificationEmailTo}
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("job-configuration:label_email_cc")}
                  </Label>
                  <Input
                    className="formText"
                    placeholder=""
                    type="text"
                    name="notificationEmailCC"
                    value={form.notificationEmailCC}
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
          {form.notificationChannel === "SMS" ? (
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("job-configuration:label_sms_to")}
                  </Label>
                  <Input
                    className="formText"
                    placeholder=""
                    type="text"
                    name="notificationSMSTo"
                    value={form.notificationSMSTo}
                    onChange={(e) => handleFormChange(e.target.name, e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <div className={styles.areaActionRight}>
                <div>
                  <Button
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.secondaryColor,
                      "text-uppercase",
                      "text-truncate",
                      "btn-tertiary",
                      "ml-1",
                    )}
                    onClick={cancelJobEdit}
                  >
                    {t("common:button_cancel")}
                  </Button>
                  <Button
                    color="primary"
                    className={clsx(
                      styles.borderRadius2,
                      "text-uppercase",
                      "text-truncate",
                      "btn-tertiary",
                      "ml-1",
                    )}
                    onClick={saveJobDetails}
                  >
                    {t("common:button_update")}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

EditJob.propTypes = {
  cancelJobEdit: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  scopeList: PropTypes.objectOf(PropTypes.any).isRequired,
  locationList: PropTypes.objectOf(PropTypes.any).isRequired,
  alertList: PropTypes.objectOf(PropTypes.any).isRequired,
  saveJobDetails: PropTypes.func.isRequired,
  jobDetailsConfigData: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default EditJob;
