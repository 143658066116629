/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

/** ========= TAG COMPONENTS ========= */
import Collapse from "../../../common/core/Collapse";
import ListGroupItem from "../../../common/core/ListGroupItem";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

/** ========= SUB COMPONENT ========= */

/** ========= SVG IMAGE ICON ========= */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import PlusIcon from "../../../common/assets/images/svg/add-24px.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import DragIcon from "../../../common/assets/images/svg/dragIndicator.svg";
import RightArrowIcon from "../../../common/assets/images/svg/right-arrow.svg";

/** ========= CUSTOM COMPONENTS ========= */

/** ========= MODULE STYLES ========= */
import styles from "../css/MenuDetails.module.css";

const SubMenuItem = (props) => {
  const navigationItemTypes = constants.NAVIGATION_ITEM_TYPES;

  const {
    level, index, id, name, menus, type, collapseOpen, minimal, parentId, column, image, button, description, showEdit,
    addMenu, editMenu, deleteMenu,
    getLocalKey,
  } = props;

  /** local states */
  const [open, setOpen] = useState(false);
  const [hasMenus, setHasMenus] = useState(Boolean(menus));

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  useEffect(() => {
    setHasMenus(Boolean(menus));
  }, [menus]);

  useEffect(() => {
    setOpen(collapseOpen || minimal);
  }, [collapseOpen, minimal]);

  /**
   * This method is used to toggle the collapse
   */
  const toggleSubMenu = () => {
    setOpen((each) => !each);
  };

  const isCustomItem = type === navigationItemTypes.custom;
  let collapseClass = " ml-1 border-left border-light";
  if (!isCustomItem) {
    collapseClass = minimal ? `pl-1  ${collapseClass}` : `pl-4  ${collapseClass}`;
  }

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <>
            <div
              className={clsx(styles.subMenuItem, minimal ? "p-1" : "p-2", minimal ? styles.minimal : "", "mb-1 ", "border")}
              {...(minimal && provided.dragHandleProps)}
            >
              <span className="d-flex align-items-center overflow-hidden position-relative">
                {
                 !minimal && (
                 <span
                   className={clsx("px-1", styles.cursorMove)}
                   {...provided.dragHandleProps}
                 >
                   <img className={styles.icon} src={DragIcon} alt="" />
                 </span>
                 )
                }
                <div>
                  <div>
                    <span title={name} className={`text-truncate ${!minimal ? "pl-2" : "pl-1"}  text-body mr-auto d-flex flex-column`}>
                      <span className={` text-truncate ${!minimal ? "" : styles.smallText}  ${level === 2 ? "font-weight-bolder" : "font-weight-normal"} `}>
                        {name}
                      </span>
                      {
                    type && type !== "" && (
                    <span className={clsx("text-truncate  font-weight-normal text-secondary d-flex ", styles.superSmallText)}>
                        {type}
                    </span>
                    )
                  }
                    </span>
                  </div>
                  {
                  isCustomItem && (
                  <div>
                    {
                    image && image !== "" && (
                      <div className="w-100 text-center">
                        <img className="img-fluid" src={image} alt="" />
                      </div>
                    )
                  }
                    { /** there isn't any button filed in api */
                    button && button !== "" && false && (
                    <div className="w-100 text-center">
                      <button className="btn btn-sm btn-default " type="button">{ button}</button>
                    </div>
                    )
                  }
                    {
                    !minimal && description !== "" && (
                    <div className="pt-1 small w-100 text-center">
                      <p className="mb-1">{ description}</p>
                    </div>
                    )
                  }
                  </div>
                  )
                }
                </div>
                {
                    hasMenus && !minimal && collapseOpen && (
                      <span className={styles.hideInHover}>
                        <span className={clsx("px-1", "mx-1", "commonPointer", styles.collapseIcon)} onClick={toggleSubMenu} role="button" tabIndex={0} onKeyPress={() => { }}>
                          <img src={RightArrowIcon} className={clsx(styles.icon, open ? styles.openCollapse : styles.closeCollapse)} alt="" />
                        </span>
                      </span>
                    )
                  }
                <span className={clsx("d-flex", "justify-content-center", minimal ? "p-0" : "pr-1", styles.actions)}>
                  {
                    hasMenus && !minimal && (
                    <span>
                      <span className={clsx("px-1", "mx-1", "commonPointer", styles.collapseIcon)} onClick={toggleSubMenu} role="button" tabIndex={0} onKeyPress={() => { }}>
                        <img src={RightArrowIcon} className={clsx(styles.icon, open ? styles.openCollapse : styles.closeCollapse)} alt="" />
                      </span>
                    </span>
                    )
                    }
                  {((hasMenus && !minimal) || showEdit) && (
                  <span>
                    <span
                      disabled={false}
                      className="commonPointer d-flex mr-2"
                      onClick={(o) => editMenu({
                        id: parentId, columnId: id, level, column,
                      }, o)}
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => { }}
                    >
                      <img
                        className={styles.icon}
                        src={EditIcon}
                        alt="Edit"
                      />
                    </span>
                  </span>
                  )}
                  <span>
                    <span disabled={false} className="commonPointer" onClick={(o) => deleteMenu(id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                      <img
                        className={styles.icon}
                        src={DeleteIcon}
                        alt="Delete"
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            {
              hasMenus && (
              <Collapse isOpen={open} className={collapseClass}>
                <Droppable key={id} droppableId={`sub_menu_item_${getLocalKey()}`} type={`droppableSubItem_${getLocalKey()}`}>
                  {(providedIn) => (
                    <div
                      ref={providedIn.innerRef}
                      {...providedIn.droppableProps}
                    >
                      {/* ============== EACH SUB MAIN MENU ITEM S=================== */}
                      {
                        menus.map((menu, indexIn) => (
                          <SubMenuItem
                            parentId={parentId}
                            collapseOpen={collapseOpen}
                            minimal={minimal}
                            level={level + 1}
                            index={indexIn}
                            column={column}
                            id={menu.id}
                            key={menu.id}
                            name={menu.name}
                            menus={menu.menus}
                            type={menu.type}
                            editMenu={editMenu}
                            addMenu={addMenu}
                            deleteMenu={deleteMenu}
                            getLocalKey={getLocalKey}
                            // extra
                            image={menu.image}
                            button={menu.button}
                            description={menu.description}
                          />
                        ))
                      }
                      {/* ============== EACH MAIN MENU ITEM E=================== */}
                      {providedIn.placeholder}
                    </div>
                  )}
                </Droppable>
                {/* ============== ADD NEW SUB MAIN MENU  S=================== */}
                {
                  level < 1
                  && (
                  <div
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    onClick={(o) => addMenu({ id, level: (level + 1) }, o)}
                    className={clsx(styles.subMenuItem, minimal ? "p-1" : "p-2", minimal ? styles.minimal : "", "mb-1 ", "border", "commonPointer")}
                  >
                    <span className="d-flex align-items-center commonPointer">
                      <span className="px-1">
                        <img className={styles.icon} src={PlusIcon} alt="" />
                      </span>
                      <span className="pl-1 text-truncate font-weight-bold text-primary">
                        {t("site-navigation:label_add_menu_items")}
                        <span className="pl-1">{name}</span>
                      </span>
                    </span>
                  </div>
                  )
               }
                {/* ============== ADD NEW SUB MAIN MENU  E=================== */}
              </Collapse>
              )
            }
          </>
        </div>
      )}
    </Draggable>
  );
};

SubMenuItem.defaultProps = {
  menus: null,
  type: null,
  collapseOpen: true,
  minimal: false,
  button: "",
  image: null,
  description: "",
  showEdit: false,
};

SubMenuItem.propTypes = {
  minimal: PropTypes.bool,
  level: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  collapseOpen: PropTypes.bool,
  showEdit: PropTypes.bool,
  menus: PropTypes.arrayOf(PropTypes.any),
  addMenu: PropTypes.func.isRequired,
  editMenu: PropTypes.func.isRequired,
  deleteMenu: PropTypes.func.isRequired,
  getLocalKey: PropTypes.func.isRequired,
  // extra
  button: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

export default SubMenuItem;
