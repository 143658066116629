/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Widget configuration services
 *
 * @author Naseef O
 */

import httpApi from "./httpApi";

/**
 * This method is used to get all widget configurations
 *
 * @returns
 */
const getWidgetConfigurations = async (channelType) => {
  const response = await httpApi.get("/v1/widget-configurations", { params: { channelType } });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get widget configurations by pageType
 *
 * @returns
 */
const getWidgetConfigurationsByPageType = async (pageType, channelType) => {
  const response = await httpApi.get(`/v1/widget-configurations/page-type/${pageType}`, { params: { channelType } });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get widget configuration by widgetType
 *
 * @returns
 */
const getWidgetConfigurationByWidgetType = async (pageType, widgetType, channelType) => {
  const response = await httpApi.get(`/v1/widget-configurations/page-type/${pageType}/widget-type/${widgetType}`, { params: { channelType } });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

export {
  getWidgetConfigurations,
  getWidgetConfigurationsByPageType,
  getWidgetConfigurationByWidgetType,
};
