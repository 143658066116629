/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import SimpleBrandSelector from "../components/SimpleBrandSelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { getBrands } from "../../../api/brandServices";


const SimpleBrandSelectorContainer = ({
  onChange,
  name,
  value,
  options,
  disabled,
}) => {
  /** local states */
  const [brands, setBrands] = useState(options || []);

  useEffect(() => {
    const localOptions = (options && Array.isArray(options)) ? options : [];
    setBrands(localOptions);
  }, [options]);

  /**
   * This method is used to trigger onChange
   * @param {Event} event
   */
  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };

  /**
 * This method is used to handle typeahead change
 * @param {Event} event
 */
  const handleTypeaheadChange = (queryText) => {
    if (!queryText || (queryText && `${queryText}`.trim().length < 3)) {
      setBrands([]);
      return;
    }
    getBrands(queryText).then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        setBrands(response.data);
      }
    });
  };

  return (
    <SimpleBrandSelector
      brands={brands}
      selectedBrands={value}
      onChange={localOnChange}
      name={name}
      handleTypeaheadChange={handleTypeaheadChange}
      disabled={disabled}
    />
  );
};


SimpleBrandSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
};

SimpleBrandSelectorContainer.defaultProps = {
  name: "brand",
  options: undefined,
};

export default SimpleBrandSelectorContainer;
