/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";

/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= *//** ======== MODULE STYLES ========== */
import styles from "../css/ProductSelector.module.css";

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/MultiSelect";

/** ========= API SERVICE FUNCTIONS ========= */

const ProductSelector = ({
  name, handleSearchFiled, products, setProductSelected, selectedProducts,
}) => {
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["product"]);

  return (
    <Select
      className="formText"
      placeholder={t("product:placeholder_select_Products")}
      name={name}
      id="product"
      value={selectedProducts}
      onChange={setProductSelected}
      typeahead={handleSearchFiled}
      typeaheadText="Product name"
      typeaheadPlaceholder="Search ID, name"
      options={
              Array.isArray(products)
              && products.map(({ id: value, name: label }) => ({ value, label }))
             }
    />
  );
};

ProductSelector.propTypes = {
  // products
  name: PropTypes.string.isRequired,
  productList: PropTypes.arrayOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  setProductSelected: PropTypes.func.isRequired,
  // search product
  searchKeyword: PropTypes.string.isRequired,
  handleSearchFiled: PropTypes.func.isRequired,

};

export default ProductSelector;
