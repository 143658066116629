/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";

/** ========= TAG COMPONENTS ========= */
import Input from "../../core/Input";

/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */

/** ========= MODULE STYLES ========= */
import styles from "./styles.module.css";

const LocationSelector = (props) => {
  const {
    mapApi, map, toZoom,
    setPlace,
  } = props;

  /** local states */
  const searchInput = useRef(null);

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  /**
   * This function is used to clear search box
   */
  const clearSearchBox = () => {
    if (searchInput && searchInput.current) {
      searchInput.current.value = "";
    }
  };

  /**
   * This function ued to change input filed value
   * @param {Object} localSearchBox
   */
  const onPlacesChanged = (localSearchBox) => {
    if (localSearchBox) {
      const place = localSearchBox.getPlace();
      if (!place.geometry) return;
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(toZoom);
      }

      setPlace(place);
      if (searchInput && searchInput.current) {
        searchInput.current.blur();
      }
    }
  };


  useEffect(() => {
    const options = {
      // restrict your search to a specific type of result
      // types: ['geocode', 'address', 'establishment', '(regions)', '(cities)'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };

    if ((searchInput && searchInput.current)) {
      const localSearchBox = new mapApi.places.Autocomplete(searchInput.current, options);
      localSearchBox.addListener("place_changed", (o) => onPlacesChanged(localSearchBox, o));
      localSearchBox.bindTo("bounds", map);
      return () => {
        mapApi.event.clearInstanceListeners(localSearchBox);
      };
    }
  }, [searchInput]);

  return (
    <div className={styles.searchBoxWrapper}>
      <Input
        innerRef={searchInput}
        onFocus={clearSearchBox}
        placeholder="Search Box"
      />
      <svg className="bi bi-search" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
        <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
      </svg>
    </div>
  );
};

LocationSelector.defaultProps = {
  toZoom: 17,
};

LocationSelector.propTypes = {
  toZoom: PropTypes.number,
  mapApi: PropTypes.objectOf(PropTypes.any).isRequired,
  map: PropTypes.objectOf(PropTypes.any).isRequired,
  // functions
  setPlace: PropTypes.func.isRequired,
};

export default LocationSelector;
