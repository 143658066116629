// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n.ImageSelection_uploadImagePreview__3yPn1 {\n    max-width: 150px;\n    margin-right: 1rem;\n    position: relative;\n  }\n.ImageSelection_uploadImagePreviewIcon__3DeC6 {\n    max-width: 150px;\n}\n.ImageSelection_uploadImagePreviewClose__3r3zl{\n  display: none;\n}\n.ImageSelection_uploadImagePreview__3yPn1:hover .ImageSelection_uploadImagePreviewClose__3r3zl {\n  display: flex;\n  border-radius: 50%;\n  position: absolute;\n  top: -4px;\n  right: -4px;\n  background: #b3b3b3;\n  font-size: 11px;\n  padding: 0px 5px;\n  font-weight: 600;\n  cursor: pointer;\n  z-index: 3;\n}\n\n.ImageSelection_uploadImagePreview__3yPn1:last-child {\n      margin-right: 0;\n}\n\n.ImageSelection_uploadImagePreview__3yPn1 .ImageSelection_hoverImageUpload__38W5x {\n  z-index: 2;\n  cursor: pointer;\n  position: absolute;\n    top: 0;\n    left: 0;\n    display: none;\n}\n\n.ImageSelection_uploadImagePreview__3yPn1 .ImageSelection_hoverImageUpload__38W5x label {\n  border: 0;\n}\n\n.ImageSelection_uploadImagePreview__3yPn1 .ImageSelection_hoverImageUpload__38W5x label img {\n  opacity: 0.6;\n  border: none;\n}\n\n.ImageSelection_uploadImagePreview__3yPn1:hover .ImageSelection_hoverImageUpload__38W5x {\n  display: flex;\n}", ""]);
// Exports
exports.locals = {
	"uploadImagePreview": "ImageSelection_uploadImagePreview__3yPn1",
	"uploadImagePreviewIcon": "ImageSelection_uploadImagePreviewIcon__3DeC6",
	"uploadImagePreviewClose": "ImageSelection_uploadImagePreviewClose__3r3zl",
	"hoverImageUpload": "ImageSelection_hoverImageUpload__38W5x"
};
module.exports = exports;
