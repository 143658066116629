/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import styles from "../css/DocumentationGuide.module.css";

const Merchandising = () => (
  <>
    <Row>
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Row className="pageHeader  common-page-card">
          <Col xs="12" md="6" className="text-truncate">
            <span className={styles.pageHeader}>Merchandising Your Site</span>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Content Management
              <br />
              <br />
            </span>
            <span className={styles.pageSubHeading2}>
              Changing the layout settings of your storefront pages
              <br />
            </span>
            <div className={styles.pageText}>
              <b>
                Login > Dashboard > Merchandising > Content Management > Layout
                Setting
                <br />
              </b>
              <br />
              This screen allows you to manage and modify your Homepage, PLP and
              CLP.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image9.png"
                className={styles.imgSize}
              />
            </div>
            <div className={styles.pageText}>
              To change the layout, choose the page you desire from the page
              selection drop down - Home, PLP (Product listing page) or CLP
              (Category landing page) In the next section, you will see the{" "}
              <b>Layout options</b> available for the pages you selected. The
              pre-loaded layout settings appear here. For adding more layouts,
              please contact your system administrator. This would involve
              development effort. The layout images displayed here are static
              images for ease in comprehending how the layout components are
              sequenced.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading3}>
              <b>Current Homepage </b>
            </span>
            <div className={styles.pageText}>
              A view of the current page setting for comparison against the
              selected option is available.
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading2}>
              Changing the static pages listed on the storefront
              <br />
            </span>
            <div className={styles.pageText}>
              Login > Dashboard > Merchandising > Content Management > Static
              Pages
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image21.png"
                className={styles.imgSize}
              />
            </div>
            <div className={styles.pageText}>
              <br />
              <br />
              If you have to make changes below sections in “About Us”{" "}
              <ul>
                <li>Contact us</li>
                <li>Who we are</li>
                <li>Terms and Conditions</li>
                <li>Privacy & Security</li>
                <li>Consumer Rights</li>
              </ul>{" "}
              Access Merchandising> Content Management > Static Pages.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image27.png"
                className={styles.imgSize}
              />
            </div>
            <div className={styles.pageText}>
              Click the edit button against the corresponding pages. The system
              will take you to the customization page.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image20.png"
                className={styles.imgSize}
              />
            </div>
            <div className={styles.pageText}>
              You can edit the text and redirect pages here. Adding new links to
              the static pages, and creating a static page are not included in
              the product. Discuss with your system administrator or product
              manager for the same.
              <br />
              <br />
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading2}>
              Making Customizations to the Web Application
              <br />
            </span>
            <div className={styles.pageText}>
              As a merchandiser, you can make changes to how the storefront
              looks.
            </div>
            <div className={styles.pageText}>
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations.
              </b>
              <br />
              <br />
            </div>
            <div className={styles.pageText}>
              This page enables the merchandiser to customize the storefront
              pages - Homepage, PLP, CLP and PDP. This section is used to change
              the texts/icons/images/products that appear in respective pages.
              <br />
              <br />
            </div>
            <div className={styles.pageText}>
              <span>
                <img
                  src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image67.png"
                  className={styles.iconSize}
                />
              </span>
              - The edit Icon. You can edit on any feature by clicking on this
              icon.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image15.png"
                className={styles.imgSize}
              />
              <br />
            </div>
            <div className={styles.pageText}>
              Use the page selection drop down to choose the page you want to
              customize.
              <ul>
                <li>Homepage</li>
                <li>PLP – Product Listing Page</li>
                <li>CLP – Category Listing Page</li>
                <li>PDP – Product Detail Page</li>
              </ul>
              The function remains the same for all pages.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Changing the Homepage</b>
              <br />
            </span>
            <div className={styles.pageText}>
              Ensure that the drop down that reads “Page Selection”, shows
              “Home”. Home is a default value in the dropdown.
              <br /> You can edit the following sections in the page.
              <ul>
                <li>Carousel Banner</li>
                <li>Quick Links</li>
                <li>Single Banner</li>
                <li>Explore Our Collections</li>
                <li>Featured Category</li>
                <li>Shop Value Icon</li>
                <li>Page Header / Page Footer / Featured Products Group</li>
                <li>Header Tape </li>
              </ul>
              Edit the name of each section: <br />
              <br />
              If your organization is using a different term for the carousel
              and the merchandising team wants to put that name in the
              administration section for future reference, you can edit the name
              of this section, by clicking the edit icon next to the subsection
              name.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Changing the Carousel Banner images</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              Changing the images on the carousel banner of the homepage.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image33.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations > Carousel banner
                <br />
                <br />
                <img
                  src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image39.png"
                  className={styles.imgSize}
                />
                <br />
                <br />
                The maximum recommended number of carousel banner images is 4
                <br />
                <br />
              </b>
              The carousel image table is explained below. <br />
              <b>Order</b> : The order in which the categories appear on the
              site. <br />
              <b>Preview</b> : The preview of the picture corresponding to the
              category. <br />
              <b>Header</b> : The name of the category. <br />
              <b>Start Date & End Date</b> : You can set the time period during
              which you want the image to be featured.
              <br />
              <b>Edit</b> : The Edit Option, this leads to the below screen.{" "}
              <br />
              <b>Delete</b> : To delete the image. <br />
              <b>Status</b> : To change the status.
              <br /> Once you click on the edit option, you are led to the
              screen below: <br />
              <br />
              The carousel image can be edited or deleted by clicking the edit
              or delete option against each image.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image7.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <ul>
                <li>
                  <b>Header</b> : Enter Category Name <br />
                </li>
                <li>
                  <b>Body</b> : Enter a single liner about the product. This
                  will not be visible on the storefront <br />
                </li>
                <li>
                  <b>Link</b> : Enter the link to the corresponding page. <br />
                </li>
                <li>
                  <b>Call to Action</b> : There are two options here. <br />
                  <ul>
                    <li>
                      <b>Internal</b> : Selecting Internal will prefix the
                      internal storefront url to the Link input field. <br />
                    </li>
                    <li>
                      <b>External</b> : Selecting external helps you configure a
                      redirect link to an external website. <br />
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Description</b> : Space for a brief description about the
                  product
                </li>
                <li>
                  <b>Description</b> : Space for a brief description about the
                  product
                </li>
                <li>
                  <b>Start & End Date</b> : You can set the time period during
                  which you want the category to be featured. This operation
                  will be available in a future release of the product.
                </li>
                <li>
                  <b>Media</b> : Enter a product or marketing image. Different
                  size options are available for different types of screens.
                  Large - for Desktops ( recommended 851x317, Crop 1:3) and
                  laptops, Medium (Crop 1:3) for tablets, small (crop: 1:3) for
                  mobile phones and extra small (1:1 crop). The uploaded images
                  are cropped by the default cropper. High resolution images
                  which conform to the cropper aspect ratio recommended.
                </li>
              </ul>
              <b>
                The function of customization is similar for all the
                subcategories.
              </b>
            </div>
            <br />
            <br />
            <span className={styles.pageSubHeading2}>
              <b>Changing the Quick links</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>Preview on storefront</b>
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image12.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations > Quick links
              </b>
              <br />
              <br />
              The text in the header column in each row is displayed under the
              respective icons. The recommended maximum number of icons is 6.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image5.png"
                className={styles.imgSize}
              />
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Changing the single banner</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>Preview on storefront</b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image54.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations > Single banner
              </b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image75.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              You can select different sizes for the banner inside the edit
              option under “media”.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image47.png"
                className={styles.imgSize}
              />
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Changing the Explore Our collections display</b>
              <br />
            </span>
            <div className={styles.pageText}>
              <b>Preview on storefront</b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image35.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations > Explore our collections
              </b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image40.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Upon clicking the edit icon against each row, you will be taken to
              another screen where you will be able to edit the headings and the
              text. The recommended maximum number of icons is 3.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image19.png"
                className={styles.imgSize}
              />
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Changing Featured Categories</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>Preview on storefront</b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image57.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations > Featured categories
              </b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image15.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The recommended maximum number of icons is 4.
            </div>
            <br />
            <br />
            <span className={styles.pageSubHeading2}>
              <b>Changing the Shop Value Icons</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>Preview on storefront</b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image66.png"
                className={styles.imgSize}
              />{" "}
              <br />
              <br />
              You can customize - Add, edit or delete using the following screen
              in retail admin.
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations > Featured categories
              </b>
              <br />
              <br />
              The recommended maximum number of icons is 3.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image4.png"
                className={styles.imgSize}
              />
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Customizing Page Header, Page Footer & Featured Products</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>Preview on storefront</b>
              <br />
              <br />{" "}
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image1.png"
                className={styles.imgSize}
              />
              <br />
              <br />{" "}
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations>Page Header/Page Footer/Featured products
              </b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image73.png"
                className={styles.imgSize}
              />{" "}
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Editing Header Tapes</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>Preview on storefront</b>
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations>Header Tape
              </b>
              <br />
              This header that appears when the size of the screen is reduced
              (to fit a mobile or tablet on responsive views)
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image51.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image64.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The content given under the header is displayed on the site.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>
                Customizing the Product Listing, Category Listing and Product
                Details pages.
              </b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>Customizing PLP (Product Listing Page)</b>
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations
              </b>
              <br />
              On the drop down that reads “Page Selection”, select “PLP”. (Home
              is a default value in the dropdown)
              <br />
              This page shows you the products listed under one individual
              category.
              <br />
              <br />{" "}
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image16.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              To edit the product listing pages, select PLP from the dropdown
              under “Page Selection” and select the category in “Select a
              Category”.
              <br />
              <br />{" "}
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image41.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Select a Category - These categories and sub categories in this
              drop down correspond directly to the above mentioned space in the
              web page.
              <br />
              The categories included in this screen can be viewed as you scroll
              down, and they include
              <ul>
                <li>Empty Product List</li>
                <li>Single Banner</li>
                <li>Shop Value Icons.</li>
                <li>PLP Banner </li>
                <li>Filter</li>
                <li>Featured Product</li>
                <li>SEO info</li>
                <li>Product Listing </li>
                <li>Page Header</li>
                <li>Page Footer</li>
                <li>Header Tape</li>
                <li>Sibling Category Filter</li>
              </ul>
              <br />
              In case you need to do changes to the products featured in the PLP
              page
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image68.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations
                <br />
              </b>
              Go to Page Customization > Select “PLP” in Page Selection and
              “Make Up” (as an example) in Select a Category, scroll down to
              reach <b>Featured Products</b>. <br />
              <br />
              <br />{" "}
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image29.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              You can view the currently featured products in PLP. By selecting
              Add Product, you can add or delete the featured products in PLP
              here. <br />
              <br />
              <b>To Add a product</b> <br />
              To add a product here, click on the “ADD PRODUCT” button. You are
              led to the page below.
              <br />
              <br />{" "}
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image79.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The attributes here are
              <br />
              <ul>
                <li>
                  <b>Header</b> : Enter Category Name <br />
                </li>
                <li>
                  <b>Body</b> : Enter a single liner about the product. This
                  will not be visible on the storefront <br />
                </li>
                <li>
                  <b>Link</b> :Enter the link to the corresponding page. <br />
                </li>
                <li>
                  <b>Call to Action</b>:
                  <ul>
                    <li>
                      <b>Internal</b> : Selecting Internal will prefix the
                      internal storefront url to the Link input field. <br />
                    </li>
                    <li>
                      <b>External</b> : Selecting external helps you configure a
                      redirect link to an external website. Internal prefixes
                      the internal storefront url to the Link input field.
                      <br />
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Description</b> : Space for a brief description about the
                  product <br />
                </li>
                <li>
                  <b>Start & End dates</b> : You can set the time period during
                  which you want the category to be featured. This operation
                  will be available in a future release of the product. <br />
                </li>
                <li>
                  <b>Media</b> : Enter a product or marketing image. Different
                  size options are available for different types of screens.
                  Large - for Desktops ( recommended 851x317, Crop 1:3) and
                  laptops, Medium (Crop 1:3) for tablets, small (crop: 1:3) for
                  mobile phones and extra small (1:1 crop). The uploaded images
                  are cropped by the default cropper. High resolution images
                  which conform to the cropper aspect ratio recommended.
                </li>
              </ul>
              Click on <b>“SAVE” </b>
              to save the details.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Customizing CLP (Category Landing Page)</b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image50.png"
                className={styles.imgSize}
              />
              <br />
              <br />
            </span>
            <div className={styles.pageText}>
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customization
              </b>
              <br />
              Ensure that the drop down that reads “Page Selection” shows “CLP”.
              (Home is a default value in the dropdown)
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image24.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              To edit the Categories listing pages, select CLP from the dropdown
              under “Page Selection” and select the category in “Select a
              Category”.
              <br />
              The Preview Button can be used to preview the page design while
              editing.
              <br />
              The customizable categories included in this screen can be viewed
              as you scroll down, and they include
              <ol>
                <li>Single banner</li>
                <li>Top New brands</li>
                <li>Must Try</li>
                <li>Recommended for you</li>
                <li>SEO information</li>
              </ol>
            </div>
            <br />
            <br />
            <span className={styles.pageSubHeading2}>
              <b>Customizing PDP (Product Details Page)</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations
              </b>
              <br />
              Ensure that the drop down that reads “Page Selection”, shows
              “PDP”. Home is a default value in the dropdown.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image44.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Use the Preview Button to get a quick view of the page design
              while editing
              <br />
              The categories included in this screen can be viewed as you scroll
              down, and they include
              <ol>
                <li>Promotions </li>
                <li>Product Details</li>
                <li>Return and Refunds</li>
                <li>Product Details</li>
                <li>Page Header</li>
                <li>Page Footer</li>
                <li>Recommended for you.</li>
              </ol>
              The customization process follows the same flow as described in
              the sections on customizing homepage or PLP.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Customizing BLP (Brand Landing Page)</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>
                Login > Dashboard > Merchandising > Content Management > Web App
                Customizations
              </b>
              <br />
              Ensure that the drop down that reads “Page Selection”, shows
              “BLP”. Home is a default value in the dropdown.
              <br />
              <b>Preview</b> of BLP on storefront :
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image61.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              In Footer the “ Top Brands “ appear. Once you click on a brand you
              are taken to the brands page.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image31.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The categories included in this screen can be viewed as you scroll
              down, and they include
              <ul>
                <li>Bread Crumbs</li>
                <li>Shop Value Icons</li>
                <li>Brand Banner</li>
                <li>Brand Title</li>
                <li>Recommended for you</li>
                <li>Must Buy</li>
                <li>Page Footer</li>
                <li>Page Header</li>
                <li>Category list</li>
                <li>Header Tape</li>
                <li>SEO Information</li>
              </ul>
              The customization process follows the same flow as described in
              the sections on customizing homepage or PLP.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Making Customizations to the Mobile Application</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              The mobile pages corresponding to the web pages can also be
              customized to a large extent using the retail admin functionality.
              <br />
              To manage the mobile app customizations,
              <br />
              <b>
                Login > Dashboard > Merchandising > Content Management > Mobile
                App Customizations
              </b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image28.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              You can change the display settings of the mobile app using this
              screen. The categories under this screen are
              <ul>
                <li>Featured Categories</li>
                <li>Shop Value Icons</li>
                <li>Single Banner</li>
                <li>Quick Links</li>
                <li>Explore Our Collections</li>
                <li>Carousel Banner</li>
                <li>Page Footer</li>
                <li>Page Header</li>
                <li>Featured Products Group</li>
              </ul>
              The mobile app customization screens follow the same process -
              add, edit and delete, similar to the web application.
              <br />
              The images uploaded for the mobile screens are cropped for
              appropriate aspect ratio. All images should be 1:1 aspect ratio
              except single banner (~3:2) resolution ref 268*189, High
              resolution images to be uploaded.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Changing Site Navigations</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>
                Login > Dashboard > Merchandising > Site Navigations >
                Navigation Management
              </b>
              <br />
              You can change the responsive header, footer and default web
              header here.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image25.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              To add a new menu, click on the <b>“Add New”</b> button. You can
              also edit and delete the menus.
              <br />
              The “Add New” or “Edit” buttons will take you to a new screen.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image77.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Here you can add the name and position of the menu on the site.{" "}
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading}>
              <b>Managing Catalogues</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              It is to be noted that these operations on categories will depend
              on the settings for each brand. While edit, add and delete
              categories will be applicable for some brands, these screens may
              just be “View” screens for others, where the category management
              will be done from other back end systems like Product Information
              Management or Catalogue Management systems. To know more about the
              same, please contact your system administrator or product owner.
            </div>
            <br />
            <br />
            <span className={styles.pageSubHeading2}>
              <b>Managing categories</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>
                Login> Dashboard>Merchandising> Catalog Management > Category
              </b>
              <br />
              As a merchandiser you can add, edit, or delete the categories
              using this screen.
              <br />
              To add a category - Use the “Add Category” button
              <br />
              To edit a category - Use the “Edit” icon
              <br />
              To delete a category - Use the “Delete/trash” icon
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image26.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              To edit or add categories click on the edit icon or the ADD
              CATEGORY button, you are led to the details page.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image36.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The fields included in “details page” are
              <ul>
                <li>Name</li>
                <li>Short Description</li>
                <li>Long description</li>
                <li>Start and end Date </li>
                <li>
                  Parent Category - to map the product category to a main (or
                  parent category). For example, Denims under trousers or
                  lipstick under make up.
                </li>
                <li>Status </li>
                <li>Banner image</li>
                <li>
                  Sub Category - to enter the sub categories associated with the
                  new category.
                </li>
              </ul>
              To add, edit or delete products in the category, move to the
              “product page”.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image38.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              You can select the products to be displayed under the category.
              <br />
              Click on the”UPDATE CHANGES” button to save the changes.
              <br />
              To add a category click on the “ADD CATEGORY “ Button. You are led
              to this page.
              <br />
              The attributed present here are
              <ul>
                <li>Name</li>
                <li>Short Description</li>
                <li>Long Description</li>
                <li>Start and End Date</li>
                <li>
                  Parent Category - The drop drop down will list the main
                  categories and subcategories under it. You can easily select
                  and place your category.{" "}
                </li>
                <li>Status</li>
                <li>Sub Categories - to enter the sub categories</li>
              </ul>
              Click on “SAVE “ to save the data.
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              <b>Managing Products</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              To manage products,
              <b>Login> Dashboard>Merchandising>Products> Product Management</b>
              <br />
              As a merchandiser you can Add, Edit, Delete, Enable or Disable the
              status, and Search for a product using this screen.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image3.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              To edit click on the edit icon. You are led to the details page
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image74.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The attributes in this page are
              <ul>
                <li>Name</li>
                <li>Product Type</li>
                <li>Active</li>
                <li>Description </li>
                <li>Long Description</li>
                <li>Start and End Date</li>
                <li>Landing Page Base URL</li>
                <li>Banner Images</li>
                <li>
                  Category - You can list the product under multiple categories.
                </li>
                <li>
                  Variants - You can add / edit / delete variants using a button{" "}
                </li>
                <li>
                  Related Product - You can search products and add all related
                  products to appear on this page.
                </li>
                <li>
                  External Attributes - You can view the attributes pertaining
                  to a product here.
                </li>
              </ul>
              <br />
              <b>Adding a Product</b>
              <br />
              To add a product click on the
              <b> “ADD PRODUCT” </b>
              button. You are led to the details screen.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image45.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The attributed to be entered here are -
              <ul>
                <li>Name</li>
                <li>Product Type - eg: Apparel, Cosmetics etc..</li>
                <li>
                  Active - whether the product is to be listed on the pages{" "}
                </li>
                <li>Category</li>
                <li>Variant - example, different sizes</li>
                <li>SKU Name</li>
                <li>Colour</li>
                <li>Size</li>
                <li>List Price</li>
                <li>Sale Price</li>
                <li>Onsale</li>
                <li>Quantity of the product - Quantity of product available</li>
              </ul>
            </div>
            <br />
            <br />
            <span className={styles.pageSubHeading2}>
              <b>Managing Pricebook</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>
                Login> Dashboard> Merchandising> Catalog Management> Pricebook.
                <br />
              </b>
              <br />
              To view prices go to the Price book management screen.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image60.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              You can select the pricebook from the drop down. The price book
              data is imported through the backend. You can also search a
              pricebook by inputting the Pricebook ID.
              <br />
              The attributes in the list are -
              <ul>
                <li>Price Book ID</li>
                <li>
                  SKU ID - Internal Stock Keeping ID (This is autogenerated by
                  the application)
                </li>
                <li>
                  External SKU ID (If the SKUs are coming from a different
                  system, the original SKU ID received from the third party
                  system comes here)
                </li>
                <li>Product ID</li>
                <li>List Price</li>
                <li>Sale Price</li>
                <li>Updated Date</li>
              </ul>
              You can export the file as .xlsx or .csv by selecting from the
              <b> “Export As” </b>
              dropdown.
            </div>
            <br />
            <br />
            <span className={styles.pageSubHeading2}>
              <b>Managing Inventory</b>
              <br />
            </span>{" "}
            <div className={styles.pageText}>
              <b>
                Login> Dashboard> Merchandising > Catalog management> Inventory
                <br />
              </b>
              <br />
              To manage your inventory go to the inventory Management.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image14.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              You can select the store and view the inventory from the dropdown
              or search for a store by inputting the store ID.
              <br />
              The attributes in the list are -
              <ul>
                <li>External SKU ID</li>
                <li>SKU ID</li>
                <li>Product ID</li>
                <li>Available Stock</li>
                <li>Reservation - For reserving a set of</li>
                <li>ATP - Available to Purchase</li>
                <li>Status</li>
                <li>Created / Updated dates.</li>
              </ul>
              The pagination keys can be used to move through the report. This
              report can be exported as .xlsx / .csv. dropdown.
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default Merchandising;
