/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Role Management
 *
 * @author Naseef O
 *
 */
import React from "react";
import clsx from "clsx";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/RoleManagement.module.css";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import InputGroup from "../../../common/core/InputGroup";
import Input from "../../../common/core/Input";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import SearchIcon from "../../../common/assets/images/svg/search.svg";

const customRows = [
  {
    label: "Role",
    value: "",
    path: "role",
    transform: "",
  },
  {
    label: "Users",
    value: "",
    path: "users",
    transform: "",
  },
];

const customizationData = [
  {
    users: "1",
    role: "Super Admin ",
  },
  {
    users: "2",
    role: "Parent Retailer",
  },
  {
    users: "10",
    role: "Retailer Admin",
  },
  {
    users: "10",
    role: "Brand Admin",
  },
];

const RoleManagement = () => (
  <Row>
    <Col xs="12">
      <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
        <Col xs="8">
          <span className="pageText">Manage Roles</span>
        </Col>
        <Col xs="4">
          <Button className={clsx(styles.buttonNewUser, " bg-color-tertiary-main")}>New Role</Button>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="12" className="p-0">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail" />
                <div className={styles.areaActionRight}>
                  <div>
                    <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                      <Input placeholder="Search category" />
                      <InputGroupAddon
                        addonType="append"
                        className="commonPointer bg-color-tertiary-main"
                      >
                        <img
                          src={SearchIcon}
                          alt="Search"
                          className={styles.icon}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                    {" "}
                  </div>
                </div>
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, " common-page-card")}>
        <Col xs="12">
          <div className="custom-table ">
            <CustomizationTable
              customRows={customRows}
              customizationData={customizationData}
              isStatusEnable
              isDeleteEnable
              isEditEnable
            />
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default RoleManagement;
