// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Aardra S\n */\n\n.AddStore_block2__2YWxo {\n    padding-top: 20px;\n    padding-bottom: 20px;\n  } \n  \n  .AddStore_borderRadius2__1_-MN {\n    border-radius: 0.125rem !important;\n  }\n  \n  .AddStore_secondaryColor__3qbfK {\n    background-color: #757575 !important;\n    border-color: #757575 !important;\n  }\n  \n  .AddStore_primaryColor__10Z3- {\n    background: #17a2b8 !important;\n    border-color: #17a2b8 !important;\n  }\n  \n  .AddStore_btn__1dR50 {\n    min-width: 7rem;\n  }\n  ", ""]);
// Exports
exports.locals = {
	"block2": "AddStore_block2__2YWxo",
	"borderRadius2": "AddStore_borderRadius2__1_-MN",
	"secondaryColor": "AddStore_secondaryColor__3qbfK",
	"primaryColor": "AddStore_primaryColor__10Z3-",
	"btn": "AddStore_btn__1dR50"
};
module.exports = exports;
