/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Page Customization Container
 *
 * @author Jithendra RK
 *
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import imageUtils from "../../../common/utils/imageUtils";
import BannerContainerEditor from "../components/BannerContentEditor";
import validateForm from "../../../common/utils/validateForm";
import { getStaticContentByCode } from "../../../api/staticContentService";
import { updatePageContent, getPageContentByTypeAndConfigId } from "../../../api/pageContentServices";
import { getWidgetConfigurationByWidgetType } from "../../../api/widgetConfigurationsServices";

const BannerContentEditorContainer = (props) => {

    const {
        widgetType,
        configId,
        selectedPage,
        widgetConfig,
        widgetContent,
        channel,
        categoryId,
        locale,
        modal,
        setModal,
        toggle,
        setCustomBannerFlag
    } = props;

    const pageType=selectedPage,index=0;
    const [modalOpen, setModalOpen] = useState(false);
    const [linkType, setLinkType] = useState("Internal");
    const [isUploading, setIsUploading] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [content, setContent] = useState({
      imageUrl: "",
      fileName: "",
      link: "",
      status: false,
    });

    const [media, setMedia] = useState({
        largeMediaUrl: "",
        defaultMediaUrl: "",
        smallMediaUrl: "",
        mediaThumbnailUrl: "",
    });
    
    
    const [selectedMediaType, setMediaType] = useState("");
    const [mediaOption, setMediaOption] = useState("Banner");

    const validator = validateForm();
    const {
        handleSubmit, register, errors, clearError, setError,
      } = validator;

    /**
    * This method is used to handle change text
    *
    * @param {String} key
    * @param {Object} e
    */
    const handleChange = (key, e) => {
        if ((key === "startDate") || (key === "endDate")) {
        setContent({ ...content, [key]: e });
        } else {
        setContent({ ...content, [key]: e.target.value });
        }
    };

    /**
     * This method is used to handle change file
     *
     * @param {*} event
     */
    const handleChangeFile = async (event, type) => {
        const file = event.target.files[0];
        if (file && file.size > 5242880) {
        alert("Image size must under 5mb!");
        } else {
        const url = await imageUtils.readURL(file);
        setContent({ ...content, imageUrl: url });
        setMediaType(type);
        setModalOpen(true);
        }
    };

    /**
     * This method is used to handle upload image
     *
     * @param {*} url
     */
    const handleUploadImage = (data) => {
        if (data) {
        setIsUploading(false);
        setModalOpen(false);
        setMedia({ ...media, [selectedMediaType]: data.largeMediaUrl });
        } else {
        setIsUploading(false);
        setModalOpen(false);
        setContent({ ...content, imageUrl: "" });
        }
    };

    /**
     * This method is used to construct content
     *
     * @param {*} mediaData
     */
    const constructContent = (mediaData) => ({
        media: mediaData,
    });

    /**
     * This method is used to construct link content
     *
     */
    const constructLinkContent = () => {
        const linkContent = {
        type: linkType,
        url: content.link,
        name: content.altText,
        };
        if (content.paramKey && content.paramValue) {
        const newParams = {
            [content.paramKey]: content.paramValue,
        };
        linkContent.params = newParams;
        }
        return linkContent;
    };

    /**
     * This method is used to construct and validate media
     *
     */
    const constructAndValidateMedia = () => {
        let validMediaIn = true;
        const mediaIn = { ...media };
        if (widgetConfig
        && widgetConfig.propertyConfig
        && widgetConfig.propertyConfig.bannerContent) {
        const { bannerContent } = widgetConfig.propertyConfig;
        let newAspectRatio = { ...widgetConfig.propertyConfig.bannerContent.aspectRatio };
        Object.keys(newAspectRatio).map((k) => {
            if (!media[k]) {
            if (widgetConfig.responsive) {
                validMediaIn = false;
                setError("media", "notMatch", "Please upload responsive images");
            }
            }
            return validMediaIn;
        });
        }
        return { validMediaIn, mediaIn };
    };

      /**
   * This method is used to construct widget content
   *
   * @param {*} newContent
   */
  const constructWidgetContent = (newBannerContent) => {
    let newWidgetContent = {};
    let newConfigId = configId;
    if (pageType === "GLOBAL") {
      newConfigId = "GLOBAL";
    }
    if (!widgetContent) {
      newWidgetContent.widgetType = widgetType;
      newWidgetContent.contents = [];
      newWidgetContent.categoryId = categoryId;
    } else {
      newWidgetContent = { ...widgetContent };
    }
    const { contents } = newWidgetContent;
    let newContents = [];
    if (Array.isArray(contents) && contents.length > 0) {
      newContents = contents;
    }
    if(!newWidgetContent.bannerContent) {
      newWidgetContent.bannerContent = {};
    }
    newWidgetContent.bannerContent.link = newBannerContent.link;
    newWidgetContent.bannerContent.media = newBannerContent.media;
    newWidgetContent.bannerContent.active=true;
    newWidgetContent.bannerContent.navigable=true;
    newWidgetContent.configId = newConfigId;
    newWidgetContent.locale = locale;
    return { newWidgetContent};
  };


    /**
     * This method is used to handle submit
     *
     */
    const submitForm = async (value) => {
        clearError(["media"]);
        let validMedia = true;
        const { validMediaIn, mediaIn } = constructAndValidateMedia();
        validMedia = validMediaIn;
        if (value && validMedia) {
        // const newContent = constructContent(mediaIn);
        // const linkContent = constructLinkContent();
        // newContent.link = linkContent;
        const newBannerContent = constructBannerContent(mediaIn);

        const {newWidgetContent } = constructWidgetContent(newBannerContent);
        if (validMedia) {
             await updatePageContent(newWidgetContent, channel, locale).then((response) => {
             if (response && response.success) {
                setCustomBannerFlag(true);
                toggle(!modal)
             }
          });
        }
      }
    };

    const constructBannerContent = (mediaData) => {
      const linkContent = {
        type: linkType,
        url: content.link,
        name: content.altText,
      };
      const newBannerContent = {
        media: mediaData,
        link:linkContent
      }
      
      return newBannerContent;
    } 

    const handleCloseModal = () => {
        setContent({ ...content, imageUrl: "" });
        setMedia({ ...media, [selectedMediaType]: "" });
        setModalOpen(false);
    };

    /**
     * This method is used to remove an image
     *
     * @param {*} type
     */
    const handleRemoveImg = (type) => {
        setMedia({ ...media, [type]: "" });
    };

    useEffect(() => {
        const data = widgetContent && widgetContent.bannerContent;
        if (data && widgetContent) {
          setIsEdit(true);
          const basicContent = {
            ...content,
            link: data && data.link && data.link.url,
          };
          setContent({ ...basicContent });
          if (data.link && data.link.params) {
            const paramTypeIn = Object.keys(data.link.params)[0];
            setContent({
              ...basicContent,
              paramKey: paramTypeIn,
              paramValue: data.link && data.link.params[paramTypeIn],
    
            });
          }
          setLinkType(data.link ? data.link.type : "Internal");
          setMedia({
            largeMediaUrl: data.media
              && data.media.largeMediaUrl ? data.media.largeMediaUrl : "",
            defaultMediaUrl: data.media
              && data.media.defaultMediaUrl ? data.media.defaultMediaUrl : "",
            smallMediaUrl: data.media
              && data.media.smallMediaUrl ? data.media.smallMediaUrl : "",
            mediaThumbnailUrl: data.media
              && data.media.mediaThumbnailUrl ? data.media.mediaThumbnailUrl : "",
          });
          setCustomBannerFlag(false);
        }
      }, [widgetContent]);
    

    const getBreakPoints = (type) => {
        const breakPoints = {
          largeMediaUrl: "Large",
          defaultMediaUrl: "Medium",
          smallMediaUrl: "Small",
          mediaThumbnailUrl: "Extra small",
        };
        return breakPoints[type];
      };

    return (
        <BannerContainerEditor 
            widgetType={widgetType}
            configId={configId}
            categoryId={categoryId}
            selectedPage={selectedPage}
            widgetConfig={widgetConfig}
            channel={channel}
            locale={locale}
            getBreakPoints={getBreakPoints}
            linkType={linkType}
            setLinkType={setLinkType}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleUploadImage={handleUploadImage}
            handleChangeFile={handleChangeFile}
            content={content}
            handleChange={handleChange}
            submitForm={submitForm}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            handleCloseModal={handleCloseModal}
            media={media}
            selectedMediaType={selectedMediaType}
            handleRemoveImg={handleRemoveImg}
            pageType={pageType}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            modal={modal}
            setModal={setModal} 
            toggle={toggle}   
            isEdit={isEdit}                              
        />
    ); 

}



BannerContentEditorContainer.propTypes = {
    widgetType: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    categoryId: PropTypes.string.isRequired,
    selectedPage: PropTypes.string.isRequired,
    widgetConfig:PropTypes.objectOf(PropTypes.any).isRequired,
    channel: PropTypes.string.isRequired,
    setCustomBannerFlag:PropTypes.func.isRequired,
}


export default BannerContentEditorContainer;