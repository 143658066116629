/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * common utils
 *
 * @author Naseef O
 *
 */

import queryString from "query-string";

/**
 *This method is used to get query params from location
 *
 * @param {Object} location
 */
const getQueryParams = (location) => queryString.parse(location.search);

/**
 * This method is used to get mindate
 *
 * @param {*} currentDate
 * @param {*} date
 */
const minDate = (currentDate, date) => currentDate.isAfter(date);

/**
 * This method is used to get maxDate
 *
 * @param {*} currentDate
 * @param {*} date
 */
const maxDate = (currentDate, date) => currentDate.isBefore(date);

/**
 * This method is used to convert camelCase word to normal sentence case words
 *
 * @param {*} str
 */
const toSentenceCase = (str) => str.replace(/^[a-z]|[A-Z]/g, (v, i) => (i === 0 ? v.toUpperCase() : ` ${v.toLowerCase()}`));

/**
 *  To check list is not empty or not null
 *  Null check
 * @param {*} list
 */
const isListNotEmpty = (list) => {
  if (list && Array.isArray(list) && list.length > 0) {
    return true;
  }
  return false;
};


/**
 * copy text to clipboard
 * @param {String} text
 */
const copyToClipboard = (text) => {
  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

export default {
  getQueryParams,
  minDate,
  maxDate,
  toSentenceCase,
  isListNotEmpty,
  copyToClipboard,
};
