/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import httpApi from "./httpApi";

const moment = require("moment");

/**
 * This method is used to return order list
 * @returns {Array}
 */
const getOrders = async (requestData, page, size, sortBy, sortDirection) => {
  const response = await httpApi.post(`v1/orders/search`, requestData, { params: { page, size, sortBy, sortDirection } });
  if (response && response.data && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return order list
 * @returns {Array}
 */
const getOrderDetails = async (selectedOrderId) => {
  const response = await httpApi.get(`v1/orders/admin/details/id/${selectedOrderId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update order note
 * @param String
 * @param String
 * @returns {Array}
 */
const updateOrderNote = async (id, note) => {
  const requestData = {
    note
  };
  const response = await httpApi.patch(`v1/orders/${id}/note`, requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to transform order data
 * @param Array
 * @returns {Array}
 */
const transformOrderData = async (orders) => {
  // TODO - Configurable
  const filtered = orders.map(({
    id,
    orderDate,
    billingAddress,
    status,
    priceInfo
  }) => ({
    "Order id": id,
    "Order date": orderDate ? moment(orderDate).format("YYYY-MM-DD HH:mm:ss") : null,
    "Customer name": billingAddress && billingAddress.contactPerson ? billingAddress.contactPerson : null,
    "Email": billingAddress && billingAddress.email ? billingAddress.email : null,
    "Order status": status,
    "Order amount": priceInfo && priceInfo.netPrice ? priceInfo.netPrice : null
  }));
  return filtered;
}

export { getOrders, getOrderDetails, updateOrderNote, transformOrderData };
