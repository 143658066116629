/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */

import React from "react";
import PropTypes from "prop-types";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Select from "../../../common/components/Select";
import ConditionalField from "./ConditionalField";

const SingleSelect = ({
  inputData, stateData, handleChange, renderFilter,
}) => {
  const {
    label, inputName, attributes,
  } = inputData;
  const { options, conditionalFields } = attributes || {};
  const selectedValue = stateData ? stateData.selectedValue : "";
  const onChange = (e) => {
    handleChange(e.target.name,
      e.target.value);
  };
  return (
    <>
      <div className="col-sm-6 mt-2">
        <FormGroup>
          <Label className="formTextLabel">
            {label}
          </Label>
          <Select className="formText" name={inputName} value={selectedValue} options={options} onChange={onChange} />
        </FormGroup>
      </div>
      {conditionalFields && conditionalFields.length > 0 ? (
        <ConditionalField
          conditionalFields={conditionalFields}
          stateData={stateData}
          inputName={inputName}
          renderFilter={renderFilter}
        />
      ) : ""}
    </>
  );
};

SingleSelect.propTypes = {
  inputData: PropTypes.objectOf(PropTypes.any).isRequired,
  stateData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  renderFilter: PropTypes.func.isRequired,
};

export default SingleSelect;
