/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Rahna
 */
import httpApi from "./httpApi";

/**
 *This method is used to get Payment Method Configuration by id
 *
 * @param {*} id
 */
const getPaymentMethodConfigurationById = async (id) => {
  const response = await httpApi.get(`/v1/payments/configurations/id/${id}`);
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 *This method is used to get all Payment Method Configurations
 *
 * @param {*} retailer
 * @param {*} brand
 */
const getPaymentMethodConfigurations = async () => {
  const response = await httpApi.get("/v1/payments/configurations");
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to get all Payment types available for Payments
 */
const getPaymentTypes = async () => {
  const response = await httpApi.get("/v1/payments/types");
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to get all Payment methods available for Payments
 */
const getPaymentMethods = async () => {
  const response = await httpApi.get("/v1/payments/methods");
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to get all price strategies available for Payments
 */
const getPaymentPriceStrategies = async () => {
  const response = await httpApi.get("/v1/payments/price-strategies");
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to update Payment Method Configurations
 *
 * @param {*} PaymentMethodConfiguration
 * @returns
 */
const updatePaymentMethodConfiguration = async (
  paymentMethodConfiguration
) => {
  const response = await httpApi.put(
    "/v1/payments/configurations/",
    paymentMethodConfiguration
  );
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to set active flag 
 *
 * @returns
 */
const activatePaymentMethod = async (id) => {
  const response = await
    httpApi.patch(`v1/payments/configurations/id/${id}/activate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

const deactivatePaymentMethod = async (id) => {
  const response = await
    httpApi.patch(`v1/payments/configurations/id/${id}/deactivate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

export {
  getPaymentMethodConfigurationById,
  getPaymentMethodConfigurations,
  getPaymentTypes,
  getPaymentMethods,
  getPaymentPriceStrategies,
  updatePaymentMethodConfiguration,
  activatePaymentMethod,
  deactivatePaymentMethod
};