/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import httpApi from "./httpApi";

/**
 * This method used to slowdown response for show loading animations
 * @param {number} ms
 */
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * This method is used to crate new menu
 * @param {Object} data
 * @returns {Object}
 */
const getMenuTypes = async (data) => {
  const response = await httpApi.get("v1/site-navigations/items/types", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return menu list
 * @returns {Array}
 */
const getWebHeaderNavigationMenus = async (locale) => {
  const params = { locale };
  const response = await httpApi.get("v1/site-navigations", { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to return menu list
 * @returns {Array}
 */
const getNavigationDisplayTypes = async (data) => {
  const response = await httpApi.get("v1/site-navigations/items/display-types", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getBasicMenus = getWebHeaderNavigationMenus;

/**
 * This method is used to get single menu list by menu id
 * @param {String} menuId
 * @returns {Object}
 */
const getMenu = async (menuId) => {
  const response = await httpApi.get(`v1/site-navigations/id/${menuId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to crate new menu
 * @param {Object} data
 * @returns {Object}
 */
const getNavigationTypes = async (data) => {
  const response = await httpApi.get("v1/site-navigations/types", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to crate new menu
 * @param {Object} data
 * @returns {Object}
 */
const addSiteNavigation = async (data) => {
  const response = await httpApi.post("v1/site-navigations", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update menu details
 * @param {Object} data
 * @returns {Object}
 */
const updateMenu = async (data) => {
  const response = await httpApi.patch("v1/site-navigations", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete a site navigation
 * @param {String} id
 */
const deleteSiteNavigation = async (id) => {
  const response = await httpApi.delete(`v1/site-navigations/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/** This method is used to change status in site navigation
  * @param { String } id
    */

const setStatus = async (id) => {
  const response = await httpApi.patch(`v1/site-navigations/${id}/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method sync siteNavigation
 */
const syncSiteNavigation = async () => {
  const response = await httpApi.patch("v1/site-navigations/sync");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getNavigationTypes, getWebHeaderNavigationMenus, getMenu, getMenuTypes, getNavigationDisplayTypes,
  setStatus, deleteSiteNavigation, addSiteNavigation, updateMenu, getBasicMenus, syncSiteNavigation,
};
