// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Aardra S\n * \n */ \n.EditCoupon_borderRadius2__3Waas {\n  border-radius: 0.125rem !important;\n}\n\n.EditCoupon_secondaryColor__1cBC6 {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.EditCoupon_primaryColor__74d6- {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.EditCoupon_btn__3G8Im {\n  min-width: 7rem;\n}\n", ""]);
// Exports
exports.locals = {
	"borderRadius2": "EditCoupon_borderRadius2__3Waas",
	"secondaryColor": "EditCoupon_secondaryColor__1cBC6",
	"primaryColor": "EditCoupon_primaryColor__74d6-",
	"btn": "EditCoupon_btn__3G8Im"
};
module.exports = exports;
