// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Aardra S\n */\n.EditStore_block2__33Q4U {\n    padding-top: 20px;\n    padding-bottom: 20px;\n  } \n  \n  .EditStore_borderRadius2__28hQt {\n    border-radius: 0.125rem !important;\n  }\n  \n  .EditStore_secondaryColor__1rKGN {\n    background-color: #757575 !important;\n    border-color: #757575 !important;\n  }\n  \n  .EditStore_primaryColor__3_k4C {\n    background: #17a2b8 !important;\n    border-color: #17a2b8 !important;\n  }\n  \n  .EditStore_btn__2uyeD {\n    min-width: 7rem;\n  }\n  ", ""]);
// Exports
exports.locals = {
	"block2": "EditStore_block2__33Q4U",
	"borderRadius2": "EditStore_borderRadius2__28hQt",
	"secondaryColor": "EditStore_secondaryColor__1rKGN",
	"primaryColor": "EditStore_primaryColor__3_k4C",
	"btn": "EditStore_btn__2uyeD"
};
module.exports = exports;
