// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n * \n */ \n.AddCoupon_borderRadius2__2HFqs {\n  border-radius: 0.125rem !important;\n}\n\n.AddCoupon_secondaryColor__3q_ul {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.AddCoupon_primaryColor__20U9G {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.AddCoupon_btn__3djRA {\n  min-width: 7rem;\n}\n\n .AddCoupon_block1__2bcpG{\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.AddCoupon_block1__2bcpG .AddCoupon_navigationManagementnText__3kfyV{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n\n.AddCoupon_block1__2bcpG .AddCoupon_buttonAddCoupon__m-QxB{\n  color: #ffffff;\n  width: 113px;\n  height: 35px;\n  border-radius: 2px;\n  background-color: #448aff;\n  border: 0px;\n  float: right;\n}\n", ""]);
// Exports
exports.locals = {
	"borderRadius2": "AddCoupon_borderRadius2__2HFqs",
	"secondaryColor": "AddCoupon_secondaryColor__3q_ul",
	"primaryColor": "AddCoupon_primaryColor__20U9G",
	"btn": "AddCoupon_btn__3djRA",
	"block1": "AddCoupon_block1__2bcpG",
	"navigationManagementnText": "AddCoupon_navigationManagementnText__3kfyV",
	"buttonAddCoupon": "AddCoupon_buttonAddCoupon__m-QxB"
};
module.exports = exports;
