// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Naseef O\n */\n\n\n.CombinedWidgets_block2__2XqQR .CombinedWidgets_search__3_nCe {\n    border: 1px solid #3a9fe3;\n    border-radius: 0.125rem;\n  }\n  \n  .CombinedWidgets_block2__2XqQR .CombinedWidgets_search__3_nCe input {\n    border: 0;\n    width: 100%;\n  }\n  \n  .CombinedWidgets_block2__2XqQR .CombinedWidgets_search__3_nCe div {\n    border: 0;\n    border-radius: 0;\n    background-color: #3a9fe3;\n    padding-left: 0.6rem;\n    padding-right: 0.35rem;\n  }\n  \n  .CombinedWidgets_block2__2XqQR img.CombinedWidgets_icon__ps0N7 {\n    width: 20px;\n  }\n  .CombinedWidgets_labelImg__2SLHF{\n    width: 30px;\n    height: 30px;\n    margin-right: 10px !important;\n    margin-left: 5px !important;\n}\n.CombinedWidgets_block3__3xAxf{\n  min-height: calc(100vh - 420px); \n}\n\n.CombinedWidgets_block3__3xAxf .CombinedWidgets_categorySelector__3voLy{\n  height: 350px;\n  overflow: scroll;\n}", ""]);
// Exports
exports.locals = {
	"block2": "CombinedWidgets_block2__2XqQR",
	"search": "CombinedWidgets_search__3_nCe",
	"icon": "CombinedWidgets_icon__ps0N7",
	"labelImg": "CombinedWidgets_labelImg__2SLHF",
	"block3": "CombinedWidgets_block3__3xAxf",
	"categorySelector": "CombinedWidgets_categorySelector__3voLy"
};
module.exports = exports;
