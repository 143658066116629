/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import promotionUtils from "../../../common/utils/commonUtils";
import ProductSelector from "../../productManagement/containers/SimpleProductSelectorContainer";
import AddIcon from "../../../common/assets/images/svg/add-24px.svg";
import RemoveIcon from "../../../common/assets/images/svg/minus-24px.svg";
import styles from "../css/ProductBundle.module.css";
import validationConstants from "../../../common/utils/validationConstants";


const PromotionBundle = (props) => {
  const {
    bundleItems,
    addBundle,
    removeBundle,
    handleBundleChange,
    validator,
    promotionBundleDetails,
  } = props;
  const {
    register, errors,
  } = validator;
  const { IS_NUMBER } = validationConstants;
  const { isListNotEmpty } = promotionUtils;
  const { t } = useTranslation(["common", "promotion", "fulfillment-method"]);
  const bundleSize = isListNotEmpty(bundleItems) ? bundleItems.length : 0;
  return (
    <>
      <Col xs="12">
        {isListNotEmpty(bundleItems)
          ? bundleItems.map(({ productId, quantity, id }, index) => (
            <Row key={`bundle_${id}`}>
              <Col xs="4">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("common:label_quantity")}
                    <span className="pl-1 text-danger">*</span>
                  </Label>
                  <Input
                    className="formText"
                    placeholder=""
                    name={`quantity_${id}`}
                    value={quantity}
                    onChange={(e) => handleBundleChange(index, "quantity", e.target.value)}
                    innerRef={
                      register({
                        required: t("promotion:validation_buy"),
                        min: {
                          value: 1,
                          message: t("promotion:validation_buy_invalid"),
                        },
                        pattern: {
                          value: IS_NUMBER,
                          message: t("common:validation_is_number"),
                        },
                      })
                    }
                    invalid={!!(errors[`quantity_${id}`] && errors[`quantity_${id}`].message)}
                  />
                  <FormFeedback>{errors[`quantity_${id}`] && errors[`quantity_${id}`].message}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="4">
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("common:label_product")}
                    <span className="pl-1 text-danger">*</span>
                  </Label>
                  <ProductSelector
                    singleSelect
                    id={`productId_${id}`}
                    name={`productId_${id}`}
                    value={productId}
                    options={isListNotEmpty(promotionBundleDetails)
                      ? promotionBundleDetails.filter((each) => each.id === id).map((each) => (
                        { name: each.productName, id: each.productId }
                      ))
                      : []}
                    onChange={(value) => handleBundleChange(index, "productId", value)}
                    excluded={bundleItems.map((each) => each.productId)}
                    innerRef={
                      register({
                        required: t("promotion:validation_select_product"),
                      })
                    }
                    invalid={!!(errors[`productId_${id}`] && errors[`productId_${id}`].message)}
                    validator={validator}
                  />
                  <FormFeedback>{errors[`productId_${id}`] && errors[`productId_${id}`].message}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs="4" className={styles.productBundleButtons}>
                {(bundleSize - 1) === index
                  ? (
                    <img
                      src={AddIcon}
                      alt="+"
                      role="presentation"
                      className={styles.btn}
                      width="32px"
                      onClick={addBundle}
                    />
                  )
                  : ""}
                {bundleSize > 1
                  ? (
                    <img
                      src={RemoveIcon}
                      alt="-"
                      className={styles.btn}
                      width="32px"
                      role="presentation"
                      onClick={() => removeBundle(index)}
                    />
                  )
                  : ""}
              </Col>
            </Row>

          ))
          : ""}
      </Col>
    </>
  );
};

PromotionBundle.propTypes = {
  bundleItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  promotionBundleDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  addBundle: PropTypes.string.isRequired,
  removeBundle: PropTypes.string.isRequired,
  handleBundleChange: PropTypes.string.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};


export default PromotionBundle;
