// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n * \n */\n .MenuEditor_block1__3OzbE{\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.MenuEditor_block1__3OzbE .MenuEditor_navigationManagementnText__2gqb_{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n\n.MenuEditor_block1__3OzbE .MenuEditor_buttonAddMenu__2KTMH{\n  color: #ffffff;\n  width: 113px;\n  height: 35px;\n  border-radius: 2px;\n  background-color: #448aff;\n  border: 0px;\n  float: right;\n}\n\n.MenuEditor_blockWrapper__GuNQf{\n  min-height: calc(100vh - 385px);\n} \n \n.MenuEditor_borderRadius2__2W0QW {\n  border-radius: 0.125rem !important;\n}\n\n.MenuEditor_secondaryColor__14c67 {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.MenuEditor_primaryColor__2V_CK {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.MenuEditor_btn__2YapZ {\n  min-width: 7rem;\n}\n", ""]);
// Exports
exports.locals = {
	"block1": "MenuEditor_block1__3OzbE",
	"navigationManagementnText": "MenuEditor_navigationManagementnText__2gqb_",
	"buttonAddMenu": "MenuEditor_buttonAddMenu__2KTMH",
	"blockWrapper": "MenuEditor_blockWrapper__GuNQf",
	"borderRadius2": "MenuEditor_borderRadius2__2W0QW",
	"secondaryColor": "MenuEditor_secondaryColor__14c67",
	"primaryColor": "MenuEditor_primaryColor__2V_CK",
	"btn": "MenuEditor_btn__2YapZ"
};
module.exports = exports;
