// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.PrioritySchedule_subCardBody__1QaD6{\n  font-size: 14px;\n  font-weight: 400;\n  background-color: lightgray;\n  color:black;\n}", ""]);
// Exports
exports.locals = {
	"subCardBody": "PrioritySchedule_subCardBody__1QaD6"
};
module.exports = exports;
