/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Button from "../../../common/core/Button";
import Label from "../../../common/core/Label";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalFooter from "../../../common/core/ModalFooter";
import ModalBody from "../../../common/core/ModalBody";
import Select from "../../../common/components/Select";
import styles from "../css/JobConfiguration.module.css";

const AddJobTaskModal = ({
  selectedJobTaskData,
  setSelectedJobTaskData,
  handleChange,
  renderFilter,
  isAddJobTaskModalOpen,
  setAddJobTaskModalOpen,
  handleJobTaskData,
  jobTaskTypes,
  jobTaskTemplateData,
  setjobTaskTemplateData,
}) => {
  const { t } = useTranslation(["job-configuration"]);
  const onChange = (e) => {
    handleChange(e.target.name,
      e.target.value);
  };
  return (
    <Modal
      backdrop="static"
      id="modleClosehide"
      isOpen={isAddJobTaskModalOpen}
      modalTransition={{ timeout: 700 }}
      backdropTransition={{ timeout: 1300 }}
      toggle={() => {
        setAddJobTaskModalOpen(!isAddJobTaskModalOpen);
        setSelectedJobTaskData({});
      }}
      style={{ maxWidth: "600px" }}
    >
      <ModalHeader
        toggle={() => {
          setAddJobTaskModalOpen(!isAddJobTaskModalOpen);
          setSelectedJobTaskData({});
          setjobTaskTemplateData([]);
        }}
        className="font-weight-bold"
      >
        <h5 className="head1">{t("job-configuration:span_job_task_configuration")}</h5>
        <span
          className="closeIcon"
          color="primary"
          onClick={() => {
            setAddJobTaskModalOpen(!isAddJobTaskModalOpen);
            setSelectedJobTaskData({});
            setjobTaskTemplateData([]);
          }}
        />
      </ModalHeader>
      <ModalBody>
        <Label>
          {" "}
          {t("job-configuration:label_type")}
        </Label>
        <Select className="formText" name="jobTaskType" value={selectedJobTaskData.jobTaskType} options={jobTaskTypes} onChange={onChange} />
        <Row>
          {
            jobTaskTemplateData && jobTaskTemplateData.length > 0
            && jobTaskTemplateData.map((dataField) => renderFilter(dataField))
          }
        </Row>
        <Row />
      </ModalBody>
      <ModalFooter>
        <Button
          className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
          onClick={() => handleJobTaskData()}
        >
          {t("job-configuration:button_add_job_task")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AddJobTaskModal.propTypes = {
  selectedJobTaskData: PropTypes.objectOf(PropTypes.any).isRequired,
  jobTaskTemplateData: PropTypes.objectOf(PropTypes.any).isRequired,
  jobTaskTypes: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedJobTaskData: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  renderFilter: PropTypes.func.isRequired,
  isAddJobTaskModalOpen: PropTypes.bool.isRequired,
  setAddJobTaskModalOpen: PropTypes.func.isRequired,
  setjobTaskTemplateData: PropTypes.func.isRequired,
  handleJobTaskData: PropTypes.func.isRequired,
};

export default AddJobTaskModal;
