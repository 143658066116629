/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import ProductSelector from "../components/ProductSelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { getProducts } from "../../../api/productManagementServices";


const ProductSelectorContainer = ({ onChange, name, value: selectedProductDetails }) => {
  /** local states */
  /** used to for showing and sorting products */
  const [products, setProducts] = useState(Array.isArray(selectedProductDetails) ? selectedProductDetails : []);
  /** used for store products for rollback sort/filter to default */
  const [productList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  /**
   * This method is sued to get product list from server
   * @param {String} searchTerm
   */
  const getProductListFromSever = (searchTerm = "") => {
    getProducts(searchTerm).then((response) => {
      if (response && response.success === true) {
        const { content } = response.data;
        if (Array.isArray(content)) {
          const listedProductId = productList.map(({ id }) => id);
          setProducts(content);
          setProductList([
            ...(content.filter((each) => each && !listedProductId.includes(each.id))),
            ...productList,
          ]);
        }
      }
    });
  };

  useEffect(() => {
    if (Array.isArray(selectedProductDetails) && selectedProductDetails.length > 0) {
      const listedProductId = productList.map(({ id }) => id);
      const filteredList = selectedProductDetails.filter((each) => each && !listedProductId.includes(each.id));
      setProductList([
        ...productList,
        ...filteredList,
      ]);
      setSelectedProducts(selectedProductDetails.map(({ id }) => id));
    }
  }, [selectedProductDetails]);

  /**
   * This method is used to change search filed and trigger search functionality
   * @param {Event} event
   */
  const handleSearchFiled = (value) => {
    if (value && `${value}`.trim().length > 1) { getProductListFromSever(value); } else { getProductListFromSever(); }
    setSearchKeyword(value);
  };

  /**
   * This function is used to manipulate object
   * @param {Object} product
   */
  const updateUrl = (product) => {
    const catalogItemId = product.dataId || product.id;
    const url = product.landingPageBaseUrl || `/product-details?cat=${catalogItemId}`;
    return ({ ...product, url });
  };

  /**
   * This method is used to set select / deselect product
   * @param {Event} event
   */
  const setProductSelected = (event) => {
    const { value: productIds } = event.target;
    setSelectedProducts(productIds);
    const filteredProducts = productList.filter((o) => productIds.includes(o.id));
    onChange(filteredProducts.map(updateUrl));
  };

  useEffect(() => {
    getProductListFromSever();
  }, []);

  return (
    <ProductSelector
      name={name}
      productList={productList}
      products={products}
      selectedProducts={selectedProducts}
      setProductSelected={setProductSelected}
            // product search
      searchKeyword={searchKeyword}
      handleSearchFiled={handleSearchFiled}
    />
  );
};


ProductSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
};

ProductSelectorContainer.defaultProps = {
  name: "product",
};

export default ProductSelectorContainer;
