/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Meshal Chalil
 */

import React, { useState } from "react";
import XLSX from "xlsx";
import PropTypes from "prop-types";
import Sequence from "../components/Sequencing";
/** ========= CUSTOM COMPONENTS ========= */
import AlertModal from "../components/AlertModal";
/** ========= UTILS ========= */
import categoryUtils from "../../../common/utils/categoryUtils";
/** ===== API SERVICE FUNCTIONS =========== */
import { updateProductRankByUniqueIds } from "../../../api/productManagementServices";
import { productBulkDeleteByCategoryId } from "../../../api/categoryManagementServices";
import { useHistory } from "react-router-dom";

import ConfirmationModal from "../../../common/components/ConfirmationModal";

const SequencingContainer = (props) => {
  const { categoryId } = props;
  const [modalMattibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loadingFlags, setLoadingFlags] = useState({ bulkUpload: false, bulkDelete: false });

  const history = useHistory();

  /**
   * This method is sued to update product rank
   * @param {String} rankUpdataDetails
   */
  const updateProductRank = (rankUpdataDetails) => {
    updateProductRankByUniqueIds(rankUpdataDetails).then((response) => {
      setLoadingFlags({ ...loadingFlags, bulkUpload: false });
      if (
        response
        && response.success
        && response.data
        && response.data.length > 0
      ) {
        setModalAttributes({
          isOpen: true,
          content: `Product Ranking failed for ${response.data.length} Items`,
          header: "Error",
        });
      } else {
        setModalAttributes({
          isOpen: true,
          content: "Bulk upload completed",
          header: "Success",
        });
      }
      setTimeout(() => {
        history.go(0);
      }, 3000);
    });
  };

  const handleBulkImport = (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          header: "Error",
        });
      } else {
        const { name } = e.target;
        const reader = new FileReader();
        reader.onload = async (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNameList = workbook.SheetNames;
          const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
          const parsedData = categoryUtils.parseDataFromSheet(rawData);
          if (parsedData.isValid) {
            setLoadingFlags({ ...loadingFlags, bulkUpload: true });
            const sequenceDataFiltered = parsedData.options.map((each) => ({
              categoryId,
              categoryUniqueId: each.CategoryID,
              productUniqueId: each.ProductSKU,
              productRank: each.SequenuceValue,
            }));
            updateProductRank(sequenceDataFiltered);
          } else {
            setModalAttributes({
              isOpen: true,
              content: parsedData.message || "Something went wrong.Please try again",
              header: "Error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
      e.target.value = null;
    }
  };

  const downloadBulkImportTemplate = (name, context) => {
    const fileName = `${name}_template.xlsx`;
    const templateData = categoryUtils.getImportSheetTemplate(context);
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
  };

  const productBulkDelete = async () => {
    setOpenConfirmModal(false);
    setLoadingFlags({ ...loadingFlags, bulkDelete: true });
    const response = await productBulkDeleteByCategoryId(categoryId);
    setLoadingFlags({ ...loadingFlags, bulkDelete: false });
    const message = response.messages
      && Array.isArray(response.messages)
      && response.messages.length > 0
      ? response.messages[0]
      : "Something went wrong.Please try again";
    if (response && response.success && response.data) {
      setModalAttributes({
        isOpen: true,
        content: message,
        header: "Success",
      });
    } else {
      setModalAttributes({
        isOpen: true,
        content: message,
        header: "Error",
      });
    }
    setTimeout(() => {
      history.go(0);
    }, 3000);
  };
  return (
    <>
      <Sequence
        handleBulkImport={handleBulkImport}
        downloadBulkImportTemplate={downloadBulkImportTemplate}
        setOpenConfirmModal={setOpenConfirmModal}
        bulkUploadloadingFlags={loadingFlags.bulkUpload}
        bulkDeleteloadingFlags={loadingFlags.bulkDelete}
      />
      <AlertModal
        isOpen={modalMattibutes.isOpen}
        content={modalMattibutes.content}
        header={modalMattibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
      <ConfirmationModal
        isOpen={openConfirmModal}
        toggleOpen={() => setOpenConfirmModal(false)}
        togglClose={() => setOpenConfirmModal(false)}
        handleConfirm={productBulkDelete}
        content="Are you sure you want to delete all products in this category ?"
      />
    </>
  );
};

SequencingContainer.propTypes = {
  categoryId: PropTypes.string.isRequired,
};

export default SequencingContainer;
