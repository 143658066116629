/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datetime";
import moment from "moment";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Select from "../../../common/components/Select";

/** ========== SUB COMPONENT ================ */
import ParentCategory from "../containers/ParentCategoryContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/CategoryForm.module.css";

const CategoryForm = (props) => {
  const {
    id, name, longDescription, description, startDate, endDate, status, type, source, parentCategoryName,
    handleFormChange, handleChange, validator,
    parentCategory, handleParentCategory, translatableFileds, selectedLanguage,isDefault
  } = props;

  /** validation methods */
  const { register, errors } = validator;

  const CategoryTypes = [{ name: "Root", code: "Root" }, { name: "Sub category", code: "Subcategory" }];


  return (
    <Row className={clsx(styles.block3, "pt-3", "pb-1")}>
      <Col xs="10">
        <Row>
          {
            id
            && (
              <Col xs="12" sm="5">
                <FormGroup>
                  <Label className="formTextLabel">
                    Category ID
                  </Label>
                  <Input className="formText"  placeholder="Category ID" name="id" value={id} required 
                  disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("id") : false}/>
                </FormGroup>
              </Col>
            )
          }
          <Col xs="12" sm={id ? "7" : "5"}>
            <FormGroup>
              <Label className="formTextLabel">
                Name
                <span className="pl-1 text-danger">*</span>
              </Label>
              <Input
                id="name"
                className="formText"
                placeholder="Category name"
                name="name"
                defaultValue={name}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: "Please enter a name",
                    minLength: {
                      value: 3,
                      message: "Please enter a name",
                    },
                  })
                }
                disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("name") : false}
                // disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("name") : false}
                invalid={!!(errors.name && errors.name.message)}
              />

              <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm={id ? "12" : "7"}>
            <FormGroup>
              <Label className="formTextLabel">
                Short description
                <span className="pl-1 text-danger">*</span>
              </Label>
              <Input
                className="formText"
                placeholder=""
                name="description"
                id="description"
                defaultValue={description}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: "Please enter a valid description between 12-50 characters",
                    minLength: {
                      value: 12,
                      message: "Please enter a valid description between 12-50 characters",
                    },
                    maxLength: {
                      value: 1000,
                      message: "Please enter a description less than 1000 characters",
                    },
                  })
                }
                invalid={!!(errors.description && errors.description.message)}
                disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("description") : false}
                // disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("description") : false}
              />
              <FormFeedback>{errors.description && errors.description.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup>
              <Label className="formTextLabel">Long description</Label>
              <Input
                className="formText"
                placeholder=""
                name="longDescription"
                value={longDescription}
                onChange={handleFormChange}
                id="longDescription"
                disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("longDescription") : false}
                // disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("longDescription") : false}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label className="formTextLabel">
                Start date
                <span className="pl-1 text-danger">*</span>
              </Label>
              <DatePicker
                id="endate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
                input
                inputProps={{
                  placeholder: `Ex: ${moment().format("DD/MM/YYYY")}`,
                  name: "startDate",
                  // disabled: Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("endate") : false,
                }}
                value={moment(startDate).format("DD/MM/YYYY")}
                onChange={(o) => (moment.isMoment(o) && handleChange("startDate", o.toDate()))}
                required
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label className="formTextLabel">
                End date
                <span className="pl-1 text-danger">*</span>
              </Label>
              <DatePicker
                id="endate"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
                input
                inputProps={{
                  placeholder: `Ex: ${moment().format("DD/MM/YYYY")}`,
                  // disabled: Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("endate") : false,
                }}
                name="endDate"
                value={moment(endDate).format("DD/MM/YYYY")}
                onChange={(o) => (moment.isMoment(o) && handleChange("endDate", o.toDate()))}
                required
                isValidDate={(current) => current.isAfter(startDate)}
              />
            </FormGroup>
          </Col>

          {/* Category type */}
          <Col xs="12" sm="6">
            <FormGroup>
              <Label className="formTextLabel">Type</Label>
              <Select
                className="formText"
                placeholder="Select category type"
                name={name}
                id="category"
                value={type}
                onChange={(e) => handleChange("type", e.target.value)}
                options={[
                  ...CategoryTypes.map((CategoryType) => ({ value: CategoryType.code, label: CategoryType.name })),
                ]}
                // disabled={source !== "RetailAdmin" || Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("type") : false}
              />
            </FormGroup>
          </Col>

          {/*  ====== SET PATENT CATEGORIES S[0004]============ */}
          <ParentCategory
            parentCategory={parentCategory}
            handleParentCategory={handleParentCategory}
            exclude={[id]}
            categoryName={parentCategoryName}
            // disbaledivClass={Array.isArray(translatableFileds) && translatableFileds.includes("parentCategory") && "form-custom-inputs-disable"}
          />
          {/*  ====== SET PATENT CATEGORIES E[0004]============ */}
          <Col xs="12" md="6" sm="5">
            <FormGroup>
              <Label className="formTextLabel">Status</Label>
              <div
                className={clsx(
                  "custom-control",
                  "custom-switch",
                  "ml-1 pt-2",
                )}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="status-new-category"
                  name="status"
                  checked={status}
                  // disabled={Array.isArray(translatableFileds) ? selectedLanguage != "en" && !translatableFileds.includes("status") : false}
                  onChange={() => handleChange("status", !status)}
                />
                <Label className="custom-control-label" htmlFor="status-new-category" />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CategoryForm.defaultProps = {
  id: null,
};

CategoryForm.propTypes = {
  // form field
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // parent category
  parentCategory: PropTypes.string.isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  parentCategoryName: PropTypes.string.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default CategoryForm;
