/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * User Management
 *
 * @author Naseef O
 *
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/VanityUrls.module.css";
import Button from "../../../common/core/Button";
import UrlTable from "./UrlTable";
import UrlEditor from "./UrlEditor";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Input from "../../../common/core/Input";
import SearchIcon from "../../../common/assets/images/svg/search.svg";

const customRows = [
  {
    label: "Url",
    value: "",
    path: "url",
    transform: "",
  },
  {
    label: "Internal url",
    value: "",
    path: "internalUrl",
    transform: "",
  },
];

const VanityUrls = (props) => {
  const {
    vanityUrls,
    modal,
    toggleModal,
    form,
    handleChange,
    isEdit,
    register,
    errors,
    handleSave,
    handleSubmit,
    onHandleSearch,
    page,
    hasMoreData,
    handleChangeSearch,
    isLoading,
    selectedIndex,
    handleDelete,
  } = props;
  return (
    <>
      <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
        <Col xs="8">
          <span className="pageText">Vanity URLs</span>
        </Col>

        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="6" className="d-flex justify-content-start">
          <div className={styles.areaActionRight}>
            <div>
              <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                <Input placeholder="Search" onChange={(e) => handleChangeSearch(e)} />
                <InputGroupAddon
                  addonType="append"
                  className="commonPointer bg-color-tertiary-main"
                >
                  <img
                    src={SearchIcon}
                    alt="Search"
                    className={styles.icon}
                  />
                </InputGroupAddon>
              </InputGroup>
            </div>
          </div>
        </Col>
        <Col xs="6" className="d-flex justify-content-end">
          <Button
            className={clsx(styles.buttonNewUser,
              "text-uppercase bg-color-tertiary-main")}
            onClick={() => toggleModal()}
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <Row className="custom-table m-0 ">
            <UrlTable
              pageCustomizationRows={customRows}
              customizationData={vanityUrls}
              handleEdit={toggleModal}
              isLoading={isLoading}
              selectedIndex={selectedIndex}
              handleDelete={handleDelete}
            />
          </Row>
          <div
            className={clsx(
              styles.pagination,
              "p-3",
              "d-flex",
              "justify-content-end",
            )}
          >
            <div className="d-flex">
              <span
                disabled={page <= 0}
                className={clsx(styles.item, styles.arrowLeft)}
                tabIndex={0}
                role="button"
                onKeyPress={() => { }}
                onClick={() => page > 0 && onHandleSearch(-1)}
              >
                <span />
              </span>
              <span className={styles.item} />
              <span
                disabled={!hasMoreData}
                className={clsx(styles.item, styles.arrowRight)}
                tabIndex={0}
                role="button"
                onKeyPress={() => { }}
                onClick={() => hasMoreData && onHandleSearch(1)}
              >
                <span />
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <UrlEditor
        modal={modal}
        toggle={toggleModal}
        form={form}
        handleChange={handleChange}
        isEdit={isEdit}
        register={register}
        errors={errors}
        handleSave={handleSave}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
VanityUrls.propTypes = {
  modal: PropTypes.bool.isRequired,
  vanityUrls: PropTypes.arrayOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default VanityUrls;
