/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Deepak D
 */

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ===== COMPONENTS  =========== */
import ShippingMethod from "../components/ShippingMethod";

/** ===== API SERVICE FUNCTIONS =========== */
import { getShippingMethodConfigurations, activateShippingMethod, deactivateShippingMethod } from "../../../api/shipmentManagementServices";
import { getFulfillmentMethodConfigurations } from "../../../api/fulfillmentMethodService";

const ShippingMethodContainer = () => {
  const history = useHistory();

  /** local states */
  const [shippingMethodConfigurations, setShippingMethodConfigurations] = useState([]);
  const [fulfillmentMethodConfigurations, setFulfillmentMethodConfigurations] = useState([]);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    getShippingMethods();
    getFulfillmentMethods();
  }, []);

  const getShippingMethods = () => {
    getShippingMethodConfigurations().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setShippingMethodConfigurations(data);
      }
    });
  };

  const getFulfillmentMethods = () => {
    getFulfillmentMethodConfigurations().then((response) => {
      if (response && response.data) {
        const { data } = response;
        setFulfillmentMethodConfigurations(data);
      }
    });
  };

  const onHandleEditTaxRegion = (id) => {
    history.push(`/operations/shipping-method/edit/${id}`);
  };

  const updateStatusFlag = (active, id) => {
    if (active) {
      activateShippingMethod(id).then(async (response) => {
        if (response && response.success) {
          const newShipmentData = shippingMethodConfigurations.map((shipment) => (shipment.id === id
            ? ({ ...shipment, active }) : shipment));
          setShippingMethodConfigurations(newShipmentData);
        }
      });
    }
    else {
      deactivateShippingMethod(id).then(async (response) => {
        if (response && response.success) {
          const newShipmentData = shippingMethodConfigurations.map((shipment) => (shipment.id === id
            ? ({ ...shipment, active }) : shipment));
          setShippingMethodConfigurations(newShipmentData);
        }
      });
    }
  };

  const checkAvailabilityToUpdate = (active, id, fulfillmentType) => {
    let ableToUpdateStatus = true;
    fulfillmentMethodConfigurations.map((fulfillmentMethod) => {
      const { defaultShippingMethod } = fulfillmentMethod;
      if (defaultShippingMethod === fulfillmentType) {
        ableToUpdateStatus = false;
        setWarning(true);
      }
    });
    if (ableToUpdateStatus) {
      setWarning(false);
      updateStatusFlag(active, id);
    }
  }

  return (
    <ShippingMethod
      shippingMethodConfigurations={shippingMethodConfigurations}
      onHandleEditTaxRegion={onHandleEditTaxRegion}
      onStatusChange={checkAvailabilityToUpdate}
      warning={warning}
    />
  );
};
export default ShippingMethodContainer;
