/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * UrlRedirectsEditor
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Form from "../../../common/core/Form";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Select from "../../../common/components/MultiSelect/Select";

const UrlRedirectsEditor = (props) => {
  const {
    modal,
    toggle,
    form,
    handleChange,
    isEdit,
    register,
    errors,
    handleSave,
    handleSubmit,
  } = props;


  return (
    <Modal isOpen={modal} toggle={() => toggle()}>
      <Form onSubmit={handleSubmit(handleSave)}>
        <ModalHeader toggle={toggle}>{isEdit ? "Edit" : "Add"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Redirect from</Label>
                <Input
                  className="formText"
                  placeholder="ex: /abcd"
                  name="url"
                  defaultValue={form.url}
                  onChange={handleChange}
                  innerRef={
                    register({
                      required: "This field is required",
                    })
                  }
                  invalid={!!(errors.url && errors.url.message)}
                />
                <FormFeedback>{errors.url && errors.url.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Redirect to</Label>
                <Input
                  className="formText"
                  placeholder="ex: /product-list"
                  name="internalUrl"
                  defaultValue={form.internalUrl}
                  onChange={handleChange}
                  innerRef={
                    register({
                      required: "This field is required",
                    })
                  }
                  invalid={!!(errors.internalUrl && errors.internalUrl.message)}
                />
                <FormFeedback>{errors.internalUrl && errors.internalUrl.message}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label className="formTextLabel">
                  Status code
                </Label>
                <Select
                  className="formText"
                  placeholder=""
                  name="redirectStatusCode"
                  onChange={handleChange}
                  value={form.redirectStatusCode}
                  options={[
                    { value: "", disabled: true, label: "Select status code" },
                    { value: 301, label: "301 (Permanent) " },
                    { value: 302, label: "302" },
                    { value: 303, label: "303" },
                  ]}
                  innerRef={
                    register({
                      required: "This field is required",
                    })
                  }
                  invalid={!!(errors.redirectStatusCode && errors.redirectStatusCode.message)}
                />
                <FormFeedback>{errors.redirectStatusCode && errors.redirectStatusCode.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle()}>Cancel</Button>
          {" "}
          <Button className="bg-color-tertiary-main" type="submit">Save</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

UrlRedirectsEditor.propTypes = {
  modal: PropTypes.func.isRequired,
  toggle: PropTypes.bool.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UrlRedirectsEditor;
