/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Order Management
 *
 * @author Naseef O
 *
 */
import React, { useState } from "react";
import clsx from "clsx";
import DatePicker from "react-datetime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/orderManagement.module.css";
import CustomizationTable from "../../../common/components/Table";
import InputGroup from "../../../common/core/InputGroup";
import Input from "../../../common/core/Input";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Dropdown from "../../../common/core/Dropdown";
import DropdownMenu from "../../../common/core/DropdownMenu";
import DropdownToggle from "../../../common/core/DropdownToggle";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import RefreshIcon from "../../../common/assets/images/svg/refresh.svg";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";

const customRows = [
  {
    label: "Order no",
    value: "",
    path: "orderNo",
    transform: "",
  },
  {
    label: "Order date",
    value: "",
    path: "orderDate",
    transform: "",
    type: "date"
  },
  {
    label: "Customer name",
    value: "",
    path: "render",
    render: (row) => {
      if (row && row.billingAddress) {
        if (row.billingAddress.contactPerson) {
          return (
            <p>{row.billingAddress.contactPerson}</p>
          )
        }
        if (row.billingAddress.firstName) {
          return (
            <p>
              {`${row.billingAddress.firstName}  ${row.billingAddress.lastName}`}
            </p>
          )
        }
      }
    },
    transform: "",
  },
  {
    label: "Email",
    value: "",
    path: "email",
    transform: "",
  },
  {
    label: "Order status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Order amount",
    value: "",
    path: "priceInfo.grossPrice",
    transform: "",
  },
];

const OrderManagement = ({
  setToDate,
  setFromDate,
  setSearchTerm,
  onHandleSearch,
  orders,
  page,
  hasMoreData,
  isValidDate,
  isProccessing,
  onHandleExport,
  onClickRow,
  onHandleClear,
  searchTerm,
  fromDate,
  toDate,
}) => {
  const { t, i18n } = useTranslation(["common", "order-management"]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
   *This method is used to toogle dropdown
   *
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">
            {t("order-management:order_management_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="4" className="p-0">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("order-management:span_manage_orders")}
              </span>
            </Col>
            <Col xs="8" className="p-0">
              <div className={styles.areaActionRight}>
                <div className={clsx(styles.searchContainer)}>
                  <span
                    onClick={() => onHandleClear()}
                    className="commonPointer"
                    title="Refresh"
                  >
                    <img
                      width="40"
                      src={RefreshIcon}
                      alt="search category"
                      className={styles.icon}
                    />
                  </span>
                </div>
                <div>
                  <FormGroup>
                    <Label className="formTextLabel" for="exampleEmail">
                      {t("order-management:label_order_from_date")}
                    </Label>
                    <DatePicker
                      id="fromDate"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                      input
                      name="fromDate"
                      onChange={(e) => setFromDate(e)}
                      isValidDate={(e) => isValidDate(e, "ORDER_DATE_FROM")}
                      inputProps={{
                        placeholder: t("common:placeholder_date_example"),
                      }}
                      value={fromDate}
                    />
                    <FormFeedback>
                      {t("order-management:label_invalid_order_date_msg")}
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label className="formTextLabel" for="exampleEmail">
                      {t("order-management:label_order_to_date")}
                    </Label>
                    <DatePicker
                      id="toDate"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      closeOnSelect
                      input
                      name="toDate"
                      onChange={(e) => setToDate(e)}
                      isValidDate={(e) => isValidDate(e, "ORDER_DATE_TO")}
                      inputProps={{
                        placeholder: t("common:placeholder_date_example"),
                      }}
                      value={toDate}
                    />
                    <FormFeedback>
                      {t("order-management:label_invalid_order_date_msg")}
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className={clsx(styles.searchContainer)}>
                  <InputGroup
                    className={clsx(styles.search, "color-tertiary-main")}
                  >
                    <Input
                      placeholder={t(
                        "order-management:placeholder_search_orders",
                      )}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                    <InputGroupAddon
                      addonType="append"
                      className="commonPointer bg-color-tertiary-main"
                      onClick={() => onHandleSearch(0)}
                      title="Search"
                    >
                      <img
                        src={SearchIcon}
                        alt="search category"
                        className={styles.icon}
                      />
                    </InputGroupAddon>
                  </InputGroup>{" "}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <Row className={clsx(styles.block2, " pb-2  ")}>
            <Col xs="12">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className={clsx(styles.dropdownBtn, "commonDropDownWrapper")}
              >
                {isProccessing ? <span>{t("common:span_loading")}</span> : null}
                <DropdownToggle caret>
                  <span>{t("common:span_export_as")}</span>
                </DropdownToggle>
                <DropdownMenu right className="dropdownMenuWrapper">
                  <div
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className="dropdownMenuItem"
                    onClick={() => onHandleExport("XLSX")}
                  >
                    {t("common:div_export_format_XLSX")}
                  </div>
                  <div
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className="dropdownMenuItem"
                    onClick={() => onHandleExport("CSV")}
                  >
                    {t("common:div_item_export_format_CSV")}
                  </div>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <div className="custom-table">
                <CustomizationTable
                  customRows={customRows}
                  customizationData={orders}
                  isStatusEnable={false}
                  isDeleteEnable={false}
                  isEditEnable={false}
                  onClickRow={onClickRow}
                />
              </div>
              <div
                className={clsx(
                  styles.pagination,
                  "p-3",
                  "d-flex",
                  "justify-content-end",
                )}
              >
                <div className="d-flex">
                  <span
                    disabled={page <= 1}
                    className={clsx(styles.item, styles.arrowLeft)}
                    tabIndex={0}
                    role="button"
                    onKeyPress={() => { }}
                    onClick={() => onHandleSearch(-1)}
                  >
                    <span />
                  </span>
                  <span className={styles.item} />
                  <span
                    disabled={!hasMoreData}
                    className={clsx(styles.item, styles.arrowRight)}
                    tabIndex={0}
                    role="button"
                    onKeyPress={() => { }}
                    onClick={() => onHandleSearch(1)}
                  >
                    <span />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

OrderManagement.propTypes = {
  setToDate: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.any).isRequired,
  submitting: PropTypes.bool.isRequired,
  onHandleExport: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  isValidDate: PropTypes.bool.isRequired,
  isProccessing: PropTypes.bool.isRequired,
};

export default OrderManagement;
