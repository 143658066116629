/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ProductBundle from "../components/PromotionBundle";
import promotionUtils from "../../../common/utils/commonUtils";


const ProductBundleContainer = (props) => {
  const {
    onChange, value, validator, promotionBundleDetails,
  } = props;
  const [bundleItems, setBundleItems] = useState([
    {
      productId: "",
      quantity: "",
      id: new Date().valueOf(),
    },
  ]);

  useEffect(() => {
    if (promotionUtils.isListNotEmpty(value)) {
      setBundleItems(value.map((each) => (
        { id: each.id, productId: each.productId, quantity: each.quantity }
      )));
    } else {
      setBundleItems([{
        productId: "",
        quantity: "",
        id: new Date().valueOf(),
      }]);
    }
  }, [value]);


  const addBundle = () => {
    const bundle = {
      productId: "",
      quantity: "",
      id: new Date().valueOf(),
    };

    const bundleList = [...bundleItems];
    bundleList.push(bundle);
    setBundleItems(bundleList);
  };

  const removeBundle = (bundleIndex) => {
    const bundleList = [...bundleItems];
    bundleList.splice(bundleIndex, 1);
    setBundleItems(bundleList);
    if (onChange) {
      onChange(bundleList);
    }
  };

  const handleBundleChange = (bundleIndex, fieldName, fieldValue) => {
    const bundleList = [...bundleItems];
    const bundle = bundleList[bundleIndex];
    const newBundle = { ...bundle, [fieldName]: fieldValue };
    bundleList.splice(bundleIndex, 1, newBundle);
    setBundleItems(bundleList);
    if (onChange) {
      onChange(bundleList);
    }
  };


  return (
    <ProductBundle
      bundleItems={bundleItems}
      addBundle={addBundle}
      removeBundle={removeBundle}
      handleBundleChange={handleBundleChange}
      validator={validator}
      promotionBundleDetails={promotionBundleDetails}
    />
  );
};


ProductBundleContainer.propTypes = {

  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  promotionBundleDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.string.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default ProductBundleContainer;
