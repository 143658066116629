/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import JobDetails from "../components/JobDetails";
import { getJobDetails } from "../../../api/jobConfigurationService";

const JobDetailsContainer = ({ onHandleBack, selectedJobId }) => {
  const history = useHistory();

  const [jobDetailsConfigData, setJobDetailsConfigData] = useState([]);
  useEffect(() => {
    getJobDetails().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setJobDetailsConfigData(data);
      }
    });
  });

  const handleEdit = () => {
    const jobId = "2345";
    history.push(`/administration/job-list/edit/${jobId}`);
  };

  return (
    <JobDetails
      jobBasicConfigData={jobDetailsConfigData}
      handleEdit={handleEdit}
      onHandleBack={onHandleBack}
      selectedJobId={selectedJobId}
      canModify
    />
  );
};

export default JobDetailsContainer;
