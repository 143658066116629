/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Customization table
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { get } from "lodash";
import editIcon from "../../../common/assets/images/svg/edit.svg";
import UncontrolledTooltip from "../../../common/core/UncontrolledTooltip";
import deleteIcon from "../../../common/assets/images/svg/delete.svg";
import warningIcon from "../../../common/assets/images/svg/warning.svg";
import Spinner from "../../../common/core/Spinner";
import Table from "../../../common/core/Table";
import styles from "../css/Table.module.css";
import transformers from "../../../common/utils/transformers";
import Label from "../../../common/core/Label";

const CustomizationTable = (props) => {
  const {
    pageCustomizationRows,
    customizationData,
    widgetType,
    configId,
    handleRedirectToWidgetContentEditor,
    handleDelete,
    isLoading,
    selectedIndex,
    imgEnabled,
    deleteEnabled,
    handleChangeStatus,
    widget,
    locale,
  } = props;

  /**
   *This method is used to get a value from an item
   *
   * @param {object} row
   * @param {object} rowItemData
   * @returns
   */
  const getItem = (row, rowItemData) => {
    let data = get(row, rowItemData.path);
    if (rowItemData.transform && rowItemData.transform !== "") {
      data = transformers[rowItemData.transform](row, rowItemData.path);
    }
    return data;
  };

  return (
    <Table borderless>
      <thead>
        <tr>
          <th>Order</th>
          {imgEnabled && <th>Preview</th>}
          {pageCustomizationRows.map((row) => (
            <th>{row.label}</th>
          ))}
          <th>Edit</th>
          <th>Delete</th>
          {/* <th>Status</th> */}
        </tr>
      </thead>
      <tbody>
        {Array.isArray(customizationData) && customizationData.map((rowItem, index) => (
          <tr>
            <th scope="row" width="10%">
              <span className="p-2">{index + 1}</span>
            </th>
            {imgEnabled && (
              <td width="10%">
                {getItem(rowItem, { path: "media.defaultMediaUrl" }) || getItem(rowItem, { path: "media.largeMediaUrl" }) ? (
                  <img
                    src={getItem(rowItem, { path: "media.defaultMediaUrl" }) || getItem(rowItem, { path: "media.largeMediaUrl" })}
                    alt="preview"
                    width="60px"
                  />
                )
                  : (
                    <span>No preview</span>
                  )}
              </td>
            )}
            {pageCustomizationRows.map((row) => (
              <td width="10%">{getItem(rowItem, row)}</td>
            ))}
            <td width="10%">
              <img
                src={editIcon}
                className="commonPointer"
                onClick={() => handleRedirectToWidgetContentEditor(
                  true,
                  index,
                  widgetType,
                  configId,
                  locale,
                )}
                role="presentation"
                alt="drag indiactor icon"
                width="20px"
              />
            </td>
            <td width="10%">
              {deleteEnabled ? (
                <>
                  {isLoading && selectedIndex === index ? (
                    <Spinner className={styles.spinnerWrapper} />
                  )
                    : (
                      <img
                        src={deleteIcon}
                        className="commonPointer"
                        alt="drag indiactor icon"
                        width="20px"
                        role="presentation"
                        onClick={() => handleDelete(index)}
                      />
                    )}
                </>
              )
                : (
                  <>
                    <UncontrolledTooltip target="UncontrolledTooltipExample">
                      Limit reached. You can not delete this content
                    </UncontrolledTooltip>
                    <img
                      src={warningIcon}
                      className="commonPointer"
                      alt="warning"
                      width="20px"
                      id="UncontrolledTooltipExample"
                      role="presentation"
                    />
                  </>
                )}
            </td>
            {/* <td width="10%">
              <div
                className={clsx(
                  "custom-control",
                  "custom-switch",

                )}
              >

                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`toogled-${index + 1 + widget.id}`}
                  checked={(rowItem && rowItem.active) || false}
                  onChange={() => handleChangeStatus(!(rowItem && rowItem.active), index)}
                />
                <Label
                  className="custom-control-label"
                  htmlFor={`toogled-${index + 1 + widget.id}`}
                />
              </div>
              {/* added input tag for removing ESLint error > if added above input tag inside label it wont work */}
            {/* </td> */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

CustomizationTable.propTypes = {
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  imgEnabled: PropTypes.bool.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  widget: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
};

export default CustomizationTable;
