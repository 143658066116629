// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Roshna Accu\n */\n\n\n/* ========== FORM AREA ============ */\n.PromotionForm_block3__1f3e0 {\n  padding: 1.25rem;\n}\n\n.PromotionForm_block3__1f3e0 .PromotionForm_noLabelFormGroup__2NeO6 {\n  margin-top: 1.75rem;\n  padding: 0.375rem 0.75rem;\n}\n\n.PromotionForm_productOptionSelector__2-gJs .PromotionForm_error__2SJt2{\n      display: block;\n      width: 100%;\n      margin-top: 0.25rem;\n      font-size: 80%;\n      color: #dc3545;\n  }\n", ""]);
// Exports
exports.locals = {
	"block3": "PromotionForm_block3__1f3e0",
	"noLabelFormGroup": "PromotionForm_noLabelFormGroup__2NeO6",
	"productOptionSelector": "PromotionForm_productOptionSelector__2-gJs",
	"error": "PromotionForm_error__2SJt2"
};
module.exports = exports;
