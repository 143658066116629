
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */
import CustomURLManagement from "../components/CustomURLManagement";
import { updateUrlConfiguration, getUrlConfiguration } from "../../../api/SeoSettingsService";

const CustomURLManagementContainer = () => {
  const history = useHistory();
  const [form, setForm] = useState({
    product: "",
    category: "",
  });
  /** local states */
  const [activeTab, setActiveTab] = useState("1");

  /**
       * This method is used to switch tabs
       * @param {string} tab
       */
  const toggleTab = (tab) => {
    if (activeTab !== tab) { setActiveTab(tab); }
  };

  /**
     * This method is used to change fom state
     * @param {String} name
     * @param {String} value
     */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
     * This method is used to change form filed input
     * @param {Event} event
     */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
       * This method is used to cancel the edit operation and return to the listing page
       */
  const onHandleCancel = () => {
    history.push("/marketing/site-map");
  };

  /**
       * This method is used to update the form
       */
  const onHandleSave = async () => {
    await updateUrlConfiguration(form);
    history.push("/marketing/site-map");
  };

  useEffect(() => {
    let localForm = form;
    getUrlConfiguration().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        localForm = {
          ...form,
          product: (data.product && data.product.urlPattern) || "",
          category: (data.category && data.category.urlPattern) || "",
        };
        setForm(localForm);
      }
    });
  }, []);

  return (
    <CustomURLManagement
      activeTab={activeTab}
      toggleTab={toggleTab}
      form={form}
      handleFormChange={handleFormChange}
      onHandleSave={onHandleSave}
      onHandleCancel={onHandleCancel}
    />
  );
};

export default CustomURLManagementContainer;
