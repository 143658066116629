/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";

/** ========== SUB COMPONENT ================ */
import SkuForm from "./SkuForm";

/** ======== MODULE STYLES ========== */
import styles from "../css/EditProduct.module.css";
import validateForm from "../../../common/utils/validateForm";

const ModifySku = (props) => {
  const {
    productType,
    name,
    enableSku,
    skuName,
    listPrice,
    salePrice,
    quantity,
    onSale,
    images,
    attributes,
    description,
    longDescription,
    startDate, endDate,
    handleChange,
    handleFormChange,
    onSubmit,
    onSubmitting,
    handleRedirect,
    skuId,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);


  const methods = validateForm();

  const {
    handleSubmit, register, errors,
  } = methods;
  return (
    <Row className=" ">
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
          <fieldset disabled={onSubmitting}>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  {skuId ? "Edit SKU " : "Add SKU"}
                </span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={handleRedirect} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {t("common:button_save")}
                </Button>
              </Col>
            </Row>
            <Row className={clsx(styles.block3, "common-page-card")}>
              <SkuForm
                productType={productType}
                name={name}
                enableSku={enableSku}
                skuName={skuName}
                listPrice={listPrice}
                salePrice={salePrice}
                quantity={quantity}
                onSale={onSale}
                images={images}
                attributes={attributes}
                description={description}
                longDescription={longDescription}
                startDate={startDate}
                endDate={endDate}
                handleChange={handleChange}
                handleFormChange={handleFormChange}
                register={register}
                errors={errors}
              />
            </Row>

          </fieldset>
          {/*  ====== SET PATENT PRODUCTS E[0004]============ */}
        </Form>
        {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
      </Col>
    </Row>
  );
};

ModifySku.propTypes = {
  skuId: PropTypes.string.isRequired,
  /** form data */
  name: PropTypes.string.isRequired,
  enableSku: PropTypes.bool.isRequired,
  skuName: PropTypes.string.isRequired,
  listPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  salePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSale: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  productType: PropTypes.string.isRequired,
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  /** events */
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
  /** form event */
  onSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default ModifySku;
