/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anoop G
 */
import httpApi from "./httpApi";

/**
 * This method is used to set fetch the search settings
 * @param {Object} data
 */
const getSearchSettings = async (data) => {
    const response = await httpApi.post(`/v1/search-config/searchconfiguration`, data);
    if (response && response.data) {
      return response.data;
    }
    return null;
};

/**
 * This method is used to set fetch the search settings
 * @param {Object} data
 */
const updateSearchSettings = async (data) => {
    const response = await httpApi.put(`/v1/search-config/searchconfiguration`, data);
    if (response && response.data) {
      return response.data;
    }
    return null;
};

/**
 * This method is used to fetch the search synonym setting
 * @param {Object} data
 */
const getSearchSynonyms = async (data) => {
  const response = await httpApi.post(`/v1/search-config/searchSynonyms`, data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
* This method is used to set the search synonym setting
* @param {Object} data
*/
const updateSearchSynonyms = async (data) => {
  const response = await httpApi.put(`/v1/search-config/searchSynonyms`, data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
    getSearchSettings,
    updateSearchSettings,
    getSearchSynonyms,
    updateSearchSynonyms
};
  