/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */
import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Table from "../../../common/core/Table";
import styles from "../css/JobConfiguration.module.css";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import dragIndicatorIcon from "../../../common/assets/images/svg/dragIndicator.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

const reOrder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const JobTask = ({
  jobTaskData, setJobTaskData, handleJobTaskData, canModify,
}) => {
  const [jobTaskDetails, setJobTaskDetails] = useState(jobTaskData);
  const { t } = useTranslation(["job-configuration"]);
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reOrder(
      jobTaskDetails,
      result.source.index,
      result.destination.index,
    );
    setJobTaskData(items);
    setJobTaskDetails(items);
  };
  return (
    <>
      <Row className={clsx(styles.block3, styles.tableWrapper, "common-page-card")}>
        <Col>
          <div className={styles.jobTaskConfig}>
            <div
              className={clsx(styles.jobTaskConfigBody, "table-responsive")}
            >
              <Table borderless className={styles.jobTaskConfigTable}>
                <thead>
                  <tr>
                    <th width="10%">{" "}</th>
                    <th>{t("job-configuration:label_job_task_name")}</th>
                    <th>{t("job-configuration:label_type")}</th>
                    {canModify
                      ? <th className="text-center">{t("job-configuration:label_actions")}</th> : ""}
                  </tr>
                </thead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {jobTaskDetails && jobTaskDetails.length > 0 ? (
                          jobTaskDetails.map((data, index) => (
                            <Draggable key={data.jobTaskName} draggableId={data.jobTaskName} width="100%" index={index} isDragDisabled={!canModify}>
                              {(providedInner, snapshotInner) => (
                                <tr
                                  ref={providedInner.innerRef}
                                  {...providedInner.draggableProps}
                                  {...providedInner.dragHandleProps}
                                >
                                  <th scope="row" width="10%">
                                    <img src={dragIndicatorIcon} alt="drag indiactor icon" width="30px" height="24px" />
                                  </th>
                                  <td>
                                    {data.jobTaskName}
                                  </td>
                                  <td>
                                    {data.jobTaskType}
                                  </td>
                                  {canModify
                                    ? (
                                      <td align="center">
                                        <div className={clsx("d-flex", "justify-content-center")}>
                                          <span className="commonPointer" onClick={(o) => handleJobTaskData(o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                                            <img
                                              src={EditIcon}
                                              alt="Edit"
                                            />
                                          </span>
                                          <span className="commonPointer" onClick={(o) => handleJobTaskData(o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                                            <img
                                              src={DeleteIcon}
                                              alt="Delete"
                                            />
                                          </span>
                                        </div>
                                      </td>
                                    )
                                    : ""}
                                </tr>
                              )}
                            </Draggable>
                          )))
                          : (
                            <tr>
                              <td colSpan={8} align="center">
                                {t("job-configuration:label_no_job_task_added")}
                              </td>
                            </tr>
                          )}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

JobTask.propTypes = {
  jobTaskData: PropTypes.objectOf(PropTypes.any).isRequired,
  setJobTaskData: PropTypes.func.isRequired,
  handleJobTaskData: PropTypes.func.isRequired,
  canModify: PropTypes.bool.isRequired,
};

export default JobTask;
