/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ======== CUSTOM COMPONENTS ========= */
import TreeCategorySelector from "../components/TreeCategorySelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { listAllCategories } from "../../../api/categoryManagementServices";

/** Base container for add new category  */
const TreeCategorySelectorContainer = ({
  categories, onChange, multiple, fullObject, noProductsOnly, options, id, exclude,
}) => {
  /** local states */
  const [categoryList, setCategoryList] = useState([]);
  const [totalCategoryList, setTotalCategoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  /**
   * This method is sued to update global category list
   * @param {Array} localList
   */
  const updateUniqueTotalCategoryList = (localList) => {
    if (Array.isArray(localList)) {
      setTotalCategoryList((oldList) => {
        const listIds = oldList.map((l) => l && l.id);
        const filteredLocalList = localList.filter((each) => !listIds.includes(each.id));
        return [...oldList, ...filteredLocalList];
      });
    }
  };

  useEffect(() => {
    listAllCategories().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setCategoryList(data);
          updateUniqueTotalCategoryList(data);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(options)) {
      updateUniqueTotalCategoryList(options);
    }
  }, [options]);

  useEffect(() => {
    const defaultCategories = multiple && Array.isArray(categories) ? categories : [categories];
    setSelectedCategories(categories !== "" ? defaultCategories : []);
  }, [categories]);

  /**
 * This method is sued to handle category change
 * @param {String} categoryId
 */
  const handleSelectedCategories = (categoryId) => {
    const localSelectedCategories = multiple ? selectedCategories.map((category) => category) : [];
    const localIndex = selectedCategories.indexOf(categoryId);

    if (localIndex > -1) {
      localSelectedCategories.splice(localIndex, 1);
    } else {
      localSelectedCategories.push(categoryId);
    }

    setSelectedCategories(localSelectedCategories);
    if (fullObject) {
      const newSelectedListWithObjects = totalCategoryList.filter((each) => localSelectedCategories.includes(each.id));
      onChange(multiple ? newSelectedListWithObjects : newSelectedListWithObjects[0]);
    } else {
      onChange(multiple ? localSelectedCategories : localSelectedCategories[0]);
    }
  };

  return (
    <TreeCategorySelector
      // categories
      categoryList={categoryList}
      // parent category
      categories={selectedCategories}
      // selected category management
      handleSelectedCategories={handleSelectedCategories}
      handleTreeCategorySelector={onChange}
      updateUniqueTotalCategoryList={updateUniqueTotalCategoryList}
      noProductsOnly={noProductsOnly}
      htmlId={id}
      exclude={exclude}
    />
  );
};

TreeCategorySelectorContainer.defaultProps = {
  multiple: false,
  fullObject: false,
  noProductsOnly: false,
  options: [],
  id: "cat-tree",
  exclude: [],
};

TreeCategorySelectorContainer.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]).isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  fullObject: PropTypes.bool,
  noProductsOnly: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  id: PropTypes.string,
  exclude: PropTypes.arrayOf(PropTypes.any),
};

export default TreeCategorySelectorContainer;
