
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React from "react";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";


const EditStoreHours = (props) => {
  const {
    handleFormObjectChange, workingHours, translatableFields, selectedLanguage,
  } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "store"]);

  return (
    <>
      {workingHours && (
        <Row>
          <Col xs="12" sm="11" md="3">
            <FormGroup>
              <Label className="formTextLabel font-weight-bold">
                {t("store:label_day_monday")}
              </Label>
              <Input
                className="formText"
                placeholder={t("store:placeholder_opening_hours")}
                name="workingHours"
                value={workingHours.Monday}
                onChange={(o) => handleFormObjectChange(workingHours, "Monday", o)}
                disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_AE" && !translatableFields.includes("workingHours") : false}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="11" md="3">
            <FormGroup>
              <Label className="formTextLabel font-weight-bold">
                {t("store:label_day_tuesday")}
              </Label>
              <Input
                className="formText"
                placeholder={t("store:placeholder_opening_hours")}
                name="workingHours"
                value={workingHours.Tuesday}
                onChange={(o) => handleFormObjectChange(workingHours, "Tuesday", o)}
                disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_AE" && !translatableFields.includes("workingHours") : false}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="11" md="3">
            <FormGroup>
              <Label className="formTextLabel font-weight-bold">
                {t("store:label_day_wednesday")}
              </Label>
              <Input
                className="formText"
                placeholder={t("store:placeholder_opening_hours")}
                name="workingHours"
                value={workingHours.Wednesday}
                onChange={(o) => handleFormObjectChange(workingHours, "Wednesday", o)}
                disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_AE" && !translatableFields.includes("workingHours") : false}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="11" md="3">
            <FormGroup>
              <Label className="formTextLabel font-weight-bold">
                {t("store:label_day_thursday")}
              </Label>
              <Input
                className="formText"
                placeholder={t("store:placeholder_opening_hours")}
                name="workingHours"
                value={workingHours.Thursday}
                onChange={(o) => handleFormObjectChange(workingHours, "Thursday", o)}
                disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_AE" && !translatableFields.includes("workingHours") : false}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="11" md="3">
            <FormGroup>
              <Label className="formTextLabel font-weight-bold">
                {t("store:label_day_friday")}
              </Label>
              <Input
                className="formText"
                placeholder={t("store:placeholder_opening_hours")}
                name="workingHours"
                value={workingHours.Friday}
                onChange={(o) => handleFormObjectChange(workingHours, "Friday", o)}
                disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_AE" && !translatableFields.includes("workingHours") : false}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="11" md="3">
            <FormGroup>
              <Label className="formTextLabel font-weight-bold">
                {t("store:label_day_saturday")}
              </Label>
              <Input
                className="formText"
                placeholder={t("store:placeholder_opening_hours")}
                name="workingHours"
                value={workingHours.Saturday}
                onChange={(o) => handleFormObjectChange(workingHours, "Saturday", o)}
                disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_AE" && !translatableFields.includes("workingHours") : false}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="11" md="3">
            <FormGroup>
              <Label className="formTextLabel font-weight-bold">
                {t("store:label_day_sunday")}
              </Label>
              <Input
                className="formText"
                placeholder={t("store:placeholder_opening_hours")}
                name="workingHours"
                value={workingHours.Sunday}
                onChange={(o) => handleFormObjectChange(workingHours, "Sunday", o)}
                disabled={Array.isArray(translatableFields) ? selectedLanguage !== "en_AE" && !translatableFields.includes("workingHours") : false}
              />
            </FormGroup>
          </Col>
        </Row>
      )}

    </>
  );
};

EditStoreHours.propTypes = {
  workingHours: PropTypes.objectOf(PropTypes.any).isRequired,
  handleFormObjectChange: PropTypes.func.isRequired,
  translatableFields: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default EditStoreHours;
