/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
import BadgesManagementContainer from "./containers/BadgesManagementContainer";
import AddBadgeContainer from "./containers/AddBadgeContainer";
import EditBadgeContainer from "./containers/EditBadgeContainer";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
import PrivateRouter from "../../common/routes/PrivateRouter";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */

/** components */

const BadgesManagement = ({ match: { path } }) => (
  <Switch>
    <PrivateRouter
      exact
      path={`${path}`}
      component={BadgesManagementContainer}
    />
    <PrivateRouter exact path={`${path}/new`} component={AddBadgeContainer} />
    <PrivateRouter
      exact
      path={`${path}/edit/:badgeId`}
      component={EditBadgeContainer}
    />
    {/* <PrivateRouter exact path="" component={NotFound} /> */}
  </Switch>
);

BadgesManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default BadgesManagement;
