/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Permission Management
 *
 * @author Naseef O
 *
 */
import React from "react";
import clsx from "clsx";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/PermissionManagement.module.css";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";

const customRows = [
  {
    label: "Module",
    value: "",
    path: "module",
    transform: "",
  },
];

const customizationData = [
  {
    module: "Product Management",
  },
  {
    module: "Category Management",
  },
  {
    module: "Site Navigation",
  },
  {
    module: "Reports",
  },
  {
    module: "Order Management",
  },
];

const PermissionManagement = () => (
  <>
    <Row className={clsx(styles.block1, "common-page-card pageHeader")}>
      <Col xs="12">
        <span className="pageText">Manage Permissions</span>
      </Col>
    </Row>
    <Row className={clsx(styles.block2, "common-page-card")}>
      <Col xs="12">
        <span className="fontsizeMediumBold"> Modules</span>
        <Button
          className={clsx(styles.buttonAdd, "bg-color-tertiary-main")}
        >
          New Module
        </Button>
      </Col>
    </Row>
    <Row className={clsx(styles.tableWrapper, "common-page-card")}>
      <Col xs="12">
        <div className="custom-table ">
          <CustomizationTable
            customRows={customRows}
            customizationData={customizationData}
            isStatusEnable
            isDeleteEnable
            isEditEnable
          />
        </div>
      </Col>
    </Row>
  </>
);

export default PermissionManagement;
