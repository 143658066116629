/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Label from "../../../common/core/Label";
import Modal from "../../../common/core/Modal";
import Input from "../../../common/core/Input";
import Button from "../../../common/core/Button";
import ModalBody from "../../../common/core/ModalBody";
import FormGroup from "../../../common/core/FormGroup";
import ModalFooter from "../../../common/core/ModalFooter";
import FormFeedback from "../../../common/core/FormFeedback";
import constants from "../../../common/utils/constants";

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/Select";
import validateForm from "../../../common/utils/validateForm";

/** ============ SVG IMAGE ICON ===================== */
import ImageIcon from "../../../common/assets/images/svg/image.svg";

/** ========= SUB COMPONENT ========= */

/** ========= MODULE STYLES ========= */
import styles from "../css/MenuItemCreator.module.css";


const MenuItemCreator = (props) => {
  const {
    open, menu, name, url, allowDropdown,
    type, onEdit, navigable, isWebResponsiveHeader,
    image, isWebFooter, menus, menuTypes,
    onSubmitting, handleChange, handleFormChange,
    handleImageSelection, formCancel, formSubmit,
    handleRootMenuChange,
  } = props;
  const navigationItemTypes = constants.NAVIGATION_ITEM_TYPES;
  /** internationalization using i18n from common and add menu  */
  const { t } = useTranslation(["common", "site-navigation"]);

  const validator = validateForm();
  const { handleSubmit, register, errors } = validator;

  return (
    <div>
      <Modal isOpen={open} toggle={() => formCancel()} className={styles.form}>
        <Form onSubmit={handleSubmit(formSubmit)}>
          <fieldset disabled={onSubmitting}>
            <div className={clsx("bg-light", "modal-header", styles.header)}>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label className={clsx("formTextLabel", styles.formLabel)}>
                      {t("site-navigation:label_menu")}
                    </Label>
                    <Select
                      className="formText"
                      placeholder={t("site-navigation:placeholder_menu")}
                      name="menu"
                      id="submenu_menu"
                      value={menu}
                      onChange={handleRootMenuChange}
                      options={[
                        { value: "", disabled: true, label: t("site-navigation:placeholder_menu") },
                        ...(Array.isArray(menus)
                          && menus.map(({ id: value, name: label }) => ({ value, label }))),
                      ]}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <ModalBody>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label className={clsx("formTextLabel", styles.formLabel)}>
                      {t("site-navigation:label_menu_title")}
                      <span className="pl-1 text-danger">*</span>
                    </Label>
                    <Input
                      className="formText w-100"
                      placeholder={t("site-navigation:placeholder_menu_title")}
                      name="name"
                      defaultValue={name}
                      onChange={handleFormChange}
                      innerRef={
                        register({
                          required: t("site-navigation:validation_name_required"),
                        })
                      }
                      invalid={!!(errors.name && errors.name.message)}
                    />
                    <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label className="formTextLabel">{t("common:label_type")}</Label>
                    <Select
                      className="formText"
                      placeholder={t("site-navigation:placeholder_type")}
                      name="type"
                      id="id-of-menu-type"
                      onChange={handleFormChange}
                      value={type}
                      disabled={type === navigationItemTypes.system}
                      options={[
                        { value: 0, disabled: true, label: t("site-navigation:placeholder_type") },
                        ...(
                          Array.isArray(menuTypes)
                          && menuTypes.map((each) => {
                            if (each === navigationItemTypes.system) {
                              return {
                                value: each,
                                label: each,
                                disabled: true,
                              };
                            }
                            return {
                              value: each,
                              label: each,
                            };
                          })
                        ),
                      ]}
                      innerRef={
                        register({
                          required: t("site-navigation:validation_type_required"),
                        })
                      }
                      invalid={!!(errors.type && errors.type.message)}
                    />
                    <FormFeedback>{errors.type && errors.type.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <div className={clsx("formTextLabel", "d-flex", styles.formLabel)}>
                      <span>
                        {t("site-navigation:label_menu_url")}
                      </span>

                      <FormGroup className={clsx("ml-auto p-o my-0", "d-flex")}>
                        <Label className="formTextLabel">{t("site-navigation:label_navigable")}</Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-3",
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="submenu_navigable"
                            title={t("site-navigation:placeholder_navigable")}
                            name="navigable"
                            checked={navigable}
                            readOnly
                            onChange={() => handleChange("navigable", !navigable)}
                          />
                          <Label className="custom-control-label my-0" htmlFor="submenu_navigable" />
                        </div>
                      </FormGroup>
                    </div>
                    <Input className="formText w-100" disabled={!navigable} placeholder={t("site-navigation:placeholder_menu_url")} name="url" value={url} onChange={handleFormChange} />
                  </FormGroup>
                </Col>
                {
                  isWebResponsiveHeader && (
                    <Col xs="12">
                      <FormGroup>
                        <Label className={clsx(styles.imageUpload, "commonPointer")} htmlFor="promotion-image-upload">
                          <input onChange={handleImageSelection} type="file" className="d-none" id="promotion-image-upload" />
                          {
                            image ? (
                              <div>
                                <img src={image} alt="" className="img-thumbnail" />
                              </div>
                            )
                              : (
                                <div className="px-5 py-4">
                                  <div className="p-4">
                                    <img
                                      src={ImageIcon}
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <h5 className="mb-2">
                                      Drag & Drop or
                                      <span className={clsx(styles.primaryTextColor, "pl-1")}>Upload Image</span>
                                    </h5>
                                    <p className={styles.secondaryTextColor}>
                                      We support JPG and PNG files.
                                      Make sure that your files is no more than 500kb.
                                    </p>
                                  </div>
                                </div>
                              )
                          }
                        </Label>
                      </FormGroup>
                    </Col>
                  )
                }
                {
                  !(isWebFooter || isWebResponsiveHeader) && (
                    <Col xs="12">
                      <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                        <Label className="formTextLabel">{t("site-navigation:label_allow_dropdown")}</Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-3",
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="submenu_allowDropdown"
                            title={t("site-navigation:placeholder_allow_dropdown")}
                            name="allowDropdown"
                            checked={allowDropdown}
                            onChange={() => handleChange("allowDropdown", !allowDropdown)}
                          />
                          <Label className="custom-control-label" htmlFor="submenu_allowDropdown" />
                        </div>
                      </FormGroup>
                    </Col>
                  )
                }
              </Row>
            </ModalBody>
            <ModalFooter className="border-top-0">
              <Button type="button" onClick={() => formCancel(true)} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                {t("common:button_cancel")}
              </Button>
              <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                {
                  onEdit ? t("common:button_update") : t("common:button_save")
                }
              </Button>
            </ModalFooter>
          </fieldset>
        </Form>
      </Modal>
    </div>
  );
};

MenuItemCreator.propTypes = {
  open: PropTypes.bool.isRequired,
  menu: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  navigable: PropTypes.bool.isRequired,
  allowDropdown: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  menus: PropTypes.arrayOf(PropTypes.any).isRequired,
  menuTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  onEdit: PropTypes.bool.isRequired,
  isWebResponsiveHeader: PropTypes.bool.isRequired,
  isWebFooter: PropTypes.bool.isRequired,
  /** form methods */
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  formCancel: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  handleRootMenuChange: PropTypes.func.isRequired,
  handleImageSelection: PropTypes.func.isRequired,
};

export default MenuItemCreator;
