/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BasicJobConfiguration from "../components/BasicJobConfiguration";
import { getJobListScope, getLocationsByBrand, getJobListStatus } from "../../../api/jobConfigurationService";

const BasicJobConfigurationContainer = ({
  setSelectedTab,
  handleFormChange,
  form,
  cancelJobCreation,
}) => {
  const handleNext = () => {
    setSelectedTab("JOB_TASK");
  };

  const [scopeList, setScopeList] = useState([]);
  const [alertList, setAlertList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    getJobListScope().then(async (response) => {
      if (response && response.success && response.data) {
        const respoJobListScope = response.data;
        setScopeList(respoJobListScope);
      }
    });
    getJobListStatus().then(async (response) => {
      if (response && response.success && response.data) {
        const respoJobListStatus = response.data;
        setAlertList(respoJobListStatus);
      }
    });
    getLocationsByBrand().then(async (response) => {
      const locationsList = [];
      if (response && response.success && response.data) {
        const locations = response.data;
        locations.map((location) => {
          locationsList.push({ label: location.name, value: location.id });
          return locationsList;
        });
        setLocationList(locationsList);
      }
    });
  }, []);

  return (
    <BasicJobConfiguration
      handleNext={handleNext}
      cancelJobCreation={cancelJobCreation}
      handleFormChange={handleFormChange}
      form={form}
      scopeList={scopeList}
      locationList={locationList}
      alertList={alertList}
    />
  );
};

BasicJobConfigurationContainer.propTypes = {
  cancelJobCreation: PropTypes.func.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BasicJobConfigurationContainer;
