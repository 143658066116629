/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Badges Management Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BadgesManagement from "../components/BadgesManagement";
import { getBadges, deleteBadge, activateCustomBadge, deactivateCustomBadge } from "../../../api/badgeService";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

const BadgesManagementContainer = () => {
  const history = useHistory();

  const [badges, setbadges] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const paginationLimit = 5;
  const [selectedBadgeId, setSelectedBadgeId] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  /**
   * This method is used to list badges
   * @param {String} searchTerm
   * @param {Number} newPage
   * @param {Number} paginationLimit
   * @param {String} sortBy
   * @param {String} sortDirection
   */
  const getBadgeList = (
    searchTerm = "",
    newPage = 0,
    paginationLimit = 5,
    sortBy = "createdDate",
    sortDirection = "desc"
  ) => {
    getBadges(searchTerm, newPage, paginationLimit, sortBy, sortDirection).then(
      (response) => {
        if (response && response.success === true) {
          const { data } = response;
          if (Array.isArray(data) && data.length > 0) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
          setbadges(data);
          setPage(Math.max(newPage, 0));
        }
      }
    );
  };

  /**
   * This method is used to trigger pagination
   * @param {Number} page
   */
  const getPageData = (direction) => {
    const newPage = page + direction;
    getBadgeList(searchTerm, Math.max(newPage, 0), paginationLimit);
  };

  /**
   * This method is used to update text in search box
   * @param {Event} event
   */
  const handleSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value && `${value}`.trim().length > 1) {
      getBadgeList(value);
    } else getBadgeList();
  };

  /**
   * This method is used to redirect to add ndw badges page
   */
  const newBadge = () => {
    history.push("/marketing/badges/new");
  };

  /**
   * This method is used to redirect to edit badge page
   * @param {String} badgeId
   */
  const editBadge = (badgeId) => {
    history.push(`/marketing/badges/edit/${badgeId}`);
  };

  const removeBadge = (badgeId) => {
    setSelectedBadgeId(badgeId);
    setOpenConfirmModal(true);
  };

  /**
   * This method is used to cancel confirm form
   */
  const cancelConfirm = () => {
    setSelectedBadgeId("");
    setOpenConfirmModal(false);
  };

  /**
   *  This function form delete badge
   */
  const confirmDelete = async () => {
    if (submitting) return;
    const response = await deleteBadge(selectedBadgeId);
    if (response && response.success) {
      const newBadges = badges.filter((badge) => badge.id !== selectedBadgeId);
      setbadges(newBadges);
    } else {
      if (response && response.messages) {
        setMessage({ type: "warning", message: response.messages[0] });
      }
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
    setSelectedBadgeId("");
    cancelConfirm();
  };

  /** getting list of coupos when page load */
  useEffect(() => {
    getBadgeList();
  }, [paginationLimit]);

  const updateStatusFlag = (active, id) => {
    if (active) {
      activateCustomBadge(id).then(async (response) => {
        if (response && response.success) {
          const newBadges = badges.map((badge) => (badge.id === id
            ? ({ ...badge, active }) : badge));
          setbadges(newBadges);
        }
      });
    }
    else {
      deactivateCustomBadge(id).then(async (response) => {
        if (response && response.success) {
          const newBadges = badges.map((badge) => (badge.id === id
            ? ({ ...badge, active }) : badge));
          setbadges(newBadges);
        }
      });
    }
  };

  return (
    <>
      <BadgesManagement
        badges={badges}
        submitting={submitting}
        getPageData={getPageData}
        page={page}
        hasMoreData={hasMoreData}
        searchTerm={searchTerm}
        handleSearchTerm={handleSearchTerm}
        newBadge={newBadge}
        editBadge={editBadge}
        removeBadge={removeBadge}
        message={message}
        handleStatusFlag={updateStatusFlag}
      />
      <ConfirmationModal
        isOpen={openConfirmModal}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this badge ?"
      />
    </>
  );
};
export default BadgesManagementContainer;
