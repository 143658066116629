/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React, { useState, useEffect } from "react";
import CartManagement from "../component/CartManagement";
import {
  getCartSettings,
  updateCartSettings,
} from "../../../api/cartSettingsService";
import {
  getSitePreviewConfigurations,
  replicateGlobalSettings,
  getGlobalSettingsReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";

const CartManagementContainer = () => {
  const [cartSettings, setCartSettings] = useState({
    maxAllowedCartAmount: "",
    maxAllowedItemsCount: "",
    cartValidity: "",
    anonymousCartValidity: "",
    maxAllowedItemsQuantity: "",
    maxAllowedPromotionAppliedItemsCount: "",
    maxAllowedQuantityPerOrder: "",
    maxAllowedQuantityPerProduct: "",
    minAllowedCartAmount: "",
    notificationEnabled: false,
    itemLevelRevival: false,
    orderLevelRevival: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [abandonCartOpen, setAdandonCartOpen] = useState(false);
  const [allowMinCartAmount, setAllowMinCartAmount] = useState(false);
  const [allowMaximumItemsCount, setAllowMaximumItemsCount] = useState(false);


  const fetchCartSettings = () => {
    getCartSettings().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        setAllowMaximumItemsCount(data.allowMaximumItemsCount);
        setAllowMinCartAmount(data.allowMinCartAmount);
        setCartSettings(data);
      }
    });
  };
  useState(() => {
    fetchCartSettings();
  }, []);

  /**
   * This method is used to change form state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setCartSettings({
      ...cartSettings,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };


  const saveCartSettings = async () => {
    const requestBody = {
      maxAllowedCartAmount: cartSettings.maxAllowedCartAmount,
      minAllowedCartAmount: cartSettings.minAllowedCartAmount,
      maxAllowedItemsCount: cartSettings.maxAllowedItemsCount,
      cartValidity: cartSettings.cartValidity,
      anonymousCartValidity: cartSettings.anonymousCartValidity,
      maxAllowedItemsQuantity: cartSettings.maxAllowedItemsQuantity,
      maxAllowedPromotionAppliedItemsCount: cartSettings.maxAllowedPromotionAppliedItemsCount,
      maxAllowedQuantityPerOrder: cartSettings.maxAllowedQuantityPerOrder,
      maxAllowedQuantityPerProduct: cartSettings.maxAllowedQuantityPerProduct,
      notificationEnabled: cartSettings.notificationEnabled,
      itemLevelRevival: cartSettings.itemLevelRevival,
      orderLevelRevival: cartSettings.orderLevelRevival,
      allowMinCartAmount,
      allowMaximumItemsCount,
    };
    const response = await updateCartSettings(requestBody);
    if (response && response.success) {
      const { data } = response;
      setCartSettings(data);
      setIsOpen(false);
      setAdandonCartOpen(false);
    }
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const handleAllowChangeMaxItemCount = () => {
    setAllowMaximumItemsCount(!allowMaximumItemsCount);
  };

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    replicateGlobalSettings().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message:
            "Global settings locations pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };

  const getReplicationStatus = async () => {
    getGlobalSettingsReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last global settings push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch(error => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  const handleAllowMinCartAmount = () => {
    setAllowMinCartAmount(!allowMinCartAmount);
  };

  return (
    <CartManagement
      cartSettings={cartSettings}
      handleFormChange={handleFormChange}
      saveCartSettings={saveCartSettings}
      fetchCartSettings={fetchCartSettings}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      abandonCartOpen={abandonCartOpen}
      setAdandonCartOpen={setAdandonCartOpen}
      handleChange={handleChange}
      pushToLiveEnabled={false}
      previewSiteUrl={previewSiteUrl}
      previewEnabled={false}
      message={message}
      handlePushToLive={handlePushToLive}
      pushToLiveButtonEnabled={pushToLiveButtonEnabled}
      getReplicationStatus={getReplicationStatus}
      setAllowChangeMaxItemsCount={handleAllowChangeMaxItemCount}
      allowMaximumItemsCount={allowMaximumItemsCount}
      allowMinCartAmount={allowMinCartAmount}
      setAllowMinCartAmount={handleAllowMinCartAmount}
    />
  );
};

export default CartManagementContainer;
