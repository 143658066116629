/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Button from "../../../common/core/Button";
import Spinner from "../../../common/core/Spinner";

import styles from "../css/OrderDetails.module.css";

const OrderNoteForm = ({
  id,
  orderNotes,
  onHandleNoteChange,
  onHandleNoteSubmit,
  submitting,
}) => {
  const { t } = useTranslation(["common", "order-note"]);
  return (
    <Col md="6" className="p-2">
      <p className="font-weight-bold">
        {t("order-note:order_note_title")}
      </p>
      <Row>
        <Col md="12">
          <FormGroup>
            <Label className="formTextLabel font-weight-bold">
              {t("order-note:label_comment")}
            </Label>
            <Input
              type="textarea"
              className="formText"
              name="name"
              value={orderNotes}
              onChange={(e) => onHandleNoteChange(e, id)}
            />
          </FormGroup>
          <Button
            type="submit"
            color="secondary"
            className={clsx(
              styles.borderRadius2,
              styles.btn,
              styles.btnColor,
              "text-truncate",
              "mr-1",
              "button-font-size",
            )}
            onClick={onHandleNoteSubmit}
          >
            {
                 submitting ? (
                   <Spinner size="sm" animation="border" role="status">
                     <span className="sr-only">Loading...</span>
                     {" "}
                   </Spinner>
                 ) : (
                   <span>
                     {t("order-note:button_submit_notes")}
                   </span>
                 )
                }
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

OrderNoteForm.propTypes = {
  id: PropTypes.string.isRequired,
  orderNotes: PropTypes.string.isRequired,
  onHandleNoteChange: PropTypes.func.isRequired,
  onHandleNoteSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default OrderNoteForm;
