/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */

import React from "react";
import PropTypes from "prop-types";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";

const TextBox = ({
  inputData, stateData, handleChange,
}) => {
  const {
    label, inputName, attributes,
  } = inputData;
  const inputFieldType = attributes ? attributes.inputFieldType : "text";
  return (
    <div className="col-sm-6 mt-2">
      <FormGroup>
        <div className="inputBox">
          <div className="inputText">
            <Label>{label}</Label>
          </div>
          <Input
            type={inputFieldType}
            name={inputName}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={stateData ? stateData[inputName] : null}
          />
        </div>
      </FormGroup>
    </div>
  );
};

TextBox.propTypes = {
  inputData: PropTypes.objectOf(PropTypes.any).isRequired,
  stateData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default TextBox;
