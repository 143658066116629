/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Deepak D
 */
import httpApi from "./httpApi";

/**
 *This method is used to get Shipping Method Configuration by id
 *
 * @param {*} id
 */
const getShippingMethodConfigurationById = async (id) => {
  const response = await httpApi.get(`/v1/shipments/configurations/id/${id}`);
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 *This method is used to get all Shipping Method Configurations
 *
 * @param {*} retailer
 * @param {*} brand
 */
const getShippingMethodConfigurations = async () => {
  const response = await httpApi.get("/v1/shipments/configurations");
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to get all shipping types available for Shipments
 */
const getShippingTypes = async () => {
  const response = await httpApi.get("/v1/shipments/types");
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to get all Shipping methods available for Shipments
 */
const getShippingMethods = async () => {
  const response = await httpApi.get("/v1/shipments/methods");
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to get all price strategies available for Shipments
 */
const getShippingPriceStrategies = async () => {
  const response = await httpApi.get("/v1/shipments/price-strategies");
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};

/**
 * This method is used to update Shipping Method Configurations
 *
 * @param {*} shippingMethodConfiguration
 * @returns
 */
const updateShippingMethodConfiguration = async (
  shippingMethodConfiguration
) => {
  const response = await httpApi.put(
    "/v1/shipments/configurations/",
    shippingMethodConfiguration
  );
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to set active flag 
 *
 * @returns
 */
const activateShippingMethod = async (id) => {
  const response = await
    httpApi.patch(`v1/shipments/configurations/id/${id}/activate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

const deactivateShippingMethod = async (id) => {
  const response = await
    httpApi.patch(`v1/shipments/configurations/id/${id}/deactivate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to get Shipping Method Configuration by fulfillmentType
 *
 * @param {*} fulfillmentType
 */
const getShippingMethodConfigurationsByFulfillmentType = async (fulfillmentType) => {
  const response = await httpApi.get(`/v1/shipments/configurations/${fulfillmentType}/fulfillment-type`);
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};

export {
  getShippingMethodConfigurationById,
  getShippingMethodConfigurations,
  getShippingTypes,
  getShippingMethods,
  getShippingPriceStrategies,
  updateShippingMethodConfiguration,
  activateShippingMethod,
  deactivateShippingMethod,
  getShippingMethodConfigurationsByFulfillmentType
};
