/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anoop G
 */

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ======== CUSTOM COMPONENTS ========= */
import ProductRankManagement from "../components/ProductRankManagement";

/** ===== API SERVICE FUNCTIONS =========== */
import { getProductsByCategory, updateProduct } from "../../../api/productManagementServices";

const ProductRankManagementContainer = ({ categoryId, locale }) => {
  const [products, setProducts] = useState([]);
  const [totalElements, setTotalElements] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);

  const paginationLimit = 5;

  /**
   * This method is sued to get product list from server
   * @param {String} categoryId
   */
  const getProductListFromSever = () => {
    if (categoryId !== "") {
      getProductsByCategory(categoryId, page, paginationLimit).then((response) => {
        if (response && response.success) {
          const { data } = response;
          if (Array.isArray(data.content)) {
            setProducts(data.content);
          }
          if (data.totalElements > 0) {
            setTotalElements(data.totalElements);
          }
          if (data.totalPages > 1) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
        }
      });
    }
  };

  /**
   *
   * @param {*} direction
   */
  const handleRankChange = (product, rank) => {
    const productRank = new Map();
    productRank[categoryId] = rank;
    product.productRank = { ...product.productRank, ...productRank };
    updateProduct(product, locale);
  };
  /**
   * This method is used to open new product
   * This method will trigger when pagination button click
   * @param {Number} page
   */
  const getPageData = (direction) => {
    getProductsByCategory(categoryId, page + direction, paginationLimit).then((response) => {
      if (response && response.success) {
        const { data } = response;
        setPage(page + direction);
        if (Array.isArray(data.content)) {
          setProducts(data.content);
        }
        if (data.endOfResult) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
        }
      }
    });
  };

  useEffect(() => {
    getProductListFromSever();
  }, [categoryId]);

  return (
    <ProductRankManagement
      products={products}
      totalElements={totalElements}
      categoryId={categoryId}
      page={page}
      getPageData={getPageData}
      hasMoreData={hasMoreData}
      handleRankChange={handleRankChange}
    />
  );
};

ProductRankManagementContainer.propTypes = {
  categoryId: PropTypes.string,
};

ProductRankManagementContainer.defaultProps = {
  categoryId: "",
};

export default ProductRankManagementContainer;
