/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * HTTP API interface
 * @module
 *
 */
import axios from "axios";
import config from "../config";
import UserData from "../common/security/UserData";
import { getHistory } from "../common/utils/globalHistory";

const {
  api: { baseURL },
} = config;

const authHeader = () => {
  const authToken = UserData.getToken();
  if (authToken) {
    return {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };
  }
  return {};
};

const handleHttpError = (httpError) => {
  const history = getHistory();
  if (httpError.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { status } = httpError.response;
    if (status === 401) {
      UserData.clearAll();
      if (history) { history.reload(); }
    } else if (status === 403) {
      // history.replace(`/error/${403}`);
    } else if (status === 500) {
      console.log("INTERNAL_SERVER_ERROR");
    }
    return httpError.response;
  }
  if (httpError.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(httpError.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", httpError.message);
  }
};

const instance = axios.create({
  baseURL,
  withCredentials: true,
});

// Request interceptor
instance.interceptors.request.use(
  (reqConfig) => {
    const { headers } = { reqConfig };
    const newConfig = { ...reqConfig };
    const authHeaders = authHeader();
    newConfig.headers = { ...headers, ...authHeaders };
    console.log("Request config: ", newConfig);
    return newConfig;
  },
  (error) => {
    const { response } = error;
    console.log("Request error: ", response);
    return response;
    // Promise.reject(error);
  },
);

//  Response interceptor
instance.interceptors.response.use(null, (error) => {
  handleHttpError(error);
  const { response } = error;
  console.log("Response error: ", response);
  return response;
  // Promise.reject(error);
});

export default {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  patch: instance.patch,
  delete: instance.delete,
  apiBaseUrl: instance.defaults.baseURL,
};
