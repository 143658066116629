/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */


import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import validateForm from "../../../common/utils/validateForm";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Nav from "../../../common/core/Nav";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Label from "../../../common/core/Label";
import Alert from "../../../common/core/Alert";
import Button from "../../../common/core/Button";
import NavLink from "../../../common/core/NavLink";
import NavItem from "../../../common/core/NavItem";
import TabPane from "../../../common/core/TabPane";
import FormGroup from "../../../common/core/FormGroup";
import TabContent from "../../../common/core/TabContent";

/** ========= SUB COMPONENT ========= */
import BrandForm from "./BrandForm";
import ImageSelection from "../../categoryManagement/containers/ImageSelectionContainer";
import ProductSelectionContainer from "../../categoryManagement/containers/ProductSelectionContainer";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */

/** ========= MODULE STYLES ========= */
import styles from "../css/BrandCreatorView.module.css";

const BrandEditorView = (props) => {
  const {
    submitting, id, name, longDescription, description, startDate, endDate, status, exclusiveBrand, bannerImage, image, disableDelete, childProductDetails, logo,
    handleFormChange, handleChange,
    formCancelHandler, formSubmitHandler, formDeleteHandler,
  } = props;

  /** * internationalization using i18n from common and brand ** */
  const { t } = useTranslation(["common", "brand"]);

  /** * form validation ** */
  const validator = validateForm();
  const { handleSubmit, errors } = validator;


  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const errorArray = errors ? Object.entries(errors) : [];

  return (
    <Form onSubmit={handleSubmit(formSubmitHandler)}>
      <fieldset disabled={submitting}>

        {/** * ============= PAGE HEADER ================ ** */}
        <Row className="pageHeader common-page-card ">
          <Col xs="12">
            <Row className="m-0 ">
              <Col xs="6" md="8" lg="7" className="p-0">
                <span className="pageText">{t("brand:title_add_brand")}</span>
              </Col>
              <Col xs="6" md="4" lg="5" className="d-flex justify-content-end">
                {
                 false && (
                 <Button disabled={disableDelete} type="button" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.customColorForDelete, "text-truncate", "mr-1", "button-font-size")} onClick={formDeleteHandler}>
                   {t("common:button_delete")}
                 </Button>
                 )
               }
                <Button type="button" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")} onClick={formCancelHandler}>
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {t("common:button_update")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {
          errorArray && activeTab !== "1" && errorArray.map((error) => (
            <Alert color="danger" className="mb-2 p-2">
              <h5 className="m-0 alert-heading text-capitalize">{error && error[0]}</h5>
              <p className="m-0">
                {error && error[1] && error[1].message || "Invalid input"}
                <span className="pl-1">in details page</span>
                {" "}
              </p>
            </Alert>
          ))
        }

        <Nav tabs>
          <NavItem
            active={activeTab === "1"}
            className={clsx(styles.mw25, "cursor-pointer", "text-center", activeTab === "1" ? "bg-white" : " bg-light")}
          >
            <NavLink
              className="border-0"
              onClick={() => { toggle("1"); }}
            >
              Details
            </NavLink>
          </NavItem>
          <NavItem
            active={activeTab === "2"}
            className={clsx(styles.mw25, "cursor-pointer", "text-center", activeTab === "2" ? "bg-white" : " bg-light")}
          >
            <NavLink
              className="border-0"
              onClick={() => { toggle("2"); }}
            >
              Products
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="p-0">
            {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
            <div className="common-page-card">
              {/** * ============= PAGE BODY ================ ** */}
              <Row className={clsx(styles.blockWrapper, " common-page-card ")}>
                <Col xs="12">
                  <BrandForm
                    validator={validator}
                    id={id}
                    name={name}
                    longDescription={longDescription}
                    description={description}
                    startDate={startDate}
                    endDate={endDate}
                    status={status}
                    exclusiveBrand={exclusiveBrand}
                    handleFormChange={handleFormChange}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className={clsx(styles.blockWrapper, " common-page-card ")}>
                <Col>
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label className="formTextLabel">
                          Logo
                        </Label>
                        <ImageSelection images={logo} aspectRatio={212 / 136} handleChange={(o) => { handleChange("logo", o); }} />
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <Label className="formTextLabel">
                          Banner Image
                        </Label>
                        <ImageSelection images={bannerImage} single={false} aspectRatio={235 / 88} handleChange={(o) => { handleChange("bannerImage", o); }} />
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <Label className="formTextLabel">
                          Image
                        </Label>
                        <ImageSelection images={image} single={false} aspectRatio={1} handleChange={(o) => { handleChange("image", o); }} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tabId="2" className="p-0">
            <div className="common-page-card">
              <div className="py-2">
                {/*  ====== ADD PRODUCTS S[0003]============ */}
                <ProductSelectionContainer
                  selectedProductDetails={childProductDetails}
                  onChange={(p) => handleChange("childProducts", p)}
                />
              </div>
            </div>
          </TabPane>
        </TabContent>
      </fieldset>
    </Form>
  );
};

BrandEditorView.defaultProps = {
  logo: null,
  image: undefined,
  bannerImage: undefined,
  disableDelete: false,
};

BrandEditorView.propTypes = {
  submitting: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  exclusiveBrand: PropTypes.bool.isRequired,
  image: PropTypes.objectOf(PropTypes.any),
  bannerImage: PropTypes.objectOf(PropTypes.any),
  disableDelete: PropTypes.bool,
  childProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  logo: PropTypes.objectOf(PropTypes.any),
  // functions
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  formCancelHandler: PropTypes.func.isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
  formDeleteHandler: PropTypes.func.isRequired,
};

export default BrandEditorView;
