/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import styles from "../css/DocumentationGuide.module.css";

const Administering = () => (
  <>
    <Row>
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Row className="pageHeader  common-page-card">
          <Col xs="12" md="6" className="text-truncate">
            <span className={styles.pageHeader}>
              Administering your organisation
            </span>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Managing store locations
              <br />
            </span>
            <div className={styles.pageText}>
              <b>Login > Dashboard> Administration</b>
              <br />
              <br />
              <b>You will see the following links on this page.</b>
              <br />
              <ul>
                <li>User Management</li>
                <li>Tax Configuration</li>
                <li>Store Settings</li>
                <li>Job Scheduling</li>
                <li>Global Settings</li>
                <li>Search Configuration</li>
                <li>Shipping Configuration</li>
              </ul>
              <br />
              For Managing store locations, click on the store settings link.
              <br />
              <br />
              <b>
                Login > Dashboard> Administration > Store Settings > Store
                Location
              </b>
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image48.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <ul>
                <li>You can add, edit or delete store locations. </li>
                <li>To delete a store location, click on the delete icon</li>
                <li>
                  The edit and add store works in a very similar fashion. To Add
                  a new store - Click on the “ ADD NEW STORE”. You are led to a
                  similar page as edit.{" "}
                </li>
                <li>
                  To edit a store, click the “edit” icon against any of the
                  existing stores listed in the main screen.{" "}
                </li>
                <li>
                  Both add and edit options will take you to the store details
                  screen.
                </li>
              </ul>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image72.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <b>Store Details:</b>
              <br />
              The basic details of the store appear here. You can enter the
              details that include:
              <ul>
                <li>Name of the Store</li>
                <li>Store ID</li>
                <li>
                  Store events (Anything specific to the store with respect to
                  merchandising - like designer available, exclusive products
                  available, offers specific to the store etc.)
                </li>
                <li>In-Store services</li>
              </ul>
              <br />
              <b>Store Classification :</b>
              <br />
              The store can be classified as follows.
              <ul>
                <li>Fulfilment Store - Is the inventorystore.</li>
                <li>
                  DNC Available - Drive and Collect option available in the
                  store.
                </li>
                <li>
                  Allow click and collect - Purchase can be done online and the
                  product can be collected at the store.
                </li>
              </ul>
              <br />
              <br />
              <b>Display Store in storefront </b>
              - Use this radio button to display the corresponding store in the
              Store locator. Stores added with this option selected will appear
              on the store locator page. The store locator page can be accessed
              by clicking on the store icon (first among the three) icons
              displayed on the header. The second and third icons are wishlist
              and bag respectively.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image18.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <b>Store Contact & Location </b>
              - The address, Contact number, email and the location map can be
              edited here.
              <br />
              <b>SEO Information </b>
              - The page title and description for SEO optimization can be
              updated here.
              <br />
              <b> Opening Hours </b>
              - The timing of the store on each day of the week can be edited
              here.
              <br />
              After entering the data click on the <b>“UPDATE”</b> button.
              <br />
              The newly added or edited store details get saved and listed on
              the Store Locations page.
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Managing Cart Settings
              <br />
              <br />
            </span>
            <div className={styles.pageText}>
              The cart setting menu helps you to configure the cart for amount
              limits, item limit, cart retainment limits etc.
              <br />
              <b>
                Login> Dashboard> Administration> Global Settings> Cart Settings
              </b>
              <br />
              <br />
              Go to cart settings to set limits on the amount / no of items etc
              in cart at a time.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image8.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The attributes in a cart are –
              <ul>
                <li>Limit Maximum Amount</li>
                <li>Limit Maximum Items in a Cart</li>
                <li>Cart Items Retains for logged in Users (In Days)</li>
                <li>Cart Items Retains for Guest Users (in Days)</li>
                <li>Limit Maximum Promotion Applied Items in cart</li>
              </ul>
              <br />
              For Example -
              <br />
              Limit Maximum Amount
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image80.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              If the amount in the cart exceeds the set limit the check out is
              locked.
              <br />
              Limit Maximum Product in a cart
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image43.png"
                className={styles.imgSize}
              />
              <br />
              <br />
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Configuring Shipping Methods
              <br />
              <br />
            </span>
            <div className={styles.pageText}>
              <b>
                Login > Dashboard> Administration > Shipping Configuration >
                Shipping Methods
              </b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image76.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              To edit the Name, Description, Display Message, Tax, rate etc
              regarding the shipping of products click on the edit icon in the
              corresponding row.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image71.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Here you can edit all the attributes pertaining to the shipping.
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default Administering;
