/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CombineWithSelectorView from "../components/CombineWithSelectorView";

import { getCombineWith } from "../../../api/promotionServices";


const CombineWithSelector = (props) => {
  const {
    value, name, validator, message,
    onChange, disabled,
  } = props;

  /** validation methods */
  const {
    register, errors, triggerValidation, clearError,
  } = validator;

  /** local state */
  const [combineWithItems, setPromotionTypes] = useState([]);
  const [validatePreventer, setValidatePreventer] = useState(true);

  useEffect(() => {
    /** use to get product type by default */
    getCombineWith().then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        setPromotionTypes(response.data);
      }
    });
  }, []);

  /**
   * This callback function will trigger every time value changes
   * and manually validate the custom validator
   */
  const handleValidation = () => {
    if (!validatePreventer) { /** preventing default showing error, even before component change  */
      triggerValidation(name).then((isValid) => {
        if (isValid) { clearError(name); }
      });
    }
  };

  useEffect(handleValidation, [value, validatePreventer]);

  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
    setValidatePreventer(false);
  };

  /**
   * This function is used to validate filed
   * @param {String} selectedValue
   */
  const validate = (selectedValue) => {
    const validateMessage = (message && message.validate) || "";
    return `${selectedValue}`.trim() ? true : validateMessage;
  };
  return (
    <CombineWithSelectorView
      combineWithItems={combineWithItems}
      selectedPromotionType={value}
      onChange={localOnChange}
      name={name}
      innerRef={register({
        validate,
      })}
      invalid={!!(errors[name] && errors[name].message)}
      disabled={disabled}
    />
  );
};


CombineWithSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
    clearError: PropTypes.func,
    triggerValidation: PropTypes.func,
  }).isRequired,
  message: PropTypes.shape({
    validate: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CombineWithSelector;
