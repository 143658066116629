/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Dipin
 */
import httpApi from "./httpApi";
import config from "../config";
import authApi from "./authServerApi";

const {
  api: { loginURL, logoutURL },
} = config;

/**
 *This method is used to login
 *
 * @param {*} loginData
 */
const login = async (loginData) => {
  const tokenResponse = await authApi.login(loginData, httpApi.apiBaseUrl);
  return tokenResponse;
};

/**
 *This method is used to logout
 */
const logout = async () => {
  const response = await httpApi.get(logoutURL);
  if (response && response.data) {
    const { data } = response;
    return data;
  }
  return null;
};
/**
 * This method is used to get user List
 *
 * @returns
 */

const getUserList = async (roles = "", active = "", page = 0, size = 10) => {
  const response = await httpApi.get("v1/users/list", {
    params: {
      roles,
      active,
      page,
      size,
    },
  });
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};
export {
  login,
  logout,
  getUserList,
};
