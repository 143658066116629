/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Fulfillment Method Management
 *
 * @author Anagha
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import { Label } from "reactstrap";
import styles from "../css/fulfillmentMethod.module.css";
import CustomizationTable from "../../../common/components/Table";
import Form from "../../../common/core/Form";
import validateForm from "../../../common/utils/validateForm";

const FulfillmentMethod = ({
  submitForm,
  submitting,
  fulfillmentMethodConfigurations,
  onHandleEditFulfillment,
  onStatusChange,
}) => {
  const { t } = useTranslation(["common", "fulfillment-method"]);
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(e) => onStatusChange(!row.active, row.id, e)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };
  const customRows = [
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Description",
      value: "",
      path: "description",
      transform: "",
    },
    {
      label: "Display Message",
      value: "",
      path: "displayMessage",
      transform: "",
    },
    {
      label: "Status",
      path: "render",
      render: getStatus,
    },
  ];
  const validator = validateForm();
  const { handleSubmit } = validator;

  return (
    <>
      <Form onSubmit={handleSubmit(submitForm)}>
        <fieldset disabled={submitting}>
          <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
            <Col xs="8">
              <span className="pageText">{t("fulfillment-method:fulfillment_method_management_title")}</span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
            <Col xs="8">
              <span className="pageText">{t("fulfillment-method:fulfillment_method_title")}</span>
            </Col>
            <Col xs="12" md="4" lg="5" />
          </Row>
          <Row className={clsx(styles.tableWrapper, "common-page-card")}>
            <Col xs="12">
              <div className={clsx(styles.tableWrapper, "custom-table")}>
                <CustomizationTable
                  customRows={customRows}
                  customizationData={fulfillmentMethodConfigurations}
                  isEditEnable
                  onEditData={onHandleEditFulfillment}
                />
              </div>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </>
  );
};

FulfillmentMethod.propTypes = {
  submitForm: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onHandleEditFulfillment: PropTypes.func.isRequired,
  fulfillmentMethodConfigurations: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FulfillmentMethod;
