/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * FeaturedProductsEditor
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import styles from "../css/FeaturedProductsEditor.module.css";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import ListGroup from "../../../common/core/ListGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";
import Button from "../../../common/core/Button";
import ProductsTable from "./ProductsTable";
import FormGroup from "../../../common/core/FormGroup";
import errorIcon from "../../../common/assets/images/svg/error.svg";

const FeaturedProductsEditor = (props) => {
  const {
    handleRedirectToPageCustomization,
    handleSubmit,
    products,
    selectedProducts,
    setProductSelected,
    searchKeyword,
    handleSearchFiled,
    selectedProductDetails,
    isLoading,
    selectedIndex,
    handleDelete,
    error,
  } = props;

  return (
    < >
      <Row className="common-page-card pageHeader">
        <Col xs="12" md="3" lg="3">
          <span className="pageText">
            Featured products
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
        <Col xs="12" md="5" lg="4" className="buttonGroup">
          <Row>
            <Col xs="6">
              <Button
                className="buttonCancel"
                onClick={() => handleRedirectToPageCustomization()}
              >
                Cancel
              </Button>
            </Col>
            <Col xs="6">
              <Button
                className="buttonSave bg-color-tertiary-main"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="common-page-card">
        <Col xs="12">
          <Row className={styles.block2}>
            <Col xs="12">
              <FormGroup>
                <InputGroup className={styles.search}>
                  <Input
                    placeholder="Search product ID, name"
                    value={searchKeyword}
                    onChange={handleSearchFiled}
                  />
                  <InputGroupAddon
                    addonType="append"
                    className="commonPointer"
                  >
                    <img
                      src={SearchIcon}
                      alt="search product"
                      className={styles.icon}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className={styles.block2}>
            <Col xs="12">
              {error.message && (
                <span className="d-flex align-items-center color-primary-dark ">
                  <span className="pr-1">{error.message}</span>
                  {" "}
                  <img src={errorIcon} alt="error" />
                </span>
              )}
              <ListGroup className="py-1 custom-scrollbar" flush>
                {
                  products && products.length > 0 ? products.map((product) => (
                    <ListGroupItem key={product.id} className="px-2 py-1 border-0 d-flex ">
                      <div className="custom-control custom-checkbox text-truncate">

                        <input
                          type="checkbox"
                          id={`productRadio${product.id}`}
                          checked={selectedProducts.includes(product.id)}
                          name={product.id}
                          className="custom-control-input"
                          readOnly
                        />
                        <Label
                          onClick={(o) => setProductSelected(product.id, o)}
                          className="custom-control-label text-black commonPointer mw-100 "
                          htmlFor={`productRadio${product.id}`}
                          title={product.name}
                        >
                          <span className="text-truncate d-block mw-100"><img className={styles.labelImg} src={product.medias[0] && product.medias[0].defaultMediaUrl} alt="product" /></span>
                          <span className="text-truncate d-block mw-100">{product.name}</span>
                        </Label>
                      </div>
                    </ListGroupItem>
                  ))
                    : (
                      <Row>
                        <Col xs="12" className="d-flex justify-content-center">
                          <span className="font-weight-bold"> No data found!</span>
                        </Col>
                      </Row>
                    )
                }
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, "common-page-card")}>
        <Col xs="12">
          <Row className="my-3">
            <Col xs="12">
              <span className={styles.title}>
                Products
              </span>
            </Col>
          </Row>
          {selectedProductDetails.length > 0 && (
            <Row className="custom-table m-0 ">
              <ProductsTable
                handleDelete={handleDelete}
                isLoading={isLoading}
                selectedIndex={selectedIndex}
                customizationData={selectedProductDetails}
              />
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};


FeaturedProductsEditor.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleRedirectToPageCustomization: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  setProductSelected: PropTypes.func.isRequired,
  searchKeyword: PropTypes.string.isRequired,
  handleSearchFiled: PropTypes.func.isRequired,
  selectedProductDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FeaturedProductsEditor;
