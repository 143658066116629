/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

/** ===== TAG COMPONENTS =========== */
import Input from "../../core/Input";
import Dropdown from "../../core/Dropdown";
import DropdownMenu from "../../core/DropdownMenu";
import DropdownToggle from "../../core/DropdownToggle";

/** ===== IMAGE ICONS =========== */
import ClearIcon from "../../assets/images/svg/clear.svg";


/** ================== MODULE STYLES ================ */
import styles from "./MultiSelect.module.css";


const Select = ({
  className, name, id, value, chipClass,
  options, onChange, render, innerRef, invalid, excluded,
  typeahead, typeaheadPlaceholder, disabled,
}) => {
  const hasTypeAhead = Boolean(typeahead);
  const localExcluded = Array.isArray(excluded) ? excluded : [];
  /** local state */
  const [selectedIds, setSelectedIds] = useState(Array.isArray(value) ? value : []);
  const [selectedValue, setSelectedValue] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [typeaheadText, setTypeaheadText] = useState("");

  /**
   * This method is used to stop event propagation
   * @param {Event} event
   */
  const stopPropagation = (event) => event.stopPropagation();
  /**
 * This method is used to change selected elements
 * @param {String} selected
 * @param {Event} event
 */
  const updateSelectedItems = (selected, event) => {
    event.stopPropagation();

    const localSelectedValues = selectedValue.map((each) => each.value);
    const index = localSelectedValues.indexOf(selected);
    if (index > -1) {
      localSelectedValues.splice(index, 1);
      if (hasTypeAhead) { setSelectedValue(selectedValue.filter((each) => each.value !== selected)); }
    } else {
      localSelectedValues.push(selected);
      if (hasTypeAhead) { setSelectedValue([...selectedValue, ...options.filter((each) => each.value === selected)]); }
    }
    setSelectedIds(localSelectedValues);
    onChange({ ...event, target: { value: localSelectedValues, name } });
    if (typeahead) {
      typeahead("");
      setTypeaheadText("");
    }
  };

  /**
   * Used to change selected option and toggle
   * @param {String} selected
   * @param {Event} Event
   */
  const toggleDropdown = (selected, event) => {
    updateSelectedItems(selected, event);
    setDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (Array.isArray(value) && value.length > 0 && hasTypeAhead) {
      if (Array.isArray(options) && options.length > 0) {
        setSelectedValue((oldState) => {
          const newOldState = oldState.filter((each) => (value.includes(each.value)));
          const newStateIds = newOldState.map((each) => each.value);
          return [...newOldState, ...options.filter((option) => !newStateIds.includes(option.value) && value.includes(option.value))];
        });
        setSelectedIds(value);
      }
    }
  }, [options, value, hasTypeAhead]);

  useEffect(() => {
    if (Array.isArray(options)) {
      /** fist priory check goes to option value and value noting found check for default selected option else fist element  */
      const defaultValue = options.filter((option) => (value.includes(option.value))) || options.filter((option) => (option.selected)) || [];
      if (!hasTypeAhead) {
        setSelectedValue(defaultValue);
      }
    }
  }, [value, options, hasTypeAhead]);


  useEffect(() => {
    if (typeahead && typeaheadText && typeaheadText !== "") {
      setDropdownOpen(true);
    } else {
      setDropdownOpen(false);
    }
  }, [options, typeahead, typeaheadText]);

  /**
   * This method is used to change input text filed type ahead
   * @param {Event} event
   */
  const handleTypeAhead = (event) => {
    stopPropagation(event);
    const { value } = event.target;
    setTypeaheadText(value);
    if (typeahead) typeahead(value);
  };

  return (
    <Dropdown
      id={id}
      name={name}
      isOpen={dropdownOpen}
      className={clsx(styles.dropDown, disabled ? styles.disabled : "", invalid ? styles.invalid : "")}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      disabled={disabled}
    >
      <DropdownToggle className={clsx(className, styles.wrapper, "form-control", "d-flex", "flex-wrap", (!options || options.length < 1) ? "" : styles.hasDropdown)} caret>
        {
          selectedValue.map((option) => (
            <span key={option.value} className={clsx(styles.chip, chipClass ? `${chipClass}` : "mw-100")} disabled={option.disabled}>
              <span className="text-truncate pr-1">
                {option.label}
              </span>
              <span
                className={styles.remove}
                onClick={(o) => !option.disabled && updateSelectedItems(option.value, o)}
                onKeyDown={() => {}}
                tabIndex={0}
                role="button"
              >
                <img src={ClearIcon} alt="remove" />
              </span>
            </span>
          ))
        }
        {
          hasTypeAhead && <Input placeholder={typeaheadPlaceholder} onFocus={stopPropagation} className={styles.typeahead} onChange={handleTypeAhead} value={typeaheadText} ref={innerRef} disabled={disabled} />
        }
      </DropdownToggle>
      <DropdownMenu className={clsx(styles.dropdownWrapper, (!options || options.length < 1) ? "d-none" : "")}>
        { Array.isArray(options) && options.filter((g) => !localExcluded.includes(g.value)).map((option) => (
          render ? render(option) : (
            <div
              key={option.value}
              disabled={option.disabled}
              onKeyPress={() => { }}
              role="button"
              tabIndex="0"
              className={clsx(styles.dropdownItem, selectedIds.includes(option.value) ? styles.active : "")}
              onClick={(o) => !option.disabled && toggleDropdown(option.value, o)}
            >
              {option.label}
            </div>
          )))}
      </DropdownMenu>
    </Dropdown>
  );
};
Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
  })).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  render: PropTypes.func,
  typeahead: PropTypes.func,
  typeaheadPlaceholder: PropTypes.string,
  chipClass: PropTypes.string,
  disabled: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.func,
  ]),
  invalid: PropTypes.bool,
  excluded: PropTypes.arrayOf(PropTypes.string),
};

Select.defaultProps = {
  className: "",
  value: null,
  render: null,
  typeahead: null,
  typeaheadPlaceholder: "",
  chipClass: null,
  disabled: false,
  innerRef: null,
  invalid: false,
  excluded: [],
};

export default Select;
