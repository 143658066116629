/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */

import React from "react";
import PropTypes from "prop-types";
import Label from "../../../common/core/Label";

const Datetime = require("react-datetime");
const moment = require("moment");

const isValidDate = (selectedDate, allowFutureDate, allowPastDate) => {
  let condition = null;
  if (allowFutureDate && !allowPastDate) {
    condition = "isAfter";
  } else if (allowPastDate && !allowFutureDate) {
    condition = "isBefore";
  }
  if (condition) {
    return selectedDate[condition](Datetime.moment());
  }
  return true;
};

const DatePicker = ({
  inputData, stateData, handleChange,
}) => {
  const {
    label, inputName, attributes,
  } = inputData;
  const {
    showDate,
    showTime,
    placeholder,
    readOnly,
    closeOnSelect,
    checkValidDate,
    dateFormat,
    timeFormat,
    allowFutureDate,
    allowPastDate,
  } = attributes || {};
  const displayFormat = (attributes && attributes.displayFormat) || "DD-MM-YYYY HH:mm:ss";
  const dateFormatValue = showDate ? dateFormat : false;
  const timeFormatValue = showTime ? timeFormat : false;
  const inputValue = stateData && stateData[inputName] ? moment(stateData[inputName]).format(displayFormat) : "";
  return (
    <>
      <div className="col-sm-6 mt-2">
        <Label>
          {" "}
          {label}
        </Label>
        <Datetime
          id={inputName}
          value={inputValue}
          dateFormat={dateFormatValue}
          timeFormat={timeFormatValue}
          name={inputName}
          inputProps={{
            placeholder: placeholder || "",
            readOnly: readOnly || true,
          }}
          onChange={(selectedDate) => handleChange(inputName, selectedDate.toDate())}
          closeOnSelect={closeOnSelect || true}
          isValidDate={checkValidDate
            ? (selectedDate) => isValidDate(selectedDate, allowFutureDate, allowPastDate) : true}
        />
      </div>
    </>
  );
};

DatePicker.propTypes = {
  inputData: PropTypes.objectOf(PropTypes.any).isRequired,
  stateData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DatePicker;
