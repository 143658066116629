/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login
 *
 * @author Dipin
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import FormFeedback from "../../../common/core/FormFeedback";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import NavLink from "../../../common/core/NavLink";
import Container from "../../../common/core/Container";
import Card from "../../../common/core/Card";
import Spinner from "../../../common/core/Spinner";
import styles from "../css/Login.module.css";

const Login = (props) => {
  const { t } = useTranslation(["login"]);
  const {
    handleSubmit, error, loginData, handleChange, submitting,
  } = props;

  return (
    <Container className={styles.loginWrapper1}>
      <Card className="loginWrapper">
        <h3>{t("login:signin")}</h3>
        <div className="loginContent">
          <Form onSubmit={handleSubmit}>
            <fieldset disabled={submitting}>
              <FormGroup className="fixed-form-height">
                <Label for="exampleEmail">{t("login:email")}</Label>

                <Input
                  value={loginData.username}
                  id="exampleEmail"
                  type="email"
                  onChange={(e) => handleChange("username", e)}
                  autoComplete="email"
                  invalid={error.username}
                />
                {" "}
                <FormFeedback>{error.username}</FormFeedback>
              </FormGroup>
              <FormGroup className="fixed-form-height-pass">
                <Label for="examplePassword">{t("login:password")}</Label>
                {/* <a href="" className="forgotpass">Forgot Password?</a> */}
                <Input
                  id="examplePassword"
                  type="password"
                  autoComplete="current-password"
                  value={loginData.password}
                  onChange={(e) => handleChange("password", e)}
                  invalid={error.password}
                />
                {" "}
                <FormFeedback>{error.password}</FormFeedback>
                <p className="verifyLogin m-0 text-danger">
                  {error.isValid}
                </p>
              </FormGroup>
              <div className="form-group">
                <input type="checkbox" />
                <span className="checkmark">Remember Me</span>
              </div>
              <Button className={error.isValid ? "rounded bluecolor border-0 w-100" : "rounded bluecolor border-0 w-100"}>
                {
                  submitting ? (
                    <Spinner size="sm" animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                      <span>
                        {t("login:signin_button")}
                      </span>
                    )
                }
              </Button>
              {/* <div className="loginWrap d-flex justify-content-center">
                {t("login:no_account")}
                <a
                  href={{
                    pathname: "/signup",
                  }}
                  className="forgotpass float-none pl-1"
                >
                  {t("login:signup")}
                </a>
              </div> */}
            </fieldset>
          </Form>
        </div>
      </Card>
    </Container>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  loginData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
export default Login;
