/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getProductOptions } from "../../../api/productManagementServices";
import ProductOptionSelector from "../components/ProductOptionSelector";


const ProductOptionSelectorContainer = (props) => {
  const {
    name, value, onChange, options, invalid,
  } = props;

  const [productOptionList, setProductOptionList] = useState(options || []);

  useEffect(() => {
    getProductOptions().then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        const data = response.data.map((each) => ({ id: each.id, name: each.name }));
        setProductOptionList(data);
      } else {
        setProductOptionList([]);
      }
    });
  }, []);

  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };
  return (
    <ProductOptionSelector
      name={name}
      productOptionList={productOptionList}
      selectedProductOptions={value}
      onChange={localOnChange}
      invalid={invalid}
    />
  );
};

ProductOptionSelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,

};
export default ProductOptionSelectorContainer;
