
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import TabPane from "../../../common/core/TabPane";
import TabContent from "../../../common/core/TabContent";
import Nav from "../../../common/core/Nav";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";
import Badge from "../../../common/core/Badge";

/** ===== IMAGE ICONS =========== */
import QuestionIcon from "../../../common/assets/images/svg/question-mark.svg";

/** ======== MODULE STYLES ========== */
import styles from "../css/CustomURLManagement.module.css";

const CustomURLManagement = ({
  activeTab, toggleTab, form, handleFormChange, onHandleSave, onHandleCancel,
}) => (
    <>
      <Row>
        <Col xs="12">
          <Form>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">SEO Settings</span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={onHandleCancel} className={clsx(styles.borderRadius2, styles.btn1, styles.secondaryColor, "text-truncate", "mr-2")}>
                  CANCEL
              </Button>
                <Button onClick={onHandleSave} type="button" className={clsx(styles.borderRadius2, styles.btn2, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  SAVE
              </Button>
              </Col>
            </Row>
            <div>
              <Row className="common-page-card">
                <Col xs="12" className={clsx("position-relative", "mb-4")}>
                  <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>Custom URL</span>
                </Col>
                <Col>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        onClick={() => { toggleTab("1"); }}
                        className={clsx({ active: activeTab === "1" }, styles.tabLink, styles.pageSelectionText, "fontsizeMediumBold")}
                      >
                        Catalog URL
                    </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        onClick={() => { toggleTab("2"); }}
                        className={clsx({ active: activeTab === "2" }, styles.tabLink, styles.pageSelectionText, "fontsizeMediumBold")}
                      >
                        Content URL
                    </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="common-page-card">
                        <Row className="common-page-card">
                          <Col xs="12" md="2" className={clsx("ml-3", "pb-4")}>
                            <FormGroup>
                              <Label className="formTextLabel">Product</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="7" className="pb-4">
                            <Input
                              className="formText"
                              value={form.product}
                              name="product"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </Col>
                          <Col xs="12" md="2" className="pb-4" />
                          <Col xs="12" md="2" className={clsx("ml-3", "pb-4")}>
                            <FormGroup>
                              <Label className="formTextLabel">Category</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="7" className="pb-4">
                            <Input
                              className="formText"
                              value={form.category}
                              name="category"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </Col>
                          <Col xs="12" md="2" className="pb-4" />
                          {/* <Col xs="12" md="2" className={clsx("ml-3", "pb-4")}>
                            <FormGroup>
                              <Label className="formTextLabel">Sub Category</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="7" className="pb-4">
                            <Input
                              className="formText"
                              value={form.subcategory}
                              name="subcategory"
                              onChange={(e) => handleFormChange(e)}
                            />
                          </Col>
                          <Col xs="12" md="2" className="pb-4" /> */}
                        </Row>
                        <Row className="common-page-card">
                          <Col xs="12" md="2" className="ml-3" />
                          <Col xs="12" md="9" className="pb-2">
                            <Label className="formTextLabel">Available Tags</Label>
                          </Col>
                          <Col xs="12" md="2" className="ml-3" />
                          <Col xs="12" md="9">
                            <Badge className={clsx(styles.badge, "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}>%category%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}> %product%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}> %subcategory%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}>  %product-id%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}> %category-id%</Label>
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="common-page-card">
                          <Col xs="12" className="mb-4">
                            <span className="formTextLabel">URL Formats</span>
                          </Col>
                          <Col xs="12" md="3" className={clsx("text-truncate", "mr-2")}>
                            <FormGroup>
                              <Label className="formTextLabel"> Forbidden characters replacements</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="5">
                            <FormGroup>
                              <InputGroup>
                                <Input placeholder="From" />
                                <InputGroupAddon
                                  addonType="append"
                                  className={clsx(styles.quesIcon, "commonPointer ")}
                                >
                                  <img
                                    src={QuestionIcon}
                                    alt="question mark"
                                    className="ml-2"
                                  />
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <FormGroup>
                              <input className={clsx("mr-2", "mt-2")} type="checkbox" />
                              <Label className={clsx("formTextLabel")}> Inherit from global settings</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3" className="mr-2" />
                          <Col xs="12" md="5">
                            <FormGroup>
                              <Input className="formText" placeholder="To" />
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <FormGroup>
                              <Button type="button" className={clsx(styles.btnRule, "mr-2")}>
                                Add Rule
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="6" />
                          <Col xs="12" md="6">
                            <FormGroup>
                              <Button type="button" className={clsx(styles.btnAdd, "ml-4")}>
                                Add More
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="common-page-card">
                        <Row className="common-page-card">
                          <Col xs="12" md="2" className={clsx("ml-3", "pb-4")}>
                            <FormGroup>
                              <Label className="formTextLabel">Homepage</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="7" className="pb-4">
                            <Input 
                              className="formText"/>
                          </Col>
                          <Col xs="12" md="2" className="pb-4" />
                          <Col xs="12" md="2" className={clsx("ml-3", "pb-4")}>
                            <FormGroup>
                              <Label className="formTextLabel">Store Locator</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="7" className="pb-4">
                            <Input className="formText" />
                          </Col>
                          <Col xs="12" md="2" className="pb-4" />
                          <Col xs="12" md="2" className={clsx("ml-3", "pb-4")}>
                            <FormGroup>
                              <Label className="formTextLabel">Contact Us</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="7" className="pb-4">
                            <Input className="formText" />
                          </Col>
                          <Col xs="12" md="2" className="pb-4" />
                        </Row>
                        <Row className="common-page-card">
                          <Col xs="12" md="2" className="ml-3" />
                          <Col xs="12" md="9" className="pb-2">
                            <Label className="formTextLabel">Available Tags</Label>
                          </Col>
                          <Col xs="12" md="2" className="ml-3" />
                          <Col xs="12" md="9">
                            <Badge className={clsx(styles.badge, "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}>%category%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}> %product%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}> %subcategory%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}>  %product-id%</Label>
                            </Badge>
                            <Badge className={clsx(styles.badge, "formTextLabel", "mr-2", "mb-1")}>
                              <Label className={clsx(styles.badgeTag, "formTextLabel", "pt-2")}> %category-id%</Label>
                            </Badge>
                          </Col>
                        </Row>
                        <Row className="common-page-card">
                          <Col xs="12" className="mb-4">
                            <span className="formTextLabel">URL Formats</span>
                          </Col>
                          <Col xs="12" md="3" className={clsx("text-truncate", "mr-2")}>
                            <FormGroup>
                              <Label className="formTextLabel"> Forbidden characters replacements</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="5">
                            <FormGroup>
                              <InputGroup>
                                <Input placeholder="From" />
                                <InputGroupAddon
                                  addonType="append"
                                  className={clsx(styles.quesIcon, "commonPointer ")}
                                >
                                  <img
                                    src={QuestionIcon}
                                    alt="question mark"
                                    className="ml-2"
                                  />
                                </InputGroupAddon>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <FormGroup>
                              <input className={clsx("mr-2", "mt-2")} type="checkbox" />
                              <Label className={clsx("formTextLabel")}> Inherit from global settings</Label>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3" className="mr-2" />
                          <Col xs="12" md="5">
                            <FormGroup>
                              <Input className="formText" placeholder="To" />
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <FormGroup>
                              <Button type="button" className={clsx(styles.btnRule, "mr-2")}>
                                Add Rule
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="6" />
                          <Col xs="12" md="6">
                            <FormGroup>
                              <Button type="button" className={clsx(styles.btnAdd, "ml-4")}>
                                Add More
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );

CustomURLManagement.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggleTab: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleFormChange: PropTypes.func.isRequired,
  onHandleSave: PropTypes.func.isRequired,
  onHandleCancel: PropTypes.func.isRequired,
};

export default CustomURLManagement;
