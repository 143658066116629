/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Layout Settings
 *
 * @author Naseef O
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Label from "../../../common/core/Label";
import NavLink from "../../../common/core/NavLink";
import NavItem from "../../../common/core/NavItem";
import Nav from "../../../common/core/Nav";
import Spinner from "../../../common/core/Spinner";
import Alert from "../../../common/core/Alert";

/** ========= CUSTOM COMPONENTS ========= */
import CustomizationTable from "../../../common/components/Table";

/** ============ SVG IMAGE ICON ===================== */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

/** ========= MODULE STYLES ========= */
import styles from "../css/MenuList.module.css";

const MenuList = (props) => {
  const {
    menus,
    submitting,
    selectedMenuId,
    addMenu,
    localeHandler,
    handleMenuStatus,
    editMenu,
    removeMenu,
    viewDetails,
    syncSiteNavigations,
    loading,
    handlePushToLive,
    pushToLiveEnabled,
    message,
    previewSiteUrl,
    previewEnabled,
    getReplicationStatus,
    pushToLiveButtonEnabled,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  /**
   * This method use use to crate status change toggle for table
   * @param {Object} row
   * @returns {Element}
   */
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
          submitting && selectedMenuId === row.id ? "loading" : "",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) => handleMenuStatus(!row.active, row.id, o)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };

  /**
   * This method is used to create action component for table
   * @param {Object} row
   * @returns {Element}
   */
  const getActions = (row) => (
    <div className={clsx("d-flex", "justify-content-start", styles.actions)}>
      <span
        // disabled={!row.active}
        className="commonPointer mr-2"
        onClick={(o) => editMenu(row.id, o)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={EditIcon}
          alt="Edit"
        />
      </span>
      <span
        className="commonPointer"
        onClick={(o) => removeMenu(row.id, o)}
        role="button"
        tabIndex={0}
        onKeyPress={() => { }}
      >
        <img
          className={styles.icon}
          src={DeleteIcon}
          alt="Delete"
        />
      </span>
    </div>
  );

  /**
   * This method is used to open menu details page
   * @param {Object} row
   * @returns {Element}
   */
  const getClickableName = (row) => (
    <span className="w-100 text-left commonPointer d-flex" onClick={(o) => viewDetails(row.id, o)} onKeyPress={() => { }} tabIndex={0} role="button">
      {row.name}
    </span>
  );

  const redirectToPage = () => {
    let url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card ">
        <Col xs="12" md="5" className="text-truncate">
          <span className="pageText">{t("site-navigation:title")}</span>
        </Col>
        <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
          <Row>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(
                    styles.liveBtn,
                    "float-right",
                    "text-truncate",
                    "btn-tertiary",
                    "button-font-size "
                  )}
                  onClick={() => redirectToPage()}
                >
                  {t("common:button_preview")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(
                    styles.liveBtn,
                    "float-right",
                    "text-truncate",
                    "btn-tertiary",
                    "button-font-size "
                  )}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block1, " common-page-card ")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="4" className="p-0">
              <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>{t("site-navigation:sub_title")}</span>
            </Col>
            <Col xs="4" className="p-0">
              <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>
                <Nav>
                  <NavItem>
                    <NavLink className="color-tertiary-main cursor-pointer" onClick={syncSiteNavigations}>
                      {
                        loading ? (
                          <Spinner size="sm" />
                        ) : t("site-navigation:placeholder_sync_header_navigations")
                      }
                    </NavLink>
                  </NavItem>
                </Nav>
              </span>
            </Col>
            <Col xs="4" className="p-0">
              <Button className={clsx(styles.buttonAddMenu, "bg-color-tertiary-main")} onClick={addMenu}>{t("site-navigation:button_add_menu")}</Button>
            </Col>
            <Row className="common-page-card">
              <Col xs="12" sm="6">
                <LocaleDropdownContainer
                  localeHandler={localeHandler}
                  isIndocument={false}
                  entityName="SiteNavigation"
                />
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <Row
        className={clsx(styles.tableWrapper, " common-page-card ")}
      >
        <Col xs="12">
          {
            Array.isArray(menus) && menus.length > 0 ? (
              <div className="custom-table">
                <CustomizationTable
                  customRows={[
                    {
                      label: t("site-navigation:label_name"),
                      path: "render",
                      render: getClickableName,
                    },
                    {
                      label: t("site-navigation:label_position"),
                      path: "type",
                    },
                    {
                      label: t("common:label_status"),
                      path: "render",
                      render: getStatus,
                    },
                    {
                      label: t("common:label_actions"),
                      path: "render",
                      render: getActions,
                    },
                  ]}
                  customizationData={menus}
                />
              </div>
            ) : (
                <div className="px-2 py-3">
                  <p>
                    {t("site-navigation:message_no_data")}
                  </p>
                </div>
              )
          }
        </Col>
      </Row>
    </>
  );
};

MenuList.defaultProps = {
  selectedMenuId: null,
};

MenuList.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedMenuId: PropTypes.string,
  addMenu: PropTypes.func.isRequired,
  editMenu: PropTypes.func.isRequired,
  viewDetails: PropTypes.func.isRequired,
  removeMenu: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleMenuStatus: PropTypes.func.isRequired,
  syncSiteNavigations: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
};

export default MenuList;
