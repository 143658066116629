/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import styles from "../css/DocumentationGuide.module.css";
import Table from "../../../common/core/Table";
const Marketing = () => (
  <>
    <Row>
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Row className="pageHeader  common-page-card">
          <Col xs="12" md="6" className="text-truncate">
            <span className={styles.pageHeader}>Marketing your products</span>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <div className={styles.pageText}>
              The section is about creating marketing initiatives to get your
              products closer to the attention of your customers.
              <br />
              The following can be created and edited using this module:
              <ul>
                <li>Campaigns</li>
                <li>Promotions</li>
                <li>Coupons</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Creating and editing Campaigns
              <br />
            </span>
            <div className={styles.pageText}>
              <b>Login > Marketing > Campaign</b>
              <br />
              <br />
              Using this menu, you can organise campaigns. You can add, edit and
              delete campaigns here.
              <br /> To run campaigns for a product, a brand, for a time period,
              for a set target audience.
              <br />
              <ul>
                <li>
                  <b>All Campaign Dropdown</b> : You have the option to select a
                  single campaign.
                </li>
                <li>
                  <b>All Status Dropdown</b> : You have the option to select
                  campaigns according to the status - All Status / Enabled /
                  Disabled.
                </li>
                <li>
                  <b>Search Campaign</b> : You can select a campaign by entering
                  the Campaign ID or Name.
                </li>
              </ul>
            </div>

            <span className={styles.pageSubHeading2}>
              <b>Adding a new campaign</b>
            </span>
            <div className={styles.pageText}>
              Click on the “Add New Campaign” button to add a new campaign. On
              clicking this the details screen is displayed.
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image6.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Enter the Campaign Name, Short Description, Active Status (Yes,
              No), Start Date and End Date.
              <br />
              Scroll Down click on Save & Continue to go to the next tab.
              <br />
              <b> Qualifiers</b>
              : You can link customer groups and coupons through this screen. To
              know how to create customer groups, click here
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image58.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              You can set the target group to which you are pushing the
              campaigns. The qualifiers decide to which groups the discounts are
              applicable.
              <br />
              <br />
              You can either assign the campaign to an already updated customer
              group or you can create a new customer group. To know how to
              create customer groups, click here
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image37.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image2.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              For new customer groups, you can dynamically push campaigns, by
              selecting <b>“Dynamic”</b> under Type. By selecting{" "}
              <b>“New Customers”</b> under dynamic, the campaign can be set for
              customers who are registered but haven’t made any purchase
              <br />
              <br />
              Once all the details are entered please click on “Save&Continue”.
              <br />
              <br />
            </div>
            <div className={styles.pageSubHeading2}>
              Adding a new experience
              <br />
              <br />
            </div>
            <div className={styles.pageText}>
              Campaigns can contain multiple experiences. Currently the
              configurable experiences are promotions. Each experience can be
              limited by customer groups and coupons individually. Experience
              only runs within the campaign’s overall schedule. All the
              experiences that are part of the campaign can be viewed here. By
              selecting “Assign promotions”, an already defined promotion can be
              mapped to the campaign. A user can directly create a new promotion
              within a campaign. For reference on the promotion fields, refer to
              the add a new promotion section.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image52.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image30.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image10.png"
                className={styles.imgSize}
              />
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Creating and editing Campaigns
              <br />
              <br />
            </span>
            <span className={styles.pageSubHeading2}>
              Adding a new promotion
              <br />
            </span>
            <div className={styles.pageText}>
              <b>Login > Marketing > Campaign</b>
              <br />
              <br />
              <b>To add new promotion, Click on the new promo button</b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image11.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Enter data in the following fields.
              <ul>
                <li>Name</li>
                <li>Description</li>
                <li>Rank</li>
                <li>
                  Start Date - End date or No end date - Assume that no end date
                  will be a permanent promotion. A promotion without an expiry
                  date.
                </li>
                <li>Status ( toggle to enable and disable. )</li>
              </ul>
              <br />
              <br />
              <b>Promotion Configuration </b>– Promotion Class / Discount Rule /
              Combine With
              <ul>
                <li>Qualify Products – Category</li>
                <li>Brand</li>
                <li>Included products</li>
                <li>Excluded products</li>
                <li>
                  Exclude products on sale - This checkbox is to prevent the
                  promotion to be applicable for products that have sales price
                  lower than list price in the feed received
                </li>
                <li>
                  StoreFront Settings : Call-out-message – This message appears
                  on the site against relevant products. E.g buy 2 get 1 free is
                  a callout message. For order level promotions it appears in
                  the checkout page
                </li>
              </ul>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image65.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image78.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image49.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <b>The following are the promotions available:</b>
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              Item Level Promotions
              <br />
              <br />
            </span>
            <div className={styles.pageText}>
              Item level promotions are discounts or promotions are triggered
              when a shopper adds a single item to basket or multiple quantities
              of the same item. An illustration is as follows.
              <br />
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Example</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Specific Discounted Price</td>
                    <td>AED 199, AED 99, AED 200</td>
                  </tr>
                  <tr>
                    <td>Specific percentage off</td>
                    <td>15% Off, 20% off, 40% off</td>
                  </tr>
                  <tr>
                    <td>Specific amount off</td>
                    <td>AED 10 off, AED 50 off, AED 30 off</td>
                  </tr>
                  <tr>
                    <td>Buy X & Get Y free</td>
                    <td>
                      Buy 1 get 1 free, Buy 3 get 2 free <br />
                      Buy 3 pay for 2, Buy 4 pay for 2
                    </td>
                  </tr>
                  <tr>
                    <td>Buy X at specific price </td>
                    <td> Buy 2 at AED 399, BUY 4 at AED 799</td>
                  </tr>
                  <tr>
                    <td>2nd piece get X% off </td>
                    <td>Take a 2nd item and get 20% off</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <br />
            <br />
            <span className={styles.pageSubHeading2}>
              Order Level Promotions
              <br />
              <br />
            </span>
            <div className={styles.pageText}>
              Order level promotions are triggered when the total order value
              fulfills some spending requirements.
              <br />
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Example</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Additional % off</td>
                    <td>
                      Spend over AED 500 and get 15% off on your total order
                      value
                    </td>
                  </tr>
                  <tr>
                    <td>Additional amount off</td>
                    <td>
                      Spend over AED 500 and get additional AED 20 off on your
                      order
                    </td>
                  </tr>
                  <tr>
                    <td>Promotion code</td>
                    <td>
                      Enter “Holidays” when you check out and get additional 10%
                      off
                    </td>
                  </tr>
                  <tr>
                    <td>Free delivery</td>
                    <td>Spend over AED 200 and enjoy free delivery</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* table */}
            <span className={styles.pageSubHeading2}>
              Range/brand/category level promotions
              <br />
              <br />
            </span>
            <div className={styles.pageText}>
              Discounts or promotions triggered when a shopper fulfills a
              specific spending requirement on a certain range, brand or product
              category. Examples are:
              <ul>
                <li>Range : OlayRegenerist range, or Ramadan special range</li>
                <li>Brand: Specific brand names</li>
                <li>Category : Skin Care, Footwear</li>
                <li>Sub-category : Chinos, Floaters</li>
              </ul>
              <br />
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Example</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Spend over AED X on ...and get AED Y off</td>
                    <td>Spend over AED 500 on skincare and get AED 40 off</td>
                  </tr>
                  <tr>
                    <td>Spend over AED X on ...and get % Y off</td>
                    <td>
                      Spend over AED 100 on vitamins and get additional 10% off
                    </td>
                  </tr>
                  <tr>
                    <td>Spend over AED X on ...and get AED Y off</td>
                    <td>Spend over AED 20 on Olay and get AED 3 Off</td>
                  </tr>
                  <tr>
                    <td>Buy any X number of ..and get % off</td>
                    <td>
                      Buy any 2 items of Dove hair care products, get 15% off
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* table */}
            <div className={styles.pageText}>
              <span className={styles.pageSubHeading2}>
                Illustrative example - Creating a new promotion
                <br />
                <br />
              </span>
              Let’s say, we need to create an item level promotion of{" "}
              <b>$15 flat off</b> on <b>an item</b>
              .
              <br />
              Go to Promotion Configuration
              <br />
              <b> Login> Dashboard> Marketing > Promotion> “ NEW PROMO”</b>
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image23.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              Enter Attributes:
              <ul>
                <li>Name</li>
                <li>Description</li>
                <li>Rank</li>
                <li>Start & End Date</li>
                <li>Status</li>
              </ul>
              Promotion Configuration :
              <ul>
                <li>Promotion Class - Select “Product”</li>
                <li>Discount For - Select “Total Amount”</li>
                <li>Discount Rule - Select “Amount Off”</li>
                <li>
                  Min Amount - Enter minimum amount of product for the promotion
                  to be applicable. *
                </li>
                <li>
                  Discount Amount - Enter the discount amount. In this case $15.
                </li>
                <li>
                  Combine with - in case you need to combine it with another
                  promotion.
                </li>
              </ul>
              Promotion Configuration for % off (e.g. 10% off an item):
              <ul>
                <li>Promotion Class - Select “Product”</li>
                <li>Discount For - Select “Total Amount”</li>
                <li>Discount Rule - Select “% Off” </li>
                <li>
                  {" "}
                  Min Amount - Enter minimum amount of product for the promotion
                  to be applicable.*
                </li>
                <li>
                  Discount Amount - Enter the discount %. In this case 10.
                </li>
              </ul>
              Promotion Configuration to sell a product at a specific price (
              say sell a product at $25)
              <ul>
                <li>Promotion Class - Select “Product”</li>
                <li>Discount For - Select “Total Amount”</li>
                <li>Discount Rule - Select “Flat Price”</li>
                <li>
                  Min Amount - Enter minimum amount of product for the promotion
                  to be applicable. *
                </li>
                <li>
                  Discount Amount - Enter the discount amount. In this case 25
                </li>
              </ul>
              *The min amount configuration is a planned feature and may not be
              available in the initial version of the product. Promotion
              Configuration to give an order discount ( $15 off on your total
              order value)
              <ul>
                <li>Promotion Class - Select “Order”</li>
                <li>Discount Rule - Select “Amount Off”</li>
                <li>
                  Minimum cart value - Enter minimum cart amount for the
                  promotion to be applicable.
                </li>
                <li>
                  Discount Amount - Enter the discount amount. In this case $15.
                </li>
              </ul>
              Promotion Configuration for % off on order (e.g. 10% off an
              order):
              <ul>
                <li>Promotion Class - Select “Order” </li>
                <li>Discount Rule - Select “% Off”</li>
                <li>
                  Minimum cart value - Enter minimum cart amount for the
                  promotion to be applicable.
                </li>
                <li>
                  Percentage Off - Enter the discount percentage. In this case
                  10.
                </li>
              </ul>
              Promotion Configuration for free shipping (e.g. 100% off on
              shipping charges):
              <ul>
                <li>Promotion Class - Select “Shipping”</li>
                <li>Discount Rule - Select “% Off” </li>
                <li>
                  Minimum cart value - Enter minimum cart amount for the
                  promotion to be applicable.
                </li>
                <li>
                  {" "}
                  Percentage Off - Enter the discount percentage. In this case
                  100.
                </li>
              </ul>
              Promotion Configuration for % off on shipping (e.g. 10% off
              shipping charge):
              <ul>
                <li>Promotion Class - Select “Shipping”</li>
                <li>Discount Rule - Select “% Off”</li>
                <li>
                  Minimum cart value - Enter minimum cart amount for the
                  promotion to be applicable.
                </li>
                <li>
                  {" "}
                  Percentage Off - Enter the discount percentage. In this case
                  10.
                </li>
              </ul>
              Promotion Configuration for amount off on shipping (e.g. $5 off on
              shipping charge):
              <ul>
                <li> Promotion Class - Select “Shipping”</li>
                <li>Discount Rule - Select “Amount Off”</li>
                <li>
                  {" "}
                  Minimum cart value - Enter minimum cart amount for the
                  promotion to be applicable.
                </li>
                <li>
                  Discount Amount - Enter the discount amount. In this case 5.
                </li>
              </ul>
              Promotion Configuration for flat price on shipping (e.g. shipping
              charge of $2):
              <ul>
                <li>Promotion Class - Select “Shipping”</li>
                <li>Discount Rule - Select “Flat Price” .</li>
                <li>
                  Minimum cart value - Enter minimum cart amount for the
                  promotion to be applicable
                </li>
                <li>Discount Amount - Enter the amount. In this case $2.</li>
              </ul>
              Promotion Configuration for Buy X Get Y ( Buy 2 get 1 free) :
              <ul>
                <li>Promotion Class - Select “Product”</li>
                <li>Discount For - Select “Buy X Get Y Promotion”</li>
                <li>Discount Rule - Select “Buy X Get Y Free”</li>
                <li>
                  Number of Products X - Enter the count of products to be
                  purchased. In this case 2
                </li>
                <li>
                  Number of Products Y - Enter the count of products free. In
                  this case 1
                </li>
                <li>
                  Note: In all scenarios, the lowest priced products will be
                  given free.
                </li>
              </ul>
              Promotion Configuration for Buy X Pay Y ( Buy 3 Pay 2) :
              <ul>
                <li> Promotion Class - Select “Product”</li>
                <li>Discount For - Select “Buy X Pay Y Promotion”</li>
                <li>Discount Rule - Select “Buy X Pay Y” </li>
                <li>
                  Number of Products X - Enter the count of products to be
                  purchased. In this case 3
                </li>
                <li>
                  {" "}
                  Pay For - Enter the count of products to be paid for. In this
                  case 2
                </li>
                <li>
                  Note: In all scenarios, the lowest priced products will be
                  given free.
                </li>
              </ul>
              Promotion Configuration for Buy X at specific price ( Buy 2 at
              $299) :
              <ul>
                <li>Promotion Class - Select “Product” </li>
                <li> Discount For - Select “Buy X Pay Y Promotion”</li>
                <li>Discount Rule - Select “Buy X at specific price”</li>
                <li>
                  Number of Products X - Enter the count of products to be
                  purchased. In this case 2
                </li>
                <li>
                  For - Enter the price to be paid for the products. In this
                  case $299
                </li>
              </ul>
              Promotion Configuration for 2nd piece get x% off. (Take a 2nd item
              and get 10% off)
              <ul>
                <li>Promotion Class - Select “Product”</li>
                <li>Discount For - Select “Buy X Get Y Promotion”</li>
                <li> Discount Rule - Select “Percentage Off” </li>
                <li>
                  Number of Products X - Enter the count of products to be
                  purchased at full price. In this case 1
                </li>
                <li> Number of Products Y- Should always be set as 1.</li>
              </ul>
              <br />
              <br />
            </div>
            <span className={styles.pageSubHeading2}>
              Viewing and editing promotions
              <br />
              <br />
            </span>
            <div className={styles.pageText}>
              <b>Editing Promotions</b>
              <br />
              To edit promotions, click on the edit icon against a promotion.
              You are led to the screen below.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image46.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              The attributes here are
              <ul>
                <li>Promotion ID</li>
                <li>Name</li>
                <li>Description</li>
                <li>Rank</li>
                <li>Start & End Date</li>
                <li>No End Date</li>
                <li>Status - Toggle Button</li>
              </ul>
              Promotion Configuration: Promotion classes are defined as
              <ul>
                <li>Order : Promotions pertaining to order.</li>
                <li>
                  Shipping : Promotions on Shipping charges / Free Shipping.
                </li>
                <li>Products : Promotions on one or a types of products.</li>
              </ul>
              The following aspects of a promotion can be added or edited here.
              <ul>
                <li>
                  Discount Rule - Flat Price, Percentage Off or Amount Off.
                </li>
                <li>Minimum Cart Value</li>
                <li>Percentage Off </li>
                <li> Max Discount Amount </li>
                <li>External Promotion ID (only for order class promotion)</li>
                <li>
                  Combine With - To combine with other promotion - All / None /
                  Same Type Promotion / Different Type Promotion
                </li>
              </ul>
              <b>
                Adding products that qualify for promotions - Qualifying
                products
              </b>
              <br />
              You can select categories and (or) brands to include in the
              promotion. The drop down includes;
              <ul>
                <li>Category</li>
                <li>Brand</li>
                <li>Included Products</li>
                <li>Excluded products</li>
                <li>
                  Exclude products on sale : This checkbox is to prevent the
                  promotion to be applicable for products that have sales price
                  lower than list price in the feed received
                </li>
                <li>Storefront Settings</li>
                <li>
                  Call out message - This message here will appear on the site
                  against the relevant products on the check out flow as
                  detailed in the promotion configuration section.
                </li>
              </ul>
              <b>Closeness Qualifier</b>
              <br />
              Closeness Qualifier is what appears on the checkout flow, to give
              a user the idea on how close they are to free shipping or shipping
              discounts.
              <ul>
                <li>Closeness Qualifier - Toggle Button </li>
                <li>Cart Threshold for approach message display</li>
                <li>
                  Approaching message for user before qualifying for shipping
                  promotion* - This message will appear as the customer keeps
                  adding items in the cart. The amount will change corresponding
                  to the balance amount to qualify for the shipping promotion.
                </li>
                <li>
                  Message to user on qualifying for the shipping promotion -
                  This message will appear once the customer has added items
                  totaling the recommended amount.
                </li>
                <li>This message appears on the top of the checkout pages.</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Creating and editing Coupons
              <br />
              <br />
            </span>

            <div className={styles.pageText}>
              <b> Login > Marketing > Coupon</b>
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image59.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <br />
              You can search for a coupon by its ID or Code, You can add, edit
              or delete a coupon through this screen. The add or edit coupon
              function will take you to the details screen.
              <br />
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image32.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              <ul>
                <li>
                  You can change the Coupon Code, Description, start date and
                  end date through this screen.
                </li>
                <li>
                  You can add multiple coupon codes in the coupon code input
                  field by a comma separator
                </li>
              </ul>
              <b>Advanced Settings</b>
              <br />
              You can enter no: of “uses per coupon” and “uses per customer”.
              The Tick box for “unlimited” removes the restriction on the no: of
              times coupon can be used.
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              SEO Settings - Update and Download Site Maps
              <br />
              <br />
            </span>

            <div className={styles.pageText}>
              To manually update and individually download Sitemaps, follow
              these steps:
              <br />
              <b>Login> DashBoard> Marketing> SEO Settings > Site Map </b>
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image22.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              Click on “ Generate “ to generate the XMLs.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image55.png"
                className={styles.imgSize}
              />
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Marketing : Creating Customer Groups <br />
              <br />
            </span>

            <div className={styles.pageText}>
              <b>Login > Marketing > Customer Management > Customer Groups</b>
              <br />
              To add, edit, delete, enable/disable status and search for
              customer groups.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image69.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              To edit the group click on the edit{" "}
              <span>
                <img
                  src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image67.png"
                  className={styles.iconSize}
                />
              </span>{" "}
              icon. You are led to the details screen.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image53.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              Here you can edit Name, Description, Type. Types of customer
              groups:
              <ul>
                <li>Static : Data remains the same.</li>
                <li>
                  Dynamic : Data can be retrieved according to the customer
                  behaviors/categories. Example, High value customers, First
                  time users, Brand specific customers etc.
                </li>
              </ul>
              <br />
              You can also search and delete specific customers from the group.
              <br />
              <b>Adding a customer group</b>
              <br />
              Click on the Add Group Button. You are led to the details below.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image17.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              Here the screen and attributes are similar to the edit screen.
              Once you enter the data and select the customers you can create
              the group by clicking the “create” button.
              <br />
            </div>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col className={styles.pageText}>
            <span className={styles.pageSubHeading}>
              Customer Management
              <br />
              <br />
            </span>

            <div className={styles.pageText}>
              <b>Login > Marketing > Customer Management > Customer Data</b>
              <br />
              The screen displays the data capture from all registered
              customers.
              <br />
              <br />
              <br />
              <img
                src="https://afgecomcommonstorage.blob.core.windows.net/documentation/images/image34.png"
                className={styles.imgSize}
              />
              <br />
              <br />
              <br />
              The headers available are First Name, Last Name, Email, Mobile
              Numbers.
              <br />
              If you wish to search a specific customer, use the “Search
              Customer” box.
              <br />
              You can also download the customer data using the “Export As” drop
              down. The available export options are .xlsx and .csv.
              <br />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export default Marketing;
