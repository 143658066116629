// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n * \n */\n .MenuCreator_block1__2srL4{\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n.MenuCreator_block1__2srL4 .MenuCreator_navigationManagementnText__oVPUB{\n  text-align: left;\n  color: #333333;\n  padding-bottom: 17px;\n}\n\n.MenuCreator_block1__2srL4 .MenuCreator_buttonAddMenu__3q602{\n  color: #ffffff;\n  width: 113px;\n  height: 35px;\n  border-radius: 2px;\n  background-color: #448aff;\n  border: 0px;\n  float: right;\n}\n\n.MenuCreator_blockWrapper__2cZtc{\n  min-height: calc(100vh - 385px);\n} \n \n.MenuCreator_borderRadius2__1r8zt {\n  border-radius: 0.125rem !important;\n}\n\n.MenuCreator_secondaryColor__iF7DZ {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.MenuCreator_primaryColor__2VSCu {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.MenuCreator_btn__3lfy5 {\n  min-width: 7rem;\n}\n", ""]);
// Exports
exports.locals = {
	"block1": "MenuCreator_block1__2srL4",
	"navigationManagementnText": "MenuCreator_navigationManagementnText__oVPUB",
	"buttonAddMenu": "MenuCreator_buttonAddMenu__3q602",
	"blockWrapper": "MenuCreator_blockWrapper__2cZtc",
	"borderRadius2": "MenuCreator_borderRadius2__1r8zt",
	"secondaryColor": "MenuCreator_secondaryColor__iF7DZ",
	"primaryColor": "MenuCreator_primaryColor__2VSCu",
	"btn": "MenuCreator_btn__3lfy5"
};
module.exports = exports;
