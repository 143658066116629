import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";

/** ========= MODULE STYLES ========= */
import styles from "../css/PromotionCreatorView.module.css";

/** ========= UTIlS ========= */
import constants from "../../../common/utils/constants";

/** ========= SUB COMPONENT ========= */
import PromotionForm from "./PromotionForm";
import DiscountForm from "./DiscountForm";
import AdvancedSettingsForm from "./AdvancedSettingsForm";
import DiscountedProductsForm from "./DiscountedProductsForm";
import StorefrontSettingsForm from "./StorefrontSettingsForm";
import ClosenessQualifierForm from "./ClosenessQualifierForm";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

const PromotionListView = (props) => {
  const {
    validator,
    id, name, description, priority, externalId, startDate, endDate, combine, categories, products, submitting, indefinite, excludeProductsOnOffer, excludeSourceDiscountedProducts, discountedItemsSameAsApplicableItems, payFor,
    handleFormChange, handleChange, classType, discountType, cartValue, promotionType, onCampaign, brands, excludedProducts, showInBrowsingJourney, badge, colorOptions,
    discountStrategyCategories, discountStrategyBrands, discountStrategyProducts, discountStrategyExcludedProducts, discountedExcludeProductsOnOffer, discountedExcludeSourceDiscountedProducts,
    discountRate,
    discountAmount,
    miniProduct,
    categoryDetails, productDetails, brandDetails, excludedProductDetails, discountStrategyCategoryDetails, discountStrategyBrandDetails, discountStrategyProductDetails, discountStrategyExcludedProductDetails, buy, amount, status,
    submitForm, cancelForm, calloutMessage,
    isClosenessQualifierEnabled, closenessQualifierMessage, closenessApproachMessage, closenessQualifierCriteriaSubTotal,
    isapplicationsPerOrderUnlimited, maxApplicationsPerOrder,
    refDiscountedElements, refQualifiedElements, refProductOptions, message,
    fulfilmentTypes, fulfilmentTypesDetails,
    productOptions, productOptionDetails, restrictedChannelGroups, restrictedChannelGroupDetails,
    bonusProduct, bonusProductDetails, promotionBundles, handleBulkImport, downloadBulkImportTemplate, translatableFileds, localeHandler, isDefault, loadingFlags,
  } = props;

  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);
  const { handleSubmit } = validator;
  const {
    BUY_X_GET_Y_PROMOTION,
    BUY_X_GET_Y_FREE,
    PERCENTAGE_OFF,
    CLASS_SHIPPING,
  } = constants.PROMOTION;
  const isDiscountedItemsSameAsApplicableItemsDisabled = () => {
    let disable = false;
    if (discountType === BUY_X_GET_Y_PROMOTION) {
      if (promotionType === PERCENTAGE_OFF) disable = true;
    }
    return disable;
  };

  const isDisabledDiscountedItemsSameAsApplicableItemsHidden = () => {
    let hidden = true;
    if (discountType === BUY_X_GET_Y_PROMOTION) {
      hidden = false;
    }
    return hidden;
  };

  const disableDiscountedItemsSameAsApplicableItems = isDiscountedItemsSameAsApplicableItemsDisabled();
  const hideDiscountedItemsSameAsApplicableItems = isDisabledDiscountedItemsSameAsApplicableItemsHidden();
  return (
    <Row className="">
      <Col xs="12">
        {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
        <Form onSubmit={handleSubmit(submitForm)}>
          {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
          {
            message && message.type && (
              <Alert color={message.type}>
                {message.message}
              </Alert>
            )
          }
          <fieldset disabled={submitting}>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  Edit Promotion
                </span>
              </Col>

              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={cancelForm} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                  Cancel
                </Button>
                <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  Save
                </Button>
              </Col>
            </Row>
            <Row className="common-page-card">
              <Col xs="12" sm="6">
                <LocaleDropdownContainer
                  localeHandler={localeHandler}
                  isIndocument
                  entityName="promotion"
                />
              </Col>
            </Row>
            {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
            <PromotionForm
              id={id}
              name={name}
              handleChange={handleChange}
              handleFormChange={handleFormChange}
              startDate={startDate}
              endDate={endDate}
              indefinite={indefinite}
              discountType={discountType}
              cartValue={cartValue}
              promotionType={promotionType}
              discountRate={discountRate}
              discountAmount={discountAmount}
              miniProduct={miniProduct}
              buy={buy}
              amount={amount}
              status={status}
              validator={validator}
              onCampaign={onCampaign}
              priority={priority}
              translatableFileds={translatableFileds}
              isDefault={isDefault}
            />
            <DiscountForm
              name={name}
              classType={classType}
              discountType={discountType}
              cartValue={cartValue}
              promotionType={promotionType}
              discountRate={discountRate}
              discountAmount={discountAmount}
              miniProduct={miniProduct}
              buy={buy}
              payFor={payFor}
              amount={amount}
              handleChange={handleChange}
              handleFormChange={handleFormChange}
              validator={validator}
              onCampaign={onCampaign}
              isapplicationsPerOrderUnlimited={isapplicationsPerOrderUnlimited}
              maxApplicationsPerOrder={maxApplicationsPerOrder}
              externalId={externalId}
              fulfilmentTypesDetails={fulfilmentTypesDetails}
              fulfilmentTypes={fulfilmentTypes}
              productOptions={productOptions}
              productOptionDetails={productOptionDetails}
              refProductOptions={refProductOptions}
              bonusProduct={bonusProduct}
              bonusProductDetails={bonusProductDetails}
              promotionBundles={promotionBundles}
              promotionBundleDetails={promotionBundles}
              handleBulkImport={handleBulkImport}
              downloadBulkImportTemplate={downloadBulkImportTemplate}
              translatableFileds={translatableFileds}
              isDefault={isDefault}
              loadingFlags={loadingFlags}
            />
            <AdvancedSettingsForm
              combine={combine}
              categories={categories}
              products={products}
              excludedProducts={excludedProducts}
              discountType={discountType}
              handleChange={handleChange}
              handleFormChange={handleFormChange}
              validator={validator}
              onCampaign={onCampaign}
              brands={brands}
              excludeProductsOnOffer={excludeProductsOnOffer}
              excludeSourceDiscountedProducts={excludeSourceDiscountedProducts}
              discountedItemsSameAsApplicableItems={discountedItemsSameAsApplicableItems}
              categoryDetails={categoryDetails}
              productDetails={productDetails}
              brandDetails={brandDetails}
              excludedProductDetails={excludedProductDetails}
              hideDiscountedItemsSameAsApplicableItems={hideDiscountedItemsSameAsApplicableItems}
              disableDiscountedItemsSameAsApplicableItems={disableDiscountedItemsSameAsApplicableItems}
              refQualifiedElements={refQualifiedElements}
              restrictedChannelGroups={restrictedChannelGroups}
              restrictedChannelGroupDetails={restrictedChannelGroupDetails}
              handleBulkImport={handleBulkImport}
              downloadBulkImportTemplate={downloadBulkImportTemplate}
              translatableFileds={translatableFileds}
              isDefault={isDefault}
              loadingFlags={loadingFlags}
            />
            {
              (
                promotionType === BUY_X_GET_Y_FREE && !discountedItemsSameAsApplicableItems
              ) && (
                <DiscountedProductsForm
                  categories={discountStrategyCategories}
                  products={discountStrategyProducts}
                  excludedProducts={discountStrategyExcludedProducts}
                  handleChange={handleChange}
                  handleFormChange={handleFormChange}
                  validator={validator}
                  onCampaign={onCampaign}
                  brands={discountStrategyBrands}
                  excludeProductsOnOffer={discountedExcludeProductsOnOffer}
                  excludeSourceDiscountedProducts={discountedExcludeSourceDiscountedProducts}
                  categoryDetails={discountStrategyCategoryDetails}
                  productDetails={discountStrategyProductDetails}
                  brandDetails={discountStrategyBrandDetails}
                  excludedProductDetails={discountStrategyExcludedProductDetails}
                  refDiscountedElements={refDiscountedElements}
                />
              )
            }
            <StorefrontSettingsForm
              handleChange={handleChange}
              handleFormChange={handleFormChange}
              validator={validator}
              colorOptions={colorOptions}
              badge={badge}
              calloutMessage={calloutMessage}
              onCampaign={onCampaign}
              showInBrowsingJourney={showInBrowsingJourney}
              description={description}
              translatableFileds={translatableFileds}
              isDefault={isDefault}
            />
            {classType === CLASS_SHIPPING
              ? (
                <ClosenessQualifierForm
                  onCampaign={onCampaign}
                  isClosenessQualifierEnabled={isClosenessQualifierEnabled}
                  handleChange={handleChange}
                  closenessQualifierMessage={closenessQualifierMessage}
                  closenessApproachMessage={closenessApproachMessage}
                  closenessQualifierCriteriaSubTotal={closenessQualifierCriteriaSubTotal}
                  handleFormChange={handleFormChange}
                  validator={validator}
                />
              )
              : ""}
          </fieldset>
        </Form>
      </Col>
    </Row>
  );
};
PromotionListView.propTypes = {
  // form field
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  priority: PropTypes.number.isRequired,
  externalId: PropTypes.number.isRequired,

  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  indefinite: PropTypes.bool.isRequired,

  promotionType: PropTypes.func.isRequired,
  classType: PropTypes.string.isRequired,
  discountType: PropTypes.string.isRequired,

  combine: PropTypes.string.isRequired,
  categories: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.objectOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  fulfilmentTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  restrictedChannelGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  restrictedChannelGroupDetails: PropTypes.arrayOf(PropTypes.any).isRequired,

  excludeProductsOnOffer: PropTypes.bool.isRequired,
  excludeSourceDiscountedProducts: PropTypes.bool.isRequired,
  discountedItemsSameAsApplicableItems: PropTypes.bool.isRequired,
  showInBrowsingJourney: PropTypes.bool.isRequired,
  badge: PropTypes.string.isRequired,
  colorOptions: PropTypes.arrayOf(PropTypes.any).isRequired,

  discountStrategyCategories: PropTypes.objectOf(PropTypes.any).isRequired,
  discountStrategyBrands: PropTypes.objectOf(PropTypes.any).isRequired,
  discountStrategyProducts: PropTypes.objectOf(PropTypes.any).isRequired,
  discountStrategyExcludedProducts: PropTypes.objectOf(PropTypes.any).isRequired,
  discountedExcludeProductsOnOffer: PropTypes.bool.isRequired,
  discountedExcludeSourceDiscountedProducts: PropTypes.bool.isRequired,

  categoryDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  productDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  fulfilmentTypesDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  discountStrategyCategoryDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  discountStrategyBrandDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  promotionBundles: PropTypes.arrayOf(PropTypes.any).isRequired,
  discountStrategyProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  discountStrategyExcludedProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  bonusProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  bonusProduct: PropTypes.string.isRequired,

  cartValue: PropTypes.string.isRequired,
  discountRate: PropTypes.string.isRequired,
  discountAmount: PropTypes.string.isRequired,
  miniProduct: PropTypes.string.isRequired,
  buy: PropTypes.string.isRequired,
  payFor: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  isapplicationsPerOrderUnlimited: PropTypes.bool.isRequired,
  maxApplicationsPerOrder: PropTypes.number.isRequired,
  productOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  productOptionDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingFlags: PropTypes.objectOf(PropTypes.any).isRequired,

  message: PropTypes.string.isRequired,
  isClosenessQualifierEnabled: PropTypes.bool.isRequired,
  closenessQualifierMessage: PropTypes.string.isRequired,
  closenessApproachMessage: PropTypes.string.isRequired,
  closenessQualifierCriteriaSubTotal: PropTypes.number.isRequired,
  // functions
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  cancelForm: PropTypes.func.isRequired,
  calloutMessage: PropTypes.string.isRequired,
  handleBulkImport: PropTypes.func.isRequired,
  downloadBulkImportTemplate: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    handleSubmit: PropTypes.func,
  }).isRequired,

  // scroll ref
  refDiscountedElements: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refQualifiedElements: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refProductOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  localeHandler: PropTypes.func.isRequired,
};

export default PromotionListView;
