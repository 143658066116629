/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *

 * @author Roshna Accu
 *
 */


import React, { useState, useEffect, useRef } from "react";
import XLSX from "xlsx";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import PromotionCreatorView from "../components/PromotionCreatorView";

/** ========= API SERVICE FUNCTIONS ========= */
import { addPromotion } from "../../../api/promotionServices";
import { getProductForPromotion } from "../../../api/productManagementServices";
import { getCategoryByIds } from "../../../api/categoryManagementServices";

/** ========= CUSTOM COMPONENTS ========= */
import AlertModal from "../components/AlertModal";
import validateForm from "../../../common/utils/validateForm";

/** ========= UTILS ========= */
import promotionUtils from "../../../common/utils/promotionUtils";
import commonUtils from "../../../common/utils/commonUtils";
import constants from "../../../common/utils/constants";


const PromotionCreator = (props) => {
  const validator = validateForm();
  const { clearError, setError } = validator;
  const {
    onSave, onCancel, setPromotionFlag, open, activeTab,
  } = props;
  const refQualifiedElements = useRef(null);
  const refDiscountedElements = useRef(null);
  const refProductOptions = useRef(null);
  const { PROMOTION } = constants;
  const colorOptions = [
    "red", "blue", "green", "orange",
  ];
  const { t } = useTranslation(["promotion"]);

  const [form, setForm] = useState({
    name: "",
    priority: "",
    externalId: "",
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    classType: "",
    discountType: "",
    cartValue: "",
    indefinite: false,
    promotionType: "",
    discountRate: "",
    discountAmount: "",
    miniProduct: "",
    buy: "",
    payFor: "",
    amount: "",
    status: true,
    isapplicationsPerOrderUnlimited: true,
    maxApplicationsPerOrder: "",
    fulfilmentTypes: [],
    combine: "None",
    restrictedChannelGroups: [],
    categories: [],
    products: [],
    calloutMessage: "",
    brands: [],
    excludedProducts: [],
    discountStrategyCategories: [],
    discountStrategyBrands: [],
    discountStrategyProducts: [],
    discountStrategyExcludedProducts: [],
    discountedItemsSameAsApplicableItems: false,
    productOptions: [],
    bonusProduct: "",
    promotionBundles: [],
    discountStrategyGetableQuantity: "",
    badge: null,
    showInBrowsingJourney: true,
    excludeProductsOnOffer: true,
    excludeSourceDiscountedProducts: true,
    discountedExcludeProductsOnOffer: true,
    closenessQualifierMessage: "",
    closenessApproachMessage: "",
    closenessQualifierCriteriaSubTotal: "",
    isClosenessQualifierEnabled: false,
    categoryDetails: [],
    productDetails: [],
    brandDetails: [],
    excludedProductDetails: [],
  });
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [modalMattibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });
  const [loadingFlags, setLoadingFlags] = useState({
    context: "",
    value: false,
  });
  const history = useHistory();
  const [description, setDescription] = useState("");

  const handleChange = (name, value) => {
    if (name === "name" && typeof setPromotionFlag === "function" && value.length === 0) {
      setPromotionFlag(false);
    } else if (name === "name" && typeof setPromotionFlag === "function") {
      setPromotionFlag(true);
    }
    const clearQualifyingErrorArray = ["categories", "products", "brands", "classType", "promotionType", "discountType"];
    const clearDiscountedErrorArray = ["discountStrategyCategories", "discountStrategyProducts", "discountStrategyBrands", "classType", "promotionType", "discountType"];
    const clearProductOptionErrorArray = ["productOptions", "classType", "promotionType", "discountType"];
    if (name === "isapplicationsPerOrderUnlimited" && value) {
      setForm({
        ...form,
        [name]: value,
        maxApplicationsPerOrder: parseInt(0, 10),
      });
      clearError(["maxApplicationsPerOrder"]);
    } else if (name === "description") {
      setDescription(value);
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
    if (clearQualifyingErrorArray.includes(name)) {
      clearError(["qualifyingElements"]);
    }
    if (clearDiscountedErrorArray.includes(name)) {
      clearError(["discountedElements"]);
    }
    if (clearProductOptionErrorArray.includes(name)) {
      clearError(["productOptions"]);
    }
  };

  useEffect(() => {
    if (typeof setPromotionFlag === "function" && activeTab === "2") {
      handleChange("name", "");
    }
  }, [activeTab]);

  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const cancelForm = (event) => {
    if (typeof setPromotionFlag === "function") {
      setPromotionFlag(false);
    }
    if (onCancel) {
      setForm({
        name: "",
        priority: "",
        externalId: "",
        startDate: moment().startOf("day"),
        endDate: moment().endOf("day"),
        classType: "",
        discountType: "",
        cartValue: "",
        indefinite: false,
        promotionType: "",
        discountRate: "",
        discountAmount: "",
        miniProduct: "",
        buy: "",
        payFor: "",
        amount: "",
        status: true,
        isapplicationsPerOrderUnlimited: true,
        fulfilmentTypes: [],
        maxApplicationsPerOrder: "",
        combine: "None",
        restrictedChannelGroups: [],
        categories: [],
        products: [],
        calloutMessage: "",
        excludeProductsOnOffer: true,
        excludeSourceDiscountedProducts: true,
        discountStrategyCategories: [],
        discountStrategyBrands: [],
        discountStrategyProducts: [],
        discountStrategyExcludedProducts: [],
        discountedItemsSameAsApplicableItems: false,
        discountedExcludeProductsOnOffer: true,
        productOptions: [],
        bonusProduct: "",
        promotionBundles: [],
        badge: null,
        showInBrowsingJourney: true,
        discountStrategyPayableQuantity: "",
        isClosenessQualifierEnabled: false,
        closenessQualifierMessage: "",
        closenessApproachMessage: "",
        closenessQualifierCriteriaSubTotal: "",
      });
      setDescription("");
    } else {
      history.push("/marketing/promotion");
    }
    if (event) {
      onCancel();
    }
  };


  const ValidateArrayField = () => {
    clearError(["qualifyingElements", "discountedElements"]);
    let response = true;
    if (form.classType === PROMOTION.CLASS_PRODUCT
      && form.discountType !== PROMOTION.BUNDLE_PROMOTION) {
      if (form.discountType === PROMOTION.BUY_X_GET_Y_PROMOTION && !form.discountedItemsSameAsApplicableItems) {
        if (!commonUtils.isListNotEmpty(form.discountStrategyCategories)
          && !commonUtils.isListNotEmpty(form.discountStrategyProducts)
          && !commonUtils.isListNotEmpty(form.discountStrategyBrands)) {
          setError("discountedElements", "noMatch", "Please select at least 1 category, brand or product");
          if (refDiscountedElements && refDiscountedElements.current) {
            refDiscountedElements.current.scrollIntoView({ behavior: "smooth" });
          }
          response = false;
        }
      }
      if (!commonUtils.isListNotEmpty(form.categories)
        && !commonUtils.isListNotEmpty(form.brands)
        && !commonUtils.isListNotEmpty(form.products)) {
        setError("qualifyingElements", "noMatch", "Please select at least 1 category, brand or product");
        if (refQualifiedElements && refQualifiedElements.current) {
          refQualifiedElements.current.scrollIntoView({
            behavior: "smooth",
          });
        }
        response = false;
      }
      if (form.discountType === PROMOTION.PRODUCT_OPTION_PROMOTION
        && !commonUtils.isListNotEmpty(form.productOptions)) {
        setError("productOptions", "noMatch", "Please select at least 1 product option");
        if (refProductOptions && refProductOptions.current) {
          refProductOptions.current.scrollIntoView({
            behavior: "smooth",
          });
        }
        response = false;
      }
    }
    return response;
  };

  const getFormattedDate = (promotionDate) => {
    const dateFormat = "YYYY-MM-DD";
    const date = moment(promotionDate).format(dateFormat);
    return date;
  }

  const getFormattedTime = (promotionTime) => {
    const timeFormat = "HH:mm:ss";
    const time = moment(promotionTime).format(timeFormat);
    return time;
  }

  /**
   * This function is used to submit the from
   * @param {Event} event
   */
  const submitForm = async (data, event) => {
    if (data && ValidateArrayField()) {
      if (event) event.preventDefault();
      if (event) event.stopPropagation();
      const templateObject = { discountStrategy: form.promotionType };
      templateObject.discountRate = parseFloat(form.discountRate) || undefined;
      templateObject.amount = parseFloat(form.amount) || undefined;
      templateObject.minAmount = parseFloat(form.cartValue) || undefined;
      templateObject.maxDiscountAmount = parseFloat(form.discountAmount) || undefined;
      templateObject.excludeSourceDiscountedProducts = form.discountedExcludeSourceDiscountedProducts;
      templateObject.excludeProductsOnOffer = form.discountedExcludeProductsOnOffer;
      templateObject.discountedCategories = Array.isArray(form.discountStrategyCategories) ? form.discountStrategyCategories : [];
      templateObject.discountedBrands = Array.isArray(form.discountStrategyBrands) ? form.discountStrategyBrands : [];
      templateObject.discountedProducts = Array.isArray(form.discountStrategyProducts) ? form.discountStrategyProducts : [];
      templateObject.excludedProducts = Array.isArray(form.discountStrategyExcludedProducts) ? form.discountStrategyExcludedProducts : [];
      templateObject.discountedItemsSameAsApplicableItems = form.discountedItemsSameAsApplicableItems;
      templateObject.freeItems = form.bonusProduct ? [form.bonusProduct] : [];
      templateObject.bundleItems = Array.isArray(form.promotionBundles) ? form.promotionBundles : [];

      templateObject.qualifyingQuantity = parseFloat(form.buy) || undefined;
      templateObject.discountedQuantity = parseFloat(form.payFor) || undefined;

      if (form.promotionType === PROMOTION.PERCENTAGE_OFF
        && form.discountType === PROMOTION.BUY_X_GET_Y_PROMOTION) {
        templateObject.restrictToSameItemType = true;
      }

      let discountType = "";
      let promotionTemplate = form.discountType;

      if (PROMOTION.DISCOUNT_PRODUCTS === form.discountType) { discountType = "quantityPromotion"; }

      if (PROMOTION.DISCOUNT_AMOUNT === form.discountType) { discountType = "amountPromotion"; }

      if (PROMOTION.BUY_X_GET_Y_PROMOTION === form.discountType) { discountType = "buyXGetYPromotion"; }

      if (PROMOTION.BUY_X_PAY_Y_PROMOTION === form.discountType) { discountType = "buyXPayYPromotion"; }

      if (PROMOTION.BUNDLE_PROMOTION === form.discountType) { discountType = "bundlePromotion"; }

      if (PROMOTION.PRODUCT_OPTION_PROMOTION === form.discountType) {
        discountType = "amountPromotion";
        promotionTemplate = PROMOTION.DISCOUNT_AMOUNT;
      }

      if (PROMOTION.BONUS_OPTION_PROMOTION === form.discountType) {
        discountType = "buyXGetYPromotion";
        promotionTemplate = PROMOTION.BUY_X_GET_Y_PROMOTION;
        templateObject.discountedQuantity = 1;
      }

      const closenessQualifier = {
        qualifiedMessage: form.closenessQualifierMessage,
        approachingMessage: form.closenessApproachMessage,
        criteria: {
          subTotal: parseFloat(form.closenessQualifierCriteriaSubTotal) || undefined,
        },
      };
      const maxApplicationsPerOrder = !form.isapplicationsPerOrderUnlimited
        ? form.maxApplicationsPerOrder
        : 0;
      const context = await promotionUtils.getPromotionContext(form.classType, form.discountType);

      const requestBody = {
        title: form.name,
        description,
        priority: form.priority,
        externalId: form.externalId,
        active: form.status,
        type: form.classType,
        context,
        startDate: (form.startDate && moment.isMoment(form.startDate) && moment(form.startDate).format("YYYY-MM-DD HH:mm:ss")) || null,
        endDate: (form.endDate && moment.isMoment(form.endDate) && moment(form.endDate).format("YYYY-MM-DD HH:mm:ss")) || null,
        indefinite: !form.endDate,
        maxApplicationsPerOrder: parseInt(maxApplicationsPerOrder, 10),
        applicableFulfillmentTypes: form.fulfilmentTypes,
        combineWith: form.combine,
        restrictedChannelGroups: form.restrictedChannelGroups,
        applicableCategories: form.categories,
        applicableBrands: form.brands,
        applicableProducts: form.products,
        excludedProducts: form.excludedProducts,
        applicableProductOptions: form.productOptions,
        excludeSourceDiscountedProducts: form.excludeSourceDiscountedProducts,
        excludeOnOfferProducts: form.excludeProductsOnOffer,
        promotionTemplate,
        [discountType]: templateObject,
        calloutMessage: form.calloutMessage,
        showInBrowsingJourney: form.showInBrowsingJourney,
        badge: form.badge,
        closenessQualifier: form.isClosenessQualifierEnabled ? closenessQualifier : null,
      };

      const promotionStartDate = getFormattedDate(requestBody?.startDate);
      const promoionEndDate = getFormattedDate(requestBody?.endDate);
      const promotionStartTime = getFormattedTime(requestBody?.startDate);
      const promotionEndTime = getFormattedTime(requestBody?.endDate);
      if (promotionStartDate === promoionEndDate && promotionStartTime > promotionEndTime) {
        // Warning Message to be added 
        setMessage({ type: "warning", message: "Start time should not be greater than End time" });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      } else if (promotionStartDate === promoionEndDate && promotionStartTime === promotionEndTime) {
        // Warning Message to be added 
        setMessage({ type: "warning", message: "Start time should not be equal to End time" });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      } else {
        const response = await addPromotion(requestBody);
        setSubmitting(false);
        if (response && response.success) {
          cancelForm();
          if (onSave) {
            onSave(response.data);
          } else {
            setTimeout(() => {
              history.push("/marketing/promotion");
            }, 3000);
          }
          if (typeof setPromotionFlag === "function") {
            setPromotionFlag(false);
            cancelForm();
          }
        } else {
          if (response.messages) {
            setMessage({ type: "warning", message: response.messages[0] });
          }
          setTimeout(() => {
            setMessage({ type: null, message: "" });
          }, 3000);
        }
      }
    }
  };


  useEffect(() => {
    if (form.promotionType === PROMOTION.PERCENTAGE_OFF
      && form.discountType === PROMOTION.BUY_X_GET_Y_PROMOTION) {
      setForm({
        ...form,
        discountedItemsSameAsApplicableItems: true,
      });
    }
    if (form.discountType === PROMOTION.BUY_X_PAY_Y_PROMOTION) {
      setForm({
        ...form,
        discountedItemsSameAsApplicableItems: true,
      });
    }
  }, [form.discountType, form.promotionType]);

  useEffect(() => {
    const localDiscountType = form.classType
      && PROMOTION.CLASS_PRODUCT === form.classType ? "" : PROMOTION.DISCOUNT_AMOUNT;
    setForm((oldState) => ({
      ...oldState,
      discountType: localDiscountType,
      promotionType: "",
      discountRate: "",
      discountAmount: "",
      miniProduct: "",
      buy: "",
      payFor: "",
      amount: "",
      cartValue: "",
      isapplicationsPerOrderUnlimited: true,
      maxApplicationsPerOrder: "",
      fulfilmentTypes: [],
      productOptions: [],
      discountStrategyCategories: [],
      discountStrategyBrands: [],
      discountStrategyProducts: [],
      discountStrategyExcludedProducts: [],
      bonusProduct: "",
      promotionBundles: [],
    }));
  }, [form.classType]);

  useEffect(() => {
    const localPromotionType = (form.discountType
      && PROMOTION.BONUS_OPTION_PROMOTION === form.discountType) ? PROMOTION.FREE_ITEM : "";
    setForm((oldState) => ({
      ...oldState,
      promotionType: localPromotionType,
      discountRate: "",
      discountAmount: "",
      miniProduct: "",
      buy: "",
      payFor: "",
      amount: "",
      cartValue: "",
      isapplicationsPerOrderUnlimited: true,
      maxApplicationsPerOrder: "",
      productOptions: [],
      discountStrategyCategories: [],
      discountStrategyBrands: [],
      discountStrategyProducts: [],
      discountStrategyExcludedProducts: [],
      bonusProduct: "",
      promotionBundles: [],
    }));
  }, [form.discountType]);

  useEffect(() => {
    setForm((oldState) => ({
      ...oldState,
      discountRate: "",
      discountAmount: "",
      miniProduct: "",
      buy: "",
      payFor: "",
      amount: "",
      cartValue: "",
      isapplicationsPerOrderUnlimited: true,
      maxApplicationsPerOrder: "",
      productOptions: [],
      discountStrategyCategories: [],
      discountStrategyBrands: [],
      discountStrategyProducts: [],
      discountStrategyExcludedProducts: [],
      bonusProduct: "",
      promotionBundles: [],
    }));
  }, [form.promotionType]);

  useEffect(() => {
    setForm((oldState) => ({
      ...oldState,
      closenessQualifierMessage: "",
      closenessApproachMessage: "",
      closenessQualifierCriteriaSubTotal: "",
    }));
  }, [form.isClosenessQualifierEnabled]);

  const formatParsedData = (validOptions, refernceState) => {
    const mappedValidOptions = validOptions.map(
      (mapItem) => ({ id: mapItem.id, name: mapItem.name }),
    ).filter((filterItem) => !refernceState.includes(filterItem.id)); // formats the valid option from db
    return mappedValidOptions;
  };

  const handleBulkImport = (e, context) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          header: "Error",
        });
      } else {
        const { name } = e.target;
        const reader = new FileReader();
        reader.onload = async (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNameList = workbook.SheetNames;
          const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
          const parsedData = promotionUtils.parseDataFromSheet(rawData, context);
          if (parsedData.isValid) {
            const localForm = { ...form };
            if (name === "categories") {
              const filteredValues = parsedData.value.filter((filterItem) => !form.categories.includes(filterItem));
              setLoadingFlags({ context: name, value: true });
              const response = await getCategoryByIds(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = response.data.filter(
                  (filterItem) => (!form.categories.includes(filterItem.id)) && filterItem.type !== "Brand",
                ).map((mapItem) => ({ id: mapItem.id, name: mapItem.name }));
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.categoryDetails = formattedOptions;
                localForm.categories = [...form.categories, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_categories_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "brands") {
              const filteredValues = parsedData.value.filter((filterItem) => !form.brands.includes(filterItem));
              setLoadingFlags({ context: name, value: true });
              const response = await getCategoryByIds(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = response.data.filter(
                  (filterItem) => (!form.brands.includes(filterItem.id)) && filterItem.type === "Brand",
                ).map((mapItem) => ({ id: mapItem.id, name: mapItem.name }));
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.brandDetails = formattedOptions;
                localForm.brands = [...form.brands, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_brands_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "products") {
              const filteredValues = parsedData.value.filter((each) => !form.products.includes(each));
              setLoadingFlags({ context: name, value: true });
              const response = await getProductForPromotion(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = await formatParsedData(response.data, form.products);
                localForm.productDetails = formattedOptions;
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.products = [...form.products, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_products_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "excludedProducts") {
              const filteredValues = parsedData.value.filter((each) => !form.excludedProducts.includes(each));
              setLoadingFlags({ context: name, value: true });
              const response = await getProductForPromotion(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = await formatParsedData(response.data, form.products);
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.excludedProductDetails = formattedOptions;
                localForm.excludedProducts = [...form.excludedProducts, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_products_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "promotionBundles") {
              const productIds = form.promotionBundles.map((each) => each.productId);
              const filteredValues = parsedData.value.filter((each) => !productIds.includes(each));
              setLoadingFlags({ context: name, value: true });
              const response = await getProductForPromotion(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const mappedProducts = response.data.map((each) => {
                  const selectedOption = parsedData.options.find((option) => option.id === each.id);
                  return ({
                    id: each.id.toString(),
                    productName: each.name,
                    quantity: selectedOption.quantity,
                    productId: each.id.toString(),
                  });
                });
                localForm.promotionBundles = [...form.promotionBundles, ...mappedProducts];
                if (filteredValues.length > mappedProducts.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_bundles_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            setForm(localForm);
          } else {
            setLoadingFlags({ context: "", value: false });
            setModalAttributes({
              isOpen: true,
              content: parsedData.message || "Something went wrong.Please try again",
              header: "Error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
      e.target.value = null;
    }
  };

  const downloadBulkImportTemplate = (name, context) => {
    const fileName = `${name}_template.xlsx`;
    const templateData = promotionUtils.getImportSheetTemplate(context);
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      <PromotionCreatorView
        validator={validator}
        name={form.name}
        description={description}
        priority={form.priority}
        externalId={form.externalId}
        handleChange={handleChange}
        handleFormChange={handleFormChange}
        startDate={form.startDate}
        endDate={form.endDate}
        classType={form.classType}
        discountType={form.discountType}
        cartValue={form.cartValue}
        promotionType={form.promotionType}
        discountRate={form.discountRate}
        discountAmount={form.discountAmount}
        miniProduct={form.miniProduct}
        indefinite={form.indefinite}
        discountedItemsSameAsApplicableItems={form.discountedItemsSameAsApplicableItems}
        showInBrowsingJourney={form.showInBrowsingJourney}
        isapplicationsPerOrderUnlimited={form.isapplicationsPerOrderUnlimited}
        maxApplicationsPerOrder={form.maxApplicationsPerOrder}
        message={message}
        discountStrategyCategories={form.discountStrategyCategories}
        discountStrategyBrands={form.discountStrategyBrands}
        discountStrategyProducts={form.discountStrategyProducts}
        discountStrategyExcludedProducts={form.discountStrategyExcludedProducts}
        discountedExcludeProductsOnOffer={form.discountedExcludeProductsOnOffer}
        discountedExcludeSourceDiscountedProducts={form.discountedExcludeSourceDiscountedProducts}
        fulfilmentTypes={form.fulfilmentTypes}
        buy={form.buy}
        payFor={form.payFor}
        amount={form.amount}
        status={form.status}
        combine={form.combine}
        categories={form.categories}
        brands={form.brands}
        products={form.products}
        excludedProducts={form.excludedProducts}
        badge={form.badge}
        colorOptions={colorOptions}
        submitting={submitting}
        cancelForm={cancelForm}
        submitForm={submitForm}
        calloutMessage={form.calloutMessage}
        onCampaign={Boolean(onSave)}
        excludeProductsOnOffer={form.excludeProductsOnOffer}
        excludeSourceDiscountedProducts={form.excludeSourceDiscountedProducts}
        isClosenessQualifierEnabled={form.isClosenessQualifierEnabled}
        closenessQualifierMessage={form.closenessQualifierMessage}
        closenessApproachMessage={form.closenessApproachMessage}
        closenessQualifierCriteriaSubTotal={form.closenessQualifierCriteriaSubTotal}
        setDescription={setDescription}
        productOptions={form.productOptions}
        restrictedChannelGroups={form.restrictedChannelGroups}
        bonusProduct={form.bonusProduct}
        promotionBundles={form.promotionBundles}
        handleBulkImport={handleBulkImport}
        categoryDetails={form.categoryDetails}
        brandDetails={form.brandDetails}
        productDetails={form.productDetails}
        excludedProductDetails={form.excludedProductDetails}
        downloadBulkImportTemplate={downloadBulkImportTemplate}
        loadingFlags={loadingFlags}
        // scroll refs
        refDiscountedElements={refDiscountedElements}
        refQualifiedElements={refQualifiedElements}
        refProductOptions={refProductOptions}
      />
      <AlertModal
        isOpen={modalMattibutes.isOpen}
        content={modalMattibutes.content}
        header={modalMattibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};

PromotionCreator.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setPromotionFlag: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default PromotionCreator;
