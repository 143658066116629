/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author sreelekshmi
 *
*/

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/MultiSelect";

/** ========= API SERVICE FUNCTIONS ========= */

const BrandSelector = ({
  name, brands, setBrandSelected, selectedBrands, handleSearchField,
}) => {
  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  return (
    <Select
      className="formText"
      name={name}
      id="brand"
      value={selectedBrands}
      onChange={setBrandSelected}
      typeahead={handleSearchField}
      typeaheadPlaceholder={t("site-navigation:placeholder_select_Brands")}
      options={
        Array.isArray(brands)
        && brands.map(({ id: value, name: label }) => ({ value, label }))
      }
    />
  );
};

BrandSelector.propTypes = {
  name: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  setBrandSelected: PropTypes.func.isRequired,
  handleSearchField: PropTypes.func.isRequired,
};

export default BrandSelector;
