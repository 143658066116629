/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 *
 */

import httpApi from "./httpApi";

const moment = require("moment");

/**
 * This method is used to get Price Book List
 *
 * @returns
 */
const getPriceBookList = async () => {
  const priceBookList = [];
  const response = await httpApi.get("v1/pricing/pricebook/list");
  if (response && response.data) {
    const { data: requestData } = response;
    const { data } = requestData;
    if (data && Array.isArray(data)) {
      data.map((pricebook) => {
        priceBookList.push({ value: pricebook.id, label: pricebook.id });
        return null;
      });
    }
    return priceBookList;
  }
  return null;
};
/**
 * This method is used to get price table data
 *
 * @param {*} data
 * @returns
 */
const getPriceTableData = async (req, page, size) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { priceBookId, newDate, searchTerm } = req;
  const data = {
    priceBookId,
    date: newDate,
    timeZone,
    searchTerm,
    page,
    size,
  };
  const response = await httpApi.post("v1/pricing/pricetables/price", data);
  if (response && response.data && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to transform priceBooks data
 * @param Array
 * @returns {Array}
 */
const transformPriceBookData = async (priceBooks) => {
  const filtered = priceBooks.map(({
    priceBookId,
    skuId,
    externalSkuId,
    productId,
    listPrice,
    salePrice,
    updatedDate,
  }) => ({
    "Price Book Id": priceBookId,
    "Sku Id": skuId,
    "External SkuId": externalSkuId,
    "Product Id": productId,
    "List Price": listPrice || 0.0,
    "Sale Price": salePrice || 0.0,
    "Updated Date": updatedDate ? moment(updatedDate).format("YYYY-MM-DD HH:mm:ss") : null,
  }));
  return filtered;
}

export {
  getPriceBookList,
  getPriceTableData,
  transformPriceBookData,
};
