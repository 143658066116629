import moment, { locale } from "moment";
import XLSX from "xlsx";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import PromotionEditorView from "../components/PromotionEditorView";

/** ========= CUSTOM COMPONENTS ========= */
import validateForm from "../../../common/utils/validateForm";
import AlertModal from "../components/AlertModal";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";
import promotionUtils from "../../../common/utils/promotionUtils";
import commonUtils from "../../../common/utils/commonUtils";

import { updatePromotion, getPromotion } from "../../../api/promotionServices";
import { getProductForPromotion } from "../../../api/productManagementServices";
import { getCategoryByIds } from "../../../api/categoryManagementServices";
import { getTranslatedInputsByPage } from "../../../api/translationServices";

const PromotionEditor = () => {
  const history = useHistory();
  const validator = validateForm();
  const { clearError, setError } = validator;
  const refQualifiedElements = useRef(null);
  const refDiscountedElements = useRef(null);
  const refProductOptions = useRef(null);
  const { t } = useTranslation(["promotion"]);
  // const params = useRouteMatch();
  const { PROMOTION } = constants;
  const colorOptions = [
    "red", "blue", "green", "orange",
  ];
  const { params } = useRouteMatch();

  if (!params.promotionId) {
    history.push("/marketing/promotion");
  }

  const [form, setForm] = useState({
    id: "",
    name: "",
    priority: "",
    externalId: "",
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    classType: "",
    discountType: "",
    cartValue: "",
    indefinite: false,
    promotionType: "",
    discountRate: "",
    discountAmount: "",
    miniProduct: "",
    buy: "",
    payFor: "",
    amount: "",
    status: true,
    isapplicationsPerOrderUnlimited: true,
    maxApplicationsPerOrder: "",
    fulfilmentTypes: [],
    combine: "None",
    restrictedChannelGroups: [],
    categories: [],
    products: [],
    calloutMessage: "",
    brands: [],
    excludedProducts: [],
    discountStrategyCategories: [],
    discountStrategyBrands: [],
    discountStrategyProducts: [],
    discountStrategyExcludedProducts: [],
    discountedItemsSameAsApplicableItems: false,
    discountStrategyGetableQuantity: "",
    productOptions: [],
    badge: null,
    showInBrowsingJourney: true,
    excludeProductsOnOffer: true,
    discountedExcludeProductsOnOffer: true,
    categoryDetails: [],
    productDetails: [],
    brandDetails: [],
    excludedProductDetails: [],
    discountStrategyCategoryDetails: [],
    discountStrategyBrandDetails: [],
    discountStrategyProductDetails: [],
    discountStrategyExcludedProductDetails: [],
    productOptionDetails: [],
    bonusProduct: "",
    promotionBundles: [],
    bonusProductDetails: [],
    restrictedChannelGroupDetails: [],
    closenessQualifierMessage: "",
    closenessApproachMessage: "",
    closenessQualifierCriteriaSubTotal: "",
    isClosenessQualifierEnabled: false,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [modalMattibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });

  const [loadingFlags, setLoadingFlags] = useState({
    context: "",
    value: false,
  });

  const [description, setDescription] = useState("");
  const [selectedLanguage, setLanguage] = useState("en_AE");
  const [translatableFileds, setSelectedtranslatableFiledsect] = useState([]);
  const [isDefault, setIsDefault] = useState(true);

  const localeHandler = (localeLang, defaultIn, translatableProperties) => {
    setLanguage(localeLang);
    setSelectedtranslatableFiledsect(translatableProperties);
    setIsDefault(defaultIn);
  };

  const handleChange = (name, value) => {
    if (name === "description") {
      setDescription(value);
    } else {
      const formData = { ...form };
      formData[name] = value;
      if (name === "isapplicationsPerOrderUnlimited" && value) {
        formData.maxApplicationsPerOrder = parseInt(0, 10);
        clearError(["maxApplicationsPerOrder"]);
      }
      if (name === "classType") {
        const localDiscountType = PROMOTION.CLASS_PRODUCT === value ? "" : PROMOTION.DISCOUNT_AMOUNT;
        formData.discountType = localDiscountType;
        formData.promotionType = "";
        formData.discountRate = "";
        formData.discountAmount = "";
        formData.miniProduct = "";
        formData.buy = "";
        formData.payFor = "";
        formData.amount = "";
        formData.cartValue = "";
        formData.isapplicationsPerOrderUnlimited = true;
        formData.maxApplicationsPerOrder = "";
        formData.fulfilmentTypes = [];
        formData.productOptions = [];
        formData.discountStrategyCategories = [];
        formData.discountStrategyBrands = [];
        formData.discountStrategyProducts = [];
        formData.discountStrategyExcludedProducts = [];
        formData.bonusProduct = "";
        formData.promotionBundles = [];
        clearError(["qualifyingElements"]);
        clearError(["discountedElements"]);
        clearError(["productOptions"]);
      }
      if (name === "discountType") {
        formData.promotionType = PROMOTION.BONUS_OPTION_PROMOTION === value ? PROMOTION.FREE_ITEM : "";
        formData.discountRate = "";
        formData.discountAmount = "";
        formData.miniProduct = "";
        formData.buy = "";
        formData.payFor = "";
        formData.amount = "";
        formData.cartValue = "";
        formData.isapplicationsPerOrderUnlimited = true;
        formData.maxApplicationsPerOrder = "";
        formData.productOptions = [];
        formData.discountStrategyCategories = [];
        formData.discountStrategyBrands = [];
        formData.discountStrategyProducts = [];
        formData.discountStrategyExcludedProducts = [];
        formData.bonusProduct = "";
        formData.promotionBundles = [];
        clearError(["qualifyingElements"]);
        clearError(["discountedElements"]);
        clearError(["productOptions"]);
      }
      if (name === "promotionType") {
        formData.discountRate = "";
        formData.discountAmount = "";
        formData.miniProduct = "";
        formData.buy = "";
        formData.payFor = "";
        formData.amount = "";
        formData.cartValue = "";
        formData.isapplicationsPerOrderUnlimited = true;
        formData.maxApplicationsPerOrder = "";
        formData.productOptions = [];
        formData.discountStrategyCategories = [];
        formData.discountStrategyBrands = [];
        formData.discountStrategyProducts = [];
        formData.discountStrategyExcludedProducts = [];
        formData.bonusProduct = "";
        formData.promotionBundles = [];
        clearError(["qualifyingElements"]);
        clearError(["discountedElements"]);
        clearError(["productOptions"]);
      }
      if (name === "isClosenessQualifierEnabled") {
        formData.closenessQualifierMessage = "";
        formData.closenessApproachMessage = "";
        formData.closenessQualifierCriteriaSubTotal = "";
      }
      if (name === "categories" || name === "products" || name === "brands") {
        clearError(["qualifyingElements"]);
      }
      if (name === "discountStrategyCategories" || name === "discountStrategyProducts" || name === "discountStrategyBrands") {
        clearError(["discountedElements"]);
      }
      if (name === "productOptions") {
        clearError(["productOptions"]);
      }
      setForm(formData);
    }
  };

  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const cancelForm = () => {
    history.push("/marketing/promotion/");
  };

  useEffect(() => {
    let localForm = form;
    getPromotion(params.promotionId, selectedLanguage).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        let discountType = "";

        if (PROMOTION.DISCOUNT_PRODUCTS === data.promotionTemplate) { discountType = "quantityPromotion"; }

        if (PROMOTION.DISCOUNT_AMOUNT === data.promotionTemplate) { discountType = "amountPromotion"; }

        if (PROMOTION.BUY_X_GET_Y_PROMOTION === data.promotionTemplate) { discountType = "buyXGetYPromotion"; }

        if (PROMOTION.BUY_X_PAY_Y_PROMOTION === data.promotionTemplate) { discountType = "buyXPayYPromotion"; }

        if (PROMOTION.BUNDLE_PROMOTION === data.promotionTemplate) { discountType = "bundlePromotion"; }

        let discountAmount = "";
        let cartValue = "";
        let amount = "";
        let discountRate = "";
        let promotionType = "";
        let discountedExcludeProductsOnOffer = true;
        let discountedExcludeSourceDiscountedProducts = true;
        let discountStrategyCategories = [];
        let discountStrategyBrands = [];
        let discountStrategyProducts = [];
        let discountStrategyExcludedProducts = [];
        let discountedItemsSameAsApplicableItems = "";
        let discountStrategyCategoryDetails = [];
        let discountStrategyBrandDetails = [];
        let discountStrategyProductDetails = [];
        let discountStrategyExcludedProductDetails = [];
        let bonusProduct = "";
        let bonusProductDetails = [];
        let promotionBundles = [];
        let buy = "";
        let payFor = "";
        if (discountType && data[discountType]) {
          const inDataObject = data[discountType];
          discountAmount = inDataObject.maxDiscountAmount || "";
          cartValue = inDataObject.minAmount;
          amount = inDataObject.amount;
          discountRate = inDataObject.discountRate;
          promotionType = inDataObject.discountStrategy;
          discountedExcludeProductsOnOffer = inDataObject.excludeProductsOnOffer || false;
          discountedExcludeSourceDiscountedProducts = inDataObject.excludeSourceDiscountedProducts || false;

          discountStrategyCategories = Array.isArray(inDataObject.discountedCategories) ? inDataObject.discountedCategories.map((each) => each.id || each) : [];
          discountStrategyBrands = Array.isArray(inDataObject.discountedBrands) ? inDataObject.discountedBrands.map((each) => each.id || each) : [];
          discountStrategyProducts = Array.isArray(inDataObject.discountedProducts) ? inDataObject.discountedProducts.map((each) => each.id || each) : [];
          discountStrategyExcludedProducts = Array.isArray(inDataObject.excludedProducts) ? inDataObject.excludedProducts.map((each) => each.id || each) : [];
          bonusProduct = Array.isArray(inDataObject.freeItems) && inDataObject.freeItems.length > 0 && inDataObject.freeItems[0] ? inDataObject.freeItems[0].id : "";
          promotionBundles = Array.isArray(inDataObject.bundleItems) && inDataObject.bundleItems.length > 0 ? inDataObject.bundleItems.map((each) => ({
            ...(typeof each === "object" && each),
            id: each.productId || each,
            name: each.productName || each,
            quantity: each.quantity,
            productId: each.productId || each,
          })) : [];
          discountedItemsSameAsApplicableItems = inDataObject.discountedItemsSameAsApplicableItems || "";

          discountStrategyCategoryDetails = (Array.isArray(inDataObject.discountedCategories) && inDataObject.discountedCategories.length > 0 && inDataObject.discountedCategories.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [];
          discountStrategyBrandDetails = (Array.isArray(inDataObject.discountedBrands) && inDataObject.discountedBrands.length > 0 && inDataObject.discountedBrands.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [];
          discountStrategyProductDetails = (Array.isArray(inDataObject.discountedProducts) && inDataObject.discountedProducts.length > 0 && inDataObject.discountedProducts.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [];
          discountStrategyExcludedProductDetails = (Array.isArray(inDataObject.excludedProducts) && inDataObject.excludedProducts.length > 0 && inDataObject.excludedProducts.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [];
          bonusProductDetails = (Array.isArray(inDataObject.freeItems) && inDataObject.freeItems.length > 0 && inDataObject.freeItems.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [];
          buy = inDataObject.qualifyingQuantity || "";
          payFor = inDataObject.discountedQuantity || "";
        }
        let isClosenessQualifierEnabled = false;
        let closenessQualifierMessage = "";
        let closenessApproachMessage = "";
        let closenessQualifierCriteriaSubTotal = "";
        if (data.closenessQualifier
          && data.closenessQualifier.qualifiedMessage
          && data.closenessQualifier.approachingMessage
          && data.closenessQualifier.criteria
          && data.closenessQualifier.criteria.subTotal) {
          isClosenessQualifierEnabled = true;
          closenessQualifierMessage = data.closenessQualifier.qualifiedMessage;
          closenessApproachMessage = data.closenessQualifier.approachingMessage;
          closenessQualifierCriteriaSubTotal = data.closenessQualifier.criteria.subTotal;
        }
        const maxApplicationsPerOrder = data.maxApplicationsPerOrder && parseInt(data.maxApplicationsPerOrder, 10);
        setDescription(data.description || "");

        let discountFor = data.promotionTemplate;

        if (data.context === PROMOTION.CONTEXT_PRODUCT_OPTION) {
          discountFor = PROMOTION.PRODUCT_OPTION_PROMOTION;
        }
        if (data.promotionTemplate === PROMOTION.BUY_X_GET_Y_PROMOTION && promotionType === PROMOTION.FREE_ITEM) {
          discountFor = PROMOTION.BONUS_OPTION_PROMOTION;
        }
        localForm = {
          ...form,
          id: data.id,
          name: data.title || "",
          startDate: (data.startDate && moment(data.startDate)) || null,
          endDate: (data.endDate && moment(data.endDate)) || null,
          status: data.active,
          classType: data.type || "",
          combine: data.combineWith,
          priority: data.priority,
          externalId: data.externalId || "",

          categories: Array.isArray(data.applicableCategories) ? data.applicableCategories.map((each) => each.id || each) : [],
          brands: Array.isArray(data.applicableBrands) ? data.applicableBrands.map((each) => each.id || each) : [],
          products: Array.isArray(data.applicableProducts) ? data.applicableProducts.map((each) => each.id || each) : [],
          excludedProducts: Array.isArray(data.excludedProducts) ? data.excludedProducts.map((each) => each.id || each) : [],
          fulfilmentTypes: Array.isArray(data.applicableFulfillmentTypes) ? data.applicableFulfillmentTypes.map((each) => each.code || each) : [],
          restrictedChannelGroups: Array.isArray(data.restrictedChannelGroups) ? data.restrictedChannelGroups.map((each) => each.id || each) : [],
          productOptions: Array.isArray(data.applicableProductOptions) ? data.applicableProductOptions.map((each) => each.id || each) : [],

          categoryDetails: (Array.isArray(data.applicableCategories) && data.applicableCategories.length > 0 && data.applicableCategories.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [],
          productDetails: (Array.isArray(data.applicableProducts) && data.applicableProducts.length > 0 && data.applicableProducts.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [],
          brandDetails: (Array.isArray(data.applicableBrands) && data.applicableBrands.length > 0 && data.applicableBrands.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [],
          excludedProductDetails: (Array.isArray(data.excludedProducts) && data.excludedProducts.length > 0 && data.excludedProducts.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [],
          fulfilmentTypesDetails: (Array.isArray(data.applicableFulfillmentTypes) && data.applicableFulfillmentTypes.map((each) => ({ ...(typeof each === "object" && each), id: each.code || each, name: each.name || each }))) || [],
          restrictedChannelGroupDetails: (Array.isArray(data.restrictedChannelGroups) && data.restrictedChannelGroups.length > 0 && data.restrictedChannelGroups.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [],
          productOptionDetails: (Array.isArray(data.applicableProductOptions) && data.applicableProductOptions.length > 0 && data.applicableProductOptions.map((each) => ({ ...(typeof each === "object" && each), id: each.id || each, name: each.name || each }))) || [],

          excludeProductsOnOffer: data.excludeOnOfferProducts,
          excludeSourceDiscountedProducts: data.excludeSourceDiscountedProducts,
          calloutMessage: data.calloutMessage,
          showInBrowsingJourney: data.showInBrowsingJourney,
          badge: data.badge,
          discountType: discountFor,
          indefinite: data.indefinite,
          discountAmount,
          cartValue,
          amount,
          discountRate,
          promotionType,
          buy,
          payFor,
          maxApplicationsPerOrder,
          isapplicationsPerOrderUnlimited: maxApplicationsPerOrder === 0,
          discountedExcludeProductsOnOffer,
          discountedExcludeSourceDiscountedProducts,
          discountStrategyCategories,
          discountStrategyBrands,
          discountStrategyProducts,
          discountStrategyExcludedProducts,
          discountedItemsSameAsApplicableItems,
          discountStrategyCategoryDetails,
          discountStrategyBrandDetails,
          discountStrategyProductDetails,
          discountStrategyExcludedProductDetails,
          bonusProduct,
          bonusProductDetails,
          promotionBundles,
          closenessQualifierMessage,
          closenessApproachMessage,
          closenessQualifierCriteriaSubTotal,
          isClosenessQualifierEnabled,
        };
        setForm(localForm);
      }
    });
  }, [params.promotionId, selectedLanguage]);

  const ValidateArrayField = () => {
    clearError(["categories", "products", "brands", "excludedProducts"]);
    let response = true;
    if (form.classType === PROMOTION.CLASS_PRODUCT
      && form.discountType !== PROMOTION.BUNDLE_PROMOTION) {
      if (form.discountType === PROMOTION.BUY_X_GET_Y_PROMOTION
        && !form.discountedItemsSameAsApplicableItems) {
        if (!commonUtils.isListNotEmpty(form.discountStrategyCategories)
          && !commonUtils.isListNotEmpty(form.discountStrategyProducts)
          && !commonUtils.isListNotEmpty(form.discountStrategyBrands)) {
          setError("discountedElements", "noMatch", "Please select at least 1 category, brand or product");
          if (refDiscountedElements && refDiscountedElements.current) {
            refDiscountedElements.current.scrollIntoView({
              behavior: "smooth",
            });
          }
          response = false;
        }
      }
      if (!commonUtils.isListNotEmpty(form.categories)
        && !commonUtils.isListNotEmpty(form.brands)
        && !commonUtils.isListNotEmpty(form.products)) {
        setError("qualifyingElements", "noMatch", "Please select at least 1 category, brand or product");
        if (refQualifiedElements && refQualifiedElements.current) {
          refQualifiedElements.current.scrollIntoView({ behavior: "smooth" });
        }
        response = false;
      }
      if (form.discountType === PROMOTION.PRODUCT_OPTION_PROMOTION
        && !commonUtils.isListNotEmpty(form.productOptions)) {
        setError("productOptions", "noMatch", "Please select at least 1 product option");
        if (refProductOptions && refProductOptions.current) {
          refProductOptions.current.scrollIntoView({
            behavior: "smooth",
          });
        }
        response = false;
      }
    }
    return response;
  };

  const getFormattedDate = (promotionDate) => {
    const dateFormat = "YYYY-MM-DD";
    const date = moment(promotionDate).format(dateFormat);
    return date;
  }

  const getFormattedTime = (promotionTime) => {
    const timeFormat = "HH:mm:ss";
    const time = moment(promotionTime).format(timeFormat);
    return time;
  }

  /**
* This function is used to submit the from
* @param {Event} event
*/
  const submitForm = async (event) => {
    if (ValidateArrayField()) {
      const templateObject = { discountStrategy: form.promotionType };
      templateObject.discountRate = parseFloat(form.discountRate) || undefined;
      templateObject.amount = parseFloat(form.amount) || undefined;
      templateObject.minAmount = parseFloat(form.cartValue) || undefined;
      templateObject.maxDiscountAmount = parseFloat(form.discountAmount) || undefined;
      templateObject.excludeProductsOnOffer = form.discountedExcludeProductsOnOffer;
      templateObject.excludeSourceDiscountedProducts = form.discountedExcludeSourceDiscountedProducts;
      templateObject.discountedCategories = Array.isArray(form.discountStrategyCategories) ? form.discountStrategyCategories : [];
      templateObject.discountedBrands = Array.isArray(form.discountStrategyBrands) ? form.discountStrategyBrands : [];
      templateObject.discountedProducts = Array.isArray(form.discountStrategyProducts) ? form.discountStrategyProducts : [];
      templateObject.excludedProducts = Array.isArray(form.discountStrategyExcludedProducts) ? form.discountStrategyExcludedProducts : [];
      templateObject.discountedItemsSameAsApplicableItems = form.discountedItemsSameAsApplicableItems;
      templateObject.freeItems = form.bonusProduct ? [form.bonusProduct] : [];
      templateObject.bundleItems = Array.isArray(form.promotionBundles) ? form.promotionBundles : [];

      templateObject.qualifyingQuantity = parseFloat(form.buy) || undefined;
      templateObject.discountedQuantity = parseFloat(form.payFor) || undefined;

      if (form.promotionType === PROMOTION.PERCENTAGE_OFF
        && form.discountType === PROMOTION.BUY_X_GET_Y_PROMOTION) {
        templateObject.restrictToSameItemType = true;
      }
      let discountType = "";
      let promotionTemplate = form.discountType;

      let applicableCategories = form.categories;
      let applicableBrands = form.brands;
      let applicableProducts = form.products;
      let { excludedProducts } = form;
      let { excludeSourceDiscountedProducts } = form;
      let { excludeProductsOnOffer } = form;

      if (PROMOTION.DISCOUNT_PRODUCTS === form.discountType) { discountType = "quantityPromotion"; }

      if (PROMOTION.DISCOUNT_AMOUNT === form.discountType) { discountType = "amountPromotion"; }

      if (PROMOTION.BUY_X_GET_Y_PROMOTION === form.discountType) { discountType = "buyXGetYPromotion"; }

      if (PROMOTION.BUY_X_PAY_Y_PROMOTION === form.discountType) { discountType = "buyXPayYPromotion"; }

      if (PROMOTION.BUNDLE_PROMOTION === form.discountType) {
        discountType = "bundlePromotion";
        templateObject.discountedItemsSameAsApplicableItems = false;
        applicableCategories = [];
        applicableBrands = [];
        applicableProducts = [];
        excludedProducts = [];
        excludeSourceDiscountedProducts = false;
        excludeProductsOnOffer = false;
      }

      if (PROMOTION.PRODUCT_OPTION_PROMOTION === form.discountType) {
        discountType = "amountPromotion";
        promotionTemplate = PROMOTION.DISCOUNT_AMOUNT;
      }

      if (PROMOTION.BONUS_OPTION_PROMOTION === form.discountType) {
        discountType = "buyXGetYPromotion";
        promotionTemplate = PROMOTION.BUY_X_GET_Y_PROMOTION;
        templateObject.discountedQuantity = 1;
      }

      const closenessQualifier = {
        qualifiedMessage: form.closenessQualifierMessage,
        approachingMessage: form.closenessApproachMessage,
        criteria: {
          subTotal: parseFloat(form.closenessQualifierCriteriaSubTotal) || undefined,
        },
      };

      const maxApplicationsPerOrder = !form.isapplicationsPerOrderUnlimited
        ? form.maxApplicationsPerOrder
        : 0;
      const context = await promotionUtils.getPromotionContext(form.classType, form.discountType);
      const requestBody = {
        id: form.id,
        title: form.name,
        description,
        priority: form.priority,
        externalId: form.externalId,
        active: form.status,
        type: form.classType,
        context,
        startDate: (form.startDate && moment.isMoment(form.startDate) && moment(form.startDate).format("YYYY-MM-DD HH:mm:ss")) || null,
        endDate: (form.endDate && moment.isMoment(form.endDate) && moment(form.endDate).format("YYYY-MM-DD HH:mm:ss")) || null,
        indefinite: !form.endDate,
        maxApplicationsPerOrder: parseInt(maxApplicationsPerOrder, 10),
        applicableFulfillmentTypes: form.fulfilmentTypes,
        combineWith: form.combine,
        restrictedChannelGroups: form.restrictedChannelGroups,
        applicableCategories,
        applicableBrands,
        applicableProducts,
        excludedProducts,
        excludeSourceDiscountedProducts,
        excludeOnOfferProducts: excludeProductsOnOffer,
        applicableProductOptions: form.productOptions,
        promotionTemplate,
        [discountType]: templateObject,
        calloutMessage: form.calloutMessage,
        showInBrowsingJourney: form.showInBrowsingJourney,
        badge: form.badge,
        closenessQualifier: form.isClosenessQualifierEnabled ? closenessQualifier : null,
      };

      const promotionStartDate = getFormattedDate(requestBody?.startDate);
      const promoionEndDate = getFormattedDate(requestBody?.endDate);
      const promotionStartTime = getFormattedTime(requestBody?.startDate);
      const promotionEndTime = getFormattedTime(requestBody?.endDate);
      if (promotionStartDate === promoionEndDate && promotionStartTime > promotionEndTime) {
        // Warning Message to be added 
        setMessage({ type: "warning", message: "Start time should not be greater than End time" });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      } else if (promotionStartDate === promoionEndDate && promotionStartTime === promotionEndTime) {
        // Warning Message to be added 
        setMessage({ type: "warning", message: "Start time should not be equal to End time" });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      }
      else {
        const response = await updatePromotion(requestBody, selectedLanguage);
        if (response && response.success) {
          setTimeout(() => {
            history.push("/marketing/promotion");
          }, 3000);
        } else {
          if (response && response.messages) {
            setMessage({ type: "warning", message: response.messages[0] || "" });
          }
          setTimeout(() => {
            setMessage({ type: null, message: "" });
          }, 3000);
        }
      }

      setOnSubmitting(false);
    }
  };

  useEffect(() => {
    if (form.promotionType === PROMOTION.PERCENTAGE_OFF
      && form.discountType === PROMOTION.BUY_X_GET_Y_PROMOTION) {
      setForm({
        ...form,
        discountedItemsSameAsApplicableItems: true,
      });
    }
    if (form.discountType === PROMOTION.BUY_X_PAY_Y_PROMOTION) {
      setForm({
        ...form,
        discountedItemsSameAsApplicableItems: true,
      });
    }
  }, [form.discountType, form.promotionType]);

  const formatParsedData = (validOptions, refernceState) => {
    const mappedValidOptions = validOptions.map(
      (mapItem) => ({ id: mapItem.id, name: mapItem.name }),
    ).filter((filterItem) => !refernceState.includes(filterItem.id)); // formats the valid option from db
    return mappedValidOptions;
  };

  const handleBulkImport = (e, context) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          header: "Error",
        });
      } else {
        const { name } = e.target;
        const reader = new FileReader();
        reader.onload = async (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNameList = workbook.SheetNames;
          const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
          const parsedData = promotionUtils.parseDataFromSheet(rawData, context);
          if (parsedData.isValid) {
            const localForm = { ...form };
            if (name === "categories") {
              const filteredValues = parsedData.value.filter((filterItem) => !form.categories.includes(filterItem));
              setLoadingFlags({ context: name, value: true });
              const response = await getCategoryByIds(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = response.data.filter(
                  (filterItem) => (!form.categories.includes(filterItem.id)) && filterItem.type !== "Brand",
                ).map((mapItem) => ({ id: mapItem.id, name: mapItem.name }));
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.categoryDetails = formattedOptions;
                localForm.categories = [...form.categories, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_categories_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "brands") {
              const filteredValues = parsedData.value.filter((filterItem) => !form.brands.includes(filterItem));
              setLoadingFlags({ context: name, value: true });
              const response = await getCategoryByIds(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = response.data.filter(
                  (filterItem) => (!form.brands.includes(filterItem.id)) && filterItem.type === "Brand",
                ).map((mapItem) => ({ id: mapItem.id, name: mapItem.name }));
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.brandDetails = formattedOptions;
                localForm.brands = [...form.brands, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_brands_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "products") {
              const filteredValues = parsedData.value.filter((each) => !form.products.includes(each));
              setLoadingFlags({ context: name, value: true });
              const response = await getProductForPromotion(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = await formatParsedData(response.data, form.products);
                localForm.productDetails = formattedOptions;
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.products = [...form.products, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_products_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "excludedProducts") {
              const filteredValues = parsedData.value.filter((each) => !form.excludedProducts.includes(each));
              setLoadingFlags({ context: name, value: true });
              const response = await getProductForPromotion(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const formattedOptions = await formatParsedData(response.data, form.products);
                const mappedValues = formattedOptions.map((each) => each.id);
                localForm.excludedProductDetails = formattedOptions;
                localForm.excludedProducts = [...form.excludedProducts, ...mappedValues];
                if (filteredValues.length > formattedOptions.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_products_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setLoadingFlags({ context: "", value: false });
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            if (name === "promotionBundles") {
              const productIds = form.promotionBundles.map((each) => each.productId);
              const filteredValues = parsedData.value.filter((each) => !productIds.includes(each));
              setLoadingFlags({ context: name, value: true });
              const response = await getProductForPromotion(filteredValues);
              if (response && response.success && response.data) {
                setLoadingFlags({ context: "", value: false });
                const mappedProducts = response.data.map((each) => {
                  const selectedOption = parsedData.options.find((option) => option.id === each.id);
                  return ({
                    id: each.id.toString(),
                    productName: each.name,
                    quantity: selectedOption.quantity,
                    productId: each.id.toString(),
                  });
                });
                localForm.promotionBundles = [...form.promotionBundles, ...mappedProducts];
                if (filteredValues.length > mappedProducts.length) {
                  setModalAttributes({
                    isOpen: true,
                    content: t("promotion:alert_inavalid_bundles_exist"),
                    header: "Alert",
                  });
                }
              } else {
                setModalAttributes({
                  isOpen: true,
                  content: t("promotion:error_something_went_wrong"),
                  header: "Error",
                });
              }
            }
            setForm(localForm);
          } else {
            setLoadingFlags({ context: "", value: false });
            setModalAttributes({
              isOpen: true,
              content: parsedData.message || "Something went wrong.Please try again",
              header: "Error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
      e.target.value = null;
    }
  };

  const downloadBulkImportTemplate = (name, context) => {
    const fileName = `${name}_template.xlsx`;
    const templateData = promotionUtils.getImportSheetTemplate(context);
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      <PromotionEditorView
        validator={validator}
        id={form.id}
        name={form.name}
        description={description}
        priority={form.priority}
        externalId={form.externalId}
        handleChange={handleChange}
        handleFormChange={handleFormChange}
        startDate={form.startDate}
        endDate={form.endDate}
        classType={form.classType}
        discountType={form.discountType}
        cartValue={form.cartValue}
        promotionType={form.promotionType}
        discountRate={form.discountRate}
        discountAmount={form.discountAmount}
        miniProduct={form.miniProduct}
        indefinite={form.indefinite}
        discountedItemsSameAsApplicableItems={form.discountedItemsSameAsApplicableItems}
        showInBrowsingJourney={form.showInBrowsingJourney}
        isapplicationsPerOrderUnlimited={form.isapplicationsPerOrderUnlimited}
        maxApplicationsPerOrder={form.maxApplicationsPerOrder}
        message={message}
        discountStrategyCategories={form.discountStrategyCategories}
        discountStrategyBrands={form.discountStrategyBrands}
        discountStrategyProducts={form.discountStrategyProducts}
        discountStrategyExcludedProducts={form.discountStrategyExcludedProducts}
        discountedExcludeProductsOnOffer={form.discountedExcludeProductsOnOffer}
        discountedExcludeSourceDiscountedProducts={form.discountedExcludeSourceDiscountedProducts}
        fulfilmentTypes={form.fulfilmentTypes}

        buy={form.buy}
        payFor={form.payFor}
        amount={form.amount}
        status={form.status}
        combine={form.combine}
        categories={form.categories}
        brands={form.brands}
        products={form.products}
        excludedProducts={form.excludedProducts}
        badge={form.badge}
        colorOptions={colorOptions}
        submitting={onSubmitting}
        cancelForm={cancelForm}
        submitForm={submitForm}
        calloutMessage={form.calloutMessage}
        onCampaign={false}
        excludeProductsOnOffer={form.excludeProductsOnOffer}
        excludeSourceDiscountedProducts={form.excludeSourceDiscountedProducts}

        categoryDetails={form.categoryDetails}
        productDetails={form.productDetails}
        brandDetails={form.brandDetails}
        fulfilmentTypesDetails={form.fulfilmentTypesDetails}
        excludedProductDetails={form.excludedProductDetails}
        discountStrategyCategoryDetails={form.discountStrategyCategoryDetails}
        discountStrategyBrandDetails={form.discountStrategyBrandDetails}
        discountStrategyProductDetails={form.discountStrategyProductDetails}
        discountStrategyExcludedProductDetails={form.discountStrategyExcludedProductDetails}
        isClosenessQualifierEnabled={form.isClosenessQualifierEnabled}
        closenessQualifierMessage={form.closenessQualifierMessage}
        closenessQualifierCriteriaSubTotal={form.closenessQualifierCriteriaSubTotal}
        closenessApproachMessage={form.closenessApproachMessage}
        productOptions={form.productOptions}
        productOptionDetails={form.productOptionDetails}
        restrictedChannelGroups={form.restrictedChannelGroups}
        restrictedChannelGroupDetails={form.restrictedChannelGroupDetails}
        bonusProduct={form.bonusProduct}
        bonusProductDetails={form.bonusProductDetails}
        promotionBundles={form.promotionBundles}
        handleBulkImport={handleBulkImport}
        downloadBulkImportTemplate={downloadBulkImportTemplate}

        // scroll refs
        refDiscountedElements={refDiscountedElements}
        refQualifiedElements={refQualifiedElements}
        refProductOptions={refProductOptions}
        translatableFileds={translatableFileds}
        selectedLanguage={selectedLanguage}
        localeHandler={localeHandler}
        isDefault={isDefault}
      />
      <AlertModal
        isOpen={modalMattibutes.isOpen}
        content={modalMattibutes.content}
        header={modalMattibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};

export default PromotionEditor;
