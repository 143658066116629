/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Input from "../../../common/core/Input";
import Label from "../../../common/core/Label";
import Modal from "../../../common/core/Modal";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import ModalBody from "../../../common/core/ModalBody";
import ModalFooter from "../../../common/core/ModalFooter";
import FormFeedback from "../../../common/core/FormFeedback";

/** ========= CUSTOM COMPONENTS ========= */
import Select from "../../../common/components/MultiSelect/Select";
import validateForm from "../../../common/utils/validateForm";

/** ========= UTILS ========= */
import constants from "../../../common/utils/constants";

/** ========= SUB COMPONENT ========= */
import SelectedItemListView from "./SelectedItemListView";
import CustomMenuItem from "../containers/CustomMenuItemCreatorContainer";
import ProductSelector from "../containers/ProductSelectorContainer";
import CategorySelector from "../containers/CategorySelectorContainer";

/** ========= MODULE STYLES ========= */
import styles from "../css/MegaMenuItemCreator.module.css";
import BrandSelectorContainer from "../containers/BrandSelectorContainer";

/** ============ SVG IMAGE ICON ===================== */

const MegaMenuItemCreator = (props) => {
  const {
    open, column, parentMenu, name, url,
    type, limit, menus, onEdit, navigable,
    isWebFooter, isWebResponsiveHeader,
    columns, parentMenus, onSubmitting, menuTypes,
    handleChange, handleFormChange, removeItem, handleMenuChange,
    formCancel, formSubmit,
  } = props;

  const navigationItemTypes = constants.NAVIGATION_ITEM_TYPES;

  const validator = validateForm();
  const { handleSubmit, register, errors } = validator;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  return (
    <div>
      <Modal isOpen={open} toggle={() => formCancel(true)} className={styles.form}>
        <Form onSubmit={handleSubmit(formSubmit)}>
          <fieldset disabled={onSubmitting}>
            <div className={clsx("bg-light", "modal-header", styles.header)}>
              <Row>
                <Col xs="12" sm={(isWebFooter || isWebResponsiveHeader) ? "12" : "6"}>
                  <FormGroup>
                    <Label className={clsx("formTextLabel", styles.formLabel)}>
                      {t("site-navigation:label_parent_item")}
                    </Label>
                    <Select
                      className="formText"
                      placeholder={t("site-navigation:placeholder_parent_item")}
                      name="parentMenu"
                      id="submenu_parentMenu"
                      value={parentMenu}
                      onChange={handleMenuChange}
                      options={[
                        {
                          value: "",
                          disabled: true,
                          label: t("site-navigation:placeholder_parent_item"),
                        },
                        ...(Array.isArray(parentMenus)
                          && parentMenus.map(({ id: value, name: label }) => ({ value, label }))),
                      ]}
                    />
                  </FormGroup>
                </Col>
                {
                  !(isWebFooter || isWebResponsiveHeader) && (
                    <Col xs="12" sm="6">
                      <FormGroup>
                        <Label className={clsx("formTextLabel", styles.formLabel)}>
                          {t("site-navigation:label_column")}
                        </Label>
                        <Select
                          className="formText"
                          placeholder={t("site-navigation:placeholder_column")}
                          name="column"
                          id="submenu_column"
                          value={column}
                          onChange={handleFormChange}
                          options={[
                            { value: "", disabled: true, label: t("site-navigation:placeholder_column") },
                            ...(Array.isArray(columns)
                              && columns.map((each, index) => ({
                                value: index,
                                label: ` ${t("site-navigation:label_column")} ${index + 1} `,
                              }))),
                          ]}
                        />
                      </FormGroup>
                    </Col>
                  )
                }
              </Row>
            </div>
            <ModalBody>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label className={clsx("formTextLabel", styles.formLabel)}>
                      {t("common:label_type")}
                    </Label>
                    <Select
                      className="formText"
                      placeholder={t("site-navigation:placeholder_type")}
                      name="type"
                      id="submenu_item_type"
                      value={type}
                      disabled={type === navigationItemTypes.system}
                      onChange={handleFormChange}
                      options={[
                        { value: 0, disabled: true, label: t("site-navigation:placeholder_type") },
                        ...(
                          Array.isArray(menuTypes)
                          && menuTypes.map((each) => {
                            if (each === "System") {
                              return {
                                value: each,
                                label: each,
                                disabled: true,
                              };
                            }
                            return {
                              value: each,
                              label: each,
                            };
                          })
                        ),
                      ]}
                      innerRef={
                        register({
                          required: t("site-navigation:validation_type_required"),
                        })
                      }
                      invalid={!!(errors.type && errors.type.message)}
                    />
                    <FormFeedback>{errors.type && errors.type.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label className={clsx("formTextLabel", styles.formLabel)}>
                      {t("site-navigation:label_menu_item_name")}
                    </Label>
                    <Input
                      className="formText w-100"
                      disabled={type === navigationItemTypes.system}
                      placeholder={t("site-navigation:placeholder_menu_item_name")}
                      name="name"
                      value={name}
                      onChange={handleFormChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <div className={clsx("formTextLabel", "d-flex", styles.formLabel)}>
                      <span>
                        {t("site-navigation:label_menu_url")}
                      </span>
                      <FormGroup className={clsx("ml-auto p-o my-0", "d-flex")}>
                        <Label className="formTextLabel">{t("site-navigation:label_navigable")}</Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-3",
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="submenu_navigable"
                            title={t("site-navigation:placeholder_navigable")}
                            name="navigable"
                            checked={navigable}
                            onChange={() => handleChange("navigable", !navigable)}
                          />
                          <Label className="custom-control-label my-0" htmlFor="submenu_navigable" />
                        </div>
                      </FormGroup>
                    </div>
                    <Input className="formText w-100" disabled={!navigable} placeholder={t("site-navigation:placeholder_menu_url")} name="url" value={url} onChange={handleFormChange} />
                  </FormGroup>
                </Col>

                <Col xs="12 d-none">
                  <FormGroup>
                    <Label className={clsx("formTextLabel", styles.formLabel)}>
                      {t("site-navigation:label_columns")}
                    </Label>
                    <Select
                      className="formText"
                      placeholder={t("site-navigation:placeholder_display_limit")}
                      name="limit"
                      id="submenu_columns_display_limit"
                      value={limit}
                      onChange={handleFormChange}
                      options={[
                        { value: 0, disabled: true, label: t("site-navigation:placeholder_display_limit") },
                        ...(
                          [1, 2, 3, 4, 5,
                            6, 7, 8, 9, 10,
                            11, 12,
                            13, 14, 15, 16,
                            17, 18, 19, 20,
                          ].map((each) => ({
                            value: `${each}`,
                            label: `${each}`,
                          }))
                        ),
                      ]}
                    />
                  </FormGroup>
                </Col>
                {
                  type && type !== "" && (
                    <>
                      <Col xs="12">
                        <FormGroup>
                          {(type !== navigationItemTypes.custom
                            && type !== navigationItemTypes.system)
                            && (
                              <Label className={clsx("formTextLabel", styles.formLabel)}>
                                {`${t("site-navigation:label_add")} ${type}`}
                              </Label>
                            )}
                          {
                            type === navigationItemTypes.category
                            && <CategorySelector value={Array.isArray(menus) ? menus.map((each) => ({ ...each, id: each.dataId || each.id })) : []} type={type} onChange={(o) => handleChange("menus", o)} />
                          }
                          {
                            type === navigationItemTypes.product
                            && <ProductSelector value={Array.isArray(menus) ? menus.map((each) => ({ ...each, id: each.dataId || each.id })) : []} type={type} onChange={(o) => handleChange("menus", o)} />
                          }
                          {
                            type === navigationItemTypes.brand
                            && <BrandSelectorContainer value={Array.isArray(menus) ? menus.map((each) => ({ ...each, id: each.dataId || each.id })) : []} type={type} onChange={(o) => handleChange("menus", o)} />
                          }
                          {
                            type === navigationItemTypes.custom
                            && <CustomMenuItem value={Array.isArray(menus) ? menus.map((each) => ({ ...each, id: each.dataId || each.id })) : []} type={type} onChange={(o) => handleChange("menus", o)} />
                          }
                          {
                            type === navigationItemTypes.system
                            && <CustomMenuItem value={Array.isArray(menus) ? menus.map((each) => ({ ...each, id: each.dataId || each.id })) : []} type={type} onChange={(o) => handleChange("menus", o)} />
                          }
                          {
                            (type === navigationItemTypes.product
                              || type === navigationItemTypes.category
                              || type === navigationItemTypes.brand)
                            && Array.isArray(menus) && (
                              <div className="pt-3">
                                <SelectedItemListView
                                  data={menus}
                                  actionHandler={removeItem}
                                  onChange={(o) => handleChange("menus", o)}
                                  //  editEnabled={(isWebFooter || isWebResponsiveHeader)}
                                  editEnabled
                                />
                              </div>
                            )
                          }
                        </FormGroup>
                      </Col>
                    </>
                  )
                }
              </Row>
            </ModalBody>
            <ModalFooter className="border-top-0">
              <Button type="button" onClick={(o) => formCancel(true, o)} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                {t("common:button_cancel")}
              </Button>
              <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                {
                  onEdit ? t("common:button_update") : t("common:button_save")
                }
              </Button>
            </ModalFooter>
          </fieldset>
        </Form>
      </Modal>
    </div>
  );
};

MegaMenuItemCreator.defaultProps = {
  menus: null,
  column: null,
  parentMenu: null,
};

MegaMenuItemCreator.propTypes = {
  open: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  limit: PropTypes.string.isRequired,
  column: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menus: PropTypes.arrayOf(PropTypes.any),
  parentMenu: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  parentMenus: PropTypes.arrayOf(PropTypes.any).isRequired,
  menuTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  onEdit: PropTypes.bool.isRequired,
  navigable: PropTypes.bool.isRequired,
  isWebResponsiveHeader: PropTypes.bool.isRequired,
  isWebFooter: PropTypes.bool.isRequired,
  /** form methods */
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  formCancel: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  handleMenuChange: PropTypes.func.isRequired,
};

export default MegaMenuItemCreator;
