/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import ImageSelection from "../components/ImageSelection";
import imageUtils from "../../../common/utils/imageUtils";


const ImageSelectionContainer = ({ images: media, handleChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [images, setProductImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setProductImages(media);
  }, [media]);

  const handleUploadImage = (newMedia) => {
    const newImages = images;
    newImages.push(newMedia);
    setProductImages(newImages);
    handleChange("images", newImages);
  };

  const handleRemoveImage = (item) => {
    const filteredImages = images.filter((image, index) => index !== item);
    setProductImages(filteredImages);
    handleChange("images", filteredImages);
  };
  /**
 * This method is used to handle change file
 *
 * @param {*} event
 */
  const handleChangeFile = async (event) => {
    const file = event.target.files[0];
    if (file.size > 5242880) {
      alert("Image size must under 5mb!");
    } else {
      const url = await imageUtils.readURL(file);
      setImageUrl(url);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setImageUrl("");
    setModalOpen(false);
  };

  return (

    <ImageSelection
      images={images}
      handleChangeFile={handleChangeFile}
      modalOpen={modalOpen}
      imageUrl={imageUrl}
      setModalOpen={setModalOpen}
      handleUpload={handleUploadImage}
      isUploading={isUploading}
      setIsUploading={setIsUploading}
      handleCloseModal={handleCloseModal}
      handleRemoveImage={handleRemoveImage}
    />
  );
};

ImageSelectionContainer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
};

ImageSelectionContainer.defaultProps = {
  images: [],
};

export default ImageSelectionContainer;
