/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Deepak D
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/ShippingMethod.module.css";
import CustomizationTable from "../../../common/components/Table";
import Label from "../../../common/core/Label";
import Alert from "../../../common/core/Alert";

const ShippingMethod = ({
  shippingMethodConfigurations,
  onHandleEditTaxRegion,
  onStatusChange,
  warning,
}) => {

  const { t } = useTranslation(["shipment", "common"]);

  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(e) => onStatusChange(!row.active, row.id, row.shippingMethod, e)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );

    return newStatus;
  };

  const customRows = [
    {
      label: "Name",
      value: "",
      path: "name",
      transform: "",
    },
    {
      label: "Shipping Method",
      value: "",
      path: "shippingMethod",
      transform: "",
    },
    {
      label: "Price Strategy",
      value: "",
      path: "priceStrategy",
      transform: "",
    },
    {
      label: "Amount",
      value: "",
      path: "amount",
      transform: "",
    },
    {
      label: "Status",
      path: "render",
      render: getStatus,
    },
  ];

  return (
    <>
      {
        warning && (
          <Alert color="warning">
            <p>{t("shipment:shipment_method_warning_message")}</p>
          </Alert>
        )

      }
      <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
        <Col xs="8">
          <span className="pageText">{t("shipment:label_shipment_heading")}</span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <div className="custom-table">
            <CustomizationTable
              customRows={customRows}
              customizationData={shippingMethodConfigurations}
              isEditEnable
              isStatusEnable={false}
              isDeleteEnable={false}
              onEditData={onHandleEditTaxRegion}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

ShippingMethod.propTypes = {
  shippingMethodConfigurations: PropTypes.arrayOf(PropTypes.any).isRequired,
  onHandleEditTaxRegion: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired
};

export default ShippingMethod;
