/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CustomizationTable from "../../../common/components/Table";

import Row from "../../../common/core/Row";
import leftArrowLong from "../../../common/assets/images/svg/left-arrow-long.svg";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";

import styles from "../css/JobConfiguration.module.css";

const customRows = [
  {
    label: "Job Id",
    value: "",
    path: "jobId",
    transform: "",
  },
  {
    label: "Step Id",
    value: "",
    path: "stepId",
    transform: "",
  },
  {
    label: "Status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Type",
    value: "",
    path: "type",
    transform: "",
  },
  {
    label: "Chunk Count",
    value: "",
    path: "chunkCount",
    transform: "",
    default: 0,
  },
  {
    label: "Filename",
    value: "",
    path: "fileName",
    transform: "",
    default: 0,
  },
  {
    label: "Store Id",
    value: "",
    path: "storeId",
    transform: "",
  },
  {
    label: "Exception Reason",
    value: "",
    path: "render",
    transform: "",
    default: 0,
    className: "truncate",
    render: (row) => {
      return (
        <p className={clsx(styles.textTruncate)} title={row?.exceptionReason}>
          {row?.exceptionReason}
        </p>
      );
    },
  },
];

const JobStepExAuditDetails = ({
  jobStepExDetailsData,
  canModify,
  onHandleBackStepEx,
  rowDataStep,
  formatDate,
}) => {
  const { t } = useTranslation(["common", "job-configuration"]);

  return (
    <Row className={clsx(styles.block1, "common-page-card ")}>
      <Col xs="12">
        {canModify ? (
          <Row className="pb-4">
            <Col xs="12">
              <span className={clsx(styles.leftArrow, "mr-4")}>
                <img
                  src={leftArrowLong}
                  alt="click left"
                  width="30"
                  height="auto"
                  onClick={() => onHandleBackStepEx(null)}
                />
              </span>
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("job-configuration:span_job_step_exec_details")}
              </span>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_name")}</b>
              </Label>
              <p>{rowDataStep.name}</p>
            </FormGroup>
          </Col>

          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_id")}</b>
              </Label>
              <p>{rowDataStep.jobId}</p>
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_step_id")}</b>
              </Label>
              <p>{rowDataStep.id}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_status")}</b>
              </Label>
              <p>{rowDataStep.status}</p>
            </FormGroup>
          </Col>
          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_start_date")}</b>
              </Label>
              <p>{formatDate(rowDataStep.startDate)}</p>
            </FormGroup>
          </Col>

          <Col xs="4">
            <FormGroup>
              <Label className="formTextLabel">
                <b>{t("job-configuration:label_job_history_end_date")}</b>
              </Label>
              <p>{formatDate(rowDataStep.endDate)}</p>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="12">
            <Label className={styles.sectionHead}>
              Step Execution Audit List
            </Label>
            <div className="custom-table">
              <CustomizationTable
                customRows={customRows}
                customizationData={jobStepExDetailsData}
                isStatusEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
                // onClickRow={onClickRowStep}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

JobStepExAuditDetails.propTypes = {
  onHandleBack: PropTypes.func.isRequired,
  jobAuditDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  canModify: PropTypes.bool.isRequired,
  jobStepExDetailsConfigData: PropTypes.arrayOf.isRequired,
};

export default JobStepExAuditDetails;
