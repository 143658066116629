/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */

const ConditionalField = ({
  conditionalFields, stateData, inputName, renderFilter,
}) => (
    conditionalFields && conditionalFields.length > 0 ? (
      conditionalFields.map((field) => {
        if (field && field.attributes && field.attributes.dependsOn === stateData[inputName]) {
          return renderFilter(field);
        }
        return null;
      })
    ) : ""
  );

export default ConditionalField;
