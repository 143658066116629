/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * User Management
 *
 * @author Naseef O
 *
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/UserManagement.module.css";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import InputGroup from "../../../common/core/InputGroup";
import Input from "../../../common/core/Input";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import Select from "../../../common/components/Select";

const customRows = [
  {
    label: "User",
    value: "",
    path: "username",
    transform: "",
  },
  {
    label: "Email",
    value: "",
    path: "email",
    transform: "",
  },
  {
    label: "Role",
    value: "",
    path: "roles",
    transform: "",
  },
];

const UserManagement = (props) => {
  const {
    userList, hasMoreData, page, getPageData, roles, onHandleRolesChange, active, onHandleActiveChange, roleList,
  } = props;
  
  const hidePagination = page === 0 && !(userList && Array.isArray(userList) && userList.length > 0);

  return (
    <>
      <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
        <Col xs="8">
          <span className="pageText">Manage Users</span>
        </Col>
        <Col xs="4">
          <Button className={clsx(styles.buttonNewUser, "text-uppercase bg-color-tertiary-main")}>New User</Button>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="12" md="4">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">Role</Label>
                <Select
                  className="formTextLabel"
                  name="status"
                  value={roles}
                  onChange={(e) => onHandleRolesChange(e)}
                  options={
                    Array.isArray(roleList)
                    && roleList.map(({ code: value, label }) => ({ value, label }))
                  }
                />
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label className="formTextLabel" for="exampleEmail">Status</Label>
                <Select
                  className="formTextLabel"
                  name="status"
                  value={active}
                  onChange={(e) => onHandleActiveChange(e)}
                  options={[
                    { label: "Active", value: true },
                    { label: "Inactive", value: false },
                  ]}

                />
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="p-0">
              <FormGroup>
                <Label className="formTextLabel  pt-3" for="exampleEmail" />
                <div className={styles.areaActionRight}>
                  <div>
                    <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                      <Input placeholder="Search User" />
                      <InputGroupAddon
                        addonType="append"
                        className="commonPointer bg-color-tertiary-main"
                      >
                        <img
                          src={SearchIcon}
                          alt="Search"
                          className={styles.icon}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                    {" "}
                  </div>
                </div>
                <FormFeedback>You will not be able to see this</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <div className="custom-table">
            <CustomizationTable
              customRows={customRows}
              customizationData={userList}
              isStatusEnable
              isDeleteEnable
              isEditEnable
            />
          </div>
          {!hidePagination
            ? (
              <div
                className={clsx(
                  styles.pagination,
                  "p-3",
                  "d-flex",
                  "justify-content-end",
                )}
              >
                <div
                  className={clsx(
                    styles.pagination,
                    "p-3",
                    "d-flex",
                    "justify-content-end",
                  )}
                >
                  <div className="d-flex">
                    <span
                      disabled={page <= 0}
                      className={clsx(styles.item, styles.arrowLeft)}
                      tabIndex={0}
                      role="button"
                      onKeyPress={() => { }}
                      onClick={() => !page <= 0 && getPageData(page - 1)}
                    >
                      <span />
                    </span>
                    <span className={styles.item} />
                    <span
                      disabled={!hasMoreData}
                      className={clsx(styles.item, styles.arrowRight)}
                      tabIndex={0}
                      role="button"
                      onKeyPress={() => { }}
                      onClick={() => hasMoreData && getPageData(page + 1)}
                    >
                      <span />
                    </span>
                  </div>
                </div>
              </div>
            )
            : ""}
        </Col>
      </Row>
    </>
  );
};
UserManagement.propTypes = {
  userList: PropTypes.objectOf(PropTypes.any).isRequired,
  hasMoreData: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  getPageData: PropTypes.func.isRequired,
  onHandleRolesChange: PropTypes.func.isRequired,
  onHandleActiveChange: PropTypes.func.isRequired,
  roleList: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default UserManagement;
