/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

import React, { useState, useEffect } from "react";
import OrderDetails from "../components/OrderDetails";
import { getOrderDetails } from "../../../api/orderServices";

const OrderDetailsContainer = ({ onHandleBack, selectedOrderId }) => {
  const [order, setOrder] = useState({});
  useEffect(() => {
    getOrderDetails(selectedOrderId).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        setOrder(data);
      }
    });
  }, [selectedOrderId]);
  return <OrderDetails order={order} onHandleBack={onHandleBack} />;
};

export default OrderDetailsContainer;
