/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * DynamicContentInfo
 *
 * @author Naseef O
 *
 */

import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/PageCustomization.module.css";
import info from "../../../common/assets/images/svg/info.svg";

const DynamicContentInfo = () => {
  const { t } = useTranslation(["common", "page-customization"]);
  return (
    <>
      <Row className={clsx(styles.widgetsTable, "m-0 ")}>
        <Col xs="12">
          <span className={styles.contentInfo}>
            <img src={info} alt="info" className="pr-2 float-left" />
            <span>
              {t("page-customization:contentInfo")}
            </span>
          </span>
        </Col>
      </Row>
    </>
  );
};


export default DynamicContentInfo;
