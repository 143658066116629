/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Hima-Mohan
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import { Collapse } from "reactstrap";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";

/** ===== IMAGE ICONS =========== */
import polygonIcon from "../../../common/assets/images/svg/polygon.svg";

/** ======== MODULE STYLES ========== */
import styles from "../css/PageMetaTags.module.css";
import MetaTitleSelector from "../containers/MetaTitleSelectorContainer";
import MetaDescriptionSelector from "../containers/MetaDescriptionSelectorContainer";
import MetaKeywordSelector from "../containers/MetaKeywordSelectorContainer";
import validateForm from "../../../common/utils/validateForm";


const PageMetaTags = ({
  activeTab, toggle, isOpen, handleChange, productmetaTitle, productmetaKeyword, productmetaDescription, categorymetaTitle, categorymetaKeyword, categorymetaDescription, onSubmit, onSubmitting, onCancel, 
}) => {
  const methods = validateForm();

  const {
    handleSubmit,
  } = methods;

  return (
    <>
      <Row>
        <Col xs="12">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={onSubmitting}>
              <Row className="pageHeader common-page-card">
                <Col xs="12" md="7" className="text-truncate">
                  <span className="pageText">SEO Settings</span>
                </Col>
                <Col xs="12" md="5" className="d-flex justify-content-end">
                  <Button type="button" onClick={onCancel} className={clsx(styles.borderRadius2, styles.btn1, styles.secondaryColor, "text-truncate", "mr-2")}>
                    CANCEL
                  </Button>
                  <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div>
                <Row className="common-page-card">
                  <Col xs="12" className={clsx("position-relative", "mb-4")}>
                    <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>Page MetaTags</span>
                  </Col>
                  <Col>
                    <div className={styles.pageSelectionText} id="headingOne" style={{ backgroundColor: " #f9f9f9", paddingTop: "10px", paddingBottom: "10px" }} onClick={() => { toggle("product"); }}>

                      <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold", "ml-3")} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Product</span>
                      <img src={polygonIcon} className="commonPointer  ml-3" alt="drag indiactor icon" width="10px" height="10px" />
                    </div>
                    <div>
                      {activeTab === "product" ? (
                        <Collapse isOpen={isOpen}>
                          <Row style={{ backgroundColor: " #edf0f5" }} className="common-page-card">
                            <Col xs="12" md="10" className={clsx("ml-3")}>
                              <FormGroup>
                                <Label className="formTextLabel">Meta Title </Label>
                                <MetaTitleSelector
                                  value={productmetaTitle}
                                  name="productmetaTitle"
                                  onChange={(o) => handleChange("productmetaTitle", o)}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="10" className={clsx("ml-3")}>
                              <FormGroup>
                                <Label className="formTextLabel">Meta keyword </Label>
                                <MetaKeywordSelector
                                  value={productmetaKeyword}
                                  name="productmetaKeyword"
                                  onChange={(o) => handleChange("productmetaKeyword", o)}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="10" className={clsx("ml-3")}>
                              <FormGroup>
                                <Label className="formTextLabel">Meta Description </Label>
                                <MetaDescriptionSelector
                                  value={productmetaDescription}
                                  name="productmetaDescription"
                                  onChange={(o) => handleChange("productmetaDescription", o)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Collapse>
                      ) : ""}
                    </div>
                  </Col>
                  <Col xs="12">
                    <div className={styles.pageSelectionText} id="headingOne" style={{ backgroundColor: " #f9f9f9", paddingTop: "10px", paddingBottom: "10px" }} onClick={() => { toggle("category"); }}>
                      <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold", "ml-3")} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Category</span>
                      <img src={polygonIcon} className="commonPointer  ml-3" alt="drag indiactor icon" width="10px" height="10px" />
                    </div>
                    <div>
                      {activeTab === "category" ? (
                        <Collapse isOpen={isOpen}>
                          <Row style={{ backgroundColor: " #edf0f5" }} className="common-page-card">
                            <Col xs="12" md="10" className={clsx("ml-3")}>
                              <FormGroup>
                                <Label className="formTextLabel">Meta Title </Label>
                                <MetaTitleSelector
                                  value={categorymetaTitle}
                                  name="categorymetaTitle"
                                  onChange={(o) => handleChange("categorymetaTitle", o)}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="10" className={clsx("ml-3")}>
                              <FormGroup>
                                <Label className="formTextLabel">Meta keyword </Label>
                                <MetaKeywordSelector
                                  value={categorymetaKeyword}
                                  name="categorymetaKeyword"
                                  onChange={(o) => handleChange("categorymetaKeyword", o)}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="12" md="10" className={clsx("ml-3")}>
                              <FormGroup>
                                <Label className="formTextLabel">Meta Description </Label>
                                <MetaDescriptionSelector
                                  value={categorymetaDescription}
                                  name="categorymetaDescription"
                                  onChange={(o) => handleChange("categorymetaDescription", o)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Collapse>
                      ) : ""}
                    </div>
                    {/* </Row> */}
                    {/* </div> */}
                  </Col>
                  {/* <Col xs="12">
                    <div style={{ backgroundColor: " #f9f9f9", paddingTop: "10px", paddingBottom: "10px" }}>
                      <Row className="fontsizeMediumBold textColor ">
                      <Col xs="12" md="7" className=" pt-1 pb-1 ml-3" onClick={() => { toggle("subcategory"); }}>
                          <span className="pageText">Sub Category</span>
                          <img src={polygonIcon} className="commonPointer  ml-3" alt="drag indiactor icon" width="10px" height="10px" />
                        </Col>
                      {activeTab === "subcategory" ? (
                          <Collapse isOpen={isOpen} />
                        ) : ""}
                    </Row>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  );
};
PageMetaTags.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  productmetaTitle: PropTypes.string.isRequired,
  productmetaKeyword: PropTypes.string.isRequired,
  productmetaDescription: PropTypes.string.isRequired,
  categorymetaTitle: PropTypes.string.isRequired,
  categorymetaKeyword: PropTypes.string.isRequired,
  categorymetaDescription: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PageMetaTags;
