/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Anokh J Ajai
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FulfilmentTypeSelector from "../components/FulfilmentTypeSelector";
import { getFulfillmentMethodConfigurations } from "../../../api/fulfillmentMethodService";


const FulfilmentTypeSelectorConatiner = (props) => {
  const {
    onChange,
    name,
    value,
    options,
  } = props;
  /** local states */
  const [fulfilmentTypes, setFulfilmentTypes] = useState(options || []);

  useEffect(() => {
    setFulfilmentTypes((prevState) => (prevState.length < 1 ? options : prevState));
  }, [options]);

  useEffect(() => {
    getFulfillmentMethodConfigurations().then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        const data = response.data.map((each) => ({ id: each.code, name: each.name }));
        setFulfilmentTypes(data);
      }
    });
  }, []);

  /**
     * This method is used to trigger onChange
     * @param {Event} event
     */
  const localOnChange = (event) => {
    const { name: localName, value: localValue } = event.target;
    onChange(localValue, localName);
  };


  return (
    <FulfilmentTypeSelector
      name={name}
      selectedFulfilmentTypes={value}
      fulfilmentTypeList={fulfilmentTypes}
      onChange={localOnChange}
    />
  );
};
FulfilmentTypeSelectorConatiner.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,

};
export default FulfilmentTypeSelectorConatiner;
