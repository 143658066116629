// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Angela\n */\n\n.PaymentClasses_borderRadius2__2Y78V {\n  border-radius: 0.125rem !important;\n}\n\n/** =========== ACTION AREA STYLES ============== **/\n.PaymentClasses_areaActionRight__1UjKc {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.PaymentClasses_secondaryColor__1m3CK,\n.PaymentClasses_secondaryColor__1m3CK:focus,\n.PaymentClasses_secondaryColor__1m3CK:active {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.PaymentClasses_tableWrapper__3GZFn{\n  min-height: calc(100vh - 365px);\n}", ""]);
// Exports
exports.locals = {
	"borderRadius2": "PaymentClasses_borderRadius2__2Y78V",
	"areaActionRight": "PaymentClasses_areaActionRight__1UjKc",
	"secondaryColor": "PaymentClasses_secondaryColor__1m3CK",
	"tableWrapper": "PaymentClasses_tableWrapper__3GZFn"
};
module.exports = exports;
