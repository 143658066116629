/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import styles from "../css/DocumentationGuide.module.css";

const AboutBlueCommerce = () => (
  <>
    <Row>
      <Col xs="12">
        <Row className="pageHeader  common-page-card">
          <Col xs="12" md="6" className="text-truncate">
            <span className={styles.pageHeader}>About Blue Commerce</span>
          </Col>
        </Row>
        <Row className="pageHeader  common-page-card">
          <div className={styles.pageText}>
            <div className={styles.pageText2}>
              Hello, and welcome to Blue Commerce. This application helps you to
              provide your end customer with a unique user experience in
              shopping. The customer experience is fully configurable through a
              fully loaded administration side of the application. The document
              explains how to use the blue commerce application and customize
              your user experience. As a merchandiser, you will be able to
              configure all the functions so that you can keep the brand
              revenues up and intact, and give a seamless experience for your
              shoppers.
            </div>
          </div>
        </Row>
        <Row className="pageHeader common-page-card">
          <span className="py-8">
            <Col xs="12" md="6" className="text-truncate">
              <span className={styles.pageHeader}>Getting Started</span>
            </Col>
            <Row>
              <Col className={styles.pageText}>
                <div className={styles.pageText}>
                  The Blue Commerce application can be broadly divided into two
                  - (1) Retail Admin and (2) Storefront. The storefront is the
                  online shopping site that is given to the user. The storefront
                  functions include the browsing journey - search, product
                  listing, product details, brand listing, brand landing pages,
                  and the checkout journey - Add to cart, payment and order
                  confirmation. The user can also add products to their
                  wishlist, redeem loyalty points and more. The retail admin
                  access is for merchandiser users to configure the storefront.
                  Creating different layouts for different pages, Content
                  Management, Catalogue, Pricebook, Inventory management,
                  Ordermanagement, and other merchandising and administration
                  functions are made possible for the retail admin user. If you
                  are a merchandiser or a retail admin user, you must first get
                  appropriate access to use the system. Contact your system
                  administrator for the same. The document details on how to use
                  the Blue Commerce system from a retail administration user
                  perspective.
                </div>
              </Col>
            </Row>
          </span>
        </Row>
        <Row className="pageHeader  common-page-card">
          <Col xs="12" md="6" className="text-truncate">
            <span className={styles.pageHeader}>
              The Preview and Push to live buttons
            </span>
          </Col>
          <Row>
            <Col className={styles.pageText}>
              <div className={styles.pageText}>
                In the application, if you make a change from the retail admin
                pages, it doesn't reflect directly on the storefront. Instead,
                there is a Preview phase, first for verifying the changes and
                ensuring that it has worked correctly. Preview The preview
                option helps you preview the changed layout setting. Once you
                verify the changes using the Preview button, then you can
                release those changes to the live storefront. Push To Live The
                changes are pushed to the live site upon clicking this button.
                To make any change to the storefront - edit the respective
                screen through retail admin, view the Preview, and once you have
                verified it, Push to live.
              </div>
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  </>
);

export default AboutBlueCommerce;
