// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.\n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n * \n */\n .CouponForm_noLabelFormGroup__10k4n {\n  margin-top: 1.75rem;\n  padding: 0.375rem 0.75rem;\n} ", ""]);
// Exports
exports.locals = {
	"noLabelFormGroup": "CouponForm_noLabelFormGroup__10k4n"
};
module.exports = exports;
