/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import ButtonGroup from "../../../common/core/ButtonGroup";
/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= SVG IMAGE ICON ========= */
import errorIcon from "../../../common/assets/images/svg/error.svg";
import ImageIcon from "../../../common/assets/images/svg/image.svg";

/** ========= MODULE STYLES ========= */
import styles from "../css/CustomMenuItemCreator.module.css";
import FormFeedback from "../../../common/core/FormFeedback";

const CustomMenuItemCardForm = (props) => {
  const {
    name, description, button, url, image,
    handleFormChange, handleImageSelection, onSave, onClear, error,
  } = props;

  /** internationalization using i18n from common and site-navigation  */
  const { t } = useTranslation(["common", "site-navigation"]);

  return (
    <Form>
      <fieldset disabled={false}>
        <FormGroup>
          <Label className="formTextLabel">
            {t("site-navigation:label_title")}
          </Label>
          <Input
            className="formText w-100"
            placeholder={t("site-navigation:placeholder_title")}
            invalid={error.name}
            name="name"
            value={name}
            onChange={handleFormChange}
          />
          <FormFeedback>
            {error.name}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="formTextLabel">
            {t("site-navigation:label_description")}
          </Label>
          <Input
            type="textarea"
            className="formText w-100"
            placeholder={t("site-navigation:placeholder_description")}
            name="description"
            value={description}
            onChange={handleFormChange}
          />
        </FormGroup>
        <FormGroup className="d-none">
          <Label className="formTextLabel">
            {t("site-navigation:label_promotion_button_name")}
          </Label>
          <Input className="formText w-100" placeholder={t("site-navigation:placeholder_promotion_button_name")} name="button" value={button} onChange={handleFormChange} />
        </FormGroup>
        <FormGroup>
          <Label className="formTextLabel">
            {t("site-navigation:label_url")}
          </Label>
          <Input className="formText w-100" placeholder={t("site-navigation:placeholder_url")} name="url" value={url} onChange={handleFormChange} />
        </FormGroup>
        <FormGroup>
          <Label className={clsx(styles.imageUpload, "commonPointer w-100")} htmlFor="promotion-image-upload">
            <input onChange={handleImageSelection} type="file" className="d-none" id="promotion-image-upload" />
            {
              image ? (
                <div>
                  <img src={image} alt="" className="img-thumbnail" />
                </div>
              )
                : (
                  <div className="px-5 py-4">
                    <div className="p-4">
                      <img
                        src={ImageIcon}
                        alt=""
                      />
                    </div>
                    <div>
                      <h5 className="mb-2">
                        Drag & Drop or
                        <span className={clsx(styles.primaryTextColor, "pl-1")}>Upload Image</span>
                      </h5>
                      <p className={styles.secondaryTextColor}>
                        Make sure that your files is no more than 500kb.
                      </p>
                    </div>
                  </div>
                )
            }
          </Label>
          {error.image && (
            <div className="common-form-error">
              <span className="d-flex align-items-center">
                <img src={errorIcon} alt="error" />
                <span className="pl-2">{error.image}</span>
              </span>
            </div>
          )}
        </FormGroup>
        <FormGroup className="text-center">
          <ButtonGroup>
            <Button type="button" onClick={onClear} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1", "btn-sm")}>
              {t("common:button_cancel")}
            </Button>
            <Button type="button" onClick={onSave} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size", "btn-sm")}>
              {t("common:button_save")}
            </Button>
          </ButtonGroup>
        </FormGroup>
      </fieldset>
    </Form>
  );
};

CustomMenuItemCardForm.defaultProps = {
  name: null,
  description: null,
  button: null,
  url: null,
  image: null,
};

CustomMenuItemCardForm.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  /** form methods */
  onSave: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleImageSelection: PropTypes.func.isRequired,
};

export default CustomMenuItemCardForm;
