/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

/** ========= TAG COMPONENTS ========= */

/** ========= SUB COMPONENT ========= */
import BrandCreatorView from "../components/BrandCreatorView";

/** ========= MODULE STYLES ========= */
/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
import { addBrand } from "../../../api/brandServices";

const BrandCreator = () => {
  const history = useHistory();

  /** local states */
  const [form, setForm] = useState({
    name: "",
    description: "",
    longDescription: "",
    startDate: new Date(),
    endDate: new Date(),
    status: true,
    childProducts: [],
    exclusiveBrand: false,
  });
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [parentBrand, setParentBrand] = useState("");
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method triggers when a subbrand selected or deselected
   * @param {Array} brands
   */
  const selectedBrandChange = (brands) => {
    setSelectedBrands(brands);
  };


  /**
   * This method is used to change parent brand
   * @param {Array} brands
   */
  const handleParentBrand = (brands) => {
    setParentBrand(brands);
  };

  /**
   * Clear button trigger
   */
  const cancelForm = () => {
    history.push("/merchandising/brand");
  };

  /**
   * This method is used to submit the form
   */
  const submitForm = async () => {
    const dataToServer = {
      ...form,
      startDate: moment(form.startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(form.endDate).format("YYYY-MM-DD HH:mm:ss"),
      parentBrand,
      childBrands: selectedBrands,
      type: parentBrand === "" ? "Root" : "Subbrand",
    };
    setMessage({ type: null, message: "" });
    setOnSubmitting(true);
    /** now passing object itself into service */
    const response = await addBrand(dataToServer);
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages[0] });
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/merchandising/brand");
        setMessage({ type: null, message: "" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      console.error(response.error);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  return (
    <BrandCreatorView
    // form field
      name={form.name}
      description={form.description}
      longDescription={form.longDescription}
      startDate={form.startDate}
      endDate={form.endDate}
      status={form.status}
      exclusiveBrand={form.exclusiveBrand}
      // for showing success message
      message={message}
      // disable text filed while form submitting
      onSubmitting={onSubmitting}
      // input filed handle change
      handleFormChange={handleFormChange}
      handleChange={handleChange}
      // form actions
      submitForm={submitForm}
      cancelForm={cancelForm}
      // brands
      selectedBrands={selectedBrands}
      // parent brand
      parentBrand={parentBrand}
      handleParentBrand={handleParentBrand}
      // sub brand
      selectedBrandChange={selectedBrandChange}
    />
  );
};

export default BrandCreator;
