/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */


import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import validateForm from "../../../common/utils/validateForm";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";

/** ========= SUB COMPONENT ========= */
import BrandForm from "./BrandForm";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= API SERVICE FUNCTIONS ========= */
/** ========= SVG IMAGE ICON ========= */

/** ========= MODULE STYLES ========= */
import styles from "../css/BrandCreatorView.module.css";

const BrandCreatorView = (props) => {
  const {
    submitting, name, longDescription, description, startDate, endDate, status, exclusiveBrand,
    handleFormChange, handleChange,
    formCancelHandler, formSubmitHandler,
  } = props;

  /** * internationalization using i18n from common and brand ** */
  const { t } = useTranslation(["common", "brand"]);

  /** * form validation ** */
  const validator = validateForm();
  const { handleSubmit } = validator;

  return (
    <Form onSubmit={handleSubmit(formSubmitHandler)}>
      <fieldset disabled={submitting}>

        {/** * ============= PAGE HEADER ================ ** */}
        <Row className="pageHeader common-page-card ">
          <Col xs="12">
            <Row className="m-0 ">
              <Col xs="6" md="8" lg="7" className="p-0">
                <span className="pageText">{t("brand:title_add_brand")}</span>
              </Col>
              <Col xs="6" md="4" lg="5" className="d-flex justify-content-end">
                <Button type="button" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")} onClick={formCancelHandler}>
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="primary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {t("common:button_save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/** * ============= PAGE BODY ================ ** */}
        <Row className={clsx(styles.blockWrapper, " common-page-card ")}>
          <Col xs="12">
            <BrandForm
              validator={validator}
              name={name}
              longDescription={longDescription}
              description={description}
              startDate={startDate}
              endDate={endDate}
              status={status}
              exclusiveBrand={exclusiveBrand}
              handleFormChange={handleFormChange}
              handleChange={handleChange}
            />
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
};

BrandCreatorView.propTypes = {
  submitting: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  exclusiveBrand: PropTypes.bool.isRequired,
  // functions
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  formCancelHandler: PropTypes.func.isRequired,
  formSubmitHandler: PropTypes.func.isRequired,
};

export default BrandCreatorView;
