/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */

import React from "react";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";

/** ===== IMAGE ICONS =========== */
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

/** ========= CUSTOM COMPONENTS ========= */
import TreeCategorySelector from "../../categoryManagement/containers/TreeCategorySelectorContainer";


/** ======== MODULE STYLES ========== */
import styles from "../css/CategorySelector.module.css";

const CategorySelector = (props) => {
  const {
    parentCategory,
    handleParentCategory,
    dropdownOpen,
    toggle,
    categoryName,
    clearCategories,
    multiple,
    options,
  } = props;

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={styles.CategorySelector}
      direction="down"
      inNavbar
    >
      <DropdownToggle caret>
        <span className={styles.imgWrapper}>
          {" "}
          <span className="text-truncate">
            {categoryName || "Root category"}
          </span>
          <span className="ml-auto">
            <span />
            {
              categoryName && (
                <span role="button" onKeyDown={() => { }} tabIndex={0} className={styles.clear} onClick={clearCategories}>
                  <img src={ClearIcon} alt="" />
                </span>
              )
            }
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className={styles.dropdownWrapper}>
        <TreeCategorySelector
          categories={parentCategory}
          onChange={handleParentCategory}
          options={options}
          fullObject
          multiple={multiple}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

CategorySelector.defaultProps = {
  categoryName: null,
  parentCategory: null,
  multiple: false,
};

CategorySelector.propTypes = {
  categoryName: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  clearCategories: PropTypes.func.isRequired,
  parentCategory: PropTypes.string,
  handleParentCategory: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CategorySelector;
