/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Edison
 */


import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Label from "../../../common/core/Label";

/** ===== COMPONENTS =========== */
import RobotsTxtManagement from "../components/RobotsTxtManagement";

/** ========= CUSTOM COMPONENTS ========= */
import AlertModal from "../components/AlertModal";

/** ========= API SERVICE FUNCTIONS ========= */
import { getSeoInfos, uploadRobotsTxtFile } from "../../../api/SeoSettingsService";

const RobotsTxtManagementContainer = () => {

  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [robotsTxtFileUrl, setRobotsTxtFileUrl] = useState(null);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    getSeoInfos().then((response) => {
      if (response && response.success && response.data) {
        const { robotsTxtUrl } = response.data;
        if (robotsTxtUrl) {
          setRobotsTxtFileUrl(robotsTxtUrl);
        }
      }
    });
  }, []);


  const handleFileUpload = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const uploadFile = async (event) => {
    setLoading(true);
    const fileData = event?.target?.files[0];
    if (fileData) {
      await uploadRobotsTxtFile(fileData).then((robotsTxtUrl) => {
        setLoading(false);
        if (robotsTxtUrl) {
          setRobotsTxtFileUrl(robotsTxtUrl);
          setModalAttributes({
            isOpen: true,
            content: "Robots txt file uploaded successfully",
            header: "Success",
          });
        } else {
          setModalAttributes({
            isOpen: true,
            content: "Something went wrong. Please try again",
            header: "Error",
          });
        }
      })
    }
  };

  const [modalAttibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });

  return (
    <>
      <RobotsTxtManagement
        uploadFile={uploadFile}
        handleFileUpload={handleFileUpload}
        hiddenFileInput={hiddenFileInput}
        loading={loading}
        robotsTxtFileUrl={robotsTxtFileUrl}
        modalAttibutes={modalAttibutes}
        setModalAttributes={setModalAttributes}
      />
      <AlertModal
        isOpen={modalAttibutes.isOpen}
        content={modalAttibutes.content}
        header={modalAttibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};
export default RobotsTxtManagementContainer;
