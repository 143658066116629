// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta\n * (\"Confidential Information\"). You shall not disclose such Confidential\n * Information and shall use it only in accordance with the terms of the\n * contract agreement you entered into with Mozanta.\n *\n * Login.module.css\n *\n * @author Naseef\n *\n */\n\n.Login_loginWrapper1__2Gw5l{\n    min-height: calc(100vh - 205px);\n    display: flex;\n}", ""]);
// Exports
exports.locals = {
	"loginWrapper1": "Login_loginWrapper1__2Gw5l"
};
module.exports = exports;
