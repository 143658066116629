/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */
import clsx from "clsx";
import React, { useState } from "react";
import DatePicker from "react-datetime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import CustomizationTable from "../../../common/components/Table";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Badge from "../../../common/core/Badge";
import Select from "../../../common/components/Select";
import StatusBadge from "../common/StatusBadge";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import styles from "../css/JobConfiguration.module.css";
import constants from "../../../common/utils/constants";

import SearchIcon from "../../../common/assets/images/svg/search.svg";
import ExcelIcon from "../../../common/assets/images/svg/excel.svg";
import TextDownloadIcon from "../../../common/assets/images/svg/text-download.svg";
import AddIcon from "../../../common/assets/images/svg/add-24px.svg";
import MinusIcon from "../../../common/assets/images/svg/minus-24px.svg";

const customRows = [
  {
    label: "Id",
    value: "",
    path: "id",
    transform: "",
  },
  {
    label: "Name",
    value: "",
    path: "name",
    transform: "",
    //   type: "date"
  },
  {
    label: "Status",
    value: "",
    path: "status",
    transform: "",
  },
  {
    label: "Start Date",
    value: "",
    path: "startDate",
    transform: "",
    type: "date",
  },
  {
    label: "End Date",
    value: "",
    path: "endDate",
    transform: "",
    type: "date",
  },
];

const JobHistoryConfiguration = (props) => {
  const {
    jobHistoryData,
    onJobListing,
    jobId,
    jobHistoryRunTimes,
    jobHistoryStatuses,
    jobNameList,
    onChange,
    setFromDate,
    setToDate,
    search,
    startOfResult,
    endOfResult,
    paginationNext,
    onClickRow,
    paginationPrev,
  } = props;

  const { STATUS_INPROGRESS } = constants;

  /** internationalization using i18n from common and job  */
  const { t } = useTranslation(["common", "job-configuration"]);

  const [dropdownRoleOpen, setDropdownRoleOpen] = useState(false);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [selectedindex, setSelectedindex] = useState(null);
  const [initialStage, setInitialStage] = useState(false);
  const [onConfirmationModal, setConfirmationModal] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [toggleOpen, setToggleOpen] = useState(true);
  const [jobRun, setJobRun] = useState(false);
  const [jobEnable, setJobEnable] = useState(true);

  /**
   *This method is used to toggle role dropdown
   *
   */
  const toggleDropdownRole = () => {
    setDropdownRoleOpen(!dropdownRoleOpen);
  };

  /**
   *This method is used to toggle status dropdown
   *
   */
  const toggleDropdownStatus = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  /**
   *This method is used to toggle arrow in Job Listing Table
   *
   */
  const toggleArrow = (index) => {
    setInitialStage(false);
    if (selectedindex === index) {
      setSelectedindex(null);
    } else {
      setSelectedindex(index);
    }
  };

  /**
   *This method is used to handle Logs column
   *
   */
  const handleLogs = (status) => {
    if (status !== STATUS_INPROGRESS) {
      return true;
    }
  };

  /**
   *This method is used to handle delete action modal
   *
   */
  const handleDelete = () => {
    if (jobRun === false) {
      setConfirmationModal(true);
      setIsOpen(true);
      setToggleOpen(true);
    }
  };
  const handleStart = () => {
    if (jobEnable === true) {
      setJobRun(!jobRun);
    }
  };
  const handleEnable = () => {
    if (jobRun === false) {
      setJobEnable(!jobEnable);
    }
  };

  /**
   *This method is used to handle delete action modal
   *
   */
  const handleToggleModal = () => {
    setIsOpen(false);
    setToggleOpen(false);
  };

  /**
   *This method is used to handle run and stop
   *
   */

  return (
    <>
      <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
        <Col xs="12">
          <Row>
            <Col xs="6">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("job-configuration:sub_title_job_history")}
              </span>
            </Col>
            {/* <Col xs="6">
              <div className={clsx(styles.jobButtons)}>
                <span>
                  <Button
                    className={clsx(
                      styles.buttonStopJob,
                      "text-uppercase bg-color-tertiary-main"
                    )}
                    onClick={() => {
                      handleStart();
                    }}
                  >
                    {jobRun
                      ? t("job-configuration:span_stop_job")
                      : t("job-configuration:span_run_job")}
                  </Button>
                </span>
                <span>
                  <Button
                    className={clsx(
                      styles.buttonDisable,
                      "text-uppercase bg-color-tertiary-main"
                    )}
                    onClick={() => {
                      handleEnable();
                    }}
                  >
                    {jobEnable
                      ? t("job-configuration:span_disable")
                      : t("job-configuration:span_enable")}
                  </Button>
                </span>
                <span>
                  <Button
                    className={clsx(
                      styles.buttonDelete,
                      "text-uppercase bg-color-tertiary-main"
                    )}
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    {t("job-configuration:span_delete")}
                  </Button>
                </span>
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Col xs="12" md="4" lg="5" />
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="12" md="3">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_job_name")}
                </Label>
                <Select
                  className="formTextLabel"
                  placeholder="select job Name"
                  name="jobConfigId"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  options={[
                    { label: "ALL", value: "" },
                    ...(Array.isArray(jobNameList)
                      ? jobNameList
                      : []),
                  ]}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="3">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_job_status")}
                </Label>
                <Select
                  className="formTextLabel"
                  placeholder={t("job-configuration:job_status_all")}
                  name="status"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  options={[
                    ...(Array.isArray(jobHistoryStatuses)
                      ? jobHistoryStatuses
                      : []),
                  ]}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="3">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_job_history_start_date_from")}
                </Label>
                <DatePicker
                  id="fromDate"
                  dateFormat="DD/MM/YYYY"
                  timeFormat
                  closeOnSelect
                  input
                  onChange={(e) => {
                    setFromDate(e);
                  }}
                  name="fromDate"
                  inputProps={{
                    placeholder: t("common:placeholder_date_example"),
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="3">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("job-configuration:label_job_history_start_date_to")}
                </Label>
                <DatePicker
                  id="endDate"
                  dateFormat="DD/MM/YYYY"
                  timeFormat
                  closeOnSelect
                  input
                  onChange={(e) => {
                    setToDate(e);
                  }}
                  name="endDate"
                  inputProps={{
                    placeholder: t("common:placeholder_date_example"),
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="3" className="p-0">
              <FormGroup>
                <Label className="formTextLabel  pt-3" for="exampleEmail" />
                <div className={styles.areaActionRight}>
                  <div>
                    <InputGroup
                      className={clsx(styles.search, "color-tertiary-main")}
                    >
                      <Input
                        placeholder={t(
                          "job-configuration:placeholder_search_name_task"
                        )}
                        name="searchTerm"
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                      <InputGroupAddon
                        addonType="append"
                        className="commonPointer bg-color-tertiary-main"
                      >
                        <img
                          src={SearchIcon}
                          alt={t(
                            "job-configuration:placeholder_search_name_task"
                          )}
                          className={styles.icon}
                          onClick={search}
                        />
                      </InputGroupAddon>
                    </InputGroup>{" "}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className={clsx(
          styles.block3,
          styles.tableWrapper,
          " common-page-card"
        )}
      >
        <Col xs="12">
          <div className="custom-table">
            <CustomizationTable
              customRows={customRows}
              customizationData={jobHistoryData}
              isStatusEnable={false}
              isDeleteEnable={false}
              isEditEnable={false}
              onClickRow={onClickRow}
            />
          </div>
          <div
            className={clsx(
              styles.pagination,
              "p-3",
              "d-flex",
              "justify-content-end"
            )}
          >
            <div className="d-flex">
              <span
                className={clsx(styles.item, styles.arrowLeft)}
                onClick={paginationPrev}
                disabled={startOfResult}
              >
                <span />
              </span>
              <span className="p-2"> </span>
              <span
                className={clsx(styles.item, styles.arrowRight)}
                onClick={paginationNext}
                disabled={endOfResult}
              >
                <span />
              </span>
            </div>
          </div>
        </Col>
      </Row>
      {onConfirmationModal ? (
        <ConfirmationModal
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          togglClose={handleToggleModal}
          handleConfirm
          content={t("job-configuration:modal_confirmation")}
        />
      ) : (
          " "
        )}
    </>
  );
};

JobHistoryConfiguration.propTypes = {
  onJobListing: PropTypes.func.isRequired,
  jobHistoryData: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobHistoryRunTimes: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobHistoryStatuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobId: PropTypes.string.isRequired,
};

export default JobHistoryConfiguration;
