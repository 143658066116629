/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Modify Carousel Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import WidgetContentEditor from "../components/WidgetContentEditor";
import commonUtils from "../../../common/utils/commonUtils";
import { updatePageContent, getPageContentByTypeAndConfigId } from "../../../api/pageContentServices";
import { getWidgetConfigurationByWidgetType } from "../../../api/widgetConfigurationsServices";
import imageUtils from "../../../common/utils/imageUtils";
import validateForm from "../../../common/utils/validateForm";
import { getStaticContentByCode } from "../../../api/staticContentService";

const WidgetContentEditorContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    widgetType, configId, isEdit, index, categoryId, pageType, channel, locale,
  } = commonUtils.getQueryParams(location);

  const [modalOpen, setModalOpen] = useState(false);
  const [linkType, setLinkType] = useState("Internal");
  const [widgetContent, setPageContent] = useState(null);
  const [widgetConfig, setWidgetConfig] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [content, setContent] = useState({
    header: "",
    body: "",
    description: "",
    altText: "",
    imageUrl: "",
    fileName: "",
    startDate: new Date(),
    endDate: new Date(),
    link: "",
    paramKey: "",
    paramValue: "",
    status: false,
  });
  const [media, setMedia] = useState({
    largeMediaUrl: "",
    defaultMediaUrl: "",
    smallMediaUrl: "",
    mediaThumbnailUrl: "",
  });
  const [selectedAnimationAssets, setAnimationAssets] = useState(null);
  const [selectedMediaType, setMediaType] = useState("");
  const [selectedShape, setShape] = useState("");
  const [animationSets, setAnimationSets] = useState(null);
  const [mediaOption, setMediaOption] = useState("Banner");

  /**
   *This method is used to handle media option
   *
   */
  const handleMediaOption = (value) => {
    setMediaOption(value);
  };

  /**
  *This method is used to toggle dropdown
  *
  */
  const handleChangeAnimationAsset = (value) => {
    setAnimationAssets(value);
  };


  /**
  *This method is used to toggle dropdown
  *
  */
  const handleChangeShape = (value) => {
    setShape(value);
  };

  // Getting  animation assets
  useEffect(() => {
    if (widgetConfig && widgetConfig.propertyConfig.animationAsset) {
      getStaticContentByCode("AnimationSets").then((response) => {
        if (response && response.success && response.data) {
          setAnimationSets(response.data);
        }
      });
    }
  }, [widgetConfig]);

  useEffect(() => {
    getPageContentByTypeAndConfigId(
      widgetType,
      configId, categoryId,
      channel, locale,
    ).then((response) => {
      if (response && response.success) {
        setPageContent(response.data);
      }
    });
  }, []);

  useEffect(() => {
    getWidgetConfigurationByWidgetType(pageType, widgetType, channel).then((response) => {
      if (response && response.success && response.data) {
        setWidgetConfig(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (isEdit && widgetContent) {
      const data = Array.isArray(widgetContent.contents) && widgetContent.contents[index];
      const basicContent = {
        ...content,
        startDate: moment(data.startDate).format("YYYY-MM-DD"),
        endDate: moment(data.endDate).format("YYYY-MM-DD"),
        link: data.link && data.link.url,
        altText: data.link && data.link.name,
        header: data.header,
        body: data.body,
        description: data.description,
      };
      if (widgetContent.animationAssets) {
        setMediaOption("Animated banner");
        setAnimationAssets(widgetContent.animationAssets);
        setShape(widgetContent.animationAssets.shape);
      }
      setContent({ ...basicContent });
      if (data.link && data.link.params) {
        const paramTypeIn = Object.keys(data.link.params)[0];
        setContent({
          ...basicContent,
          paramKey: paramTypeIn,
          paramValue: data.link && data.link.params[paramTypeIn],

        });
      }
      setLinkType(data.link ? data.link.type : "Internal");
      setMedia({
        largeMediaUrl: data.media
          && data.media.largeMediaUrl ? data.media.largeMediaUrl : "",
        defaultMediaUrl: data.media
          && data.media.defaultMediaUrl ? data.media.defaultMediaUrl : "",
        smallMediaUrl: data.media
          && data.media.smallMediaUrl ? data.media.smallMediaUrl : "",
        mediaThumbnailUrl: data.media
          && data.media.mediaThumbnailUrl ? data.media.mediaThumbnailUrl : "",
      });
    }
  }, [widgetContent]);

  /**
* This method is used to handle change text
*
* @param {String} key
* @param {Object} e
*/
  const handleChange = (key, e) => {
    if ((key === "startDate") || (key === "endDate")) {
      setContent({ ...content, [key]: e });
    } else {
      setContent({ ...content, [key]: e.target.value });
    }
  };

  /**
 * This method is used to handle change file
 *
 * @param {*} event
 */
  const handleChangeFile = async (event, type) => {
    const file = event.target.files[0];
    if (file && file.size > 5242880) {
      alert("Image size must under 5mb!");
    } else {
      const url = await imageUtils.readURL(file);
      setContent({ ...content, imageUrl: url });
      setMediaType(type);
      setModalOpen(true);
    }
  };

  /**
 * This method is used to handle upload image
 *
 * @param {*} url
 */
  const handleUploadImage = (data) => {
    if (data) {
      setIsUploading(false);
      setModalOpen(false);
      setMedia({ ...media, [selectedMediaType]: data.largeMediaUrl });
    } else {
      setIsUploading(false);
      setModalOpen(false);
      setContent({ ...content, imageUrl: "" });
    }
  };

  /**
 * This method is used to redirect to page-customization
 *
 */
  const handleRedirectToPageCustomization = () => {
    const path = categoryId ? `/merchandising/${channel === "mobile" ? "mobile-app-customization" : "web-app-customization"}?pageType=${pageType}&&categoryId=${categoryId}` : `/merchandising/${channel === "mobile" ? "mobile-app-customization" : "web-app-customization"}?pageType=${pageType}&locale=${locale}`;
    history.push(path);
  };

  const validator = validateForm();
  const {
    handleSubmit, register, errors, clearError, setError,
  } = validator;

  /**
 * This method is used to construct content
 *
 * @param {*} mediaData
 */
  const constructContent = (mediaData) => ({
    startDate: moment(content.startDate).format("YYYY-MM-DD hh:mm:ss"),
    endDate: moment(content.endDate).format("YYYY-MM-DD hh:mm:ss"),
    media: mediaData,
    header: content.header,
    body: content.body,
    description: content.description,
  });

  /**
  * This method is used to construct link content
  *
  */
  const constructLinkContent = () => {
    const linkContent = {
      type: linkType,
      url: content.link,
      name: content.altText,
    };
    if (content.paramKey && content.paramValue) {
      const newParams = {
        [content.paramKey]: content.paramValue,
      };
      linkContent.params = newParams;
    }
    return linkContent;
  };

  /**
 * This method is used to construct and validate media
 *
 */
  const constructAndValidateMedia = () => {
    let validMediaIn = true;
    const mediaIn = { ...media };
    if (widgetConfig
      && widgetConfig.propertyConfig
      && widgetConfig.propertyConfig.imageUrl) {
      const { imageUrl } = widgetConfig.propertyConfig;
      let newAspectRatio = {};
      if (widgetConfig.propertyConfig.animationAsset) {
        if (mediaOption && mediaOption === "Animated banner") {
          newAspectRatio.defaultMediaUrl = imageUrl.aspectRatio.defaultMediaUrl;
          mediaIn.largeMediaUrl = "";
          mediaIn.smallMediaUrl = "";
        } else {
          newAspectRatio.largeMediaUrl = imageUrl.aspectRatio.largeMediaUrl;
          newAspectRatio.smallMediaUrl = imageUrl.aspectRatio.smallMediaUrl;
          mediaIn.defaultMediaUrl = "";
        }
      } else {
        newAspectRatio = { ...widgetConfig.propertyConfig.imageUrl.aspectRatio };
      }
      Object.keys(newAspectRatio).map((k) => {
        if (!media[k]) {
          if (widgetConfig.responsive) {
            validMediaIn = false;
            setError("media", "notMatch", "Please upload responsive images");
          }
        }
        return validMediaIn;
      });
    }
    return { validMediaIn, mediaIn };
  };

  /**
   * This method is used to construct animation assets
   *
   * @param {*} contentIn
   */
  const constructAnimationAssets = (contentIn) => {
    const widgetContentIn = { ...contentIn };
    let isValid = true;
    if (widgetConfig
      && widgetConfig.propertyConfig
      && widgetConfig.propertyConfig.animationAsset) {
      const animationAssets = { ...selectedAnimationAssets };
      if (mediaOption === "Animated banner") {
        if (!selectedAnimationAssets) {
          isValid = false; setError("media", "notMatch", "Please select banner assets");
        } else if (!selectedShape) {
          isValid = false; setError("media", "notMatch", "Please select a shape");
        }
        const shuffleArray = (arr) => (arr ? arr
          .map((a) => [Math.random(), a])
          .sort((a, b) => a[0] - b[0])
          .map((a) => a[1]) : null);
        const shuffledIcons = selectedAnimationAssets
          ? shuffleArray(selectedAnimationAssets.icons) : null;
        animationAssets.shape = selectedShape;
        if (shuffledIcons) {
          animationAssets.icons = shuffledIcons;
          widgetContentIn.contentType = "AnimationAssets";
        } else {
          widgetContentIn.contentType = "";
        }
        widgetContentIn.animationAssets = animationAssets;
      } else {
        widgetContentIn.contentType = "";
        widgetContentIn.animationAssets = null;
      }
    }
    return { isValid, widgetContentIn };
  };

  /**
   * This method is used to construct widget content
   *
   * @param {*} newContent
   */
  const constructWidgetContent = (newContent) => {
    let newWidgetContent = {};
    let newConfigId = configId;
    if (pageType === "GLOBAL") {
      newConfigId = "GLOBAL";
    }
    if (!widgetContent) {
      newWidgetContent.widgetType = widgetType;
      newWidgetContent.contents = [];
      newWidgetContent.categoryId = categoryId;
    } else {
      newWidgetContent = { ...widgetContent };
    }
    const { contents } = newWidgetContent;
    let newContents = [];
    if (Array.isArray(contents) && contents.length > 0) {
      newContents = contents;
    }
    if (isEdit) {
      newContents[index] = { ...((newContents && newContents[index]) || {}), ...newContent };
    } else {
      newContents.push(newContent);
    }
    newWidgetContent.configId = newConfigId;
    newWidgetContent.contents = newContents;
    newWidgetContent.locale = locale;
    const { isValid, widgetContentIn } = constructAnimationAssets(newWidgetContent);
    newWidgetContent = widgetContentIn;
    return { newWidgetContent, isValid };
  };

  /**
   * This method is used to handle submit
   *
   */
  const submitForm = async (value) => {
    clearError(["media"]);
    let validMedia = true;
    const { validMediaIn, mediaIn } = constructAndValidateMedia();
    validMedia = validMediaIn;
    if (value && validMedia) {
      const newContent = constructContent(mediaIn);
      const linkContent = constructLinkContent();
      newContent.link = linkContent;
      const { isValid, newWidgetContent } = constructWidgetContent(newContent);
      validMedia = isValid;
      if (validMedia) {
        await updatePageContent(newWidgetContent, channel, locale).then((response) => {
          if (response && response.success) {
            handleRedirectToPageCustomization();
          }
        });
      }
    }
  };

  const handleCloseModal = () => {
    setContent({ ...content, imageUrl: "" });
    setMedia({ ...media, [selectedMediaType]: "" });
    setModalOpen(false);
  };

  /**
 * This method is used to remove an image
 *
 * @param {*} type
 */
  const handleRemoveImg = (type) => {
    setMedia({ ...media, [type]: "" });
  };

  return (
    <WidgetContentEditor
      widgetType={widgetType}
      linkType={linkType}
      setLinkType={setLinkType}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      handleUploadImage={handleUploadImage}
      location={location}
      handleChangeFile={handleChangeFile}
      content={content}
      handleChange={handleChange}
      submitForm={submitForm}
      isUploading={isUploading}
      setIsUploading={setIsUploading}
      handleRedirectToPageCustomization={handleRedirectToPageCustomization}
      handleCloseModal={handleCloseModal}
      media={media}
      selectedMediaType={selectedMediaType}
      handleRemoveImg={handleRemoveImg}
      pageType={pageType}
      handleSubmit={handleSubmit}
      register={register}
      errors={errors}
      channel={channel}
      widgetConfig={widgetConfig}
      selectedAnimationSet={selectedAnimationAssets}
      handleChangeAnimationAsset={handleChangeAnimationAsset}
      animationSets={animationSets}
      selectedShape={selectedShape}
      handleChangeShape={handleChangeShape}
      setShape={setShape}
      mediaOption={mediaOption}
      handleMediaOption={handleMediaOption}
    />
  );
};
export default WidgetContentEditorContainer;
