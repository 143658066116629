/* eslint-disable import/prefer-default-export */
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */


import httpApi from "./httpApi";

/**
 *This method is used to get site settings
 *
 * */
const getSiteSettings = async () => {
  const response = await httpApi.get("/v1/products/admin/product-list/configuration");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 *This method is used to update site settings
 *
 * */
const updateSiteSettings = async (data) => {
  const response = await httpApi.patch("/v1/products/admin/product-list/configuration", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getSiteSettings,
  updateSiteSettings,
};
