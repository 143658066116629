// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Angela\n */\n\n.SiteSettingsManagement_collapseIcon__3r2q->img {\n  width: 15px;\n  height: 15px;\n  transition: 0.6s ease-out;\n  float: right;\n}\n\n.SiteSettingsManagement_openCollapse__1W1C9 {\n  transform: rotateZ(-90deg);\n}\n\n.SiteSettingsManagement_closeCollapse__1JMvF {\n  transform: rotateZ(90deg);\n}\n\n.SiteSettingsManagement_block2__2aOW6 {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n.SiteSettingsManagement_borderRadius2__v5ngC {\n  border-radius: 0.125rem !important;\n}\n\n.SiteSettingsManagement_secondaryColor__dyK0L {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.SiteSettingsManagement_primaryColor__ulKJ3 {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n\n.SiteSettingsManagement_btn__3BvuK {\n  min-width: 7rem;\n}\n\n.SiteSettingsManagement_outOfStockSwitch__3MaKG{\n    margin-left: 15px;\n}\n\n.SiteSettingsManagement_zeroPriceProductStatusSwitch__2Sstf{\n    margin-left: 15px;\n}", ""]);
// Exports
exports.locals = {
	"collapseIcon": "SiteSettingsManagement_collapseIcon__3r2q-",
	"openCollapse": "SiteSettingsManagement_openCollapse__1W1C9",
	"closeCollapse": "SiteSettingsManagement_closeCollapse__1JMvF",
	"block2": "SiteSettingsManagement_block2__2aOW6",
	"borderRadius2": "SiteSettingsManagement_borderRadius2__v5ngC",
	"secondaryColor": "SiteSettingsManagement_secondaryColor__dyK0L",
	"primaryColor": "SiteSettingsManagement_primaryColor__ulKJ3",
	"btn": "SiteSettingsManagement_btn__3BvuK",
	"outOfStockSwitch": "SiteSettingsManagement_outOfStockSwitch__3MaKG",
	"zeroPriceProductStatusSwitch": "SiteSettingsManagement_zeroPriceProductStatusSwitch__2Sstf"
};
module.exports = exports;
