/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */


import React, { useState } from "react";
import clsx from "clsx";

/** ===== TAG COMPONENTS =========== */
import PropTypes from "prop-types";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";

/** ===== CUSTOM COMPONENTS =========== */
import Select from "../../../common/components/Select";

/** ======== MODULE STYLES ========== */
import styles from "../css/PrioritySchedule.module.css";


const PriorityValues = [
  {
    value: "1.0", selected: true, label: "1.0",
  },
  { value: "0.9", label: "0.9" },
  { value: "0.8", label: "0.8" },
  { value: "0.7", label: "0.7" },
  { value: "0.6", label: "0.6" },
  { value: "0.5", label: "0.5" },
  { value: "0.4", label: "0.4" },
  { value: "0.3", label: "0.3" },
  { value: "0.2", label: "0.2" },
  { value: "0.1", label: "0.1" },
  { value: "0", label: "0" },
];

const ScheduleValues = [
  { value: "Daily", selected: true, label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
];

const PrioritySchedule = ({
  productPriority, productSchedule, categoryPriority, handleChange,
  categorySchedule,
}) => {

  return (
    <>
      <div className={clsx(styles.subCardBody, "card-body")}>
        <Row className="m-0">
          <Col xs="6" md="6" lg="3" className="mr-3">
            <Label className="formTextLabel">Priority</Label>
            <Select
              className="formText"
              value={productPriority ? `${productPriority}` : `${categoryPriority}`}
              options={PriorityValues}
              onChange={(o) => handleChange("Priority", o)}
              name="Priority"
            />
          </Col>
          <Col xs="6" md="6" lg="3" className="ml-3">
            <Label className="formTextLabel">Schedule</Label>
            <Select
              className="formText"
              value={productSchedule ? `${productSchedule}` : `${categorySchedule}`}
              options={ScheduleValues}
              onChange={(o) => handleChange("Schedule", o)}
              name="Schedule"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
PrioritySchedule.propTypes = {
  handleChange: PropTypes.func.isRequired,
  productPriority: PropTypes.string.isRequired,
  categoryPriority: PropTypes.string.isRequired,
  productSchedule: PropTypes.string.isRequired,
  categorySchedule: PropTypes.string.isRequired,
};

export default PrioritySchedule;
