// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta\n * (\"Confidential Information\"). You shall not disclose such Confidential\n * Information and shall use it only in accordance with the terms of the\n * contract agreement you entered into with Mozanta.\n *\n * OrderReport.module.css\n *\n * @author Naseef O\n *\n */\n\n.OrderReport_block1__2o402 .OrderReport_buttonNewUser__24l05{\n  color: #ffffff;\n  width: 113px;\n  border-radius: 2px;\n  background-color: #e91e63;\n  border: 0px;\n  float: right;\n}\n\n\n/* block 2 */\n\n.OrderReport_tableWrapper__3h8Xv  {\n  padding-bottom: 20px;\n    background: #ffffff;\n    margin: 0px !important;\n    min-height: calc(100vh - 408px);\n    margin-bottom: 10px !important;\n}\n\n.OrderReport_block2__Hmit9 .OrderReport_dropdownBtn__3tRCL {\n  padding-right: 0px;\n}\n  \n\n/* block 3 */\n\n.OrderReport_block3__1vmBq .OrderReport_dropdownBtn__3tRCL{\n  padding: 0px;\n}\n\n.OrderReport_block3__1vmBq .OrderReport_dropdownBtn__3tRCL button{\n  float: right !important;\n  font-size: 11px;\n  min-width: 100px;\n}\n.OrderReport_block3__1vmBq  .OrderReport_dateFilterRow__1ReaY{\n  border-radius: 4px;\n  border: 1px solid #dff3f7;\n  background-color: #fdfdfd;\n  color: black;\n}\n.OrderReport_block3__1vmBq .OrderReport_verticalLine__2yw7x {\n  height: 15px;\n  padding: 5px;\n}\n.OrderReport_block3__1vmBq .OrderReport_verticalLineNone__I0Xua {\n  padding: 5px;\n  border-radius: 4px;\n\n}\n.OrderReport_block3__1vmBq .OrderReport_verticalLine__2yw7x:hover {\n  background-color: #17a2b8;\n  border-radius: 4px;\n  color: rgb(255, 255, 255);\n}\n.OrderReport_block3__1vmBq .OrderReport_verticalLineNone__I0Xua:hover {\n  background-color: #17a2b8;\n  border-radius: 4px;\n  color: rgb(255, 255, 255);\n}", ""]);
// Exports
exports.locals = {
	"block1": "OrderReport_block1__2o402",
	"buttonNewUser": "OrderReport_buttonNewUser__24l05",
	"tableWrapper": "OrderReport_tableWrapper__3h8Xv",
	"block2": "OrderReport_block2__Hmit9",
	"dropdownBtn": "OrderReport_dropdownBtn__3tRCL",
	"block3": "OrderReport_block3__1vmBq",
	"dateFilterRow": "OrderReport_dateFilterRow__1ReaY",
	"verticalLine": "OrderReport_verticalLine__2yw7x",
	"verticalLineNone": "OrderReport_verticalLineNone__I0Xua"
};
module.exports = exports;
