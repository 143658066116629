// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Naseef O\n */\n\n.ImageSelection_uploadImagePreview__qsKid {\n    min-width: 150px;\n    min-height: 150px;\n    max-width: 150px;\n    max-height: 150px;\n    position: relative;\n    justify-content: center;\n    align-items: center;\n    background: #f9f9f9;\n    justify-items: center;\n    display:flex;\n  }\n.ImageSelection_uploadImagePreviewIcon__1kc76 {\n    max-width: 150px;\n    min-width: 150px;\n}\n.ImageSelection_uploadImagePreviewEdit__F2Nlw{\n  display: none;\n}\n.ImageSelection_uploadImagePreviewClose__1Na49{\n  display: none;\n}\n.ImageSelection_uploadImagePreview__qsKid:hover .ImageSelection_uploadImagePreviewClose__1Na49 {\n  display: flex;\n  position: absolute;\n  top: 2px;\n  right: 2px;\n  padding: 0px 1px;\n  cursor: pointer;\n  background: white;\n  border-radius: 50%;\n}\n\n.ImageSelection_uploadImagePreview__qsKid:last-child {\n      margin-right: 0;\n}\n\n", ""]);
// Exports
exports.locals = {
	"uploadImagePreview": "ImageSelection_uploadImagePreview__qsKid",
	"uploadImagePreviewIcon": "ImageSelection_uploadImagePreviewIcon__1kc76",
	"uploadImagePreviewEdit": "ImageSelection_uploadImagePreviewEdit__F2Nlw",
	"uploadImagePreviewClose": "ImageSelection_uploadImagePreviewClose__1Na49"
};
module.exports = exports;
