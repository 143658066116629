/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */
import PropTypes from "prop-types";
import React, { useState } from "react";

/** ======== CUSTOM COMPONENTS ========= */
import CategorySelector from "../components/CategorySelector";

/** Base container for add new category  */
const CategorySelectorContainer = ({
  parentCategory, handleParentCategory, categoryLabel, setCategoryLabel,
}) => {
  /** local states */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  /**
   * This method is used return id only
   * but to display we need category name
   * @param {Object} category
   */
  const localHandleParentCategory = (category) => {
    if (category) {
      setCategoryLabel(category.name ? category.name : "");
      handleParentCategory(category);
      setDropdownOpen(false);
    } else {
      setCategoryLabel("");
      handleParentCategory(null);
    }
  };

  /**
   * This method is used for set parent category as root
   */
  const clearCategories = () => {
    setCategoryLabel("");
    handleParentCategory("");
  };

  return (
    <CategorySelector
      // component
      categoryName={categoryLabel}
      toggle={toggle}
      dropdownOpen={dropdownOpen}
      clearCategories={clearCategories}
      // parent category
      parentCategory={parentCategory}
      handleParentCategory={localHandleParentCategory}
    />
  );
};

CategorySelectorContainer.propTypes = {
  parentCategory: PropTypes.string.isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  categoryLabel: PropTypes.string.isRequired,
  setCategoryLabel: PropTypes.func.isRequired,
};

export default CategorySelectorContainer;
