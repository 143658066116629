/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * transformers
 *
 * @author Naseef O
 *
 */

const moment = require("moment");

/**
 * This method is used to format date
 *
 * @param {*} data
 * @param {*} path
 * @param {*} format
 */
const formatDate = (data, path, format) => moment(data[path]).format(format || "YYYY-MM-DD");

export default { formatDate };
