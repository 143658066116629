/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Order Management Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import OrderManagement from "../components/OrderManagement";

/** ===== API SERVICE FUNCTIONS =========== */
import {
  getOrders,
  transformOrderData,
} from "../../../api/orderServices";
import constants from "../../../common/utils/constants";
import OrderDetailsContainer from "../../orderDetails/containers/OrderDetailsContainer";

const moment = require("moment");
const Datetime = require("react-datetime");

const {
  ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE,
  STATUS_INCOMPLETE, STATUS_MERGED, STATUS_ABANDONED,
} = constants;
const OrderManagementContainer = () => {
  /** state variables */
  const [orders, setOrders] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isProccessing, setIsProccessing] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  useEffect(() => {
    onHandleSearch(0);
  }, [fromDate, toDate, paginationLimit, searchTerm]);

  const onHandleSearch = (direction) => {
    let newPage = page + direction;
    const sortBy = ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE;
    const searchData = {
      startDate: fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      endDate: toDate
        ? moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : null,
      searchTerm: searchTerm ? searchTerm.trim() : "",
      excludedStatuses: [STATUS_INCOMPLETE, STATUS_MERGED, STATUS_ABANDONED],
    };
    const pageLimit = Math.max(newPage, 0);
    const size = paginationLimit;
    const sortDir = "desc";
    getOrders(searchData, pageLimit, size, sortBy, sortDir).then((response) => {
      if (response && response.success === true) {
        const {
          data: { content },
        } = response;
        if (Array.isArray(content)) {
          setOrders(content);
          setHasMoreData(true);
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 0));
      }
    });
  };

  const initializeData = async () => {
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    setPage(0);
    setPaginationLimit(10);
    setHasMoreData(true);
    setIsProccessing(false);
  };

  /**
   * This function returns the true or false to disable the previous and future dates in datepicker
   */
  const isValidDate = (date, type) => {
    if (type === "ORDER_DATE_FROM") {
      if (toDate) {
        return date.isBefore(moment(toDate, "DD-MM-YYYY").add(1, "days"));
      }
      return date.isBefore(Datetime.moment());
    }
    if (type === "ORDER_DATE_TO") {
      if (fromDate) {
        return (
          date.isSameOrAfter(moment(fromDate, "DD-MM-YYYY"))
          && date.isSameOrBefore(Datetime.moment())
        );
      }
      return date.isSameOrBefore(Datetime.moment());
    }
  };

  const onHandleExport = async (type) => {
    const sortBy = ORDER_MANAGMENT_ORDER_LISTING_SORT_BY_ORDER_DATE;
    const searchData = {
      startDate: fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      endDate: toDate ? moment(toDate).format("YYYY-MM-DD HH:mm:ss") : null,
      searchTerm,
      isPaginationDisabled: true,
    };
    setIsProccessing(true);
    const page = 0;
    const size = 0;
    const sortDir = "desc";
    getOrders(searchData, page, size, sortBy, sortDir)
      .then(async (response) => {
        if (response && response.success === true) {
          const {
            data: { content },
          } = response;
          if (Array.isArray(content)) {
            const data = await transformOrderData(content);
            let fileName = "Orders.xlsx";
            if (type === "CSV") {
              fileName = "Orders.csv";
            }
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Orders");
            ws["!cols"] = [
              { wch: 15 },
              { wch: 30 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];
            XLSX.writeFile(wb, fileName);
            setIsProccessing(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsProccessing(false);
      });
    setIsProccessing(false);
  };

  const onHandleBack = (id) => {
    setSelectedOrderId(id);
  };

  const onHandleClear = () => {
    initializeData();
  };

  const onClickRow = (row) => {
    const { id } = row;
    setSelectedOrderId(id);
  };

  if (selectedOrderId) {
    return (
      <OrderDetailsContainer
        onHandleBack={onHandleBack}
        selectedOrderId={selectedOrderId}
      />
    );
  }
  return (
    <OrderManagement
      setFromDate={setFromDate}
      setToDate={setToDate}
      fromDate={fromDate}
      toDate={toDate}
      setSearchTerm={setSearchTerm}
      onHandleSearch={onHandleSearch}
      orders={orders}
      page={page}
      hasMoreData={hasMoreData}
      isValidDate={isValidDate}
      isProccessing={isProccessing}
      onHandleExport={onHandleExport}
      onClickRow={onClickRow}
      onHandleClear={onHandleClear}
      searchTerm={searchTerm}
    />
  );
};

export default OrderManagementContainer;
