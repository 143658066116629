/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aneesh K
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";

import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Label from "../../../common/core/Label";
import FormGroup from "../../../common/core/FormGroup";

/** ===== IMAGE ICONS =========== */
import SearchIcon from "../../../common/assets/images/svg/search.svg";

/** ======== COMPONENTS */

import CustomizationTable from "../../../common/components/Table";

import styles from "../css/SearchSynonymManagement.module.css";
import {useTranslation} from "react-i18next";

const SearchSynonymManagement = (props) => {
  const {updateSynonym, synonymSetting, onHandleEditSynonyms, onHandleDeleteSynonym, addNewSynonym, saveSynonymSettings, updateStatus, LocaleDropDownContainer, localeOnChange} = props;
  const {t} = useTranslation(["common", "synonym"]);
  /**
   * Get locale for each row
   */
  const getLocale = () => {
    return synonymSetting.locale;
  };

  /**
   * This method to change status in campaign list
   * @param {Object} row
   * @returns {Element}
   */
  const getStatus = (row) => {
    const newStatus = (
      <div className={clsx("custom-control", "custom-switch")}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.active}
          onChange={(o) =>
            updateStatus(!row.active, row.name,row.id, o)
          }
        />
        <Label
          className="custom-control-label"
          htmlFor={`expand-multiple${row.id}`}
        />
      </div>
    );

    return newStatus;
  };

  return (
    <>
      <Row>
        <Col xs="12">
          <Row className="pageHeader common-page-card">
            <Col xs="12" md="7">
              <span className="pageText">
                {t("synonym:synonym_method_title")}
              </span>
            </Col>
            <Col xs="12" md="5" className="d-flex justify-content-end">
              <Button
                onClick={addNewSynonym}
                className={clsx(
                  styles.borderRadius2,
                  "text-uppercase",
                  "text-truncate",
                  "btn-tertiary",
                  "button-font-size",
                  "mr-1"
                )}
              >
                Add New Synonym
              </Button>
              <Button
                onClick={saveSynonymSettings}
                className={clsx(
                  styles.borderRadius2,
                  "text-uppercase",
                  "text-truncate",
                  "btn-tertiary",
                  "button-font-size",
                  "mr-1"
                )}
              >
                Save Synonyms
              </Button>
            </Col>
          </Row>
          <Row className="pageHeader common-page-card">
            <Col xs="12">
              <LocaleDropDownContainer
                entityName=""
                isIndocument="false"
                localeHandler={localeOnChange}
              ></LocaleDropDownContainer>
            </Col>
          </Row>
          <Row className={clsx(styles.tableWrapper, " common-page-card")}>
            <Col xs="12">
              <div className="custom-table ">
                <CustomizationTable
                  customRows={[
                    {
                      label: "Settings name",
                      path: "name",
                    },
                    {
                      label: "Locale",
                      path: "render",
                      render: getLocale,
                    },
                    {
                      label: "Status",
                      path: "render",
                      render: getStatus,
                    },
                  ]}
                  customizationData={synonymSetting}
                  // isStatusEnable
                  isEditEnable
                  onEditData={onHandleEditSynonyms}
                  isDeleteEnable
                  onDeleteData={onHandleDeleteSynonym}
                />
              </div>
            </Col>
          </Row>

          {/* <Row className={clsx(styles.tableWrapper, " common-page-card")}>
            <Col xs="12">
              <Row>
                {synonymSetting &&
                  synonymSetting.map((element) => (
                    <>
                      <Col xs="4">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("synonym:synonym_field")}
                          </Label>
                          <Input
                            className="formText"
                            placeholder=""
                            name={element.field}
                            value={element.elements}
                            // onChange={(e) => handleFormChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="4 py-md-4">
                        <FormGroup>
                          <div
                            className={clsx("custom-control", "custom-switch")}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`expand-multiple${element.field}`}
                              checked={element.active}
                              onChange={(o) =>
                                updateSynonym(!element.active, element.field, o)
                              }
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor={`expand-multiple${element.field}`}
                            >
                              {t("synonym:label_active")}
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                  ))}
              </Row>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
};
SearchSynonymManagement.propTypes = {
  rowsLoading: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleIndexing: PropTypes.func.isRequired,
};

export default SearchSynonymManagement;
