// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2020 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Indrajith C\n */\n\n.BrandManagement_block2__3nPjN {\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n/** =========== ACTION AREA STYLES ============== **/\n.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.BrandManagement_block2__3nPjN .BrandManagement_borderRadius2__2Mt0P {\n  border-radius: 0.125rem;\n}\n\n.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q > div:first-child {\n  margin-right: 0.3rem;\n}\n.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q > div:last-child {\n  display: flex;\n}\n\n/** ========= SEARCH FILED ================ **/\n.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q .BrandManagement_search__260Em {\n  border: 1px solid;\n  border-radius: 0.125rem;\n}\n\n.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q .BrandManagement_search__260Em input {\n  border: 0;\n  width: 100%;\n  height: calc(1.5em + 0.75rem + 0px);\n}\n\n.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q .BrandManagement_search__260Em div {\n  border: 0;\n  border-radius: 0;\n  background-color: inherit;\n  padding-left: 0.6rem;\n  padding-right: 0.35rem;\n}\n\n/***** ========== MAIN ACTION AREA ============ **********/\n.BrandManagement_block3__2PNR2 {\n  padding: 1.25rem 0;\n}\n.BrandManagement_tableWrapper__1ao0i{\n  min-height: calc(100vh - 385px);\n}", ""]);
// Exports
exports.locals = {
	"block2": "BrandManagement_block2__3nPjN",
	"areaActionRight": "BrandManagement_areaActionRight__2hJ0Q",
	"borderRadius2": "BrandManagement_borderRadius2__2Mt0P",
	"search": "BrandManagement_search__260Em",
	"block3": "BrandManagement_block3__2PNR2",
	"tableWrapper": "BrandManagement_tableWrapper__1ao0i"
};
module.exports = exports;
